import Honeybadger from "honeybadger-js";
import { toast } from "react-toastify";

export const onMutationError = () => {
  toast.error("Something went wrong. Please, try again");
};

export const onQueryError = () => {
  toast.error("Something went wrong. Please, try again");
};

export const onFormSubmitFail = (form: object, serverResponse: any) => {
  // Is used when you are sure that YUP validation is enough
  // and mutation success should alway be success
  toast.error("Something went wrong. Please, try again");

  Honeybadger.notify({
    message: "Failed to submit Goal form",
    form,
    serverResponse
  });
};
