import React from "react";
import styled from "styled-components/macro";
import { Body, BodyProps } from "./Body";
import { Footer, FooterProps } from "./Footer";
import { Header, HeaderProps } from "./Header";

export interface DisabledType {
  disabled: boolean;
}

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

type CardProps = DisabledType & HeaderProps & BodyProps & FooterProps;

export const Card: React.SFC<CardProps> = ({ children, ...props }) => (
  <StyledCard>
    <Header {...props} />
    <Body {...props} />
    <Footer {...props} />
  </StyledCard>
);
