import { pick } from "lodash";
import React from "react";
import {
  GetSessionRecordingsForListPageGoalEntityInlineFragment,
  SessionRecordingFilter
} from "testly-web/queries";
import { AdvancedFilter } from "../../filters/AdvancedFilter/AdvancedFilter";
import { OnFilterChange } from "../../filters/AdvancedFilter/types";
import { countryFilter } from "./countryFilter";
import { deviceFilter } from "./deviceFilter";
import { durationFilter } from "./durationFilter";
import { goalsFilter } from "./goalsFilter";
import { referrerSourceFilter } from "./referrerSourceFilter";

interface Filters {
  device: React.ReactNode;
  country: React.ReactNode;
  projectGoals: React.ReactNode;
  splitTestGoals: React.ReactNode;
  duration: React.ReactNode;
  referrerSource: React.ReactNode;
}

export type FilterName = keyof Filters;

export const SessionRecordingsAdvancedFilter: React.SFC<{
  filter: SessionRecordingFilter;
  onFilterChange: OnFilterChange<SessionRecordingFilter>;
  projectGoals: GetSessionRecordingsForListPageGoalEntityInlineFragment[];
  splitTestGoals: GetSessionRecordingsForListPageGoalEntityInlineFragment[];
  className?: string;
  filtersToShow: FilterName[];
  onFilterClose?(): void;
}> = ({
  filter,
  projectGoals,
  splitTestGoals,
  onFilterChange,
  onFilterClose,
  filtersToShow
}) => {
  const filtersCount =
    (filter.deviceTypeIn || []).length +
    (filter.referrerSourceIn || []).length +
    (filter.locationCountryIsoCodeIn || []).length +
    (filter.convertedProjectGoalIdsIn || []).length +
    (filter.convertedSplitTestGoalIdsIn || []).length +
    (filter.durationLteq || filter.durationGteq ? 1 : 0);

  const renderedFilters: Filters = {
    projectGoals: goalsFilter(
      "convertedProjectGoalIdsIn",
      filter,
      onFilterChange,
      projectGoals
    ),
    splitTestGoals: goalsFilter(
      "convertedSplitTestGoalIdsIn",
      filter,
      onFilterChange,
      splitTestGoals
    ),
    duration: durationFilter(filter, onFilterChange),
    device: deviceFilter(filter, onFilterChange),
    country: countryFilter(filter, onFilterChange),
    referrerSource: referrerSourceFilter(filter, onFilterChange)
  };

  return (
    <AdvancedFilter
      filtersCount={filtersCount}
      onFilterClose={onFilterClose}
      renderedFilters={pick(renderedFilters, filtersToShow)}
    />
  );
};
