import React from "react";
import styled, { css } from "styled-components/macro";
import { menuItemHeight, menuItemWidth } from "../../../style-vars";

const StyledItem = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  box-sizing: border-box;

  width: ${menuItemWidth}px;
  height: ${menuItemHeight}px;

  position: relative;

  border-bottom: solid 1px rgba(30, 29, 30, 0.5);

  svg {
    transition: fill 0.3s ease-in-out;
    fill: #a7a9ae;
  }

  &:hover {
    svg {
      fill: #fff;
    }
  }

  &:focus {
    outline: none;
  }

  &:hover {
    color: #fff;
  }

  ${({ active }) =>
    active &&
    css`
      color: #fff;
      background-color: #1e1d1e;

      svg {
        transition-property: none;
        fill: #00ac69;
      }

      &:hover {
        svg {
          fill: #00ac69;
        }
      }
    `};
`;

const ImageContainer = styled.div`
  margin: 0 auto;
  width: 30px;
  height: 30px;
`;

const ActiveLine = styled.div`
  position: absolute;
  height: 100%;
  width: 4px;
  background-color: #00ac69;
`;

const Caption = styled.div`
  margin-top: 12px;

  transition: color 0.3s ease-in-out;
`;

export const Item: React.SFC<{
  caption: string;
  active: boolean;
  className?: string;
  image(): JSX.Element;
  onClick?(e: React.MouseEvent<Element, MouseEvent>): void;
}> = ({ image: Image, caption, active, className, onClick }) => {
  return (
    <StyledItem active={active} className={className} onClick={onClick}>
      {active && <ActiveLine />}
      <ImageContainer>
        <Image />
      </ImageContainer>

      <Caption>{caption}</Caption>
    </StyledItem>
  );
};
