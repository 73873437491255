import React from "react";
import { ApolloConsumer } from "react-apollo";
import Helmet from "react-helmet";
import { State } from "./state";

export const SetNavbar: React.SFC<{
  header: string;
  backPath?: string;
  caption?: string;
}> = ({ header, caption, backPath }) => {
  return (
    <ApolloConsumer>
      {client => {
        const navbarState: State = {
          header,
          backPath: backPath || "",
          caption: caption || "",
          __typename: "Navbar"
        };

        setTimeout(() => {
          // Not sure why, but without setTimeout
          // NavbarContainer doesn't get updated
          client.writeData({ data: { navbar: navbarState } });
        }, 0);

        return (
          <Helmet>
            <title>{header}</title>
          </Helmet>
        );
      }}
    </ApolloConsumer>
  );
};
