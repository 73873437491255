import React from "react";
import CustomScroll from "react-custom-scroll";
import styled from "styled-components/macro";
import { Checkbox } from "testly-web/components/Form/Checkbox";
import {
  InputWithImage,
  InputWithImageProps
} from "testly-web/components/Form/Input";
import { CloseIcon } from "testly-web/icons/CloseIcon";
import { SearchIcon } from "testly-web/icons/SearchIcon";

const itemHeight = 18;
const itemMarginTop = 8;
const itemsToDisplay = 8;
const listContainerHeight =
  (itemHeight + itemMarginTop) * itemsToDisplay - itemMarginTop;

const SelectBoxStyled = styled.div`
  position: absolute;

  box-sizing: border-box;
  border-radius: 2px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.3);
  border: solid 1px rgba(167, 169, 174, 0.5);
  background-color: #393839;
  padding: 20px 16px;
  width: 210px;
`;
const Title = styled.div`
  font-size: 10px;
  letter-spacing: 1.7px;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: bold;
`;
const CloseBtn = styled(CloseIcon)`
  position: absolute;
  right: 16px;
  top: 20px;
  cursor: pointer;
`;
const ListContainer = styled.div`
  height: ${listContainerHeight}px;

  margin-top: 16px;
  overflow-y: auto;
`;
const ListItem = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${itemMarginTop}px;

  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);

  &:first-child {
    margin-top: 1px;
  }
`;

const InputStyled = styled(InputWithImage)<InputWithImageProps>`
  height: 32px;
`;

const InputWrapper = styled.div`
  margin-top: 16px;
`;

const CheckboxStyled = styled(Checkbox)`
  display: flex;
  min-width: 0;
`;

interface Option {
  name: string;
  value: string;
  selected: boolean;
}

interface SelectBoxProps {
  availableValues: Option[];
  pluralSearchEntityName: string;
  className?: string;
  innerRef?: React.RefObject<HTMLDivElement>;
  onSelect(option: Option): void;
  onCloseClick(e: React.MouseEvent<Element, MouseEvent>): void;
}

interface SelectBoxState {
  searchValue: string;
}

class SelectBoxInnerRef extends React.Component<
  SelectBoxProps,
  SelectBoxState
> {
  private inputRef: React.RefObject<HTMLInputElement>;

  constructor(props: SelectBoxProps) {
    super(props);

    this.inputRef = React.createRef<HTMLInputElement>();
    this.state = { searchValue: "" };
  }

  public componentDidMount() {
    if (this.inputRef.current) {
      this.inputRef.current.focus();
    }
  }

  public render() {
    const filteredOptions =
      this.state.searchValue !== ""
        ? this.props.availableValues.filter(option =>
            option.name
              .toLowerCase()
              .includes(this.state.searchValue.toLowerCase())
          )
        : this.props.availableValues;

    return (
      <SelectBoxStyled
        className={this.props.className}
        ref={this.props.innerRef as React.RefObject<HTMLDivElement> & string}
      >
        <CloseBtn onClick={this.props.onCloseClick} />
        <Title>Choose {this.props.pluralSearchEntityName}</Title>
        <InputWrapper>
          <InputStyled
            dark={true}
            imagePosition="left"
            imageRender={() => <SearchIcon />}
            ref={this.inputRef as React.RefObject<HTMLInputElement> & string}
            onChange={e => {
              this.setState({ searchValue: e.target.value });
            }}
          />
        </InputWrapper>
        <ListContainer>
          <CustomScroll heightRelativeToParent="100%">
            {filteredOptions.map(option => (
              <ListItem key={option.value}>
                <CheckboxStyled
                  green={true}
                  checked={option.selected}
                  label={option.name}
                  onChange={() => {
                    this.props.onSelect(option);
                  }}
                />
              </ListItem>
            ))}
          </CustomScroll>
        </ListContainer>
      </SelectBoxStyled>
    );
  }
}

export const SelectBox: React.ComponentType<SelectBoxProps> = React.forwardRef(
  (props: SelectBoxProps, ref?: React.Ref<HTMLDivElement>) => (
    <SelectBoxInnerRef
      innerRef={ref as React.RefObject<HTMLDivElement>}
      {...props}
    />
  )
);
