import React from "react";

export const ImpactIcon = () => (
  // tslint:disable:max-line-length
  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="13">
    <path
      fill="#A7A9AE"
      d="M2.328 12.255a.275.275 0 0 1-.263-.355L3.45 7.36H.554a.277.277 0 0 1-.25-.157.279.279 0 0 1 .034-.292L5.595.358a.276.276 0 0 1 .481.248L4.784 5.152h2.788a.276.276 0 0 1 .216.448l-5.244 6.552a.274.274 0 0 1-.216.103z"
    />
  </svg>
);

export const ExampleTagIcon = () => (
  // tslint:disable:max-line-length
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
    <path
      fill="#FFBA47"
      d="M0 0v1.2h1.37l1.575 4.687-.9 1.2h-.02a1.249 1.249 0 0 0-.093 1.276C2.126 8.735 2.537 9 3 9h7.802V7.8H3c.002-.002-.007-.01 0-.019L3.9 6.6h5.645c.24 0 .483-.117.544-.356l1.857-4.2a.596.596 0 0 0-.544-.844H2.626L2.363.413 2.232 0H0zm3 9.6a1.2 1.2 0 1 0 0 2.399A1.2 1.2 0 0 0 3 9.6zm6.603 0a1.2 1.2 0 1 0-.002 2.399A1.2 1.2 0 0 0 9.603 9.6z"
    />
  </svg>
);

export const FilledHeartIcon = () => (
  // tslint:disable:max-line-length
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="13">
    <path
      fill="#FF5858"
      d="M10.733 0c-1.416 0-2.63 1.01-3.295 1.707C6.775 1.009 5.565 0 4.148 0 1.704 0 0 1.702 0 4.138c0 2.687 2.12 4.423 4.167 6.1.967.794 1.968 1.613 2.735 2.524.13.15.32.238.518.238h.04a.68.68 0 0 0 .517-.238c.769-.911 1.768-1.73 2.737-2.523 2.048-1.678 4.167-3.414 4.167-6.101C14.881 1.702 13.177 0 10.733 0z"
    />
  </svg>
);

export const EmptyHeartIcon = () => (
  // tslint:disable:max-line-length
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="15">
    <path
      fill="none"
      stroke="#A7A9AE"
      d="M11.733 1c-1.416 0-2.63 1.01-3.295 1.707C7.775 2.009 6.565 1 5.148 1 2.704 1 1 2.702 1 5.138c0 2.687 2.12 4.423 4.167 6.1.967.794 1.968 1.613 2.735 2.524.13.15.32.238.518.238h.04a.68.68 0 0 0 .517-.238c.769-.911 1.768-1.73 2.737-2.523 2.048-1.678 4.167-3.414 4.167-6.101C15.881 2.702 14.177 1 11.733 1z"
    />
  </svg>
);

export const StartTestIcon = () => (
  // tslint:disable:max-line-length
  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11">
    <path
      fill="#00AC69"
      d="M10.503 4.7L1.304.099A.899.899 0 0 0 0 .894v9.214c0 .308.16.597.427.758.146.09.308.134.472.134.14 0 .279-.033.405-.095l9.202-4.609a.889.889 0 0 0-.003-1.595z"
    />
  </svg>
);

export const AddCutsomIdeaIcon = () => (
  // tslint:disable:max-line-length
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21">
    <path
      fill="#A7A9AE"
      d="M10.5.188C4.805.188.188 4.805.188 10.5c0 5.695 4.617 10.313 10.312 10.313 5.695 0 10.313-4.618 10.313-10.313S16.195.188 10.5.188zM16 12h-4v4H9v-4H5V9h4V5h3v4h4v3z"
    />
  </svg>
);
