import { isEqual } from "lodash";
import React from "react";
import { SessionRecordingFilter } from "testly-web/queries";
import { CheckboxFilter } from "../../filters/AdvancedFilter/CheckboxFilter";
import { CheckboxOption } from "../../filters/AdvancedFilter/CheckboxOption";
import { OnFilterChange, OptionType } from "../../filters/AdvancedFilter/types";

type DurationName =
  | "lteq_2_mins"
  | "gteq_5_mins_and_lteq_10_mins"
  | "gteq_10_mins_and_lteq_20_mins"
  | "gteq_20_mins";

const durationNameToFilter = (name: DurationName) => {
  switch (name) {
    case "lteq_2_mins":
      return { durationGteq: null, durationLteq: 2 * 60 * 1000 };
    case "gteq_5_mins_and_lteq_10_mins":
      return { durationGteq: 5 * 60 * 1000, durationLteq: 10 * 60 * 1000 };
    case "gteq_10_mins_and_lteq_20_mins":
      return { durationGteq: 10 * 60 * 1000, durationLteq: 20 * 60 * 1000 };
    case "gteq_20_mins":
      return { durationGteq: 20 * 60 * 1000, durationLteq: null };
    default:
      return { durationGteq: null, durationLteq: null };
  }
};

const durationOptions: (
  durationGteq: number | null | undefined,
  durationLteq: number | null | undefined
) => Array<{
  value: DurationName;
  name: string;
  selected: boolean;
}> = (durationGteq, durationLteq) => {
  const filterValue = { durationGteq, durationLteq };

  return [
    {
      value: "lteq_2_mins",
      name: "< 2 min",
      selected: isEqual(durationNameToFilter("lteq_2_mins"), filterValue)
    },
    {
      value: "gteq_5_mins_and_lteq_10_mins",
      name: "5 - 10 min",
      selected: isEqual(
        durationNameToFilter("gteq_5_mins_and_lteq_10_mins"),
        filterValue
      )
    },
    {
      value: "gteq_10_mins_and_lteq_20_mins",
      name: "10 - 20 min",
      selected: isEqual(
        durationNameToFilter("gteq_10_mins_and_lteq_20_mins"),
        filterValue
      )
    },
    {
      value: "gteq_20_mins",
      name: "> 20 min",
      selected: isEqual(durationNameToFilter("gteq_20_mins"), filterValue)
    }
  ];
};

const handleDurationChange = (
  filter: SessionRecordingFilter,
  onFilterChange: (filter: SessionRecordingFilter) => void
) => (option: OptionType) => {
  const { durationGteq, durationLteq } = filter;
  const filterValue = { durationGteq, durationLteq };
  const filterFromName = durationNameToFilter(option.value as DurationName);

  onFilterChange({
    ...filter,
    ...(isEqual(filterValue, filterFromName)
      ? {
          durationGteq: null,
          durationLteq: null
        }
      : filterFromName)
  });
};

export const durationFilter = (
  filter: SessionRecordingFilter,
  onFilterChange: OnFilterChange<SessionRecordingFilter>
) => (
  <CheckboxFilter
    key="durationFilter"
    name="Duration"
    options={durationOptions(filter.durationGteq, filter.durationLteq)}
    optionRender={option => (
      <CheckboxOption
        key={option.value}
        option={option}
        onChange={handleDurationChange(filter, onFilterChange)}
      />
    )}
  />
);
