import { Point } from "heatmap.js/build/heatmap.js";

export const initMatrix = (width: number, height: number) => {
  const matrix: number[][] = [];

  for (let y = 0; y < height; y++) {
    matrix[y] = [];
    for (let x = 0; x < width; x++) {
      matrix[y][x] = 0;
    }
  }

  return matrix;
};

export const applyKernel = (
  matrix: number[][],
  kernel: number[][],
  points: Point[]
): number[][] => {
  const kernelCenter = (kernel.length - 1) / 2;

  points.forEach(({ y, x, weight }) => {
    for (let kernelY = 0; kernelY < kernel.length; kernelY++) {
      for (let kernelX = 0; kernelX < kernel[0].length; kernelX++) {
        const neighborX = x - (kernelCenter - kernelX);
        const neighborY = y - (kernelCenter - kernelY);

        if (
          matrix[neighborY] !== undefined &&
          matrix[neighborY][neighborX] !== undefined
        ) {
          matrix[neighborY][neighborX] += kernel[kernelY][kernelX] * weight;
        }
      }
    }
  });

  return matrix;
};
