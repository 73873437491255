import React, { useState } from "react";
import ReactModal from "react-modal";
import { toast } from "react-toastify";
import styled from "styled-components/macro";
import modalBg from "./modal-bg.svg";
import { ModalCloseIcon } from "./ModalCloseIcon";

const StyledModal = styled(ReactModal)`
  width: 800px !important;
  height: 550px;
  padding: 0 !important;

  border: none !important;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;

  outline: none;
  margin-top: 150px;
`;

const ModalHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 63px;
  padding: 0 25px;

  background-color: #00ac69;

  color: #fff;
  font-size: 18px;
`;

// const ModalMenu = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;

//   height: 100px;
//   padding: 0 30px;

//   background-color: #f2f3f4;
// `;

const ModalClose = styled.a`
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.6;
  }
`;

const ModalContent = styled.div`
  padding: 45px 340px 45px 30px;

  background: url(${modalBg}) no-repeat 100% 45px;

  font-size: 16px;
  line-height: 1.5;

  p:first-child {
    margin-top: 0;
  }

  ol {
    padding-left: 20px;

    font-size: 14px;

    li + li {
      margin-top: 35px;
    }
  }
`;

const Note = styled.p`
  margin: 10px 0 45px;

  color: #a7a9ae;
  font-size: 14px;
`;

const StyledForm = styled.form`
  display: flex;
  align-items: center;

  button {
    min-width: 90px;
    height: 40px;

    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);
    border: solid 1px #dcdae0;
    background-color: #07ae6d;

    color: #fff;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.5px;

    cursor: pointer;
    transition: 0.2s ease;

    &:hover {
      background-color: #10d287;
    }

    &:active {
      background-color: #098454;
    }

    &:focus {
      outline: none;
    }
  }
`;

const StyledInput = styled.input`
  height: 40px;
  width: 100%;
  padding: 10px;

  border-radius: 4px;
  border: solid 1px #dcdae0;
  box-sizing: border-box;

  font-size: 14px;
`;

export const CheckCodeModal: React.SFC<{
  url: string;
  projectId: string;
  children: (open: () => void) => React.ReactNode;
}> = ({ url, projectId, children }) => {
  const [active, setActive] = useState(false);
  const [currentUrl, setCurrentUrl] = useState(url);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    try {
      const parsedUrl = new URL(currentUrl);

      parsedUrl.searchParams.append(
        "testlyCodeInstallationCheckerProjectId",
        projectId
      );

      window.open(parsedUrl.toString(), "_blank");
    } catch (error) {
      toast.error("Please, enter the valid URL.");
    }

    e.preventDefault();
  };

  return (
    <>
      {children(() => setActive(true))}
      <StyledModal
        isOpen={active}
        shouldCloseOnEsc={true}
        onRequestClose={() => setActive(false)}
      >
        <>
          <ModalHeader>
            <span>Check Code Installation</span>
            <ModalClose href="#" onClick={() => setActive(false)}>
              <ModalCloseIcon />
            </ModalClose>
          </ModalHeader>
          {/* <ModalMenu>
          <nav>
            <a>Tracking code</a>
            <a>Verify installation</a>
          </nav>
          <span>
            Site ID: <strong>1009432</strong>
          </span>
        </ModalMenu> */}
          <ModalContent>
            <p>
              You can verify this tracking code is installed on a page by
              entering a URL in the field below.
            </p>

            <StyledForm onSubmit={onSubmit}>
              <StyledInput
                name="urlToCheck"
                placeholder={currentUrl}
                value={currentUrl}
                onChange={e => setCurrentUrl(e.target.value)}
              />
              <button type="submit">Verify</button>
            </StyledForm>
            <Note>e.g. https://google.com</Note>

            <ol>
              <li>
                Enter a URL above where you installed PathPulse to verify
                installation. This will open a new window.
              </li>

              <li>
                If you see the confirmation message - you can navigate
                throughout your site if you wish to verify installation on
                multiple pages.
              </li>

              <li>
                If you don`t see the confirmation message or see a failure
                message, it means that PathPulse has not been properly
                installed.
              </li>
            </ol>
          </ModalContent>
        </>
      </StyledModal>
    </>
  );
};
