import React from "react";
import styled, { css } from "styled-components";
import { Button } from "../Button";

const StyledBillingPlan = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;

  width: 520px;
  padding: 10px;

  background: #fff;
  border: 2px solid #ecedee;
  border-radius: 4px;
  box-sizing: border-box;

  color: #393839;

  & + & {
    margin-top: 10px;
  }

  ${({ active }) =>
    active &&
    css`
      background-color: #f7f7f8;

      ${BillingPlanButton} {
        color: #a7a9ae;
      }
    `}
`;

const BillingPlanVisitors = styled.strong`
  width: 90px;
  min-width: 90px;
  margin-right: 15px;

  text-align: right;
  font-size: 20px;
  font-weight: 600;
`;

const BillingPlanPrice = styled.strong`
  width: 50px;
  min-width: 50px;
  margin-left: 5px;
  margin-right: 10px;

  text-align: right;
  color: #393839;
  font-size: 20px;
  font-weight: 600;
`;

const BillingPlanCaption = styled.span`
  margin-right: 15px;
  color: #a7a9ae;
  font-size: 14px;
`;

const BillingPlanButton = styled(Button)`
  margin-left: auto;
  height: 40px;
  min-width: 156px;
  width: 156px;

  font-size: 14px;
  letter-spacing: 0;
`;

export const BillingPlan: React.SFC<{
  maxVisitors: string;
  price?: number;
  active?: boolean;
  upgrade?: boolean;
  special?: boolean;
}> = ({ maxVisitors, price, active, upgrade, special }) => {
  const buttonText = (): string => {
    if (active) {
      return "Cancel subscription";
    } else if (upgrade) {
      return "Upgrade Plan";
    } else if (special) {
      return "Contact Us";
    } else {
      return "Choose Plan";
    }
  };

  return (
    <StyledBillingPlan active={active}>
      <BillingPlanVisitors>
        {maxVisitors}
        {special && "+"}
      </BillingPlanVisitors>
      <BillingPlanCaption>visitors/month</BillingPlanCaption>
      {price && (
        <>
          <BillingPlanPrice>${price}</BillingPlanPrice>
          <BillingPlanCaption>/month</BillingPlanCaption>
        </>
      )}
      <BillingPlanButton
        action={upgrade || special ? "primary" : "secondary"}
        size="medium"
        children={buttonText()}
      />
    </StyledBillingPlan>
  );
};
