import moment from "moment";
import React from "react";
import styled from "styled-components/macro";
import { DropdownController } from "testly-web/components/Dropdown/DropdownController";
import { InputWithImage } from "testly-web/components/Form/Input";
import { CalendarIcon } from "testly-web/icons/CalendarIcon";
import { DEFAULT_DATE_FORMAT } from "testly-web/settings";
import { DateFilterDropbox, DateFilterProps } from "./DateFilterDropbox";

class TypedDropdownController extends DropdownController<
  HTMLDivElement,
  HTMLInputElement
> {}

const formatDate = (date: Date | null) => {
  if (date === null) {
    return "Not Set";
  } else {
    return moment(date).format(DEFAULT_DATE_FORMAT);
  }
};

const DateFilterStyled = styled.div`
  position: relative;
  width: 224px;
`;

export const DateFilter: React.SFC<DateFilterProps> = props => (
  <TypedDropdownController onClose={props.onFilterClose}>
    {({
      wrapperRef,
      togglerRef,
      onControlClick,
      closeAnimationIsRunning: closedByUser,
      opened
    }) => (
      <DateFilterStyled>
        <InputWithImage
          onClick={onControlClick}
          ref={togglerRef}
          value={
            props.fromDate || props.toDate
              ? `${formatDate(props.fromDate)} ... ${formatDate(props.toDate)}`
              : ""
          }
          imagePosition="right"
          placeholder="Select Date"
          readOnly={true}
          clickable={true}
          imageRender={() => <CalendarIcon dark={true} />}
        />
        <DateFilterDropbox
          opened={opened}
          closedByUser={closedByUser}
          innerRef={wrapperRef}
          {...props}
        />
      </DateFilterStyled>
    )}
  </TypedDropdownController>
);
