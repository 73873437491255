import { Form, Formik, FormikProps } from "formik";
import { pick } from "lodash";
import React from "react";
import { MutationFn } from "react-apollo";
import { toast } from "react-toastify";
import styled from "styled-components/macro";
import {
  UpdateProfileAtAccountSettingsMutation,
  UpdateProfileAtAccountSettingsVariables
} from "testly-web/queries";
import * as Yup from "yup";
import { TextField } from "../Form/Field";
import { onFormSubmitFail } from "../onFail";
import { AccountSettingsControls } from "./AccountSettingsControls";
import { AvatarUpload } from "./AvatarUpload";

const StyledAccountSettingsProfile = styled.div`
  display: flex;

  padding: 25px 40px;
`;

const Fields = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;

interface ProfileValues {
  id: string;
  fullName: string;
  companyName: string | null;
  email: string;
  phone: string | null;
  avatarUrl: string | null;
}

const schema = Yup.object().shape({
  fullName: Yup.string().required("A name is required"),
  companyName: Yup.string().nullable(),
  phone: Yup.string().nullable()
});

export const AccountSettingsProfileForm: React.SFC<{
  initialValues: ProfileValues;
  updateProfile: MutationFn<
    UpdateProfileAtAccountSettingsMutation,
    UpdateProfileAtAccountSettingsVariables
  >;
}> = ({ initialValues, updateProfile }) => (
  <Formik
    initialValues={{
      ...initialValues,
      companyName: initialValues.companyName || "",
      phone: initialValues.phone || ""
    }}
    onSubmit={async (values: ProfileValues, actions) => {
      const response = await updateProfile({
        variables: {
          userParams: pick(values, ["fullName", "companyName", "phone"]),
          userId: initialValues.id
        }
      });

      if (response && response.data && response.data.updateUser) {
        const { successful, result } = response.data.updateUser;

        if (successful && result) {
          // notification here
          toast.success("Profile is updated");
          actions.resetForm();
        } else {
          onFormSubmitFail(values, response.data);
        }
      }

      actions.setSubmitting(false);
    }}
    validationSchema={schema}
    validateOnBlur={false}
    validateOnChange={false}
    render={(formProps: FormikProps<ProfileValues>) => (
      <Form>
        <StyledAccountSettingsProfile>
          <AvatarUpload
            userId={initialValues.id}
            updateProfile={updateProfile}
            avatarUrl={initialValues.avatarUrl}
          />

          <Fields>
            <TextField label="Full Name" name="fullName" {...formProps} />
            <TextField label="Company Name" name="companyName" {...formProps} />
            <TextField
              inputType="email"
              label="Email Address"
              name="email"
              {...formProps}
              disabled={true}
            />
            <TextField label="Phone" name="phone" {...formProps} />
          </Fields>
        </StyledAccountSettingsProfile>

        <AccountSettingsControls
          reset={formProps.handleReset}
          loading={formProps.isSubmitting}
        />
      </Form>
    )}
  />
);
