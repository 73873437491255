import React from "react";
import {
  GetSessionRecordingsForListPageGoalEntityInlineFragment,
  SessionRecordingFilter
} from "testly-web/queries";
import { handleInFilterChange } from "../../filters/AdvancedFilter/inFilter";
import { ListInFilter } from "../../filters/AdvancedFilter/ListInFilter";
import { OnFilterChange } from "../../filters/AdvancedFilter/types";

export const goalsFilter = (
  fitlerName: "convertedProjectGoalIdsIn" | "convertedSplitTestGoalIdsIn",
  filter: SessionRecordingFilter,
  onFilterChange: OnFilterChange<SessionRecordingFilter>,
  goals:
    | GetSessionRecordingsForListPageGoalEntityInlineFragment[]
    | GetSessionRecordingsForListPageGoalEntityInlineFragment[]
) => {
  const selectedGoals = filter[fitlerName] || [];
  const onItemClick = handleInFilterChange(filter, fitlerName, onFilterChange);

  return (
    <ListInFilter
      key={fitlerName}
      namePlural="Goals"
      nameSingular="Goal"
      options={goals.map(({ id, name }) => ({
        name,
        value: id,
        selected: selectedGoals.includes(id)
      }))}
      onItemClick={onItemClick}
    />
  );
};
