import styled from "styled-components/macro";

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 33px 36px 40px;
  margin-left: 25px;
  background-color: #ffffff;

  &:first-child {
    margin-left: 0px;
  }
`;
export const Header = styled.div`
  display: flex;
  align-items: center;

  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  color: #393839;

  white-space: nowrap;
`;
export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 23px;
`;
export const StepNumber = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 31px;
  height: 31px;
  border-radius: 50%;
  margin-right: 16px;
  background-color: #00ac69;

  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  color: #ffffff;
`;
export const Body = styled.div`
  flex: 1;
  margin-top: 16px;
`;
