import { isEqual } from "lodash";
import React from "react";
import { MousePosition } from "session-player/siteTypes";
import styled, { keyframes } from "styled-components/macro";
import { LayerProps } from "./types";

interface MousePostionProps {
  mousePosition: MousePosition;
  previousMousePosition: MousePosition;
  animationDuration: number;
}

const mouseAnimaton = ({
  mousePosition,
  previousMousePosition
}: MousePostionProps) =>
  keyframes`
    from {
      transform: translate3d(${previousMousePosition.x}px, ${
    previousMousePosition.y
  }px, 0)
    }
    to {
      transform: translate3d(${mousePosition.x}px, ${mousePosition.y}px, 0)
    }
  `;

const Mouse = styled.img<MousePostionProps>`
  will-change: transform;
  position: absolute;

  animation: ${mouseAnimaton} ${({ animationDuration }) => animationDuration}ms
    linear;
  animation-fill-mode: forwards;

  top: -2px;
  left: -3px;
`;

export class MouseLayer extends React.Component<LayerProps> {
  public mouseRef: React.RefObject<HTMLDivElement>;
  private previousMousePosition: MousePosition;

  constructor(props: LayerProps) {
    super(props);

    this.previousMousePosition = {
      x: 0,
      y: 0,
      pageX: 0,
      pageY: 0,
      animationDuration: 0
    };
    this.mouseRef = React.createRef<HTMLDivElement>();
  }

  public shouldComponentUpdate(nextProps: LayerProps) {
    const mousePosition = nextProps.frame.lastMousePosition;
    const prevMousePosition = this.props.frame.lastMousePosition;

    return !isEqual(mousePosition, prevMousePosition);
  }

  public render() {
    // tslint:disable:max-line-length

    const {
      frame: { lastMousePosition }
    } = this.props;

    const mousePosition = lastMousePosition || {
      x: 0,
      y: 0,
      pageX: 0,
      pageY: 0
    };

    const previousMousePosition = this.previousMousePosition;
    this.previousMousePosition = mousePosition;

    return (
      <Mouse
        mousePosition={mousePosition}
        previousMousePosition={previousMousePosition}
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAZdJREFUeNqslTtLxEAUhTNmxSeKiqL4KLRQsLDcFP4Ae7XRn2RvEVfyA8QuqS222k2xjYHY6IKkkW0EERTdeA7c0fG1GTdeOORmMny592Qmo/I8dxTCkcg58A+hRAMiQrvCL/UCDRwG5znLskPkQxwzu+g3CFrIJZrN5h7uRyGX3bDwfsQYgdYI9TyPl24cx6XhjgDWxdN3eNnKNXhDg014mcp/BFPVarVU5b+Cy9rSE2xW/ldbCsH9wq3Api2tVmuXG4qbyBbcNSFmpV+FZ1NQpVfV38CEpWl6ySvva7VaO4qiM+Tc7jvQFjQDDVqDdYVhGJ77vn9jWoB8m/O4/aGxIp/NnafbPID2mWtLgiC4SpLkCPms/AKsPh4nrgqUrXpUvV4/RVxrezqdzgXyuSILTDD/bvPQJiuXVlfYulm1vHhJ5luBXbFjGpqQpTRJP1H1ibao0WgcY2zZFmyeIMpYtxVZUovSPsfuoFvoHnopOmGUfs4Tg5Pl5FBS2bh0w3iEHqAnscWxAn8a/IC7IsaryOo8fBNgAOgV7fqnrxxaAAAAAElFTkSuQmCC"
        role="presentation"
        alt=""
        ref={this.mouseRef as React.RefObject<HTMLImageElement>}
        animationDuration={mousePosition.animationDuration}
      />
    );
  }
}
