import { FormikHandlers, FormikProps, FormikState, getIn } from "formik";
import React from "react";
import styled from "styled-components/macro";
import { TextField } from "testly-web/components/Form/Field";
import { RadionInput } from "testly-web/components/Form/Radio";
import { CrossIcon } from "testly-web/icons/CrossIcon";
import { SimplePlusIcon } from "testly-web/icons/SimplePlusIcon";
import { matches } from "./matches";

export const orderNumberSize = 40;

const MatchTypeSelectStyled = styled.div`
  display: flex;
  margin-top: 8px;
`;

const RadionInputStyled = styled(RadionInput)`
  margin-left: 20px;

  &:first-child {
    margin-left: 0px;
  }
`;

const StyledInput = (styled(TextField)`
  width: 100%;
  margin-bottom: 0px;
` as unknown) as new <T>() => TextField<T>;

const Circle = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color: #ecedee;
  cursor: pointer;
  border: none;
  margin-left: 16px;
`;

const randomId = () =>
  // tslint:disable-next-line:insecure-random
  Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, "");

const MatchTypeSelect: React.SFC<{
  fieldName: string;
  readOnly?: boolean;
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  values: FormikState<object>["values"];
  errors: FormikState<object>["errors"];
  touched: FormikState<object>["touched"];
}> = ({ fieldName, values, handleChange, handleBlur, readOnly }) => (
  <MatchTypeSelectStyled>
    {Object.entries(matches).map(([patternMode, name]) => (
      <RadionInputStyled
        key={patternMode}
        label={name}
        name={readOnly ? randomId() : fieldName}
        value={patternMode}
        checked={getIn(values, fieldName) === patternMode}
        onChange={handleChange}
        onBlur={handleBlur}
        readOnly={readOnly}
      />
    ))}
  </MatchTypeSelectStyled>
);

export const UrlInputControl: React.SFC<{
  onAddClick: () => void;
  onDeleteClick: () => void;
  mode: "addable" | "deletable";
}> = ({ onAddClick, onDeleteClick, mode }) =>
  mode === "addable" ? (
    <Circle role="button" onClick={onAddClick}>
      <SimplePlusIcon />
    </Circle>
  ) : (
    <Circle role="button" onClick={onDeleteClick}>
      <CrossIcon />
    </Circle>
  );

export const UrlInput: React.SFC<
  FormikProps<object> & {
    fieldPrefix: string;
    label?: string;
    className?: string;
    readOnly?: boolean;
    showMatchTypes?: boolean;
    urlInputAfterRender?(): React.ReactNode;
  }
> = ({
  fieldPrefix,
  label,
  className,
  readOnly,
  showMatchTypes,
  urlInputAfterRender,
  ...props
}) => (
  <div className={className}>
    <StyledInput
      label={label}
      name={`${fieldPrefix}url`}
      readOnly={readOnly}
      afterInputRender={urlInputAfterRender}
      {...props}
    />
    {showMatchTypes !== false && (
      <MatchTypeSelect
        fieldName={`${fieldPrefix}matchType`}
        readOnly={readOnly}
        {...props}
      />
    )}
  </div>
);
