import { FormikProps } from "formik";
import React from "react";
import { Button } from "testly-web/components/Button";
import { GoalForm } from "../../components/goal/GoalForm";
import { PathGoalForm } from "../../components/goal/pathGoal";

export const GoalNewPage: React.SFC<{
  formProps: FormikProps<PathGoalForm>;
}> = ({ formProps }) => (
  <GoalForm
    formProps={formProps}
    footerRender={(isSubmitting: boolean) => (
      <>
        <div />
        <Button
          action="primary"
          size="small"
          type="submit"
          disabled={isSubmitting}
          loading={isSubmitting}
        >
          Create Goal
        </Button>
      </>
    )}
  />
);
