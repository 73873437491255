import React from "react";

export const CancelIcon: React.SFC<{
  className?: string;
  onClick?(): void;
}> = ({ className, onClick }) => (
  // tslint:disable:max-line-length
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    className={className}
    onClick={onClick}
  >
    <path
      fill="#DDDBE1"
      d="M7 0C3.129 0 0 3.129 0 7s3.129 7 7 7 7-3.129 7-7-3.129-7-7-7zm3.5 9.513l-.987.987L7 7.987 4.487 10.5 3.5 9.513 6.013 7 3.5 4.487l.987-.987L7 6.013 9.513 3.5l.987.987L7.987 7 10.5 9.513z"
    />
  </svg>
);
