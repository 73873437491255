import React from "react";

export const PollActiveIcon: React.SFC<
  React.SVGProps<SVGSVGElement>
> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="#A7A9AE"
      d="M8 0C3.591 0 0 3.591 0 8s3.591 8 8 8 8-3.591 8-8-3.591-8-8-8zm0 1.6c3.544 0 6.4 2.856 6.4 6.4 0 3.544-2.856 6.4-6.4 6.4A6.388 6.388 0 0 1 1.6 8c0-3.544 2.856-6.4 6.4-6.4zm3.434 3.434L6.4 10.07 4.566 8.234 3.434 9.366 6.4 12.33l6.166-6.165-1.132-1.132z"
    />
  </svg>
);
