import { toPairs } from "lodash";
import React from "react";
import styled from "styled-components/macro";
import { DisabledType } from "../Card";

export type BodyProps = {
  statistics: Record<string, string>;
} & DisabledType;

const StyledBody = styled.div`
  margin-top: 10px;
`;
const Statistics = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const StatisticsRow = styled.div`
  display: flex;
  margin-top: 16px;
`;
const StaticName = styled.div`
  font-family: Roboto;
  font-size: 16px;
  color: #a7a9ae;
`;
const StaticValue = styled.div<DisabledType>`
  width: 100px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;

  color: ${({ disabled }) => (disabled ? `#a7a9ae` : "#393839")};

  margin-left: auto;
`;

export const Body: React.SFC<BodyProps> = ({ disabled, statistics }) => (
  <StyledBody>
    <Statistics>
      {toPairs(statistics).map(([fieldName, fieldValue]) => (
        <StatisticsRow key={fieldName}>
          <StaticName>{fieldName}:</StaticName>
          <StaticValue disabled={disabled}>{fieldValue}</StaticValue>
        </StatisticsRow>
      ))}
    </Statistics>
  </StyledBody>
);
