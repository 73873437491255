import { Form, Formik, FormikActions } from "formik";
import React from "react";
import { Button } from "testly-web/components/Button";
import { ConfirmAlert } from "testly-web/components/ConfirmAlert/ConfirmAlert";
import { TextField } from "testly-web/components/Form/Field";
import { TogglerWithLabel } from "testly-web/components/Form/Toggler";
import { Body, Footer, Panel } from "testly-web/components/Panel";
import { GetDataForProjectEditPageProject } from "testly-web/queries";
import * as Yup from "yup";

export interface FormValues {
  domain: string;
  isRecordingEnabled: boolean;
}

export const ProjectEditPage: React.SFC<{
  project: GetDataForProjectEditPageProject;
  onSubmit(values: FormValues, actions: FormikActions<FormValues>): void;
  onDelete(): Promise<void>;
}> = ({ project, onSubmit, onDelete }) => (
  <Panel>
    <Formik
      initialValues={{
        domain: project.domain,
        isRecordingEnabled: project.isRecordingEnabled
      }}
      validationSchema={Yup.object().shape({
        domain: Yup.string().required("A domain is required"),
        isRecordingEnabled: Yup.boolean().required()
      })}
      onSubmit={onSubmit}
    >
      {props => {
        const { isSubmitting, values, setFieldValue } = props;

        return (
          <Form>
            <Body>
              <TextField label="Domain" name="domain" {...props} />
              <TogglerWithLabel
                name="isRecordingEnabled"
                label="Session Recordings"
                {...props}
                checked={values.isRecordingEnabled}
                onChange={() => {
                  setFieldValue(
                    "isRecordingEnabled",
                    !values.isRecordingEnabled
                  );
                }}
              />
            </Body>
            <Footer>
              <ConfirmAlert
                title={`Are you sure you wish to remove your ${
                  project.domain
                } project?`}
                description="You will lose all your data"
              >
                {confirm => (
                  <Button
                    action="dangerous"
                    size="small"
                    onClick={confirm(onDelete)}
                    type="button"
                  >
                    Delete Project
                  </Button>
                )}
              </ConfirmAlert>
              <Button
                action="primary"
                size="small"
                type="submit"
                disabled={isSubmitting}
                loading={isSubmitting}
              >
                Save
              </Button>
            </Footer>
          </Form>
        );
      }}
    </Formik>
  </Panel>
);
