import React from "react";

export const RightArrowIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="13"
    viewBox="0 0 8 13"
  >
    <path d="M.5 1.387L1.404.5l5.663 5.556.433.444-.433.444L1.404 12.5.5 11.613 5.71 6.5z" />
  </svg>
);
