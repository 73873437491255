import React from "react";

export const ArrowIcon = () => (
  <svg width="7.56" height="13" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M.142 2.232l4.757 4.437-4.436 4.757 1.448 1.35 5.787-6.205L1.492.784z"
      fillRule="evenodd"
    />
  </svg>
);
