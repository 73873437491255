import { isEqual } from "lodash";
import { darken } from "polished";
import React from "react";
import styled, { css, keyframes } from "styled-components/macro";
import { DropdownArrow } from "testly-web/components/Dropdown/DropdownArrow";
import { DropdownController } from "testly-web/components/Dropdown/DropdownController";
import { Page } from "testly-web/components/Player/PlayerContainer";
import { toMMSS } from "testly-web/utils";
import { Time } from "./styles";

const pageBgColor = "#fff";
const selectedPageBgColor = darken(0.05, pageBgColor);

const pageItemHeight = 65;

const slideInDown = keyframes`
  0% {
    transform: translate3d(0, -5%, 0);
    visibility: visible;
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
`;

const slideInUp = keyframes`
  0% {
    visibility: visible;
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, -5%, 0);
    opacity: 0;
  }
`;

const StyledPage = React.memo(styled.div<{ selected: boolean; hover: boolean }>`
  display: flex;
  position: relative;
  height: ${pageItemHeight}px;
  z-index: 1;
  box-sizing: border-box;

  padding-left: 20px;
  padding-right: 50px;

  background-color: ${pageBgColor};
  transition: background-color 0.3s;

  cursor: pointer;

  border-top: solid 1px #ecedee;
  &:first-child {
    border-top: none;
  }

  ${({ hover }) =>
    hover &&
    css`
      &:hover {
        background-color: ${darken(0.04, pageBgColor)};
      }
    `};
  ${({ selected }) =>
    selected &&
    css`
      background-color: ${selectedPageBgColor};
      &:hover {
        background-color: ${darken(0.04, selectedPageBgColor)};
      }
    `};
`);
const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 0;
`;
const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: #1e1d1e;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const URLStyled = styled.div`
  font-family: Roboto;
  line-height: 1.67;
  color: #a7a9ae;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
`;
const PageTimeWrapper = styled.div`
  align-self: center;
  display: inline-flex;
  margin-left: auto;
  padding-left: 10px;
`;
const StyledDropdownArrow = styled(DropdownArrow)`
  margin: auto;
  position: absolute;
  right: 23px;
  top: 50%;
  transform: translateY(-50%);
`;

const PageComponent: React.SFC<{
  page: Page;
  dropdownArrow: boolean;
  selected: boolean;
  innerRef?: React.RefObject<HTMLElement>;
  currentPage: boolean;
  onClick(e: React.MouseEvent<Element, MouseEvent>): void;
}> = ({ page, dropdownArrow, onClick, innerRef, selected, currentPage }) => (
  <StyledPage
    onClick={onClick}
    ref={
      // tslint:disable-next-line:no-any
      innerRef as React.RefObject<any>
    }
    selected={selected}
    hover={!currentPage}
  >
    <Body>
      <Title>{page.title}</Title>
      {page.url && <URLStyled>{new URL(page.url).pathname}</URLStyled>}
    </Body>
    <PageTimeWrapper>
      <Time>{toMMSS(page.duration / 1000)}</Time>
    </PageTimeWrapper>
    {dropdownArrow && <StyledDropdownArrow />}
  </StyledPage>
);

const PagesDropdownStyled = styled.div`
  margin-top: 40px;
  position: relative;
`;

// eslint-disable-next-line no-unexpected-multiline
const DropdownElements = styled.div<{
  opened: boolean;
  closeAnimationIsRunning: boolean;
}>`
  width: 100%;
  position: absolute;
  z-index: 2000; /* to show box shadow */
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.21);
  max-height: ${pageItemHeight * 4}px;
  overflow-y: auto;
  background-color: #fff;

  visibility: hidden;

  ${({ opened }) =>
    opened &&
    css`
      animation: ${slideInDown} 0.2s;
      opacity: 1;
      visibility: visible;
    `};

  ${({ closeAnimationIsRunning: closedByUser }) =>
    closedByUser &&
    css`
      animation: ${slideInUp} 0.2s;
    `};
`;

export const PagesDropdown: React.SFC<{
  pages: Page[];
  currentPage: Page;
  onPageClick(pageIndex: number): void;
}> = React.memo(({ currentPage, pages, onPageClick }) => {
  return (
    <PagesDropdownStyled>
      <DropdownController>
        {({
          wrapperRef,
          togglerRef,
          onControlClick,
          closeAnimationIsRunning,
          opened,
          close
        }) => (
          <>
            <PageComponent
              currentPage={true}
              selected={false}
              page={currentPage}
              dropdownArrow={true}
              onClick={onControlClick}
              innerRef={togglerRef}
            />
            <DropdownElements
              ref={wrapperRef as React.RefObject<HTMLDivElement> & string}
              opened={opened}
              closeAnimationIsRunning={closeAnimationIsRunning}
            >
              {pages.map((page, i) => (
                <PageComponent
                  currentPage={false}
                  selected={isEqual(page, currentPage)}
                  key={JSON.stringify(page)}
                  page={page}
                  dropdownArrow={false}
                  onClick={() => {
                    close();
                    onPageClick(i);
                  }}
                />
              ))}
            </DropdownElements>
          </>
        )}
      </DropdownController>
    </PagesDropdownStyled>
  );
});
