import { NetworkStatus } from "apollo-boost";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { Button } from "testly-web/components/Button";
import {
  ContextActionItem,
  ContextDropdown
} from "testly-web/components/ContextDropdown/ContextDropdown";
import { DeleteFeedbackPollResponse } from "testly-web/components/feedback/DeleteFeedbackPollResponse";
import { SimplePaginator } from "testly-web/components/SimplePaginator";
import {
  ActionsWrapper,
  BodyCell,
  BodyCellWithImage,
  HeadCell,
  Row,
  SecondTextLine,
  Table,
  TableEmptyCell,
  TableRefreshIcon
} from "testly-web/components/Table";
import { CalendarIcon } from "testly-web/icons/CalendarIcon";
import { paths } from "testly-web/paths";
import { DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT } from "testly-web/settings";

interface FeedbackPollQuestion {
  id: string;
  title: string;
}

interface FeedbackPollResponse {
  id: string;
  answers: FeedbackPollResponseAnswer[];
  createdAt: string;
  pageUrl: string;
  sessionRecording: {
    id: string;
  } | null;
}

interface FeedbackPollResponseAnswer {
  question: {
    id: string;
  };
  content: string;
}

const FeedbackPollIndexPageStyled = styled.div`
  padding: 40px;
`;

const TableStyled = styled(Table)`
  table-layout: fixed;
`;

const ActionHeadCell = styled(HeadCell)`
  width: 250px;
`;

const ActionButton = styled(Button)`
  width: 160px;
`;

const BodyCellStyled = styled(BodyCell)`
  &:first-child {
    font-weight: 400;
    width: auto;
  }
`;

const SubmittedHeadCell = styled(HeadCell)`
  width: 150px;
`;

const SimplePaginatorStyled = styled(SimplePaginator)`
  margin-top: 30px;
`;

const LinkStyled = styled(Link)`
  display: inline-flex;
`;

const PageHeadCell = styled(HeadCell)`
  width: 200px;
`;

export const FeedbackPollResponseIndexPage: React.SFC<{
  questions: FeedbackPollQuestion[];
  responses: FeedbackPollResponse[];
  pageInfo: {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
  };
  projectId: string;
  networkStatus: NetworkStatus;
  refetch(): void;
  loadNextPage(): void;
  loadPreviousPage(): void;
}> = ({
  projectId,
  questions,
  responses,
  pageInfo,
  networkStatus,
  refetch,
  loadNextPage,
  loadPreviousPage
}) => (
  <FeedbackPollIndexPageStyled>
    <TableStyled>
      <thead>
        <Row hover={false}>
          <PageHeadCell>Page</PageHeadCell>
          {questions.map(q => (
            <HeadCell key={q.id}>{q.title}</HeadCell>
          ))}
          <SubmittedHeadCell>Submitted At</SubmittedHeadCell>
          <ActionHeadCell>
            <TableRefreshIcon
              isRefreshing={networkStatus === NetworkStatus.refetch}
              onClick={e => {
                refetch();
              }}
            />
          </ActionHeadCell>
        </Row>
      </thead>
      <tbody>
        {responses.length === 0 && (
          <Row hover={false}>
            <TableEmptyCell>No responses found.</TableEmptyCell>
          </Row>
        )}
        {responses.map(response => (
          <Row hover={false} key={response.id}>
            <BodyCellStyled canContainLongText={true}>
              {response.pageUrl}
            </BodyCellStyled>
            {questions.map(question => (
              <BodyCellStyled canContainLongText={true} key={question.id}>
                {
                  (
                    response.answers.find(
                      ({ question: { id } }) => id === question.id
                    ) || { content: "" }
                  ).content
                }
              </BodyCellStyled>
            ))}
            <BodyCellWithImage
              image={CalendarIcon}
              style={{ whiteSpace: "nowrap" }}
            >
              {moment(response.createdAt).format(DEFAULT_DATE_FORMAT)}
              <SecondTextLine>
                {moment(response.createdAt).format(DEFAULT_TIME_FORMAT)}
              </SecondTextLine>
            </BodyCellWithImage>
            <BodyCellStyled>
              <ActionsWrapper>
                {response.sessionRecording && (
                  <LinkStyled
                    to={{
                      pathname: paths.sessionRecordingPath({
                        sessionRecordingId: response.sessionRecording.id,
                        projectId
                      }),
                      state: {
                        previousPath: `${window.location.pathname}${
                          window.location.search
                        }`
                      }
                    }}
                  >
                    <ActionButton action="secondary" size="small">
                      View Recording
                    </ActionButton>
                  </LinkStyled>
                )}
                <ContextDropdown>
                  <DeleteFeedbackPollResponse
                    feedbackPollResponseId={response.id}
                    afterDelete={refetch}
                  >
                    {deleteFeedbackPoll => (
                      <ContextActionItem onClick={deleteFeedbackPoll}>
                        Delete
                      </ContextActionItem>
                    )}
                  </DeleteFeedbackPollResponse>
                </ContextDropdown>
              </ActionsWrapper>
            </BodyCellStyled>
          </Row>
        ))}
      </tbody>
    </TableStyled>
    <SimplePaginatorStyled
      onNextPageClick={loadNextPage}
      onPreviousPageClick={loadPreviousPage}
      {...pageInfo}
    />
  </FeedbackPollIndexPageStyled>
);
