import memoize from "memoize-one";
import React from "react";
import { DelayedPlayerController } from "session-player/DelayedPlayerController";
import { SessionEvent, SessionEventType } from "session-player/eventTypes";
import {
  FrameRenderedEvent,
  NewFrameEvent,
  PlayingState,
  PlayingStateName
} from "session-player/playback/PlaybackManager";
import { Frame } from "session-player/playback/types";
import { SessionRecording, Throttles } from "session-player/types";
import styled from "styled-components/macro";
import { ControlBar } from "testly-web/components/Player/ControlBar/ControlBar";
import { FullscreenController } from "testly-web/components/Player/Fullscreen/FullscreenController";
import {
  Layer,
  PlayerScreen
} from "testly-web/components/Player/PlayerScreen/PlayerScreen";
import { ProgressBar } from "testly-web/components/Player/ProgressBar/ProgressBar";
import { ViewableEvent } from "testly-web/components/Player/ProgressBar/types";
import { UserPlayerSettings } from "session-player/UserPlayerSettings";

export type SetIframeRef = (ref: HTMLIFrameElement) => void;
export type NewFrameEventListener = (event: NewFrameEvent) => void;
export type OnNewFrame = (listener: NewFrameEventListener) => void;

export interface ReactPlayerState {
  frameRenderedEvent: FrameRenderedEvent | undefined;
  session: SessionRecording;
}

interface PlayerContainerProps {
  setIframeRef: SetIframeRef;
  layers: Layer[];
  playerController: DelayedPlayerController;
  frame: Frame | undefined;
  playingState: PlayingState | undefined;
  eventsOnTimeline: SessionEvent[];
  timelineDuration: number;
  throttles: Throttles;
  userPlayerSettings: UserPlayerSettings;
}

const PlayerStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export class Player extends React.Component<PlayerContainerProps> {
  private filterViewableEvents = memoize(
    (events: SessionEvent[]) =>
      events.filter(
        event =>
          event.eventType === SessionEventType.MouseClicked ||
          event.eventType === SessionEventType.PageVisited
      ) as ViewableEvent[]
  );

  public render() {
    const { frame, playingState, userPlayerSettings } = this.props;
    const viewableEvents = this.filterViewableEvents(
      this.props.eventsOnTimeline
    );
    const currentPlayTime = (playingState && playingState.playTime) || 0;
    const playingStateName = playingState && playingState.name;
    const timelineDuration = this.props.timelineDuration;

    return (
      <FullscreenController>
        {(isFullscreen, onFullscreenClick, containerRef) => (
          <PlayerStyled
            ref={containerRef as React.RefObject<HTMLDivElement> & string}
          >
            <PlayerScreen
              frame={frame}
              setIframeRef={this.props.setIframeRef}
              layers={this.props.layers}
              throttles={this.props.throttles}
            />
            <ProgressBar
              timelineDuration={this.props.timelineDuration}
              currentPlayTime={currentPlayTime}
              play={() => {
                this.props.playerController.play();
              }}
              seek={(to: number) => {
                this.props.playerController.seek(to);
              }}
              seekPercent={(percent: number) => {
                this.props.playerController.seekPercent(percent);
              }}
              vieawableEvents={viewableEvents}
              playingStateName={playingStateName}
            />
            <ControlBar
              currentPlayTime={Math.trunc(currentPlayTime / 1000)}
              timelineDuration={Math.trunc(timelineDuration / 1000)}
              isPlaying={playingStateName === PlayingStateName.Playing}
              isFullscreen={isFullscreen}
              onControlClick={this.handleControlClick}
              onFullsreenClick={onFullscreenClick}
              playingSpeed={userPlayerSettings.getPlayingSpeed()}
              setPlayingSpeed={userPlayerSettings.setPlayingSpeed}
              skipPauses={userPlayerSettings.getSkipPauses()}
              setPauseSkipping={userPlayerSettings.setSkipPauses}
            />
          </PlayerStyled>
        )}
      </FullscreenController>
    );
  }

  private handleControlClick = () => {
    const playerInstance = this.props.playerController;
    const currentState = playerInstance.state();

    if (!currentState) {
      return;
    }

    if (currentState.name === PlayingStateName.Paused) {
      playerInstance.play();
    }
    if (currentState.name === PlayingStateName.Playing) {
      playerInstance.pause();
    }
    if (currentState.name === PlayingStateName.Finished) {
      playerInstance.seek(0);
      playerInstance.play();
    }
  };
}
