import React from "react";

export const RemoveIcon: React.SFC<{
  className?: string;
  onClick?(): void;
}> = ({ className, onClick }) => (
  // tslint:disable:max-line-length
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="13"
    viewBox="0 0 11 13"
    className={className}
    onClick={onClick}
  >
    <path
      fill="#A7A9AE"
      d="M3.9 0l-.65.65H0v1.3h10.4V.65H7.15L6.5 0H3.9zM.65 3.25v8.45c0 .715.585 1.3 1.3 1.3h6.5c.715 0 1.3-.585 1.3-1.3V3.25H.65zm1.95 1.3h1.3v7.15H2.6V4.55zm3.9 0h1.3v7.15H6.5V4.55z"
      opacity="0.505"
    />
  </svg>
);
