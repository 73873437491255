import React from "react";
import { RouteComponentProps } from "react-router";
import { SetCurrentProjectId } from "testly-web/components/CurrentProjectId/SetCurrentProjectId";
import { loadingRender } from "testly-web/components/loadingRender";
import { SetNavbar } from "testly-web/components/Navbar/SetNavbar";
import { paths } from "testly-web/paths";
import { GetDataForFeedbackPollIndexPageComponent } from "testly-web/queries";
import { FeedbackPollIndexPage } from "./FeedbackPollIndexPage";

export const FeedbackPollIndexPageContainer: React.SFC<
  RouteComponentProps<{
    projectId: string;
  }>
> = ({
  match: {
    params: { projectId }
  }
}) => (
  <>
    <SetCurrentProjectId id={projectId} />
    <SetNavbar
      header="Feedback Polls"
      backPath={paths.projectPath({ projectId })}
    />
    <GetDataForFeedbackPollIndexPageComponent
      variables={{ projectId }}
      notifyOnNetworkStatusChange={true}
      fetchPolicy="network-only"
    >
      {loadingRender(
        "currentUser.project.feedbackPolls",
        ({ data, refetch, networkStatus }) => (
          <FeedbackPollIndexPage
            feedbackPolls={data!.currentUser!.project!.feedbackPolls.nodes}
            projectId={projectId}
            networkStatus={networkStatus}
            refetch={refetch}
          />
        )
      )}
    </GetDataForFeedbackPollIndexPageComponent>
  </>
);
