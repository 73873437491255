import { IdentifiableVNode, NodeId, NodePath } from "./types";

export class NodeMap {
  private links: Map<NodeId, NodePath>;

  constructor(dom: IdentifiableVNode) {
    this.links = new Map<NodeId, NodePath>();

    this.addNode(dom, []);
  }

  public getNodeLink(id: NodeId): NodePath | undefined {
    return this.links.get(id);
  }

  public fetchNodeLink(id: NodeId): NodePath {
    const link = this.links.get(id);

    if (link === undefined) {
      throw new Error(`Couldn't find link with id ${id}`);
    }

    return link;
  }

  public addNode(node: IdentifiableVNode, parentPath: NodePath): void {
    if (parentPath.length === 0 && this.links.size > 0) {
      throw new Error("Node without parent can be set only on empty NodeMap");
    }

    this.links.set(node.data.nodeId, [...parentPath, node.data.nodeId]);
    if (node.children) {
      node.children.forEach(childNode => {
        this.addNode(childNode, [...parentPath, node.data.nodeId]);
      });
    }
  }

  public removeNode(id: NodeId): void {
    this.links.delete(id);
  }
}
