import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { SetCurrentProjectId } from "testly-web/components/CurrentProjectId/SetCurrentProjectId";
import { loadingRender } from "testly-web/components/loadingRender";
import { SetNavbar } from "testly-web/components/Navbar/SetNavbar";
import { DoneStepsType } from "testly-web/components/split-test-form/ProgressBar";
import { SplitTestFormStep } from "testly-web/components/split-test-form/schemas";
import { paths } from "testly-web/paths";
import {
  GetDataForSplitTestEditPageComponent,
  SplitTestStatus
} from "testly-web/queries";
import { SplitTestEditPage } from "./SplitTestEditPage";

export const SplitTestEditPageContainer: React.SFC<
  RouteComponentProps<
    {
      projectId: string;
      splitTestId: string;
      step: SplitTestFormStep;
    },
    {},
    {
      previousPath?: string;
    }
  >
> = ({
  history: { replace },
  match: {
    params: { projectId, splitTestId, step }
  },
  location: { state }
}) => {
  const previousPath =
    state && state.previousPath
      ? state.previousPath
      : paths.projectPath({ projectId });

  return (
    <>
      <SetCurrentProjectId id={projectId} />
      <SetNavbar header="Split Test Wizard" backPath={previousPath} />
      <GetDataForSplitTestEditPageComponent
        variables={{ projectId, splitTestId }}
      >
        {loadingRender("currentUser.project.splitTest", ({ data }) => {
          const splitTest = data!.currentUser!.project!.splitTest!;
          const pageTypes = data!.pageTypes;
          const pageCategories = data!.pageCategories;

          const doneSteps: DoneStepsType = {
            [SplitTestFormStep.Details]: true,
            [SplitTestFormStep.Variations]: splitTest.variations.length !== 0,
            [SplitTestFormStep.Goals]: splitTest.goals.length !== 0,
            [SplitTestFormStep.CodeInstallationCheck]:
              splitTest.status === SplitTestStatus.Active,
            [SplitTestFormStep.Settings]:
              splitTest.status !== SplitTestStatus.Draft
          };

          return (
            <SplitTestEditPage
              doneSteps={doneSteps}
              currentStep={step}
              splitTest={splitTest}
              projectId={projectId}
              pageTypes={pageTypes}
              testIdeas={data!.testIdeas}
              pageCategories={pageCategories}
              replacePath={path =>
                replace({ pathname: path, state: { previousPath } })
              }
            />
          );
        })}
      </GetDataForSplitTestEditPageComponent>
    </>
  );
};
