import { GoalParams, GoalType, PageMatcherMatchType } from "testly-web/queries";
import { initialGoalUrl, PathGoalForm } from "./pathGoal";

export const convertToDto = (values: PathGoalForm): GoalParams => ({
  id: values.id,
  name: values.name,
  path: [
    ...[
      ...(values.pathBuilderToggled ? values.path : []),
      values.finalGoalUrl
    ].map((step, i) => ({
      ...step,
      index: i
    }))
  ],
  type: GoalType.Path,
  value: values.value
});

interface StepDTO {
  url: string;
  matchType: PageMatcherMatchType;
}

interface GoalDTO {
  id: string;
  name: string;
  path: StepDTO[];
  value: string;
}

export const mapStep = (step: StepDTO) => ({
  url: step.url,
  matchType: step.matchType
});

export const convertFromDto = (goal: GoalDTO): PathGoalForm => ({
  id: goal.id,
  name: goal.name,
  finalGoalUrl: mapStep(goal.path[goal.path.length - 1]),
  pathBuilderToggled: goal.path.length > 1,
  value: goal.value,
  path:
    goal.path.length === 1
      ? [initialGoalUrl()]
      : goal.path.slice(0, -1).map(mapStep)
});
