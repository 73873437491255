import React from "react";
import styled from "styled-components/macro";
import logout from "testly-web/icons/logout.svg";
// import upgradePlan from "testly-web/icons/upgrade-plan.svg";
import { logoutUser } from "testly-web/services/accounts";
import { ToggleAccountSettings } from "../../AccoutSettings/ToggleAccountSettings";
// import progress from "./progress.png";

const LOGIN_URL = process.env.REACT_APP_LOGIN_URL as string;

// const StyledAccountDropdown = styled.div`
//   padding: 20px 18px;
//   border-bottom: solid 1px #d5d6d9;
// `;
//
// const Visitors = styled.div`
//   font-size: 14px;
//   color: #a7a9ae;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   width: 100%;
//   margin-bottom: 8px;
//   color: #393839;
// `;
//
// const VisitorThisMonthCaption = styled.span`
//   color: #393839;
// `;
//
// const VisitorsStatus = styled.div`
//   display: inline-block;
//   width: 220px;
//   height: 14px;
//   border-radius: 7px;
//   background-color: #ecedee;
//   overflow: hidden;
//   margin-bottom: 10px;
// `;
//
// // eslint-disable-next-line no-unexpected-multiline
// const VisitorsStatusBar = styled.div<{
//   visitorsMonthlyLimit: number;
//   visitorsThisMonth: number;
// }>`
//   display: block;
//   background-image: url("${progress}");
//   height: 14px;
//   width: ${({ visitorsMonthlyLimit, visitorsThisMonth }) =>
//     visitorsMonthlyLimit !== 0
//       ? (visitorsThisMonth * 100) / visitorsMonthlyLimit
//       : 0}
// `;

// const UpgradeAccount = styled.div`
//   text-align: left;
//   font-size: 16px;
//   color: #19b5fe;
//
//   &:before {
//     content: " ";
//     width: 23px;
//     height: 22px;
//     display: inline-block;
//     margin-right: 9px;
//     vertical-align: text-bottom;
//     background-image: url("${upgradePlan}");
//   }
//
//   &:hover {
//     opacity: 0.6;
//   }
// `;

const Actions = styled.div`
  padding: 20px 18px;
  font-size: 16px;
  color: #393839;
  text-align: left;
`;

const ActionItem = styled.div`
  margin-bottom: 20px;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
`;

const LogoutActionItem = styled(ActionItem)`
  margin-bottom: 0px;
  cursor: pointer;

  &:after {
    content: " ";
    margin-left: 9px;
    vertical-align: text-bottom;
    width: 17px;
    height: 17px;
    display: inline-block;
    background-image: url("${logout}");
  }
`;

// <StyledAccountDropdown>
//   <Visitors>
//     Monthly Visitors
//     <div>
//       <VisitorThisMonthCaption>
//         {visitorsThisMonth.toLocaleString()}
//       </VisitorThisMonthCaption>
//       /{visitorsMonthlyLimit.toLocaleString()}
//     </div>
//   </Visitors>
//   <VisitorsStatus>
//     <VisitorsStatusBar
//       visitorsMonthlyLimit={visitorsMonthlyLimit}
//       visitorsThisMonth={visitorsThisMonth}
//     />
//   </VisitorsStatus>
//   <UpgradeAccount>Upgrade Account</UpgradeAccount>
// </StyledAccountDropdown>
export const UserDropdown: React.SFC<{
  visitorsMonthlyLimit: number;
  visitorsThisMonth: number;
}> = () => (
  <>
    <Actions>
      <ToggleAccountSettings active={true}>
        <ActionItem>Account Settings</ActionItem>
      </ToggleAccountSettings>
      <LogoutActionItem
        onClick={async () => {
          await logoutUser();
          window.location.replace(LOGIN_URL);
        }}
      >
        Logout
      </LogoutActionItem>
    </Actions>
  </>
);
