import {
  FieldArrayRenderProps,
  FormikErrors,
  FormikProps,
  getIn
} from "formik";
import React from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult
} from "react-beautiful-dnd";
import styled from "styled-components/macro";
import { SimplePlusIcon } from "testly-web/icons/SimplePlusIcon";
import uuidv1 from "uuid/v1";
import { TextQuestionInput } from "./question-inputs/TextQuestionInput";
import { FeedbackFormValues, QuestionValues } from "./schema";
import { QuestionType } from "./types";

const QuestionInputs = styled.div``;
const TextQuestionInputStyled = styled(TextQuestionInput)`
  margin-bottom: 20px;
`;

const AddQuestion = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 16px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);
  border: dashed 1px #dcdae0;
  cursor: pointer;

  margin-top: 20px;

  letter-spacing: 0.4px;
  font-size: 12px;
  font-weight: 500;
  color: #a7a9ae;

  svg {
    margin-right: 12px;
  }

  outline: none;

  background-position: center;
  transition: background 0.8s;

  &:hover {
    background: #fafafa;
  }
`;

export const QuestionsForm: React.SFC<{
  values: FeedbackFormValues;
  arrayHelpers: FieldArrayRenderProps;
  setFieldValue: FormikProps<FeedbackFormValues>["setFieldValue"];
  errors: FormikErrors<FeedbackFormValues>;
}> = ({ values, arrayHelpers, setFieldValue, errors }) => {
  const onQuestionTypeSelect = (
    i: number,
    selectedQuestionType: QuestionType
  ) => {
    setFieldValue(`questions.${i}.type`, selectedQuestionType);
  };

  const onValueChange = (i: number, newValue: string) => {
    setFieldValue(`questions.${i}.title`, newValue);
  };

  const onDeleteClick = (i: number) => {
    arrayHelpers.remove(i);
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    arrayHelpers.move(result.source.index, result.destination.index);
  };

  const onAddQuestionClick = () => {
    const question: QuestionValues = {
      type: QuestionType.LongTextQuestion,
      id: uuidv1(),
      title: "",
      index: values.questions.length,
      isNew: true
    };

    arrayHelpers.push(question);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(droppableProvided, droppableSnapshot) => (
          <QuestionInputs
            ref={droppableProvided.innerRef}
            {...droppableProvided.droppableProps}
          >
            {values.questions.map(({ type, id, title, isNew }, i) => (
              <Draggable key={id} draggableId={id} index={i}>
                {(draggableProvided, draggableSnapshot) => (
                  <>
                    <TextQuestionInputStyled
                      ref={draggableProvided.innerRef}
                      orderNumber={i + 1}
                      selectedQuestionType={type}
                      onQuestionTypeSelect={newQuestionType =>
                        onQuestionTypeSelect(i, newQuestionType)
                      }
                      value={title}
                      onValueChange={newValue => onValueChange(i, newValue)}
                      onDeleteClick={() => onDeleteClick(i)}
                      deletable={values.questions.length > 1}
                      error={getIn(errors, `questions.${i}.title`)}
                      ableToChangeType={isNew}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                    />
                  </>
                )}
              </Draggable>
            ))}

            {droppableProvided.placeholder}
          </QuestionInputs>
        )}
      </Droppable>

      <AddQuestion role="button" tabIndex={0} onClick={onAddQuestionClick}>
        <SimplePlusIcon />
        Add Question here
      </AddQuestion>
    </DragDropContext>
  );
};
