import React from "react";

export const ProvingIcon = () => (
  // tslint:disable:max-line-length
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34"
    height="32"
    viewBox="0 0 34 32"
  >
    <path
      fillRule="nonzero"
      d="M3.401 0C1.73 0 .376 1.42.376 3.172v19.031c0 1.753 1.354 3.172 3.025 3.172H13.99v3.172h-3.025v3.172h12.101v-3.172H20.04v-3.172h10.588c1.671 0 3.025-1.42 3.025-3.172V3.172C33.653 1.419 32.3 0 30.628 0H3.401zm0 3.172h27.227v19.031H3.401V3.172zm19.35 2.847l-7.25 7.598-2.679-2.81-2.139 2.243 4.819 5.052 9.386-9.844-2.136-2.24z"
    />
  </svg>
);
