import {
  FormikActions,
  FormikHandlers,
  FormikState,
  FormikValues,
  getIn
} from "formik";
import { uniqueId, upperFirst } from "lodash";
import React from "react";
import styled from "styled-components/macro";
import { Error, Input, Label } from ".";
import { NumberFormatStyled, Textarea } from "./Input";
import { PercentInput } from "./NumberInput/NumberInput";
import { Select } from "./Select";

// tslint:disable:max-classes-per-file

interface InputProps<Values extends FormikValues>
  extends FormikActions<Values> {
  label?: string;
  name: string;
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  values: FormikState<Values>["values"];
  errors: FormikState<Values>["errors"];
  touched: FormikState<Values>["touched"];
}

const InputFieldStyled = styled.div`
  margin-bottom: 15px;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Description = styled.div`
  box-sizing: border-box;
  display: block;
  width: 100%;
  height: 20px;
  font-family: Roboto;
  font-size: 12px;
  color: #a7a9ae;
  margin-top: 6px;
`;

const LabelOptional = styled.span`
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  color: #a7a9ae;
  text-transform: lowercase;
`;

export type InputFieldProps<Values> = InputProps<Values> & {
  readOnly?: boolean;
  className?: string;
  description?: string;
  placeholder?: string;
  optional?: boolean;
  afterInputRender?(): React.ReactNode;
};

class Field<Values extends FormikValues> extends React.Component<
  InputFieldProps<Values> & {
    inputRender(id: string, withError: boolean): React.ReactNode;
  }
> {
  public render() {
    const {
      name,
      label,
      errors,
      touched,
      readOnly,
      className,
      afterInputRender,
      description,
      inputRender,
      optional
    } = this.props;
    const id = uniqueId();

    return (
      <InputFieldStyled className={className}>
        {label && (
          <Label htmlFor={id}>
            {upperFirst(label)}
            {optional && <LabelOptional> (optional)</LabelOptional>}
          </Label>
        )}

        <InputWrapper>
          {inputRender(
            id,
            !readOnly && getIn(errors, name) && getIn(touched, name)
              ? true
              : false
          )}
          {afterInputRender && afterInputRender()}
        </InputWrapper>

        {!readOnly && getIn(errors, name) && getIn(touched, name) && (
          <Error>{getIn(errors, name) as string}</Error>
        )}

        {description && <Description>{description}</Description>}
      </InputFieldStyled>
    );
  }
}

export class TextField<Values extends FormikValues> extends React.Component<
  InputFieldProps<Values> & {
    component?: "input" | "textarea";
    inputType?: string;
    disabled?: boolean;
  }
> {
  public render() {
    const {
      name,
      handleChange,
      handleBlur,
      values,
      readOnly,
      placeholder,
      component,
      inputType,
      disabled
    } = this.props;

    return (
      <Field<Values>
        inputRender={(id, withError) => {
          const inputProps = {
            onChange: handleChange,
            onBlur: handleBlur,
            name,
            value: getIn(values, name) as string,
            withError,
            readOnly,
            placeholder,
            id,
            as: component,
            disabled
          };

          return component === "textarea" ? (
            <Textarea {...inputProps} />
          ) : (
            <Input type={inputType || "text"} {...inputProps} />
          );
        }}
        {...this.props}
      />
    );
  }
}

export class SelectField<
  Values extends FormikValues,
  T extends { label: string; value: string }
> extends React.Component<
  InputFieldProps<Values> & {
    options: T[];
  }
> {
  public render() {
    const { options, name, handleBlur, setFieldValue, values } = this.props;

    const optionValue = options.find(
      ({ value }) => getIn(values, name) === value
    );

    return (
      <Field<Values>
        inputRender={(id, withError) => (
          <Select<T>
            options={options}
            name={name}
            onChange={(option: T | T[] | undefined | null) => {
              if (option && !Array.isArray(option)) {
                setFieldValue(name, option.value);
              } else {
                console.error("SelectField received unknown option!");
              }
            }}
            value={optionValue}
            onBlur={handleBlur}
            withError={withError}
            id={id}
          />
        )}
        {...this.props}
      />
    );
  }
}

const PercentFieldStyled = (styled(Field)`
  ${InputWrapper} {
    justify-content: flex-start;
  }
` as unknown) as new <T>() => Field<T>;

export class PercentField<Values extends FormikValues> extends React.Component<
  InputFieldProps<Values>
> {
  public render() {
    const { name, handleBlur, setFieldValue, values } = this.props;

    const optionValue: number = getIn(values, name);

    return (
      <PercentFieldStyled<Values>
        inputRender={(id, withError) => (
          <PercentInput
            name={name}
            onChange={percentValue => {
              setFieldValue(name, percentValue);
            }}
            value={optionValue}
            onBlur={handleBlur}
            withError={withError}
            id={id}
          />
        )}
        {...this.props}
      />
    );
  }
}

export class NumberField<Values extends FormikValues> extends React.Component<
  InputFieldProps<Values> & {
    suffix?: string;
    prefix?: string;
    keepStringValue?: string;
  }
> {
  public render() {
    const {
      name,
      handleBlur,
      values,
      readOnly,
      placeholder,
      setFieldValue,
      suffix,
      prefix,
      keepStringValue
    } = this.props;

    return (
      <Field<Values>
        inputRender={(id, withError) => {
          const inputProps = {
            onBlur: handleBlur,
            name,
            value: getIn(values, name) as string,
            withError,
            readOnly,
            placeholder,
            id,
            suffix,
            prefix
          };

          return (
            <NumberFormatStyled
              {...inputProps}
              onValueChange={({ floatValue, value }) => {
                if (keepStringValue) {
                  setFieldValue(name, value);
                } else {
                  setFieldValue(name, floatValue);
                }
              }}
            />
          );
        }}
        {...this.props}
      />
    );
  }
}
