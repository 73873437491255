import { FrameProducer } from "session-player/playback/FrameProducer";
import { Frame } from "session-player/playback/types";
import { TimelineManager } from "./TimelineManager";
import { requestFrameWithTimestamp } from "./requestFrameWithTimestamp";
import { PlaybackStateManager } from "./PlaybackStateManager";
import { UserPlayerSettings } from "session-player/UserPlayerSettings";

export enum PlaybackEventType {
  NewFrame = "newFrame",
  StateChanged = "stateChanged",
  FrameRendered = "frameRendered"
}

export interface NewFrameEvent {
  eventType: PlaybackEventType.NewFrame;
  frame: Frame;
  previousPlayingState: PlayingState;
  newPlayingState: PlayingState;
}

export interface FrameRenderedEvent {
  eventType: PlaybackEventType.FrameRendered;
  frame: Frame;
  newPlayingState: PlayingState;
}

export type PlaybackEvent = NewFrameEvent | FrameRenderedEvent;

export type BrokerEventListener = (event: PlaybackEvent) => Promise<void>;
export type OnErrorListener = (e: Error) => void;

export enum PlayingStateName {
  Playing = "playing",
  Paused = "paused",
  Finished = "finished",
  Seeking = "seeking",
  // When Seeking happens we don't get lastMouseClick in frame producer
  // But with SeekingPlay we will get it.
  // We don't want to get mouseClicks on just Seeking when,
  // for example, user just clicks on timeline. But we want to return
  // click when user click on the icon of the click.
  SeekingPlay = "seekingPlay",
  TimelineChange = "timelineChange"
}

export interface PlayingState {
  name: PlayingStateName;
  playTime: number;
}

// NOTE: the order of listeners is very important
// At first dom snapshot listener should go
export class PlaybackManager {
  public stateManager: PlaybackStateManager;

  private changesListeners: BrokerEventListener[] = [];
  private onErrorListeners: OnErrorListener[] = [];
  private timelineManager: TimelineManager;
  private frameProducer: FrameProducer;
  private stopped: boolean = false;
  private userPlayerSettings: UserPlayerSettings;

  constructor(
    timelineManager: TimelineManager,
    frameProducer: FrameProducer,
    userPlayerSettings: UserPlayerSettings
  ) {
    this.timelineManager = timelineManager;
    this.frameProducer = frameProducer;
    this.stateManager = new PlaybackStateManager(this.timelineManager);
    this.userPlayerSettings = userPlayerSettings;

    timelineManager.onTimelineChange(() => {
      this.stateManager.addNextState(() => ({
        playTime: 0,
        name: PlayingStateName.TimelineChange
      }));
    });

    requestFrameWithTimestamp(this.nextRenderFrame, () => this.stopped);
  }

  public appendListener(changesListener: BrokerEventListener): void {
    this.changesListeners.push(changesListener);
  }

  public appendOnErrorListener(errorListener: OnErrorListener): void {
    this.onErrorListeners.push(errorListener);
  }

  public play(): void {
    this.stateManager.addNextState(currentState => ({
      playTime: currentState.playTime,
      name: PlayingStateName.Playing
    }));
  }

  public pause(): void {
    this.stateManager.addNextState(currentState => ({
      playTime: currentState.playTime,
      name: PlayingStateName.Paused
    }));
  }

  public seek(playTime: number): void {
    this.stateManager.addNextState(() => ({
      playTime,
      name: PlayingStateName.Seeking
    }));
  }

  public stop(): void {
    this.stopped = true;
  }

  // NOTE: this cycle always runs, even if player is paused
  private nextRenderFrame = async (
    previousTimestamp: number,
    currentTimestamp: number
  ) => {
    const [previousState, newState] = this.stateManager.nextState(
      previousTimestamp,
      currentTimestamp,
      this.userPlayerSettings.getPlayingSpeed(),
      this.userPlayerSettings.getSkipPauses()
    );

    const frame = this.frameProducer.produce(newState, previousState);

    if (frame) {
      await this.notifyListeners({
        frame,
        newPlayingState: newState,
        previousPlayingState: previousState,
        eventType: PlaybackEventType.NewFrame
      });

      await this.notifyAboutRenderedFrame(newState, frame);
    }
  };

  private async notifyListeners(event: PlaybackEvent) {
    try {
      for (const listener of this.changesListeners) {
        await listener(event);
      }
    } catch (e) {
      this.stopped = true;
      for (const listener of this.onErrorListeners) {
        listener(e as Error);
      }
    }
  }

  private async notifyAboutRenderedFrame(newState: PlayingState, frame: Frame) {
    await this.notifyListeners({
      newPlayingState: newState,
      frame,
      eventType: PlaybackEventType.FrameRendered
    });
  }
}
