import React, { CSSProperties } from "react";
import ReactSelect, { components } from "react-select";
import { IndicatorProps } from "react-select/lib/components/indicators";
import { Props } from "react-select/lib/Select";
import { StylesConfig } from "react-select/lib/styles";
import styled from "styled-components/macro";
import { border, errorBorder, focusBorder } from "./Input";

// tslint:disable:max-classes-per-file

const DropdownIndicatorStyled = styled.div`
  width: 0;
  height: 0;
  padding: 0 !important;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #393839;
  margin-right: 16px;
`;

class DropdownIndicator<T> extends React.Component<IndicatorProps<T>> {
  public render() {
    return components.DropdownIndicator && <DropdownIndicatorStyled />;
  }
}

const StyledSelect = (styled(ReactSelect)`
  width: 100%;
  position: relative;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #393839;
  height: 40px;
` as React.ComponentType) as new <T>() => ReactSelect<T>;

const controlBorder = (isFocused: boolean, withError: boolean | undefined) => {
  if (withError) {
    return errorBorder;
  } else if (isFocused) {
    return focusBorder;
  } else {
    return border;
  }
};

export interface Option {
  label: string;
  value: string;
}

export class Select<T extends Option> extends React.Component<
  Props<T> & {
    withError?: boolean;
    controlStyle?: CSSProperties;
  }
> {
  public render() {
    const { controlStyle } = this.props;

    const customStyles: StylesConfig = {
      option: (base, state: { isSelected: boolean }) => ({
        ...base,
        cursor: "pointer",
        backgroundColor: state.isSelected ? "#00ac69" : base.backgroundColor
      }),
      control: (base, state: { isFocused: boolean }) => ({
        cursor: "pointer",
        display: "flex",
        boxSizing: "border-box",
        width: "100%",
        borderRadius: 2,
        backgroundColor: "#ffffff",
        border: controlBorder(state.isFocused, this.props.withError),
        height: "100%",
        ...(controlStyle || {})
      }),
      singleValue: (base, state: { isDisabled: boolean }) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = "opacity 300ms";

        return { ...base, opacity, transition };
      },
      indicatorSeparator: () => ({
        display: "none"
      })
    };

    return (
      <StyledSelect<T>
        maxMenuHeight={200}
        styles={customStyles}
        components={{ DropdownIndicator }}
        placeholder="Select ..."
        {...this.props}
      />
    );
  }
}
