import { OnFilterChange, OptionType } from "./types";

export const handleInFilterChange = <T extends {}>(
  filter: T,
  filterKey: keyof (T),
  onFilterChange: OnFilterChange<T>
) => {
  return (option: OptionType) => {
    const filterValues = (filter[filterKey] || []) as string[];
    const selectedValue = option.value;

    const valuesIn = (() => {
      if (filterValues.includes(selectedValue)) {
        return filterValues.filter(d => d !== selectedValue);
      } else {
        return [...filterValues, selectedValue];
      }
    })();

    onFilterChange({
      ...filter,
      [filterKey]: valuesIn.length === 0 ? null : valuesIn
    });
  };
};
