import { NetworkStatus } from "apollo-client";
import H from "history";
import Honeybadger from "honeybadger-js";
import React from "react";
import { RouteComponentProps } from "react-router";
import { toast } from "react-toastify";
import { SetCurrentProjectId } from "testly-web/components/CurrentProjectId/SetCurrentProjectId";
import { loadingRender } from "testly-web/components/loadingRender";
import { SetNavbar } from "testly-web/components/Navbar/SetNavbar";
import { SessionRecordingPage } from "testly-web/pages/SessionRecordingPage/SessionRecordingPage";
import { paths } from "testly-web/paths";
import {
  GetSessionRecordingForRecordingPageComponent,
  GetSessionRecordingForRecordingPageSessionRecording
} from "testly-web/queries";
import { mapSessionRecording } from "./mappers/mapSessionRecording";

const sessionRecordingComponent = (
  sessionRecording: GetSessionRecordingForRecordingPageSessionRecording,
  projectId: string,
  history: H.History,
  refetch: () => Promise<void>,
  networkStatus: NetworkStatus
) => {
  const onError = (e: Error) => {
    Honeybadger.notify(e);
    console.error(e);
    toast.error(
      "An internal error of the player occurred. Our developers have already been notified"
    );
    history.goBack();
  };

  const mappedSessionRecordings = (() => {
    try {
      return mapSessionRecording(sessionRecording, {
        mouseMove: 100,
        scroll: 100
      });
    } catch (e) {
      onError(e as Error);

      return null;
    }
  })();

  return (
    mappedSessionRecordings && (
      <SessionRecordingPage
        sessionRecordingForPlayer={mappedSessionRecordings}
        sessionRecording={sessionRecording}
        nextSessionRecordingId={null}
        previousSessionRecordingId={null}
        projectId={projectId}
        onError={onError}
        pages={sessionRecording.pages}
        refetch={refetch}
        networkStatus={networkStatus}
      />
    )
  );
};

export const SessionRecordingPageContainer: React.SFC<
  RouteComponentProps<
    { projectId: string; sessionRecordingId: string },
    {},
    {
      previousPath?: string;
    }
  >
> = ({
  match: {
    params: { projectId, sessionRecordingId }
  },
  history,
  location: { state }
}) => {
  const backPath =
    state && state.previousPath
      ? state.previousPath
      : paths.projectPath({ projectId });

  return (
    <>
      <SetCurrentProjectId id={projectId} />
      <SetNavbar header="Session Recording" backPath={backPath} />
      <GetSessionRecordingForRecordingPageComponent
        variables={{ projectId, sessionRecordingId }}
        fetchPolicy="network-only"
        notifyOnNetworkStatusChange={true}
      >
        {loadingRender(
          "currentUser.project.sessionRecording",
          ({ data, refetch, networkStatus }) =>
            sessionRecordingComponent(
              data!.currentUser!.project!.sessionRecording!,
              projectId,
              history,
              async () => {
                await refetch();
              },
              networkStatus
            )
        )}
      </GetSessionRecordingForRecordingPageComponent>
    </>
  );
};
