import { darken } from "polished";
import React from "react";
import styled, { css } from "styled-components/macro";
import { RefreshIcon } from "testly-web/icons/RefreshIcon";
import { OrderDirection } from "testly-web/queries";
import { tableBgColor } from "./style-vars";

const SidePadding = 20;

export const Table = styled.table`
  table-layout: auto;
  background-color: ${tableBgColor};
  width: 100%;
  border-collapse: collapse;
`;

export const HeadCell = styled.th`
  font-family: Roboto;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 1px;
  color: #a7a9ae;
  text-transform: uppercase;
  text-align: left;

  box-sizing: border-box;
  height: 40px;
  padding: 0 ${SidePadding}px;
  padding-bottom: 8px;
  vertical-align: bottom;

  position: relative;

  white-space: nowrap;

  overflow: hidden;
  text-overflow: ellipsis;
`;

export type Direction = OrderDirection | undefined;

const directionChanger = (direction: Direction): OrderDirection => {
  switch (direction) {
    case undefined:
      return OrderDirection.Asc;
    case OrderDirection.Asc:
      return OrderDirection.Desc;
    case OrderDirection.Desc:
      return OrderDirection.Asc;
    default:
      return OrderDirection.Asc;
  }
};

const SorterArea = styled.a`
  display: inline-block;
  margin-left: 8px;
  height: 8px;
`;

// eslint-disable-next-line no-unexpected-multiline
const Sorter = styled.div<{
  orderDirection: OrderDirection;
  bothAreShown: boolean;
}>`
  border: 3px solid transparent;
  content: "";
  display: block;
  height: 0;
  width: 0;

  ${({ orderDirection }) =>
    orderDirection === OrderDirection.Asc
      ? css`
          border-bottom-color: #a7a9ae;
          margin-top: -3px;
        `
      : css`
          border-top-color: #a7a9ae;
          margin-top: 1px;
        `}

  ${({ bothAreShown, orderDirection }) =>
    !bothAreShown &&
    orderDirection === OrderDirection.Asc &&
    css`
      margin-top: -1px;
    `}

    ${({ bothAreShown, orderDirection }) =>
      !bothAreShown &&
      orderDirection === OrderDirection.Desc &&
      css`
        margin-top: 3px;
      `}
`;

const SortableHeadCellStyled = styled(HeadCell)`
  cursor: pointer;
`;

export const SortableHeadCell: React.SFC<{
  direction: Direction;
  onDirectionChange: (newDirection: OrderDirection) => void;
}> = ({ direction, onDirectionChange, children }) => (
  <SortableHeadCellStyled
    onClick={() => onDirectionChange(directionChanger(direction))}
    role="button"
  >
    {children}
    <SorterArea>
      {[OrderDirection.Asc, undefined].includes(direction) && (
        <Sorter
          orderDirection={OrderDirection.Asc}
          bothAreShown={direction === undefined}
        />
      )}
      {[OrderDirection.Desc, undefined].includes(direction) && (
        <Sorter
          orderDirection={OrderDirection.Desc}
          bothAreShown={direction === undefined}
        />
      )}
    </SorterArea>
  </SortableHeadCellStyled>
);

// eslint-disable-next-line no-unexpected-multiline
export const BodyCell = styled.td<{
  canContainLongText?: boolean;
}>`
  font-family: Roboto;
  font-size: 14px;
  color: #393839;
  vertical-align: top;
  line-height: 16px;

  box-sizing: border-box;
  padding: 20px ${SidePadding}px;

  ${({ canContainLongText }) =>
    canContainLongText
      ? css`
          word-break: break-word;
        `
      : css`
          white-space: nowrap;
        `};

  &:first-child {
    font-weight: 500;
    width: 20%;
  }
`;

export const SecondTextLine = styled.div`
  font-family: Roboto;
  font-size: 12px;
  color: #a7a9ae;
  margin-top: 7px;
`;

export const TableRefreshIcon = styled(RefreshIcon)`
  display: block;
  float: right;
  position: static;
`;

export const Row = styled.tr<{ hover?: boolean }>`
  border-top: solid 1px #f0f1f1;
  border-bottom: solid 1px #f0f1f1;
  ${({ hover }) =>
    hover !== false &&
    css`
      transition: background-color 0.1s linear;
      &:hover {
        background-color: ${darken(0.02, tableBgColor)};
      }
    `};
`;

const Body = styled.div`
  line-height: 16px;
  vertical-align: middle;
`;

const BodyWithImage = styled.div`
  display: flex;
`;

const ImageWrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 9px;
  height: 16px;
  width: 16px;
`;

export const BodyCellWithImage: React.SFC<
  { image: React.SFC } & React.TdHTMLAttributes<HTMLTableDataCellElement>
> = ({ children, image: Image, ...props }) => (
  <BodyCell {...props}>
    <BodyWithImage>
      <ImageWrapper>
        <Image />
      </ImageWrapper>
      <Body>{children}</Body>
    </BodyWithImage>
  </BodyCell>
);

export const TableEmptyCell = styled.div`
  font-family: Roboto;
  color: #393839;
  padding: 20px;
  width: 100%;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
