import React from "react";
import { TopBar } from "testly-web/components/Layout/TopBar/TopBar";

import styled from "styled-components/macro";
import { menuItemWidth, siteMinWidth } from "testly-web/style-vars";
import { AccountSettingsContainer } from "../AccoutSettings/AccountSettingsContainer";
import { LeftBarContainer } from "./LeftBar";

const StyledLayout = styled.div`
  min-width: ${siteMinWidth}px;
  margin: 0 auto;
  content: "";
  clear: both;
  height: 100%;
`;

const TopBarContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  z-index: 10000;
  padding-left: ${menuItemWidth}px;
`;

const ContentContainer = styled.div`
  box-sizing: border-box;
  min-width: ${siteMinWidth - menuItemWidth}px;
  padding-left: ${menuItemWidth}px;
  clear: both;
`;

const LeftBarWrapper = styled.div`
  top: 0;
  min-width: ${menuItemWidth}px;
  display: flex;
  position: fixed;
  z-index: 10001;
  height: 100%;
`;

export const Layout: React.SFC = ({ children }) => (
  <StyledLayout>
    <TopBarContainer>
      <TopBar />
    </TopBarContainer>

    <LeftBarWrapper>
      <LeftBarContainer />
    </LeftBarWrapper>
    <ContentContainer>{children}</ContentContainer>
    <AccountSettingsContainer />
  </StyledLayout>
);
