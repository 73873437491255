import React from "react";
import styled from "styled-components/macro";
import { heatmapViewPadding } from "../config";

interface ScalerProps {
  siteHeight: number | undefined;
  siteWidth: number | undefined;
  wrapperRef: React.RefObject<HTMLDivElement>;
}

interface ScalerState {
  scale: number;
}

// eslint-disable-next-line no-unexpected-multiline
const ScalerStyled = styled.div<{
  scaleValue: number;
  siteHeight: number | undefined;
  siteWidth: number | undefined;
}>`
  transform: scale(${({ scaleValue }) => scaleValue}) translateZ(0px);
  transform-origin: left top 0px;
  height: ${({ scaleValue, siteHeight }) =>
    siteHeight ? `${scaleValue * siteHeight}px` : "100%"};
  width: ${({ scaleValue, siteWidth }) =>
    siteWidth
      ? `${scaleValue * siteWidth + heatmapViewPadding * 2}px`
      : "100%"};
`;

export class Scaler extends React.Component<ScalerProps, ScalerState> {
  public state: Readonly<ScalerState> = {
    scale: 1
  };
  private timer?: number;

  constructor(props: ScalerProps) {
    super(props);

    this.state = {
      scale: this.caclScale(props)
    };
  }

  public componentWillReceiveProps(nextProps: ScalerProps) {
    return {
      scale: this.caclScale(nextProps)
    };
  }

  public render() {
    return (
      <ScalerStyled
        scaleValue={this.state.scale}
        siteHeight={this.props.siteHeight}
        siteWidth={this.props.siteWidth}
      >
        {this.props.children}
      </ScalerStyled>
    );
  }

  public componentDidMount() {
    this.timer = setInterval(() => {
      const newScale = this.caclScale(this.props);
      if (newScale !== this.state.scale) {
        this.setState({ scale: newScale });
      }
    }, 50);
  }

  public componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  private caclScale = (props: ScalerProps) => {
    const wrapper = props.wrapperRef.current;
    const { siteHeight, siteWidth } = props;

    if (!wrapper || !siteHeight || !siteWidth) {
      return 1;
    }

    const siteWidthWidthPadding = siteWidth + heatmapViewPadding * 2;

    if (siteWidthWidthPadding > wrapper.clientWidth) {
      return wrapper.clientWidth / siteWidthWidthPadding;
    } else {
      return 1;
    }
  };
}
