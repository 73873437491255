import { Formik, FormikConfig, FormikProps } from "formik";
import React from "react";
import { FeedbackForm } from "testly-web/components/feedback/FeedbackForm";
import {
  feedbackFormSchema,
  FeedbackFormValues
} from "testly-web/components/feedback/schema";
import { QuestionType } from "testly-web/components/feedback/types";
import {
  FeedbackPollShowOption,
  PageMatcherMatchType
} from "testly-web/queries";
import uuidv1 from "uuid/v1";

const initialValues: FeedbackFormValues = {
  name: "",
  thankYouMessage: "Thank you for your feedback!",
  isActive: true,
  isPageMatcherEnabled: false,
  isPollOpenedOnStart: true,
  showPollOption: FeedbackPollShowOption.HideAfterSubmit,
  questions: [
    {
      id: uuidv1(),
      type: QuestionType.ShortTextQuestion,
      title: "",
      index: 1,
      isNew: true
    }
  ],
  pageMatchers: [
    {
      url: "",
      matchType: PageMatcherMatchType.Contains
    }
  ]
};

export const FeedbackPollNewPage: React.SFC<{
  projectId: string;
  onSubmit: FormikConfig<FeedbackFormValues>["onSubmit"];
}> = ({ projectId, onSubmit }) => (
  <Formik<FeedbackFormValues>
    initialValues={initialValues}
    onSubmit={onSubmit}
    validateOnBlur={false}
    validateOnChange={false}
    validationSchema={feedbackFormSchema}
  >
    {(props: FormikProps<FeedbackFormValues>) => (
      <FeedbackForm formProps={props} />
    )}
  </Formik>
);
