import React from "react";

export const TimeIcon = () => (
  // tslint:disable:max-line-length
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="16"
    viewBox="0 0 14 16"
  >
    <path
      fill="#A7A9AE"
      d="M5.423 0a.615.615 0 0 0-.5.615v.616c0 .339.277.615.615.615v.73C2.394 3.16 0 5.924 0 9.232 0 12.959 3.043 16 6.77 16c3.728 0 6.768-3.04 6.768-6.77a6.74 6.74 0 0 0-2.115-4.903l.654-.808a.617.617 0 0 0-.096-.865l-.75-.577a.29.29 0 0 0-.039-.039.616.616 0 0 0-.827.135l-.73.942A6.76 6.76 0 0 0 8 2.577v-.73a.617.617 0 0 0 .615-.616V.615A.617.617 0 0 0 8 0H5.423zM6.77 4.308a4.91 4.91 0 0 1 4.923 4.923 4.91 4.91 0 0 1-4.923 4.923A4.913 4.913 0 0 1 1.846 9.23 4.913 4.913 0 0 1 6.77 4.308zM3 6.327a.435.435 0 0 0-.288.192c-.142.21-.06.452.134.635.147.134 1.858 1.529 2.712 2.23a1.224 1.224 0 0 0 1.211 1.078A1.23 1.23 0 1 0 6.77 8c-.113 0-.223.01-.327.038L3.346 6.404A.552.552 0 0 0 3 6.327z"
    />
  </svg>
);
