import { FormikActions } from "formik";
import { LocationDescriptorObject } from "history";
import React from "react";
import { MutationFn } from "react-apollo";
import { Route } from "react-router";
import {
  onFormSubmitFail,
  onMutationError
} from "testly-web/components/onFail";
import {
  SplitTestFormStep,
  Variation,
  VariationFormValues
} from "testly-web/components/split-test-form/schemas";
import { VariationsForm } from "testly-web/components/split-test-form/VariationsForm";
import { paths } from "testly-web/paths";
import {
  GetDataForSplitTestEditPageSplitTest,
  UpdateSplitTestAtSplitTestEditPageComponent,
  UpdateSplitTestAtSplitTestEditPageMutation,
  UpdateSplitTestAtSplitTestEditPageVariables
} from "testly-web/queries";

const onSubmit = (
  projectId: string,
  splitTestId: string,
  updateSplitTest: MutationFn<
    UpdateSplitTestAtSplitTestEditPageMutation,
    UpdateSplitTestAtSplitTestEditPageVariables
  >,
  replace: (path: LocationDescriptorObject) => void
) => {
  return async (
    params: VariationFormValues,
    actions: FormikActions<VariationFormValues>
  ) => {
    const variationValues = [
      params.controlVariation,
      ...params.notControlVariations
    ].map(({ id, name, url, control }) => ({
      id,
      name,
      url,
      control
    }));

    const response = await updateSplitTest({
      variables: {
        splitTestId,
        splitTestParams: { variations: variationValues }
      }
    });

    actions.setSubmitting(false);

    if (response && response.data && response.data.updateSplitTest) {
      const { successful, result } = response.data.updateSplitTest;

      if (successful && result) {
        actions.resetForm();

        replace({
          pathname: paths.splitTestEditPath({
            projectId,
            splitTestId: result.id,
            step: SplitTestFormStep.Goals
          })
        });
      } else {
        onFormSubmitFail(params, response.data);
      }
    }
  };
};

export const VariationsFormContainer: React.SFC<{
  splitTest: GetDataForSplitTestEditPageSplitTest;
  projectId: string;
  onBackButtonClick(): void;
}> = ({ splitTest, projectId, onBackButtonClick }) => {
  const variations: Array<Variation<boolean>> = (() => {
    if (splitTest.variations.length < 2) {
      return [
        {
          name: "Control URL",
          url: "",
          control: true
        },
        {
          name: "Variation 1",
          url: "",
          control: false
        }
      ];
    } else {
      return splitTest.variations;
    }
  })();

  const controlVariation = variations.find(
    ({ control }) => control === true
  ) as Variation<true>;
  const notControlVariations = variations.filter(
    ({ control }) => control === false
  ) as Array<Variation<false>>;

  return (
    <Route
      render={({ history: { replace }, location: { state } }) => (
        <UpdateSplitTestAtSplitTestEditPageComponent onError={onMutationError}>
          {updateSplitTest => (
            <VariationsForm
              onBackButtonClick={onBackButtonClick}
              initialValues={{ controlVariation, notControlVariations }}
              onSubmit={onSubmit(
                projectId,
                splitTest.id,
                updateSplitTest,
                path => replace({ ...path, state })
              )}
            />
          )}
        </UpdateSplitTestAtSplitTestEditPageComponent>
      )}
    />
  );
};
