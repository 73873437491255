import { Form, FormikProps } from "formik";
import React from "react";
import styled from "styled-components/macro";
import { Body, Panel } from "testly-web/components/Panel";
import {
  DeviceType,
  ReferrerSource,
  SplitTestFinishConditionType,
  SplitTestStatus
} from "testly-web/queries";
import { Checkbox } from "../../Form/Checkbox";
import {
  InputWrapper,
  NumberField,
  PercentField,
  SelectField
} from "../../Form/Field";
import {
  Footer,
  Section,
  SectionDescription,
  SectionHeader
} from "../../Panel/Panel";
import { BackButton, FormFotterPrimaryButtonWidth } from "../formComponents";
import { SettingsFormValues } from "../schemas";
import { SettingsActionButton } from "../SettingsActionButton";
import { CheckboxGroup } from "./CheckboxGroup";
import { ConditionTypeSelect } from "./ConditionTypeSelect";

const winnerFieldNameMap: Record<SplitTestFinishConditionType, string> = {
  [SplitTestFinishConditionType.DaysPassed]: "finishCondition.daysPassed",
  [SplitTestFinishConditionType.GoalConversions]:
    "finishCondition.goalConversions",
  [SplitTestFinishConditionType.Visits]: "finishCondition.visits"
};

const sourceCheckboxes: Record<ReferrerSource, string> = {
  [ReferrerSource.Direct]: "Direct Traffic",
  [ReferrerSource.Email]: "Email Traffic",
  [ReferrerSource.Paid]: "Paid Traffic",
  [ReferrerSource.Search]: "Search Traffic",
  [ReferrerSource.Social]: "Social Traffic",
  [ReferrerSource.Unknown]: "Other Traffic"
};

const deviceCheckboxes: Record<DeviceType, string> = {
  [DeviceType.Desktop]: "Desktop Traffic",
  [DeviceType.Tablet]: "Tablet Traffic",
  [DeviceType.Mobile]: "Mobile Traffic"
};

const BodyStyled = styled(Body)`
  padding-bottom: 5px;
`;

const FinishInput = styled(NumberField)`
  input {
    width: 70px;
  }

  ${InputWrapper} {
    justify-content: flex-start;
  }
`;

const Sources = styled.div`
  margin-left: 30px;
`;

const Devices = styled.div`
  margin-left: 70px;
`;

const Traffic = styled.div`
  display: flex;

  ${Sources} {
    opacity: 0.5;
  }

  ${Devices} {
    opacity: 0.5;
  }
`;

const AllSourceCheckbox = styled(Checkbox)`
  margin-top: 20px;
  margin-bottom: 17px;
`;

const SettingsActionButtonStyled = styled(SettingsActionButton)`
  width: ${FormFotterPrimaryButtonWidth}px;
`;

export const SettingsForm: React.SFC<{
  formProps: FormikProps<SettingsFormValues>;
  goals: Array<{
    id: string;
    name: string;
  }>;
  onStatusSelect(newState: SplitTestStatus): void;
  onBackButtonClick(): void;
}> = ({ formProps, goals, onStatusSelect, onBackButtonClick }) => {
  const { values, isSubmitting } = formProps;
  const { conditionType } = values.finishCondition;

  return (
    <Form>
      <Panel>
        <BodyStyled>
          <Section>
            <SectionHeader>Traffic</SectionHeader>
            <SectionDescription>
              Set the percent of traffic you want to use for your test.
            </SectionDescription>
            <PercentField name="trafficPercent" {...formProps} />
          </Section>
          <Section>
            <SectionHeader>Test Finish</SectionHeader>
            <SectionDescription>Finish test after:</SectionDescription>
            <FinishInput
              name={winnerFieldNameMap[conditionType]}
              afterInputRender={() => (
                <ConditionTypeSelect
                  selectedConditionType={conditionType}
                  formProps={formProps}
                />
              )}
              {...formProps}
            />
            {conditionType === SplitTestFinishConditionType.GoalConversions && (
              <>
                <SectionDescription>For Goal:</SectionDescription>
                <SelectField
                  name="finishCondition.goalId"
                  options={goals.map(({ id, name }) => ({
                    label: name,
                    value: id
                  }))}
                  {...formProps}
                />
              </>
            )}
          </Section>
          <Section>
            <SectionHeader>Traffic sources</SectionHeader>
            <SectionDescription>
              Select the traffic sources you want to track for your test.
            </SectionDescription>
            <AllSourceCheckbox
              label="All Sources"
              green={true}
              light={true}
              checked={
                Object.values(DeviceType).every(device =>
                  values.trafficDeviceTypes.includes(device)
                ) &&
                Object.values(ReferrerSource).every(source =>
                  values.trafficReferrerSources.includes(source)
                )
              }
              onChange={e => {
                if (e.target.checked) {
                  formProps.setFieldValue(
                    "trafficDeviceTypes",
                    Object.values(DeviceType)
                  );
                  formProps.setFieldValue(
                    "trafficReferrerSources",
                    Object.values(ReferrerSource)
                  );
                } else {
                  formProps.setFieldValue("trafficDeviceTypes", []);
                  formProps.setFieldValue("trafficReferrerSources", []);
                }
              }}
            />
            <Traffic>
              <Sources>
                <CheckboxGroup
                  name="trafficReferrerSources"
                  setFieldValue={formProps.setFieldValue}
                  currentValues={values.trafficReferrerSources}
                  checkboxes={sourceCheckboxes}
                />
              </Sources>

              <Devices>
                <CheckboxGroup
                  name="trafficDeviceTypes"
                  setFieldValue={formProps.setFieldValue}
                  currentValues={values.trafficDeviceTypes}
                  checkboxes={deviceCheckboxes}
                />
              </Devices>
            </Traffic>
          </Section>
        </BodyStyled>
        <Footer>
          <BackButton
            onClick={onBackButtonClick}
            type="button"
            disabled={isSubmitting}
          />

          <SettingsActionButtonStyled
            status={values.status}
            isUpdating={isSubmitting}
            onSelect={onStatusSelect}
            disabled={isSubmitting}
          />
        </Footer>
      </Panel>
    </Form>
  );
};
