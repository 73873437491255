import React from "react";

export function ModalCloseIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
    >
      <path
        fill="#FFF"
        d="M1.376 0L0 1.376 6.124 7.5 0 13.624 1.376 15 7.5 8.876 13.624 15 15 13.624 8.876 7.5 15 1.376 13.624 0 7.5 6.124z"
      />
    </svg>
  );
}
