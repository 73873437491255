import React from "react";

import "./index.css";

// taken from https://github.com/lonelyclick/react-loading-bar

interface LoadingBarProps {
  change?: boolean;
  showSpinner?: boolean;
  show: boolean;
}

interface LoadingBarState {
  size: number;
  disappearDelayHide: boolean;
  percent: number;
  appearDelayWidth: number | boolean;
}

export class LoadingBar extends React.Component<
  LoadingBarProps,
  LoadingBarState
> {
  public static defaultProps = {
    change: true,
    showSpinner: true
  };

  public state = {
    size: 0,
    disappearDelayHide: false, // when dispappear, first transition then display none
    percent: 0,
    appearDelayWidth: 0 // when appear, first display block then transition width
  };

  public componentDidMount() {
    if (this.props.show) {
      this.show();
    }
  }

  public componentWillReceiveProps(nextProps: LoadingBarProps) {
    const { show, change } = nextProps;

    if (!change) {
      return;
    }
    if (show) {
      this.show();
    } else {
      this.hide();
    }
  }

  public show() {
    const { size } = this.state;
    let { percent } = this.state;

    const appearDelayWidth = size === 0;
    percent = this.calculatePercent(percent);

    this.setState({
      size: size + 1,
      appearDelayWidth,
      percent
    });

    if (appearDelayWidth) {
      setTimeout(() => {
        this.setState({
          appearDelayWidth: false
        });
      });
    }
  }

  public hide() {
    const { size } = this.state;

    if (size - 1 < 0) {
      this.setState({ size: 0 });

      return;
    }

    this.setState({
      size: 0,
      disappearDelayHide: true,
      percent: 1
    });

    setTimeout(() => {
      this.setState({
        disappearDelayHide: false,
        percent: 0
      });
    }, 500);
  }

  public getBarStyle() {
    const { disappearDelayHide, appearDelayWidth, percent } = this.state;

    return {
      background: "#00ac69",
      width: appearDelayWidth ? 0 : `${percent * 100}%`,
      display: disappearDelayHide || percent > 0 ? "block" : "none"
    };
  }

  public getSpinnerStyle() {
    const { size } = this.state;

    return {
      display: size > 0 ? "block" : "none",
      borderColor: "#00ac69"
    };
  }

  public calculatePercent(percent: number) {
    // tslint:disable:insecure-random
    let random = 0;

    if (percent >= 0 && percent < 0.25) {
      random = (Math.random() * (5 - 3 + 1) + 10) / 100;
    } else if (percent >= 0.25 && percent < 0.65) {
      random = (Math.random() * 3) / 100;
    } else if (percent >= 0.65 && percent < 0.9) {
      random = (Math.random() * 2) / 100;
    } else if (percent >= 0.9 && percent < 0.99) {
      random = 0.005;
    } else {
      random = 0;
    }

    return percent + random;
  }

  public render() {
    return (
      <div>
        <div className="loading">
          <div className="bar" style={this.getBarStyle()}>
            <div className="peg" />
          </div>
        </div>
        {this.props.showSpinner && (
          <div className="spinner">
            <div className="icon" style={this.getSpinnerStyle()} />
          </div>
        )}
      </div>
    );
  }
}
