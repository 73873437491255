import React from "react";
import styled from "styled-components/macro";

const ElementClicks = styled.div`
  display: inline-block;
  font-family: Roboto;
  font-size: 10px;
  border-radius: 4px;
  background-color: #ff5858;
  color: #ffffff;
  padding: 5px;

  position: absolute;
  left: 8px;
  top: -12px;

  user-select: none;
  white-space: nowrap;
  visibility: hidden;
`;

const ElementTooltipStyled = styled.div`
  position: absolute;
  transition: opacity 0.3s;
  border-radius: 8px;
  border: 2px dashed transparent;

  top: 0;
  left: 0;

  transform: translateZ(0);
  &:hover {
    border-color: #ff5858;

    ${ElementClicks} {
      visibility: visible;
    }
  }
`;

const padding = 4;

export const Tooltip: React.SFC<{
  left: number;
  top: number;
  width: number;
  height: number;
  clicksCount: number;
  clicksPercent: number;
}> = ({ left, top, width, height, clicksCount, clicksPercent }) => (
  <ElementTooltipStyled
    key={`${left}${top}${width}${height}`}
    style={{
      transform: `translate3d(${left - padding}px, ${top - padding}px, 0px)`,
      width: `${width + padding}px`,
      height: `${height + padding}px`
    }}
  >
    <ElementClicks>
      {clicksCount} Clicks ({(clicksPercent * 100).toFixed(2)}%)
    </ElementClicks>
  </ElementTooltipStyled>
);
