import React from "react";
import {
  SessionRecordingListPage,
  SessionRecordingListPageProps
} from "testly-web/components/session-recordings-list/SessionRecordingListPage";
import {
  AtiveTab,
  SplitTestPageHeader
} from "testly-web/components/split-test/SplitTestShowPageHeader";

export const SplitTestSessionRecordingIndexPage: React.SFC<{
  splitTestId: string;
  projectId: string;
  isDataLoaded: boolean;
  sessionRecordingListProps(): SessionRecordingListPageProps;
}> = ({ sessionRecordingListProps, splitTestId, projectId, isDataLoaded }) => {
  return (
    <>
      <SplitTestPageHeader
        splitTestId={splitTestId}
        projectId={projectId}
        activeTab={AtiveTab.splitTestSessionRecordingsIndexPage}
      />
      {isDataLoaded && (
        <SessionRecordingListPage {...sessionRecordingListProps()} />
      )}
    </>
  );
};
