import React from "react";
import styled, { keyframes } from "styled-components/macro";

const disappearingFrame1 = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;

const disappearingFrame2 = ({ size }: { size: number }) => keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(${size * 1.5}px, 0);
  }
`;

const disappearingFrame3 = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;

// eslint-disable-next-line no-unexpected-multiline
const LoadingIndicatorStyled = styled.div<{
  size: number;
}>`
  width: ${({ size }) => size * 4}px;
  height: ${({ size }) => size}px;
  position: relative;
`;

// eslint-disable-next-line no-unexpected-multiline
const Circle = styled.div<{
  size: number;
}>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 50%;
  background-color: #00ac69;
  position: absolute;

  &:nth-child(1) {
    left: 0px;
    animation: ${disappearingFrame1} 0.6s infinite;
  }
  &:nth-child(2) {
    left: 0px;
    animation: ${({ size }) => disappearingFrame2({ size })} 0.6s infinite;
  }
  &:nth-child(3) {
    left: ${({ size }) => size * 1.5}px;
    animation: ${({ size }) => disappearingFrame2({ size })} 0.6s infinite;
  }
  &:nth-child(4) {
    left: ${({ size }) => size * 3}px;
    animation: ${disappearingFrame3} 0.6s infinite;
  }
`;

export const DotLoadingIndicator: React.SFC<{
  size: number;
}> = ({ size }) => (
  <LoadingIndicatorStyled size={size}>
    <Circle size={size} />
    <Circle size={size} />
    <Circle size={size} />
    <Circle size={size} />
  </LoadingIndicatorStyled>
);
