import React from "react";

export const EyeIcon = () => (
  // tslint:disable:max-line-length
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="12"
    viewBox="0 0 23 12"
  >
    <path
      fill="#A7A9AE"
      d="M11.344 0C5.18 0 .439 5.43.439 5.43L0 5.913l.439.486s4.323 4.93 10.073 5.383a6.6 6.6 0 0 0 .832.046 6.6 6.6 0 0 0 .831-.046C17.925 11.329 22.25 6.4 22.25 6.4l.439-.486-.44-.485S17.508 0 11.345 0zm0 1.479c1.629 0 3.13.444 4.436 1.04.47.779.739 1.677.739 2.656a5.172 5.172 0 0 1-4.598 5.152c-.014.003-.031-.003-.046 0-.176.009-.352.023-.531.023-.197 0-.387-.011-.578-.023A5.172 5.172 0 0 1 6.17 5.175c0-.964.26-1.863.716-2.634h-.023c1.317-.606 2.836-1.062 4.482-1.062zm0 1.478a2.218 2.218 0 1 0 0 4.437 2.218 2.218 0 0 0 0-4.437zm-6.47.693a6.658 6.658 0 0 0-.184 1.525c0 1.297.37 2.51 1.017 3.535a17.149 17.149 0 0 1-3.581-2.796A17.692 17.692 0 0 1 4.875 3.65zm12.939 0c1.403.936 2.394 1.9 2.749 2.264a17.149 17.149 0 0 1-3.581 2.796 6.601 6.601 0 0 0 1.017-3.535 6.62 6.62 0 0 0-.185-1.525z"
    />
  </svg>
);
