import React from "react";
import { CheckboxInFilter } from "testly-web/components/filters/AdvancedFilter/CheckboxInFilter";
import { handleInFilterChange } from "testly-web/components/filters/AdvancedFilter/inFilter";
import { OnFilterChange } from "testly-web/components/filters/AdvancedFilter/types";
import { SplitTestFilter } from "testly-web/queries";

const filterName = "pageCategoryIdIn";

export interface PageCategory {
  id: string;
  name: string;
}

export const pageCategoryFilter = (
  filter: SplitTestFilter,
  onFilterChange: OnFilterChange<SplitTestFilter>,
  pageCategories: PageCategory[]
) => {
  const pageCategoriesIn = filter[filterName] || [];
  const onChange = handleInFilterChange(filter, filterName, onFilterChange);

  return (
    <CheckboxInFilter
      key={filterName}
      name="Page Category"
      options={pageCategories.map(({ name, id }) => ({
        name,
        value: id,
        selected: pageCategoriesIn.includes(id)
      }))}
      onChange={onChange}
    />
  );
};
