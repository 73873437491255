import React from "react";

export const WaitingIcon = () => (
  // tslint:disable:max-line-length
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
  >
    <g fill="#A7A9AE">
      <path d="M15 0C6.73 0 0 6.73 0 15s6.73 15 15 15 15-6.73 15-15S23.27 0 15 0zm0 2.308c7.022 0 12.692 5.67 12.692 12.692S22.022 27.692 15 27.692A12.676 12.676 0 0 1 2.308 15C2.308 7.978 7.978 2.308 15 2.308z" />
      <path d="M7.253 5.934v.685c0 .377.31.684.69.684h.688v.685c0 .377.31.685.69.685h.215c.075 3.405 1.494 4.587 2.434 5.37.571.477.603.56.603.792 0 .249-.067.343-.624.813-.937.792-2.338 1.985-2.413 5.35h-.215c-.38 0-.69.307-.69.684v.685h-.689c-.38 0-.69.307-.69.685v.684h15.166v-.684a.688.688 0 0 0-.69-.685h-.689v-.685a.688.688 0 0 0-.69-.685h-.215c-.075-3.388-1.505-4.562-2.455-5.349-.587-.484-.625-.583-.625-.813 0-.225.048-.302.625-.77.955-.778 2.38-1.958 2.455-5.392h.216c.38 0 .689-.308.689-.685v-.685h.69c.379 0 .689-.307.689-.684v-.685H7.253zm3.403 2.739h8.358c-.116 4.793-3.08 4.156-3.08 6.162 0 2.006 2.962 1.423 3.08 6.162h-1.443c0-2.717-2.499-3.42-2.499-5.028 0-2.471 1.831-2.787 1.831-3.873H12.81c0 .926 1.788 1.319 1.788 3.873 0 1.634-2.499 2.29-2.499 5.028h-1.443c.116-4.718 3.037-4.156 3.037-6.162 0-2.006-2.921-1.399-3.037-6.162z" />
    </g>
  </svg>
);
