import { History } from "history";
import queryString from "qs";

export interface QueryState {
  filter: Filter;
  page: number;
}

export interface Filter {
  titleCont: string | null;
  categoryIdEq: string | null;
  impactRateEq: string | null;
}

export const useQueryState = (
  history: History,
  search: string
): [QueryState, (state: QueryState) => void] => {
  const setState = (newState: QueryState) => {
    history.replace({
      pathname: history.location.pathname,
      search: queryString.stringify(newState, { skipNulls: true })
    });
  };

  const state: QueryState = (() => {
    const parsedQuery: {
      filter?: Filter;
      page?: string;
    } = queryString.parse(search, { ignoreQueryPrefix: true }) || {};

    return {
      filter: parsedQuery.filter || {
        titleCont: null,
        categoryIdEq: null,
        impactRateEq: null
      },
      page: parseInt(parsedQuery.page || "1", 10)
    };
  })();

  return [state, setState];
};
