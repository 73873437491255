import { isEqual } from "lodash";
import React from "react";
import { StyledComponent } from "styled-components";
import styled from "styled-components/macro";
import { Event as EventIcon } from "testly-web/icons/ActionIcon";
import { mapEventTypeToAction } from "testly-web/pages/SessionRecordingPage/viewableActions";
import { ViewableEvent } from "./types";

const barHeight = 10;
// const cursorSize = 20;

const EventIconHeight = 32;
const EventIconWidth = 28;

const StyledProgressBar = styled.div`
  box-sizing: border-box;
  position: relative;
  z-index: 1000;
  width: 100%;
  height: ${barHeight}px;
  min-height: ${barHeight}px;
  cursor: pointer;
`;

const EventsStyled = styled.div``;

const Event = React.memo(styled(EventIcon)<{ location: number }>`
  position: absolute;
  left: -${EventIconWidth / 2}px;
  top: ${barHeight - EventIconHeight}px;
  margin-left: ${({ location }) => location}%;
`);

const Bar = styled.div`
  background-color: #1e1d1e;
`;

interface PropgressProps {
  progressPercent: number;
}
const DoneLine = styled.div.attrs<PropgressProps>({
  style: ({ progressPercent }: PropgressProps) => ({
    width: `${progressPercent}%`
  })
})`
  background-color: #008b55;
  height: ${barHeight}px;
` as StyledComponent<"div", any, PropgressProps>;

// const Cursor = styled.div.attrs<PropgressProps>({
//   style: ({ progressPercent }: PropgressProps) => ({
//     marginLeft: `${progressPercent}%`
//   })
// })`
//   position: absolute;
//   width: ${cursorSize}px;
//   height: ${cursorSize}px;
//   background-color: green;
//   left: -${cursorSize / 2}px;
//   top: -${(cursorSize - barHeight) / 2}px;
//   border-radius: 50%;
// `;

interface EventsProps {
  sessionDuration: number | undefined;
  events: ViewableEvent[];
  currentPlayTime: number;
  onSessionEventClick(e: React.MouseEvent<HTMLDivElement>, time: number): void;
}

class Events extends React.Component<EventsProps> {
  public shouldComponentUpdate(nextProps: EventsProps) {
    return (
      nextProps.sessionDuration !== this.props.sessionDuration ||
      nextProps.events !== this.props.events ||
      !isEqual(
        this.activeEvents(nextProps.currentPlayTime, nextProps.events),
        this.activeEvents(this.props.currentPlayTime, this.props.events)
      )
    );
  }

  public render() {
    const {
      events,
      sessionDuration,
      onSessionEventClick,
      currentPlayTime
    } = this.props;

    return (
      <EventsStyled>
        {events.map(event => (
          <Event
            key={`${event.time}`}
            location={
              sessionDuration ? (event.time / sessionDuration) * 100 : 0
            }
            onClick={(e: React.MouseEvent<HTMLDivElement>) => {
              onSessionEventClick(e, event.time);
            }}
            role="button"
            tail={true}
            active={event.time === currentPlayTime}
            actionType={mapEventTypeToAction(event.eventType)}
          />
        ))}
      </EventsStyled>
    );
  }

  private activeEvents(currentPlayTime: number, events: ViewableEvent[]) {
    return events.filter(e => e.time === currentPlayTime);
  }
}

export const ProgressBarUI: React.SFC<{
  vieawableEvents: ViewableEvent[];
  sessionDuration: number | undefined;
  progressPercent: number;
  currentPlayTime: number;
  progressBarRef: React.RefObject<HTMLDivElement>;
  onProgressBarTouchStart(e: React.TouchEvent<HTMLDivElement>): void;
  onProgressBarMouseDown(e: React.MouseEvent<HTMLDivElement>): void;
  onSessionEventClick(e: React.MouseEvent<HTMLDivElement>, time: number): void;
}> = ({
  vieawableEvents,
  sessionDuration,
  onSessionEventClick,
  progressPercent,
  onProgressBarTouchStart,
  onProgressBarMouseDown,
  progressBarRef,
  currentPlayTime
}) => {
  return (
    <StyledProgressBar>
      <Events
        sessionDuration={sessionDuration}
        events={vieawableEvents}
        currentPlayTime={currentPlayTime}
        onSessionEventClick={onSessionEventClick}
      />

      <Bar
        onMouseDown={onProgressBarMouseDown}
        onTouchStart={onProgressBarTouchStart}
        ref={progressBarRef as React.RefObject<HTMLDivElement> & string}
        role="button"
      >
        <DoneLine progressPercent={progressPercent} />

        {/* <Cursor progressPercent={progressPercent} /> */}
      </Bar>
    </StyledProgressBar>
  );
};
