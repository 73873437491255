export type NavbarHeader = string;
export type NavbarBackPath = string | null;
export type Caption = string | null;

export interface State {
  header: NavbarHeader;
  backPath: NavbarBackPath;
  caption: Caption;
  __typename: "Navbar";
}

export const defaultState: State = {
  header: "",
  backPath: null,
  caption: null,
  __typename: "Navbar"
};
