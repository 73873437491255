import React from "react";

export const CopyIcon = () => (
  // tslint:disable:max-line-length

  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Dashboard" fill="none" fillRule="evenodd">
      <g
        id="Testly-Dashboard-Add-code"
        transform="translate(-1237 -259)"
        fill="#A7A9AE"
        fillRule="nonzero"
      >
        <g id="Code-Copy" transform="translate(830 205)">
          <path
            d="M408.5,54 C407.67125,54 407,54.67125 407,55.5 L407,66 L408.5,66 L408.5,55.5 L419,55.5 L419,54 L408.5,54 Z M411.5,57 C410.67125,57 410,57.67125 410,58.5 L410,67.5 C410,68.32875 410.67125,69 411.5,69 L420.5,69 C421.32875,69 422,68.32875 422,67.5 L422,58.5 C422,57.67125 421.32875,57 420.5,57 L411.5,57 Z M412,59 L421,59 L421,68 L412,68 L412,59 Z"
            id="Shape"
          />
        </g>
      </g>
    </g>
  </svg>
);
