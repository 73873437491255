import { fromPairs } from "lodash";
import { Attrs } from "snabbdom/modules/attributes";
import { IdentifiableVNode, MutationAttrs, NodePath } from "../types";
import { mapChildNode } from "./mapChildNode";

const setAttrs = (node: IdentifiableVNode, attrs: MutationAttrs) => {
  const newAttrs = fromPairs(
    Object.entries(node.data.attrs || {})
      .filter(([name]) => attrs[name] !== null)
      .concat(Object.entries(attrs).filter(
        ([, value]) => value !== null
      ) as Array<[string, string]>)
  ) as Attrs;

  return {
    ...node,
    data: {
      ...(node.data || {}),
      attrs: newAttrs
    }
  };
};

const mutateAttrsWithoutRoot = (
  currentElement: IdentifiableVNode,
  nodePath: NodePath,
  attrs: MutationAttrs
): IdentifiableVNode => {
  if (nodePath.length === 0) {
    // If it is root element
    return setAttrs(currentElement, attrs);
  } else if (nodePath.length === 1) {
    return mapChildNode(currentElement, nodePath, node => {
      return setAttrs(node, attrs);
    });
  } else {
    return mapChildNode(currentElement, nodePath, (node, newPath) =>
      mutateAttrsWithoutRoot(node, newPath, attrs)
    );
  }
};

export const mutateAttrs = (
  currentElement: IdentifiableVNode,
  nodePath: NodePath,
  attrs: MutationAttrs
): IdentifiableVNode => {
  if (nodePath[0] !== currentElement.data.nodeId) {
    throw new Error("First element should be root");
  }

  return mutateAttrsWithoutRoot(currentElement, nodePath.slice(1), attrs);
};
