import queryString from "qs";
import React from "react";
import { RouteComponentProps } from "react-router";
import { SetCurrentProjectId } from "testly-web/components/CurrentProjectId/SetCurrentProjectId";
import { loadingRender } from "testly-web/components/loadingRender";
import { SetNavbar } from "testly-web/components/Navbar/SetNavbar";
import { paths } from "testly-web/paths";
import { GetDataForFeedbackPollResponseIndexPageComponent } from "testly-web/queries";
import { FeedbackPollResponseIndexPage } from "./FeedbackPollResponseIndexPage";

export interface PaginationState {
  before?: string;
  after?: string;
}

export const FeedbackPollResponseIndexPageContainer: React.SFC<
  RouteComponentProps<{ projectId: string; feedbackPollId: string }>
> = ({
  match: {
    params: { projectId, feedbackPollId }
  },
  location: { search },
  history
}) => {
  const changeQuery = (pagination: PaginationState) => {
    history.replace({
      pathname: history.location.pathname,
      search: queryString.stringify({ pagination })
    });
  };

  const parsedQuery: {
    pagination: PaginationState;
  } = queryString.parse(search.substr(1)) || {};

  const paginationState: PaginationState = parsedQuery.pagination
    ? parsedQuery.pagination
    : {};

  const navbarProps = {
    header: "Poll Responses",
    backPath: paths.feedbackPollIndexPath({
      projectId
    })
  };

  return (
    <>
      <SetCurrentProjectId id={projectId} />
      <SetNavbar {...navbarProps} />

      <GetDataForFeedbackPollResponseIndexPageComponent
        variables={{
          projectId,
          feedbackPollId,
          pagination: paginationState
        }}
        notifyOnNetworkStatusChange={true}
        fetchPolicy="network-only"
      >
        {loadingRender(
          "currentUser.project.feedbackPoll.responses",
          ({ data, networkStatus, refetch }) => {
            const poll = data!.currentUser!.project!.feedbackPoll!;
            const pageInfo = poll.responses.pageInfo;

            return (
              <>
                <SetNavbar {...navbarProps} caption={poll.name} />
                <FeedbackPollResponseIndexPage
                  questions={poll.questions}
                  responses={poll.responses.nodes}
                  pageInfo={pageInfo}
                  projectId={projectId}
                  networkStatus={networkStatus}
                  refetch={refetch}
                  loadNextPage={() => {
                    window.scrollTo({
                      top: 0,
                      left: 0
                    });
                    changeQuery({ after: pageInfo.after! });
                  }}
                  loadPreviousPage={() => {
                    window.scrollTo({
                      top: 0,
                      left: 0
                    });
                    changeQuery({ before: pageInfo.before! });
                  }}
                />
              </>
            );
          }
        )}
      </GetDataForFeedbackPollResponseIndexPageComponent>
    </>
  );
};
