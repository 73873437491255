import React from "react";

export interface DropdownContentProps<W, T> {
  wrapperRef: React.RefObject<W>;
  togglerRef: React.RefObject<T>;
  closeAnimationIsRunning: boolean;
  opened: boolean;
  close: () => void;
  onControlClick(event: React.MouseEvent<Element, MouseEvent>): void;
}

interface DropdownProps<W, T> {
  className?: string;
  children: React.SFC<DropdownContentProps<W, T>>;
  onClose?(): void;
}

interface DropdownState {
  opened: boolean;
  closeAnimationIsRunning: boolean;
}

export class DropdownController<
  W extends HTMLElement,
  T extends HTMLElement
> extends React.Component<DropdownProps<W, T>, DropdownState> {
  private wrapperRef: React.RefObject<W>;
  private togglerRef: React.RefObject<T>;

  constructor(props: DropdownProps<W, T>) {
    super(props);

    this.state = {
      opened: false,
      closeAnimationIsRunning: false
    };

    this.togglerRef = React.createRef<T>();
    this.wrapperRef = React.createRef<W>();
  }

  public componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    // document.addEventListener("touchend", this.handleClickOutside);
  }

  public componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    // document.removeEventListener("touchend", this.handleClickOutside);
  }

  public render() {
    return this.props.children({
      togglerRef: this.togglerRef,
      wrapperRef: this.wrapperRef,
      onControlClick: this.handleControlClick,
      closeAnimationIsRunning: this.state.closeAnimationIsRunning,
      opened: this.state.opened,
      close: this.handleClose
    });
  }

  public handleClickOutside = (event: MouseEvent) => {
    const current = this.wrapperRef.current;
    const toggler = this.togglerRef.current;
    const target = event.target as Element;

    if (toggler && toggler.contains(target)) {
      return;
    }

    if (this.state.opened && current && !current.contains(target)) {
      this.setState({ opened: false, closeAnimationIsRunning: true }, () => {
        this.onClose();
      });
    }
  };

  private handleClose = () => {
    this.setState(state => {
      return {
        opened: !state.opened,
        closeAnimationIsRunning: state.opened
      };
    }, this.onClose);
  };

  private handleControlClick = (
    event: React.MouseEvent<Element, MouseEvent>
  ) => {
    if (
      this.togglerRef.current &&
      !this.togglerRef.current!.contains(event.target as Element)
    ) {
      return;
    }

    this.setState(state => {
      return {
        opened: !state.opened,
        closeAnimationIsRunning: state.opened
      };
    }, this.onClose);
  };

  private onClose = () => {
    if (this.state.closeAnimationIsRunning) {
      setTimeout(() => {
        // If we will not remove class with animanation-name: closed,
        // then when DOM node will be moved - the animation will be played again
        // The animation duration usually less then 1s, I hope it will not cause bugs
        this.setState({ closeAnimationIsRunning: false });
      }, 1000);
    }

    if (this.props.onClose && this.state.opened === false) {
      this.props.onClose();
    }
  };
}
