import React from "react";
import { ViewableActionType } from "testly-web/pages/SessionRecordingPage/viewableActions";

// tslint:disable:max-line-length

export interface IconProps {
  active: boolean;
  tail: boolean;
  actionType: ViewableActionType;
  role?: string;
  onClick?(e: React.MouseEvent): void;
}

const icons: Record<ViewableActionType, React.SFC<{ iconColor: string }>> = {
  [ViewableActionType.MouseClicked]: ({ iconColor }) => (
    <path
      fill={iconColor}
      d="M9.742 7.797a.875.875 0 0 0-.6 1.082l2.01 7.009-1.58-1.228c-.486-.422-1.193-.409-1.59.027-.39.442-.32 1.138.161 1.561 0 0 2.626 2.246 3.748 3.21 1.12.96 2.65 1.527 5.013.85 3.917-1.124 3.4-4.247 3.142-5.15L19 11.514a.875.875 0 0 0-1.683.482.29.29 0 0 1-.2.36.29.29 0 0 1-.36-.2l-.161-.56a.875.875 0 0 0-1.682.482.294.294 0 0 1-.2.361.294.294 0 0 1-.361-.2.875.875 0 0 0-1.682.483.294.294 0 0 1-.2.36.294.294 0 0 1-.361-.2l-1.286-4.486a.875.875 0 0 0-1.083-.6z"
    />
  ),
  [ViewableActionType.PageVisited]: ({ iconColor }) => (
    <path
      fill={iconColor}
      d="M6.825 14.265l5.81 1.904 1.904 5.81 4.628-12.342z"
    />
  ),
  [ViewableActionType.RespondedToPoll]: ({ iconColor }) => (
    <path fill={iconColor} d="M6 11h4v7H6zM12 7h4v11h-4zM18 14h4v4h-4z" />
  )
};

export const Event: React.SFC<IconProps> = React.memo(
  ({ active, tail, actionType: eventType, ...props }) => {
    const bgColor = active ? "#FFF" : "#00AC69";
    const iconColor = active ? "#00AC69" : "#FFF";

    const height = tail ? 32 : 28;

    const Icon = icons[eventType];

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height={height}
        viewBox={`0 0 28 ${height}`}
        {...props}
      >
        <g fill="none">
          {tail ? (
            <path
              fill={bgColor}
              d="M15 27.782V32h-2v-4.218a2.153 2.153 0 0 1-.616-.429L.647 15.616c-.863-.863-.863-2.37 0-3.232L12.384.647c.863-.863 2.37-.863 3.232 0l11.737 11.737c.863.863.863 2.37 0 3.232L15.616 27.353c-.181.181-.39.324-.616.429z"
            />
          ) : (
            <path
              fill={bgColor}
              fillRule="nonzero"
              d="M27.353 12.384L15.616.647c-.863-.863-2.37-.863-3.232 0L.647 12.384c-.863.863-.863 2.37 0 3.232l11.737 11.737c.863.863 2.37.863 3.232 0l11.737-11.737c.863-.863.863-2.37 0-3.232z"
            />
          )}

          <Icon iconColor={iconColor} />
        </g>
      </svg>
    );
  }
);
