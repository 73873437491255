import React from "react";

export const LeftGrayArrowIcon: React.SFC<
  React.SVGProps<SVGSVGElement>
> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="13"
    viewBox="0 0 8 13"
    {...props}
  >
    <path
      fill="#A7A9AE"
      d="M7.12.92L6.2 0 .44 5.76 0 6.22l.44.46 5.76 5.76.92-.92-5.3-5.3z"
    />
  </svg>
);
