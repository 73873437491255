import { debounce } from "lodash";
import React from "react";
import styled from "styled-components/macro";
import { OnFilterChange } from "testly-web/components/filters/AdvancedFilter/types";
import { DateFilter } from "testly-web/components/filters/DateFilter/DateFilter";
import { FilterStyled } from "testly-web/components/filters/style";
import { InputWithImage } from "testly-web/components/Form/Input";
import { Search2Icon } from "testly-web/icons/Search2Icon";
import { SplitTestFilter } from "testly-web/queries";
import { PageCategory } from "../SplitTestAdvancedFilter/pageCategoryFilter";
import { PageType } from "../SplitTestAdvancedFilter/pageTypeFilter";
import { SplitTestAdvancedFilter } from "../SplitTestAdvancedFilter/SplitTestAdvancedFilter";

interface FilterProps {
  onFilterChange: OnFilterChange<SplitTestFilter>;
  filter: SplitTestFilter;
  pageCategories: PageCategory[];
  pageTypes: PageType[];
  onFilterClose(): void;
}

const InputWrapper = styled.div`
  position: relative;
  width: 260px;
  margin-right: 30px;
`;
const InputWithImageStyled = styled(InputWithImage)`
  width: 260px;
  margin-right: 30px;
`;

export class Filter extends React.Component<FilterProps> {
  private debouncedOnFilterClose: () => void;

  constructor(props: FilterProps) {
    super(props);

    this.debouncedOnFilterClose = debounce(props.onFilterClose, 300);
  }

  public render() {
    const {
      filter,
      onFilterChange,
      onFilterClose,
      pageCategories,
      pageTypes
    } = this.props;

    return (
      <FilterStyled>
        <InputWrapper>
          <InputWithImageStyled
            onChange={this.handleNameInput}
            value={filter.nameCont || ""}
            placeholder="Min. 3 chars"
            imagePosition="right"
            imageRender={() => <Search2Icon />}
          />
        </InputWrapper>
        <DateFilter
          onDateChange={this.handleDateChange}
          fromDate={
            filter.createdAtGteq ? new Date(filter.createdAtGteq) : null
          }
          toDate={filter.createdAtLteq ? new Date(filter.createdAtLteq) : null}
          onFilterClose={onFilterClose}
        />
        <SplitTestAdvancedFilter
          filter={filter}
          onFilterChange={onFilterChange}
          onFilterClose={onFilterClose}
          pageCategories={pageCategories}
          pageTypes={pageTypes}
        />
      </FilterStyled>
    );
  }

  private handleDateChange = (fromDate: Date | null, toDate: Date | null) => {
    this.props.onFilterChange({
      ...this.props.filter,
      createdAtGteq: fromDate && fromDate.toISOString(),
      createdAtLteq: toDate && toDate.toISOString()
    });
  };

  private handleNameInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onFilterChange({
      ...this.props.filter,
      nameCont: e.target.value
    });

    if (e.target.value.length >= 3 || e.target.value.length === 0) {
      this.debouncedOnFilterClose();
    }
  };
}
