import { capitalize } from "lodash";
import React from "react";
import { DeviceType, SessionRecordingFilter } from "testly-web/queries";
import { CheckboxInFilter } from "../../filters/AdvancedFilter/CheckboxInFilter";
import { handleInFilterChange } from "../../filters/AdvancedFilter/inFilter";
import { OnFilterChange } from "../../filters/AdvancedFilter/types";

const deviceOptions = Object.values(DeviceType).map((value: string) => ({
  name: capitalize(value),
  value
}));

const filterName = "deviceTypeIn";

export const deviceFilter = (
  filter: SessionRecordingFilter,
  onFilterChange: OnFilterChange<SessionRecordingFilter>
) => {
  const deviceTypeIn = filter[filterName] || [];
  const onChange = handleInFilterChange(filter, filterName, onFilterChange);

  return (
    <CheckboxInFilter
      key={filterName}
      name="Device"
      options={deviceOptions.map(({ name, value }) => ({
        name,
        value,
        selected: deviceTypeIn.includes(value as DeviceType)
      }))}
      onChange={onChange}
    />
  );
};
