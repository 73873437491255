import { Point } from "heatmap.js/build/heatmap.js";
import { groupBy } from "lodash";
import React from "react";
import styled from "styled-components/macro";
import { initWorker } from "testly-web/WebWorker";
import { heatmapViewPadding } from "../../config";
import { CalculatedClickPoint, CountedHeatmapElement } from "../ClickHeatmap";
import { fillRegionsWithAnalyticInfo, RegionWithAnalyticInfo } from "../Region";
import { Tooltip } from "../Tooltip";
import {
  matrixKernelDimension,
  pointsGroupRadius,
  pointsMinWeight,
  tooltipAddableRadius
} from "./config";
import { interKernel as initKernel } from "./heatmapMatrix/heatmapKernel";
import { applyKernel, initMatrix } from "./heatmapMatrix/heatmapMatrix";
import pointsReducerWorker, {
  PointsReducedEventData,
  ReducePointEventData
} from "./pointsReducer.worker";

const TooltipsStyled = styled.div`
  padding: 20px;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  transform: translateZ(0px);
`;

interface HeatmapTooltipsProps {
  heatmapElements: CountedHeatmapElement[];
  siteHeight: number;
  siteWidth: number;
  tooltipsAreGenerated(): void;
}

interface HeatmapTooltipsState {
  regions: RegionWithAnalyticInfo[];
}

export class HeatmapTooltips extends React.PureComponent<
  HeatmapTooltipsProps,
  HeatmapTooltipsState
> {
  private worker: Worker;
  private clickPoints: CalculatedClickPoint[] = [];
  private totalClicksCount: number = 0;

  constructor(props: HeatmapTooltipsProps) {
    super(props);

    this.state = {
      regions: []
    };
    this.worker = initWorker(pointsReducerWorker);
    this.worker.addEventListener("message", this.onPointsReduced);
  }

  public render() {
    return (
      <TooltipsStyled>
        {this.state.regions.map(region => (
          <Tooltip
            key={`${region.x}${region.y}`}
            width={region.x2 - region.x}
            height={region.y2 - region.y}
            top={region.y}
            left={region.x}
            clicksCount={region.clicksCount}
            clicksPercent={region.clicksCount / this.totalClicksCount}
          />
        ))}
      </TooltipsStyled>
    );
  }

  public componentDidMount() {
    const { heatmapElements, siteWidth, siteHeight } = this.props;

    this.clickPoints = this.groupClicks(
      heatmapElements.flatMap(
        ({ calculatedClickPoints }) => calculatedClickPoints
      )
    );
    this.totalClicksCount = this.clickPoints.reduce(
      (sum, point) => sum + point.count,
      0
    );
    const matrix = initMatrix(
      siteWidth + heatmapViewPadding * 2,
      siteHeight + heatmapViewPadding * 2
    );
    const kernel = initKernel(matrixKernelDimension);
    const filteredWeightPoints: Point[] = [];
    const heatmapMatrix = applyKernel(matrix, kernel, this.clickPoints);

    heatmapMatrix.forEach((pointsRow, y) => {
      pointsRow.forEach((value, x) => {
        if (value >= pointsMinWeight) {
          filteredWeightPoints.push({
            x,
            y,
            weight: value
          });
        }
      });
    });

    const event: ReducePointEventData = {
      eventName: "reducePoints",
      points: filteredWeightPoints,
      pointsGroupRadius
    };

    this.worker.postMessage(event);
  }

  private onPointsReduced = (ev: MessageEvent) => {
    const data: PointsReducedEventData = ev.data;

    if (data.eventName === "pointsReduced") {
      this.setState(
        {
          regions: fillRegionsWithAnalyticInfo(
            data.regions
              // the smaller elements should go first due to zIndex
              .sort(
                (a, b) => a.x2 - a.x + a.y2 - a.y - (b.x2 - b.x + b.y2 - b.y)
              )
              .map(region => ({
                ...region,
                x: region.x - tooltipAddableRadius,
                y: region.y - tooltipAddableRadius,
                x2: region.x2 + tooltipAddableRadius,
                y2: region.y2 + tooltipAddableRadius
              })),
            this.clickPoints
          )
        },
        () => {
          this.props.tooltipsAreGenerated();
        }
      );
    }
  };

  private groupClicks(clickPoints: CalculatedClickPoint[]) {
    return Object.values(
      // Some clicks can have the same top/left location. We groupd them
      groupBy(clickPoints, click => `${click.y}${click.x}`)
    ).map(clicks => {
      const firstClick = clicks[0];

      return {
        ...firstClick,
        count: clicks.reduce((sum, click) => sum + click.count, 0),
        weight: clicks.reduce((sum, click) => sum + click.weight, 0)
      };
    });
  }
}
