import { FieldArray, FormikProps, getIn } from "formik";
import React from "react";
import styled, { css } from "styled-components/macro";
import { initialGoalUrl, PathGoalStep } from "./pathGoal";
import { UrlInput, UrlInputControl } from "./UrlInput";

const orderNumberSize = 40;
const inputMargin = 16;

const PathBuilderStyled = styled.div`
  position: relative;

  &:after {
    position: absolute;
    content: "";
    width: 2px;
    height: 100%;
    background-color: #00ac69;
    left: ${orderNumberSize / 2}px;
    z-index: 0;
    top: 0;
  }
`;

// eslint-disable-next-line no-unexpected-multiline
const Row = styled.div<{
  last?: boolean;
}>`
  position: relative;

  ${({ last }) =>
    last &&
    css`
      &:after {
        top: ${orderNumberSize}px;
        position: absolute;
        height: calc(100% - ${orderNumberSize}px);
        width: ${orderNumberSize}px;
        content: "";
        background-color: #fff;

        z-index: 1;
      }
    `};
`;

const UrlOrderNumber = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  width: ${orderNumberSize}px;
  height: ${orderNumberSize}px;
  background-color: #fff;
  border: solid 2px #00ac69;
  border-radius: 50%;
  z-index: 1;

  font-size: 20px;
  color: #00ac69;

  left: 0;
`;

const InputWrapper = styled.div<{ last?: true }>`
  margin-left: ${orderNumberSize + inputMargin}px;
  padding-bottom: ${({ last }) => (last ? "0" : "22")}px;
`;

const Caption = styled.div`
  font-family: Roboto;
  font-size: 12px;
  color: #393839;
  margin-top: 8px;
  margin-bottom: 20px;
`;

export const PathBuilder: React.SFC<{
  formikProps: FormikProps<object>;
  fieldPrefix: string;
}> = ({ formikProps, fieldPrefix }) => {
  const path: PathGoalStep[] = getIn(
    formikProps.values,
    `${fieldPrefix}path`
  ) as PathGoalStep[];

  return (
    <>
      <Caption>
        Order of urls is important. The final url will be the Goal URL
      </Caption>
      <FieldArray
        name={`${fieldPrefix}path`}
        validateOnChange={false}
        render={arrayHelpers => (
          <PathBuilderStyled>
            {path.map((url, i) => (
              <Row key={i}>
                <UrlOrderNumber>{i + 1}</UrlOrderNumber>

                <InputWrapper>
                  <UrlInput
                    fieldPrefix={`${fieldPrefix}path.${i}.`}
                    urlInputAfterRender={() => (
                      <UrlInputControl
                        onAddClick={() => {
                          arrayHelpers.push(initialGoalUrl());
                        }}
                        onDeleteClick={() => {
                          arrayHelpers.remove(i);
                        }}
                        mode={i === path.length - 1 ? "addable" : "deletable"}
                      />
                    )}
                    {...formikProps}
                  />
                </InputWrapper>
              </Row>
            ))}
            <Row last={true}>
              <UrlOrderNumber>{path.length + 1}</UrlOrderNumber>

              <InputWrapper last={true}>
                <UrlInput
                  fieldPrefix={`${fieldPrefix}finalGoalUrl.`}
                  readOnly={true}
                  showMatchTypes={false}
                  {...formikProps}
                />
              </InputWrapper>
            </Row>
          </PathBuilderStyled>
        )}
      />
    </>
  );
};
