import moment from "moment";
import React from "react";
import {
  GetSessionRecordingsForListPageGoalEntityInlineFragment,
  SessionRecordingFilter
} from "testly-web/queries";
import { OnFilterChange } from "../../filters/AdvancedFilter/types";
import { DateFilter } from "../../filters/DateFilter/DateFilter";
import { FilterStyled } from "../../filters/style";
import {
  FilterName,
  SessionRecordingsAdvancedFilter
} from "../SessionRecordingsAdvancedFilter/SessionRecordingsAdvancedFilter";

export class Filter extends React.Component<{
  onFilterChange: OnFilterChange<SessionRecordingFilter>;
  filter: SessionRecordingFilter;
  projectGoals: GetSessionRecordingsForListPageGoalEntityInlineFragment[];
  splitTestGoals: GetSessionRecordingsForListPageGoalEntityInlineFragment[];
  filtersToShow: FilterName[];
  onFilterClose(): void;
}> {
  public render() {
    const {
      filter,
      onFilterChange,
      onFilterClose,
      projectGoals,
      splitTestGoals,
      filtersToShow
    } = this.props;

    return (
      <FilterStyled>
        <DateFilter
          onDateChange={this.handleDateChange}
          fromDate={
            filter.createdAtGteq ? new Date(filter.createdAtGteq) : null
          }
          toDate={filter.createdAtLteq ? new Date(filter.createdAtLteq) : null}
          onFilterClose={onFilterClose}
        />

        <SessionRecordingsAdvancedFilter
          projectGoals={projectGoals}
          splitTestGoals={splitTestGoals}
          filter={filter}
          onFilterChange={onFilterChange}
          onFilterClose={onFilterClose}
          filtersToShow={filtersToShow}
        />
      </FilterStyled>
    );
  }

  private handleDateChange = (fromDate: Date | null, toDate: Date | null) => {
    this.props.onFilterChange({
      ...this.props.filter,
      createdAtGteq:
        fromDate &&
        moment(fromDate)
          .startOf("day")
          .toISOString(),
      createdAtLteq:
        toDate &&
        moment(toDate)
          .endOf("day")
          .toISOString()
    });
  };
}
