import React from "react";
import { Route, Switch } from "react-router";
import { FeedbackPollEditPageContainer } from "./pages/FeedbackPollEditPage/FeedbackPollEditPageContainer";
import { FeedbackPollIndexPageContainer } from "./pages/FeedbackPollIndexPage/FeedbackPollIndexPageContainer";
import { FeedbackPollNewPageContainer } from "./pages/FeedbackPollNewPage/FeedbackPollNewPageContainer";
import { FeedbackPollResponseIndexPageContainer } from "./pages/FeedbackPollResponseIndexPage/FeedbackPollResponseIndexPageContainer";
import { GoalEditPageContainer } from "./pages/GoalEditPage/GoalEditPageContainer";
import { GoalsListPageContainer } from "./pages/GoalListPage/GoalsListPageContainer";
import { GoalNewPageContainer } from "./pages/GoalNewPage/GoalNewPageContainer";
import { HeatmapIndexPageContainer } from "./pages/HeatmapIndexPage/HeatmapIndexPageContainer";
import { HeatmapShowPageContainer } from "./pages/HeatmapShowPage/HeatmapShowPageContainer";
import { ProjectEditPageContainer } from "./pages/ProjectEditPage";
import { ProjectPageContainer } from "./pages/ProjectPage";
import { ProjectRedirectPage } from "./pages/ProjectRedirectPage";
import { ProjectSetupPageContainer } from "./pages/ProjectSetupPage";
import { SessionRecordingListPageContainer } from "./pages/SessionRecordingListPage/SessionRecordingListPageContainer";
import { SessionRecordingPageContainer } from "./pages/SessionRecordingPage/SessionRecordingPageContainer";
import { SplitTestEditPageContainer } from "./pages/SplitTestEditPage/SplitTestEditPageContainer";
import { SplitTestIndexPageContainer } from "./pages/SplitTestIndexPage";
import { SplitTestNewPageContainer } from "./pages/SplitTestNewPage/SplitTestNewPageContainer";
import { SplitTestSessionRecordingIndexPageContainer } from "./pages/SplitTestSessionRecordingIndexPage/SplitTestSessionRecordingIndexPageContainer";
import { SplitTestShowPageContainer } from "./pages/SplitTestShowPage/SplitTestShowPageContainer";
import { TestIdeaIndexPageContainer } from "./pages/TestIdeaIndexPage/TestIdeaIndexPageContainer";
import { PATHS } from "./paths";

export const Router = () => (
  <Switch>
    <Route
      exact={true}
      path={PATHS.SESSION_RECORDING_PATH}
      component={SessionRecordingPageContainer}
    />
    <Route
      exact={true}
      path={PATHS.SESSION_RECORDING_LIST_PATH}
      component={SessionRecordingListPageContainer}
    />

    <Route
      exact={true}
      path={PATHS.GOAL_NEW_PATH}
      component={GoalNewPageContainer}
    />
    <Route
      exact={true}
      path={PATHS.GOAL_EDIT_PATH}
      component={GoalEditPageContainer}
    />
    <Route
      exact={true}
      path={PATHS.GOAL_LIST_PATH}
      component={GoalsListPageContainer}
    />

    <Route
      exact={true}
      path={PATHS.SPLIT_TEST_INDEX_PATH}
      component={SplitTestIndexPageContainer}
    />

    <Route
      exact={true}
      path={PATHS.PROJECT_SETUP_PATH}
      component={ProjectSetupPageContainer}
    />
    <Route
      exact={true}
      path={PATHS.PROJECT_EDIT_PATH}
      component={ProjectEditPageContainer}
    />
    <Route
      exact={true}
      path={PATHS.PROJECT_PATH}
      component={ProjectPageContainer}
    />
    <Route
      exact={true}
      path={PATHS.SPLIT_TEST_NEW_PATH}
      component={SplitTestNewPageContainer}
    />
    <Route
      exact={true}
      path={PATHS.SPLIT_TEST_EDIT_PATH}
      component={SplitTestEditPageContainer}
    />
    <Route
      exact={true}
      path={PATHS.SPLIT_TEST_SHOW_PATH}
      component={SplitTestShowPageContainer}
    />
    <Route
      exact={true}
      path={PATHS.SPLIT_TEST_SESSION_RECORDING_INDEX_PAGE}
      component={SplitTestSessionRecordingIndexPageContainer}
    />
    <Route
      exact={true}
      path={PATHS.HEATMAP_INDEX_PATH}
      component={HeatmapIndexPageContainer}
    />
    <Route
      exact={true}
      path={PATHS.HEATMAP_SHOW_PATH}
      component={HeatmapShowPageContainer}
    />
    <Route
      exact={true}
      path={PATHS.TEST_IDEA_INDEX_PATH}
      component={TestIdeaIndexPageContainer}
    />

    <Route
      exact={true}
      path={PATHS.FEEDBACK_POLL_INDEX_PATH}
      component={FeedbackPollIndexPageContainer}
    />
    <Route
      exact={true}
      path={PATHS.FEEDBACK_POLL_NEW_PATH}
      component={FeedbackPollNewPageContainer}
    />
    <Route
      exact={true}
      path={PATHS.FEEDBACK_POLL_EDIT_PATH}
      component={FeedbackPollEditPageContainer}
    />
    <Route
      exact={true}
      path={PATHS.FEEDBACK_POLL_RESPONSE_INDEX_PATH}
      component={FeedbackPollResponseIndexPageContainer}
    />

    {/* Should be put at the end */}
    <Route path={PATHS.PROJECT_REDIRECT_PATH} component={ProjectRedirectPage} />
  </Switch>
);
