import React from "react";

export const OkIcon: React.SFC<React.SVGProps<SVGSVGElement>> = props => (
  // tslint:disable:max-line-length

  <svg width="18" height="17" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16.771 1.483L15.234.44a.933.933 0 0 0-1.292.244l-7.534 11.11L2.946 8.33a.93.93 0 0 0-1.312 0L.318 9.646a.934.934 0 0 0 0 1.316l5.324 5.324c.298.298.766.525 1.188.525.422 0 .847-.264 1.122-.663l9.067-13.376a.928.928 0 0 0-.248-1.289z"
      fill="#00AC69"
    />
  </svg>
);
