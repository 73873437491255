import React from "react";
import { toast } from "react-toastify";
import { ConfirmAlert } from "testly-web/components/ConfirmAlert";
import { onMutationError } from "testly-web/components/onFail";
import { DeleteGoalComponent as DeleteGoalMutation } from "testly-web/queries";

export const DeleteGoal: React.SFC<{
  goal: {
    id: string;
    name: string;
  };
  afterDelete?(): void;
  children(onDelete: (event: React.SyntheticEvent) => void): React.ReactNode;
}> = ({ goal: { id, name }, children, afterDelete }) => (
  <DeleteGoalMutation onError={onMutationError}>
    {deleteGoal => (
      <ConfirmAlert
        title={`Are you sure you wish to delete «${name}»? This cannot be undone.`}
      >
        {confirm =>
          children(
            confirm(async () => {
              await deleteGoal({ variables: { goalId: id } });

              toast.info("The goal was deleted.");

              if (afterDelete) {
                afterDelete();
              }
            })
          )
        }
      </ConfirmAlert>
    )}
  </DeleteGoalMutation>
);
