import { FormikActions } from "formik";
import { LocationDescriptor, LocationDescriptorObject } from "history";
import React from "react";
import { MutationFn } from "react-apollo";
import { Route } from "react-router";
import {
  onFormSubmitFail,
  onMutationError
} from "testly-web/components/onFail";
import { DetailsForm } from "testly-web/components/split-test-form/DetailsForm";
import {
  DetailsFormValues,
  SplitTestFormStep
} from "testly-web/components/split-test-form/schemas";
import { paths } from "testly-web/paths";
import {
  GetDataForSplitTestEditPagePageCategories,
  GetDataForSplitTestEditPagePageTypes,
  GetDataForSplitTestEditPageSplitTest,
  GetDataForSplitTestEditPageTestIdeas,
  SplitTestParams,
  UpdateSplitTestAtSplitTestEditPageComponent,
  UpdateSplitTestAtSplitTestEditPageMutation,
  UpdateSplitTestAtSplitTestEditPageVariables
} from "testly-web/queries";

const onSubmit = (
  projectId: string,
  splitTestId: string,
  updateSplitTest: MutationFn<
    UpdateSplitTestAtSplitTestEditPageMutation,
    UpdateSplitTestAtSplitTestEditPageVariables
  >,
  replace: (path: LocationDescriptorObject) => void
) => {
  return async (
    params: SplitTestParams,
    actions: FormikActions<DetailsFormValues>
  ) => {
    const response = await updateSplitTest({
      variables: {
        splitTestParams: params,
        splitTestId
      }
    });

    actions.setSubmitting(false);

    if (response && response.data && response.data.updateSplitTest) {
      const { successful, result } = response.data.updateSplitTest;

      if (successful && result) {
        replace({
          pathname: paths.splitTestEditPath({
            projectId,
            splitTestId: result.id,
            step: SplitTestFormStep.Variations
          })
        });
      } else {
        onFormSubmitFail(params, response.data);
      }
    }
  };
};

export const DetailsFormContainer: React.SFC<{
  pageCategories: GetDataForSplitTestEditPagePageCategories[];
  pageTypes: GetDataForSplitTestEditPagePageTypes[];
  testIdeas: GetDataForSplitTestEditPageTestIdeas[];
  splitTest: GetDataForSplitTestEditPageSplitTest;
  projectId: string;
  pathToStep(step: SplitTestFormStep): LocationDescriptor;
}> = ({ splitTest, projectId, pageCategories, pageTypes, testIdeas }) => (
  <Route
    render={({ history: { replace }, location: { state } }) => (
      <UpdateSplitTestAtSplitTestEditPageComponent onError={onMutationError}>
        {updateSplitTest => (
          <DetailsForm
            pageCategories={pageCategories}
            pageTypes={pageTypes}
            testIdeas={testIdeas}
            initialValues={{
              name: splitTest.name,
              description: splitTest.description,
              pageCategoryId: splitTest.pageCategory.id,
              pageTypeId: splitTest.pageType.id,
              testIdeaId: (splitTest.testIdea && splitTest.testIdea.id) || null
            }}
            onSubmit={onSubmit(projectId, splitTest.id, updateSplitTest, path =>
              replace({ ...path, state })
            )}
          />
        )}
      </UpdateSplitTestAtSplitTestEditPageComponent>
    )}
  />
);
