import styled, { css } from "styled-components/macro";

export const Column = styled.div<{ withoutTitle?: boolean }>`
  color: rgba(255, 255, 255, 0.5);
  margin-top: 40px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
  width: 170px;

  ${({ withoutTitle }) =>
    withoutTitle &&
    css`
      margin-top: 51px;
    `};
`;

export const ColumnTitle = styled.div`
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 1.7px;
  text-transform: uppercase;
`;
