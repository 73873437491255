import { FormikActions } from "formik";
import React from "react";
import styled from "styled-components/macro";
import { Checkbox } from "../../Form/Checkbox";
import { SettingsFormValues } from "../schemas";

const TrafficCheckbox = styled(Checkbox)`
  margin-top: 14px;

  &:first-child {
    margin-top: 0px;
  }
`;

export const CheckboxGroup: React.SFC<{
  currentValues: string[];
  checkboxes: Record<string, string>;
  name: string;
  setFieldValue: FormikActions<SettingsFormValues>["setFieldValue"];
}> = ({ currentValues, checkboxes, name, setFieldValue }) => {
  return (
    <>
      {Object.entries(checkboxes).map(([value, label]) => (
        <TrafficCheckbox
          key={value}
          label={label}
          green={true}
          light={true}
          checked={currentValues.includes(value)}
          onChange={e => {
            if (e.target.checked) {
              setFieldValue(name, [...currentValues, value]);
            } else {
              setFieldValue(name, currentValues.filter(v => v !== value));
            }
          }}
        />
      ))}
    </>
  );
};
