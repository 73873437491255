import { fromPairs } from "lodash";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { toast } from "react-toastify";
import { paths } from "testly-web/paths";
import { CreateNewProjectComponent } from "testly-web/queries";
import { onMutationError } from "../onFail";
import { NewProjectForm } from "./NewProjectForm";

const NewProjectFormContainerWithoutRouter: React.SFC<
  RouteComponentProps & { onCreate(): void }
> = ({ history, onCreate }) => (
  <CreateNewProjectComponent
    refetchQueries={["getProjectsForDropdown"]}
    onError={onMutationError}
  >
    {createNewProject => {
      return (
        <NewProjectForm
          onSubmit={async (values, actions) => {
            const response = await createNewProject({
              variables: {
                projectParams: { ...values, isRecordingEnabled: true }
              }
            });

            if (response && response.data && response.data.createProject) {
              const {
                successful,
                messages,
                result
              } = response.data.createProject;

              if (successful && result) {
                // notification here
                toast.success(
                  "Follow the steps below to start collecting data."
                );
                history.replace(
                  paths.projectSetupPath({ projectId: result.id })
                );
                actions.resetForm();
                onCreate();
              } else {
                actions.setErrors(
                  fromPairs(messages.map(m => [m.field, m.message]))
                );
              }
            }

            actions.setSubmitting(false);
          }}
        />
      );
    }}
  </CreateNewProjectComponent>
);

export const NewProjectFormContainer = withRouter(
  NewProjectFormContainerWithoutRouter
);
