import queryString from "qs";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { SetCurrentProjectId } from "testly-web/components/CurrentProjectId/SetCurrentProjectId";
import { SetNavbar } from "testly-web/components/Navbar/SetNavbar";
import { paths } from "testly-web/paths";
import { SplitTestNewPage } from "./SplitTestNewPage";

export const SplitTestNewPageContainer: React.SFC<
  RouteComponentProps<{ projectId: string }>
> = ({
  history,
  match: {
    params: { projectId }
  },
  location: { state: locationState, search }
}) => {
  const previousPath =
    locationState && locationState.previousPath
      ? locationState.previousPath
      : paths.projectPath({ projectId });

  const parsedQuery: {
    ideaId?: string;
  } = queryString.parse(search, { ignoreQueryPrefix: true }) || {};

  return (
    <>
      <SetCurrentProjectId id={projectId} />
      <SetNavbar header="Create Split Test" backPath={previousPath} />
      <SplitTestNewPage
        projectId={projectId}
        testIdeaId={parsedQuery.ideaId || null}
      />
    </>
  );
};
