import React from "react";

export const ArrowUpIcon: React.SFC<React.SVGProps<SVGSVGElement>> = props => (
  // tslint:disable:max-line-length
  <svg width="14" height="14" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.01 7a6.982 6.982 0 0 0-2.06-4.95 7.004 7.004 0 0 0-9.9 0 7.004 7.004 0 0 0 0 9.9 7.004 7.004 0 0 0 9.9 0A6.982 6.982 0 0 0 14.01 7zm-1.402 0a5.583 5.583 0 0 1-1.643 3.965 5.596 5.596 0 0 1-7.93 0 5.596 5.596 0 0 1 0-7.93 5.596 5.596 0 0 1 7.93 0A5.583 5.583 0 0 1 12.608 7zM9.804 4.196H4.196v1.402h3.198L3.692 9.3 4.7 10.308l3.702-3.702v3.198h1.402V4.196z"
      fill="#34CF8C"
    />
  </svg>
);
