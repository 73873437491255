import React from "react";
import DayPickerInputFromLib from "react-day-picker/DayPickerInput";
import { DayPickerInput } from "testly-web/components/DayPickerInput/DayPickerInput";
import { DateContainer, DateFilterStyled, DateName } from "./styles";
import { OnDateChange } from "./types";

export interface DateFilterProps {
  fromDate: Date | null;
  toDate: Date | null;
  onDateChange: OnDateChange;
  onFilterClose(): void;
}

export interface DateFilterUIProps {
  opened: boolean;
  closedByUser: boolean;
  innerRef: React.RefObject<HTMLDivElement>;
}

export class DateFilterDropbox extends React.Component<
  DateFilterProps & DateFilterUIProps
> {
  private toInputRef: React.RefObject<DayPickerInputFromLib>;

  constructor(props: DateFilterProps & DateFilterUIProps) {
    super(props);

    this.toInputRef = React.createRef<DayPickerInputFromLib>();
  }

  public render() {
    const { fromDate, toDate, closedByUser, opened, innerRef } = this.props;

    const fromModifiers =
      fromDate && toDate
        ? { start: fromDate, end: toDate }
        : { singleSelected: fromDate, toSelected: toDate };

    const toModifiers =
      fromDate && toDate
        ? { start: fromDate, end: toDate }
        : { singleSelected: toDate, fromSelected: fromDate };

    return (
      <DateFilterStyled
        opened={opened}
        closedByUser={closedByUser}
        ref={innerRef as React.RefObject<HTMLDivElement> & string}
      >
        <DateContainer>
          <DateName>START DATE</DateName>
          <DayPickerInput
            date={this.props.fromDate}
            dayPickerProps={{
              selectedDays:
                fromDate && toDate
                  ? [fromDate, { from: fromDate, to: toDate }]
                  : fromDate,
              disabledDays: this.props.toDate && { after: this.props.toDate },
              modifiers: fromModifiers,
              onDayClick: () => {
                (this.toInputRef.current!.getInput() as HTMLInputElement).focus();
              }
            }}
            onDateChange={day => {
              this.props.onDateChange(day, this.props.toDate);
            }}
            onResetClick={() => {
              this.props.onDateChange(null, this.props.toDate);
            }}
          />
        </DateContainer>

        <DateContainer>
          <DateName>END DATE</DateName>
          <DayPickerInput
            date={this.props.toDate}
            dayPickerProps={{
              selectedDays:
                fromDate && toDate
                  ? [fromDate, { from: fromDate, to: toDate }]
                  : toDate,
              disabledDays: this.props.fromDate && {
                before: this.props.fromDate
              },
              modifiers: toModifiers
            }}
            onDateChange={day => {
              this.props.onDateChange(this.props.fromDate, day);
            }}
            onResetClick={() => {
              this.props.onDateChange(this.props.fromDate, null);
            }}
            innerRef={this.toInputRef}
          />
        </DateContainer>
      </DateFilterStyled>
    );
  }
}
