import React from "react";
import { Dropdown } from ".";
import { DropdownProps } from "./Dropdown";
import { DropdownController } from "./DropdownController";

interface DropdownContainerProps {
  className?: string;
  showPointer?: boolean;
  dropdownEnabled?: boolean;
  animation: DropdownProps["animation"];
  dropdownContent(): React.ReactNode;
}

class TypedDropdownController extends DropdownController<
  HTMLDivElement,
  HTMLDivElement
> {}

export const DropdownContainer: React.SFC<DropdownContainerProps> = ({
  className,
  children,
  dropdownContent,
  showPointer,
  dropdownEnabled,
  animation
}) => (
  <>
    {dropdownEnabled === false ? (
      children
    ) : (
      <TypedDropdownController>
        {({
          wrapperRef,
          togglerRef,
          onControlClick,
          closeAnimationIsRunning,
          opened,
          close
        }) => (
          <Dropdown
            className={className}
            dropdownContent={dropdownContent}
            wrapperRef={wrapperRef}
            onControlClick={onControlClick}
            closeAnimationIsRunning={closeAnimationIsRunning}
            opened={opened}
            togglerRef={togglerRef}
            showPointer={showPointer ? showPointer : false}
            animation={animation}
            close={close}
          >
            {children}
          </Dropdown>
        )}
      </TypedDropdownController>
    )}
  </>
);
