import { get } from "lodash";
import React from "react";
import { Route } from "react-router";
import { toast } from "react-toastify";
import { mapSplitTest } from "testly-web/components/split-test-form/mapSplitTest";
import { SplitTestFormStep } from "testly-web/components/split-test-form/schemas";
import { actionsNameInPastMap } from "testly-web/components/split-test/actionsNameInPastMap";
import { paths } from "testly-web/paths";
import { GetSplitTestForSplitTestIndexPageComponent } from "testly-web/queries";
import { SpitTestActionMutations } from "../SplitTestActionMutations";
import { SplitTestCard, SplitTestType } from "./SplitTestCard";

interface SplitTestCardProps {
  splitTest: SplitTestType;
  expanded: boolean;
  className?: string;
  projectId: string;
  onExpandClick(): void;
}

export class SplitTestCardContainer extends React.Component<
  SplitTestCardProps,
  { selectedGoalId: string | null }
> {
  constructor(props: SplitTestCardProps) {
    super(props);

    this.state = { selectedGoalId: null };
  }

  public render() {
    const projectId = this.props.projectId;
    const splitTestId = this.props.splitTest.id;

    return (
      <Route
        render={({ history: { push } }) => (
          <GetSplitTestForSplitTestIndexPageComponent
            skip={!this.state.selectedGoalId}
            variables={{
              splitTestId,
              conversionGoalId: this.state.selectedGoalId || "",
              projectId
            }}
          >
            {({ data, loading: goalChangeLoading }) => {
              const splitTest = (() => {
                if (get(data, "currentUser.project.splitTest")) {
                  return mapSplitTest(data!.currentUser!.project!.splitTest!);
                } else {
                  return this.props.splitTest;
                }
              })();

              return (
                <SpitTestActionMutations
                  onCompleted={() => {
                    toast.success(
                      `Split test ${actionsNameInPastMap[splitTest.status]}!`
                    );
                  }}
                  onError={() => {
                    toast.error("Split test update failed. Please, try again.");
                  }}
                  variables={{
                    splitTestId,
                    conversionGoalId: this.state.selectedGoalId
                  }}
                >
                  {(splitTestActions, isUpdating) => (
                    <SplitTestCard
                      {...this.props}
                      splitTestShowPath={paths.splitTestShowPath({
                        projectId,
                        splitTestId
                      })}
                      isUpdating={isUpdating}
                      goalChangeLoading={goalChangeLoading}
                      onGoalSelect={goalId => {
                        this.setState({ selectedGoalId: goalId });
                      }}
                      splitTestActions={{
                        ...splitTestActions,
                        finishSetup: () => {
                          push({
                            pathname: paths.splitTestEditPath({
                              projectId,
                              splitTestId,
                              step: SplitTestFormStep.Details
                            }),
                            state: {
                              previousPath: `${window.location.pathname}${
                                window.location.search
                              }`
                            }
                          });
                        }
                      }}
                      splitTest={splitTest}
                    />
                  )}
                </SpitTestActionMutations>
              );
            }}
          </GetSplitTestForSplitTestIndexPageComponent>
        )}
      />
    );
  }
}
