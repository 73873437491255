import { every, isEqual } from "lodash";
import { SessionEvent } from "session-player/eventTypes";
import { Timeline } from "./types";

type Listener = (timelineManager: TimelineManager) => void;

export class TimelineManager {
  private timeline?: Timeline;
  private originalEvents: SessionEvent[];
  private allEvents: SessionEvent[] = [];
  private eventsOnTimeline: SessionEvent[] = [];

  private timelineChangedListeners: Listener[] = [];

  constructor(events: SessionEvent[], timeline: Timeline) {
    this.originalEvents = events;
    this.setTimeline(timeline);
  }

  public onTimelineChange(changesListener: Listener): void {
    this.timelineChangedListeners.push(changesListener);
  }

  public mergeEvents(newEvents: SessionEvent[]) {
    const lastEventsTimestamp = this.originalEvents[
      this.originalEvents.length - 1
    ].happenedAt;
    const eventsWithLastTimestamp = this.originalEvents.filter(
      e => e.happenedAt === lastEventsTimestamp
    );

    const biggerEvents = newEvents.filter(
      e => e.happenedAt > lastEventsTimestamp
    );
    const sameTimestampEvents = newEvents
      .filter(e => e.happenedAt === lastEventsTimestamp)
      .filter(e => every(eventsWithLastTimestamp, e2 => !isEqual(e2, e)));

    this.originalEvents = this.originalEvents
      .concat(sameTimestampEvents)
      .concat(biggerEvents);
  }

  public getAllEvents() {
    return this.allEvents;
  }

  public getEventsOnTimeline() {
    return this.eventsOnTimeline;
  }

  public setTimeline(timeline: Timeline) {
    if (isEqual(this.timeline, timeline)) {
      return;
    }

    if (timeline.fromTime > timeline.toTime) {
      throw new Error(`FromTime > ToTime for ${JSON.stringify(timeline)}`);
    }

    const previousTimeline = this.timeline;
    this.timeline = timeline;

    this.allEvents = this.originalEvents
      .filter(({ happenedAt }) => happenedAt <= timeline.toTime)
      .map(event => {
        const time = event.happenedAt - timeline.fromTime;

        return { ...event, time, onTimeline: time >= 0 };
      })
      .sort((a, b) => a.time - b.time);

    this.eventsOnTimeline = this.allEvents.filter(
      ({ onTimeline }) => onTimeline
    );

    if (previousTimeline && previousTimeline.fromTime !== timeline.fromTime) {
      this.notifyTinelineChangedListeners();
    }
  }

  public getTimeLine() {
    return this.timeline;
  }

  public duration() {
    if (!this.timeline) {
      throw new Error("Timeline is not set");
    }

    return this.timeline.toTime - this.timeline.fromTime;
  }

  private notifyTinelineChangedListeners() {
    for (const listener of this.timelineChangedListeners) {
      listener(this);
    }
  }
}
