import React from "react";
import styled, { css, keyframes } from "styled-components/macro";

export interface ButtonStyledProps {
  action: "primary" | "secondary" | "thirdly" | "dangerous" | "link";
  disabled?: boolean;
  loading?: boolean;
  size: "small" | "medium" | "big";
  fullWidth?: boolean;
  dropdown?: boolean;
}

export const clickKeyframes = keyframes`
  from { transform: translate(0, 0); }
  30% { transform: translate(3px, 3px); }
  to { transform: translate(0, 0); }
`;

const loadingFrames = keyframes`
  from {left: -95%; }
  to {left: 100%;}
`;

const primaryBgColor = "#00ac69";
const thirdlyBgColor = "#19b5fe";

const LoadingIndicator = styled.div`
  display: block;
  z-index: 0;
  position: absolute;
  top: 0;
  content: "";
  width: 95%;
  height: 3px;
  background-color: rgba(0, 0, 0, 0.7);
  animation: ${loadingFrames} 1s ease infinite;

  z-index: 0;
`;

const dropdownPartWidth = 26;

export const ButtonText = styled.div<{ withDropdown: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  flex: 1;

  z-index: 0;

  white-space: nowrap;

  width: 100%;
  height: 100%;

  ${({ withDropdown }) =>
    withDropdown &&
    css`
      padding-right: ${dropdownPartWidth}px;
    `}
`;

const DropdownPart = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${dropdownPartWidth}px;
  border-left: 1px solid;
  position: absolute;
  height: 100%;
  right: 0px;
  border-color: rgba(0, 0, 0, 0.1);

  &::before {
    content: " ";
    display: inline-block;
    vertical-align: middle;
    margin-left: 16px;
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 4px solid #fff;
    margin: 0 auto;
  }
`;

export const StyledButton = styled.button<ButtonStyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);

  padding: 12px;
  cursor: pointer;

  font-family: Roboto;
  font-weight: 500;

  letter-spacing: 0.6px;
  text-align: center;
  color: #ffffff;
  outline: none;
  border: none;

  transition: opacity 0.3s ease-in-out;

  position: relative;
  overflow: hidden;

  box-sizing: border-box;

  &:hover {
    opacity: 0.9;
  }

  &:active {
    animation: ${clickKeyframes} 0.3s ease-out;
  }

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `};

  ${({ size }) =>
    size === "small" &&
    css`
      font-size: 14px;
      height: 40px;
      padding-top: 5px;
      padding-bottom: 5px;
    `};
  ${({ size }) =>
    size === "medium" &&
    css`
      font-size: 16px;
      height: 50px;
    `};

  ${({ action }) =>
    action === "primary" &&
    css`
      background-color: ${primaryBgColor};
    `};

  ${({ action }) =>
    action === "secondary" &&
    css`
      background-color: #ffffff;
      border: solid 1px #dcdae0;
      color: #393839;
    `};

  ${({ action }) =>
    action === "thirdly" &&
    css`
      background-color: ${thirdlyBgColor};
    `};

  ${({ action }) =>
    action === "dangerous" &&
    css`
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);
      border: solid 1px #dcdae0;
      background-color: #ff5858;
      color: #fff;
    `};

  ${({ action }) =>
    action === "link" &&
    css`
      background-color: #ffffff;
      border: none;
      border-radius: 0px;
      color: #393839;
      background-color: transparent;
      box-shadow: none;
    `};

  ${({ disabled, action }) =>
    disabled &&
    action !== "link" &&
    css`
      cursor: default;
      pointer-events: none;
      color: #fff;
      background-color: #ecedee;
      border: none;
    `};
`;

export const Button: React.SFC<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > &
    ButtonStyledProps
> = ({ children, className, onClick, loading, ref, ...props }) => (
  <StyledButton
    className={className}
    onClick={onClick}
    onTouchStart={() => null}
    {...props}
  >
    {loading === true && <LoadingIndicator />}
    <ButtonText withDropdown={props.dropdown ? true : false}>
      {children}
    </ButtonText>
    {props.dropdown && <DropdownPart />}
  </StyledButton>
);
