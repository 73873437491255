import React from "react";

export const ResolutionIcon = () => (
  // tslint:disable:max-line-length
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path
      fill="#A7A9AE"
      d="M.923 0A.923.923 0 0 0 0 .923v12.923c0 .51.413.923.923.923H8v-1.846H1.538a.307.307 0 0 1-.307-.308V1.538c0-.17.137-.307.307-.307h8.616c.17 0 .308.137.308.307v2.77h1.23V.923A.923.923 0 0 0 10.77 0H.923zm4.48.404h.885c.12 0 .212.094.212.211a.209.209 0 0 1-.212.212h-.884a.209.209 0 0 1-.212-.212c0-.117.092-.211.212-.211zm4.135 4.52a.923.923 0 0 0-.923.922v9.23c0 .51.414.924.923.924h5.539c.51 0 .923-.413.923-.923v-9.23a.923.923 0 0 0-.923-.924H9.538zm2.327.403h.885c.12 0 .212.094.212.211a.209.209 0 0 1-.212.212h-.885a.209.209 0 0 1-.211-.212c0-.117.091-.211.211-.211zm-1.711.827h4.308c.17 0 .307.137.307.308v7.384c0 .17-.137.308-.307.308h-4.308a.307.307 0 0 1-.308-.308V6.462c0-.171.137-.308.308-.308zm-4.308 7.154c.305 0 .558.238.558.538 0 .3-.25.539-.558.539a.547.547 0 0 1-.558-.539c0-.3.253-.538.558-.538zm6.462 1.23c.305 0 .557.238.557.539 0 .3-.25.538-.557.538a.547.547 0 0 1-.558-.538c0-.3.252-.539.558-.539z"
    />
  </svg>
);
