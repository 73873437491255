import React from "react";

export const CalendarIcon: React.SFC<{ dark?: boolean }> = ({ dark }) => (
  // tslint:disable:max-line-length
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path
      fill={dark ? "#393839" : "#A7A9AE"}
      d="M4.308 0c-.37 0-.616.245-.616.615v1.847c0 .37.245.615.616.615.37 0 .615-.245.615-.615V.615c0-.37-.245-.615-.615-.615zm7.384 0c-.37 0-.615.245-.615.615v1.847c0 .37.245.615.615.615s.616-.245.616-.615V.615c0-.37-.245-.615-.616-.615zM1.846 1.23C.8 1.23 0 2.032 0 3.078v11.077C0 15.2.8 16 1.846 16h12.308C15.2 16 16 15.2 16 14.154V3.077c0-1.046-.8-1.846-1.846-1.846h-1.23v1.23c0 .678-.554 1.231-1.232 1.231-.678 0-1.23-.553-1.23-1.23V1.23H5.538v1.23c0 .678-.552 1.231-1.23 1.231-.678 0-1.231-.553-1.231-1.23V1.23h-1.23zm-.615 4.308h13.538v8.616c0 .37-.245.615-.615.615H1.846c-.37 0-.615-.245-.615-.615V5.538zm4.923 6.77v1.23h1.23v-1.23h-1.23zm-2.462 0v1.23h1.231v-1.23h-1.23zm7.385-2.462v1.23h1.23v-1.23h-1.23zm-2.462 0v1.23h1.231v-1.23h-1.23zm-2.461 0v1.23h1.23v-1.23h-1.23zm-2.462 0v1.23h1.231v-1.23h-1.23zm7.385-2.461v1.23h1.23v-1.23h-1.23zm-2.462 0v1.23h1.231v-1.23h-1.23zm-2.461 0v1.23h1.23v-1.23h-1.23zm-2.462 0v1.23h1.231v-1.23h-1.23z"
    />
  </svg>
);
