import React from "react";
import styled from "styled-components/macro";
import { Checkbox } from "testly-web/components/Form/Checkbox";
import { OptionType } from "./types";

const CheckboxStyled = styled(Checkbox)`
  margin-bottom: 12px;
`;

export const CheckboxOption: React.SFC<{
  option: OptionType;
  onChange(option: OptionType): void;
}> = ({ option, onChange }) => (
  <CheckboxStyled
    green={true}
    checked={option.selected}
    onChange={() => {
      onChange(option);
    }}
    name={`value_${option.value}`}
    label={option.name}
  />
);
