import { Form, Formik, FormikProps } from "formik";
import { fromPairs, pick, startCase } from "lodash";
import React from "react";
import { MutationFn } from "react-apollo";
import { toast } from "react-toastify";
import styled from "styled-components";
import {
  UpdatePasswordAtAccountSettingsMutation,
  UpdatePasswordAtAccountSettingsVariables
} from "testly-web/queries";
import * as Yup from "yup";
import { TextField } from "../Form/Field";
import { AccountSettingsControls } from "./AccountSettingsControls";

const StyledAccountSettingsProfile = styled.div`
  display: flex;
  flex-direction: column;

  padding: 25px 40px;
`;

interface PasswordFormValues {
  currentPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
}

const schema = Yup.object().shape({
  currentPassword: Yup.string()
    .label("Current Password")
    .required()
    .min(6),
  newPassword: Yup.string()
    .label("New Password")
    .required()
    .min(6),
  newPasswordConfirmation: Yup.string()
    .label("Password confirmation")
    .required()
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    .min(6)
});

export const AccountSettingsPasswordForm: React.SFC<{
  updatePassword: MutationFn<
    UpdatePasswordAtAccountSettingsMutation,
    UpdatePasswordAtAccountSettingsVariables
  >;
  userId: string;
}> = ({ updatePassword, userId }) => (
  <Formik
    initialValues={{
      currentPassword: "",
      newPassword: "",
      newPasswordConfirmation: ""
    }}
    onSubmit={async (values, actions) => {
      const response = await updatePassword({
        variables: {
          passwordParams: pick(values, ["currentPassword", "newPassword"]),
          userId
        }
      });

      if (response && response.data && response.data.updatePassword) {
        const { successful, messages } = response.data.updatePassword;

        if (successful) {
          toast.success("Password is updated");
          actions.resetForm();
        } else {
          actions.setErrors(
            // TODO: humanize
            fromPairs(
              messages.map(m => [
                m.field,
                `${startCase(m.field || "")} ${m.message}`
              ])
            )
          );
        }
      }
      actions.setSubmitting(false);
    }}
    validationSchema={schema}
    validateOnBlur={false}
    validateOnChange={false}
  >
    {(passwordProps: FormikProps<PasswordFormValues>) => (
      <Form>
        <StyledAccountSettingsProfile>
          <TextField
            inputType="password"
            label="Current Password"
            name="currentPassword"
            {...passwordProps}
          />
          <TextField
            inputType="password"
            label="New Password"
            name="newPassword"
            {...passwordProps}
          />

          <TextField
            inputType="password"
            label="Confirm New Password"
            name="newPasswordConfirmation"
            {...passwordProps}
          />
        </StyledAccountSettingsProfile>
        <AccountSettingsControls reset={passwordProps.handleReset} />
      </Form>
    )}
  </Formik>
);
