import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { CheckCodeModal } from "testly-web/components/CheckCodeModal/CheckCodeModal";
// import upgradePlan from "testly-web/icons/upgrade-plan.svg";
import { paths } from "testly-web/paths";
import { Plan } from "testly-web/types";

const kFormatter = (num: number) =>
  num > 999 ? `${(num / 1000).toFixed(0)}K` : num.toString();

interface BillingWithLinksProps {
  currentPlan: Plan;
  currentVisitorsAmount: number;
  visitorsLimit: number;
  project: {
    id: string;
    domain: string;
  };
}

const StyledBillingWithLinks = styled.div``;
const Row = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  font-size: 16px;
  color: #393839;
  line-height: 2;
`;
const RowCaption = styled.div`
  font-size: 16px;
  color: #393839;
`;
const RowValue = styled.div``;
const CurrentVisitorsAmount = styled.span`
  font-size: 14px;
  color: #393839;
`;
const CurrentVisitorsLimit = styled.span`
  font-size: 14px;
  color: #a7a9ae;
`;
// const VisitorsProgressBar = styled.div`
//   width: 100%;
//   border-radius: 7px;
//   background-color: #ecedee;
//   height: 13px;
// `;
const ItemLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  color: #19b5fe;
  margin-top: 20px;
  line-height: 1.78;

  vertical-align: middle;

  img {
    vertical-align: middle;
    margin-bottom: 7px;
    margin-right: 9px;
  }
`;
const CurrentPlan = styled.div<{ plan: Plan }>`
  font-size: 16px;
  font-weight: 500;
  color: #00ac69;
  text-transform: uppercase;
`;

// <ItemLink to="#">
//   <img src={upgradePlan} alt="Upgrade Plan Icon" />
//   Upgrade Your Plan
// </ItemLink>

export const BillingWithLinks: React.SFC<BillingWithLinksProps> = ({
  currentPlan,
  currentVisitorsAmount,
  visitorsLimit,
  project
}) => (
  <StyledBillingWithLinks>
    <Row>
      <RowCaption>Current plan:</RowCaption>
      <RowValue>
        <CurrentPlan plan={currentPlan}>{currentPlan}</CurrentPlan>
      </RowValue>
    </Row>
    <Row>
      <RowCaption>Visitors:</RowCaption>
      <RowValue>
        <CurrentVisitorsAmount>
          {kFormatter(currentVisitorsAmount)}
        </CurrentVisitorsAmount>
        <CurrentVisitorsLimit>
          /{kFormatter(visitorsLimit)}
        </CurrentVisitorsLimit>
      </RowValue>
      <ItemLink to={paths.projectSetupPath({ projectId: project.id })}>
        Code Installation Guide
      </ItemLink>

      <CheckCodeModal
        url={`https://${project.domain}`}
        projectId={project.id}
        key={project.id}
      >
        {open => (
          <ItemLink to="#" onClick={open}>
            Check code installation
          </ItemLink>
        )}
      </CheckCodeModal>
    </Row>
  </StyledBillingWithLinks>
);
