import { FormikProps } from "formik";
import React from "react";
import { Button } from "testly-web/components/Button";
import { DeleteGoal } from "testly-web/mutations/DeleteGoal/DeleteGoal";
import { GetDataForGoalEditPageGoal } from "testly-web/queries";
import { GoalForm } from "../../components/goal/GoalForm";
import { PathGoalForm } from "../../components/goal/pathGoal";

export const GoalEditPage: React.SFC<{
  formProps: FormikProps<PathGoalForm>;
  goal: GetDataForGoalEditPageGoal;
  afterDelete(): void;
}> = ({ formProps, goal, afterDelete }) => (
  <GoalForm
    formProps={formProps}
    footerRender={(isSubmitting: boolean) => (
      <>
        <DeleteGoal goal={goal} afterDelete={afterDelete}>
          {confirm => (
            <Button
              action="dangerous"
              size="small"
              type="button"
              onClick={confirm}
            >
              Delete
            </Button>
          )}
        </DeleteGoal>
        <Button
          action="primary"
          size="small"
          type="submit"
          disabled={isSubmitting}
          loading={isSubmitting}
        >
          Update Goal
        </Button>
      </>
    )}
  />
);
