import React from "react";

export const MapIcon = () => (
  // tslint:disable:max-line-length
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path
      fill="#A7A9AE"
      d="M0 0v14.154L4.308 16 8 14.154l3.673 1.577.635.269L16 14.154V0l-3.173 1.577c.156.387.248.812.27 1.25L14.768 2v11.385l-2.5 1.25-.73-.308c-.517-2.868-2.207-3.385-3.539-3.385-.334 0-.596.397-1.23.039-1.073-.604-.73-2.27-2.462-2.366-1.034-1.033-2.327-1.23-3.077-1.25v-5.5l2.596 1.116c.156.067.315.096.48.096.19 0 .385-.048.558-.135l.5-.25c.05-.596.229-1.144.52-1.634l-1.577.788L0 0zm9.23.058a2.963 2.963 0 0 0-2.96 2.961c0 1.635 2.576 4.57 2.576 6.77h.77c0-2.193 2.576-5.267 2.576-6.77A2.961 2.961 0 0 0 9.231.058zm0 1.673c.72 0 1.308.57 1.308 1.288 0 .719-.588 1.308-1.307 1.308a1.312 1.312 0 0 1-1.308-1.308c0-.719.589-1.288 1.308-1.288z"
    />
  </svg>
);
