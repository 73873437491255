import React from "react";
import styled from "styled-components/macro";
import { GetSessionRecordingForRecordingPageFeedbackPollResponses } from "testly-web/queries";
import { toMMSS } from "testly-web/utils";
import { Time } from "../styles";

const AnswerContainer = styled.div`
  display: flex;
  width: 100%;
  border-bottom: solid 1px #ecedee;
  cursor: pointer;

  padding: 14px 21px;
  box-sizing: border-box;
  align-items: center;
  padding-right: 40px;
`;

const Question = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #1e1d1e;
  text-overflow: ellipsis;

  /* Required for text-overflow to do anything */
  white-space: nowrap;
  overflow: hidden;
`;

const Answer = styled.div`
  font-size: 12px;
  color: #a7a9ae;
  margin-top: 5px;
`;

const TimeStyled = styled(Time)`
  display: inline-flex;
  margin-left: auto;
  height: 13px;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-width: 0;
`;

export const PollAnswersDropdown: React.SFC<{
  pollResponse: GetSessionRecordingForRecordingPageFeedbackPollResponses;
  firstEventAt: number;
  onPollAnswerClick: (time: number) => void;
}> = ({ pollResponse, firstEventAt, onPollAnswerClick }) => {
  return (
    <>
      {pollResponse.answers
        .map(a => ({ ...a, answeredAt: Date.parse(a.answeredAt) }))
        .sort((a, b) => a.answeredAt - b.answeredAt)
        .map(answer => (
          <AnswerContainer
            key={answer.id}
            onClick={() => onPollAnswerClick(answer.answeredAt - firstEventAt)}
          >
            <Container>
              <Question>{answer.question.title}</Question>
              <Answer>{answer.content}</Answer>
            </Container>
            <TimeStyled>
              {toMMSS((answer.answeredAt - firstEventAt) / 1000)}
            </TimeStyled>
          </AnswerContainer>
        ))}
    </>
  );
};
