import React from "react";
import { OnFilterChange } from "./types";

interface OptimisticFilterProps<T> {
  filter: T;
  onFilterChange: OnFilterChange<T>;
  children(
    filter: T,
    onFilterChange: OnFilterChange<T>,
    handleFilterClose: () => void
  ): React.ReactNode;
}

// It calls onFilterChange prop on AdvancedFilter close(to make filter request to the server)
export class OptimisticFilter<T> extends React.Component<
  OptimisticFilterProps<T>,
  { filter: T }
> {
  constructor(props: OptimisticFilterProps<T>) {
    super(props);

    this.state = { filter: props.filter };
  }

  public render() {
    return this.props.children(
      this.state.filter,
      this.handleFilterChange,
      this.handleFilterClose
    );
  }

  private handleFilterChange = (filter: T): void => {
    this.setState({ filter });
  };

  private handleFilterClose = (): void => {
    this.props.onFilterChange(this.state.filter);
  };
}
