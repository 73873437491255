import { FormikActions } from "formik";
import { History } from "history";
import React from "react";
import { MutationFn } from "react-apollo";
import { Route } from "react-router";
import { loadingRender } from "testly-web/components/loadingRender";
import {
  onFormSubmitFail,
  onMutationError
} from "testly-web/components/onFail";
import { DetailsForm } from "testly-web/components/split-test-form/DetailsForm";
import {
  DetailsFormValues,
  SplitTestFormStep
} from "testly-web/components/split-test-form/schemas";
import { paths } from "testly-web/paths";
import {
  CreateSplitTestAtSplitTestCreatePageComponent,
  CreateSplitTestAtSplitTestCreatePageMutation,
  CreateSplitTestAtSplitTestCreatePageVariables,
  GetDataForSplitTestNewPageComponent,
  SplitTestParams
} from "testly-web/queries";

const onSubmit = (
  projectId: string,
  createSplitTest: MutationFn<
    CreateSplitTestAtSplitTestCreatePageMutation,
    CreateSplitTestAtSplitTestCreatePageVariables
  >,
  replace: History["replace"]
) => {
  return async (
    params: SplitTestParams,
    actions: FormikActions<DetailsFormValues>
  ) => {
    const response = await createSplitTest({
      variables: {
        splitTestParams: params,
        projectId
      }
    });

    actions.setSubmitting(false);

    if (response && response.data && response.data.createSplitTest) {
      const { successful, result } = response.data.createSplitTest;

      if (successful && result) {
        replace({
          pathname: paths.splitTestEditPath({
            projectId,
            splitTestId: result.id,
            step: SplitTestFormStep.Variations
          }),
          state: {
            fromCreateStep: true
          }
        });
      } else {
        onFormSubmitFail(params, response.data);
      }
    }
  };
};

export const DetailsFormContainer: React.SFC<{
  projectId: string;
  testIdeaId: string | null;
}> = ({ projectId, testIdeaId }) => (
  <Route
    render={({ history: { replace } }) => (
      <CreateSplitTestAtSplitTestCreatePageComponent onError={onMutationError}>
        {createSplitTest => (
          <GetDataForSplitTestNewPageComponent>
            {loadingRender("pageCategories", ({ data }) => (
              <DetailsForm
                pageCategories={data!.pageCategories}
                pageTypes={data!.pageTypes}
                testIdeas={data!.testIdeas}
                initialValues={{
                  name: "",
                  description: "",
                  pageCategoryId: "",
                  pageTypeId: "",
                  testIdeaId
                }}
                onSubmit={onSubmit(projectId, createSplitTest, replace)}
              />
            ))}
          </GetDataForSplitTestNewPageComponent>
        )}
      </CreateSplitTestAtSplitTestCreatePageComponent>
    )}
  />
);
