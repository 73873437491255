import { lighten } from "polished";
import React from "react";
import styled from "styled-components/macro";

const color = "#a7a9ae";

const ExpandStyled = styled.svg`
  fill: ${color};

  transition: fill 0.3s;

  &:hover {
    fill: ${lighten(0.2, color)};
  }
`;

export const Expand = ({ ...props }) => (
  // tslint:disable:max-line-length
  <ExpandStyled
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    {...props}
  >
    <path d="M.625 0A.625.625 0 0 0 0 .625v5.577c0 .252.15.48.385.577a.619.619 0 0 0 .673-.144l1.85-1.851 2.741 2.74a.444.444 0 0 0 .625 0l1.25-1.25a.447.447 0 0 0 0-.625l-2.74-2.74 1.85-1.851a.625.625 0 0 0 .193-.433A.626.626 0 0 0 6.202 0H.625zm13.173 0c-.252 0-.48.15-.577.385a.619.619 0 0 0 .144.673l1.851 1.85-2.74 2.741a.444.444 0 0 0 0 .625l1.25 1.25a.444.444 0 0 0 .625 0l2.74-2.74 1.851 1.85c.12.118.27.193.433.193A.626.626 0 0 0 20 6.202V.625A.625.625 0 0 0 19.375 0h-5.577zM5.962 12.356a.44.44 0 0 0-.313.12l-2.74 2.74-1.851-1.85a.625.625 0 0 0-.433-.193.626.626 0 0 0-.625.625v5.577c0 .346.282.625.625.625h5.577c.252 0 .48-.15.577-.385a.619.619 0 0 0-.144-.673l-1.851-1.85L7.5 14.35a.444.444 0 0 0 0-.625L6.274 12.5a.466.466 0 0 0-.312-.144zm8.076 0a.445.445 0 0 0-.312.12l-1.226 1.25c-.171.171-.195.454-.024.625l2.74 2.74-1.85 1.851a.625.625 0 0 0-.193.433.626.626 0 0 0 .625.625h5.577c.346 0 .625-.28.625-.625v-5.577c0-.252-.15-.48-.385-.577a.619.619 0 0 0-.673.144l-1.85 1.851-2.741-2.74a.44.44 0 0 0-.313-.12z" />
  </ExpandStyled>
);
