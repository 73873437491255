import React from "react";
import { GetSessionRecordingsForListPage_Nodes } from "testly-web/queries";
import { Row } from "../Table/Table";
import { ColumnName, columnsConfig } from "./columnsConfig";

interface SessionRecordingRowProps {
  sessionRecording: GetSessionRecordingsForListPage_Nodes;
  columns: ColumnName[];
}

export const SessionRecordingRow: React.SFC<SessionRecordingRowProps> = ({
  sessionRecording,
  columns
}) => {
  const mappedColumns = columns.map(column => ({
    column,
    ...columnsConfig[column]
  }));

  return (
    <Row>
      {mappedColumns.map(({ column, BodyCell }) => (
        <BodyCell key={column} sessionRecording={sessionRecording} />
      ))}
    </Row>
  );
};
