import React from "react";
import AnimateHeight from "react-animate-height";
import { Link } from "react-router-dom";
import { ValueType } from "react-select/lib/types";
import styled, { css } from "styled-components/macro";
import { Option, Select } from "testly-web/components/Form/Select";
import { LoadingIndicator } from "testly-web/components/LoadingIndicator";
import { SplitTestStatus } from "testly-web/queries";

import {
  ActionButton,
  SplitTestActions
} from "testly-web/components/split-test/ActionButton/ActionButton";

import { DropdownArrow, Footer, MoreVariationsButton } from "./Footer";
import {
  Header,
  Info,
  InfoGoalSelectCaption,
  InfoLabel,
  InfoLabelGroup,
  Title
} from "./Header";
import { BlankVariation, Variation, VariationType } from "./Variation";

export interface SplitTestType {
  id: string;
  name: string;
  pageCategory: {
    name: string;
  };
  pageType: {
    name: string;
  };
  status: SplitTestStatus;
  selectedGoal: {
    id: string;
    name: string;
  } | null;
  variations: VariationType[];
  goals: Array<{
    id: string;
    name: string;
  }>;
}

export interface SplitTestCardProps {
  splitTest: SplitTestType;
  expanded: boolean;
  className?: string;
  goalChangeLoading: boolean;
  isUpdating: boolean;
  splitTestActions: SplitTestActions;
  splitTestShowPath: string;
  onExpandClick(): void;
  onGoalSelect(goalId: string): void;
}

const Container = styled.div<{ expanded: boolean }>`
  background-color: #fff;
  transition: box-shadow 0.4s ease-in-out;

  ${({ expanded }) =>
    expanded &&
    css`
      box-shadow: 0 0 20px 0 rgba(46, 51, 65, 0.44);
    `}
`;

const Body = styled.div``;

const HiddenVariations = styled.div`
  margin-top: -1px;
`;

const LoadingIndicatorStyled = styled(LoadingIndicator)`
  margin-left: 5px;
`;

const CustomSelectStyled = styled(Select)`
  width: 150px;
  height: 30px;
  font-weight: 500;
`;

export const SplitTestCard: React.SFC<SplitTestCardProps> = ({
  splitTest,
  expanded,
  className,
  onExpandClick,
  onGoalSelect,
  goalChangeLoading,
  isUpdating,
  splitTestActions,
  splitTestShowPath
}) => {
  const controlVariation = splitTest.variations.find(({ control }) => control);
  const notControlVariations = splitTest.variations.filter(
    ({ control }) => !control
  );
  const secondVariation: VariationType | undefined = notControlVariations[0];
  const canBeHiddenVariations = notControlVariations.slice(
    1,
    notControlVariations.length
  );
  const selectedGoal = splitTest.selectedGoal;

  const goalOptions = splitTest.goals.map(({ id, name }) => ({
    label: name,
    value: id
  }));
  const selectedGoalValue = selectedGoal && {
    label: selectedGoal.name,
    value: selectedGoal.id
  };

  return (
    <div className={className}>
      <Container expanded={expanded}>
        <Header>
          <Title>
            {splitTest.status === SplitTestStatus.Draft ? (
              splitTest.name
            ) : (
              <Link to={splitTestShowPath}>{splitTest.name}</Link>
            )}
          </Title>
          <Info>
            {splitTest.status !== SplitTestStatus.Draft && (
              // In not draft state selectedGoalForConversions should not be null
              <>
                <InfoGoalSelectCaption>View:</InfoGoalSelectCaption>
                <CustomSelectStyled
                  options={goalOptions}
                  value={selectedGoalValue}
                  onChange={(option: ValueType<Option>) => {
                    if (option && !Array.isArray(option)) {
                      onGoalSelect(option.value!);
                    }
                  }}
                />
                <LoadingIndicatorStyled isLoading={goalChangeLoading} />
              </>
            )}

            <InfoLabelGroup>
              <InfoLabel dark={true}>{splitTest.pageType.name}</InfoLabel>
              <InfoLabel dark={false}>{splitTest.pageCategory.name}</InfoLabel>
            </InfoLabelGroup>
          </Info>
        </Header>
        <Body>
          {controlVariation ? (
            <Variation variation={controlVariation} />
          ) : (
            <BlankVariation />
          )}
          {secondVariation ? (
            <Variation variation={secondVariation} />
          ) : (
            <BlankVariation />
          )}

          <HiddenVariations>
            <AnimateHeight duration={300} height={expanded ? "auto" : "0"}>
              {canBeHiddenVariations.map(variation => (
                <Variation variation={variation} key={variation.id} />
              ))}
            </AnimateHeight>
          </HiddenVariations>
        </Body>
        <Footer>
          {canBeHiddenVariations.length !== 0 ? (
            <MoreVariationsButton onClick={onExpandClick}>
              {expanded ? "Hide" : `+${canBeHiddenVariations.length} Variation`}
              <DropdownArrow direction={expanded ? "up" : "down"} />
            </MoreVariationsButton>
          ) : (
            <div />
          )}
          <ActionButton
            status={splitTest.status}
            isUpdating={isUpdating}
            {...splitTestActions}
          />
        </Footer>
      </Container>
    </div>
  );
};
