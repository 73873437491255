import { lighten } from "polished";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { sidePadding } from "./styled-vars";

const feautureContentColor = "#fff";

export const Feature = styled(Link)`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0px ${sidePadding}px;
  height: 58px;

  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: ${feautureContentColor};
  text-transform: uppercase;

  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: ${lighten(0.15, "#000")};
  }
`;
export const FeatureImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 38px;
  margin-right: 23px;

  svg {
    fill: #00ac69;
  }
`;
