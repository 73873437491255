import React from "react";
import styled from "styled-components/macro";

const containerSize = 16;
const dotSize = 10;
const indent = (containerSize - dotSize) / 2;

export const Radio = styled.input`
  &:checked,
  &:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  &:checked + label,
  &:not(:checked) + label {
    position: relative;
    cursor: pointer;
    display: inline-block;

    font-family: Roboto;
    font-size: 12px;
    color: #393839;

    padding-left: ${containerSize + 8}px;
    line-height: ${containerSize}px;
    vertical-align: bottom;
  }

  &:checked + label:before,
  &:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: ${containerSize}px;
    height: ${containerSize}px;
    border: solid 1px #a7a9ae;
    border-radius: 100%;
    background: #fff;
    box-sizing: border-box;
  }

  &:checked + label:before {
    border: solid 1px #00ac69;
  }

  &:checked + label:after,
  &:not(:checked) + label:after {
    content: "";
    width: ${dotSize}px;
    height: ${dotSize}px;
    background-color: #00ac69;
    position: absolute;
    top: ${indent}px;
    left: ${indent}px;
    border-radius: 100%;
    transition: all 0.2s ease;
  }

  &:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
  }

  &:checked + label:after {
    opacity: 1;
    transform: scale(1);
  }
`;

const RadionInputStyled = styled.div`
  display: flex;
  align-items: center;
`;
const LabelStyled = styled.label`
  font-family: Roboto;
  font-size: 12px;
  color: #393839;
`;

export const RadionInput: React.SFC<
  React.InputHTMLAttributes<HTMLInputElement> & {
    label: string;
    checked: boolean;
  }
> = ({ className, ...props }) => {
  // tslint:disable-next-line:insecure-random
  const id = Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, "");

  return (
    <RadionInputStyled className={className}>
      <Radio
        role="radio"
        type="radio"
        aria-checked={props.checked}
        id={id}
        {...props}
      />

      <LabelStyled htmlFor={id}>{props.label}</LabelStyled>
    </RadionInputStyled>
  );
};
