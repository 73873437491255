import React from "react";
import styled, { css } from "styled-components/macro";
import { DotLoadingIndicator } from "testly-web/components/DotLoadingIndicator/DotLoadingIndicator";
import { DeviceIcon } from "testly-web/icons/DeviceIcon";
import { DeviceType } from "testly-web/queries";

const DeviceSelectStyled = styled.div`
  display: inline-flex;
  align-items: center;
`;
const DeviceSelectCaption = styled.div`
  font-size: 14px;
  color: #393839;
  margin-right: 15px;
`;

const DeviceSelectOptions = styled.div`
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid rgba(167, 169, 174, 0.4);
`;

const DeviceOptionStyled = styled.div<{ selected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  border-right: 1px solid rgba(167, 169, 174, 0.4);
  &:last-child {
    border-right: none;
  }
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: #ecedee;

      &:hover {
        background-color: #ecedee;
      }
    `}

  fill: #00ac69;
`;

const DeviceOptionVisits = styled.div`
  margin-left: 8px;
  font-size: 14px;
  font-weight: 500;
  color: #393839;
`;

const DeviceOption: React.SFC<{
  device: DeviceType;
  onClick: () => void;
  selected: boolean;
  visits?: number;
}> = ({ selected, onClick, device, visits }) => (
  <DeviceOptionStyled selected={selected} onClick={onClick}>
    <DeviceIcon deviceType={device} color={selected ? "green" : "gray"} />
    <DeviceOptionVisits>
      {visits !== undefined ? (
        visits.toLocaleString()
      ) : (
        <DotLoadingIndicator size={6} />
      )}
    </DeviceOptionVisits>
  </DeviceOptionStyled>
);

export const DeviceSelect: React.SFC<{
  selectedDevice: DeviceType;
  visits?: {
    desktop: number;
    tablet: number;
    mobile: number;
  };
  onDeviceSelect: (device: DeviceType) => void;
}> = ({ selectedDevice, onDeviceSelect, visits }) => (
  <DeviceSelectStyled>
    <DeviceSelectCaption>Devices</DeviceSelectCaption>
    <DeviceSelectOptions>
      {[DeviceType.Desktop, DeviceType.Tablet, DeviceType.Mobile].map(
        (deviceType: DeviceType) => {
          const deviceVisits =
            visits &&
            visits[deviceType.toLowerCase() as "desktop" | "tablet" | "mobile"];

          return (
            <DeviceOption
              key={deviceType}
              selected={selectedDevice === deviceType}
              device={deviceType}
              onClick={() =>
                deviceVisits !== 0 &&
                deviceVisits !== undefined &&
                onDeviceSelect(deviceType)
              }
              visits={deviceVisits}
            />
          );
        }
      )}
    </DeviceSelectOptions>
  </DeviceSelectStyled>
);
