import {
  DeviceType,
  ReferrerSource,
  SplitTestFinishConditionType,
  SplitTestStatus
} from "testly-web/queries";
import * as Yup from "yup";
import { goalSchema, PathGoalForm } from "../goal/pathGoal";

export enum SplitTestFormStep {
  Details = "details",
  Variations = "variations",
  Goals = "goals",
  Settings = "settings",
  CodeInstallationCheck = "codeInstallationCheck"
}

export interface DetailsFormValues {
  name: string;
  description: string;
  pageCategoryId: string;
  pageTypeId: string;
  testIdeaId: string | null;
}

export const detailsFormSchema = Yup.object().shape({
  name: Yup.string().required("A name is required."),
  description: Yup.string(),
  pageCategoryId: Yup.string().required("A category is required."),
  pageTypeId: Yup.string().required("A type is required.")
});

export interface Variation<IsControl extends boolean> {
  id?: string;
  name: string;
  url: string;
  control: IsControl;
}

export interface VariationFormValues {
  controlVariation: Variation<true>;
  notControlVariations: Array<Variation<false>>;
}

const isUrl = (url: string) => {
  try {
    // tslint:disable-next-line:no-unused-expression
    new URL(url);

    return true;
  } catch (_) {
    return false;
  }
};

const variaionShape = Yup.object().shape({
  name: Yup.string().required("Name is required."),
  url: Yup.string()
    .required("A URL is required.")
    .test("url", "A URL is not valid.", value => isUrl(value))
    .test(
      "query-params",
      "Query params and anchors are not allowed.",
      value => {
        if (!isUrl(value)) {
          return true;
        }

        const url = new URL(value);

        return (
          url.search.length === 0 &&
          url.hash.length === 0 &&
          !url.href.endsWith("?") &&
          !url.href.endsWith("#")
        );
      }
    )
});

export const variationFormSchema = Yup.object()
  .shape({
    controlVariation: variaionShape,
    notControlVariations: Yup.array().of(variaionShape)
  })
  .test("uniq-url", "", function(value: VariationFormValues) {
    const urls = [
      value.controlVariation.url,
      ...value.notControlVariations.map(({ url }) => url)
    ];

    const checkUniq = (urlToCheck: string, path: string) => {
      if (urls.filter(url => url === urlToCheck).length > 1) {
        throw this.createError({
          path,
          message: "A URL must be unique across all variations"
        });
      }
    };

    checkUniq(value.controlVariation.url, "controlVariation.url");

    value.notControlVariations.forEach((variation, i) => {
      checkUniq(variation.url, `notControlVariations.${i}.url`);
    });

    return true;
  });

export interface GoalsFormValues {
  goals: PathGoalForm[];
}
export const goalsFormSchema = Yup.object().shape({
  goals: Yup.array().of(goalSchema)
});

export interface SettingsFormValues {
  trafficPercent: number;
  trafficDeviceTypes: DeviceType[];
  trafficReferrerSources: ReferrerSource[];
  status: SplitTestStatus;
  finishCondition: {
    conditionType: SplitTestFinishConditionType;
    daysPassed: number;
    goalConversions: number;
    visits: number;
    goalId: string;
  };
}

export const settingsFormSchema = Yup.object().shape({
  trafficPercent: Yup.number()
    .required("A percent of traffic is required.")
    .min(0, "A percent of traffic shouldn't be negative.")
    .max(100, "A percent of traffic shouldn't be greater than 100."),
  finishCondition: Yup.object().shape({
    conditionType: Yup.string()
      .required()
      .oneOf(Object.values(SplitTestFinishConditionType)),
    daysPassed: Yup.number().when("conditionType", {
      is: SplitTestFinishConditionType.DaysPassed,
      then: Yup.number()
        .required("A day passed should be present.")
        .min(1, "A day passed can't be < 1")
        .typeError("You must specify a number")
        .integer()
    }),
    goalConversions: Yup.number().when("conditionType", {
      is: SplitTestFinishConditionType.GoalConversions,
      then: Yup.number()
        .required("A goals reached should be present.")
        .min(1, "A goals reached can't be < 1")
        .typeError("You must specify a number")
        .integer()
    }),
    goalId: Yup.string().when("conditionType", {
      is: SplitTestFinishConditionType.GoalConversions,
      then: Yup.string().required("A goal must be selected.")
    }),
    visits: Yup.number().when("conditionType", {
      is: SplitTestFinishConditionType.Visits,
      then: Yup.number()
        .required("A visits count should be present.")
        .min(1, "A visits count can't be < 1")
        .typeError("You must specify a number")
        .integer()
    })
  }),
  trafficDeviceTypes: Yup.array().required("Allowed devices are required."),
  trafficReferrerSources: Yup.array().required("Allowed sources are required.")
});
