import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { SetCurrentProjectId } from "testly-web/components/CurrentProjectId/SetCurrentProjectId";
import { loadingRender } from "testly-web/components/loadingRender";
import { SetNavbar } from "testly-web/components/Navbar/SetNavbar";
import { paths } from "testly-web/paths";
import { GetDataForGoalListPageComponent } from "testly-web/queries";
import { GoalsListPage } from "./GoalsListPage";

export const GoalsListPageContainer: React.SFC<
  RouteComponentProps<{
    projectId: string;
  }>
> = ({
  match: {
    params: { projectId }
  }
}) => {
  return (
    <>
      <SetCurrentProjectId id={projectId} />
      <SetNavbar header="Goals" backPath={paths.projectPath({ projectId })} />

      <GetDataForGoalListPageComponent
        variables={{ projectId }}
        notifyOnNetworkStatusChange={true}
        fetchPolicy="network-only"
      >
        {loadingRender(
          "currentUser.project.goals",
          ({ data, refetch, networkStatus }) => (
            <GoalsListPage
              goals={data!.currentUser!.project!.goals.nodes}
              projectId={projectId}
              afterDelete={refetch}
              networkStatus={networkStatus}
              refetch={refetch}
            />
          )
        )}
      </GetDataForGoalListPageComponent>
    </>
  );
};
