import React from "react";

export const TextareaInputIcon: React.SFC<
  React.SVGProps<SVGSVGElement>
> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="18"
    viewBox="0 0 20 18"
    {...props}
  >
    <g fill="none">
      <rect
        width="19"
        height="17"
        x="0.5"
        y="0.5"
        fill="#F0F1F1"
        stroke="#A7A9AE"
        rx="2"
      />
      <path fill="#A7A9AE" d="M5 3v4h1v1H3V7h1V3H3V2h3v1H5z" />
      <path
        stroke="#A7A9AE"
        strokeLinecap="square"
        d="M13.5 15.5l4-4M16.5 15.5l1.172-1.172"
      />
    </g>
  </svg>
);
