import React from "react";

export const OkCircleIcon: React.SFC<React.SVGProps<SVGSVGElement>> = props => (
  // tslint:disable:max-line-length
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="26"
    viewBox="0 0 26 26"
    {...props}
  >
    <g fill="#00AC69">
      <path d="M13 24c6.075 0 11-4.925 11-11S19.075 2 13 2 2 6.925 2 13s4.925 11 11 11zm0 2C5.82 26 0 20.18 0 13S5.82 0 13 0s13 5.82 13 13-5.82 13-13 13z" />
      <path d="M16.861 8.024l2.278 1.952-7.054 8.23-5.146-5.145 2.122-2.122 2.854 2.855z" />
    </g>
  </svg>
);
