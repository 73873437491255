import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { SetCurrentProjectId } from "testly-web/components/CurrentProjectId/SetCurrentProjectId";
import { loadingRender } from "testly-web/components/loadingRender";
import { SetNavbar } from "testly-web/components/Navbar/SetNavbar";
import { paths } from "testly-web/paths";
import { GetProjectForProjectSetupPageComponent } from "testly-web/queries";
import { ProjectSetupPage } from "./ProjectSetupPage";

export const ProjectSetupPageContainer: React.SFC<
  RouteComponentProps<{ projectId: string }>
> = ({
  history,
  match: {
    params: { projectId }
  }
}) => (
  <>
    <SetCurrentProjectId id={projectId} />
    <SetNavbar
      header="Setup Guide"
      backPath={paths.projectPath({ projectId })}
    />
    <GetProjectForProjectSetupPageComponent
      variables={{ projectId }}
      fetchPolicy="network-only"
    >
      {loadingRender("currentUser.project", ({ data }) => (
        <ProjectSetupPage project={data!.currentUser!.project!} />
      ))}
    </GetProjectForProjectSetupPageComponent>
  </>
);
