import React from "react";

export const PollIcon = () => (
  // tslint:disable:max-line-length

  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="31"
    height="30"
    viewBox="0 0 31 30"
  >
    <path
      fillRule="nonzero"
      d="M3.51 0C1.864 0 .533 1.35.533 3L.52 20.997A2.998 2.998 0 0 0 3.513 24h2.99v6l5.984-6h14.961a3.005 3.005 0 0 0 2.992-3V3c0-1.65-1.346-3-2.992-3H3.51zm10.473 6h2.992v12h-2.992V6zm5.985 3h2.992v9h-2.992V9zm-11.97 3h2.993v6H7.999v-6z"
    />
  </svg>
);
