import memoize from "memoize-one";
import React from "react";
import { Frame } from "session-player/playback/types";
import { Throttles } from "session-player/types";
import styled from "styled-components/macro";
import { SetIframeRef } from "testly-web/components/Player/Player";
import { Iframe } from "./Iframe";
import { LayerProps } from "./layers/types";
import { ScalableIframeWrapper } from "./ScalableIframeWrapper";

export type Layer = React.SFC<LayerProps> | React.ComponentClass<LayerProps>;

interface PlayerScreenProps {
  setIframeRef: SetIframeRef;
  frame: Frame | undefined;
  layers: Layer[];
  throttles: Throttles;
}

const PlayerScreenStyled = styled.div`
  background-color: #000;
  overflow: hidden;
  height: 100%;
`;

const Dim = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #fff;
  opacity: 0;
`;

export class PlayerScreen extends React.Component<PlayerScreenProps> {
  private styledRef: React.RefObject<HTMLDivElement>;
  private mapSessionDimension = memoize(
    (frameWidth?: number, frameHeight?: number) =>
      frameWidth === undefined || frameHeight === undefined
        ? undefined
        : {
            width: frameWidth,
            height: frameHeight
          }
  );

  constructor(props: PlayerScreenProps) {
    super(props);

    this.styledRef = React.createRef();
  }

  public render() {
    const { layers, frame, setIframeRef, throttles } = this.props;

    return (
      <PlayerScreenStyled
        ref={this.styledRef as React.RefObject<HTMLDivElement> & string}
      >
        <ScalableIframeWrapper
          sessionDimension={this.mapSessionDimension(
            frame && frame.lastWindowSize.width,
            frame && frame.lastWindowSize.height
          )}
          wrapperRef={this.styledRef}
          center={true}
        >
          {frame &&
            layers.map((MappedLayer, i) => (
              <MappedLayer key={i} frame={frame} throttles={throttles} />
            ))}

          <Iframe setIframeRef={setIframeRef} />

          <Dim />
        </ScalableIframeWrapper>
      </PlayerScreenStyled>
    );
  }
}
