import Honeybadger from "honeybadger-js";
import React from "react";
import ReactDOM from "react-dom";
import { App } from "testly-web/App";

// import registerServiceWorker from "./registerServiceWorker";
if (process.env.REACT_APP_HONEYBADGER_ENABLED === "true") {
  Honeybadger.configure({
    apiKey: process.env.REACT_APP_HONEYBADGER_API_KEY as string,
    environment: process.env.NODE_ENV
  });
}

ReactDOM.render(<App />, document.getElementById("root") as HTMLElement);
// registerServiceWorker();
