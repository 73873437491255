import { darken } from "polished";
import React from "react";
import styled from "styled-components/macro";
import { PlusIcon } from "testly-web/icons/PlusIcon";

const projectButtonTextColor = "#00ac69";
const AddNewProjectButtonStyled = styled.div`
  display: flex;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  color: ${projectButtonTextColor};
  width: 100%;
  align-items: center;

  transition: color 0.2s ease-in-out;

  &:hover {
    color: ${darken(0.05, projectButtonTextColor)};
  }
`;

const AddNewProjectImg = styled.div`
  width: 40px;
  height: 40px;
  margin-right: 21px;
`;

export const AddNewProjectButton = () => (
  <AddNewProjectButtonStyled>
    <AddNewProjectImg>
      <PlusIcon />
    </AddNewProjectImg>
    Add New Project
  </AddNewProjectButtonStyled>
);
