import copy from "clipboard-copy";
import React from "react";
import { toast } from "react-toastify";
import { getCodeToInstall } from "testly-web/services/codeToInstall";
import { CodeStep, CodeStepBaseProps } from "./CodeStep";

export type CodeStepContainerProps = CodeStepBaseProps;

function selectElementText(el: HTMLElement) {
  const sel = window.getSelection();
  const range = document.createRange();
  range.selectNodeContents(el);

  if (sel) {
    sel.removeAllRanges();
    sel.addRange(range);
  }
}

export class CodeStepContainer extends React.Component<CodeStepContainerProps> {
  private codeContainerRef: React.RefObject<HTMLElement>;

  constructor(props: CodeStepContainerProps) {
    super(props);

    this.codeContainerRef = React.createRef<HTMLElement>();
  }

  public render() {
    return (
      <CodeStep
        codeToInstall={getCodeToInstall({ projectId: this.props.project.id })}
        onCopyClick={this.handleCopyClick}
        codeContainerRef={this.codeContainerRef}
        onCodeClick={this.selectCode}
        {...this.props}
      />
    );
  }

  private selectCode = () => {
    selectElementText(this.codeContainerRef.current!);
  };

  private handleCopyClick = async () => {
    try {
      this.selectCode();

      await copy(getCodeToInstall({ projectId: this.props.project.id }));

      toast.info("The code has been copied to your clipboard.");
    } catch (error) {
      toast.error("Failed to copy code to clipboard. Please, do it manually");
    }
  };
}
