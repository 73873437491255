import queryString from "qs";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { SetCurrentProjectId } from "testly-web/components/CurrentProjectId/SetCurrentProjectId";
import { loadingRender } from "testly-web/components/loadingRender";
import { SetNavbar } from "testly-web/components/Navbar/SetNavbar";
import { paths } from "testly-web/paths";
import {
  GetDataForHeatmapIndexPageComponent,
  HeatmapPageOrderField,
  OrderDirection
} from "testly-web/queries";
import { HeatmapIndexPage } from "./HeatmapIndexPage";

const perPage = 30;

export interface FilterState {
  urlCont: string;
}

export interface PaginationState {
  page: number;
}

export interface OrderState {
  field: HeatmapPageOrderField;
  direction: OrderDirection;
}

interface QueryState {
  filter: FilterState;
  pagination: PaginationState;
  order: OrderState;
}

const getQueryState = (search: string): QueryState => {
  const parsedQuery: {
    filter: FilterState;
    pagination: { page: string };
    order: OrderState;
  } = queryString.parse(search.substr(1)) || {};

  const paginationState: PaginationState = parsedQuery.pagination
    ? { page: parseInt(parsedQuery.pagination.page, 10) }
    : { page: 1 };

  const filterState: FilterState = parsedQuery.filter
    ? parsedQuery.filter
    : { urlCont: "" };

  const orderState: OrderState = parsedQuery.order
    ? parsedQuery.order
    : {
        field: HeatmapPageOrderField.TotalViewsCount,
        direction: OrderDirection.Desc
      };

  return {
    filter: filterState,
    pagination: paginationState,
    order: orderState
  };
};

// TODO: refactor and make pagination, filtering and sorting general
// for all such pages
export const HeatmapIndexPageContainer: React.SFC<
  RouteComponentProps<{ projectId: string }>
> = ({
  match: {
    params: { projectId }
  },
  location: { search },
  history
}) => {
  const changeQuery = (newQueryState: QueryState) => {
    history.replace({
      pathname: history.location.pathname,
      search: queryString.stringify(newQueryState)
    });
  };

  const queryState = getQueryState(search);
  const {
    filter: filterState,
    pagination: paginationState,
    order: orderState
  } = queryState;

  return (
    <>
      <SetCurrentProjectId id={projectId} />
      <SetNavbar
        header="Heatmaps"
        backPath={paths.projectPath({ projectId })}
      />

      <GetDataForHeatmapIndexPageComponent
        variables={{
          projectId,
          perPage,
          page: paginationState.page,
          filter: filterState,
          order: orderState
        }}
        notifyOnNetworkStatusChange={true}
        fetchPolicy="network-only"
      >
        {loadingRender(
          "currentUser.project.heatmapPagesConnection",
          ({ data }) => (
            <HeatmapIndexPage
              heatmaps={
                data!.currentUser!.project!.heatmapPagesConnection.heatmapPages
              }
              projectId={projectId}
              page={paginationState.page}
              pageCount={Math.ceil(
                data!.currentUser!.project!.heatmapPagesConnection
                  .totalRecords / perPage
              )}
              onPageChange={({ selected }) => {
                window.scrollTo({
                  top: 0,
                  left: 0
                });

                changeQuery({
                  ...queryState,
                  pagination: { page: selected }
                });
              }}
              filter={filterState}
              onFilterChange={newFilterState => {
                changeQuery({ ...queryState, filter: newFilterState });
              }}
              orderState={orderState}
              onOrderStateChange={newOrderState => {
                changeQuery({
                  ...queryState,
                  order: newOrderState
                });
              }}
            />
          )
        )}
      </GetDataForHeatmapIndexPageComponent>
    </>
  );
};
