import React from "react";
import { OkIcon } from "testly-web/icons/OkIcon";
import { SplitTestStatus } from "testly-web/queries";
import { ButtonStyledProps } from "../Button/Button";
import {
  Action,
  PauseStyled,
  StyledButton,
  StyledDropdown
} from "../split-test/ActionButton/common";

// Active => Paused, Finished
// Paused => Active, Finished
// Draft & AbleToRun => Active
// Finished

const buttonActionMap = (
  status: SplitTestStatus
): ButtonStyledProps["action"] => {
  return ({
    [SplitTestStatus.Active]: "primary",
    [SplitTestStatus.Finished]: "secondary",
    [SplitTestStatus.Draft]: "primary",
    [SplitTestStatus.Paused]: "thirdly"
  } as Record<SplitTestStatus, ButtonStyledProps["action"]>)[status];
};

export const SettingsActionButton: React.SFC<{
  status: SplitTestStatus;
  isUpdating: boolean;
  className?: string;
  disabled?: boolean;
  onSelect(newState: SplitTestStatus): void;
}> = ({ status, isUpdating, onSelect, disabled, className }) => {
  const dropdownEnabled = status === SplitTestStatus.Paused;

  return (
    <StyledDropdown
      animation="popup"
      dropdownContent={() => (
        <>
          {status === SplitTestStatus.Draft ? (
            <>
              <Action
                active={false}
                onClick={() => {
                  onSelect(SplitTestStatus.Active);
                }}
              >
                Save & Start
              </Action>
              <Action
                active={false}
                onClick={() => {
                  onSelect(SplitTestStatus.Draft);
                }}
              >
                Save
              </Action>
            </>
          ) : (
            <>
              <Action
                active={false}
                onClick={() => {
                  onSelect(SplitTestStatus.Active);
                }}
              >
                Save & {status === SplitTestStatus.Active && "Keep"} Active
              </Action>
              <Action
                active={false}
                onClick={() => {
                  onSelect(SplitTestStatus.Paused);
                }}
              >
                Save & {status === SplitTestStatus.Paused && "Keep"} Pause
              </Action>

              <Action
                active={false}
                onClick={() => {
                  onSelect(SplitTestStatus.Finished);
                }}
              >
                Finish Test
              </Action>
            </>
          )}
        </>
      )}
      dropdownEnabled={dropdownEnabled}
    >
      <StyledButton
        action={buttonActionMap(status)}
        size="small"
        dropdown={dropdownEnabled}
        loading={isUpdating}
        type="button"
        disabled={disabled}
        className={className}
        onClick={() => {
          if (status === SplitTestStatus.Draft) {
            onSelect(SplitTestStatus.Active);
          } else if (status === SplitTestStatus.Active) {
            onSelect(SplitTestStatus.Active);
          }
        }}
      >
        {status === SplitTestStatus.Draft && "Start Test"}
        {status === SplitTestStatus.Active && "Save"}

        {status === SplitTestStatus.Paused && (
          <>
            <PauseStyled />
            Paused
          </>
        )}

        {status === SplitTestStatus.Finished && (
          <>
            <OkIcon />
            Finished
          </>
        )}
      </StyledButton>
    </StyledDropdown>
  );
};
