import { isEqual } from "lodash-es";
import { ScrollPosition } from "session-player/siteTypes";

export class Scroller {
  private contentWindow: Window;
  private isSafari: boolean;

  constructor(contentWindow: Window) {
    this.contentWindow = contentWindow;
    this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    // if (iframeWindow && !this.wasIosFixPresent) {
    //   const prevPositon = iframeWindow.document.body.style.position;
    //   // not sure why, but it is iOS bug and can be fixed in such way
    //   // spent on it almost the whole day
    //   setTimeout(() => {
    //     iframeWindow.document.body.style.position = "fixed";
    //     setTimeout(() => {
    //       iframeWindow.document.body.style.position = prevPositon;
    //     }, 100);
    //   }, 100);
    //   this.wasIosFixPresent = true;
    // }
  }

  private prevScrollPos: Record<string, ScrollPosition> = {};
  public scroll(newScrollPositions: Record<string, ScrollPosition>) {
    Object.entries(newScrollPositions).forEach(([id, scrollPosition]) => {
      const el = this.findEl(id);

      if (el === null) {
        return;
      }

      if (isEqual(this.prevScrollPos[id], scrollPosition)) return;

      this.prevScrollPos[id] = scrollPosition;

      el.scroll({
        top: scrollPosition.top,
        left: scrollPosition.left,

        behavior: "smooth"
      });
    });
    // const el = this.findEl(newScrollPos.id);
    // if (el === null) {
    //   return;
    // }
    // if (this.prevScrollPos === undefined || newScrollPos.top !== el.scrollTop) {
    // console.log("scroll!", el.scrollTop, newScrollPos.top);
    // el.scrollTop = newScrollPos.top;
    // smoothScrollTo(
    //   newScrollPos.top,
    //   newScrollPos.animationDuration,
    //   pos => {
    //     el.scrollTop = pos;
    //   },
    //   () => el.scrollTop
    // );
    // }
    // if (
    //   this.prevScrollPos === undefined ||
    //   newScrollPos.left !== this.prevScrollPos.left
    // ) {
    //   smoothScrollTo(
    //     newScrollPos.left,
    //     newScrollPos.animationDuration,
    //     pos => {
    //       el.scrollLeft = pos;
    //     },
    //     () => el.scrollLeft
    //   );
    // }
  }

  private findEl(nodeId: string) {
    const el = this.contentWindow.document.querySelector(this.selector(nodeId));

    if (el && el.tagName.toLowerCase() === "html" && this.isSafari) {
      // Safari not able to scroll the html element
      return this.contentWindow.document.body as Element;
    }

    return el;
  }

  private selector(nodeId: string) {
    return `[data-testly-player-node-id='${nodeId}']`;
  }
}
