import React from "react";

export const LineSample: React.SFC<{
  color: string;
  dashed: boolean;
}> = ({ color, dashed }) => {
  return (
    <svg
      height="5"
      width="24"
      style={{ marginRight: 10, position: "relative", top: -1 }}
    >
      <line
        x1="0"
        y1="0"
        x2="24"
        y2="0"
        stroke={color}
        strokeWidth="5"
        strokeDasharray={dashed ? "6 3" : "none"}
      />
    </svg>
  );
};
