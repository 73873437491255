import { UnreachableCaseError } from "testly-web/utils";
import { DomMutation, MutationType } from "./mutationTypes";
import { NodeMap } from "./NodeMap";
import { deleteNode } from "./snabbdomDom/deleteNode";
import { mutateAttrs } from "./snabbdomDom/mutateAttrs";
import { mutateText } from "./snabbdomDom/mutateText";
import { putNode } from "./snabbdomDom/putNode";
import { IdentifiableVNode } from "./types";

export const mutate = (
  nodeMap: NodeMap,
  currentNodeState: IdentifiableVNode,
  mutation: DomMutation
): IdentifiableVNode => {
  try {
    switch (mutation.mutationType) {
      case MutationType.CreateOrMoveNode:
        const parentPath = nodeMap.fetchNodeLink(mutation.parentId);

        const maybeRemoved = (() => {
          const nodePath = nodeMap.getNodeLink(mutation.node.data.nodeId);

          if (nodePath) {
            return deleteNode(currentNodeState, nodePath);
          } else {
            return currentNodeState;
          }
        })();

        nodeMap.addNode(mutation.node, parentPath);

        return putNode(
          maybeRemoved,
          mutation.node,
          parentPath,
          mutation.previousSiblingId
        );
      case MutationType.RemoveNode:
        const removeNodePath = nodeMap.getNodeLink(mutation.nodeId);

        if (removeNodePath) {
          return deleteNode(currentNodeState, removeNodePath);
        } else {
          return currentNodeState;
        }

      case MutationType.MutateAttrs:
        const mutatesAttrsPath = nodeMap.fetchNodeLink(mutation.nodeId);

        return mutateAttrs(currentNodeState, mutatesAttrsPath, mutation.attrs);

      case MutationType.MutateCharacterData:
        const mutatesCharPath = nodeMap.fetchNodeLink(mutation.nodeId);

        return mutateText(currentNodeState, mutatesCharPath, mutation.data);

      default:
        throw new UnreachableCaseError(mutation!.mutationType);
    }
  } catch (e) {
    console.error(
      "Failed to mutate, nodemap",
      nodeMap,
      "currentNodeState",
      currentNodeState,
      "mutation",
      mutation
    );

    return currentNodeState;
  }
};
