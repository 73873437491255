import React from "react";
import DayPickerInputFromLib from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import { formatDate, parseDate } from "react-day-picker/moment";
import { DayPickerProps } from "react-day-picker/types/props";
import styled, { keyframes } from "styled-components/macro";
import { CancelIcon } from "testly-web/icons/CancelIcon";
import { DEFAULT_DATE_FORMAT } from "testly-web/settings";
import { InputCss } from "../Form/Input";

const dateFilterOpen = keyframes`
  0% {
    visibility: visible;
    opacity: 0;
    transform: scale(0.5, 0.5) translateX(-170px) translateY(-45px);
  }
  100% {
    visibility: visible;
    transform: scale(1, 1);
    opacity: 1;
  }
`;

const DayPickerInputStyled = styled.div`
  position: relative;

  .DayPickerInput input {
    ${InputCss};
    padding-right: 30px;
  }

  .DayPicker {
    font-family: Roboto;

    animation: ${dateFilterOpen} 0.15s;
    box-shadow: 0 10px 20px 0 rgba(167, 169, 174, 0.54);
    background-color: #ffffff;
    border: solid 1px rgba(81, 86, 99, 0.24);
    border-radius: 4px;
  }

  .DayPicker-Month {
    border-spacing: 0px 0px;
    border-collapse: separate;
  }

  .DayPicker-WeekdaysRow {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 500;
    color: #a7a9ae;
  }

  .DayPicker-Caption {
    margin-top: 8px;
    padding-bottom: 32px;
  }

  .DayPicker-NavButton {
    margin-top: 8px;
  }

  .DayPicker-Weekday {
    color: #8b9898;
    padding-bottom: 8px;
  }

  .DayPicker-Day {
    font-size: 14px;
    font-weight: 500;
    color: #393839;
    padding: 16px 16px;

    margin-left: 8px;
    margin-right: 8px;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    color: #fff;
    background-color: #00ac69;
    border-radius: 0;

    &:hover {
      background-color: #00ac69 !important;
    }
  }

  .DayPicker-Day--singleSelected {
    border-radius: 50% !important;
  }

  .DayPicker-Day--fromSelected,
  .DayPicker-Day--toSelected {
    color: #fff;
    background-color: #00ac69 !important;
  }

  .DayPicker-Day--start,
  .DayPicker-Day--fromSelected {
    border-radius: 0 !important;
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }

  .DayPicker-Day--end,
  .DayPicker-Day--toSelected {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }

  .DayPicker-Day--outside,
  .DayPicker-Day--disabled {
    color: #dddbe1;
  }
`;

const weekdays: [string, string, string, string, string, string, string] = [
  "S",
  "M",
  "T",
  "W",
  "T",
  "F",
  "S"
];

const StyledCancel = styled(CancelIcon)`
  position: absolute;
  right: 8px;

  top: 50%;
  transform: translateY(-50%);

  cursor: pointer;
`;

export const DayPickerInput: React.SFC<{
  date?: Date | null;
  dayPickerProps?: DayPickerProps;
  innerRef?: React.RefObject<DayPickerInputFromLib>;
  onDateChange?(day: Date): void;
  onResetClick?(): void;
}> = ({ date, onDateChange, dayPickerProps, innerRef, onResetClick }) => {
  return (
    <DayPickerInputStyled>
      <DayPickerInputFromLib
        value={(date && formatDate(date, DEFAULT_DATE_FORMAT)) || ""}
        formatDate={formatDate}
        parseDate={parseDate}
        placeholder={DEFAULT_DATE_FORMAT}
        format={DEFAULT_DATE_FORMAT}
        onDayChange={onDateChange}
        dayPickerProps={{
          showOutsideDays: true,
          weekdaysShort: weekdays,
          ...dayPickerProps
        }}
        ref={innerRef}
      />
      <StyledCancel onClick={onResetClick} />
    </DayPickerInputStyled>
  );
};
