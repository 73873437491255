import React, { createRef, useEffect } from "react";

export const OutsideEventHandler: React.SFC<{
  onEvent: () => void;
  eventName: string;
}> = ({ children, onEvent, eventName }) => {
  const divRef = createRef<HTMLDivElement>();

  useEffect(() => {
    const handler = (e: Event) => {
      if (!divRef.current) {
        return;
      }

      if (!divRef.current.contains(e.target as HTMLDivElement)) {
        onEvent();
      }
    };

    document.addEventListener(eventName, handler);
    return () => {
      document.removeEventListener(eventName, handler);
    };
  });

  return <div ref={divRef}>{children}</div>;
};
