import { NetworkStatus } from "apollo-boost";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { SetCurrentProjectId } from "testly-web/components/CurrentProjectId/SetCurrentProjectId";
import { loadingRender } from "testly-web/components/loadingRender";
import { SetNavbar } from "testly-web/components/Navbar/SetNavbar";
import {
  AtiveTab,
  SplitTestPageHeader
} from "testly-web/components/split-test/SplitTestShowPageHeader";
import { paths } from "testly-web/paths";
import { GetDataForSplitTestShowPageComponent } from "../../queries";
import { mapSplitTestData } from "./helpers";
import { SplitTestShowPage } from "./SplitTestShowPage";

export const SplitTestShowPageContainer: React.SFC<
  RouteComponentProps<{
    projectId: string;
    splitTestId: string;
  }>
> = ({
  match: {
    params: { projectId, splitTestId }
  }
}) => {
  const backPath = paths.splitTestIndexPath({ projectId });

  return (
    <>
      <SetCurrentProjectId id={projectId} />
      <SetNavbar header="Split Test" backPath={backPath} />
      <GetDataForSplitTestShowPageComponent
        variables={{ projectId, splitTestId }}
        fetchPolicy="network-only"
        notifyOnNetworkStatusChange={true}
      >
        {loadingRender(
          "currentUser.project.splitTest",
          ({ data, refetch, networkStatus }, isDataLoaded) => (
            <>
              <SplitTestPageHeader
                splitTestId={splitTestId}
                projectId={projectId}
                activeTab={AtiveTab.splitTestShowPage}
              />

              {isDataLoaded && (
                <>
                  <SplitTestShowPage
                    splitTest={mapSplitTestData(
                      data!.currentUser!.project!.splitTest!
                    )}
                    onGoalSelect={goalId => {
                      refetch({ goalId, projectId, splitTestId });
                    }}
                    refetching={networkStatus === NetworkStatus.setVariables}
                    projectId={projectId}
                  />
                </>
              )}
            </>
          ),
          true
        )}
      </GetDataForSplitTestShowPageComponent>
    </>
  );
};
