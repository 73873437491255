import { CalculatedClickPoint } from "./ClickHeatmap";

const defaultJoinRadius = 40;

// !!! The same code is located at pointsReducer.worker.ts
// Don't forget to copy-paste it
// The reason is described in mentioned file

export interface Point {
  x: number;
  y: number;
  weight: number;
}

export interface Region {
  absolutePoints: Point[];
  x: number;
  y: number;
  x2: number;
  y2: number;
  joinRadius: number;
}

export interface RegionWithAnalyticInfo extends Region {
  clicksCount: number;
  clicksPercent: number;
}

const checkPointInRegion = (point: CalculatedClickPoint, region: Region) =>
  region.y <= point.y &&
  point.y <= region.y2 &&
  region.x <= point.x &&
  point.x <= region.x2;

export const fillRegionsWithAnalyticInfo = (
  regions: Region[],
  allClickPoints: CalculatedClickPoint[]
): RegionWithAnalyticInfo[] => {
  const mappedRegions: RegionWithAnalyticInfo[] = regions.map(region => ({
    ...region,
    clicksCount: 0,
    clicksPercent: 100
  }));

  allClickPoints.forEach(clickPoint => {
    mappedRegions.forEach(region => {
      if (checkPointInRegion(clickPoint, region)) {
        region.clicksCount += clickPoint.count;
      }
    });
  });

  return mappedRegions;
};

// This is needed due to performance optimization,
// cause heatmap-js can't render big canvas
// So we are rendering small canvas regions

// Also it is used in HeatmapTooltip generation
export const initRegion = (
  point: Point,
  joinRadius = defaultJoinRadius
): Region => ({
  absolutePoints: [point],
  x: point.x - joinRadius,
  y: point.y - joinRadius,
  x2: point.x + joinRadius,
  y2: point.y + joinRadius,
  joinRadius
});
const checkRegionCross = (region1: Region, region2: Region) => {
  return (
    region1.x <= region2.x2 &&
    region1.x2 >= region2.x &&
    region1.y <= region2.y2 &&
    region1.y2 >= region2.y
  );
};
const mergeTwoRegions = (region1: Region, region2: Region): Region => {
  return {
    absolutePoints: [...region1.absolutePoints, ...region2.absolutePoints],
    x: Math.min(region1.x, region2.x),
    y: Math.min(region1.y, region2.y),
    x2: Math.max(region1.x2, region2.x2),
    y2: Math.max(region1.y2, region2.y2),
    joinRadius: region1.joinRadius
  };
};
export const pointInRegion = (region: Region, point: Point): boolean => {
  return checkRegionCross(region, initRegion(point, region.joinRadius));
};

export const addPointToRegion = (point: Point, region: Region): Region => {
  const pointRegion = initRegion(point);
  return {
    absolutePoints: [...region.absolutePoints, point],
    x: Math.min(region.x, pointRegion.x),
    y: Math.min(region.y, pointRegion.y),
    x2: Math.max(region.x2, pointRegion.x2),
    y2: Math.max(region.y2, pointRegion.y2),
    joinRadius: region.joinRadius
  };
};

export const mergeRegions = (regions: Region[]): Region[] => {
  if (regions[0] === undefined) {
    return [];
  }
  // It mutates reducedRegions array because of speed optimization
  const mergedRegions = regions.slice(1).reduce(
    (reducedRegions, currentRegion) => {
      const crossedRegionIndex = reducedRegions.findIndex(region =>
        checkRegionCross(region, currentRegion)
      );

      if (crossedRegionIndex !== -1) {
        reducedRegions[crossedRegionIndex] = mergeTwoRegions(
          currentRegion,
          reducedRegions[crossedRegionIndex]
        );

        return reducedRegions;
      } else {
        reducedRegions.push(currentRegion);

        return reducedRegions;
      }
    },
    [regions[0]]
  );

  if (mergedRegions.length === regions.length) {
    return mergedRegions;
  } else {
    return mergeRegions(mergedRegions);
  }
};

export const reducePointsToRegions = (
  points: Point[],
  joinRadius = defaultJoinRadius
): Region[] => {
  return mergeRegions(points.map(point => initRegion(point, joinRadius)));
};

export const calcRelativePoints = (region: Region): Point[] => {
  return region.absolutePoints.map(point => ({
    x: point.x - region.x,
    y: point.y - region.y,
    weight: point.weight
  }));
};
