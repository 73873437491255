import { NetworkStatus } from "apollo-client";
import moment from "moment";
import React from "react";
import { OnErrorListener } from "session-player/playback/PlaybackManager";
import { SessionRecording as SessionRecordingType } from "session-player/types";
import styled from "styled-components/macro";
import { Player } from "testly-web/components/Player";
import {
  Page,
  PlayerContainer
} from "testly-web/components/Player/PlayerContainer";
import { ClickLayer } from "testly-web/components/Player/PlayerScreen/layers/ClickLayer";
import { MouseLayer } from "testly-web/components/Player/PlayerScreen/layers/MouseLayer";
import { SessionRecordingIcon } from "testly-web/icons/SessionRecordingIcon";
import { SessionDetails } from "testly-web/pages/SessionRecordingPage/SessionDetails";
import {
  GetSessionRecordingForRecordingPagePages,
  GetSessionRecordingForRecordingPageSessionRecording
} from "testly-web/queries";
import { Actions } from "./Actions/Actions";
import { PagesDropdown } from "./PagesDropdown";

const PlayerWrapper = styled.div`
  flex: 7;
  min-width: 0;
  max-width: 70%;
  min-width: 70%;
  width: 70%;
  height: 550px;
`;

const Content = styled.div`
  display: flex;
  padding: 40px;
  padding-bottom: 0px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;

  background-color: #efefef;
  padding: 40px;

  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  color: #393839;

  word-break: break-word;

  svg {
    width: 26px;
    margin-right: 20px;
    path {
      fill: #a7a9ae;
    }
  }
`;

const Sidebar = styled.div`
  flex: 3;
  margin-left: 40px;
  max-width: calc(30% - 40px);
  min-width: calc(30% - 40px);
`;

const generatePages = (
  sessionRecording: SessionRecordingType,
  pages: GetSessionRecordingForRecordingPagePages[]
): Page[] => {
  const allPage: Page = {
    url: undefined,
    title: `All Pages (${pages.length} in total)`,
    duration: moment(
      sessionRecording.events[sessionRecording.events.length - 1].happenedAt
    ).diff(moment(pages[0].visitedAt)),
    visitedAt: moment(sessionRecording.events[0].happenedAt)
  };

  return [
    allPage,
    ...pages.map(({ duration, title, url, visitedAt }) => ({
      url,
      title,
      duration: duration - 1,
      visitedAt: moment(visitedAt)
    }))
  ];
};

export const SessionRecordingPage: React.SFC<{
  sessionRecordingForPlayer: SessionRecordingType;
  nextSessionRecordingId: string | null;
  previousSessionRecordingId: string | null;
  projectId: string;
  onError: OnErrorListener;
  pages: GetSessionRecordingForRecordingPagePages[];
  sessionRecording: GetSessionRecordingForRecordingPageSessionRecording;
  networkStatus: NetworkStatus;
  refetch(): Promise<void>;
}> = ({
  sessionRecordingForPlayer,
  sessionRecording,
  onError,
  pages,
  refetch,
  networkStatus
}) => {
  const generatedPages = generatePages(sessionRecordingForPlayer, pages);

  return (
    <PlayerContainer
      session={sessionRecordingForPlayer}
      pages={generatedPages}
      onError={onError}
    >
      {(
        setRef,
        playerController,
        frame,
        playingState,
        timelineManager,
        { currentPage, onPageChange },
        userPlayerSettings
      ) => (
        <>
          <Header>
            <SessionRecordingIcon />
            {frame && frame.lastUrl}
          </Header>
          <Content>
            <PlayerWrapper>
              <Player
                frame={frame}
                eventsOnTimeline={timelineManager.getEventsOnTimeline()}
                timelineDuration={timelineManager.duration()}
                throttles={sessionRecordingForPlayer.throttles}
                setIframeRef={setRef}
                playerController={playerController}
                layers={[MouseLayer, ClickLayer]}
                playingState={playingState}
                userPlayerSettings={userPlayerSettings}
              />
            </PlayerWrapper>
            <Sidebar>
              <SessionDetails
                sessionRecording={sessionRecording}
                refetch={refetch}
                networkStatus={networkStatus}
              />
              <PagesDropdown
                pages={generatedPages}
                currentPage={currentPage}
                onPageClick={onPageChange}
              />
              <Actions
                eventsOnTimeline={timelineManager.getEventsOnTimeline()}
                pollResponses={sessionRecording.feedbackPollResponses}
                currentPlayTime={(playingState && playingState.playTime) || 0}
                seek={time => {
                  playerController.seek(time);
                }}
              />
            </Sidebar>
          </Content>
        </>
      )}
    </PlayerContainer>
  );
};
