import { Formik, FormikConfig, FormikProps } from "formik";
import React from "react";
import { FeedbackForm } from "testly-web/components/feedback/FeedbackForm";
import {
  feedbackFormSchema,
  FeedbackFormValues
} from "testly-web/components/feedback/schema";

export const FeedbackPollEditPage: React.SFC<{
  onSubmit: FormikConfig<FeedbackFormValues>["onSubmit"];
  initialValues: FeedbackFormValues;
}> = ({ initialValues, onSubmit }) => (
  <Formik<FeedbackFormValues>
    initialValues={initialValues}
    onSubmit={onSubmit}
    validateOnBlur={false}
    validateOnChange={false}
    validationSchema={feedbackFormSchema}
  >
    {(props: FormikProps<FeedbackFormValues>) => (
      <FeedbackForm formProps={props} />
    )}
  </Formik>
);
