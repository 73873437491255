import { Point, Region } from "../Region";

export interface ReducePointEventData {
  eventName: "reducePoints";
  points: Point[];
  pointsGroupRadius: number;
}

export interface PointsReducedEventData {
  eventName: "pointsReduced";
  regions: Region[];
}

export default () => {
  // NOTE: we can't import code from other libraries and files(except typescript defs),
  // cause we are putting this code new Blob(...code+here...)
  // and such way will not support references to any external dependencies

  // CRA doesn't have built in workers support :(

  // !!! It just copypaste of Region.ts code :(

  const initRegion = (point: Point, joinRadius: number): Region => ({
    absolutePoints: [point],
    x: point.x - joinRadius,
    y: point.y - joinRadius,
    x2: point.x + joinRadius,
    y2: point.y + joinRadius,
    joinRadius
  });

  const mergeTwoRegions = (region1: Region, region2: Region): Region => {
    return {
      absolutePoints: region1.absolutePoints.concat(region2.absolutePoints),
      x: Math.min(region1.x, region2.x),
      y: Math.min(region1.y, region2.y),
      x2: Math.max(region1.x2, region2.x2),
      y2: Math.max(region1.y2, region2.y2),
      joinRadius: region1.joinRadius
    };
  };

  const checkRegionCross = (region1: Region, region2: Region) => {
    return (
      region1.x <= region2.x2 &&
      region1.x2 >= region2.x &&
      region1.y <= region2.y2 &&
      region1.y2 >= region2.y
    );
  };

  const mergeRegions = (regions: Region[]): Region[] => {
    if (regions[0] === undefined) {
      return [];
    }

    // It mutates reducedRegions array because of speed optimization
    const mergedRegions = regions.slice(1).reduce(
      (reducedRegions, currentRegion) => {
        const crossedRegionIndex = reducedRegions.findIndex(region =>
          checkRegionCross(region, currentRegion)
        );

        if (crossedRegionIndex !== -1) {
          reducedRegions[crossedRegionIndex] = mergeTwoRegions(
            currentRegion,
            reducedRegions[crossedRegionIndex]
          );

          return reducedRegions;
        } else {
          reducedRegions.push(currentRegion);

          return reducedRegions;
        }
      },
      [regions[0]]
    );

    if (mergedRegions.length === regions.length) {
      return mergedRegions;
    } else {
      return mergeRegions(mergedRegions);
    }
  };

  const reducePointsToRegions = (
    points: Point[],
    joinRadius: number
  ): Region[] => {
    return mergeRegions(points.map(point => initRegion(point, joinRadius)));
  };

  // eslint-disable-next-line
  self.addEventListener("message", e => {
    const data: ReducePointEventData | undefined = e && e.data;

    if (!data || data.eventName !== "reducePoints") {
      return;
    }

    const reducedEvent: PointsReducedEventData = {
      eventName: "pointsReduced",
      regions: reducePointsToRegions(data.points, data.pointsGroupRadius)
    };

    postMessage(reducedEvent);
  });
};
