import React from "react";
import styled, { css } from "styled-components";

enum Color {
  Red = "#ff5857",
  Green = "#00ac69",
  Yellow = "#ffc669"
}

const colorBreakpoints: Record<Color, number> = {
  [Color.Red]: 100,
  [Color.Yellow]: 90,
  [Color.Green]: 80
};

const VisitorsWrapper = styled.div`
  display: flex;

  width: 100%;
  height: 15px;

  position: relative;

  background-color: #ecedee;
  border-radius: 7px;
  overflow: hidden;
`;

const ProgressBar = styled.div<{ color: string; width: number }>`
  display: block;

  height: 100%;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  background-color: #ecedee;
  z-index: 1;

  ${({ color, width }) =>
    css`
      width: ${width}%;
      background-color: ${color};
    `};
`;

export const BillingVisitors: React.SFC<{
  current: number;
  total: number;
}> = ({ current, total }) => {
  const visitorsPercent = (current / total) * 100;

  return (
    <VisitorsWrapper>
      {Object.entries(colorBreakpoints).map(([color, breakpoint]) => (
        <ProgressBar
          key={color}
          color={color}
          width={visitorsPercent >= breakpoint ? breakpoint : visitorsPercent}
        />
      ))}
    </VisitorsWrapper>
  );
};
