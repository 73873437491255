import { Formik, FormikActions, FormikProps } from "formik";
import { LocationDescriptorObject } from "history";
import React from "react";
import { MutationFn } from "react-apollo";
import { Prompt, Route } from "react-router";
import {
  convertFromDto,
  convertToDto
} from "testly-web/components/goal/DTOConverter";
import {
  onFormSubmitFail,
  onMutationError
} from "testly-web/components/onFail";
import {
  goalsFormSchema,
  GoalsFormValues,
  SplitTestFormStep
} from "testly-web/components/split-test-form/schemas";
import { paths } from "testly-web/paths";
import {
  GetDataForSplitTestEditPageSplitTest,
  UpdateSplitTestAtSplitTestEditPageComponent,
  UpdateSplitTestAtSplitTestEditPageMutation,
  UpdateSplitTestAtSplitTestEditPageVariables
} from "testly-web/queries";
import { GoalsForm } from "../../components/split-test-form/GoalsForm";

const onSubmit = (
  projectId: string,
  splitTestId: string,
  updateSplitTest: MutationFn<
    UpdateSplitTestAtSplitTestEditPageMutation,
    UpdateSplitTestAtSplitTestEditPageVariables
  >,
  replace: (path: LocationDescriptorObject) => void
) => {
  return async (
    params: GoalsFormValues,
    actions: FormikActions<GoalsFormValues>
  ) => {
    const goalsValues = params.goals.map(convertToDto);

    const response = await updateSplitTest({
      variables: {
        splitTestId,
        splitTestParams: { goals: goalsValues }
      }
    });

    actions.setSubmitting(false);

    if (response && response.data && response.data.updateSplitTest) {
      const { successful, result } = response.data.updateSplitTest;

      if (successful && result) {
        actions.resetForm();

        replace({
          pathname: paths.splitTestEditPath({
            projectId,
            splitTestId: result.id,
            step: SplitTestFormStep.CodeInstallationCheck
          })
        });
      } else {
        onFormSubmitFail(params, response.data);
      }
    }
  };
};

export const GoalsFormContainer: React.SFC<{
  splitTest: GetDataForSplitTestEditPageSplitTest;
  projectId: string;
  onBackButtonClick(): void;
}> = ({ splitTest, projectId, onBackButtonClick }) => {
  const formGoals = splitTest.goals.map(convertFromDto);
  const initialValues = { goals: formGoals };

  return (
    <Route
      render={({ history: { replace }, location: { state } }) => (
        <UpdateSplitTestAtSplitTestEditPageComponent onError={onMutationError}>
          {updateSplitTest => (
            <Formik<GoalsFormValues>
              initialValues={initialValues}
              validationSchema={goalsFormSchema}
              onSubmit={onSubmit(
                projectId,
                splitTest.id,
                updateSplitTest,
                path => replace({ ...path, state })
              )}
              validateOnBlur={false}
              validateOnChange={false}
            >
              {(props: FormikProps<GoalsFormValues>) => (
                <>
                  <Prompt
                    when={props.dirty}
                    message="You have unsaved changes, are you sure you want to leave?"
                  />
                  <GoalsForm
                    formProps={props}
                    onBackButtonClick={onBackButtonClick}
                  />
                </>
              )}
            </Formik>
          )}
        </UpdateSplitTestAtSplitTestEditPageComponent>
      )}
    />
  );
};
