import styled from "styled-components/macro";

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 20px 9px 20px;
  position: relative;
`;

export const Info = styled.div`
  display: flex;
  margin-top: 8px;
  align-items: center;
  height: 30px;
`;
export const InfoLabel = styled.div<{ dark: boolean }>`
  display: inline;
  border-radius: 12px;
  border: solid 1px #ecedee;
  background-color: ${({ dark }) => (dark ? "#ecedee" : "#ffffff")};

  font-family: Roboto;
  font-size: 9px;
  font-weight: bold;
  letter-spacing: 0.8px;
  color: #a7a9ae;

  padding: 3px 5px;

  text-transform: uppercase;
  white-space: nowrap;

  & + & {
    margin-left: 8px;
  }
`;

export const InfoGoalSelectCaption = styled.div`
  margin-right: 8px;
`;

export const InfoGoalSelect = styled.select``;

export const InfoLabelGroup = styled.div`
  margin-left: auto;
  display: flex;
`;

export const Title = styled.div`
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  color: #393839;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 5px;
  position: relative;
  height: 27px;

  a {
    position: relative;
    &:before,
    &:after {
      content: "";
      position: absolute;
      bottom: -7px;
      width: 0;
      height: 5px;
      margin: 5px 0 0;
      transition: all 0.2s ease-in-out;
      transition-duration: 0.5s;
      background-color: #000;
      z-index: 10000;
    }

    &:before {
      left: calc(50%);
      background-color: #393839;
    }

    &:after {
      right: calc(50%);
      background-color: #393839;
    }

    &:hover {
      &:before,
      &:after {
        width: 50%;
      }
    }
  }
`;
