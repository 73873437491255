import React from "react";
import { AdvancedFilter } from "testly-web/components/filters/AdvancedFilter/AdvancedFilter";
import { OnFilterChange } from "testly-web/components/filters/AdvancedFilter/types";
import { SplitTestFilter } from "testly-web/queries";
import { PageCategory, pageCategoryFilter } from "./pageCategoryFilter";
import { PageType, pageTypeFilter } from "./pageTypeFilter";
import { statusFilter } from "./statusFilter";

interface Filters {
  pageCategory: React.ReactNode;
  pageType: React.ReactNode;
  status: React.ReactNode;
}

export const SplitTestAdvancedFilter: React.SFC<{
  filter: SplitTestFilter;
  onFilterChange: OnFilterChange<SplitTestFilter>;
  pageCategories: PageCategory[];
  pageTypes: PageType[];
  onFilterClose?(): void;
}> = ({ filter, onFilterChange, onFilterClose, pageCategories, pageTypes }) => {
  const filtersCount =
    (filter.pageCategoryIdIn || []).length +
    (filter.pageTypeIdIn || []).length +
    (filter.statusIn || []).length;

  const renderedFilters: Filters = {
    status: statusFilter(filter, onFilterChange),
    pageType: pageTypeFilter(filter, onFilterChange, pageTypes),
    pageCategory: pageCategoryFilter(filter, onFilterChange, pageCategories)
  };

  return (
    <AdvancedFilter
      filtersCount={filtersCount}
      onFilterClose={onFilterClose}
      renderedFilters={{ ...renderedFilters }}
    />
  );
};
