import React from "react";
import styled, { css } from "styled-components/macro";
import { DropdownController } from "testly-web/components/Dropdown/DropdownController";
import { paths } from "testly-web/paths";
import { slideDownAnimation, slideUpAnimation } from "../animation";
import { DropdownArrow } from "../Dropdown/DropdownArrow";
import { AddNewProjectButton } from "./AddNewProjectButton";
import { NewProjectFormContainer } from "./NewProjectFormContainer";
import { Project } from "./Project";
import { ActiveRow, defaultSizeRowHeight, LinkabeRow, Row } from "./Row";
import { DropdownProject } from "./types";

const appearAnimationTime = 0.2;

// eslint-disable-next-line no-unexpected-multiline
export const StyledProjectsDropdown = styled.div<{
  opened: boolean;
  closeAnimationIsRunning: boolean;
}>`
  will-change: box-shadow;
  min-width: 250px;

  ${({ opened }) =>
    opened &&
    css`
      transition: box-shadow 0.2s linear ${appearAnimationTime / 3}s;
      box-shadow: 0 10px 30px 0 rgba(167, 169, 174, 0.2);
    `};

  ${({ closeAnimationIsRunning }) =>
    closeAnimationIsRunning &&
    css`
      transition: box-shadow 0.15s ease-out;
      box-shadow: none;
    `};
`;

// eslint-disable-next-line no-unexpected-multiline
const DropdownContent = styled.div<{
  opened: boolean;
  closeAnimationIsRunning: boolean;
}>`
  will-change: transform, opacity;
  overflow: hidden;
  max-height: 0;

  animation-duration: ${appearAnimationTime}s;
  animation-fill-mode: both;
  animation-timing-function: ease-out;

  ${({ opened }) =>
    opened &&
    css`
      max-height: none;
      animation-name: ${slideDownAnimation("100%")};
    `};

  ${({ closeAnimationIsRunning }) =>
    closeAnimationIsRunning &&
    css`
      animation-name: ${slideUpAnimation("100%")};
    `};
`;

const ScrollableRows = styled.div<{ rowsCount: number }>`
  height: ${({ rowsCount }) => Math.min(rowsCount, 3) * defaultSizeRowHeight}px;
  overflow-y: auto;
  background-color: #fff;
`;

export interface ProjectsDropdownProps {
  currentProjectId: string | null;
  availableProjects: DropdownProject[];
}

interface ProjectsContainerState {
  addNewProjectToggled: boolean;
}

export class ProjectsDropdown extends React.Component<
  ProjectsDropdownProps,
  ProjectsContainerState
> {
  constructor(props: ProjectsDropdownProps) {
    super(props);

    this.state = {
      addNewProjectToggled: false
    };
  }

  public render() {
    const { currentProjectId, availableProjects } = this.props;
    const { addNewProjectToggled } = this.state;

    const projectsToDisplay = availableProjects.filter(
      ({ id }) => id !== currentProjectId
    );

    return (
      <DropdownController>
        {({
          opened,
          closeAnimationIsRunning,
          onControlClick,
          wrapperRef,
          close
        }) => (
          <StyledProjectsDropdown
            opened={opened}
            closeAnimationIsRunning={closeAnimationIsRunning}
            ref={wrapperRef as React.RefObject<HTMLDivElement> & string}
          >
            <ActiveRow opened={opened} role="button" onClick={onControlClick}>
              <Project
                project={
                  availableProjects.find(
                    project => project.id === currentProjectId
                  ) || { id: "", domain: "" }
                }
              />
              <DropdownArrow />
            </ActiveRow>
            <DropdownContent
              opened={opened}
              closeAnimationIsRunning={closeAnimationIsRunning}
            >
              <ScrollableRows rowsCount={projectsToDisplay.length}>
                {projectsToDisplay.map(project => (
                  <LinkabeRow
                    key={project.id}
                    to={paths.projectPath({ projectId: project.id })}
                    onClick={onControlClick}
                  >
                    <Project project={project} />
                  </LinkabeRow>
                ))}
              </ScrollableRows>
              {addNewProjectToggled ? (
                <Row defaultCursor={true} hover={false}>
                  <NewProjectFormContainer
                    onCreate={() => {
                      close();
                      this.setState({ addNewProjectToggled: false });
                    }}
                  />
                </Row>
              ) : (
                <Row
                  hover={false}
                  role="button"
                  onClick={this.handleNewProjectButtonClick}
                >
                  <AddNewProjectButton />
                </Row>
              )}
            </DropdownContent>
          </StyledProjectsDropdown>
        )}
      </DropdownController>
    );
  }

  private handleNewProjectButtonClick = () => {
    this.setState({ addNewProjectToggled: true });
  };
}
