import React from "react";
import { GetCurrentUserIdComponent } from "testly-web/queries";

export const GetCurrentUserId: React.SFC<{
  children(currentUserId: string): React.ReactNode;
}> = ({ children }) => (
  <GetCurrentUserIdComponent>
    {({ data }) => data && children(data.currentUserId)}
  </GetCurrentUserIdComponent>
);
