import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { SetCurrentProjectId } from "testly-web/components/CurrentProjectId/SetCurrentProjectId";
import { loadingRender } from "testly-web/components/loadingRender";
import { SetNavbar } from "testly-web/components/Navbar/SetNavbar";
import { pageProps } from "testly-web/components/session-recordings-list/pageProps";
import { paths } from "testly-web/paths";
import { GetSessionRecordingsForListPageComponent } from "testly-web/queries";
import { parseQuery } from "../../components/session-recordings-list/query";
import { SessionRecordingListPage } from "../../components/session-recordings-list/SessionRecordingListPage";

const perPage = 30;

export const SessionRecordingListPageContainer: React.SFC<
  RouteComponentProps<{
    projectId: string;
  }>
> = ({
  match: {
    params: { projectId }
  },
  history
}) => {
  const queryState = parseQuery(history.location.search);

  return (
    <>
      <SetCurrentProjectId id={projectId} />
      <SetNavbar
        header="Session Recordings"
        backPath={
          queryState.cameFrom === "goals"
            ? paths.goalListPath({ projectId })
            : paths.projectPath({ projectId })
        }
      />
      <GetSessionRecordingsForListPageComponent
        variables={{
          projectId,
          perPage,
          page: queryState.page,
          filter: queryState.filter
        }}
        notifyOnNetworkStatusChange={true}
        fetchPolicy="network-only"
      >
        {loadingRender(
          "currentUser.project.sessionRecordings.nodes",
          ({ data, networkStatus, refetch }) => (
            <SessionRecordingListPage
              projectGoals={data!.currentUser!.project!.goals.nodes}
              splitTestGoals={[]}
              sessionRecordings={
                data!.currentUser!.project!.sessionRecordings.nodes
              }
              filter={queryState.filter}
              page={queryState.page}
              queryState={queryState}
              networkStatus={networkStatus}
              refetch={refetch}
              filtersToShow={[
                "device",
                "country",
                "projectGoals",
                "duration",
                "referrerSource"
              ]}
              columns={[
                "startingPage",
                "country",
                "duration",
                "device",
                "projectGoals",
                "createdAt",
                "action"
              ]}
              {...pageProps({
                totalRecords: data!.currentUser!.project!.sessionRecordings
                  .totalRecords,
                history,
                perPage,
                queryState
              })}
            />
          )
        )}
      </GetSessionRecordingsForListPageComponent>
    </>
  );
};
