import React from "react";
import { ApolloProvider } from "react-apollo";
import "react-custom-scroll/dist/customScroll.css";
import { hot } from "react-hot-loader";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createGlobalStyle } from "styled-components/macro";
import styledNormalize from "styled-normalize";
import { LoadCurrentUser } from "testly-web/components/CurrentUser";
import { client } from "./client";
import { HighlightHiddenTextGlobalHover } from "./components/HighlightHiddenText";
import { Layout } from "./components/Layout";
import { Router as AppRouter } from "./Router";
import { siteMinWidth } from "./style-vars";

const GlobalStyle = createGlobalStyle`
  ${styledNormalize}

  @import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,600");

  html,
  body {
    margin: 0;
    padding: 0;
    background-color: #f8f8f8;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-width: ${siteMinWidth}px;

    font-family: Roboto;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    font-weight: normal;

  }

  a {
    color: inherit;
    text-decoration: none;
  }

  .Toastify__toast-container {
    top: 3em;
    z-index: 10005;
  }

  .Toastify__toast-body {
    text-align: center;
  }

  .Toastify__toast--success {
    background-color: #00ac69;
  }


  .rcs-custom-scroll .rcs-custom-scrollbar {
    opacity: 1;
    width: 5px;
  }

  .rcs-custom-scroll .rcs-inner-handle {
    background-color: #a7a9ae;
    border-radius: 2px;
    margin-top: 0px;
    height: 100%;
  }

  .ReactModal__Overlay {
    z-index: 10002;
    background-color: rgba(0, 0, 0, 0.31) !important;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
`;

const DefaultApp = () => (
  <ApolloProvider client={client}>
    <BrowserRouter>
      <>
        <GlobalStyle />
        <HighlightHiddenTextGlobalHover />
        <LoadCurrentUser>
          <Layout>
            <AppRouter />
          </Layout>
        </LoadCurrentUser>

        <ToastContainer />
      </>
    </BrowserRouter>
  </ApolloProvider>
);

export const App = hot(module)(DefaultApp);
