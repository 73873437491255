import { flattenDeep } from "lodash";
import { IdentifiableVNode, NodePath } from "../types";

interface IdentifiableVNodeWithChildren extends IdentifiableVNode {
  children: IdentifiableVNode[];
}

export class MapChildError extends Error {
  constructor(m: string) {
    super(m);

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, MapChildError.prototype);
  }
}

export const mapChildNode = (
  currentElement: IdentifiableVNode,
  path: NodePath,
  mapper: (
    findedChildNode: IdentifiableVNode,
    path: NodePath
  ) => IdentifiableVNode | IdentifiableVNode[] | undefined
): IdentifiableVNodeWithChildren => {
  const childId = path[0];

  if (currentElement.children === undefined) {
    throw new MapChildError(
      `Node(id:${currentElement.data.nodeId}) doesn't have children element`
    );
  }

  const i = currentElement.children.findIndex(
    node => node.data.nodeId === childId
  );

  if (i === -1) {
    throw new MapChildError(
      `Failed to find child node(id:${childId}) element in node(id:${
        currentElement.data.nodeId
      }, ${JSON.stringify(currentElement)}), current path is ${path}`
    );
  }

  const children = currentElement.children.map((node: IdentifiableVNode) => {
    return childId === node.data.nodeId ? mapper(node, path.slice(1)) : node;
  });

  // filter out undefined
  const filteredChildren = children.filter(
    node => node !== undefined
  ) as IdentifiableVNode[];

  return {
    ...currentElement,
    children: flattenDeep(filteredChildren)
  };
};
