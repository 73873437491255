import { NetworkStatus } from "apollo-boost";
import React from "react";
import styled from "styled-components/macro";
import { Paginator } from "testly-web/components/Paginator";
import {
  GetSessionRecordingsForListPage_Nodes,
  GetSessionRecordingsForListPageGoalEntityInlineFragment,
  SessionRecordingFilter
} from "testly-web/queries";
import { OptimisticFilter } from "../filters/AdvancedFilter/OptimisticFilter";
import { OnFilterChange } from "../filters/AdvancedFilter/types";
import { Row, Table, TableEmptyCell } from "../Table/Table";
import { ColumnName, columnsConfig } from "./columnsConfig";
import { Filter } from "./Filter";
import { QueryState } from "./query";
import { SessionRecordingRow } from "./SessionRecordingRow";
import { FilterName } from "./SessionRecordingsAdvancedFilter/SessionRecordingsAdvancedFilter";

const SessionRecordingListPageStyled = styled.div``;

const SessionRecordingTableWrapper = styled.div`
  padding: 40px;
`;

const StyledPaginator = styled(Paginator)`
  margin-top: 20px;
`;

export interface SessionRecordingListPageProps {
  sessionRecordings: GetSessionRecordingsForListPage_Nodes[];
  page: number;
  pageCount: number;
  onFilterChange: OnFilterChange<SessionRecordingFilter>;
  filter: SessionRecordingFilter;
  queryState: QueryState;
  networkStatus: NetworkStatus;
  columns: ColumnName[];
  projectGoals: GetSessionRecordingsForListPageGoalEntityInlineFragment[];
  splitTestGoals: GetSessionRecordingsForListPageGoalEntityInlineFragment[];
  filtersToShow: FilterName[];
  refetch(): void;
  onPageChange(data: { selected: number }): void;
}

export const SessionRecordingListPage: React.SFC<
  SessionRecordingListPageProps
> = ({
  sessionRecordings,
  page,
  onPageChange,
  onFilterChange,
  filter,
  pageCount,
  projectGoals,
  splitTestGoals,
  networkStatus,
  refetch,
  columns,
  filtersToShow
}) => {
  const mappedColumns = columns.map(column => ({
    column,
    ...columnsConfig[column]
  }));

  return (
    <SessionRecordingListPageStyled>
      <OptimisticFilter onFilterChange={onFilterChange} filter={filter}>
        {(optimisticFilter, optimisticOnFilterChange, onFilterClose) => (
          <Filter
            onFilterChange={optimisticOnFilterChange}
            filter={optimisticFilter}
            onFilterClose={onFilterClose}
            projectGoals={projectGoals}
            splitTestGoals={splitTestGoals}
            filtersToShow={filtersToShow}
          />
        )}
      </OptimisticFilter>

      <SessionRecordingTableWrapper>
        <Table>
          <thead>
            <Row hover={false}>
              {mappedColumns.map(({ column, HeaderCell }) => (
                <HeaderCell
                  key={column}
                  networkStatus={networkStatus}
                  refetch={refetch}
                />
              ))}
            </Row>
          </thead>
          <tbody>
            {sessionRecordings.length > 0 ? (
              sessionRecordings.map(sessionRecording => (
                <SessionRecordingRow
                  key={sessionRecording.id}
                  sessionRecording={sessionRecording}
                  columns={columns}
                />
              ))
            ) : (
              <tr>
                <TableEmptyCell>No session recordings found.</TableEmptyCell>
              </tr>
            )}
          </tbody>
        </Table>
        {pageCount > 1 && (
          <StyledPaginator
            pageCount={pageCount}
            onPageChange={onPageChange}
            page={page}
          />
        )}
      </SessionRecordingTableWrapper>
    </SessionRecordingListPageStyled>
  );
};
