import React from "react";
import styled, { css, keyframes } from "styled-components/macro";

const openDropbox = keyframes`
  0% {
    opacity: 0;
    visibility: visible;
    transform: translateY(-8px);
  }

  100% {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
  }
`;

const closeDropbox = keyframes`
  100% {
    opacity: 0;
    visibility: visible;
    transform: translateY(-8px);
  }

  0% {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
  }

`;

// eslint-disable-next-line no-unexpected-multiline
const AdvancedFilterDropboxStyled = styled.div<{
  opened: boolean;
  closedByUser: boolean;
}>`
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  box-shadow: 0 11px 20px 0 rgba(46, 51, 65, 0.44);
  background-color: #393839;

  visibility: hidden;
  animation-fill-mode: forwards;

  z-index: 1;

  ${({ opened }) =>
    opened &&
    css`
      animation: ${openDropbox} 0.3s ease-out;
      opacity: 1;
      visibility: visible;
    `};

  ${({ closedByUser }) =>
    closedByUser &&
    css`
      animation: ${closeDropbox} 0.3s ease-out;
    `};
`;

export interface AdvancedFilterDropboxProps {
  className?: string;
  opened: boolean;
  closedByUser: boolean;
  innerRef?: React.RefObject<HTMLDivElement>;
  renderedFilters: Record<string, React.ReactNode>;
}

export const AdvancedFilterDropboxWithoutRef: React.SFC<
  AdvancedFilterDropboxProps
> = ({ className, opened, closedByUser, innerRef, renderedFilters }) => {
  return (
    <AdvancedFilterDropboxStyled
      className={className}
      ref={innerRef as React.RefObject<HTMLDivElement> & string}
      opened={opened}
      closedByUser={closedByUser}
    >
      {Object.values(renderedFilters)}
    </AdvancedFilterDropboxStyled>
  );
};

export const AdvancedFilterDropbox: React.ComponentType<
  AdvancedFilterDropboxProps
> = React.forwardRef(
  (props: AdvancedFilterDropboxProps, ref?: React.Ref<HTMLDivElement>) => (
    <AdvancedFilterDropboxWithoutRef
      innerRef={ref as React.RefObject<HTMLDivElement>}
      {...props}
    />
  )
);
