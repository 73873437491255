import React from "react";
import CustomScroll from "react-custom-scroll";
import initToHtml from "snabbdom-to-html/init";
import snabbdomAttributes from "snabbdom-to-html/modules/attributes";
import snabbdomDataset from "snabbdom-to-html/modules/dataset";
import styled from "styled-components/macro";
import { heatmapViewPadding } from "../config";
import { Snapshot } from "../HeatmapShowPage";
import { calcClicksCount, calcHeatmapElements } from "./element";
import { fixStyleSheets } from "./fixStyleSheets";
import { Heatmap } from "./Heatmap/Heatmap";
import { HeatmapTooltips } from "./HeatmapTooltips/HeatmapTooltips";
import { Scaler } from "./Scaler";

interface HeatmapIframeProps {
  snapshot: Snapshot;
  heatmapContainerRef: React.RefObject<HTMLDivElement>;
  viewportWidth: number;
  onLoaded: () => void;
}

interface HeatmapIframeState {
  siteWidth?: number;
  siteHeight?: number;
  htmlSrc?: string;
  heatmapElements: CountedHeatmapElement[];
  loadState: LoadState;
}

export interface ClickPoint {
  percentX: number;
  percentY: number;
  count: number;
}

export interface CalculatedClickPoint extends ClickPoint {
  y: number;
  x: number;
  weight: number;
}

export interface HeatmapElement {
  element: HTMLElement;
  width: number;
  height: number;
  top: number;
  left: number;
  selector: string;
  calculatedClickPoints: CalculatedClickPoint[];
}

export interface CountedHeatmapElement extends HeatmapElement {
  clicksCount: number;
}

export interface HeatmapClickElement {
  selector: string;
  clickPoints: ClickPoint[];
}

enum LoadState {
  NotLoaded,
  DomContentLoaded,
  DomViewportSet,
  HeatmapElementsCalculated,
  ChildrenLoad,
  Loaded
}

const HeatmapViewStyled = styled.div`
  position: relative;

  height: 100%;
  overflow: hidden;
  transform: translateZ(0);
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.1);

  .rcs-custom-scroll .rcs-inner-handle {
    background-color: #eee;
  }
`;

const Cover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  transform: translateZ(0);
  padding: ${heatmapViewPadding}px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 8px;
`;

const HeatmapContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #f8f8f8;
  transform: translateZ(0px);
`;

const getDocWidth = (doc: Document) => {
  return Math.max(
    doc.body.scrollWidth,
    doc.documentElement.scrollWidth,
    doc.body.offsetWidth,
    doc.documentElement.offsetWidth,
    doc.documentElement.clientWidth
  );
};

const getDocHeight = (doc: Document) => {
  return Math.max(
    doc.body.scrollHeight,
    doc.documentElement.scrollHeight,
    doc.body.offsetHeight,
    doc.documentElement.offsetHeight,
    doc.documentElement.clientHeight
  );
};

// !!TODO
// Disable all animations with

// * {
//   /*CSS transitions*/
//   -o-transition-property: none !important;
//   -moz-transition-property: none !important;
//   -ms-transition-property: none !important;
//   -webkit-transition-property: none !important;
//   transition-property: none !important;t;
//   transform: none !important;
//   /*CSS animations*/
//   -webkit-animation: none !important;
//   -moz-animation: none !important;
//   -o-animation: none !important;
//   -ms-animation: none !important;
//   animation: none !important;
// }
// Cause some elements can be hidden on render

// https://stackoverflow.com/questions/9941972/slow-list-view-scrolling-on-ipad-when-scrolling-in-an-overflowauto-div

// Iframe srcDoc={} is used, cause it supports onLoad event
// We just need to be sure that all images/fonts are loaded before we can render
// the heatmap
export class ClickHeatmap extends React.Component<
  HeatmapIframeProps,
  HeatmapIframeState
> {
  private frameRef: React.RefObject<HTMLIFrameElement>;
  private wrapperRef: React.RefObject<HTMLDivElement>;

  constructor(props: HeatmapIframeProps) {
    super(props);
    this.frameRef = React.createRef<HTMLIFrameElement>();
    this.wrapperRef = React.createRef<HTMLDivElement>();
    this.state = {
      loadState: LoadState.NotLoaded,
      heatmapElements: []
    };
  }

  public componentDidMount() {
    const { docType, domNodes } = this.props.snapshot;
    const toHtml = initToHtml([snabbdomAttributes, snabbdomDataset]);

    this.setState({
      htmlSrc: docType + toHtml(domNodes)
    });
  }

  // Just state machine
  public componentDidUpdate() {
    if (this.state.loadState === LoadState.NotLoaded) {
      return;
    }

    const current = this.frameRef.current;

    if (!current) {
      console.error("iframe is missed");
      return;
    }

    const doc = current.contentDocument;
    const win = current.contentWindow;

    if (!doc || !win) {
      console.error("Iframe document or window is missed");
      return;
    }

    switch (this.state.loadState) {
      case LoadState.DomContentLoaded:
        const width = getDocWidth(current.contentWindow!.document);
        const height = getDocHeight(current.contentWindow!.document);

        fixStyleSheets(this.props.snapshot.domNodes, doc);

        this.setState({
          siteWidth: width,
          siteHeight: height,
          loadState: LoadState.DomViewportSet
        });
        break;
      case LoadState.DomViewportSet:
        this.setState({
          heatmapElements: calcClicksCount(
            calcHeatmapElements(this.props.snapshot.elements, doc, win)
          ),
          loadState: LoadState.HeatmapElementsCalculated
        });
        break;
      default:
        break;
    }
  }

  public render() {
    const {
      siteWidth,
      siteHeight,
      heatmapElements,
      htmlSrc,
      loadState
    } = this.state;

    // If after setting iframe width site still has bigger width -
    // we take width of the site
    const viewportWidth = Math.max(this.props.viewportWidth, siteWidth || 0);

    return (
      <HeatmapViewStyled ref={this.wrapperRef}>
        <CustomScroll heightRelativeToParent="100%">
          <Scaler
            siteHeight={siteHeight}
            siteWidth={siteWidth}
            wrapperRef={this.wrapperRef}
          >
            <HeatmapContainer
              style={
                siteWidth && siteHeight
                  ? {
                      width: `${siteWidth + heatmapViewPadding * 2}px`,
                      height: `${siteHeight + heatmapViewPadding * 2 + 4}px`
                    }
                  : {}
              }
            >
              {htmlSrc && (
                <iframe
                  frameBorder="0"
                  key="frame"
                  ref={
                    this.frameRef as React.RefObject<HTMLIFrameElement> & string
                  }
                  sandbox="allow-same-origin"
                  srcDoc={htmlSrc}
                  name={Date.now().toString()}
                  id={Date.now().toString()}
                  scrolling="no"
                  style={{
                    width: `${viewportWidth}px`,
                    height: `${siteHeight}px`,
                    transform: "translateZ(0)",
                    padding: `${heatmapViewPadding}px`
                  }}
                  onLoad={() => {
                    setTimeout(() => {
                      this.setState({ loadState: LoadState.DomContentLoaded });
                    }, 1000);
                  }}
                  title="Click heatmap content"
                />
              )}
              {[LoadState.HeatmapElementsCalculated, LoadState.Loaded].includes(
                loadState
              ) && (
                <>
                  <Cover />
                  <Heatmap elements={heatmapElements} />
                  <HeatmapTooltips
                    heatmapElements={heatmapElements}
                    tooltipsAreGenerated={() => {
                      this.setState({ loadState: LoadState.Loaded });
                      this.props.onLoaded();
                    }}
                    siteHeight={siteHeight!}
                    siteWidth={siteWidth!}
                  />
                </>
              )}
            </HeatmapContainer>
          </Scaler>
        </CustomScroll>
      </HeatmapViewStyled>
    );
  }
}
