import queryString from "query-string";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { convertNodeToIdentifiable } from "session-player/virtualDom/convertNodeToIdentifiable";
import { converToSnabbdomVNode } from "session-player/virtualDom/converToSnabbdomVNode";
import { SetCurrentProjectId } from "testly-web/components/CurrentProjectId/SetCurrentProjectId";
import { loadingRender } from "testly-web/components/loadingRender";
import { SetNavbar } from "testly-web/components/Navbar/SetNavbar";
import { paths } from "testly-web/paths";
import {
  DeviceType,
  GetDataForHeatmapShowPageComponent,
  GetDataForHeatmapShowPageHeatmapPage
} from "testly-web/queries";
import { HeatmapShowPage, Page } from "./HeatmapShowPage";

enum HeatmapType {
  Click = "ClickHeatmap"
}

const mapPage = (page: GetDataForHeatmapShowPageHeatmapPage): Page => {
  return {
    ...page,
    snapshot: {
      ...page.snapshot,
      domNodes: convertNodeToIdentifiable(
        converToSnabbdomVNode(JSON.parse(page.snapshot.domNodes))
      )
    }
  };
};

export const HeatmapShowPageContainer: React.SFC<
  RouteComponentProps<
    { projectId: string; heatmapPageId: string },
    {},
    {
      previousPath?: string;
    }
  >
> = ({
  history,
  match: {
    params: { projectId, heatmapPageId }
  },
  location: { state: locationState }
}) => {
  const parsedQuery: {
    deviceType?: DeviceType;
    heatmapType?: HeatmapType;
  } = queryString.parse(history.location.search) || {};

  const state = {
    deviceType: parsedQuery.deviceType || DeviceType.Desktop,
    heatmapType: parsedQuery.heatmapType || HeatmapType.Click
  };

  const previousPath =
    locationState && locationState.previousPath
      ? locationState.previousPath
      : paths.heatmapIndexPath({ projectId });

  const navbarProps = {
    header: "Heatmap",
    backPath: previousPath
  };

  return (
    <>
      <SetCurrentProjectId id={projectId} />
      <SetNavbar {...navbarProps} />
      <GetDataForHeatmapShowPageComponent
        variables={{
          projectId,
          heatmapPageId,
          deviceType: state.deviceType,
          heatmapType: state.heatmapType
        }}
        notifyOnNetworkStatusChange={true}
      >
        {loadingRender(
          "currentUser.project.heatmapPage",
          ({ data }) => {
            const page =
              (data &&
                data.currentUser &&
                data.currentUser.project &&
                data.currentUser.project.heatmapPage) ||
              undefined;

            return (
              <>
                {page && <SetNavbar {...navbarProps} caption={page.url} />}
                <HeatmapShowPage
                  key={state.deviceType} // to reinit components on device type change
                  page={
                    page && mapPage(data!.currentUser!.project!.heatmapPage)
                  }
                  deviceType={state.deviceType}
                  onDeviceSelect={deviceType => {
                    history.replace({
                      pathname: history.location.pathname,
                      search: queryString.stringify({ ...state, deviceType })
                    });
                  }}
                />
              </>
            );
          },
          true,
          false
        )}
      </GetDataForHeatmapShowPageComponent>
    </>
  );
};
