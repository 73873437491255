import React from "react";
import styled, { css } from "styled-components/macro";
import { OkCircleIcon } from "testly-web/icons/OkCircleIcon";
import { SplitTestFormStep } from "./schemas";

const ProgressBarStyled = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100px;
  background-color: #efefef;
  display: flex;
  align-items: center;
  padding: 0 40px;
`;

const StepStyled = styled.div<{ active: boolean; done: boolean }>`
  flex: 1;
  max-width: 200px;
  box-sizing: border-box;
  height: 100%;
  vertical-align: middle;
  display: flex;
  align-items: center;

  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.6px;
  color: #a7a9ae;

  cursor: pointer;
  position: relative;

  padding-left: 10px;

  &:focus {
    outline: none;
  }

  ${({ active }) =>
    active &&
    css`
      &:after {
        content: "";
        position: absolute;
        height: 4px;
        width: 100%;
        background-color: #00ac69;
        bottom: 0;
      }
      pointer-events: none;
    `}

  ${({ done }) =>
    done &&
    css`
      color: #393839;
    `}
`;

const OrderNumber = styled.div<{ active: boolean; done: boolean }>`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: solid 2px #a7a9ae;
  color: #a7a9ae;

  ${({ active }) =>
    active &&
    css`
      border-color: #00ac69;
      background-color: #00ac69;
      color: #fff;
    `}
`;

const StepIcon = styled.div`
  box-sizing: border-box;
  width: 26px;
  height: 26px;
  margin-top: 4px;
  margin-right: 19px;
  font-weight: bold;
  flex-shrink: 0;
`;

const Step: React.SFC<{
  active: boolean;
  done: boolean;
  orderNumber: number;
  name: string;
  onClick(): void;
}> = ({ active, orderNumber, name, done, onClick }) => (
  <StepStyled active={active} done={done} onClick={onClick}>
    <StepIcon>
      {done ? (
        <OkCircleIcon />
      ) : (
        <OrderNumber active={active} done={done}>
          {orderNumber}
        </OrderNumber>
      )}
    </StepIcon>
    {name}
  </StepStyled>
);

export type DoneStepsType = Record<SplitTestFormStep, boolean>;

export const ProgressBar: React.SFC<{
  doneSteps: DoneStepsType;
  currentStep: SplitTestFormStep;
  onStepClick(step: SplitTestFormStep): void;
}> = ({ currentStep, doneSteps, onStepClick }) => (
  <ProgressBarStyled>
    <Step
      done={doneSteps.details}
      active={currentStep === SplitTestFormStep.Details}
      orderNumber={1}
      name="Details"
      onClick={() => onStepClick(SplitTestFormStep.Details)}
    />
    <Step
      done={doneSteps.variations}
      active={currentStep === SplitTestFormStep.Variations}
      orderNumber={2}
      name="Variations"
      onClick={() => onStepClick(SplitTestFormStep.Variations)}
    />
    <Step
      done={doneSteps.goals}
      active={currentStep === SplitTestFormStep.Goals}
      orderNumber={3}
      name="Goals"
      onClick={() => onStepClick(SplitTestFormStep.Goals)}
    />
    <Step
      done={doneSteps.codeInstallationCheck}
      active={currentStep === SplitTestFormStep.CodeInstallationCheck}
      orderNumber={4}
      name="Install Check"
      onClick={() => onStepClick(SplitTestFormStep.CodeInstallationCheck)}
    />
    <Step
      done={doneSteps.settings}
      active={currentStep === SplitTestFormStep.Settings}
      orderNumber={5}
      name="Settings"
      onClick={() => onStepClick(SplitTestFormStep.Settings)}
    />
  </ProgressBarStyled>
);
