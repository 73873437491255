import React, { useEffect } from "react";
import { Body, Header, Step, StepNumber } from "./Step";

export const VideoStep = () => {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://embed.voomly.com/embed/embed-build.js";

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Step>
      <Header>
        <StepNumber>1</StepNumber>
        Watch the video below
      </Header>
      <Body>
        <script src="https://embed.voomly.com/embed/embed-build.js" />
        <div
          className="voomly-embed"
          data-id="FTqDThl4EwzM41QkU2QTYu1LUPhmNS8DrFdENGdpQsMelF4iE"
          data-ratio="1.777778"
          data-type="v"
          data-skin-color="#008EFF"
          data-shadow=""
          style={{
            width: "100%",
            aspectRatio: "1.77778 / 1",
            background:
              "linear-gradient(45deg, rgb(142, 150, 164) 0%, rgb(201, 208, 222) 100%)",
            borderRadius: 10
          }}
        />
      </Body>
    </Step>
  );
};
