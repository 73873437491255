import React from "react";

export const RightWhiteArrowIcon: React.SFC<
  React.SVGProps<SVGSVGElement>
> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="13"
    viewBox="0 0 8 13"
    {...props}
  >
    <path
      fill="#FFF"
      d="M0 .92L.92 0l5.76 5.76.44.46-.44.46-5.76 5.76-.92-.92 5.3-5.3z"
    />
  </svg>
);
