import React from "react";
import ReactCountryFlag from "react-country-flag";
import { countryCodes } from "testly-web/countryCodes";
import { SessionRecordingFilter } from "testly-web/queries";
import { handleInFilterChange } from "../../filters/AdvancedFilter/inFilter";
import { ListInFilter } from "../../filters/AdvancedFilter/ListInFilter";
import { OnFilterChange } from "../../filters/AdvancedFilter/types";

const countryOptions = countryCodes.map(({ name, code }) => ({
  name,
  value: code
}));

const filterName = "locationCountryIsoCodeIn";

export const countryFilter = (
  filter: SessionRecordingFilter,
  onFilterChange: OnFilterChange<SessionRecordingFilter>
) => {
  const selectedCounties = filter[filterName] || [];
  const onItemClick = handleInFilterChange(filter, filterName, onFilterChange);

  return (
    <ListInFilter
      key={filterName}
      namePlural="Countries"
      nameSingular="Country"
      options={countryOptions.map(({ name, value }) => ({
        name,
        value,
        selected: selectedCounties.includes(value)
      }))}
      iconRender={option => <ReactCountryFlag code={option.value} svg={true} />}
      onItemClick={onItemClick}
    />
  );
};
