import { SetIframeRef } from "testly-web/components/Player/Player";

import React, { PureComponent } from "react";
import styled from "styled-components/macro";

interface IframeProps {
  setIframeRef: SetIframeRef;
}

const StyledIframe = styled.iframe`
  width: 100%;
  background-color: #fff;
  z-index: -1;
`;

export class Iframe extends PureComponent<IframeProps> {
  private frameRef: React.RefObject<HTMLIFrameElement>;

  constructor(props: IframeProps) {
    super(props);
    this.frameRef = React.createRef();
  }

  public componentDidMount() {
    const current = this.frameRef.current;

    this.props.setIframeRef(current!);
  }

  public render() {
    // src="javascript:void(0);" is very important, otherwise will not work in firefox
    // `name` and `id` is uniq to avoid firefox caching

    return (
      <StyledIframe
        frameBorder="0"
        scrolling="no" // iOS bug fix, scroll scrollTo was not working
        key="frame"
        height="100%"
        ref={this.frameRef as React.RefObject<HTMLIFrameElement> & string}
        className="testly-player__iframe"
        sandbox="allow-same-origin"
        src="javascript:false;" // eslint-disable-line no-script-url
        name={Date.now().toString()}
        id={Date.now().toString()}
      />
    );
  }
}
