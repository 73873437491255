import React from "react";
import styled, { css } from "styled-components/macro";
import {
  popDownAnimation,
  popUpAnimation,
  slideDownAnimation,
  slideUpAnimation
} from "../animation";

export interface DropdownProps {
  className?: string;
  wrapperRef: React.RefObject<HTMLDivElement>;
  togglerRef: React.RefObject<HTMLDivElement>;
  closeAnimationIsRunning: boolean;
  opened: boolean;
  showPointer: boolean;
  animation: "popup" | "slide";
  onControlClick(event: React.MouseEvent<HTMLElement, MouseEvent>): void;
  dropdownContent(props: { close: DropdownProps["close"] }): React.ReactNode;
  close(): void;
}

const dropdownAnimation = ({
  opened,
  closedAnimation,
  animation
}: {
  opened: boolean;
  closedAnimation: boolean;
  animation: DropdownProps["animation"];
}) => {
  if (animation === "popup") {
    return css`
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.05s ease-in-out, transform 0.05s ease-in-out;
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.21);
      border: solid 1px #dcdae0;

      ${opened &&
        css`
          animation: ${popUpAnimation} 0.3s;
          opacity: 1;
          visibility: visible;
        `}

      ${closedAnimation &&
        css`
          animation: ${popDownAnimation} 0.15s;
        `}
    `;
  } else {
    return css`
      will-change: transform, opacity;
      overflow: hidden;
      max-height: 0;
      animation-fill-mode: both;
      animation-timing-function: ease-out;
      animation-duration: 0.2s;

      ${opened &&
        css`
          border: solid 1px #dcdae0;
          max-height: none;
          animation-name: ${slideDownAnimation("40px")};
          transition: box-shadow 0.1s linear 0.1s;
          box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.21);
        `}

      ${closedAnimation &&
        css`
          border: solid 1px #dcdae0;
          animation-name: ${slideUpAnimation("40px")};
        `}
    `;
  }
};

// eslint-disable-next-line no-unexpected-multiline
export const DropdownContent = styled.div<{
  opened: boolean;
  closedAnimation: boolean;
  showPointer: boolean;
  animation: DropdownProps["animation"];
}>`
  position: absolute;
  border-radius: 4px;
  background-color: #ffffff;
  top: 100%;
  width: 100%;
  font-weight: normal;
  font-size: 14px;
  right: -1px;
  z-index: 1000;
  box-sizing: border-box;

  ${dropdownAnimation};

  ${({ showPointer }) =>
    showPointer &&
    css`
      margin-top: 9px;
      &:before {
        content: " ";
        height: 10px;
        width: 10px;
        margin-right: 4px;
        position: absolute;
        top: -6px;
        right: 14px;
        display: inline-block;
        border: solid 2px rgba(81, 86, 99, 0.24);
        border-width: 0 1px 1px 0;
        transform: rotate(-135deg);
        background-color: #ffffff;
      }
    `}
`;

const StyledDropdown = styled.div`
  position: relative;
`;

export const DropdownCaptionContainer = styled.div`
  cursor: pointer;
  position: relative;
  &:focus {
    outline: 0;
  }
`;

export const Dropdown: React.SFC<DropdownProps> = ({
  className,
  onControlClick,
  children,
  closeAnimationIsRunning: closedAnimation,
  opened,
  wrapperRef,
  dropdownContent,
  togglerRef,
  showPointer,
  animation,
  close
}) => (
  <StyledDropdown className={className}>
    <DropdownCaptionContainer
      onClick={onControlClick}
      role="button"
      tabIndex={0}
      ref={togglerRef as React.RefObject<HTMLDivElement> & string}
    >
      {children}
    </DropdownCaptionContainer>

    <DropdownContent
      closedAnimation={closedAnimation}
      opened={opened}
      ref={wrapperRef as React.RefObject<HTMLDivElement> & string}
      showPointer={showPointer}
      onClick={e => {
        onControlClick(e);
        if (opened) {
          close();
        }
      }}
      animation={animation}
    >
      {dropdownContent({ close })}
    </DropdownContent>
  </StyledDropdown>
);
