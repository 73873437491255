import { PageMatcherMatchType } from "testly-web/queries";

type Matches = { [key in PageMatcherMatchType]: string };

export const matches: Matches = {
  CONTAINS: "Contains",
  MATCHES_EXACTLY: "Match Exactly",
  BEGINS_WITH: "Begins With",
  ENDS_WITH: "Ends With"
};
