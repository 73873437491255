import { TimelineManager } from "./TimelineManager";
import { SessionEventType } from "session-player/eventTypes";
import { PlayingStateName, PlayingState } from "./PlaybackManager";

const userInteractionEvents = [
  SessionEventType.ScrollPositionChanged,
  SessionEventType.MouseClicked,
  SessionEventType.MouseMoved,
  SessionEventType.WindowSizeChanged
];

const pauseInterval = 1000;

export class PlaybackStateManager {
  private timelineManager: TimelineManager;
  private statesQueue: ((currentState: PlayingState) => PlayingState)[] = [];
  public currentState: PlayingState = {
    playTime: 0,
    name: PlayingStateName.Seeking
  };

  public constructor(timelineManager: TimelineManager) {
    this.timelineManager = timelineManager;
  }

  public addNextState = (
    newState: (currentState: PlayingState) => PlayingState
  ) => {
    this.statesQueue.push(newState);
  };

  public nextState = (
    previousTimestamp: number,
    currentTimestamp: number,
    speed: number,
    skipPause: boolean
  ): [PlayingState, PlayingState] => {
    const previousState = this.currentState;

    this.currentState = this.calculateNextState(
      this.currentState,
      previousTimestamp,
      currentTimestamp,
      speed,
      skipPause
    );

    return [previousState, this.currentState];
  };

  private calculateNextState = (
    currentState: PlayingState,
    previousTimestamp: number,
    currentTimestamp: number,
    speed: number,
    skipPause: boolean
  ) => {
    if (this.statesQueue.length > 0) {
      return this.statesQueue.shift()!(currentState);
    }

    const timelineDuration = this.timelineManager.duration();
    const frameTimeDelta = (currentTimestamp - previousTimestamp) * speed;

    if (currentState.playTime === timelineDuration) {
      return {
        playTime: timelineDuration,
        name: PlayingStateName.Finished
      };
    }

    switch (currentState.name) {
      case PlayingStateName.Seeking:
        return {
          playTime: currentState.playTime,
          name: PlayingStateName.SeekingPlay
        };
      case PlayingStateName.SeekingPlay:
        return {
          playTime: currentState.playTime,
          name: PlayingStateName.Paused
        };
      case PlayingStateName.Playing:
        return {
          playTime: this.calcNewPlayTime(
            currentState,
            frameTimeDelta,
            timelineDuration,
            skipPause
          ),
          name: PlayingStateName.Playing
        };
      case PlayingStateName.TimelineChange:
        return {
          playTime: 0,
          name: PlayingStateName.Seeking
        };
      case PlayingStateName.Finished: // happens when timeline is expanded, timelineDuration > currentState.playTime
        return {
          playTime: currentState.playTime,
          name: PlayingStateName.Paused
        };

      default:
        return currentState;
    }
  };

  private calcNewPlayTime = (
    currentState: PlayingState,
    frameTimeDelta: number,
    timelineDuration: number,
    skipPause: boolean
  ) => {
    if (skipPause) {
      const events = this.timelineManager.getAllEvents();

      const nextUserEvent = events
        .filter(e => currentState.playTime < e.time)
        .find(e => userInteractionEvents.includes(e.eventType));

      if (
        nextUserEvent &&
        nextUserEvent.time - currentState.playTime > pauseInterval
      ) {
        return nextUserEvent.time;
      }
    }

    if (frameTimeDelta + currentState.playTime > timelineDuration) {
      return timelineDuration;
    } else {
      return frameTimeDelta + currentState.playTime;
    }
  };
}
