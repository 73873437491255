import React from "react";

export const Search2Icon: React.SFC<React.SVGProps<SVGSVGElement>> = props => (
  // tslint:disable:max-line-length
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    {...props}
  >
    <path
      fill="#393839"
      d="M11.077 0C7.26 0 4.154 3.107 4.154 6.923a6.87 6.87 0 0 0 1.558 4.37L.195 16.81l.995.995 5.517-5.517a6.87 6.87 0 0 0 4.37 1.558c3.816 0 6.923-3.107 6.923-6.923S14.893 0 11.077 0zm0 1.385a5.528 5.528 0 0 1 5.538 5.538 5.528 5.528 0 0 1-5.538 5.539 5.528 5.528 0 0 1-5.539-5.539 5.528 5.528 0 0 1 5.539-5.538z"
    />
  </svg>
);
