import { darken } from "polished";
import React from "react";
import styled from "styled-components/macro";
import { Collapse } from "testly-web/icons/Collapse";
import { Expand } from "testly-web/icons/Expand";
import { PauseIcon } from "testly-web/icons/PauseIcon";
import { Play } from "testly-web/icons/Play";
import { toMMSS } from "testly-web/utils";
import { TimeIcon } from "./TimeIcon";
import { Checkbox } from "testly-web/components/Form/Checkbox";

interface ControlsProps {
  isPlaying: boolean;
  timelineDuration: number;
  currentPlayTime: number;
  isFullscreen: boolean;
  skipPauses: boolean;
  playingSpeed: number;
  setPlayingSpeed(speed: number): void;
  setPauseSkipping(skipPauses: boolean): void;
  onControlClick(): void;
  onFullsreenClick(): void;
}

const controlBarHeight = 50;

const ControlBarStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  height: ${controlBarHeight}px;
  min-height: ${controlBarHeight}px;
  background-color: #393839;
`;

const controlBtnBg = "#00ac69";

const ControlButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${controlBarHeight}px;
  height: ${controlBarHeight}px;
  background-color: ${controlBtnBg};

  cursor: pointer;
  padding: 0;
  border: none;
  outline: none;

  transition: background-color 0.3s;

  &:hover {
    background-color: ${darken(0.03, controlBtnBg)};
  }
`;

const CurrentTime = styled.div`
  margin-left: 10px;

  color: #fff;
`;

const Delimiter = styled.div`
  &:before {
    margin-left: 3px;
    margin-right: 3px;
    content: "/";

    color: #a7a9ae;
  }
`;

const Duration = styled.div`
  color: #a7a9ae;
`;

const fullscreenBtnBg = "#1e1d1e";
const FullscreenBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${controlBarHeight}px;
  height: ${controlBarHeight}px;
  background-color: ${fullscreenBtnBg};
  margin-left: 20px;

  cursor: pointer;
  padding: 0;
  border: none;
  outline: none;
`;

const RightPart = styled.div`
  display: flex;
  margin-left: auto;
`;

const SpeedController = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SpeedChoice = styled.div<{ active: boolean }>`
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;

  cursor: pointer;

  margin-left: 10px;
  color: ${({ active }) => (active ? "#fff" : "#747474")};
`;

const CheckboxStyled = styled(Checkbox)`
  display: flex;
  align-items: center;

  color: #fff;

  margin-right: 10px;
  padding: 0 16px;

  border-right: 1px solid #515051;
  border-left: 1px solid #515051;
`;

export const ControlBar = React.memo(
  ({
    onControlClick,
    onFullsreenClick,
    timelineDuration,
    currentPlayTime,
    isPlaying,
    isFullscreen,
    playingSpeed,
    setPlayingSpeed,
    skipPauses,
    setPauseSkipping
  }: ControlsProps) => (
    <ControlBarStyled>
      <ControlButton onClick={onControlClick}>
        {isPlaying ? <PauseIcon /> : <Play />}
      </ControlButton>
      <CurrentTime>{toMMSS(currentPlayTime)}</CurrentTime>
      <Delimiter />
      <Duration>{toMMSS(timelineDuration)}</Duration>

      <RightPart>
        <CheckboxStyled
          label="Skip Pauses"
          checked={skipPauses}
          onChange={() => {
            setPauseSkipping(!skipPauses);
          }}
          green={true}
        />
        <SpeedController>
          <TimeIcon />
          <SpeedChoice
            active={playingSpeed === 1}
            onClick={() => {
              setPlayingSpeed(1);
            }}
          >
            NORMAL
          </SpeedChoice>
          <SpeedChoice
            active={playingSpeed === 2}
            onClick={() => {
              setPlayingSpeed(2);
            }}
          >
            2X
          </SpeedChoice>
          <SpeedChoice
            active={playingSpeed === 3}
            onClick={() => {
              setPlayingSpeed(3);
            }}
          >
            3X
          </SpeedChoice>
        </SpeedController>
        <FullscreenBtn onClick={onFullsreenClick}>
          {isFullscreen ? <Collapse /> : <Expand />}
        </FullscreenBtn>
      </RightPart>
    </ControlBarStyled>
  )
);
