import React from "react";
import { toast } from "react-toastify";
import { onMutationError } from "testly-web/components/onFail";
import { DeleteFeedbackPollResponseComponent } from "testly-web/queries";

export const DeleteFeedbackPollResponse: React.SFC<{
  feedbackPollResponseId: string;
  afterDelete?(): void;
  children(onDelete: (event: React.SyntheticEvent) => void): React.ReactNode;
}> = ({ feedbackPollResponseId, children, afterDelete }) => (
  <DeleteFeedbackPollResponseComponent onError={onMutationError}>
    {deleteFeedbackPollResponse =>
      children(async () => {
        await deleteFeedbackPollResponse({
          variables: { feedbackPollResponseId }
        });

        toast.info("The feedback response was deleted.");

        if (afterDelete) {
          afterDelete();
        }
      })
    }
  </DeleteFeedbackPollResponseComponent>
);
