import React from "react";

export const CrossIcon: React.SFC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="10"
    viewBox="0 0 10 10"
    {...props}
  >
    <path
      fill="#FF5858"
      d="M.05 1.464L3.586 5 .05 8.536 1.464 9.95 5 6.414 8.536 9.95 9.95 8.536 6.414 5 9.95 1.464 8.536.05 5 3.586 1.464.05z"
    />
  </svg>
);
