import React from "react";

export const LearningIcon = () => (
  // tslint:disable:max-line-length
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="29"
    height="27"
    viewBox="0 0 29 27"
  >
    <path
      fillRule="nonzero"
      d="M27.001 0c-1.103 0-1.997.94-1.997 2.1 0 .166.02.333.054.487l-4.172 4.07a2.004 2.004 0 0 0-.445-.057c-.29 0-.57.07-.82.188L16.715 4.95C16.64 3.86 15.79 3 14.736 3c-1.103 0-1.997.94-1.997 2.1 0 .045.016.087.018.131L9.584 7.894a1.956 1.956 0 0 0-.553-.094c-.982 0-1.794.74-1.96 1.725l-3.423 1.669A1.934 1.934 0 0 0 2.47 10.8c-1.103 0-1.997.94-1.997 2.1 0 1.16.894 2.1 1.997 2.1.911 0 1.669-.64 1.907-1.519l3.655-1.762c.292.178.637.281.998.281 1.103 0 1.997-.94 1.997-2.1 0-.045-.016-.087-.018-.131l3.173-2.663c.174.052.361.094.553.094.285 0 .557-.075.802-.187l2.924 1.837c.073 1.09.925 1.95 1.979 1.95 1.103 0 1.996-.94 1.996-2.1 0-.094-.006-.19-.017-.281l4.367-4.238c.07.007.143.019.214.019 1.103 0 1.997-.94 1.997-2.1C28.998.94 28.104 0 27 0zm0 15.6c-1.103 0-1.997.94-1.997 2.1 0 .171.016.347.054.506l-4.172 4.05a2.004 2.004 0 0 0-.445-.056c-.12 0-.243.016-.357.037l-3.387-3.562c.02-.12.036-.248.036-.375 0-1.16-.894-2.1-1.997-2.1-1.103 0-1.997.94-1.997 2.1 0 .045.016.087.018.131l-3.155 2.663a1.93 1.93 0 0 0-.57-.094c-.571 0-1.081.253-1.445.656l-3.262-.544C4.03 20.34 3.31 19.8 2.47 19.8c-1.103 0-1.997.94-1.997 2.1 0 1.16.894 2.1 1.997 2.1.51 0 .967-.213 1.32-.544l3.458.563c.323.7.993 1.181 1.782 1.181 1.103 0 1.997-.94 1.997-2.1 0-.045-.016-.087-.018-.131l3.173-2.663c.174.052.361.094.553.094.12 0 .243-.016.357-.038l3.387 3.563c-.02.12-.036.248-.036.375 0 1.16.894 2.1 1.997 2.1 1.103 0 1.996-.94 1.996-2.1 0-.094-.006-.19-.017-.281l4.385-4.238c.065.007.13.019.196.019 1.103 0 1.997-.94 1.997-2.1 0-1.16-.894-2.1-1.997-2.1z"
    />
  </svg>
);
