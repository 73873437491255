import React from "react";
import styled from "styled-components/macro";
import { ContextMenuIcon } from "testly-web/icons/ContextMenuIcon";
import { DropdownCaptionContainer, DropdownContent } from "../Dropdown";
import { DropdownContainer } from "../Dropdown/DropdownContainer";

const StyledDropdown = styled(DropdownContainer)`
  ${DropdownContent} {
    min-width: 110px;
    margin-right: -21px;
    margin-top: 12px;

    &:before {
      right: 12px;
    }
  }

  ${DropdownCaptionContainer} {
    display: flex;
  }
`;

const ContextActions = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 18px;
  font-size: 16px;
  color: #393839;
  text-align: left;

  font-weight: 500;
  font-size: 16px;
`;

const ContextMenuStyled = styled(ContextMenuIcon)`
  margin-left: 32px;
`;

export const ContextActionItem = styled.div`
  margin-bottom: 0.88em;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }

  &:last-child {
    margin-bottom: 0px;
  }
`;

export const ContextDropdown: React.SFC = ({ children }) => (
  <StyledDropdown
    dropdownContent={() => <ContextActions>{children}</ContextActions>}
    animation="popup"
  >
    <ContextMenuStyled />
  </StyledDropdown>
);
