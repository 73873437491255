import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { SetCurrentProjectId } from "testly-web/components/CurrentProjectId/SetCurrentProjectId";
import { loadingRender } from "testly-web/components/loadingRender";
import { SetNavbar } from "testly-web/components/Navbar/SetNavbar";
import { mapSplitTest } from "testly-web/components/split-test-form/mapSplitTest";
import { paths } from "testly-web/paths";
import { GetDataForSplitTestIndexPageComponent } from "testly-web/queries";
import { SplitTestIndexPage } from "./SplitTestIndexPage";
import { buildQuery, parseQuery, QueryState } from "./urlQueryState";

const perPage = 10;

export const SplitTestIndexPageContainer: React.SFC<
  RouteComponentProps<{ projectId: string }>
> = ({
  history,
  match: {
    params: { projectId }
  }
}) => {
  const queryState = parseQuery(history.location.search);

  const handlePageFilterChange = (state: QueryState) => {
    history.replace({
      pathname: history.location.pathname,
      search: buildQuery(state)
    });
  };

  return (
    <>
      <SetCurrentProjectId id={projectId} />
      <SetNavbar
        header="Split Tests"
        backPath={paths.projectPath({ projectId })}
      />

      <GetDataForSplitTestIndexPageComponent
        variables={{
          projectId,
          conversionGoalId: null,
          perPage,
          filter: queryState.filter,
          page: queryState.page
        }}
        fetchPolicy="network-only"
      >
        {loadingRender("currentUser.project", ({ data }) => (
          <SplitTestIndexPage
            projectId={projectId}
            splitTests={data!.currentUser!.project!.splitTestsConnection.splitTests.map(
              mapSplitTest
            )}
            pageCategories={data!.pageCategories}
            pageTypes={data!.pageTypes}
            page={queryState.page}
            pageCount={Math.ceil(
              data!.currentUser!.project!.splitTestsConnection.totalRecords /
                perPage
            )}
            filter={queryState.filter}
            onFilterChange={changedFilter => {
              handlePageFilterChange({
                page: 1,
                filter: changedFilter
              });
            }}
            onPageChange={({ selected }) => {
              window.scrollTo({
                top: 0,
                left: 0
              });

              handlePageFilterChange({
                ...queryState,
                page: selected
              });
            }}
          />
        ))}
      </GetDataForSplitTestIndexPageComponent>
    </>
  );
};
