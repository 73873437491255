import { chunk, flatMap } from "lodash";
import React from "react";
import styled from "styled-components/macro";
import { Column, ColumnTitle } from "./styles";
import { OptionType } from "./types";

const ChunkableOptions = styled.div`
  margin-top: 19px;
`;

export const CheckboxFilter: React.SFC<{
  name: string;
  options: OptionType[];
  optionRender(option: OptionType): React.ReactNode;
}> = ({ name, options, optionRender }) => (
  <>
    {flatMap(chunk(options, 6), (chunkedOptions: OptionType[], i: number) => (
      <Column key={i}>
        {i === 0 ? (
          <ColumnTitle>{name}</ColumnTitle>
        ) : (
          <ColumnTitle>&nbsp;</ColumnTitle>
        )}
        <ChunkableOptions>{chunkedOptions.map(optionRender)}</ChunkableOptions>
      </Column>
    ))}
  </>
);
