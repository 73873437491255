import { SafeStylesheet } from "session-player/stylesheet/SafeStylesheet";
import { IdentifiableVNode } from "session-player/virtualDom/types";

// We need to fix stylesheets, cause some of rules maybe inserted via
// insertRule https://developer.mozilla.org/en-US/docs/Web/API/CSSStyleSheet/insertRule
// that will not affect any <style> tag value changes(like apps that use styled-components)
export const fixStyleSheets = (node: IdentifiableVNode, doc: Document) => {
  const sheetRules: string[] | null = node.data.sheetRules;

  if (sheetRules) {
    const el: Element | HTMLStyleElement | null = doc.querySelector(
      `[data-testlyplayernodeid='${node.data.nodeId}']`
    );

    if (el && "sheet" in el) {
      const safeStylesheet = new SafeStylesheet(el.sheet as CSSStyleSheet);
      safeStylesheet.resetAllRules();

      sheetRules.forEach((rule, i) => {
        safeStylesheet.insertRule(rule, i);
      });
    } else {
      console.error(el, "not instance of HTMLStyleElement");
    }
  }

  if (node.children) {
    for (const childNode of node.children) {
      fixStyleSheets(childNode, doc);
    }
  }
};
