import { uniqueId } from "lodash";
import React from "react";
import styled, { css } from "styled-components/macro";

const greenColor = "#03a365";

interface CheckboxInputProps {
  green?: boolean;
  light?: boolean;
}

// With position: abolut scroll of SelectBox have very strange behaviour
export const CheckboxInput = styled.input<CheckboxInputProps>`
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  margin: 0;
  padding: 0;

  & + label {
    display: flex;
    position: relative;
    cursor: pointer;
    padding: 0;
    min-width: 0;
  }

  & + label:before {
    content: "";
    margin-right: 10px;
    margin-top: -1px;
    display: inline-block;
    vertical-align: text-top;
    width: 16px;
    min-width: 16px;
    height: 16px;
    border-radius: 2px;
    background-color: rgba(255, 255, 255, 0.2);
    border: solid 1px rgba(255, 255, 255, 0.6);
  }

  &:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1);
  }

  &:checked + label:after {
    content: "";
    position: absolute;
    left: 3px;
    top: 8px;
    background: white;
    width: 3px;
    height: 3px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
      4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
  }

  ${({ green }) =>
    green &&
    css`
      & + label:before {
        background-color: rgba(255, 255, 255, 0.05);
        border: solid 1px rgba(255, 255, 255, 0.2);
      }

      &:checked + label:after {
        background: ${greenColor};
        box-shadow: 2px 0 0 ${greenColor}, 4px 0 0 ${greenColor},
          4px -2px 0 ${greenColor}, 4px -4px 0 ${greenColor},
          4px -6px 0 ${greenColor}, 4px -8px 0 ${greenColor};
      }

      &:checked + label:before {
        border: solid 1px ${greenColor};
      }
    `};

  ${({ light }) =>
    light &&
    css`
      & + label:before {
        border-color: #a7a9ae;
      }
    `}
`;

const CheckboxStyled = styled.div`
  display: flex;
  font-size: 14px;
`;

const LabelText = styled.div`
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
`;

export const Checkbox: React.SFC<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > &
    CheckboxInputProps & {
      label?: string;
    }
> = ({ label, ref, className, ...props }) => {
  const id = uniqueId();

  return (
    <CheckboxStyled className={className}>
      <CheckboxInput {...props} type="checkbox" id={id} />
      <label htmlFor={id}>
        <LabelText>{label}</LabelText>
      </label>
    </CheckboxStyled>
  );
};
