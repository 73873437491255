import memoize from "memoize-one";
import React from "react";
import scrollIntoView from "scroll-into-view-if-needed";
import { SessionEvent, SessionEventType } from "session-player/eventTypes";
import styled from "styled-components/macro";
import { GetSessionRecordingForRecordingPageFeedbackPollResponses } from "testly-web/queries";
import { ViewableActionType } from "../viewableActions";
import { Action, ViewableAction } from "./Action";
import { PollAnswersDropdown } from "./PollAnswersDropdown";

const ActionsList = styled.div`
  max-height: 366px;
  overflow-y: auto;
  overflow-x: visible;
  scroll-behavior: smooth;
`;

const ActionsStyled = styled.div`
  background-color: #ffffff;
  margin: 40px 0px;
`;

const Title = styled.div`
  padding: 23px 23px 16px;

  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  color: #393839;
`;

interface ActionsProps {
  eventsOnTimeline: SessionEvent[];
  currentPlayTime: number;
  pollResponses: GetSessionRecordingForRecordingPageFeedbackPollResponses[];
  seek(time: number): void;
}

export class Actions extends React.Component<ActionsProps> {
  private takeViewableActionsMemoized = memoize(this.takeViewableActions);
  private mapActionsWithRefs = memoize((actions: ViewableAction[]) =>
    actions.map(a => ({
      action: a,
      ref: React.createRef<HTMLDivElement>()
    }))
  );
  private actionsWithRefs: Array<{
    action: ViewableAction;
    ref: React.RefObject<HTMLDivElement>;
  }>;
  private prevScrolledTime?: number;
  private listRef: React.RefObject<HTMLDivElement>;

  constructor(props: ActionsProps) {
    super(props);

    this.actionsWithRefs = [];
    this.listRef = React.createRef<HTMLDivElement>();
  }

  public componentDidUpdate() {
    const nearestAction = this.actionsWithRefs.find(
      ({ action: event }) => this.props.currentPlayTime <= event.time
    );
    const el = nearestAction && nearestAction.ref.current;

    if (nearestAction && el && this.listRef.current) {
      if (this.prevScrolledTime !== nearestAction.action.time) {
        scrollIntoView(el, {
          block: "center",
          behavior: "smooth",
          scrollMode: "if-needed",
          boundary: this.listRef.current
        });

        this.prevScrolledTime = nearestAction.action.time;
      }
    }
  }

  public render() {
    const {
      eventsOnTimeline,
      pollResponses,
      currentPlayTime,
      seek
    } = this.props;

    const viewableActions = this.takeViewableActionsMemoized(
      eventsOnTimeline,
      pollResponses
    );

    // Note: should be here! Not in constructor, cause
    // new props can be passed
    this.actionsWithRefs = this.mapActionsWithRefs(viewableActions);

    return (
      <ActionsStyled>
        <Title>Actions</Title>
        <ActionsList
          ref={this.listRef as React.RefObject<HTMLDivElement> & string}
        >
          {this.actionsWithRefs.map(({ action, ref }) => (
            <Action
              innerRef={ref}
              active={currentPlayTime >= action.time}
              key={action.time}
              onClick={() => {
                seek(action.time);
              }}
              action={action}
            />
          ))}
        </ActionsList>
      </ActionsStyled>
    );
  }

  private takeViewableActions(
    events: SessionEvent[],
    pollResponses: GetSessionRecordingForRecordingPageFeedbackPollResponses[]
  ) {
    const firstEventDateTime = events[0].happenedAt;
    const lastEventDateTime = events[events.length - 1].happenedAt;

    const pollRespondedActions = pollResponses
      .map(response => ({
        ...response,
        firstInteractionAt: Date.parse(response.firstInteractionAt),
        dropdownContent: (
          <PollAnswersDropdown
            pollResponse={response}
            firstEventAt={firstEventDateTime}
            onPollAnswerClick={(time: number) => {
              this.props.seek(time);
            }}
          />
        )
      }))
      .filter(
        response =>
          response.firstInteractionAt >= firstEventDateTime &&
          response.firstInteractionAt <= lastEventDateTime
      )
      .map(
        (response): ViewableAction => ({
          actionType: ViewableActionType.RespondedToPoll,
          title: "Poll",
          body: response.feedbackPoll.name,
          time: response.firstInteractionAt - firstEventDateTime,
          dropdownContent: response.dropdownContent
        })
      );

    const viewableEvents = events
      .filter(
        event =>
          event.eventType === SessionEventType.MouseClicked ||
          event.eventType === SessionEventType.PageVisited
      )
      .map(
        (e): ViewableAction => {
          if (e.eventType === SessionEventType.MouseClicked) {
            return {
              actionType: ViewableActionType.MouseClicked,
              title: "Clicked",
              body: e.data.selector,
              time: e.time
            };
          } else if (e.eventType === SessionEventType.PageVisited) {
            return {
              actionType: ViewableActionType.PageVisited,
              title: "Navigate",
              body: e.data.url,
              time: e.time
            };
          } else {
            throw new Error(`${e} is not viewable action`);
          }
        }
      );

    return viewableEvents
      .concat(pollRespondedActions)
      .sort((a, b) => a.time - b.time);
  }
}
