import React from "react";

export const SimplePlusIcon: React.SFC<
  React.SVGProps<SVGSVGElement>
> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    {...props}
  >
    <path fill="#19b5fe" d="M5 0v5H0v2h5v5h2V7h5V5H7V0z" />
  </svg>
);
