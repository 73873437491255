import React from "react";
import {
  GetDataForAccountSettingsComponent,
  UpdatePasswordAtAccountSettingsComponent,
  UpdateProfileAtAccountSettingsComponent
} from "testly-web/queries";
import { AccountSettings } from "./AccountSettings";

export const AccountSettingsContainer = () => (
  <UpdatePasswordAtAccountSettingsComponent>
    {mutatePassword => (
      <UpdateProfileAtAccountSettingsComponent>
        {mutateProfile => (
          <GetDataForAccountSettingsComponent>
            {({ data }) =>
              (data && data.currentUser && (
                <div>
                  <AccountSettings
                    active={
                      (data &&
                        data.accountSettingsTab &&
                        data.accountSettingsTab.active) ||
                      false
                    }
                    user={data.currentUser}
                    updateProfile={mutateProfile}
                    updatePassword={mutatePassword}
                  />
                </div>
              )) ||
              null
            }
          </GetDataForAccountSettingsComponent>
        )}
      </UpdateProfileAtAccountSettingsComponent>
    )}
  </UpdatePasswordAtAccountSettingsComponent>
);
