import styled from "styled-components/macro";

export const DropdownArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  &::before {
    content: " ";
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 4px solid #000;
    margin: 0 auto;
  }
`;
