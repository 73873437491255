import React from "react";
import { GetCurrentProjectId } from "../../CurrentProjectId/GetCurrentProjectId";
import { DropdownController } from "../../Dropdown/DropdownController";
import { OpenedMenuType } from "../../LeftBarSlideMenu/SlideMenu";
import { ActiveType, LeftBar } from "./LeftBar";

interface LeftBarContainerState {
  openedMenuType: OpenedMenuType;
  activeType: ActiveType;
}

class TypedDropdownController extends DropdownController<
  HTMLDivElement,
  HTMLDivElement
> {}

export class LeftBarContainer extends React.Component<
  {},
  LeftBarContainerState
> {
  constructor(props: {}) {
    super(props);

    this.state = {
      openedMenuType: "none",
      activeType: "none"
    };
  }

  public render() {
    return (
      <GetCurrentProjectId>
        {currentProjectId => (
          <TypedDropdownController>
            {({ onControlClick, togglerRef, ...props }) => (
              <LeftBar
                openedMenuType={this.state.openedMenuType}
                onLearningClick={e => {
                  this.handleLearningClick(
                    e,
                    onControlClick,
                    props.opened,
                    "learning"
                  );
                }}
                onProvingClick={e => {
                  this.handleLearningClick(
                    e,
                    onControlClick,
                    props.opened,
                    "proving"
                  );
                }}
                togglerRef={togglerRef}
                menuState={{ ...props, onControlClick }}
                activeType={
                  props.opened
                    ? this.state.openedMenuType
                    : this.state.activeType
                }
                currentProjectId={currentProjectId}
              />
            )}
          </TypedDropdownController>
        )}
      </GetCurrentProjectId>
    );
  }

  private handleLearningClick = (
    e: React.MouseEvent<Element, MouseEvent>,
    onControlClick: (e: React.MouseEvent<Element, MouseEvent>) => void,
    opened: boolean,
    openedMenuType: OpenedMenuType
  ) => {
    if (!(opened && this.state.openedMenuType !== openedMenuType)) {
      onControlClick(e);
    }

    this.setState({ openedMenuType });
  };
}
