import { ApolloError } from "apollo-client";
import React from "react";
import { MutationFn } from "react-apollo";
import {
  ActivateSplitTestAtSplitTestEditPageComponent,
  ActivateSplitTestAtSplitTestEditPageMutation,
  ActivateSplitTestAtSplitTestEditPageVariables,
  FinishSplitTestAtSplitTestEditPageComponent,
  FinishSplitTestAtSplitTestEditPageMutation,
  FinishSplitTestAtSplitTestEditPageVariables,
  PauseSplitTestAtSplitTestEditPageComponent,
  PauseSplitTestAtSplitTestEditPageMutation,
  PauseSplitTestAtSplitTestEditPageVariables
} from "testly-web/queries";

export interface SplitTestActions {
  activate: MutationFn<
    ActivateSplitTestAtSplitTestEditPageMutation,
    ActivateSplitTestAtSplitTestEditPageVariables
  >;
  pause: MutationFn<
    PauseSplitTestAtSplitTestEditPageMutation,
    PauseSplitTestAtSplitTestEditPageVariables
  >;
  complete: MutationFn<
    FinishSplitTestAtSplitTestEditPageMutation,
    FinishSplitTestAtSplitTestEditPageVariables
  >;
}

export const SpitTestActionMutations: React.SFC<{
  children: (props: SplitTestActions, loading: boolean) => React.ReactNode;
  onError?: (error: ApolloError) => void;
}> = ({ children, onError }) => (
  <ActivateSplitTestAtSplitTestEditPageComponent onError={onError}>
    {(activateMutation, activateResult) => (
      <PauseSplitTestAtSplitTestEditPageComponent onError={onError}>
        {(pauseMutation, pauseResult) => (
          <FinishSplitTestAtSplitTestEditPageComponent onError={onError}>
            {(completeMutation, completeResult) =>
              children(
                {
                  activate: activateMutation,
                  pause: pauseMutation,
                  complete: completeMutation
                },
                activateResult.loading ||
                  pauseResult.loading ||
                  completeResult.loading
              )
            }
          </FinishSplitTestAtSplitTestEditPageComponent>
        )}
      </PauseSplitTestAtSplitTestEditPageComponent>
    )}
  </ActivateSplitTestAtSplitTestEditPageComponent>
);
