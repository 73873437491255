import React from "react";
import { Redirect } from "react-router";
import { LoadingBar } from "testly-web/components/LoadingBar";
import { paths } from "testly-web/paths";
import { GetRelevantProjectForRedirectPageComponent } from "testly-web/queries";

const NEW_PROJECT_URL = process.env.REACT_APP_NEW_PROJECT_URL as string;

export const ProjectRedirectPage = () => {
  return (
    <GetRelevantProjectForRedirectPageComponent>
      {({ data, loading }) => {
        if (data && !loading) {
          const relevantProject = data.currentUser!.relevantProject;
          if (relevantProject) {
            return (
              <Redirect
                to={paths.projectPath({ projectId: relevantProject.id })}
              />
            );
          } else {
            window.location.replace(NEW_PROJECT_URL);

            return null;
          }
        } else {
          return <LoadingBar show={true} />;
        }
      }}
    </GetRelevantProjectForRedirectPageComponent>
  );
};
