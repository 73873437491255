import React from "react";

export const SplitTestSettingsIcon = () => (
  // tslint:disable:max-line-length
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22">
    <path
      fill="#A7A9AE"
      d="M22 9h-3a8 8 0 0 0-1-2l2-2V4l-2-2h-1l-2 2a8 8 0 0 0-2-1V0h-3-1v3a8 8 0 0 0-2 1L5 2H4L2 4v1l2 2a8 8 0 0 0-1 2H0v4h3a8 8 0 0 0 1 2l-2 2v1l2 2h1l2-2a8 8 0 0 0 2 1v3h4v-3a8 8 0 0 0 2-1l2 2h1l2-2v-1l-2-2a8 8 0 0 0 1-2h3v-3-1zm-11 5a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"
    />
  </svg>
);
