import memoize from "memoize-one";
import React from "react";
import styled from "styled-components/macro";

interface Dimension {
  width: number;
  height: number;
}

interface ScalableWrapperProps {
  sessionDimension: Partial<Dimension> | undefined;
  wrapperRef: React.RefObject<HTMLDivElement>;
  center: boolean;
}

interface ScalableWrapperState {
  wrapperDimension?: Dimension;
}

// eslint-disable-next-line no-unexpected-multiline
const Scaler = React.memo(styled.div<{
  width: number | undefined;
  height: number | undefined;
  marginLeft: number;
  marginTop: number;
  scale: number;
}>`
  transform: scale(${({ scale }) => scale});
  width: ${({ width }) => (width ? `${width}px` : "100%")};
  height: ${({ height }) => (height ? `${height}px` : "100%")};
  margin-left: ${({ marginLeft }) => `${marginLeft}px`};
  margin-top: ${({ marginTop }) => `${marginTop}px`};

  transform-origin: left top 0px;
  display: flex;
  overflow: hidden;
`);

export class ScalableIframeWrapper extends React.PureComponent<
  ScalableWrapperProps,
  ScalableWrapperState
> {
  public timer?: number;

  private mapWrapperDimension = memoize((width: number, height: number) => ({
    width,
    height
  }));

  private calcScale = memoize(
    (
      wrapperDimension: Dimension | undefined,
      sessionDimension: Dimension | undefined
    ) => {
      if (!wrapperDimension || !sessionDimension) {
        return 1;
      }

      const ratio = Math.min(
        wrapperDimension.width / sessionDimension.width,
        wrapperDimension.height / sessionDimension.height
      );

      return ratio > 1 ? 1 : ratio;
    }
  );

  private calcCentering = memoize(
    (
      wrapperDimension: Dimension | undefined,
      sessionDimension: Dimension | undefined,
      scale: number
    ) => {
      if (!wrapperDimension || !sessionDimension) {
        return { marginLeft: 0, marginTop: 0 };
      }

      return {
        marginLeft:
          (wrapperDimension.width - sessionDimension.width * scale) / 2,
        marginTop:
          (wrapperDimension.height - sessionDimension.height * scale) / 2
      };
    }
  );

  constructor(props: ScalableWrapperProps) {
    super(props);

    this.state = {
      wrapperDimension: undefined
    };
  }

  public componentDidMount() {
    this.timer = setInterval(this.setWrapperSizes, 100);
  }

  public componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  public render() {
    const wrapperDimension = this.state.wrapperDimension;
    const sessionDimension = this.mapSessionDimension(
      this.props.sessionDimension,
      this.state.wrapperDimension
    );

    const scale = this.calcScale(wrapperDimension, sessionDimension);

    const { marginLeft, marginTop } = this.props.center
      ? this.calcCentering(wrapperDimension, sessionDimension, scale)
      : { marginLeft: 0, marginTop: 0 };

    return (
      <Scaler
        scale={scale}
        width={sessionDimension && sessionDimension.width}
        height={sessionDimension && sessionDimension.height}
        marginLeft={marginLeft}
        marginTop={marginTop}
      >
        {this.props.children}
      </Scaler>
    );
  }

  private mapSessionDimension = (
    sessionDimension: Partial<Dimension> | undefined,
    wrapperDimension: Dimension | undefined
  ): Dimension | undefined => {
    return wrapperDimension
      ? {
          width:
            (sessionDimension && sessionDimension.width) ||
            wrapperDimension.width,
          height:
            (sessionDimension && sessionDimension.height) ||
            wrapperDimension.height
        }
      : undefined;
  };

  private setWrapperSizes = () => {
    const wrapper = this.props.wrapperRef.current;

    if (!wrapper) {
      return;
    }

    this.setState({
      wrapperDimension: this.mapWrapperDimension(
        wrapper.clientWidth,
        wrapper.clientHeight
      )
    });
  };
}
