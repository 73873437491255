import styled, { css } from "styled-components/macro";

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0px 20px;
`;

export const MoreVariationsButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  height: 40px;
  width: 120px;

  font-family: Roboto;
  font-size: 14px;
  color: #19b5fe;
  cursor: pointer;
  border: none;
  appearance: none;
  background-color: transparent;

  transition: color 0.3s ease-in-out;

  &:hover {
    color: #1079a9;
  }

  &:focus {
    outline: none;
  }
`;

// eslint-disable-next-line no-unexpected-multiline
export const DropdownArrow = styled.div<{
  direction: "up" | "down";
}>`
  border: solid #393839;
  border-width: 0 1.5px 1.5px 0;
  display: inline-block;
  padding: 3px;

  transition: transform 0.4s linear;
  margin-left: 8px;

  vertical-align: middle;

  ${({ direction }) =>
    direction === "up" &&
    css`
      transform: rotate(-135deg);
      margin-bottom: -3px;
    `}

  ${({ direction }) =>
    direction === "down" &&
    css`
      transform: rotate(45deg);
      margin-top: -5px;
    `}
`;
