import React from "react";

export const LogoutIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="17"
    viewBox="0 0 18 17"
  >
    <path
      fill="#A7A9AE"
      d="M8.875 0c3.128 0 5.867 1.717 7.343 4.25h-1.696c-1.295-1.715-3.344-2.833-5.647-2.833-3.896 0-7.083 3.187-7.083 7.083 0 3.896 3.187 7.083 7.083 7.083 2.302 0 4.352-1.118 5.647-2.833h1.696C14.742 15.283 12.003 17 8.875 17c-4.675 0-8.5-3.825-8.5-8.5S4.2 0 8.875 0zm4.25 5.667L17.109 8.5l-3.984 2.833V9.208H6.75V7.792h6.375V5.667z"
    />
  </svg>
);
