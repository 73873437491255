import { Form, FormikProps } from "formik";
import React from "react";
import { GoalFields } from "testly-web/components/goal/GoalFields";
import { PathGoalForm } from "testly-web/components/goal/pathGoal";
import { Body, Footer, Panel } from "testly-web/components/Panel";

export const GoalForm: React.SFC<{
  formProps: FormikProps<PathGoalForm>;
  footerRender?(isSubmitting: boolean): React.ReactNode;
}> = ({ footerRender, formProps }) => (
  <Panel>
    <Form>
      <Body>
        <GoalFields formProps={formProps} />
      </Body>
      <Footer>{footerRender && footerRender(formProps.isSubmitting)}</Footer>
    </Form>
  </Panel>
);
