import React from "react";
import { HeatmapIcon } from "testly-web/icons/HeatmapIcon";
import { GoalIcon } from "testly-web/icons/GoalIcon";
import { PollIcon } from "testly-web/icons/PollIcon";
import { SessionRecordingIcon } from "testly-web/icons/SessionRecordingIcon";
import { paths } from "testly-web/paths";
import { Feature, FeatureImageWrapper } from "./Feature";
import { Body, Section, Title } from "./Section";

export const LearningContent: React.SFC<{
  currentProjectId: string | null;
  onItemClick(e: React.MouseEvent<Element, MouseEvent>): void;
}> = ({ currentProjectId, onItemClick }) => (
  <>
    <Section>
      <Title>Learning</Title>
      <Body>
        <Feature
          to={paths.sessionRecordingListPath({
            projectId: currentProjectId || ""
          })}
          onClick={onItemClick}
        >
          <FeatureImageWrapper>
            <SessionRecordingIcon />
          </FeatureImageWrapper>
          Session Recordings
        </Feature>
        <Feature
          to={paths.heatmapIndexPath({
            projectId: currentProjectId || ""
          })}
          onClick={onItemClick}
        >
          <FeatureImageWrapper>
            <HeatmapIcon />
          </FeatureImageWrapper>
          Heatmaps
        </Feature>
        <Feature
          to={paths.feedbackPollIndexPath({
            projectId: currentProjectId || ""
          })}
          onClick={onItemClick}
        >
          <FeatureImageWrapper>
            <PollIcon />
          </FeatureImageWrapper>
          Feedback Polls
        </Feature>
      </Body>
    </Section>
    <Section>
      <Title>Tracking</Title>
      <Body>
        <Feature
          to={paths.goalListPath({
            projectId: currentProjectId || ""
          })}
          onClick={onItemClick}
        >
          <FeatureImageWrapper>
            <GoalIcon />
          </FeatureImageWrapper>
          Goals
        </Feature>
      </Body>
    </Section>
  </>
);
