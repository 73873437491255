import React from "react";

export const OptionsIcon = () => (
  // tslint:disable:max-line-length
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
  >
    <path d="M6.397 0c-.783 0-1.439.655-1.439 1.437v.685H0v1.415h4.958v.685A1.45 1.45 0 0 0 6.375 5.66h.022l.642.022h.022c.783 0 1.439-.655 1.439-1.437V3.56l8.5-.022V2.122l-8.5.022V1.46C8.5.683 7.855.033 7.083.022h-.022L6.42 0h-.022zm-.022 1.415h.022l.642.022h.022c.017 0 .022.005.022.022v2.785c0 .009.003.02 0 .023h-.022l-.642-.023h-.022c-.016 0-.022-.005-.022-.022V1.437c0-.008-.003-.02 0-.022zm5.689 4.244c-.783 0-1.439.655-1.439 1.437v.686H0v1.414h10.625v.686a1.45 1.45 0 0 0 1.417 1.437h.022l.642.022h.022c.783 0 1.439-.655 1.439-1.437v-.686L17 9.196V7.782l-2.833.022v-.686a1.45 1.45 0 0 0-1.417-1.437h-.022l-.642-.022h-.022zm-.022 1.415h.022l.642.022h.022c.016 0 .022.006.022.022v2.786c0 .008.003.02 0 .022h-.022l-.642-.022h-.022c-.017 0-.022-.006-.022-.022V7.096c0-.008-.003-.02 0-.022zM3.564 11.32c-.783 0-1.439.655-1.439 1.437v.685H0v1.415h2.125v.685a1.45 1.45 0 0 0 1.417 1.437h.022l.642.022h.022c.783 0 1.439-.655 1.439-1.437v-.685L17 14.856V13.44l-11.333.022v-.685A1.45 1.45 0 0 0 4.25 11.34h-.022l-.642-.022h-.022zm-.022 1.414h.022l.642.023h.022c.016 0 .022.005.022.022v2.785c0 .008.003.02 0 .022h-.022l-.642-.022h-.022c-.017 0-.022-.005-.022-.022v-2.785c0-.009-.003-.02 0-.023z" />
  </svg>
);
