import React from "react";
import { CheckboxInFilter } from "testly-web/components/filters/AdvancedFilter/CheckboxInFilter";
import { handleInFilterChange } from "testly-web/components/filters/AdvancedFilter/inFilter";
import { OnFilterChange } from "testly-web/components/filters/AdvancedFilter/types";
import { SplitTestFilter } from "testly-web/queries";

const filterName = "pageTypeIdIn";

export interface PageType {
  id: string;
  name: string;
}

export const pageTypeFilter = (
  filter: SplitTestFilter,
  onFilterChange: OnFilterChange<SplitTestFilter>,
  pageTypes: PageType[]
) => {
  const pageTypesIn = filter[filterName] || [];
  const onChange = handleInFilterChange(filter, filterName, onFilterChange);

  return (
    <CheckboxInFilter
      key={filterName}
      name="Page Types"
      options={pageTypes.map(({ name, id }) => ({
        name,
        value: id,
        selected: pageTypesIn.includes(id)
      }))}
      onChange={onChange}
    />
  );
};
