import { flatMap, groupBy } from "lodash";
import { heatmapViewPadding } from "../config";
import {
  CalculatedClickPoint,
  CountedHeatmapElement,
  HeatmapClickElement,
  HeatmapElement
} from "./ClickHeatmap";

export const calcHeatmapElements = (
  elements: HeatmapClickElement[],
  doc: Document,
  win: Window
): HeatmapElement[] => {
  let pointMaxClicksCount = -1;

  const visibleElemnts = elements.filter(({ selector, clickPoints }) => {
    const el: HTMLElement | null = doc.querySelector(selector);

    // if (selector === "[lang]") {
    //   return false;
    // }

    if (!el) {
      console.error(`Selector '${selector}' was not found for the snapshot`);
      return false;
    }

    const visible = !!(
      el.offsetWidth ||
      el.offsetHeight ||
      el.getClientRects().length
    );

    if (!visible) {
      console.error(`Selector '${selector}' is invisible`);
      return false;
    }

    if (el.tagName.toLocaleLowerCase() === "html") {
      // In most cases it just scroll clicks
      return false;
    }

    return true;
  });

  visibleElemnts.forEach(({ clickPoints }) =>
    clickPoints.forEach(
      ({ count }) =>
        (pointMaxClicksCount = Math.max(pointMaxClicksCount, count))
    )
  );

  return visibleElemnts.map(({ selector, clickPoints }) => {
    const el = doc.querySelector(selector) as HTMLElement;

    const boundingRect = el.getBoundingClientRect();
    const { width, height } = boundingRect;
    const left = boundingRect.left + win.scrollX + heatmapViewPadding;
    const top = boundingRect.top + win.scrollY + heatmapViewPadding;

    const calcualtedClicks = clickPoints.map(point => {
      const weight = (point.count / pointMaxClicksCount) * 100;

      return {
        ...point,
        x: Math.floor(left + width * (point.percentX / 100)),
        y: Math.floor(top + height * (point.percentY / 100)),
        weight: weight < 1 ? 1 : weight
      };
    });

    const groupedClicks = Object.values(
      groupBy(calcualtedClicks, click => `${click.x}${click.y}`)
    ).map(clicks => {
      const firstClick = clicks[0];

      return {
        ...firstClick,
        count: clicks.reduce((sum, click) => sum + click.count, 0),
        weight: clicks.reduce((sum, click) => sum + click.weight, 0)
      };
    });

    return {
      element: el,
      width,
      height,
      top,
      left,
      selector,
      calculatedClickPoints: groupedClicks
    };
  });
};

const checkPointInElement = (
  point: CalculatedClickPoint,
  element: HeatmapElement
) =>
  element.top <= point.y &&
  point.y <= element.top + element.height &&
  element.left <= point.x &&
  point.x <= element.left + element.width;

export const calcClicksCount = (
  elements: HeatmapElement[]
): CountedHeatmapElement[] => {
  const allPoints = flatMap(
    elements,
    ({ calculatedClickPoints: positionedClickPoints }) => positionedClickPoints
  );

  return elements
    .map(el => {
      const clicksCount = allPoints.reduce((currentClicksCount, point) => {
        if (checkPointInElement(point, el)) {
          return currentClicksCount + point.count;
        } else {
          return currentClicksCount;
        }
      }, 0);

      if (clicksCount === 0) {
        // Can happen when percentX/percentY is negative
        console.error(el, "0 clicks after fix!");
        return null;
      }

      return { ...el, clicksCount };
    })
    .filter(el => el !== null) as CountedHeatmapElement[];
};
