import React from "react";
import NumberFormat from "react-number-format";
import styled, { css } from "styled-components/macro";

type ImagePosition = "left" | "right";

export const border = "solid 1px rgba(167, 169, 174, 0.5)";
export const focusBorder = "solid 1px #19b5fe";
export const errorBorderColor = "#eb547c";
export const errorBorder = `solid 2px ${errorBorderColor}`;

export const InputCss = ({
  withError,
  dark
}: {
  withError?: boolean;
  dark?: boolean;
}) => css`
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  border-radius: 2px;
  background-color: #ffffff;
  border: ${border};
  padding-left: 10px;
  padding-right: 10px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #393839;

  &::placeholder {
    color: rgba(62, 72, 98, 0.5);
  }

  &:focus {
    border: ${focusBorder};
    outline: none;
  }

  &:read-only {
    border: solid 1px rgba(167, 169, 174, 0.5);
    background-color: #f8f8f8;
  }

  ${dark &&
    css`
      border: solid 1px #686768;
      background-color: #393839;
      color: #fff;
    `}

  ${withError &&
    css`
      border: ${errorBorder} !important;
    `}
`;

// eslint-disable-next-line no-unexpected-multiline
export const Textarea = styled.textarea<{
  withError?: boolean;
  dark?: boolean;
}>`
  ${InputCss};
  height: 80px;
  min-height: 80px;
  padding: 10px 14px;
  resize: vertical;
`;

// eslint-disable-next-line no-unexpected-multiline
export const Input = styled.input<{
  withError?: boolean;
  withImage?: boolean;
  clickable?: boolean;
  imagePosition?: ImagePosition;
  dark?: boolean;
}>`
  ${InputCss};
  min-width: 0;

  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
    `};

  ${({ withImage, imagePosition }) =>
    withImage &&
    imagePosition === "right" &&
    css`
      padding-right: 32px;
    `};

  ${({ withImage, imagePosition }) =>
    withImage &&
    imagePosition === "left" &&
    css`
      padding-left: 32px;
    `};

  &:disabled {
    background-color: #f0f1f1;
    cursor: not-allowed;
  }
`;

export const ImageInputWrapper = styled.div`
  position: relative;
`;
export const ImageWrapper = styled.div<{ position: ImagePosition }>`
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
  pointer-events: none;
  svg {
    pointer-events: none;
  }

  ${({ position }) =>
    position === "left" &&
    css`
      left: 8px;
    `};
`;

export const NumberFormatStyled = styled(NumberFormat)`
  ${InputCss};
`;

export type InputWithImageProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  clickable?: boolean;
  dark?: boolean;
  imagePosition: ImagePosition;
  ref?: React.RefObject<HTMLInputElement>;
  imageRender(): React.ReactNode;
};

export const InputWithImage: React.SFC<InputWithImageProps> = React.forwardRef<
  HTMLInputElement,
  InputWithImageProps
>(
  (
    { imageRender, imagePosition, ...props }: InputWithImageProps,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const { ref: otherRef, ...inputProps } = props;

    return (
      <ImageInputWrapper>
        <Input
          withImage={true}
          imagePosition={imagePosition}
          ref={ref as React.RefObject<HTMLInputElement>}
          {...inputProps}
        />
        <ImageWrapper position={imagePosition}>{imageRender()}</ImageWrapper>
      </ImageInputWrapper>
    );
  }
);

// export const InputWithImage: React.SFC<
//   React.DetailedHTMLProps<
//     React.InputHTMLAttributes<HTMLInputElement>,
//     HTMLInputElement
//   > & {
//     clickable?: boolean;
//     innerRef?: React.RefObject<HTMLInputElement>;
//     dark?: boolean;
//     imagePosition: ImagePosition;
//     imageRender(): React.ReactNode;
//   }
// > = ({ imageRender, ref, imagePosition, ...props }) => (
//   <ImageInputWrapper>
//     <Input withImage={true} imagePosition={imagePosition} {...props} />
//     <ImageWrapper position={imagePosition}>{imageRender()}</ImageWrapper>
//   </ImageInputWrapper>
// );
