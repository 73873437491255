import { NetworkStatus } from "apollo-client";
import React from "react";
import styled from "styled-components/macro";
import {
  GetProjectForProjectPageProject,
  GetProjectForProjectPageBillingProducts,
  GetProjectForProjectPageCurrentBilling
} from "testly-web/queries";
import { ProjectStatusColumn } from "./ProjectStatusTable/ProjectStatusColumn";

export const ProjectStatusTableStyled = styled.div`
  height: auto;
`;

interface ProjectStatusTableProps {
  className?: string;
  project: GetProjectForProjectPageProject;
  networkStatus: NetworkStatus;
  refetch(): void;
  billingProducts: GetProjectForProjectPageBillingProducts[];
  currentBilling: GetProjectForProjectPageCurrentBilling;
}

export const ProjectStatusTable: React.SFC<ProjectStatusTableProps> = ({
  className,
  project,
  networkStatus,
  refetch,
  billingProducts,
  currentBilling
}) => (
  <ProjectStatusTableStyled className={className}>
    <ProjectStatusColumn
      project={project}
      networkStatus={networkStatus}
      refetch={refetch}
      billingProducts={billingProducts}
      currentBilling={currentBilling}
    />
  </ProjectStatusTableStyled>
);
