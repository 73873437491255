import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { SetCurrentProjectId } from "testly-web/components/CurrentProjectId/SetCurrentProjectId";
import { loadingRender } from "testly-web/components/loadingRender";
import { SetNavbar } from "testly-web/components/Navbar/SetNavbar";
import { pageProps } from "testly-web/components/session-recordings-list/pageProps";
import { paths } from "testly-web/paths";
import { GetDataForSplitTestSessionRecordingIndexPageComponent } from "testly-web/queries";
import { parseQuery } from "../../components/session-recordings-list/query";
import { SplitTestSessionRecordingIndexPage } from "./SplitTestSessionRecordingIndexPage";

const perPage = 30;

export const SplitTestSessionRecordingIndexPageContainer: React.SFC<
  RouteComponentProps<{
    projectId: string;
    splitTestId: string;
  }>
> = ({
  match: {
    params: { projectId, splitTestId }
  },
  history
}) => {
  const parsedQuery = parseQuery(history.location.search);
  const queryState = {
    ...parsedQuery,
    filter: { ...parsedQuery.filter, splitTestIdEq: splitTestId }
  };

  return (
    <>
      <SetCurrentProjectId id={projectId} />
      <SetNavbar
        header="Session Recordings"
        backPath={paths.splitTestIndexPath({ projectId })}
      />
      <GetDataForSplitTestSessionRecordingIndexPageComponent
        variables={{
          projectId,
          splitTestId,
          perPage,
          page: queryState.page,
          filter: queryState.filter
        }}
        notifyOnNetworkStatusChange={true}
        fetchPolicy="network-only"
      >
        {loadingRender(
          "currentUser.project.sessionRecordings.nodes",
          ({ data, networkStatus, refetch }, isDataLoaded) => (
            <SplitTestSessionRecordingIndexPage
              sessionRecordingListProps={() => ({
                projectGoals: [],
                splitTestGoals: data!.currentUser!.project!.splitTest!.goals,
                sessionRecordings: data!.currentUser!.project!.sessionRecordings
                  .nodes,
                filter: queryState.filter,
                page: queryState.page,
                queryState,
                networkStatus,
                refetch,
                filtersToShow: [
                  "device",
                  "country",
                  "splitTestGoals",
                  "duration",
                  "referrerSource"
                ],
                columns: [
                  "variation",
                  "country",
                  "duration",
                  "device",
                  "referrerSource",
                  "resolution",
                  "splitTestGoals",
                  "createdAt",
                  "action"
                ],
                ...pageProps({
                  totalRecords: data!.currentUser!.project!.sessionRecordings
                    .totalRecords,
                  history,
                  perPage,
                  queryState
                })
              })}
              splitTestId={splitTestId}
              projectId={projectId}
              isDataLoaded={isDataLoaded}
            />
          ),
          true
        )}
      </GetDataForSplitTestSessionRecordingIndexPageComponent>
    </>
  );
};
