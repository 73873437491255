import queryString from "query-string";
import { SessionRecordingFilter } from "testly-web/queries";

export interface QueryState {
  page: number;
  filter: SessionRecordingFilter;
  cameFrom: CameFrom;
}

export type CameFrom = "dashboard" | "goals" | "feedbackPolls";

export const buildFilter = (
  filter?: Partial<SessionRecordingFilter>
): SessionRecordingFilter => ({
  createdAtGteq: null,
  createdAtLteq: null,
  deviceTypeIn: null,
  durationGteq: null,
  durationLteq: null,
  locationCountryIsoCodeIn: null,
  referrerSourceIn: null,
  convertedProjectGoalIdsIn: null,
  convertedSplitTestGoalIdsIn: null,
  splitTestIdEq: null,
  ...filter
});

export const buildQuery = ({ page, filter, cameFrom }: QueryState) =>
  `?page=${page}&filter=${encodeURI(
    JSON.stringify(filter)
  )}&cameFrom=${cameFrom}`;

export const parseQuery = (search: string): QueryState => {
  const parsedQuery: {
    page?: string;
    filter?: string;
    cameFrom?: string;
  } = queryString.parse(search) || {};

  return {
    page: parsedQuery.page ? parseInt(parsedQuery.page, 10) : 1,
    filter: parsedQuery.filter
      ? (JSON.parse(parsedQuery.filter) as SessionRecordingFilter)
      : buildFilter(),
    cameFrom: (parsedQuery.cameFrom || "dashboard") as CameFrom
  };
};
