import { capitalize } from "lodash";
import moment from "moment";
import React from "react";
import ReactCountryFlag from "react-country-flag";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { CalendarIcon } from "testly-web/icons/CalendarIcon";
import { DeviceIcon } from "testly-web/icons/DeviceIcon";
import { LocationIcon } from "testly-web/icons/LocationIcon";
import { ResolutionIcon } from "testly-web/icons/ResolutionIcon";
import { SourceIcon } from "testly-web/icons/SourceIcon";
import { TimeIcon } from "testly-web/icons/TimeIcon";
import { paths } from "testly-web/paths";
import {
  SessionRecordingForList_GoalEntityInlineFragment,
  SessionRecordingForListFragment
} from "testly-web/queries";
import { DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT } from "testly-web/settings";
import { toMMSS } from "testly-web/utils";
import { Button } from "../Button";
import { Caption } from "../Caption";
import { BodyCell, BodyCellWithImage, SecondTextLine } from "../Table";

export type BodyCell = React.SFC<{
  sessionRecording: SessionRecordingForListFragment;
}>;

export const StartingPageCell: BodyCell = ({
  sessionRecording: { startingPage }
}) => {
  const { title, url } = {
    title: (startingPage || {}).title || "Title Not Found",
    url: (startingPage || {}).url || "URL Not Found"
  };

  return (
    <BodyCell canContainLongText={true}>
      {title}
      <SecondTextLine>{url}</SecondTextLine>
    </BodyCell>
  );
};

export const VariationCell: BodyCell = ({
  sessionRecording: { splitTestEdges, startingPage }
}) => {
  const url = (startingPage || {}).url || "URL Not Found";
  return (
    <>
      {splitTestEdges.map(({ visitedVariation: { name, id } }) => (
        <BodyCell key={id} canContainLongText={true}>
          {name}
          <SecondTextLine>{url}</SecondTextLine>
        </BodyCell>
      ))}
    </>
  );
};

export const CountryCell: BodyCell = ({
  sessionRecording: {
    location: { country, countryIsoCode }
  }
}) => (
  <BodyCellWithImage
    image={() =>
      countryIsoCode ? (
        <ReactCountryFlag code={countryIsoCode} svg={true} />
      ) : (
        <LocationIcon />
      )
    }
  >
    {country || "Unknown"}
  </BodyCellWithImage>
);

const OnlineCaptionStyled = styled(Caption)`
  margin-left: 5px;
`;

export const DurationCell: BodyCell = ({
  sessionRecording: { duration, isVisitorOnline }
}) => (
  <BodyCellWithImage image={TimeIcon}>
    {toMMSS(duration / 1000)}{" "}
    {isVisitorOnline === true && (
      <OnlineCaptionStyled size="small">online</OnlineCaptionStyled>
    )}
  </BodyCellWithImage>
);

export const DeviceCell: BodyCell = ({
  sessionRecording: {
    device: { deviceType }
  }
}) => (
  <BodyCellWithImage image={() => <DeviceIcon deviceType={deviceType} />}>
    {capitalize(deviceType)}
  </BodyCellWithImage>
);

export const ReferrerSourceCell: BodyCell = ({
  sessionRecording: { referrerSource }
}) => (
  <BodyCellWithImage image={SourceIcon}>
    {capitalize(referrerSource)}
  </BodyCellWithImage>
);

export const ResolutionCell: BodyCell = ({
  sessionRecording: {
    device: { screenWidth, screenHeight }
  }
}) => (
  <BodyCellWithImage image={ResolutionIcon}>
    {screenWidth}x{screenHeight}
  </BodyCellWithImage>
);

export const goalCell: (
  goals: (
    sessionRecording: SessionRecordingForListFragment
  ) => SessionRecordingForList_GoalEntityInlineFragment[]
) => BodyCell = goals => {
  return ({ sessionRecording }) => {
    const goalsCaption = goals(sessionRecording)
      .map(({ name }) => name)
      .join(", ");

    return <BodyCell canContainLongText={true}>{goalsCaption}</BodyCell>;
  };
};

export const CreatedAtCell: BodyCell = ({
  sessionRecording: { createdAt }
}) => {
  const createdAtMoment = moment(createdAt);

  return (
    <BodyCellWithImage image={CalendarIcon} style={{ whiteSpace: "nowrap" }}>
      {createdAtMoment.format(DEFAULT_DATE_FORMAT)}
      <SecondTextLine>
        {createdAtMoment.format(DEFAULT_TIME_FORMAT)}
      </SecondTextLine>
    </BodyCellWithImage>
  );
};

const ActionButton = styled(Button)`
  width: 110px;
`;

export const ActionCell: BodyCell = ({
  sessionRecording: { id, projectId }
}) => (
  <BodyCell>
    <Link
      to={{
        pathname: paths.sessionRecordingPath({
          sessionRecordingId: id,
          projectId
        }),
        state: {
          previousPath: `${window.location.pathname}${window.location.search}`
        }
      }}
    >
      <ActionButton action="secondary" size="small">
        View
      </ActionButton>
    </Link>
  </BodyCell>
);
