import { NetworkStatus } from "apollo-client";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { Button } from "testly-web/components/Button";
import {
  ContextActionItem,
  ContextDropdown
} from "testly-web/components/ContextDropdown/ContextDropdown";
import { DeleteFeedbackPoll } from "testly-web/components/feedback/DeleteFeedbackPoll";
import {
  ActionsWrapper,
  BodyCell,
  BodyCellWithImage,
  HeadCell,
  Row,
  SecondTextLine,
  Table,
  TableEmptyCell,
  TableRefreshIcon
} from "testly-web/components/Table";
import { CalendarIcon } from "testly-web/icons/CalendarIcon";
import { PollActiveIcon } from "testly-web/icons/PollActiveIcon";
import { PollAnswerIcon } from "testly-web/icons/PollAnswerIcon";
import { PollNotActiveIcon } from "testly-web/icons/PollNotActiveIcon";
import { paths } from "testly-web/paths";
import { DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT } from "testly-web/settings";

interface FeedbackPoll {
  id: string;
  name: string;
  isActive: boolean;
  responsesCount: number;
  createdAt: string;
}

const FeedbackPollIndexPageStyled = styled.div`
  padding: 40px;
`;

const TableStyled = styled(Table)`
  table-layout: fixed;
`;

const ActionCell = styled(BodyCell)`
  width: 250px;
`;

const ButtonStyled = styled(Button)`
  margin-bottom: 32px;
  float: right;
`;

const ActionItemLink = ContextActionItem.withComponent(Link);

export const FeedbackPollIndexPage: React.SFC<{
  feedbackPolls: FeedbackPoll[];
  projectId: string;
  networkStatus: NetworkStatus;
  refetch(): void;
}> = ({ projectId, feedbackPolls, networkStatus, refetch }) => (
  <FeedbackPollIndexPageStyled>
    <Link to={paths.feedbackPollNewPath({ projectId })}>
      <ButtonStyled action="primary" size="small">
        Create Feedback Poll
      </ButtonStyled>
    </Link>

    <TableStyled>
      <thead>
        <Row hover={false}>
          <HeadCell>Name</HeadCell>
          <HeadCell>Responses</HeadCell>
          <HeadCell>Status</HeadCell>
          <HeadCell>Created On</HeadCell>
          <HeadCell>
            <TableRefreshIcon
              isRefreshing={networkStatus === NetworkStatus.refetch}
              onClick={e => {
                refetch();
              }}
            />
          </HeadCell>
        </Row>
      </thead>
      <tbody>
        {feedbackPolls.length === 0 && (
          <Row hover={false}>
            <TableEmptyCell>No feedback polls found.</TableEmptyCell>
          </Row>
        )}
        {feedbackPolls.map(poll => (
          <Row hover={false} key={poll.id}>
            <BodyCell canContainLongText={true}>{poll.name}</BodyCell>
            <BodyCellWithImage image={PollAnswerIcon}>
              {poll.responsesCount.toLocaleString()}
            </BodyCellWithImage>
            <BodyCellWithImage
              image={poll.isActive ? PollActiveIcon : PollNotActiveIcon}
            >
              {poll.isActive ? "Active" : "Inactive"}
            </BodyCellWithImage>
            <BodyCellWithImage image={CalendarIcon}>
              {moment(poll.createdAt).format(DEFAULT_DATE_FORMAT)}
              <SecondTextLine>
                {moment(poll.createdAt).format(DEFAULT_TIME_FORMAT)}
              </SecondTextLine>
            </BodyCellWithImage>
            <ActionCell>
              <ActionsWrapper>
                <Link
                  to={paths.feedbackPollResponseIndexPath({
                    projectId,
                    feedbackPollId: poll.id
                  })}
                >
                  <Button action="secondary" size="small">
                    View Responses
                  </Button>
                </Link>
                <ContextDropdown>
                  <ActionItemLink
                    to={paths.feedbackPollEditPath({
                      projectId,
                      feedbackPollId: poll.id
                    })}
                  >
                    Edit
                  </ActionItemLink>
                  <DeleteFeedbackPoll feedbackPoll={poll} afterDelete={refetch}>
                    {confirm => (
                      <ContextActionItem onClick={confirm}>
                        Delete
                      </ContextActionItem>
                    )}
                  </DeleteFeedbackPoll>
                </ContextDropdown>
              </ActionsWrapper>
            </ActionCell>
          </Row>
        ))}
      </tbody>
    </TableStyled>
  </FeedbackPollIndexPageStyled>
);
