// import React from "react";
import React from "react";
import styled, { css } from "styled-components/macro";
import refresh from "./refresh.svg";

type RefreshIconProps = {
  isRefreshing: boolean;
} & React.HTMLAttributes<HTMLDivElement> &
  React.DOMAttributes<HTMLDivElement>;

export const RefreshIconStyled = styled.div<RefreshIconProps>`
  @keyframes spin {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }

  position: absolute;
  border: none;
  background-image: url(${refresh});
  width: 18px;
  height: 24px;
  top: 15px;
  right: 15px;
  outline: none;
  cursor: pointer;

  animation-fill-mode: forwards;

  ${({ isRefreshing }) =>
    isRefreshing &&
    css`
      animation: spin 0.7s infinite ease;
    `};
`;

interface RefreshIconState {
  stopped: boolean;
  isRefreshing: boolean;
}

export class RefreshIcon extends React.Component<
  RefreshIconProps,
  RefreshIconState
> {
  public static getDerivedStateFromProps(
    props: Readonly<RefreshIconProps>,
    state: RefreshIconState
  ) {
    if (props.isRefreshing === true) {
      return { ...state, isRefreshing: props.isRefreshing };
    } else {
      return state;
    }
  }
  constructor(props: RefreshIconProps) {
    super(props);

    this.state = {
      isRefreshing: false,
      stopped: true
    };
  }

  public render() {
    return (
      <RefreshIconStyled
        onAnimationIteration={this.handleAnimationIteration}
        {...this.props}
        isRefreshing={this.state.isRefreshing}
        onClick={this.props.onClick}
      />
    );
  }

  private handleAnimationIteration = (
    e: React.AnimationEvent<HTMLDivElement>
  ): void => {
    if (this.props.isRefreshing === false) {
      this.setState({ isRefreshing: false });
    }
  };
}
