import styled from "styled-components/macro";
import { sidePadding } from "./styled-vars";

export const Section = styled.div`
  text-align: left;

  margin-top: 48px;

  &:first-child {
    margin-top: 0px;
  }
`;
export const Title = styled.h1`
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  color: #ecedee;
  text-transform: uppercase;
  margin: 0;
  padding: 0 ${sidePadding}px;
`;
export const Body = styled.div`
  margin-top: 20px;
`;
