import React from "react";
import styled from "styled-components/macro";
import { RemoveIcon } from "testly-web/icons/RemoveIcon";
import { OptionType } from "./types";

const ListOptionedStyled = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);

  margin-bottom: 12px;
  margin-right: 8px;
`;

const ListOptionedLabel = styled.span`
  overflow: hidden;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;

const StyledRemove = styled(RemoveIcon)`
  margin-left: auto;
  margin-right: 8px;
  cursor: pointer;
  min-width: 11px;
  flex-shrink: 0;
  padding-left: 5px;
`;

export const ListOption: React.SFC<{
  option: OptionType;
  onDeleteClick(option: OptionType): void;
  iconRender?(option: OptionType): React.ReactNode;
}> = ({ option, iconRender, onDeleteClick }) => {
  return (
    <ListOptionedStyled>
      {iconRender && <IconWrapper>{iconRender(option)}</IconWrapper>}
      <ListOptionedLabel>{option.name}</ListOptionedLabel>
      <StyledRemove
        onClick={() => {
          onDeleteClick(option);
        }}
      />
    </ListOptionedStyled>
  );
};
