import { FormikActions } from "formik";
import queryString from "query-string";
import React from "react";
import { MutationFn } from "react-apollo";
import { RouteComponentProps } from "react-router-dom";
import { toast } from "react-toastify";
import { SetCurrentProjectId } from "testly-web/components/CurrentProjectId/SetCurrentProjectId";
import {
  FeedbackFormValues,
  mapFormValuesToMutationParams
} from "testly-web/components/feedback/schema";
import { SetNavbar } from "testly-web/components/Navbar/SetNavbar";
import { onFormSubmitFail } from "testly-web/components/onFail";
import { paths } from "testly-web/paths";
import {
  CreateFeedbackPollAtFeedbackPollNewPageComponent,
  CreateFeedbackPollAtFeedbackPollNewPageMutation,
  CreateFeedbackPollAtFeedbackPollNewPageVariables
} from "testly-web/queries";
import { FeedbackPollNewPage } from "./FeedbackPollNewPage";

export const FeedbackPollNewPageContainer: React.SFC<
  RouteComponentProps<{ projectId: string }>
> = ({
  match: {
    params: { projectId }
  },
  history: { push }
}) => {
  const queryState: {
    cameFrom?: string;
  } = queryString.parse(window.location.search) || {};

  const onSubmit = async (
    mutate: MutationFn<
      CreateFeedbackPollAtFeedbackPollNewPageMutation,
      CreateFeedbackPollAtFeedbackPollNewPageVariables
    >,
    values: FeedbackFormValues,
    actions: FormikActions<FeedbackFormValues>
  ) => {
    const response = await mutate({
      variables: {
        projectId,
        feedbackPollParams: mapFormValuesToMutationParams(values)
      }
    });

    actions.setSubmitting(false);

    if (response && response.data && response.data.createFeedbackPoll) {
      const { successful, result } = response.data.createFeedbackPoll;

      if (successful && result) {
        toast.success("Your feedback poll has been created.");
        actions.resetForm();
        push(paths.feedbackPollIndexPath({ projectId }));
      } else {
        onFormSubmitFail(values, response.data);
        paths.feedbackPollIndexPath({ projectId });
      }
    }
  };

  return (
    <>
      <SetCurrentProjectId id={projectId} />
      <SetNavbar
        header="Create Feedback Poll"
        backPath={
          queryState.cameFrom === "dashboard"
            ? paths.projectPath({ projectId })
            : paths.feedbackPollIndexPath({ projectId })
        }
      />
      <CreateFeedbackPollAtFeedbackPollNewPageComponent>
        {mutate => (
          <FeedbackPollNewPage
            projectId={projectId}
            onSubmit={(values, actions) => {
              onSubmit(mutate, values, actions);
            }}
          />
        )}
      </CreateFeedbackPollAtFeedbackPollNewPageComponent>
    </>
  );
};
