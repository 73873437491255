import React from "react";
import styled from "styled-components/macro";
import { DropdownProject } from "./types";

const StyledProject = styled.div`
  display: flex;
  align-items: center;
`;

const ProjectDomain = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  color: #393839;

  text-overflow: ellipsis;
  overflow: hidden;

  margin-right: 38px;
`;
// const ProjectImage = styled.div`
//   width: 50px;
//   height: 50px;
//   background-color: #eee;
//   border-radius: 4px;
//   margin-right: 10px;
// `;

export const Project: React.SFC<{ project: DropdownProject }> = ({
  project
}) => (
  <StyledProject>
    <ProjectDomain>{project.domain}</ProjectDomain>
  </StyledProject>
);
