import styled, { css, keyframes } from "styled-components/macro";

const dateFilterOpen = keyframes`
  0% {
    visibility: visible;
    opacity: 0;
    transform: scale(0.5, 0.5) translateX(-170px) translateY(-45px);
  }
  100% {
    visibility: visible;
    transform: scale(1, 1);
    opacity: 1;
  }
`;

const dateFilterClose = keyframes`
  0% {
    visibility: visible;
    opacity: 1;
    transform: scale(1, 1);
  }
  100% {
    transform: scale(0.5, 0.5) translateX(-170px) translateY(-45px);
    opacity: 0;
  }
`;

// eslint-disable-next-line no-unexpected-multiline
export const DateFilterStyled = styled.div<{
  opened?: boolean;
  closedByUser?: boolean;
}>`
  position: absolute;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;

  width: 368px;

  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.21);
  background-color: #fff;
  border: solid 1px #d7d7da;

  padding: 16px;

  visibility: hidden;
  z-index: 1;

  ${({ opened }) =>
    opened &&
    css`
      animation: ${dateFilterOpen} 0.15s;
      visibility: visible;
      opacity: 1;
    `};

  ${({ closedByUser }) =>
    closedByUser &&
    css`
      animation: ${dateFilterClose} 0.15s;
    `};
`;

export const DateName = styled.div`
  font-family: Roboto;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.7px;
  color: #a7a9ae;

  margin-bottom: 10px;
`;

export const DateContainer = styled.div`
  &:last-child {
    margin-left: 16px;
  }
`;
