import { IdentifiableVNode, NodePath } from "../types";
import { MapChildError, mapChildNode } from "./mapChildNode";

const deleteNodeWithoutRoot = (
  currentElement: IdentifiableVNode,
  deletePath: NodePath
): IdentifiableVNode => {
  if (deletePath.length === 1) {
    return mapChildNode(currentElement, deletePath, () => undefined);
  } else {
    return mapChildNode(currentElement, deletePath, deleteNodeWithoutRoot);
  }
};

export function deleteNode(
  currentElement: IdentifiableVNode,
  deletePath: NodePath
): IdentifiableVNode {
  if (deletePath[0] !== currentElement.data.nodeId) {
    throw new Error("First element should be root");
  }

  try {
    return deleteNodeWithoutRoot(currentElement, deletePath.slice(1));
  } catch (e) {
    if (e instanceof MapChildError) {
      return currentElement;
    } else {
      throw e;
    }
  }
}
