export class SafeStylesheet {
  private stylesheet: CSSStyleSheet;

  constructor(sheet: CSSStyleSheet) {
    this.stylesheet = sheet;
  }

  public insertRule = (rule: string, index: number) => {
    // On firefox this.stylesheet.rules can be null | undefined

    // Index can be greater then this.stylesheet.rules.length when
    // snapshot was recorded at firefox and playing on chrome,
    // So somehow stylesheet.rules.length is not the same in two browsers
    index =
      this.stylesheet.rules && index > this.stylesheet.rules.length
        ? this.stylesheet.rules.length
        : index;

    try {
      this.stylesheet.insertRule(rule, index);
    } catch (e) {
      console.error("Failed to insert rule, inserting stub instead", e);

      try {
        this.stylesheet.insertRule("stub { }", index);
      } catch (e) {
        console.error("Failed to insert stub", e);
      }
    }
  };

  public deleteRule = (index: number) => {
    // Maybe avoid it? The result can be different on chrome and firefox
    // Or deleteRule should send the rule that was deleted, instead of index

    try {
      this.stylesheet.deleteRule(index);
    } catch (e) {
      console.error("Failed to delete rule", e);
    }
  };

  public resetAllRules = () => {
    try {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.stylesheet.cssRules.length; i++) {
        this.stylesheet.deleteRule(0);
      }
    } catch (e) {
      console.error("Failed to resetAll rules", e);
    }
  };
}
