import React, { DOMAttributes } from "react";
import NumberFormat from "react-number-format";
import styled, { css } from "styled-components/macro";
import { errorBorderColor } from "../Input";
import { LeftArrowIcon } from "./LeftArrowIcon";
import { RightArrowIcon } from "./RightArrowIcon";

const borderColor = "#d3d4d6";

const NumberFormatStyled = styled(NumberFormat)<{ withError?: boolean }>`
  box-sizing: border-box;
  width: 62px;
  height: 40px;
  font-family: Roboto;
  font-size: 14px;
  color: #393839;
  text-align: center;
  border: none;
  outline: none;

  border-left: solid 1px ${borderColor};
  border-right: solid 1px ${borderColor};

  &::placeholder {
    color: rgba(62, 72, 98, 0.5);
  }

  ${({ withError }) =>
    withError &&
    css`
      border-left: solid 1px ${errorBorderColor};
      border-right: solid 1px ${errorBorderColor};
    `}
`;

// eslint-disable-next-line no-unexpected-multiline
const Control = styled.button<{
  controlType: "decrease" | "increase";
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 0;
  height: 40px;
  width: 37px;

  outline: none;
  cursor: pointer;
`;

const PercentInputStyled = styled.div<{ withError?: boolean }>`
  display: inline-flex;
  border: solid 1px rgba(167, 169, 174, 0.5);
  background-color: #fff;
  border-radius: 2px;

  ${({ withError }) =>
    withError &&
    css`
      border: solid 1px ${errorBorderColor};
    `}
`;

export const PercentInput: React.SFC<{
  onChange: (value: number) => void;
  value?: number;
  name?: string;
  onBlur?: DOMAttributes<HTMLInputElement>["onBlur"];
  id?: string;
  withError?: boolean;
}> = ({ onChange, value, name, onBlur, id, withError }) => (
  <PercentInputStyled withError={withError}>
    <Control
      className="b-button-traffic b-button-traffic--decrease"
      role="button"
      tabIndex={0}
      onClick={() => {
        if (value === 0) {
          onChange(100);
        } else if (value === undefined) {
          onChange(0);
        } else {
          onChange(value - 1);
        }
      }}
      controlType="decrease"
      type="button"
    >
      <LeftArrowIcon />
    </Control>
    <NumberFormatStyled
      value={value}
      placeholder="i.e 100%"
      suffix="%"
      name={name}
      onBlur={onBlur}
      id={id}
      onValueChange={({ formattedValue, floatValue }) => {
        if (formattedValue === "" || (floatValue <= 100 && floatValue >= 0)) {
          onChange(floatValue);
        } else {
          onChange(value || 0);
        }
      }}
      withError={withError}
      allowNegative={false}
    />
    <Control
      className="b-button-traffic b-button-traffic--increase"
      role="button"
      tabIndex={0}
      onClick={() => {
        if (value === 100) {
          onChange(0);
        } else if (value === undefined) {
          onChange(100);
        } else {
          onChange(value + 1);
        }
      }}
      controlType="increase"
      type="button"
    >
      <RightArrowIcon />
    </Control>
  </PercentInputStyled>
);
