import React, { ReactNode } from "react";

interface FullscreenState {
  fullscreen: boolean;
}

interface FullscreenProps {
  children(
    isFullscreen: boolean,
    onFullscreenClick: () => void,
    containerRef: React.RefObject<FsDocumentElement>
  ): ReactNode;
}

interface FsDocumentElement extends HTMLDivElement {
  msRequestFullscreen?(): void;
  mozRequestFullScreen?(): void;
  webkitRequestFullscreen?(): void;
}

interface FsDocument extends HTMLDocument {
  mozFullScreenElement: Element | null;
  msFullscreenElement: Element | null;
  webkitFullscreenElement: Element | null;
  fullscreenElement: Element | null;

  msExitFullscreen?(): void;
  mozCancelFullScreen?(): void;
  webkitCancelFullScreen?(): void;
}

export class FullscreenController extends React.Component<
  FullscreenProps,
  FullscreenState
> {
  public containerRef: React.RefObject<FsDocumentElement>;

  constructor(props: FullscreenProps) {
    super(props);

    this.state = {
      fullscreen: false
    };

    this.containerRef = React.createRef<FsDocumentElement>();
  }

  public componentDidMount() {
    const doc = document as FsDocument;

    doc.addEventListener("webkitfullscreenchange", this.handleFullscreenChange);
    doc.addEventListener("mozfullscreenchange", this.handleFullscreenChange);
    doc.addEventListener("fullscreenchange", this.handleFullscreenChange);
    doc.addEventListener("MSFullscreenChange", this.handleFullscreenChange);
  }
  public componentWillUnmount() {
    const doc = document as FsDocument;

    doc.removeEventListener(
      "webkitfullscreenchange",
      this.handleFullscreenChange
    );
    doc.removeEventListener("mozfullscreenchange", this.handleFullscreenChange);
    doc.removeEventListener("fullscreenchange", this.handleFullscreenChange);
    doc.removeEventListener("MSFullscreenChange", this.handleFullscreenChange);
  }

  public render() {
    return this.props.children(
      this.state.fullscreen,
      this.handleFullscreenClick,
      this.containerRef
    );
  }

  private handleFullscreenClick = () => {
    if (this.state.fullscreen) {
      this.cancelFullScreen();
    } else {
      this.enterFullsreen();
    }
  };

  private handleFullscreenChange = () => {
    const doc = document as FsDocument;

    if (
      !doc.fullscreenElement &&
      !doc.webkitFullscreenElement &&
      !doc.mozFullScreenElement &&
      !doc.msFullscreenElement
    ) {
      this.setState({ fullscreen: false });
    }

    if (
      doc.fullscreenElement ||
      doc.webkitFullscreenElement ||
      doc.mozFullScreenElement ||
      doc.msFullscreenElement
    ) {
      this.setState({ fullscreen: true });
    }
  };

  private enterFullsreen = () => {
    const playerElem = this.containerRef.current;

    if (!playerElem) {
      return;
    }

    if (playerElem.requestFullscreen) {
      playerElem.requestFullscreen().catch(() => {
        console.error("Failed to fullsreen");
      });
    } else if (playerElem.mozRequestFullScreen) {
      playerElem.mozRequestFullScreen();
    } else if (playerElem.webkitRequestFullscreen) {
      playerElem.webkitRequestFullscreen();
    } else if (playerElem.msRequestFullscreen) {
      playerElem.msRequestFullscreen();
    }
  };

  private cancelFullScreen = () => {
    const doc = document as FsDocument;

    if (doc.exitFullscreen) {
      doc.exitFullscreen().catch(() => {
        console.error("Failed to exit fullsreen");
      });
    } else if (doc.mozCancelFullScreen) {
      doc.mozCancelFullScreen();
    } else if (doc.webkitCancelFullScreen) {
      doc.webkitCancelFullScreen();
    } else if (doc.msExitFullscreen) {
      doc.msExitFullscreen();
    } else {
      return;
    }
  };
}
