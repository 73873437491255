import React from "react";
import styled from "styled-components/macro";

const StyledHeader = styled.div``;
const CaptionContainer = styled.div`
  display: flex;
  margin-bottom: 15.6px;
  min-height: 40px;
  align-items: center;
`;
const Caption = styled.span`
  display: inline-flex;
  align-items: flex-end;

  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  color: #393839;

  margin-left: 16.5px;
`;
const Description = styled.div`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.43;
  color: #a7a9ae;
`;

interface HeaderProps {
  caption: string;
  image: React.SFC;
  description: string;
}

export const Header: React.SFC<HeaderProps> = ({
  caption,
  image: Image,
  description
}: HeaderProps) => (
  <StyledHeader>
    <CaptionContainer>
      <Image />
      <Caption>{caption}</Caption>
    </CaptionContainer>
    <Description>{description}</Description>
  </StyledHeader>
);
