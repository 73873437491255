import { NetworkStatus } from "apollo-client";
import React from "react";
import styled from "styled-components/macro";
import {
  GetProjectForProjectPageProject,
  GetProjectForProjectPageBillingProducts,
  GetProjectForProjectPageCurrentBilling
} from "testly-web/queries";
import { FeaturesTable, FeaturesTableStyled } from "./FeaturesTable";
import {
  ProjectStatusTable,
  ProjectStatusTableStyled
} from "./ProjectStatusTable";
import { spaceBetweenColumns } from "./styled-vars";

const StyledProject = styled.div`
  display: flex;
  margin-left: ${spaceBetweenColumns}px;

  ${FeaturesTableStyled}, ${ProjectStatusTableStyled} {
    margin-top: 17px;
  }

  ${FeaturesTableStyled} {
    width: 75%;
  }

  ${ProjectStatusTableStyled} {
    width: 25%;
    height: auto;
  }
`;

export const ProjectPage: React.SFC<{
  billingProducts: GetProjectForProjectPageBillingProducts[];
  currentBilling: GetProjectForProjectPageCurrentBilling;
  project: GetProjectForProjectPageProject;
  networkStatus: NetworkStatus;
  refetch(): void;
  updateProjectRecordingsStatus(isRecordingEnabled: boolean): Promise<void>;
}> = ({
  project,
  updateProjectRecordingsStatus,
  networkStatus,
  refetch,
  billingProducts,
  currentBilling
}) => (
  <StyledProject>
    <FeaturesTable
      project={project}
      updateProjectRecordingsStatus={updateProjectRecordingsStatus}
      currentUsage={currentBilling.currentUsage}
    />
    <ProjectStatusTable
      project={project}
      networkStatus={networkStatus}
      refetch={refetch}
      billingProducts={billingProducts}
      currentBilling={currentBilling}
    />
  </StyledProject>
);
