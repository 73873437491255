import React from "react";
import { LoadingBar } from "testly-web/components/LoadingBar";
import { LearningIcon } from "testly-web/icons/LearningIcon";
import { PollIcon } from "testly-web/icons/PollIcon";
import { SessionRecordingIcon } from "testly-web/icons/SessionRecordingIcon";
import { paths } from "testly-web/paths";
import {
  GetProjectForProjectPageProject,
  GetProjectForProjectPageCurrentUsage
} from "testly-web/queries";
import { toMMSS } from "testly-web/utils";
import { Card } from "../Card";
import * as Table from "../Table";
import { HeatmapIcon } from "testly-web/icons/HeatmapIcon";

interface LearningColumnProps {
  project: GetProjectForProjectPageProject;
  updateProjectRecordingsStatus(isRecordingEnabled: boolean): Promise<void>;
  currentUsage: GetProjectForProjectPageCurrentUsage;
}

interface LearningColumnState {
  isProjectRecordingsStatusUpdating: boolean;
}

export class LearningColumn extends React.Component<
  LearningColumnProps,
  LearningColumnState
> {
  constructor(props: LearningColumnProps) {
    super(props);

    this.state = {
      isProjectRecordingsStatusUpdating: false
    };
  }

  public render() {
    const {
      project: {
        id: projectId,
        sessionRecordings,
        isRecordingEnabled,
        feedbackPolls,
        heatmapPagesConnection
      },
      updateProjectRecordingsStatus
    } = this.props;

    return (
      <>
        <LoadingBar show={this.state.isProjectRecordingsStatusUpdating} />
        <Table.Cell order={1}>
          <Table.Header
            image={LearningIcon}
            caption="Learning"
            description="Helps you understand how your visitors are truly interacting with your site"
          />
        </Table.Cell>
        <Table.Cell order={2}>
          <Card
            titleCaption="Session Recordings"
            titleImg={SessionRecordingIcon}
            disabled={!this.props.currentUsage.sessionRecordingsEnabled}
            primaryLink={{
              disabled: !this.props.currentUsage.sessionRecordingsEnabled,
              name: "View Recorded Sessions",
              href: paths.sessionRecordingListPath({ projectId })
            }}
            statistics={{
              Recordings: sessionRecordings.totalRecords.toLocaleString(),
              "Avg session time": `${toMMSS(
                sessionRecordings.avgSessionTime / 1000
              )} mins`,
              "Avg session clicks": sessionRecordings.avgSessionClicks.toLocaleString()
            }}
            activateToggler={{
              checked: isRecordingEnabled,
              disabled: this.state.isProjectRecordingsStatusUpdating,
              onChange: async () => {
                this.setState({ isProjectRecordingsStatusUpdating: true });
                await updateProjectRecordingsStatus(!isRecordingEnabled);
                this.setState({ isProjectRecordingsStatusUpdating: false });
              }
            }}
          />
        </Table.Cell>
        <Table.Cell order={3} showHr={false}>
          <Card
            titleCaption="Heatmaps"
            titleImg={HeatmapIcon}
            disabled={!this.props.currentUsage.heatmapEnabled}
            primaryLink={{
              name: "View Heatmaps",
              href: paths.heatmapIndexPath({ projectId }),
              disabled: !this.props.currentUsage.heatmapEnabled
            }}
            statistics={{
              Pages: heatmapPagesConnection.totalRecords.toLocaleString()
            }}
          />
        </Table.Cell>
        <Table.Cell order={4}>
          <Card
            titleCaption="Polls"
            titleImg={PollIcon}
            disabled={!this.props.currentUsage.feedbackPollEnabled}
            primaryLink={{
              name: "Create Poll",
              href: `${paths.feedbackPollNewPath({
                projectId
              })}?cameFrom=dashboard`,
              disabled: !this.props.currentUsage.feedbackPollEnabled
            }}
            secondaryLink={{
              name: "View Polls",
              href: paths.feedbackPollIndexPath({ projectId }),
              disabled: !this.props.currentUsage.feedbackPollEnabled
            }}
            statistics={{
              "Polls created": feedbackPolls.totalRecords.toLocaleString(),
              "Active polls": feedbackPolls.activePollsCount.toLocaleString(),
              Responses: feedbackPolls.responsesCount.toLocaleString()
            }}
          />
        </Table.Cell>
      </>
    );
  }
}
