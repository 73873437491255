import React from "react";

export const HeatmapIcon = () => (
  // tslint:disable:max-line-length
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="29"
    height="30"
    viewBox="0 0 29 30"
  >
    <path
      fillRule="nonzero"
      d="M13.974 0a4.504 4.504 0 0 0-4.5 4.5c0 2.482 2.018 4.5 4.5 4.5s4.5-2.018 4.5-4.5-2.018-4.5-4.5-4.5zm-11 2a2.5 2.5 0 1 0 .002 5.001A2.5 2.5 0 0 0 2.974 2zm21 0a2.5 2.5 0 1 0 .002 5.001A2.5 2.5 0 0 0 23.974 2zm0 9a4.504 4.504 0 0 0-4.5 4.5c0 2.482 2.018 4.5 4.5 4.5s4.5-2.018 4.5-4.5-2.018-4.5-4.5-4.5zm-10 1a2.5 2.5 0 1 0 .002 5.001A2.5 2.5 0 0 0 13.974 12zm-11 0a2.5 2.5 0 1 0 .002 5.001A2.5 2.5 0 0 0 2.974 12zm11 9a4.504 4.504 0 0 0-4.5 4.5c0 2.482 2.018 4.5 4.5 4.5s4.5-2.018 4.5-4.5-2.018-4.5-4.5-4.5zm-11 2a2.5 2.5 0 1 0 .002 5.001A2.5 2.5 0 0 0 2.974 23zm21 0a2.5 2.5 0 1 0 .002 5.001A2.5 2.5 0 0 0 23.974 23z"
    />
  </svg>
);
