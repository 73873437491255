import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import styled from "styled-components/macro";
import {
  Caption,
  NavbarBackPath,
  NavbarHeader
} from "testly-web/components/Navbar/state";
import { BackIcon } from "testly-web/icons/BackIcon";

const StyledNavbar = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const StyledButton = styled.button`
  display: flex;
  margin-right: 30px;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: #fff;
`;

const Header = styled.div``;
const HeaderContent = styled.div``;
const HeaderCaption = styled.div`
  font-size: 15px;
`;

const NavBarWithoutRoute: React.SFC<
  {
    header: NavbarHeader;
    backPath: NavbarBackPath;
    caption: Caption;
  } & RouteComponentProps
> = ({ header, caption, backPath, history }) => {
  const backBtn = (() => {
    if (backPath) {
      return (
        <StyledButton
          onClick={() => {
            history.push(backPath);
          }}
        >
          <BackIcon />
        </StyledButton>
      );
    } else {
      return null;
    }
  })();

  return (
    <StyledNavbar>
      {backBtn}

      <Header>
        <HeaderContent>{header}</HeaderContent>
        <HeaderCaption>{caption}</HeaderCaption>
      </Header>
    </StyledNavbar>
  );
};

export const NavBar = withRouter(NavBarWithoutRoute);
