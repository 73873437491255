import { flatMap, isEqual } from "lodash";
import moment from "moment";
import {
  CssRuleMutated,
  DomMutated,
  MouseClicked,
  MouseMoved,
  PageVisited,
  ScrollPositionChanged,
  SessionEvent,
  SessionEventType,
  WindowSizeChanged
} from "session-player/eventTypes";
import { CssRuleMutationType } from "session-player/stylesheet/types";
import { SessionRecording, Throttles } from "session-player/types";
import { convertNodeToIdentifiable } from "session-player/virtualDom/convertNodeToIdentifiable";
import { converToSnabbdomVNode } from "session-player/virtualDom/converToSnabbdomVNode";
import {
  CreateNode,
  MutateAttributes,
  MutateCharacterData,
  MutationType,
  RemoveNode
} from "session-player/virtualDom/mutationTypes";
import { GetSessionRecordingForRecordingPageSessionRecording } from "testly-web/queries";

const moveFirstEventToStart = (
  events: SessionEvent[],
  eventType: SessionEventType
) => {
  const firstEvent = events.find(e => e.eventType === eventType);

  // Player requires some events to be present on the start

  if (firstEvent === undefined) {
    throw new Error(`At least one ${eventType} event should be present`);
  }

  return [
    { ...firstEvent, time: events[0].time, happenedAt: events[0].happenedAt },
    ...events.filter(e => !isEqual(e, firstEvent))
  ];
};

const movePageSnapshotted = (events: SessionEvent[]): SessionEvent[] => {
  // In real worls we have such sequence of events:
  // 1) URL_CHANGED
  // 2) PAGE_SNAPSHOTTED
  // But player requires such sequence
  // 1) PAGE_SNAPSHOTTED
  // 2) URL_CHANGED
  // So when user click on URL_CHANGED player will have page snapshot to render
  const urlChangedEvents = events.filter(
    e => e.eventType === SessionEventType.PageVisited
  ) as PageVisited[];

  return events.map(e => {
    if (
      e.eventType === SessionEventType.PageVisited &&
      e.time >= urlChangedEvents[0].time
    ) {
      const urlChanged = urlChangedEvents.shift();

      if (!urlChanged) {
        throw new Error(
          "Failed to find need url_changed event for page_snapshotted eevent"
        );
      }

      return {
        ...e,
        time: urlChanged.time,
        onTimeline: urlChanged.onTimeline,
        happenedAt: urlChanged.happenedAt
      };
    } else {
      return e;
    }
  });
};

export const mapSessionRecording = (
  serverSessionRecording: GetSessionRecordingForRecordingPageSessionRecording,
  throttles: Throttles
): SessionRecording => {
  const sortedEvents = serverSessionRecording.events
    .slice(0)
    .map(data => ({ ...data, timestamp: moment(data.happenedAt).valueOf() }))
    .sort((a, b) => a.timestamp - b.timestamp);

  const initialTimestamp = sortedEvents[0].timestamp;
  const eventsWithTime = sortedEvents.map(event => ({
    ...event,
    time: event.timestamp - initialTimestamp
  }));

  const newEvents: Array<SessionEvent | DomMutated[]> = eventsWithTime.map(
    event => {
      const data = JSON.parse(event.data);

      switch (event.type) {
        case "SCROLLED":
          const scroll: ScrollPositionChanged = {
            data: {
              left: data.left,
              top: data.top,
              id: data.id,
              animationDuration: throttles.scroll
            },
            time: event.time,
            happenedAt: event.timestamp,
            onTimeline: true,
            eventType: SessionEventType.ScrollPositionChanged
          };
          return scroll;
        case "PAGE_VISITED":
          const snapshot: PageVisited = {
            data: {
              domSnapshot: convertNodeToIdentifiable(
                converToSnabbdomVNode(data.dom_snapshot)
              ),
              docType: data.doc_type,
              url: data.url,
              title: data.title
            },
            time: event.time,
            happenedAt: event.timestamp,
            onTimeline: true,
            eventType: SessionEventType.PageVisited
          };
          return snapshot;
        case "MOUSE_MOVED":
          const mouseMove: MouseMoved = {
            data: {
              x: data.x,
              y: data.y,
              pageX: data.page_x,
              pageY: data.page_y,
              animationDuration: throttles.mouseMove
            },
            time: event.time,
            happenedAt: event.timestamp,
            onTimeline: true,
            eventType: SessionEventType.MouseMoved
          };
          return mouseMove;
        case "MOUSE_CLICKED":
          const mouseClick: MouseClicked = {
            data: {
              x: data.x,
              y: data.y,
              pageX: data.page_x,
              pageY: data.page_y,
              selector: data.selector,
              animationDuration: throttles.mouseMove
            },
            time: event.time,
            happenedAt: event.timestamp,
            onTimeline: true,
            eventType: SessionEventType.MouseClicked
          };
          return mouseClick;
        case "WINDOW_RESIZED":
          const windowsSizeChangedEvent: WindowSizeChanged = {
            data: {
              width: data.width,
              height: data.height
            },
            time: event.time,
            happenedAt: event.timestamp,
            onTimeline: true,
            eventType: SessionEventType.WindowSizeChanged
          };

          return windowsSizeChangedEvent;

        case "DOM_MUTATED":
          const mutations = (
            (data.added_or_moved &&
              data.added_or_moved.map(
                (mutation: any): CreateNode => ({
                  node: convertNodeToIdentifiable(
                    converToSnabbdomVNode(mutation.node)
                  ),
                  parentId: mutation.parent,
                  previousSiblingId: mutation.previous_sibling || undefined,
                  mutationType: MutationType.CreateOrMoveNode
                })
              )) ||
            []
          )
            .concat(
              (data.attributes &&
                data.attributes.map(
                  (attr: any): MutateAttributes => ({
                    attrs: {
                      [attr.name]: attr.value
                    },
                    nodeId: attr.id,
                    mutationType: MutationType.MutateAttrs
                  })
                )) ||
                []
            )
            .concat(
              (data.removed &&
                data.removed.reverse().map(
                  (nodeId: number): RemoveNode => ({
                    nodeId,
                    mutationType: MutationType.RemoveNode
                  })
                )) ||
                []
            )
            .concat(
              (data.character_data &&
                data.character_data.map(
                  ({
                    data: charData,
                    id
                  }: {
                    data: string;
                    id: number;
                  }): MutateCharacterData => ({
                    mutationType: MutationType.MutateCharacterData,
                    nodeId: id,
                    data: charData
                  })
                )) ||
                []
            );

          return mutations.map(
            (mutation: any): DomMutated => ({
              data: mutation,
              time: event.time,
              happenedAt: event.timestamp,
              onTimeline: true,
              eventType: SessionEventType.DomMutated
            })
          );
        case "CSS_RULE_DELETED":
          const cssRuleDeleted: CssRuleMutated = {
            data: {
              index: data.index,
              nodeId: data.node_id,
              type: CssRuleMutationType.CssRuleDeleteMutation
            },
            time: event.time,
            happenedAt: event.timestamp,
            onTimeline: true,
            eventType: SessionEventType.CssRuleMutated
          };

          return cssRuleDeleted;
        case "CSS_RULE_INSERTED":
          const cssRuleInserted: CssRuleMutated = {
            data: {
              rule: data.rule,
              index: data.index,
              nodeId: data.node_id,
              type: CssRuleMutationType.CssRuleInsertMutation
            },
            time: event.time,
            happenedAt: event.timestamp,
            onTimeline: true,
            eventType: SessionEventType.CssRuleMutated
          };

          return cssRuleInserted;
        default:
          throw new Error(`unknown event ${JSON.stringify(event)}`);
      }
    }
  );

  const newEventsFlatten: SessionEvent[] = flatMap(newEvents).filter(
    (e: SessionEvent | undefined) => e
  );

  const mappedEvents = movePageSnapshotted(
    moveFirstEventToStart(
      moveFirstEventToStart(
        moveFirstEventToStart(
          newEventsFlatten,
          SessionEventType.WindowSizeChanged
        ),
        SessionEventType.PageVisited
      ),
      SessionEventType.ScrollPositionChanged
    )
  );

  return {
    id: serverSessionRecording.id,
    events: mappedEvents,
    throttles
  };
};
