import moment from "moment";
import React from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";

import { graphColors } from "./helpers";

interface CustomXTickProps {
  x: number;
  y: number;
  payload: { value: number };
}

const CustomXTick: React.SFC<CustomXTickProps> = props => {
  const labelAlignment: string = props.x === 80 ? "start" : "middle";

  return (
    <g>
      <text x={props.x} y={props.y} dy="16" textAnchor={labelAlignment}>
        {moment(props.payload!.value).format("D MMM")}
      </text>
    </g>
  );
};

export const SplitTestShowPageChart: React.SFC<{
  width: number;
  height: number;
  data: object[] | undefined;
  variations: any[];
}> = ({ width, height, data, variations }) => {
  return (
    <LineChart
      width={width}
      height={height}
      margin={{ top: 20, left: 20, right: 40, bottom: 20 }}
      data={data}
    >
      <XAxis dataKey="date" tickLine={false} interval={0} tick={CustomXTick} />
      <YAxis
        axisLine={false}
        tickLine={false}
        tickFormatter={val => `${val.toFixed(0)}%`}
      />
      <Tooltip
        labelStyle={{
          marginBottom: 10,
          color: "#666",
          fontWeight: 500,
          fontSize: 16
        }}
        isAnimationActive={false}
        itemStyle={{ fontSize: 14 }}
        labelFormatter={label => moment(label).format("MMMM Do YYYY")}
      />
      <CartesianGrid vertical={false} stroke="#ecedee" />

      {variations.map((el, i) => {
        return (
          <Line
            key={i}
            type="linear"
            isAnimationActive={false}
            dataKey={el.name}
            strokeDasharray={el.control ? "6 3" : null}
            stroke={graphColors[i]}
            strokeWidth="3px"
            dot={false}
          />
        );
      })}
    </LineChart>
  );
};
