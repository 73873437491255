import { fixSvgNamespaces } from "session-player/virtualDom/fixSvgNamespaces";
import { mutate } from "session-player/virtualDom/mutate";
import { DomMutation } from "session-player/virtualDom/mutationTypes";
import { NodeMap } from "session-player/virtualDom/NodeMap";
import { resetElm } from "session-player/virtualDom/resetElm";
import { IdentifiableVNode } from "session-player/virtualDom/types";
import { init as initSnabbdom } from "snabbdom";
import snabbdomAttributes from "snabbdom/modules/attributes";
import snabbdomDataset from "snabbdom/modules/dataset";
import { VNode } from "snabbdom/vnode";
import { cloneDeep } from "lodash";

export class DomMutator {
  public contentDocument: Document;
  private patchDom: (oldVnode: VNode | Element, vnode: VNode) => VNode;
  private currentDom?: IdentifiableVNode;
  private nodeMap?: NodeMap;

  constructor(contentDocument: Document) {
    this.contentDocument = contentDocument;
    this.patchDom = initSnabbdom([snabbdomAttributes, snabbdomDataset]);
  }

  public initDomSnapshot(snapshot: IdentifiableVNode, docType: string) {
    // cloneDeep is needed cause snabbdom can mutate nodes on `patchNode`
    this.currentDom = cloneDeep(snapshot);
    this.patchDom(
      this.resetHtml(this.contentDocument, docType),
      this.currentDom
    );
    this.nodeMap = new NodeMap(snapshot);
  }

  public mutateDom(mutations: DomMutation[]) {
    const { nodeMap, currentDom } = this;

    if (!nodeMap || !currentDom) {
      throw new Error(
        "NodeMap/CurrentDom should be initialized(did you call initDomSnapshot()?)"
      );
    }

    const newDom = resetElm(
      mutations.reduce(
        (vnode, mutation) => mutate(nodeMap, vnode, mutation),
        currentDom
      )
    );

    fixSvgNamespaces(newDom);

    this.patchDom(currentDom, newDom);
    this.currentDom = newDom;
  }

  private resetHtml(contentDocument: Document, docType: string): HTMLElement {
    // NOTE: XSS possible
    // BUG: sets doctype only once on init
    // Doctype is important, cause at some cases it affects on margins/paddings
    contentDocument.write(`${docType}<html><head></head><body></body></html>`); // is needed to set doctype
    contentDocument.close();

    while (contentDocument.firstChild) {
      contentDocument.removeChild(contentDocument.firstChild);
    }

    const el = contentDocument.createElement("html");
    contentDocument.appendChild(el);

    return el;
  }
}
