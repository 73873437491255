import styled, { css } from "styled-components/macro";

export const Panel = styled.div`
  margin: 40px;
  background-color: #ffffff;
  max-width: 580px;
`;

export const Footer = styled.div`
  width: 100%;
  height: 80px;
  padding: 20px 40px;
  background-color: #ffffff;
  box-sizing: border-box;
  border-top: solid 1px #ecedee;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Body = styled.div`
  padding: 40px;
`;

export const Hr = styled.hr`
  height: 1px;
  width: 100%;
  background-color: #ecedee;
  border: none;
  margin: 0;
  margin-top: 24px;
  margin-bottom: 18px;
`;

export const Section = styled.div<{ last?: boolean }>`
  ${({ last }) =>
    !last &&
    css`
      margin-bottom: 28px;
    `}
`;

export const SectionHeader = styled.div`
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 6px;
  color: #393839;
`;

export const SectionDescription = styled.div<{ last?: boolean }>`
  font-size: 12px;
  color: #a7a9ae;
  margin-bottom: 10px;
`;
