import React from "react";
import styled, { css, keyframes } from "styled-components/macro";

const ldsHourglass = keyframes`
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
`;

// eslint-disable-next-line no-unexpected-multiline
const LoadingIndicatorStyled = styled.div<{
  isLoading: boolean;
  isFinishing: boolean;
}>`
  display: inline-block;
  width: 20px;
  height: 20px;
  position: relative;

  ${({ isLoading }) =>
    !isLoading &&
    css`
      display: none;
      &:after {
        display: none;
      }
    `}

  ${({ isFinishing }) =>
    isFinishing &&
    css`
      &:after {
        opacity: 0;
      }
    `}

  &:after {
    content: " ";
    display: block;
    border-radius: 50%;
    width: 0;
    height: 0;
    box-sizing: border-box;
    border: 10px solid #00ac69;
    border-color: #00ac69 transparent #00ac69 transparent;
    animation: ${ldsHourglass} 1.2s infinite;
    transition: opacity 0.2s ease-in-out;
  }
`;

interface LoadingIndicatorState {
  isLoading: boolean;
  isFinishing: boolean;
}

type LoadingIndicatorProps = {
  isLoading: boolean;
} & React.HTMLAttributes<HTMLDivElement> &
  React.DOMAttributes<HTMLDivElement>;

export class LoadingIndicator extends React.Component<
  LoadingIndicatorProps,
  LoadingIndicatorState
> {
  public static getDerivedStateFromProps(
    props: Readonly<LoadingIndicatorProps>,
    state: LoadingIndicatorState
  ) {
    if (props.isLoading === true) {
      return { ...state, isFinishing: false, isLoading: props.isLoading };
    } else {
      return { ...state, isFinishing: true };
    }
  }
  constructor(props: LoadingIndicatorProps) {
    super(props);

    this.state = {
      isLoading: false,
      isFinishing: false
    };
  }

  public render() {
    return (
      <LoadingIndicatorStyled
        onAnimationIteration={this.handleAnimationIteration}
        {...this.props}
        isFinishing={this.state.isFinishing}
        isLoading={this.state.isLoading}
      />
    );
  }

  private handleAnimationIteration = (
    e: React.AnimationEvent<HTMLDivElement>
  ): void => {
    if (this.props.isLoading === false) {
      this.setState({ isLoading: false, isFinishing: false });
    }
  };
}
