import React from "react";
import { IdentifiableVNode } from "session-player/virtualDom/types";
import styled from "styled-components/macro";
import { DeviceType } from "testly-web/queries";
import { ClickHeatmap, HeatmapClickElement } from "./ClickHeatmap/ClickHeatmap";
import { LoadingCover } from "./ClickHeatmap/LoadingCover";
import { DeviceSelect } from "./DeviceSelect";

export interface Page {
  url: string;
  snapshot: Snapshot;
  viewsCount: {
    desktop: number;
    mobile: number;
    tablet: number;
  };
}

export interface Snapshot {
  deviceType: DeviceType;
  domNodes: IdentifiableVNode;
  docType: string;
  elements: HeatmapClickElement[];
}

const Toolbar = styled.div`
  display: flex;
  width: 100%;
  height: 100px;
  padding: 32px 40px;
  box-sizing: border-box;
  align-items: center;
`;

const HeatmapScale = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
`;

const HeatmapScaleCaption = styled.div`
  font-size: 14px;
  color: #393839;
`;

const HeatmapScaleGradient = styled.div`
  width: 200px;
  height: 10px;
  border-radius: 4px;
  background-image: linear-gradient(to right, #ff0000, #a4ff00 51%, #00b5ff);

  margin: 0 16px;
`;

const HeatmapContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 230px);
  transform: translateZ(0px);
`;

// tslint:disable-next-line:no-empty-interface
interface HeatmapShowPageProps {
  page?: Page;
  onDeviceSelect: (device: DeviceType) => void;
  deviceType: DeviceType;
}

interface HeatmapShowPageState {
  loading: boolean;
}

// Taken from mouseflow
const averageDeviceWidth: Record<DeviceType, number> = {
  [DeviceType.Mobile]: 391,
  [DeviceType.Tablet]: 1156,
  [DeviceType.Desktop]: 1680
};

export class HeatmapShowPage extends React.Component<
  HeatmapShowPageProps,
  HeatmapShowPageState
> {
  private heatmapContainerRef: React.RefObject<HTMLDivElement>;

  constructor(props: HeatmapShowPageProps) {
    super(props);
    this.heatmapContainerRef = React.createRef<HTMLDivElement>();
    this.state = {
      loading: true
    };
  }

  public render() {
    const { page, onDeviceSelect, deviceType } = this.props;
    const snapshot = page && page.snapshot;

    // https://github.com/apollographql/react-apollo/issues/2202
    // that's why snapshot.deviceType === deviceType
    return (
      <>
        <Toolbar>
          <DeviceSelect
            selectedDevice={deviceType}
            visits={page && page.viewsCount}
            onDeviceSelect={onDeviceSelect}
          />
          <HeatmapScale>
            <HeatmapScaleCaption>Hot</HeatmapScaleCaption>
            <HeatmapScaleGradient />
            <HeatmapScaleCaption>Cold</HeatmapScaleCaption>
          </HeatmapScale>
        </Toolbar>
        <HeatmapContainer ref={this.heatmapContainerRef} key={deviceType}>
          {snapshot && snapshot.deviceType === deviceType ? (
            <ClickHeatmap
              snapshot={snapshot}
              heatmapContainerRef={this.heatmapContainerRef}
              viewportWidth={averageDeviceWidth[deviceType]}
              onLoaded={() => this.setState({ loading: false })}
            />
          ) : (
            undefined
          )}
          {this.state.loading && <LoadingCover />}
        </HeatmapContainer>
      </>
    );
  }
}
