import React from "react";

export const InfoIcon: React.SFC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="nonzero">
      <path
        fill="#2196F3"
        d="M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12z"
      />
      <path
        fill="#FFF"
        d="M10.8 10.8h2.4v6.6h-2.4zM13.5 7.5c0 .827-.673 1.5-1.5 1.5s-1.5-.673-1.5-1.5S11.173 6 12 6s1.5.673 1.5 1.5z"
      />
    </g>
  </svg>
);
