import { FormikActions } from "formik";
import React from "react";
import { MutationFn } from "react-apollo";
import { RouteComponentProps } from "react-router-dom";
import { toast } from "react-toastify";
import { SetCurrentProjectId } from "testly-web/components/CurrentProjectId/SetCurrentProjectId";
import {
  FeedbackFormValues,
  mapFormValuesToMutationParams,
  mapQueryDataToFormValues
} from "testly-web/components/feedback/schema";
import { loadingRender } from "testly-web/components/loadingRender";
import { SetNavbar } from "testly-web/components/Navbar/SetNavbar";
import { onFormSubmitFail } from "testly-web/components/onFail";
import { paths } from "testly-web/paths";
import {
  GetDataForFeedbackPollEditPageComponent,
  UpdateFeedbackPollAtFeedbackPollEditPageComponent,
  UpdateFeedbackPollAtFeedbackPollEditPageMutation,
  UpdateFeedbackPollAtFeedbackPollEditPageVariables
} from "testly-web/queries";
import { FeedbackPollEditPage } from "./FeedbackPollEditPage";

export const FeedbackPollEditPageContainer: React.SFC<
  RouteComponentProps<{ projectId: string; feedbackPollId: string }>
> = ({
  match: {
    params: { projectId, feedbackPollId }
  },
  history: { push }
}) => {
  const onSubmit = async (
    mutate: MutationFn<
      UpdateFeedbackPollAtFeedbackPollEditPageMutation,
      UpdateFeedbackPollAtFeedbackPollEditPageVariables
    >,
    values: FeedbackFormValues,
    actions: FormikActions<FeedbackFormValues>
  ) => {
    const response = await mutate({
      variables: {
        feedbackPollId,
        feedbackPollParams: mapFormValuesToMutationParams(values)
      }
    });

    actions.setSubmitting(false);

    if (response && response.data && response.data.updateFeedbackPoll) {
      const { successful, result } = response.data.updateFeedbackPoll;

      if (successful && result) {
        toast.success("Your feedback poll has been updated.");
        actions.resetForm();
        push(paths.feedbackPollIndexPath({ projectId }));
      } else {
        onFormSubmitFail(values, response.data);
      }
    }
  };

  return (
    <>
      <SetCurrentProjectId id={projectId} />
      <SetNavbar
        header="Edit Feedback Poll"
        backPath={paths.feedbackPollIndexPath({ projectId })}
      />
      <GetDataForFeedbackPollEditPageComponent
        variables={{ projectId, feedbackPollId }}
        fetchPolicy="network-only"
      >
        {loadingRender("currentUser.project.feedbackPoll", ({ data }) => (
          <UpdateFeedbackPollAtFeedbackPollEditPageComponent>
            {mutate => (
              <FeedbackPollEditPage
                initialValues={mapQueryDataToFormValues(
                  data!.currentUser!.project!.feedbackPoll!
                )}
                onSubmit={(values, actions) => {
                  onSubmit(mutate, values, actions);
                }}
              />
            )}
          </UpdateFeedbackPollAtFeedbackPollEditPageComponent>
        ))}
      </GetDataForFeedbackPollEditPageComponent>
    </>
  );
};
