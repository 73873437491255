import React from "react";
import styled, { css } from "styled-components/macro";

import { BodyCell, Row, SecondTextLine } from "../../components/Table/Table";
import { ConversionImprovement } from "./ConversionImprovement";
import { LineSample } from "./LineSample";

import { VariationWinnerIcon } from "testly-web/icons/VariationWinnerIcon";
import { MappedVariation } from "./helpers";

const BodyCellHighlighted = styled(BodyCell)`
  width: 100px;

  background-color: rgba(0, 0, 0, 0.025);
  vertical-align: middle;

  text-align: center;
`;

// eslint-disable-next-line no-unexpected-multiline
const StyledBodyCell = styled(BodyCell)<{
  isWinner: boolean;
}>`
  vertical-align: middle;
  text-align: right;

  ${({ isWinner }) =>
    isWinner &&
    css`
      color: #00ac69;
      font-weight: bold;
    `};
`;

const VariationWinnerBadge = styled(VariationWinnerIcon)`
  position: absolute;
  top: -8px;
  left: -7px;
`;

const VariationName = styled(BodyCell)`
  width: 500px !important;
  position: relative;
`;

interface SplitTestVariationProps {
  variation: MappedVariation;
  color: string;
}

export const SplitTestVariation: React.SFC<SplitTestVariationProps> = ({
  variation,
  color
}) => {
  const {
    conversion,
    control,
    name,
    url,
    conversion: { isWinner, improvementRate }
  } = variation;

  return (
    <Row>
      <VariationName canContainLongText={true}>
        <LineSample color={color} dashed={control} />
        {name}
        {isWinner && <VariationWinnerBadge />}
        <br />
        <SecondTextLine>{url}</SecondTextLine>
      </VariationName>
      <BodyCellHighlighted>
        {improvementRate !== null ? (
          <ConversionImprovement value={improvementRate} />
        ) : control ? (
          "Baseline"
        ) : (
          "-"
        )}
      </BodyCellHighlighted>
      <StyledBodyCell isWinner={isWinner}>
        {conversion.conversionRate.toFixed(2)}%
      </StyledBodyCell>
      <StyledBodyCell isWinner={isWinner}>
        {conversion.conversionsCount}
      </StyledBodyCell>
      <StyledBodyCell isWinner={isWinner}>
        {conversion.visitsCount}
      </StyledBodyCell>
      <StyledBodyCell isWinner={isWinner}>${conversion.revenue}</StyledBodyCell>
    </Row>
  );
};
