import { SafeStylesheet } from "./SafeStylesheet";
import { CssRuleMutation, CssRuleMutationType } from "./types";

export class StylesheetMutator {
  private contentWindow: Window;

  constructor(contentWindow: Window) {
    this.contentWindow = contentWindow;
  }

  public applyToDom = (mutations: CssRuleMutation[]) => {
    mutations.forEach(mutation => {
      const el:
        | Element
        | HTMLStyleElement
        | null = this.contentWindow.document.querySelector(
        this.selector(mutation.nodeId)
      );

      if (el && "sheet" in el) {
        const safeStylesheet = new SafeStylesheet(el.sheet as CSSStyleSheet);

        if (mutation.type === CssRuleMutationType.CssRuleInsertMutation) {
          safeStylesheet.insertRule(mutation.rule, mutation.index);
        } else {
          safeStylesheet.deleteRule(mutation.index);
        }
      }
    });
  };

  private selector(nodeId: number) {
    return `[data-testly-player-node-id='${nodeId}']`;
  }
}
