import { ApolloClient } from "apollo-boost";
import {
  defaultDataIdFromObject,
  InMemoryCache,
  IntrospectionFragmentMatcher
} from "apollo-cache-inmemory";
import {
  defaultState as defaultAccountSettingsTabState,
  State as AccountSettingsTabState
} from "testly-web/components/AccoutSettings/state";
import {
  defaultState as defaultNavbarState,
  State as NavbarState
} from "testly-web/components/Navbar/state";
import { createLink } from "../apollo-absinthe-upload-link/index";
import {
  defaultState as defaultCurrentProjectIdState,
  State as CurrentProjectIdState
} from "./components/CurrentProjectId/state";
import introspectionQueryResultData from "./fragment-types.json";

export type ClientState = {
  navbar: NavbarState;
  accountSettingsTab: AccountSettingsTabState;
} & CurrentProjectIdState;

const fragmentMatcher = new IntrospectionFragmentMatcher({
  // tslint:disable-next-line
  introspectionQueryResultData
});

interface IdObject {
  __typename?: string;
  id?: string;
  node?: {
    id?: string;
  };
}

const inMemoryCache = new InMemoryCache({
  fragmentMatcher,
  dataIdFromObject: (object: IdObject) => {
    if (object.__typename === "SplitTestVariationConversion") {
      // tslint:disable-next-line
      return `SplitTestVariationConversion:${Math.random().toString(36)}`;
    }

    if (object.__typename && object.id) {
      return `${object.__typename}:${object.id}`;
    } else if (object.__typename && object.node && object.node.id) {
      return `${object.__typename}:${object.node.id}`;
    } else {
      return defaultDataIdFromObject(object);
    }
  }
});

const defaultState: ClientState = {
  navbar: defaultNavbarState,
  accountSettingsTab: defaultAccountSettingsTabState,
  ...defaultCurrentProjectIdState
};

inMemoryCache.writeData({
  data: defaultState
});

const API_URL = process.env.REACT_APP_API_URL as string;

const client = new ApolloClient({
  link: createLink({
    uri: API_URL,
    mode: "cors",
    credentials: "include"
  }),
  cache: inMemoryCache,
  // https://github.com/apollographql/apollo-client/pull/4499
  resolvers: {}
});

export { client };
