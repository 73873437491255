import styled from "styled-components/macro";

export const FilterStyled = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  position: relative;

  padding: 32px;

  background-color: #efefef;

  z-index: 500;
`;
