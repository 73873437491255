import { chunk } from "lodash";
import React from "react";
import styled, { css } from "styled-components/macro";
import { OptimisticFilter } from "testly-web/components/filters/AdvancedFilter/OptimisticFilter";
import { Paginator } from "testly-web/components/Paginator";
import { SplitTestFilter } from "testly-web/queries";
import { Filter } from "./Filter/Filter";
import { PageCategory } from "./SplitTestAdvancedFilter/pageCategoryFilter";
import { PageType } from "./SplitTestAdvancedFilter/pageTypeFilter";
import { SplitTestType } from "./SplitTestCard/SplitTestCard";
import { SplitTestCardContainer } from "./SplitTestCard/SplitTestCardContainer";

interface SplitTestIndexPageProps {
  splitTests: SplitTestType[];
  projectId: string;
  page: number;
  pageCount: number;
  pageCategories: PageCategory[];
  pageTypes: PageType[];
  filter: SplitTestFilter;
  onPageChange(data: { selected: number }): void;
  onFilterChange(filter: SplitTestFilter): void;
}

interface SplitTestIndexPageState {
  expandedSplitTestId?: string;
}

const SplitTestIndexPageStyled = styled.div`
  padding: 0px 20px 0px 20px;
  margin-bottom: 150px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

// Zindex should be reversed to keep right overlaping on expanded card
// eslint-disable-next-line no-unexpected-multiline
const SplitTestCardStyled = styled(SplitTestCardContainer)<{
  last: boolean;
  zIndex: number;
}>`
  position: relative;
  margin-top: 20px;
  height: 300px;
  width: 49%;
  max-height: 300px;
  min-width: 430px;
  z-index: ${({ zIndex }) => zIndex};

  ${({ last }) =>
    last &&
    css`
      margin-left: 2%;
    `}
`;

const NoSplitTests = styled.div`
  font-weight: 400;
  font-size: 18px;
  margin-top: 30px;
  margin-left: 12px;
`;

const StyledPaginator = styled(Paginator)`
  display: flex;
  margin-top: 20px;
`;

export class SplitTestIndexPage extends React.Component<
  SplitTestIndexPageProps,
  SplitTestIndexPageState
> {
  constructor(props: SplitTestIndexPageProps) {
    super(props);

    this.state = {};
  }

  public render() {
    const {
      splitTests,
      projectId,
      pageCount,
      onPageChange,
      page,
      pageCategories,
      pageTypes,
      filter,
      onFilterChange
    } = this.props;
    const { expandedSplitTestId } = this.state;

    return (
      <>
        <OptimisticFilter onFilterChange={onFilterChange} filter={filter}>
          {(optimisticFilter, optimisticOnFilterChange, onFilterClose) => (
            <Filter
              onFilterChange={optimisticOnFilterChange}
              onFilterClose={onFilterClose}
              filter={optimisticFilter}
              pageCategories={pageCategories}
              pageTypes={pageTypes}
            />
          )}
        </OptimisticFilter>
        <SplitTestIndexPageStyled>
          {splitTests.length === 0 ? (
            <NoSplitTests>No split tests found</NoSplitTests>
          ) : (
            chunk(splitTests.map((splitTest, i) => ({ splitTest, i })), 2).map(
              data => (
                <Row key={data.map(({ splitTest }) => splitTest.id).join(",")}>
                  {data.map(({ splitTest, i }) => (
                    <SplitTestCardStyled
                      projectId={projectId}
                      last={i === 1}
                      zIndex={splitTests.length - i}
                      splitTest={splitTest}
                      key={splitTest.id}
                      expanded={expandedSplitTestId === splitTest.id}
                      onExpandClick={this.handleExpandClick(
                        expandedSplitTestId,
                        splitTest.id
                      )}
                    />
                  ))}
                </Row>
              )
            )
          )}

          {pageCount > 1 && (
            <StyledPaginator
              pageCount={pageCount}
              onPageChange={onPageChange}
              page={page}
            />
          )}
        </SplitTestIndexPageStyled>
      </>
    );
  }

  private handleExpandClick = (
    currentExpandedId: string | undefined,
    splitTestId: string
  ) => {
    return () => {
      this.setState({
        expandedSplitTestId:
          currentExpandedId === splitTestId ? undefined : splitTestId
      });
    };
  };
}
