import { camelCase, fromPairs, toPairs } from "lodash";
import { compile } from "path-to-regexp";
import { SplitTestFormStep } from "./components/split-test-form/schemas";
import { HeaderTab } from "./pages/TestIdeaIndexPage/Header";

const PROJECT_REDIRECT_PATH = "/";

export const PATHS = {
  SESSION_RECORDING_LIST_PATH: "/projects/:projectId/session-recordings",
  SESSION_RECORDING_PATH:
    "/projects/:projectId/session-recordings/:sessionRecordingId",

  PROJECT_SETUP_PATH: "/projects/:projectId/setup",
  PROJECT_EDIT_PATH: "/projects/:projectId/edit",
  PROJECT_PATH: "/projects/:projectId",
  PROJECT_REDIRECT_PATH,

  GOAL_NEW_PATH: "/projects/:projectId/goals/new",
  GOAL_EDIT_PATH: "/projects/:projectId/goals/edit/:goalId",
  GOAL_LIST_PATH: "/projects/:projectId/goals",

  SPLIT_TEST_INDEX_PATH: "/projects/:projectId/split-tests",
  SPLIT_TEST_NEW_PATH: "/projects/:projectId/split-tests/new",
  SPLIT_TEST_EDIT_PATH:
    "/projects/:projectId/split-tests/:splitTestId/edit/:step",
  SPLIT_TEST_CREATE_PATH: "/projects/:projectId/split-tests/create",
  SPLIT_TEST_SHOW_PATH: "/projects/:projectId/split-tests/:splitTestId",
  SPLIT_TEST_SESSION_RECORDING_INDEX_PAGE:
    "/projects/:projectId/split-tests/:splitTestId/session-recordings",
  HEATMAP_SHOW_PATH: "/projects/:projectId/heatmaps/:heatmapPageId",
  HEATMAP_INDEX_PATH: "/projects/:projectId/heatmaps",

  FEEDBACK_POLL_INDEX_PATH: "/projects/:projectId/feedback-polls",
  FEEDBACK_POLL_NEW_PATH: "/projects/:projectId/feedback-polls/new",
  FEEDBACK_POLL_EDIT_PATH:
    "/projects/:projectId/feedback-polls/edit/:feedbackPollId",
  FEEDBACK_POLL_RESPONSE_INDEX_PATH:
    "/projects/:projectId/feedback-polls/:feedbackPollId/responses",

  TEST_IDEA_INDEX_PATH: "/projects/:projectId/test-ideas/:tab",

  DEFAULT_PATH: PROJECT_REDIRECT_PATH
};

// It makes such object:
// { campaignsPath: compile('/campaigns/:id') }
// from { CAMPAIGNS_PATH: '/campaigns/:id' }
export const paths = (fromPairs(
  toPairs(PATHS).map(([key, value]) => [camelCase(key), compile(value)])
) as unknown) as {
  projectRedirectPath(): string;
  projectPath(params: { projectId: string }): string;
  projectSetupPath(params: { projectId: string }): string;
  projectEditPath(params: { projectId: string }): string;
  sessionRecordingPath(params: {
    projectId: string;
    sessionRecordingId: string;
  }): string;
  sessionRecordingListPath(params: { projectId: string }): string;
  goalListPath(params: { projectId: string }): string;
  goalNewPath(params: { projectId: string }): string;
  goalEditPath(params: { projectId: string; goalId: string }): string;
  splitTestIndexPath(params: { projectId: string }): string;
  splitTestNewPath(params: { projectId: string }): string;
  splitTestEditPath(params: {
    projectId: string;
    splitTestId: string;
    step: SplitTestFormStep;
  }): string;
  splitTestCreatePath(params: { projectId: string }): string;
  splitTestShowPath(params: { projectId: string; splitTestId: string }): string;
  splitTestSessionRecordingIndexPage(params: {
    projectId: string;
    splitTestId: string;
  }): string;
  heatmapIndexPath(params: { projectId: string }): string;
  heatmapShowPath(params: { projectId: string; heatmapPageId: string }): string;
  feedbackPollNewPath(params: { projectId: string }): string;
  feedbackPollIndexPath(params: { projectId: string }): string;
  feedbackPollEditPath(params: {
    projectId: string;
    feedbackPollId: string;
  }): string;
  feedbackPollResponseIndexPath(params: {
    projectId: string;
    feedbackPollId: string;
  }): string;
  testIdeaIndexPath(params: { projectId: string; tab: HeaderTab }): string;
};
