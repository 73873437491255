import React from "react";
import styled from "styled-components/macro";

const LabelStyled = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  min-width: 60px;
  height: 30px;
`;

const SliderRound = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e7e8e8;
  transition: 0.4s;

  border-radius: 20px;

  &:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 6px;
    bottom: 5px;
    transition: 0.4s;
    border-radius: 50%;

    transform: translateX(0px);

    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.28);
    background-color: #ffffff;
  }
`;

const InputStyled = styled.input`
  display: none;

  &:checked + ${SliderRound}:before {
    transform: translateX(26px);
  }

  &:checked + ${SliderRound} {
    background-color: #00ac69;
  }

  &:focus + ${SliderRound} {
    box-shadow: 0 0 1px #05925a;
  }
`;

export const Toggler: React.SFC<
  React.InputHTMLAttributes<HTMLInputElement>
> = ({ className, ...props }) => (
  <LabelStyled className={className}>
    <InputStyled type="checkbox" {...props} />
    <SliderRound />
  </LabelStyled>
);

const TogglerWithLabelStyled = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;

  font-size: 14px;
  color: #393839;

  ${LabelStyled} {
    margin-right: 8px;
  }
`;

export const TogglerWithLabel: React.SFC<
  React.InputHTMLAttributes<HTMLInputElement> & { label: string }
> = ({ className, label, ...props }) => (
  <TogglerWithLabelStyled className={className}>
    <Toggler {...props} />
    {label}
  </TogglerWithLabelStyled>
);
