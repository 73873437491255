import React from "react";
import { PlayingStateName } from "session-player/playback/PlaybackManager";
import { ProgressBarUI } from "testly-web/components/Player/ProgressBar/ProgressBarUI";
import { ViewableEvent } from "./types";

interface ProgressBarProps {
  timelineDuration: number;
  currentPlayTime: number;
  vieawableEvents: ViewableEvent[];
  playingStateName: PlayingStateName | undefined;
  play(): void;
  seek(to: number): void;
  seekPercent(to: number): void;
}

interface ProgressBarState {
  isDragging: boolean;
  wasPlaying: boolean;
}

export class ProgressBar extends React.Component<
  ProgressBarProps,
  ProgressBarState
> {
  public progressBarRef: React.RefObject<HTMLDivElement>;

  constructor(props: ProgressBarProps) {
    super(props);

    this.state = {
      isDragging: false,
      wasPlaying: false
    };

    this.progressBarRef = React.createRef<HTMLDivElement>();
  }

  public componentDidMount() {
    document.addEventListener("touchmove", this.handleTouchMove);
    document.addEventListener("touchend", this.handleTouchEnd);
    document.addEventListener("mouseup", this.handleMouseUp);
    document.addEventListener("mousemove", this.handleMouseMove);
  }

  public componentWillUnmount() {
    document.removeEventListener("touchmove", this.handleTouchMove);
    document.removeEventListener("touchend", this.handleTouchEnd);
    document.removeEventListener("mouseup", this.handleMouseUp);
    document.removeEventListener("mousemove", this.handleMouseMove);
  }

  public render() {
    const { vieawableEvents, timelineDuration, currentPlayTime } = this.props;

    return (
      <ProgressBarUI
        vieawableEvents={vieawableEvents}
        sessionDuration={timelineDuration}
        progressBarRef={this.progressBarRef}
        progressPercent={
          timelineDuration !== undefined
            ? (currentPlayTime / timelineDuration) * 100
            : 0
        }
        onProgressBarTouchStart={this.handleTouchStart}
        onProgressBarMouseDown={this.handleMouseDown}
        onSessionEventClick={(e, time) => {
          e.preventDefault();
          this.props.seek(time);
        }}
        currentPlayTime={currentPlayTime}
      />
    );
  }

  public handleDragStart(pageX: number) {
    const playingState = this.props.playingStateName;

    this.setState({
      isDragging: true,
      wasPlaying:
        playingState !== undefined && playingState === PlayingStateName.Playing
    });

    this.seek(pageX);
  }

  public handleDragEnd() {
    if (this.state.wasPlaying) {
      this.props.play();
    }
    this.setState({ isDragging: false });
  }

  private seek(pageX: number) {
    if (this.progressBarRef.current) {
      const el = this.progressBarRef.current;
      const elWidth = el.clientWidth;

      const difference =
        pageX - (el.getBoundingClientRect().left + window.scrollX);
      const posX = (() => {
        if (difference < 0) {
          return 0;
        } else if (difference > elWidth) {
          return elWidth;
        } else {
          return difference;
        }
      })();

      this.props.seekPercent((posX / elWidth) * 100);
    }
  }

  private handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    this.handleDragStart(e.changedTouches[0].pageX);
  };

  private handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();

    this.handleDragStart(e.pageX);
  };

  private handleTouchMove = (e: TouchEvent) => {
    if (this.state.isDragging) {
      this.seek(e.changedTouches[0].pageX);
    }
  };

  private handleMouseMove = (e: MouseEvent) => {
    if (this.state.isDragging) {
      e.preventDefault();
      this.seek(e.pageX);
    }
  };

  private handleTouchEnd = (e: TouchEvent) => {
    if (this.state.isDragging) {
      e.preventDefault();
      this.handleDragEnd();
    }
  };

  private handleMouseUp = (e: MouseEvent) => {
    if (this.state.isDragging) {
      e.preventDefault();
      this.handleDragEnd();
    }
  };
}
