import { FormikProps, getIn } from "formik";
import React from "react";
import styled from "styled-components/macro";
import {
  InputWrapper,
  NumberField,
  TextField
} from "testly-web/components/Form/Field";
import { Toggler } from "testly-web/components/Form/Toggler";
import { Hr } from "testly-web/components/Panel/Panel";
import { NumberFormatStyled } from "../Form/Input";
import { PathBuilder } from "./PathBuilder";
import { PathGoalForm } from "./pathGoal";
import { UrlInput } from "./UrlInput";

export interface GoalCreatePageProps {
  formProps: FormikProps<PathGoalForm>;
}

const TogglerStyled = styled(Toggler)`
  margin-right: 10px;
`;

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;

  font-size: 12px;
  font-weight: 500;
  color: #393839;
`;

const Optional = styled.span`
  font-weight: normal;
  color: #a7a9ae;
`;

const ClickableCaption = styled.span`
  cursor: pointer;
`;

const NumberFieldStyled = styled(NumberField)`
  ${InputWrapper} {
    justify-content: flex-start;
  }

  ${NumberFormatStyled} {
    width: 110px;
  }
`;

export const GoalFields: React.SFC<{
  formProps: FormikProps<object>;
  fieldPrefix?: string;
}> = ({ formProps, ...props }) => {
  const { setFieldValue } = formProps;

  const fieldPrefix = props.fieldPrefix || "";
  const pathBuilderToggled: boolean = getIn(
    formProps.values,
    `${fieldPrefix}pathBuilderToggled`
  ) as boolean;

  const handlePathBuilderToggle = () => {
    setFieldValue(`${fieldPrefix}pathBuilderToggled`, !pathBuilderToggled);
  };

  return (
    <>
      <TextField label="Goal Name" name={`${fieldPrefix}name`} {...formProps} />

      <NumberFieldStyled
        label="Goal Value"
        name={`${fieldPrefix}value`}
        prefix="$"
        {...formProps}
      />

      <UrlInput
        fieldPrefix={`${fieldPrefix}finalGoalUrl.`}
        label="Goal URL"
        {...formProps}
      />

      <Hr />

      <ToggleWrapper>
        <TogglerStyled
          name={`${fieldPrefix}pathBuilderToggled`}
          checked={pathBuilderToggled}
          onChange={handlePathBuilderToggle}
        />
        <ClickableCaption onClick={handlePathBuilderToggle}>
          Path To Goal&nbsp;
          <Optional>(optional)</Optional>
        </ClickableCaption>
      </ToggleWrapper>

      {pathBuilderToggled && (
        <PathBuilder formikProps={formProps} fieldPrefix={fieldPrefix} />
      )}
    </>
  );
};
