import { VNode } from "snabbdom/vnode";
import { IdentifiableVNode } from "./types";

export const convertNodeToIdentifiable = (
  node: VNode | string
): IdentifiableVNode => {
  if (typeof node === "string") {
    throw new Error("Node as string is not allowed");
  }

  if (node.data === undefined || node.data.nodeId === undefined) {
    throw new Error("Node should have nodeId present in props");
  }

  const nodeId = node.data.nodeId as number;

  return {
    ...node,
    children: node.children && node.children.map(convertNodeToIdentifiable),
    data: {
      nodeId,
      ...(node.sel === "!" || node.sel === undefined
        ? {}
        : {
            dataset: {
              testlyPlayerNodeId: nodeId.toString()
            }
          }),
      ...node.data
    }
  };
};
