import { capitalize } from "lodash";
import React from "react";
import { ReferrerSource, SessionRecordingFilter } from "testly-web/queries";
import { CheckboxInFilter } from "../../filters/AdvancedFilter/CheckboxInFilter";
import { handleInFilterChange } from "../../filters/AdvancedFilter/inFilter";
import { OnFilterChange } from "../../filters/AdvancedFilter/types";

const sourceOptions = Object.values(ReferrerSource).map((value: string) => ({
  name: capitalize(value),
  value
}));

const filterName = "referrerSourceIn";

export const referrerSourceFilter = (
  filter: SessionRecordingFilter,
  onFilterChange: OnFilterChange<SessionRecordingFilter>
) => {
  const referrerSourceIn = filter[filterName] || [];
  const onChange = handleInFilterChange(filter, filterName, onFilterChange);

  return (
    <CheckboxInFilter
      key={filterName}
      name="Source"
      options={sourceOptions.map(({ name, value }) => ({
        name,
        value,
        selected: referrerSourceIn.includes(value as ReferrerSource)
      }))}
      onChange={onChange}
    />
  );
};
