import React from "react";
import { InstallationCheckStep } from "testly-web/components/split-test-form/InstallationCheckStep";
import {
  DoneStepsType,
  ProgressBar
} from "testly-web/components/split-test-form/ProgressBar";
import { SplitTestFormStep } from "testly-web/components/split-test-form/schemas";
import { paths } from "testly-web/paths";
import {
  GetDataForSplitTestEditPagePageCategories,
  GetDataForSplitTestEditPagePageTypes,
  GetDataForSplitTestEditPageSplitTest,
  GetDataForSplitTestEditPageTestIdeas
} from "testly-web/queries";
import { DetailsFormContainer } from "./DetailsFormContainer";
import { GoalsFormContainer } from "./GoalsFormContainer";
import { SettingsFormContainer } from "./SettingsFormContainer";
import { VariationsFormContainer } from "./VariationsFormContainer";

export const SplitTestEditPage: React.SFC<{
  splitTest: GetDataForSplitTestEditPageSplitTest;
  projectId: string;
  currentStep: SplitTestFormStep;
  doneSteps: DoneStepsType;
  pageCategories: GetDataForSplitTestEditPagePageCategories[];
  pageTypes: GetDataForSplitTestEditPagePageTypes[];
  testIdeas: GetDataForSplitTestEditPageTestIdeas[];
  replacePath: (path: string) => void;
}> = ({
  splitTest,
  projectId,
  currentStep,
  doneSteps,
  pageCategories,
  pageTypes,
  testIdeas,
  replacePath
}) => {
  const pathToStep = (step: SplitTestFormStep) =>
    paths.splitTestEditPath({
      projectId,
      splitTestId: splitTest.id,
      step
    });

  return (
    <>
      <ProgressBar
        doneSteps={doneSteps}
        currentStep={currentStep}
        onStepClick={step => replacePath(pathToStep(step))}
      />
      {currentStep === SplitTestFormStep.Details && (
        <DetailsFormContainer
          splitTest={splitTest}
          projectId={projectId}
          pageCategories={pageCategories}
          pageTypes={pageTypes}
          pathToStep={pathToStep}
          testIdeas={testIdeas}
        />
      )}
      {currentStep === SplitTestFormStep.Variations && (
        <VariationsFormContainer
          splitTest={splitTest}
          projectId={projectId}
          onBackButtonClick={() =>
            replacePath(pathToStep(SplitTestFormStep.Details))
          }
        />
      )}
      {currentStep === SplitTestFormStep.Goals && (
        <GoalsFormContainer
          splitTest={splitTest}
          projectId={projectId}
          onBackButtonClick={() =>
            replacePath(pathToStep(SplitTestFormStep.Variations))
          }
        />
      )}
      {currentStep === SplitTestFormStep.CodeInstallationCheck && (
        <InstallationCheckStep
          pagesToCheck={splitTest.variations}
          projectId={projectId}
          onBackButtonClick={() => {
            replacePath(pathToStep(SplitTestFormStep.Goals));
          }}
          onNextButtonClick={() => {
            replacePath(pathToStep(SplitTestFormStep.Settings));
          }}
        />
      )}
      {currentStep === SplitTestFormStep.Settings && (
        <SettingsFormContainer
          splitTest={splitTest}
          projectId={projectId}
          onBackButtonClick={() =>
            replacePath(pathToStep(SplitTestFormStep.CodeInstallationCheck))
          }
        />
      )}
    </>
  );
};
