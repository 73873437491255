import React from "react";

export const PlayIcon: React.SFC<React.SVGProps<SVGSVGElement>> = props => (
  // tslint:disable:max-line-length

  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="46"
    height="54"
    viewBox="0 0 46 54"
    {...props}
  >
    <path
      fill="#FFF"
      d="M43.922 23.076L5.455.48c-1.163-.696-2.552-.63-3.67.174C.665 1.443 0 2.862 0 4.388v45.234c0 1.513.666 2.932 1.784 3.722.61.442 1.288.656 1.977.656a3.36 3.36 0 0 0 1.694-.469l38.478-22.624C45.198 30.171 46 28.658 46 26.998c0-1.66-.813-3.172-2.078-3.922z"
    />
  </svg>
);
