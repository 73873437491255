import { ApolloError } from "apollo-client";
import React from "react";
import { MutationFn } from "react-apollo";
import {
  ActivateSplitTestAtSplitTestIndexPageComponent,
  ActivateSplitTestAtSplitTestIndexPageMutation,
  ActivateSplitTestAtSplitTestIndexPageVariables,
  FinishSplitTestAtSplitTestIndexPageComponent,
  FinishSplitTestAtSplitTestIndexPageMutation,
  FinishSplitTestAtSplitTestIndexPageVariables,
  PauseSplitTestAtSplitTestIndexPageComponent,
  PauseSplitTestAtSplitTestIndexPageMutation,
  PauseSplitTestAtSplitTestIndexPageVariables
} from "testly-web/queries";

export interface SplitTestActions {
  activate: MutationFn<
    ActivateSplitTestAtSplitTestIndexPageMutation,
    ActivateSplitTestAtSplitTestIndexPageVariables
  >;
  pause: MutationFn<
    PauseSplitTestAtSplitTestIndexPageMutation,
    PauseSplitTestAtSplitTestIndexPageVariables
  >;
  complete: MutationFn<
    FinishSplitTestAtSplitTestIndexPageMutation,
    FinishSplitTestAtSplitTestIndexPageVariables
  >;
}

export const SpitTestActionMutations: React.SFC<{
  children: (props: SplitTestActions, loading: boolean) => React.ReactNode;
  onError?: (error: ApolloError) => void;
  onCompleted: (
    data:
      | ActivateSplitTestAtSplitTestIndexPageMutation
      | PauseSplitTestAtSplitTestIndexPageMutation
      | FinishSplitTestAtSplitTestIndexPageMutation
  ) => void;
  variables?: ActivateSplitTestAtSplitTestIndexPageVariables &
    PauseSplitTestAtSplitTestIndexPageVariables &
    FinishSplitTestAtSplitTestIndexPageVariables;
}> = ({ children, onError, onCompleted, variables }) => (
  <ActivateSplitTestAtSplitTestIndexPageComponent
    onError={onError}
    onCompleted={onCompleted}
    variables={variables}
  >
    {(activateMutation, activateResult) => (
      <PauseSplitTestAtSplitTestIndexPageComponent
        onError={onError}
        onCompleted={onCompleted}
        variables={variables}
      >
        {(pauseMutation, pauseResult) => (
          <FinishSplitTestAtSplitTestIndexPageComponent
            onError={onError}
            onCompleted={onCompleted}
            variables={variables}
          >
            {(completeMutation, completeResult) =>
              children(
                {
                  activate: activateMutation,
                  pause: pauseMutation,
                  complete: completeMutation
                },
                activateResult.loading ||
                  pauseResult.loading ||
                  completeResult.loading
              )
            }
          </FinishSplitTestAtSplitTestIndexPageComponent>
        )}
      </PauseSplitTestAtSplitTestIndexPageComponent>
    )}
  </ActivateSplitTestAtSplitTestIndexPageComponent>
);
