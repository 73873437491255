import React from "react";

export const SearchIcon = () => (
  // tslint:disable:max-line-length
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
  >
    <path
      fill="#A7A9AE"
      d="M9.049 0a5.765 5.765 0 0 0-5.757 5.757c0 1.378.484 2.642 1.295 3.633L0 13.978l.828.827 4.587-4.587a5.712 5.712 0 0 0 3.634 1.295 5.765 5.765 0 0 0 5.756-5.756A5.765 5.765 0 0 0 9.05 0zm0 1.151a4.597 4.597 0 0 1 4.605 4.606 4.597 4.597 0 0 1-4.605 4.605 4.597 4.597 0 0 1-4.606-4.605A4.597 4.597 0 0 1 9.05 1.15z"
    />
  </svg>
);
