import { NetworkStatus } from "apollo-boost";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { Button } from "testly-web/components/Button";
import {
  HeadCell,
  Row,
  Table,
  TableEmptyCell,
  TableRefreshIcon
} from "testly-web/components/Table";
import { paths } from "testly-web/paths";
import { GetDataForGoalListPageNodes } from "testly-web/queries";
import { GoalRow } from "./GoalRow";

const GoalsListPageStyled = styled.div`
  padding: 40px;
  padding-top: 24px;
`;

const ButtonStyled = styled(Button)`
  margin-bottom: 32px;
  float: right;
`;

export const GoalsListPage: React.SFC<{
  goals: GetDataForGoalListPageNodes[];
  projectId: string;
  networkStatus: NetworkStatus;
  afterDelete(): void;
  refetch(): void;
}> = ({ goals, projectId, afterDelete, networkStatus, refetch }) => {
  return (
    <GoalsListPageStyled>
      <Link to={paths.goalNewPath({ projectId })}>
        <ButtonStyled action="primary" size="small">
          Create Goal
        </ButtonStyled>
      </Link>
      <Table>
        <thead>
          <Row hover={false}>
            <HeadCell>Name</HeadCell>
            <HeadCell>Path</HeadCell>
            <HeadCell>Goals Completed</HeadCell>
            <HeadCell>Created On</HeadCell>
            <HeadCell>
              <TableRefreshIcon
                isRefreshing={networkStatus === NetworkStatus.refetch}
                onClick={e => {
                  refetch();
                }}
              />
            </HeadCell>
          </Row>
        </thead>
        <tbody>
          {goals.length > 0 ? (
            goals.map(goal => (
              <GoalRow
                projectId={projectId}
                goal={goal}
                key={goal.id}
                afterDelete={afterDelete}
              />
            ))
          ) : (
            <tr>
              <TableEmptyCell>No goals found.</TableEmptyCell>
            </tr>
          )}
        </tbody>
      </Table>
    </GoalsListPageStyled>
  );
};
