import React from "react";

export const SourceIcon: React.SFC<React.SVGProps<SVGSVGElement>> = props => (
  // tslint:disable:max-line-length
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="16"
    viewBox="0 0 12 16"
    {...props}
  >
    <path
      fill="#A7A9AE"
      d="M9.6 1.6v11.974L6.23 12.13l-.63-.27-.63.27-3.37 1.444V1.6h8zm0-1.6h-8C.72 0 0 .72 0 1.6V16l5.6-2.4 5.6 2.4V1.6c0-.88-.72-1.6-1.6-1.6z"
    />
  </svg>
);
