import React from "react";
import { toast } from "react-toastify";
import { ConfirmAlert } from "testly-web/components/ConfirmAlert";
import { onMutationError } from "testly-web/components/onFail";
import { DeleteFeedbackPollComponent } from "testly-web/queries";

export const DeleteFeedbackPoll: React.SFC<{
  feedbackPoll: {
    id: string;
    name: string;
  };
  afterDelete?(): void;
  children(onDelete: (event: React.SyntheticEvent) => void): React.ReactNode;
}> = ({ feedbackPoll: { id, name }, children, afterDelete }) => (
  <DeleteFeedbackPollComponent onError={onMutationError}>
    {deleteFeedbackPoll => (
      <ConfirmAlert
        title={`Are you sure you wish to delete «${name}»? This cannot be undone.`}
      >
        {confirm =>
          children(
            confirm(async () => {
              await deleteFeedbackPoll({ variables: { feedbackPollId: id } });

              toast.info("The feedback poll was deleted.");

              if (afterDelete) {
                afterDelete();
              }
            })
          )
        }
      </ConfirmAlert>
    )}
  </DeleteFeedbackPollComponent>
);
