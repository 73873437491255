import React from "react";
import styled, { css } from "styled-components/macro";
// import { Toggler } from "testly-web/components/App/Form/Toggler";
import { DisabledType } from "../Card";

const StyledHeader = styled.div<DisabledType>`
  display: flex;
  align-items: center;

  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  color: #393839;

  height: 40px;

  ${({ disabled }) => disabled && `color: #a7a9ae;`};
`;
// const HeaderBadge = styled.span`
//   font-family: Roboto;
//   font-size: 9px;
//   font-weight: bold;
//   text-transform: uppercase;
//   color: #ffffff;
//   background-color: #ff5858;
//   margin-left: 11px;
//
//   padding: 3px 6px;
// `;

const ImgContainer = styled.span<DisabledType>`
  display: inline-flex;
  margin-right: 12px;
  width: 36px;
  height: auto;

  fill: #ecedee;

  ${({ disabled }) =>
    !disabled &&
    css`
      fill: #00ac69;
    `};
`;

// const TogglerStyled = styled(Toggler)`
//   margin-left: auto;
// `;

export type HeaderProps = {
  titleImg: React.SFC;
  titleCaption: string;
  activateToggler?: {
    checked: boolean;
    disabled: boolean;
    onChange(): void;
  };
} & DisabledType;

export const Header: React.SFC<HeaderProps> = ({
  disabled,
  titleImg: TitleImg,
  titleCaption
}) => {
  return (
    <StyledHeader disabled={disabled}>
      <ImgContainer disabled={disabled}>
        <TitleImg />
      </ImgContainer>
      {titleCaption}
      {/* {activateToggler && <TogglerStyled {...activateToggler} />} */}
    </StyledHeader>
  );
};
