import { VNode } from "snabbdom/vnode";

// The namespace issue happens when we receive mutation of svg elements
// So `h` from snabbdom doesn't know context where mutation happened(but it happened at svg element)
// Each children of svg should have right namespace set. Otherwise image will not be rendered
export const fixSvgNamespaces = (
  vnode: VNode | string,
  isSvgNode = false
): void => {
  if (typeof vnode === "string") {
    return;
  }

  const { sel } = vnode;

  // It is taken from https://github.com/snabbdom/snabbdom/blob/master/src/h.ts#L42
  if (sel) {
    if (
      sel[0] === "s" &&
      sel[1] === "v" &&
      sel[2] === "g" &&
      (sel.length === 3 || sel[3] === "." || sel[3] === "#")
    ) {
      isSvgNode = true;
    }
  }

  if (isSvgNode) {
    vnode.data = { ...(vnode.data || {}), ns: "http://www.w3.org/2000/svg" };
  }

  if (vnode.sel === "foreignObject") {
    // https://developer.mozilla.org/en-US/docs/Web/SVG/Element/foreignObject
    isSvgNode = false;
  }

  if (vnode.children) {
    vnode.children.forEach(childVnode =>
      fixSvgNamespaces(childVnode, isSvgNode)
    );
  }
};
