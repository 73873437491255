import React from "react";
import styled from "styled-components";
import { BillingHistory } from "./BillingHistory";
import { BillingPlans } from "./BillingPlans";
import { BillingVisitors } from "./BillingVisitors";

const example = {
  current: 18927,
  total: 20000
};

const BillingWrapper = styled.div`
  padding: 25px 40px;
`;

const BillingSectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 35px 0 12px;

  &:first-child {
    margin-top: 0;
  }
`;

const VisitorsNumber = styled.div`
  & > span {
    font-size: 14px;
    color: #a7a9ae;
  }

  & > strong {
    font-size: 14px;
    color: #393839;
    font-weight: 400;
  }
`;

const BillingCaption = styled.h3`
  margin: 0;

  font-size: 18px;
  font-weight: 500;
`;

export const AccountSettingsBilling: React.SFC = () => (
  <BillingWrapper>
    <BillingSectionHeader>
      <BillingCaption>Monthly Visitors</BillingCaption>
      <VisitorsNumber>
        <strong>{example.current}</strong>
        <span>/{example.total}</span>
      </VisitorsNumber>
    </BillingSectionHeader>
    <BillingVisitors current={example.current} total={example.total} />

    <BillingSectionHeader>
      <BillingCaption>Account Plans</BillingCaption>
    </BillingSectionHeader>
    <BillingPlans />

    <BillingSectionHeader>
      <BillingCaption>Billing History</BillingCaption>
    </BillingSectionHeader>

    <BillingHistory />
  </BillingWrapper>
);
