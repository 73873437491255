import React from "react";
import styled, { css } from "styled-components/macro";
import { PauseIcon } from "testly-web/icons/PauseIcon";
import { PlayIcon } from "testly-web/icons/PlayIcon";
import { Button } from "../../Button";
import { DropdownContainer } from "../../Dropdown/DropdownContainer";

export const StyledDropdown = styled(DropdownContainer)`
  display: inline-flex;
`;

export const ActionStyled = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;

  font-family: Roboto;
  font-size: 14px;
  color: #393839;
  line-height: 1.86;
  padding-left: 15px;

  transition: background-color 0.2s ease-out;

  cursor: pointer;

  &:first-child {
    margin-top: 8px;
  }

  &:last-child {
    margin-bottom: 8px;
  }

  &:hover {
    background-color: #ecedee;
  }

  ${({ active }) =>
    active &&
    css`
      padding-left: 6px;
      &:before {
        content: " ";
        height: 5px;
        width: 5px;
        margin-right: 4px;
        background-color: #19b5fe;
        border-radius: 50%;
        display: inline-block;
      }
    `}
`;

export const Action: React.SFC<
  { active: boolean } & React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >
> = ({ children, active, onClick, ref, ...props }) => (
  <ActionStyled
    active={active}
    onClick={e => !active && onClick && onClick(e)}
    {...props}
  >
    {children}
  </ActionStyled>
);

export const StyledButton = styled(Button)`
  padding-left: 13px;
  padding-right: 13px;

  width: 130px;
  svg {
    margin-right: 12px;
  }
`;

export const PlayStyled = styled(PlayIcon)`
  width: 11px;
  height: 11px;
`;

export const PauseStyled = styled(PauseIcon)`
  width: 11px;
  height: 11px;
`;
