import { IdentifiableVNode, NodePath } from "../types";
import { mapChildNode } from "./mapChildNode";

const setText = (node: IdentifiableVNode, data: string) => {
  return {
    ...node,
    text: data
  };
};

const mutateTextWithoutRoot = (
  currentElement: IdentifiableVNode,
  nodePath: NodePath,
  data: string
): IdentifiableVNode => {
  if (nodePath.length === 0) {
    // If it is root element
    return setText(currentElement, data);
  } else if (nodePath.length === 1) {
    return mapChildNode(currentElement, nodePath, node => {
      return setText(node, data);
    });
  } else {
    return mapChildNode(currentElement, nodePath, (node, newPath) =>
      mutateTextWithoutRoot(node, newPath, data)
    );
  }
};

export const mutateText = (
  currentElement: IdentifiableVNode,
  nodePath: NodePath,
  data: string
): IdentifiableVNode => {
  if (nodePath[0] !== currentElement.data.nodeId) {
    throw new Error("First element should be root");
  }

  return mutateTextWithoutRoot(currentElement, nodePath.slice(1), data);
};
