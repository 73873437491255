import React from "react";

export const LogoIcon = () => (
  // tslint:disable:max-line-length
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.68556 2.93333C6.95489 1.3133 8.50025 0 10.1372 0H29.2137C29.2161 0 29.2176 0.0018646 29.2172 0.0041647C29.2169 0.00641637 29.2184 0.00826109 29.2207 0.00832884C32.0096 0.0918324 34.4943 0.800227 36.5842 1.99782C38.5938 3.14935 38.1717 5.8965 36.1675 7.05746L27.6739 11.9775C26.206 10.5463 24.2026 9.66505 21.9941 9.66505C17.4924 9.66505 13.8431 13.3265 13.8431 17.8432C13.8431 18.5344 13.9286 19.2056 14.0895 19.8467L6.15031 24.4456C4.80899 25.2226 3.16863 24.0876 3.42285 22.5585L6.68556 2.93333Z"
      fill="#4DA96F"
    />
    <path
      d="M4.17225 31.6008C2.59372 32.5152 1.50623 34.087 1.20706 35.8865L0.345854 41.0667C0.0765213 42.6867 1.18521 44 2.82218 44H11.8994C13.5364 44 15.0817 42.6867 15.3511 41.0667L16.1435 36.3H23.1788C23.1811 36.3 23.1833 36.2981 23.1837 36.2958C23.1841 36.2936 23.1862 36.2917 23.1885 36.2917C33.0867 35.9982 41.7925 27.9882 43.4282 18.15C43.7822 16.0203 43.7765 13.9762 43.4507 12.0776C43.1945 10.5843 41.5054 9.97469 40.1945 10.7341L30.0528 16.6089C30.1136 17.0114 30.1451 17.4236 30.1451 17.8432C30.1451 22.3598 26.4957 26.0213 21.9941 26.0213C20.0666 26.0213 18.2955 25.3501 16.9001 24.2279L4.17225 31.6008Z"
      fill="#4DA96F"
    />
  </svg>
);
