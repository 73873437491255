import React from "react";
import { ApolloConsumer } from "react-apollo";

export const ToggleAccountSettings: React.SFC<{
  active: boolean;
  children: React.ReactNode;
}> = ({ active, children }) => (
  <ApolloConsumer>
    {client => (
      <div
        onClick={() => {
          client.writeData({
            data: {
              accountSettingsTab: { active, __typename: "AccountSettings" }
            }
          });
        }}
      >
        {children}
      </div>
    )}
  </ApolloConsumer>
);
