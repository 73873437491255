import React from "react";
import { ApolloConsumer } from "react-apollo";

export const SetCurrentProjectId: React.SFC<{
  id: string | null;
}> = ({ id }) => {
  return (
    <ApolloConsumer>
      {client => {
        // Not sure why, but without setTimeout
        // components doesn't get updated
        setTimeout(
          () => client.writeData({ data: { currentProjectId: id } }),
          0
        );

        return null;
      }}
    </ApolloConsumer>
  );
};
