import React from "react";
import ReactPaginate from "react-paginate";
import { ArrowIcon } from "testly-web/icons/ArrowIcon";
import "./paginator.css";

export const Paginator: React.SFC<{
  pageCount: number;
  className?: string;
  page: number;
  onPageChange(data: { selected: number }): void;
}> = ({ pageCount, onPageChange, className, page }) => (
  <ReactPaginate
    breakLabel={<span>...</span>}
    pageCount={pageCount}
    marginPagesDisplayed={2}
    pageRangeDisplayed={5}
    forcePage={page - 1}
    nextLabel={<ArrowIcon />}
    previousLabel={<ArrowIcon />}
    onPageChange={({ selected }) => {
      onPageChange({ selected: selected + 1 });
    }}
    previousLinkClassName={"testly-pagination__previous"}
    nextLinkClassName={"testly-pagination__next"}
    breakClassName={"testly-pagination__break"}
    containerClassName={`${className} testly-pagination`}
    activeClassName={"active"}
    pageClassName={"testly-pagination__page-container"}
    pageLinkClassName={"testly-pagination__page"}
  />
);
