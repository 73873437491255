export const requestFrameWithTimestamp = (
  callback: (
    previousTimestamp: number,
    currentTimestamp: number
  ) => Promise<void>,
  stopped: () => boolean
) => {
  let currentRenderTimestamp = Date.now();

  const render = async () => {
    if (stopped()) {
      return;
    }

    const previousTimestamp = currentRenderTimestamp;
    const currentTimestamp = Date.now();

    await callback(previousTimestamp, currentTimestamp);

    currentRenderTimestamp = currentTimestamp;

    requestAnimationFrame(render);
  };

  requestAnimationFrame(render);
};
