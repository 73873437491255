import { FieldArray, Form, FormikProps } from "formik";
import React from "react";
import styled, { css } from "styled-components/macro";
import { FeedbackPollShowOption } from "testly-web/queries";
import { Button } from "../Button";
import { TextField } from "../Form/Field";
import { RadionInput } from "../Form/Radio";
import { UrlInput, UrlInputControl } from "../goal/UrlInput";
import { Body, Footer, Panel } from "../Panel";
import { Hr, SectionHeader } from "../Panel/Panel";
import { QuestionsForm } from "./QuestionsForm";
import { FeedbackFormValues, initPageMatcher } from "./schema";

const BodyStyled = styled(Body)`
  padding-bottom: 20px;
`;

const SectionHeaderStyled = styled(SectionHeader)`
  margin-top: 20px;
`;

const ThankYouTextarea = styled(TextField)`
  margin-top: 20px;
`;

const InputRow = styled.div`
  margin-bottom: 22px;
`;

const RadionInputStyled = styled(RadionInput)<{ last?: true }>`
  margin-bottom: 8px;

  ${({ last }) =>
    last &&
    css`
      margin-bottom: 16px;
    `}
`;

export const FeedbackForm: React.SFC<{
  formProps: FormikProps<FeedbackFormValues>;
}> = ({ formProps }) => {
  const { values, errors, isSubmitting } = formProps;

  return (
    <Panel>
      <Form>
        <BodyStyled>
          <TextField label="The name of the poll" name="name" {...formProps} />

          <SectionHeaderStyled>The pages to show</SectionHeaderStyled>

          <RadionInputStyled
            name="isPageMatcherEnabled"
            label="On all pages where PathPulse code is installed"
            checked={!values.isPageMatcherEnabled}
            onBlur={formProps.handleBlur}
            onChange={() => {
              formProps.setFieldValue("isPageMatcherEnabled", false);
            }}
          />

          <RadionInputStyled
            name="isPageMatcherEnabled"
            label="On specific pages where PathPulse code is installed"
            checked={values.isPageMatcherEnabled}
            last={true}
            onBlur={formProps.handleBlur}
            onChange={() => {
              formProps.setFieldValue("isPageMatcherEnabled", true);
            }}
          />

          {values.isPageMatcherEnabled && (
            <FieldArray
              name="pageMatchers"
              validateOnChange={false}
              render={arrayHelpers =>
                values.pageMatchers.map((pageMatcher, i) => (
                  <InputRow key={i}>
                    <UrlInput
                      fieldPrefix={`pageMatchers.${i}.`}
                      urlInputAfterRender={() => (
                        <UrlInputControl
                          onAddClick={() => {
                            arrayHelpers.push(initPageMatcher());
                          }}
                          onDeleteClick={() => {
                            arrayHelpers.remove(i);
                          }}
                          mode={
                            i === values.pageMatchers.length - 1
                              ? "addable"
                              : "deletable"
                          }
                        />
                      )}
                      {...formProps}
                    />
                  </InputRow>
                ))
              }
            />
          )}

          <Hr />

          <SectionHeaderStyled>Enter your questions below</SectionHeaderStyled>

          <FieldArray
            name="questions"
            validateOnChange={false}
            render={arrayHelpers => (
              <QuestionsForm
                values={values}
                setFieldValue={formProps.setFieldValue}
                arrayHelpers={arrayHelpers}
                errors={errors}
              />
            )}
          />

          <ThankYouTextarea
            label="Thank you message"
            name="thankYouMessage"
            component="textarea"
            {...formProps}
          />

          <Hr />

          <SectionHeaderStyled>Initial Poll State</SectionHeaderStyled>

          <RadionInputStyled
            name="isPollOpenedOnStart"
            label="Opened"
            checked={values.isPollOpenedOnStart}
            onBlur={formProps.handleBlur}
            onChange={() => {
              formProps.setFieldValue("isPollOpenedOnStart", true);
            }}
          />

          <RadionInputStyled
            name="isPollOpenedOnStart"
            label="Closed"
            checked={!values.isPollOpenedOnStart}
            last={true}
            onBlur={formProps.handleBlur}
            onChange={() => {
              formProps.setFieldValue("isPollOpenedOnStart", false);
            }}
          />

          <SectionHeaderStyled>
            How often should the poll be displayed?
          </SectionHeaderStyled>

          <RadionInputStyled
            name="showPollOption"
            label="Until they respond to the poll"
            checked={
              values.showPollOption === FeedbackPollShowOption.HideAfterSubmit
            }
            onBlur={formProps.handleBlur}
            onChange={formProps.handleChange}
            value={FeedbackPollShowOption.HideAfterSubmit}
          />

          <RadionInputStyled
            name="showPollOption"
            label="Always"
            checked={values.showPollOption === FeedbackPollShowOption.Always}
            onBlur={formProps.handleBlur}
            onChange={formProps.handleChange}
            value={FeedbackPollShowOption.Always}
          />

          <SectionHeaderStyled>Status</SectionHeaderStyled>

          <RadionInputStyled
            name="isActive"
            label="Active"
            checked={values.isActive}
            onBlur={formProps.handleBlur}
            onChange={() => {
              formProps.setFieldValue("isActive", true);
            }}
          />

          <RadionInputStyled
            name="isActive"
            label="Inactive"
            checked={!values.isActive}
            last={true}
            onBlur={formProps.handleBlur}
            onChange={() => {
              formProps.setFieldValue("isActive", false);
            }}
          />
        </BodyStyled>

        <Footer>
          <div />
          <Button
            action="primary"
            size="small"
            type="submit"
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            Submit
          </Button>
        </Footer>
      </Form>
    </Panel>
  );
};
