import { Link } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import { DropdownArrow } from "testly-web/components/Dropdown/DropdownArrow";

export const defaultSizeRowHeight = 100;

const commonRowStyle = css`
  display: flex;
  padding: 0 30px;
  position: relative;

  box-sizing: border-box;
  border-bottom: solid 1px #ecedee;
  cursor: pointer;
  background-color: #fff;
  transition: background-color 0.15s ease-out;

  &:hover {
    background-color: #fafafa;
  }
`;

// eslint-disable-next-line no-unexpected-multiline
export const Row = styled.div<{
  defaultCursor?: boolean;
  hover?: boolean;
}>`
  ${commonRowStyle};
  z-index: 1;
  height: ${defaultSizeRowHeight}px;

  border-left: solid 1px #ecedee;
  border-right: solid 1px #ecedee;

  &:last-child {
    border-bottom: none;
  }

  ${({ defaultCursor }) =>
    defaultCursor &&
    css`
      cursor: default;
    `};
  ${({ hover }) =>
    hover === false &&
    css`
      &:hover {
        background-color: #fff;
      }
    `};
`;

export const LinkabeRow = Row.withComponent(Link);

export const ActiveRow = styled.div<{ opened: boolean }>`
  ${commonRowStyle};
  z-index: 2;

  ${DropdownArrow} {
    margin-right: 7px;
    margin-top: 5px;
    margin-left: auto;
  }

  border-top: solid 1px #fff;

  border-left: solid 1px #ecedee;
  border-right: solid 1px #ecedee;

  ${({ opened }) =>
    opened
      ? css`
          background-color: #fff;
        `
      : css`
          border-bottom: none;
          padding-bottom: 1px;
        `};
`;
