import React from "react";
import ReactModal from "react-modal";
import styled from "styled-components/macro";
import { Button } from "../Button";

type Show = (
  // tslint:disable-next-line:no-any
  callback: (event: React.SyntheticEvent) => Promise<any>
) => (event: React.SyntheticEvent) => void;

interface ConfirmAlertProps {
  title: string;
  description?: string;
  children(show: Show): React.ReactNode;
}

interface ConfirmAlertState {
  processing: boolean;
  opened: boolean;
  // tslint:disable-next-line:no-any
  callback(): Promise<any>;
}

if (document.getElementById("root")) {
  ReactModal.setAppElement("#root");
}

const ModalStyled = styled(ReactModal)`
  background-color: #ffffff;
  margin-top: 150px;
  max-width: 530px;
  padding: 40px;
  outline: none;
`;

const Title = styled.h1`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  color: #393839;
  text-align: center;
  margin: 0;
`;

const Description = styled.div`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  color: #393839;
  text-align: center;

  margin-top: 20px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;

  margin-top: 40px;
`;

const ButtonStyled = styled(Button)`
  &:last-child {
    margin-left: 20px;
  }
`;

export class ConfirmAlert extends React.Component<
  ConfirmAlertProps,
  ConfirmAlertState
> {
  public state = {
    processing: false,
    opened: false,
    callback: async () => null
  };

  public show: Show = callback => event => {
    event.preventDefault();

    this.setState({
      processing: false,
      opened: true,
      callback: async () => {
        await callback(event);
      }
    });
  };

  public hide = () => {
    this.setState({
      processing: false,
      opened: false,
      callback: async () => null
    });
  };

  public confirm = async () => {
    this.setState({ processing: true });
    await this.state.callback();
    this.setState({ processing: false });
    this.hide();
  };

  public render() {
    const { title, description } = this.props;

    return (
      <React.Fragment>
        {this.props.children(this.show)}

        <ModalStyled
          isOpen={this.state.opened}
          shouldCloseOnEsc={true}
          onRequestClose={this.hide}
        >
          <Title>{title}</Title>
          {description && <Description>{description}</Description>}

          <Footer>
            <ButtonStyled action="secondary" size="small" onClick={this.hide}>
              Cancel
            </ButtonStyled>
            <ButtonStyled
              action="dangerous"
              size="small"
              onClick={this.confirm}
              disabled={this.state.processing}
              loading={this.state.processing}
            >
              Delete
            </ButtonStyled>
          </Footer>
        </ModalStyled>
      </React.Fragment>
    );
  }
}
