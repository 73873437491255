import { Formik, FormikActions, FormikProps } from "formik";
import H from "history";
import queryString from "query-string";
import React from "react";
import { MutationFn } from "react-apollo";
import { RouteComponentProps } from "react-router";
import { toast } from "react-toastify";
import { SetCurrentProjectId } from "testly-web/components/CurrentProjectId/SetCurrentProjectId";
import { convertToDto } from "testly-web/components/goal/DTOConverter";
import { SetNavbar } from "testly-web/components/Navbar/SetNavbar";
import {
  onFormSubmitFail,
  onMutationError
} from "testly-web/components/onFail";
import { paths } from "testly-web/paths";
import {
  CreateGoalComponent,
  CreateGoalMutation,
  CreateGoalVariables
} from "testly-web/queries";
import {
  goalSchema,
  initialGoalUrl,
  PathGoalForm
} from "../../components/goal/pathGoal";
import { GoalNewPage } from "./GoalNewPage";

const initialGoalValues = (): PathGoalForm => ({
  id: undefined,
  name: "",
  finalGoalUrl: initialGoalUrl(),
  path: [initialGoalUrl()],
  pathBuilderToggled: false,
  value: "0"
});

const handleSubmit = (
  createGoal: MutationFn<CreateGoalMutation, CreateGoalVariables>,
  pushRoute: H.History["push"],
  projectId: string
) => {
  return async (values: PathGoalForm, actions: FormikActions<PathGoalForm>) => {
    const response = await createGoal({
      variables: { params: convertToDto(values), projectId }
    });

    if (response && response.data && response.data.createGoal) {
      const { successful, result } = response.data.createGoal;

      if (successful && result) {
        toast.success("Your goal has been created.");
        pushRoute(paths.goalListPath({ projectId }));
        actions.resetForm();
      } else {
        onFormSubmitFail(values, response.data);
      }

      actions.setSubmitting(false);
    }
  };
};

export const GoalNewPageContainer: React.SFC<
  RouteComponentProps<{
    projectId: string;
  }>
> = ({
  match: {
    params: { projectId }
  },
  history: { push, location }
}) => {
  const queryState: {
    cameFrom?: string;
  } = queryString.parse(location.search) || {};

  return (
    <>
      <CreateGoalComponent onError={onMutationError}>
        {createGoal => (
          <Formik<PathGoalForm>
            initialValues={initialGoalValues()}
            validationSchema={goalSchema}
            onSubmit={handleSubmit(createGoal, push, projectId)}
          >
            {(props: FormikProps<PathGoalForm>) => (
              <GoalNewPage formProps={props} />
            )}
          </Formik>
        )}
      </CreateGoalComponent>

      <SetCurrentProjectId id={projectId} />
      <SetNavbar
        header="Create Goal"
        backPath={
          queryState.cameFrom === "dashboard"
            ? paths.projectPath({ projectId })
            : paths.goalListPath({ projectId })
        }
      />
    </>
  );
};
