import styled from "styled-components/macro";

export const Time = styled.div`
  border-radius: 10px;
  background-color: #f0f1f1;
  padding: 1px 8px;

  font-family: Roboto;
  font-size: 10px;
  color: #a7a9ae;
`;
