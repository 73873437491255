import React from "react";

export const Play = ({ ...props }) => (
  // tslint:disable:max-line-length
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="20"
    viewBox="0 0 18 20"
    {...props}
  >
    <path
      fill="#FFF"
      d="M16.368 8.547L2.033.177A1.23 1.23 0 0 0 .665.242C.248.534 0 1.06 0 1.625V18.38c0 .56.248 1.086.665 1.378.227.164.48.243.737.243.218 0 .433-.06.63-.174l14.34-8.379c.472-.272.77-.833.77-1.448 0-.614-.302-1.175-.774-1.452z"
    />
  </svg>
);
