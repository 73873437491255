import React from "react";
import { DeviceType } from "testly-web/queries";

export const DeviceIcon: React.SFC<
  React.SVGProps<SVGSVGElement> & {
    deviceType: DeviceType;
    color?: "green" | "gray";
  }
> = ({ deviceType, color, ...props }) => {
  const fill = color === "green" ? "#00ac69" : "#A7A9AE";

  // tslint:disable:max-line-length
  switch (deviceType) {
    case DeviceType.Mobile:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="20"
          viewBox="0 0 13 20"
          {...props}
        >
          <path
            fill={fill}
            d="M10.786 0H2.214C1.425 0 .786.64.786 1.429V18.57c0 .79.639 1.429 1.428 1.429h8.572c.789 0 1.428-.64 1.428-1.429V1.43c0-.79-.639-1.429-1.428-1.429zM6.5 18.571a.715.715 0 1 1 0-1.429.715.715 0 0 1 0 1.43zm4.286-2.142H2.214V2.143h8.572v14.286z"
          />
        </svg>
      );
    case DeviceType.Tablet:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21"
          height="16"
          viewBox="0 0 21 16"
          {...props}
        >
          <path
            fill={fill}
            d="M18.662 0H1.738A1.54 1.54 0 0 0 .2 1.538v12.308c0 .849.69 1.539 1.538 1.539h16.924a1.54 1.54 0 0 0 1.538-1.539V1.538A1.54 1.54 0 0 0 18.662 0zm-.77 8.462v5.384H1.738V1.538h16.154v5.385a.77.77 0 0 1 0 1.539z"
          />
        </svg>
      );
    case DeviceType.Desktop:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="19"
          viewBox="0 0 20 19"
          {...props}
        >
          <path
            fill={fill}
            d="M1.667 0C.746 0 0 .746 0 1.667v11.666C0 14.254.746 15 1.667 15h6.458l-.417 1.667H6.667a.833.833 0 1 0 0 1.666h6.666a.833.833 0 1 0 0-1.666h-1.041L11.875 15h6.458c.921 0 1.667-.746 1.667-1.667V1.667C20 .746 19.254 0 18.333 0H1.667zm0 1.667h16.666v10H1.667v-10zM10 12.5a.834.834 0 1 1 0 1.667.834.834 0 0 1 0-1.667z"
          />
        </svg>
      );
    default:
      throw new Error(`Unknown device type ${deviceType}`);
  }
};
