import { Formik, FormikActions, FormikProps } from "formik";
import { LocationDescriptorObject } from "history";
import React from "react";
import { MutationFn } from "react-apollo";
import { Route } from "react-router";
import { toast } from "react-toastify";
import {
  onFormSubmitFail,
  onMutationError
} from "testly-web/components/onFail";
import {
  settingsFormSchema,
  SettingsFormValues
} from "testly-web/components/split-test-form/schemas";
import { SettingsForm } from "testly-web/components/split-test-form/SettingsForm/SettingsForm";
import { actionsNameInPastMap } from "testly-web/components/split-test/actionsNameInPastMap";
import {
  mapFinishConditionToParams,
  mapSplitTestToValues
} from "testly-web/pages/SplitTestEditPage/settingsPageMappers";
import { paths } from "testly-web/paths";
import {
  GetDataForSplitTestEditPageSplitTest,
  SplitTestStatus,
  UpdateSplitTestAtSplitTestEditPageComponent,
  UpdateSplitTestAtSplitTestEditPageMutation,
  UpdateSplitTestAtSplitTestEditPageVariables
} from "testly-web/queries";
import {
  SpitTestActionMutations,
  SplitTestActions
} from "./SplitTestActionMutations";

const onSubmit = (
  projectId: string,
  splitTestId: string,
  initialStatus: SplitTestStatus,
  ableToStart: boolean,
  updateSplitTest: MutationFn<
    UpdateSplitTestAtSplitTestEditPageMutation,
    UpdateSplitTestAtSplitTestEditPageVariables
  >,
  splitTestActions: SplitTestActions,
  replace: (path: LocationDescriptorObject) => void
) => {
  // TODO: refactor after https://github.com/jaredpalmer/formik/issues/486 stable release
  return async (
    params: SettingsFormValues,
    actions: FormikActions<SettingsFormValues>
  ) => {
    const { status, ...paramsToUpdate } = params;

    const response = await updateSplitTest({
      variables: {
        splitTestId,
        splitTestParams: {
          ...paramsToUpdate,
          finishCondition: mapFinishConditionToParams(
            paramsToUpdate.finishCondition
          )
        }
      }
    });

    if (
      !response ||
      !response.data ||
      !response.data.updateSplitTest ||
      !response.data.updateSplitTest.successful ||
      !response.data.updateSplitTest.result
    ) {
      actions.setSubmitting(false);
      onFormSubmitFail(params, response && response.data);
      return;
    }

    if (initialStatus !== status) {
      if (!ableToStart && status === SplitTestStatus.Active) {
        toast.error("Unable to start test, setup is not finished.");
        actions.setFieldValue("status", initialStatus);
        actions.setSubmitting(false);
        return;
      }

      const statusUpdateResponse = await (async () => {
        switch (status) {
          case SplitTestStatus.Active:
            return splitTestActions.activate({ variables: { splitTestId } });
          case SplitTestStatus.Finished:
            return splitTestActions.complete({ variables: { splitTestId } });
          case SplitTestStatus.Paused:
            return splitTestActions.pause({ variables: { splitTestId } });
          default:
            throw new Error(
              `can't mutate to ${status} status, not implemented`
            );
        }
      })();

      if (!statusUpdateResponse || !statusUpdateResponse.data) {
        actions.setSubmitting(false);
        onFormSubmitFail(params, response && response.data);
        return;
      }
    }

    if (initialStatus === status) {
      toast.success("Split test saved!");
    } else {
      toast.success(`Split test saved and ${actionsNameInPastMap[status]}!`);
    }

    replace({
      pathname: paths.splitTestIndexPath({
        projectId
      })
    });
  };
};

export const SettingsFormContainer: React.SFC<{
  splitTest: GetDataForSplitTestEditPageSplitTest;
  projectId: string;
  onBackButtonClick(): void;
}> = ({ splitTest, projectId, onBackButtonClick }) => {
  const ableToActivate =
    splitTest.goals.length > 0 && splitTest.variations.length >= 2;

  return (
    <Route
      render={({ history: { replace }, location: { state } }) => (
        <UpdateSplitTestAtSplitTestEditPageComponent onError={onMutationError}>
          {updateSplitTest => (
            <SpitTestActionMutations onError={onMutationError}>
              {splitTestActions => (
                <Formik<SettingsFormValues>
                  initialValues={mapSplitTestToValues(splitTest)}
                  validationSchema={settingsFormSchema}
                  onSubmit={onSubmit(
                    projectId,
                    splitTest.id,
                    splitTest.status,
                    ableToActivate,
                    updateSplitTest,
                    splitTestActions,
                    path => replace({ ...path, state })
                  )}
                  validateOnBlur={false}
                  validateOnChange={false}
                >
                  {(props: FormikProps<SettingsFormValues>) => (
                    <SettingsForm
                      onBackButtonClick={onBackButtonClick}
                      formProps={props}
                      goals={splitTest.goals}
                      onStatusSelect={async status => {
                        const result = await props.validateForm();

                        if (Object.keys(result).length === 0) {
                          props.setFieldValue("status", status);
                        }

                        // Hack to wait for new value to be applied
                        // Pending https://github.com/jaredpalmer/formik/issues/529
                        await Promise.resolve();
                        await props.submitForm();
                      }}
                    />
                  )}
                </Formik>
              )}
            </SpitTestActionMutations>
          )}
        </UpdateSplitTestAtSplitTestEditPageComponent>
      )}
    />
  );
};
