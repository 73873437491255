import React from "react";

export const TrashIcon: React.SFC<React.SVGProps<SVGSVGElement>> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" d="M-610-413H830V645H-610z" />
      <circle cx="15" cy="15" r="15" fill="#ECEDEE" />
      <path
        fill="#A7A9AE"
        fillRule="nonzero"
        d="M13.667 8c-.306 0-.618.107-.839.328a1.187 1.187 0 0 0-.328.839v.583H9v1.167h.583v9.333c0 .96.791 1.75 1.75 1.75h7c.96 0 1.75-.79 1.75-1.75v-9.333h.584V9.75h-3.5v-.583c0-.306-.107-.618-.328-.839A1.187 1.187 0 0 0 16 8h-2.333zm0 1.167H16v.583h-2.333v-.583zm-2.917 1.75h8.167v9.333c0 .324-.26.583-.584.583h-7a.582.582 0 0 1-.583-.583v-9.333zm1.167 1.75v6.416h1.166v-6.416h-1.166zm2.333 0v6.416h1.167v-6.416H14.25zm2.333 0v6.416h1.167v-6.416h-1.167z"
      />
    </g>
  </svg>
);
