import React from "react";
import CustomScroll from "react-custom-scroll";
import styled from "styled-components/macro";
import { Column, ColumnTitle } from "./styles";
import { OptionType } from "./types";

const ListableOptions = styled.div`
  margin-top: 19px;
  box-sizing: border-box;

  height: 145px;
  overflow-y: auto;
  padding-bottom: 15px;
`;

const Bottom = styled.div`
  position: relative;
  margin-top: 20px;
  margin-bottom: -6px;
`;

export const ListFilter: React.SFC<{
  name: string;
  options: OptionType[];
  optionRender(option: OptionType): React.ReactNode;
  boxRender(): React.ReactNode;
}> = ({ name, options, optionRender, boxRender }) => (
  <Column>
    <ColumnTitle>{name}</ColumnTitle>
    <Bottom>{boxRender()}</Bottom>
    <ListableOptions>
      <CustomScroll heightRelativeToParent="100%">
        {options.map(optionRender)}
      </CustomScroll>
    </ListableOptions>
  </Column>
);
