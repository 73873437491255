import { PageMatcherMatchType } from "testly-web/queries";
import * as Yup from "yup";

export interface PathGoalStep {
  url: string;
  matchType: PageMatcherMatchType;
}

// NOTE: it has very different structure with backend.
// Basically backend doesn't know anything about `finalGoalUrl`,
// it is just used for the form. Before we send data to server
// `finalGoalUrl` will be appended to the `path`
export interface PathGoalForm {
  id?: string;
  name: string;
  finalGoalUrl: PathGoalStep;
  path: PathGoalStep[];
  pathBuilderToggled: boolean;
  value: string;
}

export const goalStepSchema = Yup.object().shape({
  url: Yup.string().required("Is required"),
  matchType: Yup.string()
});

export const goalSchema = Yup.object().shape({
  name: Yup.string().required("A goal name is required"),
  value: Yup.number()
    .required("A value passed should be present.")
    .typeError("You must specify a number"),
  finalGoalUrl: goalStepSchema,
  pathBuilderToggled: Yup.boolean(),
  path: Yup.array().when("pathBuilderToggled", {
    is: true,
    then: Yup.array().of(goalStepSchema),
    otherwise: Yup.array()
  })
});

export const initialGoalUrl = (): PathGoalStep => ({
  url: "",
  matchType: PageMatcherMatchType.Contains
});

export const initialGoalValues = (): PathGoalForm => ({
  id: undefined,
  name: "",
  finalGoalUrl: initialGoalUrl(),
  path: [initialGoalUrl()],
  pathBuilderToggled: false,
  value: "0"
});
