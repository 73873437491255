import queryString from "query-string";
import { SplitTestFilter } from "testly-web/queries";

export interface QueryState {
  page: number;
  filter: SplitTestFilter;
}

export const buildFilter = (
  filter?: Partial<SplitTestFilter>
): SplitTestFilter => ({
  createdAtGteq: null,
  createdAtLteq: null,
  nameCont: null,
  pageCategoryIdIn: null,
  pageTypeIdIn: null,
  statusIn: null,
  ...filter
});

export const buildQuery = ({ page, filter }: QueryState) =>
  `?page=${page}&filter=${encodeURI(JSON.stringify(filter))}`;

export const parseQuery = (search: string): QueryState => {
  const parsedQuery: {
    page?: string;
    filter?: string;
  } = queryString.parse(search) || {};

  return {
    page: parsedQuery.page ? parseInt(parsedQuery.page, 10) : 1,
    filter: parsedQuery.filter
      ? (JSON.parse(parsedQuery.filter) as SplitTestFilter)
      : buildFilter()
  };
};
