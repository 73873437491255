import React from "react";

export const LeftArrowIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="13"
    viewBox="0 0 8 13"
  >
    <path d="M7.5 1.387L6.596.5.933 6.056.5 6.5l.433.444L6.596 12.5l.904-.887L2.29 6.5z" />
  </svg>
);
