const key = "testly-session-player-setting";

type UserSettingsData = { playingSpeed: number; skipPauses: boolean };

export class UserPlayerSettings {
  private data: UserSettingsData;

  public constructor() {
    const settingsString = window.localStorage.getItem(key);

    this.data = (settingsString && JSON.parse(settingsString)) || {
      playingSpeed: 1,
      skipPauses: true
    };
  }

  public getPlayingSpeed = () => {
    return this.data.playingSpeed;
  };

  public setPlayingSpeed = (playingSpeed: number) => {
    this.set({ ...this.data, playingSpeed });
  };

  public getSkipPauses = () => {
    return this.data.skipPauses;
  };

  public setSkipPauses = (skipPauses: boolean) => {
    this.set({ ...this.data, skipPauses });
  };

  public get = () => {
    return this.data;
  };

  public set = (data: UserSettingsData) => {
    window.localStorage.setItem(key, JSON.stringify(data));
    this.data = data;
  };
}
