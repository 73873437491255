import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { toast } from "react-toastify";
import { SetCurrentProjectId } from "testly-web/components/CurrentProjectId/SetCurrentProjectId";
import { loadingRender } from "testly-web/components/loadingRender";
import { SetNavbar } from "testly-web/components/Navbar/SetNavbar";
import {
  ChangeProjectSessionRecordingsStatusComponent,
  GetProjectForProjectPageComponent
} from "testly-web/queries";
import { ProjectPage } from "./ProjectPage";

export const ProjectPageContainer: React.SFC<
  RouteComponentProps<{ projectId: string }>
> = ({
  match: {
    params: { projectId }
  }
}) => (
  <>
    <SetCurrentProjectId id={projectId} />
    <SetNavbar header="Dashboard" />
    <ChangeProjectSessionRecordingsStatusComponent
      onCompleted={({ updateProject }) => {
        const isRecordingEnabled = updateProject!.result!.isRecordingEnabled;

        toast.success(
          `Recordings are ${isRecordingEnabled ? "enabled" : "disabled"}.`
        );
      }}
    >
      {updateProjectRecordingsStatus => (
        <GetProjectForProjectPageComponent
          variables={{ projectId }}
          fetchPolicy="network-only"
          notifyOnNetworkStatusChange={true}
        >
          {loadingRender(
            "currentUser.project",
            ({ data, networkStatus, refetch }) => (
              <ProjectPage
                project={data!.currentUser!.project!}
                billingProducts={data!.billingProducts}
                currentBilling={data!.currentUser!.currentBilling}
                networkStatus={networkStatus}
                refetch={refetch}
                updateProjectRecordingsStatus={async (
                  isRecordingEnabled: boolean
                ) =>
                  updateProjectRecordingsStatus({
                    variables: {
                      projectId,
                      active: isRecordingEnabled
                    }
                  }) as Promise<void>
                }
              />
            )
          )}
        </GetProjectForProjectPageComponent>
      )}
    </ChangeProjectSessionRecordingsStatusComponent>
  </>
);
