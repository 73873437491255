import { SessionEventType } from "session-player/eventTypes";

export enum ViewableActionType {
  MouseClicked = "mousedClicked",
  PageVisited = "pageVisited",
  RespondedToPoll = "respondedToPoll"
}

export const mapEventTypeToAction = (
  eventType: SessionEventType
): ViewableActionType => {
  switch (eventType) {
    case SessionEventType.MouseClicked:
      return ViewableActionType.MouseClicked;
    case SessionEventType.PageVisited:
      return ViewableActionType.PageVisited;
    default:
      throw new Error(`${eventType} is not viewable`);
  }
};
