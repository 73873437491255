import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { Button } from "testly-web/components/Button";
import {
  ContextActionItem,
  ContextDropdown
} from "testly-web/components/ContextDropdown/ContextDropdown";
import { matches } from "testly-web/components/goal/matches";
import {
  ActionsWrapper,
  BodyCell,
  BodyCellWithImage,
  Row,
  SecondTextLine
} from "testly-web/components/Table";
import { CalendarIcon } from "testly-web/icons/CalendarIcon";
import { EyeIcon } from "testly-web/icons/EyeIcon";
import { DeleteGoal } from "testly-web/mutations/DeleteGoal/DeleteGoal";
import { paths } from "testly-web/paths";
import { GetDataForGoalListPageNodes } from "testly-web/queries";
import { DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT } from "testly-web/settings";
import {
  buildFilter,
  buildQuery
} from "../../components/session-recordings-list/query";

const PathList = styled.ul`
  padding-inline-start: 20px;
  margin-block-start: 0px;
  margin-block-end: 0px;
  list-style-type: decimal;
  line-height: 1.5;
`;

const ActionButton = styled(Button)`
  width: 160px;
  z-index: 0;
  padding-left: 0;
  padding-right: 0;
`;

const ActionBodyCell = styled(BodyCell)``;

const recordsPath = (projectId: string, goalId: string) =>
  `${paths.sessionRecordingListPath({ projectId })}?${buildQuery({
    page: 1,
    filter: buildFilter({
      convertedProjectGoalIdsIn: [goalId]
    }),
    cameFrom: "goals"
  })}`;

const ActionItemLink = ContextActionItem.withComponent(Link);

export const GoalRow: React.SFC<{
  goal: GetDataForGoalListPageNodes;
  projectId: string;
  afterDelete(): void;
}> = ({ goal, projectId, afterDelete }) => {
  const createdAtMoment = moment(goal.createdAt);

  return (
    <Row>
      <BodyCell canContainLongText={true}>{goal.name}</BodyCell>
      <BodyCell canContainLongText={true}>
        <PathList>
          {goal.path
            .sort((a, b) => a.index - b.index)
            .map(({ index, url, matchType }) => (
              <li key={index}>
                {url} ({matches[matchType]})
              </li>
            ))}
        </PathList>
      </BodyCell>
      <BodyCellWithImage image={EyeIcon}>
        {goal.conversionsCount}
      </BodyCellWithImage>
      <BodyCellWithImage image={CalendarIcon}>
        {createdAtMoment.format(DEFAULT_DATE_FORMAT)}
        <SecondTextLine>
          {createdAtMoment.format(DEFAULT_TIME_FORMAT)}
        </SecondTextLine>
      </BodyCellWithImage>

      <ActionBodyCell>
        <ActionsWrapper>
          <Link to={recordsPath(projectId, goal.id)}>
            <ActionButton action="secondary" size="small">
              View Recordings
            </ActionButton>
          </Link>
          <ContextDropdown>
            <ActionItemLink
              to={`${paths.goalEditPath({
                projectId,
                goalId: goal.id
              })}?cameFrom=goals`}
            >
              Edit
            </ActionItemLink>
            <DeleteGoal goal={goal} afterDelete={afterDelete}>
              {confirm => (
                <ContextActionItem onClick={confirm}>Delete</ContextActionItem>
              )}
            </DeleteGoal>
          </ContextDropdown>
        </ActionsWrapper>
      </ActionBodyCell>
    </Row>
  );
};
