import React from "react";
import styled from "styled-components/macro";
import noPhotoIcon from "testly-web/icons/no-photo-icon.svg";
import { DropdownCaptionContainer, DropdownContent } from "../../Dropdown";
import { DropdownArrow } from "../../Dropdown/DropdownArrow";
import { DropdownContainer } from "../../Dropdown/DropdownContainer";
import { UserDropdown } from "./UserDropdown";

export interface CurrentUser {
  id: string;
  fullName: string;
  avatarUrl: string | null;
}

export const StyledUser = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  height: 50px;

  font-family: Roboto;
  font-weight: 400;
  font-size: 16px;
  font-size: 16px;

  margin-right: 47px;
`;

const AvatarContainer = styled.div`
  width: 50px;
  height: 50px;

  display: block;
  margin: 0 auto;

  img {
    border-radius: 50%;
  }
`;

const StyledDropdown = styled(DropdownContainer)`
  ${DropdownContent} {
    width: 260px;
    margin-right: -21px;
  }

  ${DropdownCaptionContainer} {
    display: flex;
  }

  ${DropdownArrow} {
    margin-left: 16px;
  }
`;

const Caption = styled.div`
  display: inline;
  line-height: 50px;
  letter-spacing: normal;
`;

const Name = styled(Caption)`
  color: #393839;
  margin-left: 5px;
`;

const Greeting = styled(Caption)`
  margin-left: 10px;
  color: rgba(62, 72, 98, 0.5);
`;

export const User: React.SFC<{
  className?: string;
  loading: boolean;
  currentUser: CurrentUser | undefined;
}> = ({ className, loading, currentUser }) => (
  <StyledUser className={className}>
    <StyledDropdown
      dropdownContent={() => (
        <UserDropdown visitorsMonthlyLimit={1000} visitorsThisMonth={10} />
      )}
      animation="popup"
    >
      <AvatarContainer>
        <img
          src={(currentUser && currentUser.avatarUrl) || noPhotoIcon}
          width="50"
          height="50"
          alt="Avatar"
        />
      </AvatarContainer>

      <Greeting>Hello, </Greeting>
      <Name>{!loading && currentUser && currentUser.fullName}</Name>

      <DropdownArrow />
    </StyledDropdown>
  </StyledUser>
);
