import React from "react";

export const CirclePlusIcon: React.SFC<
  React.SVGProps<SVGSVGElement>
> = props => (
  // tslint:disable:max-line-length
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="#00AC69"
      d="M8 0C3.589 0 0 3.589 0 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8zm0 1.23A6.76 6.76 0 0 1 14.77 8 6.76 6.76 0 0 1 8 14.77 6.76 6.76 0 0 1 1.23 8 6.76 6.76 0 0 1 8 1.23zm-.615 3.693v2.462H4.923v1.23h2.462v2.462h1.23V8.615h2.462v-1.23H8.615V4.923h-1.23z"
    />
  </svg>
);
