import { keyframes } from "styled-components/macro";

export const slideDownAnimation = (height: string) => keyframes`
  from {
    transform: translate3d(0, -${height}, 0);
    opacity: 0;
    max-height: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    max-height: none;
  }
`;

export const slideUpAnimation = (height: string) => keyframes`
  from {
    visibilty: visible;
    transform: translate3d(0, 0, 0);
    opacity: 1;
    max-height: none;
  }

  to {
    visibilty: hidden;
    transform: translate3d(0, -${height}, 0);
    opacity: 0;
    max-height: 0;
  }
`;

export const popUpAnimation = keyframes`
  0% {
    transform: scale(0.7, 0.7);
    opacity: 0;
  }
  60% {
    transform: scale(1.07, 1.07);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
`;

export const popDownAnimation = keyframes`
  0% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    transform: scale(0.9, 0.9);
    opacity: 0;
    visibility: hidden;
  }
`;
