import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { IdeaLabIcon } from "testly-web/icons/IdeaLabIcon";
import { LearningIcon } from "testly-web/icons/LearningIcon";
import { LogoIcon } from "testly-web/icons/LogoIcon";
import { ProvingIcon } from "testly-web/icons/ProvingIcon";
import { SettingsIcon } from "testly-web/icons/SettingsIcon";
import { paths } from "testly-web/paths";
import { menuItemHeight, menuItemWidth } from "testly-web/style-vars";
import { SlideMenu } from "../../LeftBarSlideMenu";
import { OpenedMenuType } from "../../LeftBarSlideMenu/SlideMenu";
import { Item } from "./Item";

const StyledLeftBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: ${menuItemWidth}px;
  height: 100%;
  min-height: 480px;

  font-family: Roboto;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 1px;
  text-align: center;

  background-color: #393839;
  color: #a7a9ae;

  box-shadow: -1px 0 20px 0 rgba(52, 60, 83, 0.3);
`;

const Items = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
`;

const SettingsBtn = styled(Link)`
  margin-top: auto;
  margin-bottom: 34px;
  fill: #a7a9ae;

  transition: fill 0.3s ease-in-out;

  &:hover {
    fill: #fff;
  }
`;

const LogoContainer = styled.div`
  height: ${menuItemHeight}px;
  width: ${menuItemWidth}px;
  display: inline-block;
  position: relative;
  background-color: #fff;
`;

const LogoImageContainer = styled(Link)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100%;
`;

const ActionItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

// const SlideMenuWrapper = styled.div`
//   position: relative;
//   height: 100%;
// `;

export type ActiveType =
  | "learning"
  | "proving"
  | "communityTest"
  | "ideaLab"
  | "none";

interface SlideMenuState {
  opened: boolean;
  closeAnimationIsRunning: boolean;
  wrapperRef: React.RefObject<HTMLDivElement>;
  onControlClick(e: React.MouseEvent<Element, MouseEvent>): void;
  close(): void;
}

interface LeftBarProps {
  openedMenuType: OpenedMenuType;
  menuState: SlideMenuState;
  togglerRef: React.RefObject<HTMLDivElement>;
  activeType: ActiveType;
  currentProjectId: string | null;
  onLearningClick(e: React.MouseEvent<Element, MouseEvent>): void;
  onProvingClick(e: React.MouseEvent<Element, MouseEvent>): void;
}

export const LeftBar: React.SFC<LeftBarProps> = ({
  openedMenuType,
  menuState,
  togglerRef,
  activeType,
  onLearningClick,
  onProvingClick,
  currentProjectId
}) => (
  <StyledLeftBar>
    <LogoContainer>
      <LogoImageContainer
        className="b-top-bar__logo-image"
        to={
          (currentProjectId &&
            paths.projectPath({ projectId: currentProjectId })) ||
          "/"
        }
        role="link"
      >
        <LogoIcon />
      </LogoImageContainer>
    </LogoContainer>

    <Items>
      <SlideMenu
        openedMenuType={openedMenuType}
        currentProjectId={currentProjectId}
        {...menuState}
      />

      <ActionItemsWrapper
        ref={togglerRef as React.RefObject<HTMLDivElement> & string}
      >
        <Item
          image={LearningIcon}
          caption="Learning"
          active={activeType === "learning"}
          onClick={onLearningClick}
        />
        <Item
          image={ProvingIcon}
          caption="Proving"
          active={activeType === "proving"}
          onClick={onProvingClick}
        />
      </ActionItemsWrapper>
      {/* <Item
        image={CommunityTestIcon}
        caption="Community Tests"
        active={activeType === "communityTest"}
      /> */}
      <Link
        to={paths.testIdeaIndexPath({
          projectId: currentProjectId || "no-project",
          tab: "search"
        })}
      >
        <Item
          image={IdeaLabIcon}
          caption="Idea Lab"
          active={activeType === "ideaLab"}
        />
      </Link>

      <SettingsBtn
        to={
          (currentProjectId &&
            paths.projectEditPath({ projectId: currentProjectId })) ||
          ""
        }
      >
        <SettingsIcon />
      </SettingsBtn>
    </Items>
  </StyledLeftBar>
);
