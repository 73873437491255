import React, { CSSProperties } from "react";
import styled, { createGlobalStyle } from "styled-components/macro";

// NOTE: can be done with just css
// https://stackoverflow.com/questions/34833815/showing-truncated-text-on-hover-using-css-ellipsis-overlaps-text-below-it

interface WithCaptionProps<T> {
  caption: string;
  style: CSSProperties;
  children: React.ReactElement<T>;
}

interface WithCaptionState {
  renderHover: boolean;
  offsetTop: number;
}

const TextHover = styled.div`
  background-color: #f9f7e3;
  font-family: Roboto;
  opacity: 0;
  position: absolute;
  transition: opacity 0.1s ease-in-out;
`;

export const HighlightHiddenTextGlobalHover = createGlobalStyle`
  *:hover + ${TextHover}, ${TextHover}:hover {
    opacity: 1;
    z-index: 1000;
  }
`;

export class HighlightHiddenText<T> extends React.Component<
  WithCaptionProps<T>,
  WithCaptionState
> {
  private calcInterval?: number;
  private childRef: React.RefObject<T>;

  constructor(props: WithCaptionProps<T>) {
    super(props);

    this.state = {
      renderHover: false,
      offsetTop: 0
    };

    this.childRef = React.createRef<T>();
  }

  public componentDidMount() {
    window.addEventListener("resize", this.calcRef, true);
    this.calcInterval = setInterval(this.calcRef, 500);
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.calcRef);

    if (this.calcInterval) {
      clearInterval(this.calcInterval);
    }
  }

  public render() {
    return (
      <>
        {// tslint:disable-next-line:no-any
        React.cloneElement(this.props.children as React.ReactElement<any>, {
          ref: this.childRef,
          onClick: () => true
        })}
        {this.state.renderHover && (
          <TextHover style={{ ...this.props.style, top: this.state.offsetTop }}>
            {this.props.caption}
          </TextHover>
        )}
      </>
    );
  }

  private calcRef = () => {
    const currentChildRef = this.childRef.current as HTMLElement | null;

    if (
      currentChildRef &&
      currentChildRef.offsetWidth < currentChildRef.scrollWidth
    ) {
      this.setState({
        renderHover: true,
        offsetTop: currentChildRef.offsetTop
      });
    } else {
      this.setState({ renderHover: false });
    }
  };
}
