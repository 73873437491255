import React from "react";
import { BillingPlan } from "./BillingPlan";

const examplePlans = [
  {
    maxVisitors: 7500,
    price: 49,
    active: false,
    upgrade: false
  },
  {
    maxVisitors: 20000,
    price: 99,
    active: true,
    upgrade: false
  },
  {
    maxVisitors: 100000,
    price: 149,
    active: false,
    upgrade: true
  },
  {
    maxVisitors: 300000,
    price: 249,
    active: false,
    upgrade: true
  },
  {
    maxVisitors: 500000,
    price: 349,
    active: false,
    upgrade: true
  },
  {
    maxVisitors: 550000,
    special: true
  }
];

export const BillingPlans: React.SFC = () => (
  <>
    {examplePlans.map((plan, index) => (
      <BillingPlan
        key={index}
        maxVisitors={Number(plan.maxVisitors).toLocaleString("en")}
        price={plan.price}
        active={plan.active}
        upgrade={plan.upgrade}
        special={plan.special}
      />
    ))}
  </>
);
