import React from "react";
import styled from "styled-components/macro";
import { LeftGrayArrowIcon } from "testly-web/icons/LeftGrayArrowIcon";
import { RightWhiteArrowIcon } from "testly-web/icons/RightWhiteArrowIcon";
import { Omit } from "testly-web/types";
import { Button, ButtonStyledProps, ButtonText } from "../Button/Button";

export const FormFotterPrimaryButtonWidth = 150;

const LeftGrayArrowStyled = styled(LeftGrayArrowIcon)`
  margin-right: 16px;
`;

const BackButtonStyled = styled(Button)`
  width: 85px;
  padding: 0;
  ${ButtonText} {
    justify-content: space-between;
  }
`;

export const BackButton: React.SFC<
  Omit<ButtonStyledProps, "action" | "size"> &
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >
> = ({ ref, ...props }) => (
  <BackButtonStyled {...props} action="link" size="small">
    <LeftGrayArrowStyled />
    Previous
  </BackButtonStyled>
);

const RightWhiteArrowStyled = styled(RightWhiteArrowIcon)`
  margin-top: 3px;
`;

const NextButtonStyled = styled(Button)`
  padding-right: 16px;
  padding-left: 20px;
  width: ${FormFotterPrimaryButtonWidth}px;

  ${ButtonText} {
    justify-content: space-between;
  }
`;

export const NextButton: React.SFC<
  Omit<ButtonStyledProps, "action" | "size"> &
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >
> = ({ ref, ...props }) => (
  <NextButtonStyled {...props} action="primary" size="small">
    Next Step
    <RightWhiteArrowStyled />
  </NextButtonStyled>
);
