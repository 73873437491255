import React from "react";
import styled, { css } from "styled-components/macro";

import { Link } from "react-router-dom";
import { SplitTestFormStep } from "testly-web/components/split-test-form/schemas";
import { SplitTestSettingsIcon } from "testly-web/icons/SplitTestSettingsIcon";
import { paths } from "testly-web/paths";

export enum AtiveTab {
  splitTestShowPage,
  splitTestSessionRecordingsIndexPage
}

const PageHeader = styled.header`
  display: flex;
  height: 100px;
  padding: 0 40px;

  background-color: #f2f3f4;
  border-bottom: 4px solid #e2e4e6;
`;

const PageHeaderTabs = styled.nav`
  display: flex;
`;

// eslint-disable-next-line no-unexpected-multiline
const PageHeaderLink = styled(({ active, ...props }) => <Link {...props} />)<{
  active: boolean;
}>`
  display: flex;
  align-items: center;

  height: 100%;

  position: relative;

  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  color: #393839;

  cursor: pointer;

  & + & {
    margin-left: 45px;
  }

  &:after {
    content: "";

    width: calc(100% + 20px);
    height: 100%;

    position: absolute;
    left: -10px;

    background-color: #000;
    opacity: 0;

    transition: opacity 0.2s;
  }

  &:hover::after {
    opacity: 0.02;
  }

  ${({ active }) =>
    active &&
    css`
      color: #00ac69;
      border-bottom: 4px solid #00ac69;
    `}
`;

const SettingsLink = styled(PageHeaderLink)`
  margin-left: auto;

  & > svg {
    margin-right: 10px;

    transition: transform 0.2s;
  }

  &:hover > svg {
    transform: rotate(90deg);
  }
`;

export const SplitTestPageHeader: React.SFC<{
  splitTestId: string;
  projectId: string;
  activeTab: AtiveTab;
}> = ({ projectId, splitTestId, activeTab }) => {
  return (
    <PageHeader>
      <PageHeaderTabs>
        <PageHeaderLink
          to={paths.splitTestShowPath({ projectId, splitTestId })}
          active={activeTab === AtiveTab.splitTestShowPage}
          replace={true}
        >
          Results
        </PageHeaderLink>
        <PageHeaderLink
          to={paths.splitTestSessionRecordingIndexPage({
            projectId,
            splitTestId
          })}
          active={activeTab === AtiveTab.splitTestSessionRecordingsIndexPage}
          replace={true}
        >
          Session Recordings
        </PageHeaderLink>
      </PageHeaderTabs>
      <SettingsLink
        to={{
          pathname: paths.splitTestEditPath({
            projectId,
            splitTestId,
            step: SplitTestFormStep.Details
          }),
          state: {
            previousPath: `${window.location.pathname}${window.location.search}`
          }
        }}
        active={false}
      >
        <SplitTestSettingsIcon />
        Test Settings
      </SettingsLink>
    </PageHeader>
  );
};
