import { IdentifiableVNode, MutationAttrs, NodeId } from "./types";

export enum MutationType {
  CreateOrMoveNode = "createOrMoveNode",
  RemoveNode = "removeNode",
  MutateAttrs = "mutateAttrs",
  MutateCharacterData = "mutateCharacterData"
}

export interface MutateAttributes {
  mutationType: MutationType.MutateAttrs;
  attrs: MutationAttrs;
  nodeId: NodeId;
}

export interface CreateNode {
  mutationType: MutationType.CreateOrMoveNode;
  parentId: NodeId;
  previousSiblingId?: NodeId;
  node: IdentifiableVNode;
}

export interface RemoveNode {
  mutationType: MutationType.RemoveNode;
  nodeId: NodeId;
}

export interface MutateCharacterData {
  mutationType: MutationType.MutateCharacterData;
  nodeId: NodeId;
  data: string;
}

export type DomMutation =
  | MutateAttributes
  | CreateNode
  | RemoveNode
  | MutateCharacterData;
