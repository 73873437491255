import styled, { css } from "styled-components/macro";

// eslint-disable-next-line no-unexpected-multiline
export const Caption = styled.span<{
  size: "small" | "medium";
}>`
  vertical-align: middle;
  font-family: Roboto;
  font-size: 7px;
  line-height: 7px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #00ac69;
  padding: 3px 6px;
  color: #fff;
  align-self: center;
  letter-spacing: 1px;

  ${({ size }) =>
    size === "medium" &&
    css`
      font-size: 9px;
      line-height: 9px;
    `};
`;
