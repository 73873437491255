import React from "react";
import styled, { css } from "styled-components/macro";
import { DropdownController } from "testly-web/components/Dropdown/DropdownController";
import { OptionsIcon } from "testly-web/icons/OptionsIcon";
import { AdvancedFilterDropbox } from "./AdvancedFilterDropbox";

const MoreFiltersButton = styled.button<{ active: boolean }>`
  display: flex;
  fill: #a7a9ae;
  position: relative;
  background-color: #efefef;
  border-radius: 2px;
  border: solid 1px #a7a9ae;
  padding: 10px 20px;
  outline: none;
  cursor: pointer;

  svg {
    margin-left: 10px;
  }

  ${({ active }) =>
    active &&
    css`
      color: #fff;
      fill: #fff;
      background-color: #393839;
      border: 1px solid #393839;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `};
`;

const Backdrop = styled.div<{ active: boolean }>`
  position: absolute;
  top: -1px;
  background-color: #393839;
  width: 100%;
  height: 10px;
  opacity: 0;
  max-height: 0;

  transition: all 0.2s ease-out;

  ${({ active }) =>
    active &&
    css`
      opacity: 1;
      max-height: 10px;
    `};
`;

const FiltersCount = styled.div`
  color: #fff;

  position: absolute;
  right: -8px;
  top: -8px;

  font-family: Roboto;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.3px;
  line-height: 16px;
  text-align: center;

  width: 16px;
  height: 16px;
  background-color: #19b5fe;
  border-radius: 50%;
`;

const AdvancedFilterDropboxStyled = styled(AdvancedFilterDropbox)`
  position: absolute;
  left: 0;
  width: 100%;
  margin-top: 8px;
`;

const AdvancedFilterStyled = styled.div`
  margin-left: 30px;
`;

class TypedDropdownController extends DropdownController<
  HTMLDivElement,
  HTMLButtonElement
> {}

export interface AdvancedFilterProps {
  className?: string;
  filtersCount: number;
  renderedFilters: Record<string, React.ReactNode>;
  onFilterClose?(): void;
}

export class AdvancedFilter extends React.Component<AdvancedFilterProps> {
  public render() {
    const {
      className,
      onFilterClose,
      filtersCount,
      renderedFilters
    } = this.props;

    return (
      <TypedDropdownController onClose={onFilterClose}>
        {({
          wrapperRef,
          togglerRef,
          onControlClick,
          closeAnimationIsRunning: closedByUser,
          opened
        }) => (
          <AdvancedFilterStyled className={className}>
            <MoreFiltersButton
              onClick={onControlClick}
              active={opened}
              ref={togglerRef as React.RefObject<HTMLButtonElement>}
            >
              More Filters
              <OptionsIcon />
              {filtersCount !== 0 && (
                <FiltersCount>{filtersCount}</FiltersCount>
              )}
            </MoreFiltersButton>
            <div style={{ position: "relative" }}>
              <Backdrop active={opened} />
            </div>
            <AdvancedFilterDropboxStyled
              opened={opened}
              closedByUser={closedByUser}
              innerRef={wrapperRef}
              renderedFilters={renderedFilters}
            />
          </AdvancedFilterStyled>
        )}
      </TypedDropdownController>
    );
  }
}
