import { Form, Formik, FormikActions } from "formik";
import React from "react";
import { SelectField, TextField } from "testly-web/components/Form/Field";
import { Body, Footer, Panel } from "testly-web/components/Panel";
import { NextButton } from "./formComponents";
import { detailsFormSchema, DetailsFormValues } from "./schemas";

interface SiteCategory {
  id: string;
  name: string;
}

interface SiteType {
  id: string;
  name: string;
}

interface TestIdea {
  id: string;
  name: string;
}

const mapToOptions = (data: SiteCategory[] | SiteType[]) =>
  data.map(({ id, name }) => ({
    label: name,
    value: id
  }));

export const DetailsForm: React.SFC<{
  pageCategories: SiteCategory[];
  pageTypes: SiteType[];
  testIdeas: TestIdea[];
  initialValues: DetailsFormValues;
  onSubmit(
    values: DetailsFormValues,
    actions: FormikActions<DetailsFormValues>
  ): void;
}> = ({ initialValues, pageCategories, pageTypes, testIdeas, onSubmit }) => (
  <Panel>
    <Formik<DetailsFormValues>
      initialValues={initialValues}
      validationSchema={detailsFormSchema}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {props => {
        const { isSubmitting } = props;

        return (
          <Form>
            <Body>
              <TextField
                label="Name"
                name="name"
                description="Choose a short name that reflects what you're testing."
                placeholder="i.e. Homepage Button"
                {...props}
              />

              <TextField
                label="Description"
                name="description"
                description="Add your test description and notes here."
                placeholder="Description"
                component="textarea"
                optional={true}
                {...props}
              />

              <SelectField
                label="Site Category"
                name="pageCategoryId"
                description="Select the category that best matches the webpage that you're testing."
                options={mapToOptions(pageCategories)}
                {...props}
              />

              <SelectField
                label="Site Type"
                name="pageTypeId"
                description="Select the type that best matches your web page."
                options={mapToOptions(pageTypes)}
                {...props}
              />

              {initialValues.testIdeaId && (
                <SelectField
                  label="Test Idea"
                  name="testIdeaId"
                  description="Select the test idea that best matches your web page."
                  options={mapToOptions(testIdeas)}
                  {...props}
                />
              )}
            </Body>
            <Footer>
              <div />
              <NextButton
                disabled={isSubmitting}
                loading={isSubmitting}
                type="submit"
              />
            </Footer>
          </Form>
        );
      }}
    </Formik>
  </Panel>
);
