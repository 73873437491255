import React from "react";
import styled from "styled-components/macro";

import { Option, Select } from "testly-web/components/Form/Select";
import { HeadCell, Row, Table } from "../../components/Table/Table";
import { SplitTestVariation } from "./SplitTestVariation";

import { LoadingIndicator } from "testly-web/components/LoadingIndicator";
import { convertData, graphColors } from "./helpers";
import { MappedSplitTest } from "./helpers";
import { SplitTestShowPageChart } from "./SplitTestShowPageChart";

const PageWrapper = styled.main`
  padding: 30px 40px;

  background-color: #f8f8f8;
`;

const SplitTestChartWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 430px;
  margin: 15px 0 20px;
  padding: 20px 0;

  position: relative;

  background-color: #fff;

  font-size: 15px;
  color: #e8e8e8;

  &:before {
    content: "Average Conversion Rate";

    display: block;

    position: absolute;
    left: 20px;
    top: 50%;

    color: #3c3b3c;

    transform-origin: 0 0;
    transform: rotate(-90deg) translateX(-50%);
  }
`;

const HeadCellImprovement = styled(HeadCell)`
  width: 100px;
  background-color: rgba(0, 0, 0, 0.025);
  text-align: center;
`;

const HeadCellAlignedRight = styled(HeadCell)`
  text-align: right;
`;

const CustomSelectStyled = styled(Select)`
  width: 245px;
  height: 40px;
`;

const GoalSelectWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LoadingIndicatorStyled = styled(LoadingIndicator)`
  margin-left: 15px;
`;

interface SplitTestPageProps {
  splitTest: MappedSplitTest;
  refetching: boolean;
  projectId: string;
  onGoalSelect(goalId: string): void;
}

export class SplitTestShowPage extends React.Component<
  SplitTestPageProps,
  { chartWidth: number }
> {
  private chartWrapper = React.createRef<HTMLDivElement>();
  constructor(props: SplitTestPageProps) {
    super(props);

    this.state = {
      chartWidth: 1250
    };
  }

  public adjustChartWidth = () => {
    this.setState({
      chartWidth: this.chartWrapper.current!.clientWidth - 40
    });
  };

  public componentDidMount() {
    this.adjustChartWidth();
    window.addEventListener("resize", this.adjustChartWidth);
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.adjustChartWidth);
  }

  public render() {
    const { splitTest, onGoalSelect, refetching } = this.props;
    const data = convertData(splitTest.variations);
    const selectedGoal = splitTest.selectedGoal;
    const goalOptions = splitTest.goals.map(({ id, name }) => ({
      label: name,
      value: id
    }));
    const selectedGoalValue = selectedGoal && {
      label: selectedGoal.name,
      value: selectedGoal.id
    };

    return (
      <PageWrapper>
        <GoalSelectWrapper>
          <CustomSelectStyled
            options={goalOptions}
            value={selectedGoalValue}
            onChange={(option: Option) => {
              onGoalSelect(option.value!);
            }}
          />
          <LoadingIndicatorStyled isLoading={refetching} />
        </GoalSelectWrapper>
        <SplitTestChartWrapper
          ref={this.chartWrapper as React.RefObject<HTMLDivElement> & string}
        >
          <SplitTestShowPageChart
            width={this.state.chartWidth}
            height={430}
            data={data}
            variations={splitTest.variations}
          />
        </SplitTestChartWrapper>
        <Table>
          <thead>
            <Row hover={false}>
              <HeadCell>Variant</HeadCell>
              <HeadCellImprovement>Improvement</HeadCellImprovement>
              <HeadCellAlignedRight>Conversion Rate</HeadCellAlignedRight>
              <HeadCellAlignedRight>Conversions</HeadCellAlignedRight>
              <HeadCellAlignedRight>Visitors</HeadCellAlignedRight>
              <HeadCellAlignedRight>Revenue</HeadCellAlignedRight>
            </Row>
          </thead>
          <tbody>
            {splitTest.variations &&
              splitTest.variations.map((variation, i) => (
                <SplitTestVariation
                  key={variation.id}
                  variation={variation}
                  color={graphColors[i]}
                />
              ))}
          </tbody>
        </Table>
      </PageWrapper>
    );
  }
}
