import {
  GetDataForSplitTestEditPageSplitTest,
  SplitTestFinishConditionType,
  SplitTestParams
} from "testly-web/queries";
import { SettingsFormValues } from "../../components/split-test-form/schemas";

const defaultFinishCondition: SettingsFormValues["finishCondition"] = {
  conditionType: SplitTestFinishConditionType.DaysPassed,
  daysPassed: 0,
  visits: 0,
  goalConversions: 0,
  goalId: ""
};

export const mapFinishConditionToParams = (
  finishConditionValue: SettingsFormValues["finishCondition"]
): SplitTestParams["finishCondition"] =>
  (({
    [SplitTestFinishConditionType.DaysPassed]: {
      type: SplitTestFinishConditionType.DaysPassed,
      count: finishConditionValue.daysPassed
    },
    [SplitTestFinishConditionType.GoalConversions]: {
      type: SplitTestFinishConditionType.GoalConversions,
      count: finishConditionValue.goalConversions,
      goalId: finishConditionValue.goalId
    },
    [SplitTestFinishConditionType.Visits]: {
      type: SplitTestFinishConditionType.Visits,
      count: finishConditionValue.visits
    }
  } as Record<
    SplitTestFinishConditionType,
    SplitTestParams["finishCondition"]
  >)[finishConditionValue.conditionType]);

export const mapSplitTestToValues = (
  splitTest: GetDataForSplitTestEditPageSplitTest
) => {
  const finishConditionValue: SettingsFormValues["finishCondition"] = (() => {
    const finishCondition = splitTest.finishCondition;

    if (!finishCondition) {
      return defaultFinishCondition;
    }

    switch (finishCondition.__typename) {
      case "SplitTestGoalConversionsFinishCondition":
        const conversionFinishCondition: SettingsFormValues["finishCondition"] = {
          ...defaultFinishCondition,
          conditionType: SplitTestFinishConditionType.GoalConversions,
          goalId: finishCondition.goal.id,
          goalConversions: finishCondition.count
        };

        return conversionFinishCondition;
      case "SplitTestDaysPassedFinishCondition":
        const daysPassedFinishCondition: SettingsFormValues["finishCondition"] = {
          ...defaultFinishCondition,
          conditionType: SplitTestFinishConditionType.DaysPassed,
          daysPassed: finishCondition.count
        };

        return daysPassedFinishCondition;
      case "SplitTestVisitsFinishCondition":
        const visitsFinishCondition: SettingsFormValues["finishCondition"] = {
          ...defaultFinishCondition,
          conditionType: SplitTestFinishConditionType.Visits,
          visits: finishCondition.count
        };

        return visitsFinishCondition;
      default:
        throw new Error(
          `finishCondition.__typename=${finishCondition.__typename} is uknown`
        );
    }
  })();

  return {
    trafficPercent: splitTest.trafficPercent,
    trafficDeviceTypes: splitTest.trafficDeviceTypes,
    trafficReferrerSources: splitTest.trafficReferrerSources,
    finishCondition: finishConditionValue,
    status: splitTest.status
  };
};
