import React from "react";

type MainColor = "gray" | "green" | "blackGray";

const colors: Record<MainColor, string> = {
  gray: "#ECEDEE",
  green: "#00ac69",
  blackGray: "#A7A9AE"
};

export const SplitTestIcon: React.SFC<
  React.SVGProps<SVGSVGElement> & { mainColor: MainColor }
> = ({ mainColor, ...props }) => (
  // tslint:disable:max-line-length
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="28"
    viewBox="0 0 31 28"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <text
        fill={colors[mainColor]}
        fontFamily="Roboto-Bold, Roboto"
        fontSize="22"
        fontWeight="bold"
        letterSpacing=".275"
        transform="translate(.327 -5)"
      >
        <tspan x=".375" y="20.875">
          A
        </tspan>
      </text>
      <text
        fill={colors[mainColor]}
        fontFamily="Roboto-Bold, Roboto"
        fontSize="22"
        fontWeight="bold"
        letterSpacing=".275"
        transform="translate(.327 -5)"
      >
        <tspan x="18" y="32.125">
          B
        </tspan>
      </text>
      <path
        stroke={colors[mainColor]}
        strokeLinecap="square"
        d="M5.952 24.875L28.83 1.997"
      />
    </g>
  </svg>
);
