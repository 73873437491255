import React from "react";
import styled from "styled-components";

import { Button } from "../Button";
import { ToggleAccountSettings } from "./ToggleAccountSettings";

const ControlsWrapper = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 30px 40px;

  border-top: 3px solid #ecedee;
`;

const ControlsButton = styled(Button)`
  width: 130px;
  height: 40px;
`;

export const AccountSettingsControls: React.SFC<{
  reset: () => void;
  loading?: boolean;
}> = ({ reset, loading }) => (
  <ControlsWrapper>
    <ToggleAccountSettings active={false}>
      <ControlsButton
        action="secondary"
        type="button"
        size="small"
        children="Cancel"
        onClick={() => reset()}
        disabled={loading}
      />
    </ToggleAccountSettings>
    <ControlsButton
      action="primary"
      size="small"
      type="submit"
      children="Update"
      disabled={loading}
      loading={loading}
    />
  </ControlsWrapper>
);
