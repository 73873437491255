import React from "react";

export const DragIcon: React.SFC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="23"
    viewBox="0 0 8 23"
    {...props}
  >
    <g fill="#DBDEE0">
      <circle cx="1.5" cy="1.5" r="1.5" />
      <circle cx="1.5" cy="6.5" r="1.5" />
      <circle cx="6.5" cy="1.5" r="1.5" />
      <circle cx="6.5" cy="6.5" r="1.5" />
      <circle cx="1.5" cy="11.5" r="1.5" />
      <circle cx="6.5" cy="11.5" r="1.5" />
      <circle cx="1.5" cy="16.5" r="1.5" />
      <circle cx="1.5" cy="21.5" r="1.5" />
      <circle cx="6.5" cy="16.5" r="1.5" />
      <circle cx="6.5" cy="21.5" r="1.5" />
    </g>
  </svg>
);
