import moment from "moment";
import React, { ReactNode } from "react";
import { DelayedPlayerController } from "session-player/DelayedPlayerController";
import {
  FrameRenderedEvent,
  OnErrorListener,
  PlayingState
} from "session-player/playback/PlaybackManager";
import { TimelineManager } from "session-player/playback/TimelineManager";
import { Frame, Timeline } from "session-player/playback/types";
import { PlayerController } from "session-player/PlayerController";
import { SessionRecording } from "session-player/types";
import { UserPlayerSettings } from "session-player/UserPlayerSettings";

export interface Page {
  url: string | undefined;
  title: string;
  duration: number;
  visitedAt: moment.Moment;
}

export interface ReactPlayerState {
  frameRenderedEvent: FrameRenderedEvent | undefined;
  session: SessionRecording;
}

interface PlayerContainerProps {
  session: SessionRecording;
  pages: Page[];
  onError?: OnErrorListener;
  children(
    setRef: (ref: HTMLIFrameElement) => void,
    playerController: DelayedPlayerController,
    frame: Frame | undefined,
    playingState: PlayingState | undefined,
    timelineManager: TimelineManager,
    page: {
      pages: Page[];
      currentPage: Page;
      onPageChange(pageIndex: number): void;
    },
    userPlayerSettings: UserPlayerSettings
  ): ReactNode;
}

interface PlayerContainerState {
  frameRenderedEvent: FrameRenderedEvent | undefined;
  pageIndex: number;
  iframeRef?: HTMLIFrameElement;
  timelineManager: TimelineManager;
  delayedPlayerController: DelayedPlayerController;
  session: SessionRecording;
  userPlayerSettings: UserPlayerSettings;
}

const getTimelineOfPage = (page: Page): Timeline => {
  return {
    fromTime: moment(page.visitedAt).valueOf(),
    toTime: moment(page.visitedAt).valueOf() + page.duration
  };
};

export class PlayerContainer extends React.Component<
  PlayerContainerProps,
  PlayerContainerState
> {
  public static getDerivedStateFromProps(
    nextProps: PlayerContainerProps,
    state: PlayerContainerState
  ): PlayerContainerState {
    if (nextProps.session.id === state.session.id) {
      state.timelineManager.mergeEvents(nextProps.session.events);
      state.timelineManager.setTimeline(
        getTimelineOfPage(nextProps.pages[state.pageIndex])
      ); // Update timelime of all pages/last page

      return {
        ...state,
        session: nextProps.session
      };
    } else {
      const newTimelineManager = new TimelineManager(
        nextProps.session.events,
        getTimelineOfPage(nextProps.pages[0])
      );
      state.delayedPlayerController.resetPlayer();

      if (state.iframeRef) {
        state.delayedPlayerController.setPlayer(
          new PlayerController({
            contentWindow: state.iframeRef.contentWindow!,
            timelineManager: newTimelineManager,
            userPlayerSettings: state.userPlayerSettings
          })
        );
      }

      return {
        frameRenderedEvent: undefined,
        pageIndex: 0,
        session: nextProps.session,
        timelineManager: newTimelineManager,
        delayedPlayerController: state.delayedPlayerController,
        userPlayerSettings: state.userPlayerSettings
      };
    }
  }
  constructor(props: PlayerContainerProps) {
    super(props);

    this.state = {
      frameRenderedEvent: undefined,
      pageIndex: 0,
      delayedPlayerController: new DelayedPlayerController(),
      userPlayerSettings: new UserPlayerSettings(),
      timelineManager: new TimelineManager(
        props.session.events,
        getTimelineOfPage(props.pages[0])
      ),
      session: this.props.session
    };

    this.state.delayedPlayerController.onFrameRender(this.onFrameRender);

    this.state.delayedPlayerController.onError(e => {
      if (this.props.onError) {
        this.props.onError(e);
      }
    });
  }

  public componentWillUnmount() {
    this.state.delayedPlayerController.stop();
  }

  public render() {
    const event = this.state.frameRenderedEvent;
    const frame = event && event.frame;
    const playingState = event && event.newPlayingState;

    return this.props.children(
      this.setIframeRef,
      this.state.delayedPlayerController,
      frame,
      playingState,
      this.state.timelineManager,
      {
        pages: this.props.pages,
        currentPage: this.props.pages[this.state.pageIndex],
        onPageChange: this.handlePageChange
      },
      this.state.userPlayerSettings
    );
  }

  private setIframeRef = (ref: HTMLIFrameElement): void => {
    if (this.state.iframeRef) {
      return;
    }

    this.state.delayedPlayerController.setPlayer(
      new PlayerController({
        contentWindow: ref.contentWindow!,
        timelineManager: this.state.timelineManager,
        userPlayerSettings: this.state.userPlayerSettings
      })
    );

    this.state.delayedPlayerController.play();

    this.setState({ iframeRef: ref });
  };

  private onFrameRender = async (frameRenderedEvent: FrameRenderedEvent) => {
    this.setState({ frameRenderedEvent });
  };

  private handlePageChange = (pageIndex: number) => {
    this.setState({
      pageIndex
    });
  };
}
