import React from "react";
import { GetCurrentUserForOnlyAuthedComponent } from "testly-web/queries";
import { LoadingBar } from "../LoadingBar";

const LOGIN_URL = process.env.REACT_APP_LOGIN_URL as string;

export const LoadCurrentUser: React.SFC = ({ children }) => {
  return (
    <GetCurrentUserForOnlyAuthedComponent>
      {({ loading, data, client }) => {
        const authed = data && data.currentUser !== null;

        if (!loading && !authed) {
          window.location.replace(LOGIN_URL);

          return;
        }

        if (data && data.currentUser) {
          client.writeData({ data: { currentUserId: data.currentUser.id } });
        }

        return (
          <>
            <LoadingBar show={loading} />
            {loading || !authed ? null : children}
          </>
        );
      }}
    </GetCurrentUserForOnlyAuthedComponent>
  );
};
