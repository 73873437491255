import { capitalize } from "lodash";
import React from "react";
import { CheckboxInFilter } from "testly-web/components/filters/AdvancedFilter/CheckboxInFilter";
import { handleInFilterChange } from "testly-web/components/filters/AdvancedFilter/inFilter";
import { OnFilterChange } from "testly-web/components/filters/AdvancedFilter/types";
import { SplitTestFilter, SplitTestStatus } from "testly-web/queries";

const deviceOptions = Object.values(SplitTestStatus).map((value: string) => ({
  name: capitalize(value),
  value
}));

const filterName = "statusIn";

export const statusFilter = (
  filter: SplitTestFilter,
  onFilterChange: OnFilterChange<SplitTestFilter>
) => {
  const deviceTypeIn = filter[filterName] || [];
  const onChange = handleInFilterChange(filter, filterName, onFilterChange);

  return (
    <CheckboxInFilter
      key={filterName}
      name="Status"
      options={deviceOptions.map(({ name, value }) => ({
        name,
        value,
        selected: deviceTypeIn.includes(value as SplitTestStatus)
      }))}
      onChange={onChange}
    />
  );
};
