import React from "react";
import { CheckboxFilter } from "./CheckboxFilter";
import { CheckboxOption } from "./CheckboxOption";
import { OptionType } from "./types";

export class CheckboxInFilter extends React.Component<{
  onChange: (option: OptionType) => void;
  name: string;
  options: OptionType[];
}> {
  public render() {
    const { options, onChange, name } = this.props;

    return (
      <CheckboxFilter
        name={name}
        options={options}
        optionRender={option => (
          <CheckboxOption
            key={option.value}
            option={option}
            onChange={() => onChange(option)}
          />
        )}
      />
    );
  }
}
