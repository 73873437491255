import React from "react";
import ReactApollo from "react-apollo";
import { client } from "testly-web/client";
import { onMutationError } from "testly-web/components/onFail";
import {
  DeleteProjectComponent,
  DeleteProjectMutation as GeneratedDeleteProjectMutation,
  DeleteProjectVariables
} from "testly-web/queries";

export const DeleteProjectMutation: React.SFC<
  Partial<
    ReactApollo.MutationProps<
      GeneratedDeleteProjectMutation,
      DeleteProjectVariables
    >
  >
> = props => (
  <DeleteProjectComponent
    {...props}
    onCompleted={async (...args) => {
      await client.resetStore();
      if (props.onCompleted) {
        props.onCompleted(...args);
      }
    }}
    onError={onMutationError}
  />
);
