import { findLast, fromPairs, groupBy, intersection } from "lodash";
import {
  CssRuleMutated,
  DomMutated,
  MouseClicked,
  MouseMoved,
  PageVisited,
  ScrollPositionChanged,
  SessionEventType,
  WindowSizeChanged
} from "session-player/eventTypes";
import { Frame, FrameTime } from "session-player/playback/types";
import { ScrollPosition } from "session-player/siteTypes";
import { PlayingState, PlayingStateName } from "./PlaybackManager";
import { TimelineManager } from "./TimelineManager";

const statesThatProducesNewFrame = [
  PlayingStateName.Playing,
  PlayingStateName.Seeking,
  PlayingStateName.SeekingPlay,
  PlayingStateName.TimelineChange
];

export class FrameProducer {
  public timelineManager: TimelineManager;
  private previousFrame?: Frame;

  constructor(timelineManager: TimelineManager) {
    this.timelineManager = timelineManager;
  }

  public produce(
    newState: PlayingState,
    previousState: PlayingState
  ): Frame | undefined {
    this.previousFrame = this.calcNextFrame(
      newState,
      previousState,
      this.previousFrame
    );

    return this.previousFrame;
  }

  private calcNextFrame = (
    newState: PlayingState,
    previousState: PlayingState,
    previousFrame: Frame | undefined
  ) => {
    if (!statesThatProducesNewFrame.includes(newState.name)) {
      if (previousFrame) {
        previousFrame.isConsecutiveFrame = true;
        previousFrame.likeThePreviousFrame = true;

        return previousFrame;
      } else {
        return undefined;
      }
    }

    const allEvents = this.timelineManager.getAllEvents();

    const beforeCurrentPlayTimeEvents = allEvents.filter(
      ({ time }) => time <= newState.playTime
    );

    const pageVisitedEvent = findLast(
      beforeCurrentPlayTimeEvents,
      e => e.eventType === SessionEventType.PageVisited
    ) as PageVisited;

    const frameTime: FrameTime = {
      // `Math.max` cause we don't want to include mutations from previous snapshot
      fromTime:
        previousState.playTime > newState.playTime
          ? pageVisitedEvent.time
          : Math.max(previousState.playTime, pageVisitedEvent.time),
      toTime: newState.playTime
    };

    const isConsecutiveFrame =
      previousFrame !== undefined &&
      previousFrame.frameTime.toTime <= frameTime.fromTime;

    const isNewSnapshot = previousFrame
      ? pageVisitedEvent.data.domSnapshot !== previousFrame.lastDomSnapshot
      : true;

    // Actually newState.playTime === frameTime.toTime
    const beforeToTimeEvents = beforeCurrentPlayTimeEvents;

    const snapshotToTimeEvents = beforeToTimeEvents.filter(
      ({ time }) => pageVisitedEvent.time <= time && time <= frameTime.toTime
    );

    const inFrameTimeEvents = snapshotToTimeEvents.filter(
      ({ time }) => frameTime.fromTime <= time && time <= frameTime.toTime
    );

    const snapshotMutationEvents = snapshotToTimeEvents.filter(
      event => event.eventType === SessionEventType.DomMutated
    ) as DomMutated[];

    const snapshotMutationInFrameTimeEvents = inFrameTimeEvents.filter(
      event => event.eventType === SessionEventType.DomMutated
    ) as DomMutated[];

    const cssRuleMutationEvents = snapshotToTimeEvents.filter(
      event => event.eventType === SessionEventType.CssRuleMutated
    ) as CssRuleMutated[];

    const cssRuleMutationInFrameTimeEvents = inFrameTimeEvents.filter(
      event => event.eventType === SessionEventType.CssRuleMutated
    ) as CssRuleMutated[];

    const lastMouseMovedEvent = findLast(
      beforeToTimeEvents,
      event => event.eventType === SessionEventType.MouseMoved
    ) as MouseMoved | undefined;

    const lastWindowSizeChangedEvent = findLast(
      beforeToTimeEvents,
      event => event.eventType === SessionEventType.WindowSizeChanged
    ) as WindowSizeChanged;

    const scrollChangedEvents = beforeToTimeEvents.filter(
      event => event.eventType === SessionEventType.ScrollPositionChanged
    ) as ScrollPositionChanged[];

    const mouseClickedEvent =
      newState.name === PlayingStateName.Seeking
        ? undefined
        : (findLast(
            inFrameTimeEvents,
            event => event.eventType === SessionEventType.MouseClicked
          ) as MouseClicked | undefined);

    return {
      cssRulesMutations: cssRuleMutationEvents.map(e => e.data),
      cssRulesMutationsInFrameTime: this.removeSameMutations(
        isConsecutiveFrame,
        previousFrame && previousFrame.cssRulesMutationsInFrameTime,
        cssRuleMutationInFrameTimeEvents.map(e => e.data)
      ),
      snapshotMutations: snapshotMutationEvents.map(e => e.data),
      snapshotMutationsInFrameTime: this.removeSameMutations(
        isConsecutiveFrame,
        previousFrame && previousFrame.snapshotMutationsInFrameTime,
        snapshotMutationInFrameTimeEvents.map(e => e.data)
      ),
      mouseClickInFrameTime: mouseClickedEvent && mouseClickedEvent.data,
      lastDocType: pageVisitedEvent.data.docType,
      lastDomSnapshot: pageVisitedEvent.data.domSnapshot,
      lastScrollsPositions: this.groupScrolls(scrollChangedEvents),
      lastMousePosition: (mouseClickedEvent && mouseClickedEvent.data) ||
        (lastMouseMovedEvent && lastMouseMovedEvent.data) || {
          x: 0,
          y: 0,
          pageX: 0,
          pageY: 0,
          animationDuration: 0
        },
      lastUrl: pageVisitedEvent.data.url,
      lastWindowSize: lastWindowSizeChangedEvent.data,
      isConsecutiveFrame,
      frameTime,
      isNewSnapshot,
      likeThePreviousFrame: false
    };
  };

  private removeSameMutations<T>(
    isConsecutiveFrame: boolean,
    previousMutations: T[] | undefined,
    currentMutations: T[]
  ): T[] {
    if (isConsecutiveFrame && previousMutations) {
      const sameMutations = intersection(previousMutations, currentMutations);

      return sameMutations.length > 0
        ? currentMutations.filter(mutation => sameMutations.includes(mutation))
        : currentMutations;
    } else {
      return currentMutations;
    }
  }

  private groupScrolls(
    scrollChangedEvents: ScrollPositionChanged[]
  ): Record<string, ScrollPosition> {
    const groupedScrolls: Record<number, ScrollPositionChanged[]> = groupBy(
      scrollChangedEvents,
      (e: ScrollPositionChanged) => e.data.id
    );

    return fromPairs(
      Object.entries(groupedScrolls).map(
        ([id, scrollGroup]: [string, ScrollPositionChanged[]]): [
          string,
          ScrollPosition
        ] => [id, scrollGroup[scrollGroup.length - 1].data]
      )
    );
  }
}
