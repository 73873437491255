import React, { useState } from "react";
import CustomScroll from "react-custom-scroll";
import styled, { css } from "styled-components";

import { CloseIcon } from "testly-web/icons/CloseIcon";
import { AccountSettingsBilling } from "./AccountSettingsBilling";
import { AccountSettingsNav } from "./AccountSettingsNav";
import { AccountSettingsPasswordForm } from "./AccountSettingsPasswordForm";
import { AccountSettingsProfileForm } from "./AccountSettingsProfileForm";

import { MutationFn } from "react-apollo";
import {
  GetDataForAccountSettingsCurrentUser,
  UpdatePasswordAtAccountSettingsMutation,
  UpdatePasswordAtAccountSettingsVariables,
  UpdateProfileAtAccountSettingsMutation,
  UpdateProfileAtAccountSettingsVariables
} from "testly-web/queries";
import {
  scrollableContentVerticalGap,
  sidebarHeaderHeight,
  sidebarNavHeight,
  sidebarWidth
} from "./constants";
import { ToggleAccountSettings } from "./ToggleAccountSettings";

const AccountSettingsSidebar = styled.div<{ active: boolean }>`
  width: ${sidebarWidth}px;
  min-height: 100%;

  position: fixed;
  right: 0;
  top: 0;

  background: #fff;
  z-index: 1000;
  transition: transform 0.25s, box-shadow 0.1s;

  ${({ active }) => css`
    transform: translateX(${active ? 0 : "100%"});
    box-shadow: ${active ? "-10px 0 60px rgba(0, 0, 0, 0.25)" : "none"};
  `}
`;

const AccountSettingsHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 40px;
  height: ${sidebarHeaderHeight}px;

  border-bottom: 1px solid #ecedee;

  > h3 {
    margin: 0;

    font-size: 20px;
    font-weight: 500;
  }
`;

const ContentWrapper = styled.div`
  max-height: calc(
    100vh -
      ${sidebarHeaderHeight +
        sidebarNavHeight +
        scrollableContentVerticalGap * 2}px
  );
`;

export const AccountSettings: React.SFC<{
  active: boolean;
  user: GetDataForAccountSettingsCurrentUser;
  updateProfile: MutationFn<
    UpdateProfileAtAccountSettingsMutation,
    UpdateProfileAtAccountSettingsVariables
  >;
  updatePassword: MutationFn<
    UpdatePasswordAtAccountSettingsMutation,
    UpdatePasswordAtAccountSettingsVariables
  >;
}> = ({ active, user, updateProfile, updatePassword }) => {
  const [activeSettingsTab, setActiveSettingsTab] = useState<string>("profile");

  const content = (() => {
    switch (activeSettingsTab) {
      case "password":
        return (
          <AccountSettingsPasswordForm
            updatePassword={updatePassword}
            userId={user.id}
          />
        );
      case "billing":
        return <AccountSettingsBilling />;
      default:
        return (
          <AccountSettingsProfileForm
            initialValues={user}
            updateProfile={updateProfile}
          />
        );
    }
  })();

  return (
    <AccountSettingsSidebar active={active}>
      <AccountSettingsHeader>
        <h3>Account Settings</h3>
        <ToggleAccountSettings active={false}>
          <CloseIcon width={18} height={18} style={{ cursor: "pointer" }} />
        </ToggleAccountSettings>
      </AccountSettingsHeader>
      <AccountSettingsNav
        activeSettingsTab={activeSettingsTab}
        switchTab={setActiveSettingsTab}
      />
      <CustomScroll>
        <ContentWrapper>{content}</ContentWrapper>
      </CustomScroll>
    </AccountSettingsSidebar>
  );
};
