import React from "react";
import styled, { css } from "styled-components/macro";

const Arrow = styled.div`
  display: inline-block;
  width: 8px;
  height: 8px;
  border: solid #00ac69;
  border-width: 0 2px 2px 0;
`;

// eslint-disable-next-line no-unexpected-multiline
const PageButton = styled.button<{
  direction: "left" | "right";
}>`
  display: inline-flex;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.2px;
  color: #393839;
  text-transform: uppercase;
  cursor: pointer;

  background-color: #ffffff;
  border: none;
  outline: none;
  padding: 10px 20px;

  align-items: center;

  ${({ direction }) =>
    direction === "left" &&
    css`
      ${Arrow} {
        transform: rotate(135deg);
        margin-right: 22px;
      }
    `}

  ${({ direction }) =>
    direction === "right" &&
    css`
      ${Arrow} {
        transform: rotate(-45deg);
        margin-left: 22px;
      }
    `}

  & + & {
    margin-left: 20px;
  }
`;

const SimplePaginatorStyled = styled.div`
  display: flex;
`;

export const SimplePaginator: React.SFC<{
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  className?: string;
  onNextPageClick(): void;
  onPreviousPageClick(): void;
}> = ({
  hasNextPage,
  hasPreviousPage,
  onNextPageClick,
  onPreviousPageClick,
  className
}) => (
  <SimplePaginatorStyled className={className}>
    {hasPreviousPage && (
      <PageButton onClick={onPreviousPageClick} direction="left">
        <Arrow />
        previous
      </PageButton>
    )}
    {hasNextPage && (
      <PageButton onClick={onNextPageClick} direction="right">
        next page
        <Arrow />
      </PageButton>
    )}
  </SimplePaginatorStyled>
);
