import React from "react";

export const ArrowDownIcon: React.SFC<
  React.SVGProps<SVGSVGElement>
> = props => (
  // tslint:disable:max-line-length
  <svg width="14" height="14" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7 14.021a6.982 6.982 0 0 0 4.95-2.059 7.004 7.004 0 0 0 0-9.9 7.004 7.004 0 0 0-9.9 0 7.004 7.004 0 0 0 0 9.9A6.982 6.982 0 0 0 7 14.022zm0-1.402a5.583 5.583 0 0 1-3.965-1.643 5.596 5.596 0 0 1 0-7.93 5.596 5.596 0 0 1 7.93 0 5.596 5.596 0 0 1 0 7.93A5.583 5.583 0 0 1 7 12.62zm2.804-2.804V4.208H8.402v3.198L4.7 3.704 3.692 4.712l3.702 3.702H4.196v1.401h5.608z"
      fill="#FF5858"
    />
  </svg>
);
