import { IdentifiableVNode, NodeId, NodePath } from "../types";
import { mapChildNode } from "./mapChildNode";

const putChildren = (
  currentElement: IdentifiableVNode,
  nodeToPut: IdentifiableVNode,
  previousSiblingId: NodeId | undefined
): IdentifiableVNode[] => {
  if (currentElement.children && currentElement.children.length !== 0) {
    if (previousSiblingId !== undefined) {
      return mapChildNode(
        currentElement,
        [previousSiblingId],
        findedChildNode => [findedChildNode, { ...nodeToPut }]
      ).children;
    } else {
      return [{ ...nodeToPut }, ...currentElement.children];
    }
  } else {
    if (previousSiblingId !== undefined) {
      throw new Error(
        `Node(id:${JSON.stringify(
          currentElement
        )}) has children, while previousSiblingId is specified!`
      );
    }

    return [{ ...nodeToPut }];
  }
};

// NOTE: it will not provide deep clone of new dom,
// instead it will clone only parent of changed element
const putNodeWithoutRoot = (
  currentElement: IdentifiableVNode,
  nodeToPut: IdentifiableVNode,
  parentPath: NodePath,
  previousSiblingId: NodeId | undefined
): IdentifiableVNode => {
  if (parentPath.length === 0) {
    return {
      ...currentElement,
      children: putChildren(currentElement, nodeToPut, previousSiblingId)
    };
  } else {
    return mapChildNode(
      currentElement,
      parentPath,
      (findedChildNode, newPath) =>
        putNodeWithoutRoot(
          findedChildNode,
          nodeToPut,
          newPath,
          previousSiblingId
        )
    );
  }
};

export const putNode = (
  currentElement: IdentifiableVNode,
  nodeToPut: IdentifiableVNode,
  parentPath: NodePath,
  previousSiblingId: NodeId | undefined
) => {
  if (parentPath[0] !== currentElement.data.nodeId) {
    throw new Error("First element should be root");
  }

  return putNodeWithoutRoot(
    currentElement,
    nodeToPut,
    parentPath.slice(1),
    previousSiblingId
  );
};
