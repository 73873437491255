import { Form, Formik, FormikActions } from "formik";
import React from "react";
import styled from "styled-components/macro";
import { Error, Input } from "testly-web/components/Form";
import * as Yup from "yup";

const Caption = styled.div`
  color: #a7a9ae;
`;

const NewProjectFormStyled = styled(Form)`
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;

  ${Error}, ${Caption} {
    margin-top: 3px;
    font-size: 12px;
  }
`;

// position: absoulte is very important
// Without it this form will expand width of dropdown
const NewProjectFormContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const NewProjectFormRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WebsiteInput = styled(Input)`
  width: 100%;
  height: 45px;
  margin-right: 16px;
`;

const SubmitBtn = styled.button`
  box-sizing: border-box;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  color: #00ac69;
  padding: 0;

  cursor: pointer;

  border: none;
  background: none;
  outline: none;
`;

interface FormValues {
  domain: string;
}

interface NewProjectFormProps {
  onSubmit(values: FormValues, actions: FormikActions<FormValues>): void;
}

export class NewProjectForm extends React.Component<NewProjectFormProps> {
  private domainInputRef: React.RefObject<HTMLInputElement>;

  constructor(props: NewProjectFormProps) {
    super(props);

    this.domainInputRef = React.createRef<HTMLInputElement>();
  }

  public componentDidMount() {
    if (this.domainInputRef.current) {
      this.domainInputRef.current.focus();
    }
  }

  public render() {
    const { onSubmit } = this.props;

    return (
      <Formik
        initialValues={{ domain: "" }}
        validationSchema={Yup.object().shape({
          domain: Yup.string().required("Can't be blank")
        })}
        onSubmit={onSubmit}
      >
        {props => {
          const {
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur
          } = props;

          return (
            <NewProjectFormStyled>
              <NewProjectFormContent>
                <NewProjectFormRow>
                  <WebsiteInput
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="domain"
                    ref={
                      this.domainInputRef as React.RefObject<HTMLInputElement> &
                        string
                    }
                    withError={errors.domain && touched.domain ? true : false}
                  />

                  <SubmitBtn type="submit" disabled={isSubmitting}>
                    Add
                  </SubmitBtn>
                </NewProjectFormRow>
                {errors.domain && touched.domain ? (
                  <Error>{errors.domain}</Error>
                ) : (
                  <Caption>Is Required</Caption>
                )}
              </NewProjectFormContent>
            </NewProjectFormStyled>
          );
        }}
      </Formik>
    );
  }
}
