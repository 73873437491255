import React from "react";
import { ProgressBar } from "testly-web/components/split-test-form/ProgressBar";
import { SplitTestFormStep } from "testly-web/components/split-test-form/schemas";
import { DetailsFormContainer } from "./DetailsFormContainer";

export const SplitTestNewPage: React.SFC<{
  projectId: string;
  testIdeaId: string | null;
}> = ({ projectId, testIdeaId }) => (
  <>
    <ProgressBar
      currentStep={SplitTestFormStep.Details}
      doneSteps={{
        [SplitTestFormStep.Details]: false,
        [SplitTestFormStep.Variations]: false,
        [SplitTestFormStep.Goals]: false,
        [SplitTestFormStep.CodeInstallationCheck]: false,
        [SplitTestFormStep.Settings]: false
      }}
      onStepClick={() => ""}
    />
    <DetailsFormContainer projectId={projectId} testIdeaId={testIdeaId} />
  </>
);
