import { FormikProps } from "formik";
import React from "react";
import styled from "styled-components/macro";
import { SplitTestFinishConditionType } from "testly-web/queries";
import { RadionInput } from "../../Form/Radio";
import { SettingsFormValues } from "../schemas";

const RadionInputStyled = styled(RadionInput)`
  margin-left: 18px;
`;

export const ConditionTypeSelect: React.SFC<{
  selectedConditionType: SplitTestFinishConditionType;
  formProps: FormikProps<SettingsFormValues>;
}> = ({ selectedConditionType, formProps }) => (
  <>
    {[
      [SplitTestFinishConditionType.GoalConversions, "Goal Visits"],
      [SplitTestFinishConditionType.Visits, "Visits"],
      [SplitTestFinishConditionType.DaysPassed, "Passed Days"]
    ].map(([value, label], i) => (
      <RadionInputStyled
        key={i}
        name="finishCondition.conditionType"
        label={label}
        value={value}
        checked={selectedConditionType === value}
        onChange={formProps.handleChange}
        onBlur={formProps.handleBlur}
      />
    ))}
  </>
);
