import React from "react";
import styled from "styled-components/macro";

const ContextMenuStyled = styled.div`
  width: 4px;
  height: 20px;
`;

const Dot = styled.div`
  width: 4px;
  height: 4px;
  background-color: #000;

  margin-top: 4px;

  &:first-child {
    margin-top: 0px;
  }
`;

export const ContextMenuIcon: React.SFC<
  React.HTMLAttributes<HTMLDivElement>
> = props => (
  <ContextMenuStyled {...props}>
    <Dot />
    <Dot />
    <Dot />
  </ContextMenuStyled>
);
