import { fromPairs } from "lodash";
import { SafeStylesheet } from "session-player/stylesheet/SafeStylesheet";
import { h } from "snabbdom";
import vnode, { VNode as SnabbdomVNode } from "snabbdom/vnode";

export enum SimpleNodeType {
  Element = 1,
  Text = 3,
  Comment = 8
}

export interface NodeAttribute {
  value: string | null;
  name: string;
}

export interface SimpleTextNode {
  data: string;
  node_type: SimpleNodeType.Text | SimpleNodeType.Comment;
  id: NodeId;
}

export interface SimpleElementNode {
  tag_name: string;
  node_type: SimpleNodeType.Element;
  attributes?: NodeAttribute[];
  child_nodes?: SimpleNode[];
  sheet_rules?: string[];
  id: NodeId;
}

export type SimpleNode = SimpleTextNode | SimpleElementNode;
export type NodeId = number;
export type NodePath = NodeId[];

const fixAttrs = (tagName: string, attrs: NodeAttribute[]) => {
  if (tagName === "script" || tagName === "iframe") {
    return {};
  }

  const data = attrs
    .map(({ name, value }) => {
      // https://stackoverflow.com/a/33068867/3872807
      if (
        name.includes('"') ||
        name.includes("'") ||
        name.includes(" ") ||
        name.includes("<") ||
        name.includes("=") ||
        name.includes(">") ||
        name.includes("/") ||
        name.includes(";")
      ) {
        return undefined;
      }
      if (name === "integrity" || name === "crossorigin") {
        return undefined;
      }
      if (tagName === "svg" && (name === "xmlns:xlink" || name === "xmlns")) {
        return undefined;
      }

      return [name, value];
    })
    .concat(
      tagName === "svg"
        ? [
            ["xmlns", "http://www.w3.org/2000/svg"]
            // ["xmlns:xlink", "http://www.w3.org/1999/xlink"]
          ]
        : []
    )
    .filter(attr => attr) as Array<[string, string]>;

  return fromPairs(data);
};

const fixChildNodes = (
  tagName: string,
  nodes: SimpleNode[] | undefined
): SimpleNode[] => {
  if (tagName === "noscript") {
    return [];
  } else {
    return nodes || [];
  }
};

export function converToSnabbdomVNode(simpleNode: SimpleNode): SnabbdomVNode {
  switch (simpleNode.node_type) {
    case SimpleNodeType.Element:
      // TODO: add validation on backend that tag_name is prenset
      // sometimes it is null
      const tagName = (simpleNode.tag_name || "div").toLocaleLowerCase();

      return h(
        tagName,
        {
          nodeId: simpleNode.id,
          attrs: fixAttrs(tagName, simpleNode.attributes || []),
          key: tagName === "html" ? undefined : simpleNode.id,
          sheetRules: simpleNode.sheet_rules,
          hook:
            tagName === "style" && simpleNode.sheet_rules
              ? {
                  insert: ({ elm }) => {
                    if (!simpleNode.sheet_rules) {
                      return;
                    }

                    if (elm instanceof HTMLStyleElement && elm.sheet) {
                      const safeStylesheet = new SafeStylesheet(
                        elm.sheet as CSSStyleSheet
                      );

                      safeStylesheet.resetAllRules();
                      simpleNode.sheet_rules.forEach((rule, i) => {
                        safeStylesheet.insertRule(rule, i);
                      });
                    } else {
                      console.error(
                        elm,
                        "is not HTMLStyleElement instance or doesn't have .sheet"
                      );
                    }
                  }
                }
              : {}
        },
        fixChildNodes(tagName, simpleNode.child_nodes).map(
          converToSnabbdomVNode
        )
      );
    case SimpleNodeType.Text:
      return vnode(
        undefined,
        {
          nodeId: simpleNode.id,
          key: simpleNode.id
        },
        undefined,
        simpleNode.data ? simpleNode.data : "",
        undefined
      );
    case SimpleNodeType.Comment:
      return vnode(
        "!",
        { nodeId: simpleNode.id, key: simpleNode.id },
        undefined,
        simpleNode.data ? simpleNode.data : "",
        undefined
      );
    default:
      throw new Error(`Unknown node ${simpleNode}`);
  }
}
