import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { Button } from "testly-web/components/Button";
import { Paginator } from "testly-web/components/Paginator";
import {
  BodyCell,
  BodyCellWithImage,
  HeadCell,
  Row,
  SecondTextLine,
  SortableHeadCell,
  Table,
  TableEmptyCell
} from "testly-web/components/Table";
import { CalendarIcon } from "testly-web/icons/CalendarIcon";
import { EyeIcon } from "testly-web/icons/EyeIcon";
import { paths } from "testly-web/paths";
import { DeviceType, HeatmapPageOrderField } from "testly-web/queries";
import { DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT } from "testly-web/settings";
import { Filter } from "./Filter";
import { FilterState, OrderState } from "./HeatmapIndexPageContainer";

interface Heatmap {
  id: string;
  url: string;
  createdAt: string;
  viewsCount: {
    total: number;
    desktop: number;
    mobile: number;
    tablet: number;
  };
}

const ActionButton = styled(Button)`
  width: 200px;
  float: right;
`;

const HeatmapIndexPageStyled = styled.div`
  padding: 40px;
`;

const TableStyled = styled(Table)`
  table-layout: fixed;
`;

const ActionCell = styled(BodyCell)`
  width: 250px;
`;

const ActionHeadCell = styled(HeadCell)`
  width: 250px;
`;

const StyledPaginator = styled(Paginator)`
  margin-top: 20px;
`;

export const HeatmapIndexPage: React.SFC<{
  heatmaps: Heatmap[];
  projectId: string;
  pageCount: number;
  page: number;
  onPageChange: (data: { selected: number }) => void;
  onFilterChange: (filter: FilterState) => void;
  filter: FilterState;
  orderState: OrderState;
  onOrderStateChange: (newState: OrderState) => void;
}> = ({
  heatmaps,
  projectId,
  pageCount,
  onPageChange,
  page,
  filter,
  onFilterChange,
  orderState,
  onOrderStateChange
}) => {
  return (
    <>
      <Filter filter={filter} onFilterChange={onFilterChange} />
      <HeatmapIndexPageStyled>
        <TableStyled>
          <thead>
            <Row hover={false}>
              <HeadCell>Page</HeadCell>
              <SortableHeadCell
                onDirectionChange={newDirection =>
                  onOrderStateChange({
                    field: HeatmapPageOrderField.TotalViewsCount,
                    direction: newDirection
                  })
                }
                direction={
                  orderState.field === HeatmapPageOrderField.TotalViewsCount
                    ? orderState.direction
                    : undefined
                }
              >
                Views
              </SortableHeadCell>
              <SortableHeadCell
                onDirectionChange={newDirection =>
                  onOrderStateChange({
                    field: HeatmapPageOrderField.CreatedAt,
                    direction: newDirection
                  })
                }
                direction={
                  orderState.field === HeatmapPageOrderField.CreatedAt
                    ? orderState.direction
                    : undefined
                }
              >
                Created On
              </SortableHeadCell>
              <ActionHeadCell />
            </Row>
          </thead>
          <tbody>
            {heatmaps.length === 0 && (
              <Row hover={false}>
                <TableEmptyCell>No heatmaps found.</TableEmptyCell>
              </Row>
            )}
            {heatmaps.map((heatmap, i) => {
              const heatmapDeviceType = (() => {
                if (heatmap.viewsCount.desktop !== 0) {
                  return DeviceType.Desktop;
                } else if (heatmap.viewsCount.tablet !== 0) {
                  return DeviceType.Tablet;
                } else {
                  return DeviceType.Mobile;
                }
              })();

              return (
                <Row hover={false} key={heatmap.id}>
                  <BodyCell canContainLongText={true}>{heatmap.url}</BodyCell>
                  <BodyCellWithImage image={EyeIcon}>
                    {heatmap.viewsCount.total}
                  </BodyCellWithImage>
                  <BodyCellWithImage image={CalendarIcon}>
                    {moment(heatmap.createdAt).format(DEFAULT_DATE_FORMAT)}
                    <SecondTextLine>
                      {moment(heatmap.createdAt).format(DEFAULT_TIME_FORMAT)}
                    </SecondTextLine>
                  </BodyCellWithImage>
                  <ActionCell>
                    <Link
                      to={{
                        pathname: paths.heatmapShowPath({
                          projectId,
                          heatmapPageId: heatmap.id
                        }),
                        state: {
                          previousPath: `${window.location.pathname}${
                            window.location.search
                          }`
                        },
                        search: `?deviceType=${heatmapDeviceType}`
                      }}
                    >
                      <ActionButton action="secondary" size="small">
                        View Heatmap
                      </ActionButton>
                    </Link>
                  </ActionCell>
                </Row>
              );
            })}
          </tbody>
        </TableStyled>

        {pageCount > 1 && (
          <StyledPaginator
            pageCount={pageCount}
            onPageChange={onPageChange}
            page={page}
          />
        )}
      </HeatmapIndexPageStyled>
    </>
  );
};
