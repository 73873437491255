import React from "react";

export const PollAnswerIcon: React.SFC<
  React.SVGProps<SVGSVGElement>
> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="#A7A9AE"
      d="M1.6 0C.726 0 0 .726 0 1.6v9.6c0 .874.726 1.6 1.6 1.6h11.2L16 16l-.01-14.402C15.99.724 15.265 0 14.39 0H1.6zm0 1.6h12.79l.007 10.534-.935-.934H1.6V1.6zM3.998 4v1.6h8V4h-8zm0 3.2v1.6h5.6V7.2h-5.6z"
    />
  </svg>
);
