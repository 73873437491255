import { Formik, FormikActions, FormikProps } from "formik";
import H from "history";
import React from "react";
import { MutationFn } from "react-apollo";
import { RouteComponentProps } from "react-router";
import { toast } from "react-toastify";
import { SetCurrentProjectId } from "testly-web/components/CurrentProjectId/SetCurrentProjectId";
import {
  convertFromDto,
  convertToDto
} from "testly-web/components/goal/DTOConverter";
import { loadingRender } from "testly-web/components/loadingRender";
import { SetNavbar } from "testly-web/components/Navbar/SetNavbar";
import {
  onFormSubmitFail,
  onMutationError
} from "testly-web/components/onFail";
import { paths } from "testly-web/paths";
import {
  GetDataForGoalEditPageComponent,
  UpdateGoalComponent,
  UpdateGoalMutation,
  UpdateGoalVariables
} from "testly-web/queries";
import { goalSchema, PathGoalForm } from "../../components/goal/pathGoal";
import { GoalEditPage } from "./GoalEditPage";

const handleSubmit = (
  updateGoal: MutationFn<UpdateGoalMutation, UpdateGoalVariables>,
  pushRoute: H.History["push"],
  projectId: string,
  goalId: string
) => {
  return async (values: PathGoalForm, actions: FormikActions<PathGoalForm>) => {
    const response = await updateGoal({
      variables: { params: convertToDto(values), id: goalId }
    });

    if (response && response.data && response.data.updateGoal) {
      const { successful, result } = response.data.updateGoal;

      if (successful && result) {
        toast.success("The goal is updated.");
        pushRoute(paths.goalListPath({ projectId }));
        actions.resetForm();
      } else {
        onFormSubmitFail(values, response.data);
      }

      actions.setSubmitting(false);
    }
  };
};

export const GoalEditPageContainer: React.SFC<
  RouteComponentProps<{
    projectId: string;
    goalId: string;
  }>
> = ({
  match: {
    params: { projectId, goalId }
  },
  history: { push, replace }
}) => (
  <>
    <GetDataForGoalEditPageComponent
      variables={{ projectId, goalId }}
      fetchPolicy="network-only"
    >
      {loadingRender("currentUser.project.goal", ({ data }) => (
        <UpdateGoalComponent onError={onMutationError}>
          {udpateGoal => (
            <Formik<PathGoalForm>
              initialValues={convertFromDto(data!.currentUser!.project!.goal!)}
              validationSchema={goalSchema}
              onSubmit={handleSubmit(udpateGoal, push, projectId, goalId)}
            >
              {(props: FormikProps<PathGoalForm>) => (
                <GoalEditPage
                  formProps={props}
                  goal={data!.currentUser!.project!.goal!}
                  afterDelete={() => {
                    replace(paths.goalListPath({ projectId }));
                  }}
                />
              )}
            </Formik>
          )}
        </UpdateGoalComponent>
      ))}
    </GetDataForGoalEditPageComponent>

    <SetCurrentProjectId id={projectId} />
    <SetNavbar
      header="Update Goal"
      backPath={paths.goalListPath({ projectId })}
    />
  </>
);
