import React from "react";

export const TrackingIcon = () => (
  // tslint:disable:max-line-length
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="38"
    height="38"
    viewBox="0 0 38 38"
  >
    <path
      fill="#393839"
      fillRule="nonzero"
      d="M17.538 0v3.014C9.837 3.711 3.711 9.843 3.014 17.538H0v2.924h3.014c.697 7.695 6.829 13.827 14.524 14.524V38h2.924v-3.014c7.695-.697 13.827-6.829 14.524-14.524H38v-2.924h-3.014c-.697-7.695-6.823-13.827-14.524-14.524V0h-2.924zm0 5.892v2.877h2.924V5.892c6.125.662 10.933 5.52 11.6 11.646h-2.831v2.924h2.877a13.152 13.152 0 0 1-11.646 11.646v-2.877h-2.924v2.877A13.152 13.152 0 0 1 5.892 20.462h2.877v-2.924H5.937c.668-6.125 5.476-10.984 11.601-11.646zM19 13.154A5.843 5.843 0 0 0 13.154 19 5.843 5.843 0 0 0 19 24.846 5.843 5.843 0 0 0 24.846 19 5.843 5.843 0 0 0 19 13.154z"
    />
  </svg>
);
