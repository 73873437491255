import React from "react";
import styled, { css } from "styled-components/macro";
import { HighlightHiddenText } from "testly-web/components/HighlightHiddenText";
import { ArrowDownIcon } from "testly-web/icons/ArrowDownIcon";
import { ArrowUpIcon } from "testly-web/icons/ArrowUpIcon";
import { VariationWinnerIcon } from "testly-web/icons/VariationWinnerIcon";

export interface VariationType {
  id: string;
  name: string;
  url: string;
  control: boolean;
  conversion: ConversionType;
}

export interface ConversionType {
  conversionRate: number;
  visitsCount: number;
  isWinner: boolean;
  improvementRate: number | null;
  conversionsCount: number;
}

export const VariationStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-top: solid 1px #f0f1f1;
  border-bottom: solid 1px #f0f1f1;
  height: 50px;
  max-height: 50px;
  position: relative;

  & + & {
    margin-top: -1px;
  }
`;

const Description = styled.div<{ blank?: true }>`
  display: flex;

  flex-direction: column;
  justify-content: center;
  min-width: 0;
  padding-right: 10px;

  ${({ blank }) =>
    blank &&
    css`
      width: 100%;
    `}
`;

const DescriptionName = styled.div<{ blank?: true }>`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  color: #393839;
  min-width: 0;
  position: relative;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ blank }) =>
    blank &&
    css`
      max-width: 173px;
      min-width: 0px;
      height: 14px;
      background-color: #ecedee;
    `}
`;

const DescriptionUrl = styled.div<{ blank?: true }>`
  margin-top: 6px;
  font-family: Roboto;
  font-size: 12px;
  color: #a7a9ae;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ blank }) =>
    blank &&
    css`
      max-width: 144px;
      min-width: 0;
      height: 8px;
      background-color: #ecedee;
    `}
`;

const Stat = styled.div`
  display: flex;
  flex-direction: row;
`;

const StatColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & + & {
    margin-left: 15px;
  }
`;

const StatColumnName = styled.div<{ blank?: true }>`
  font-family: Roboto;
  font-size: 10px;
  letter-spacing: 1px;
  color: #a7a9ae;
  text-transform: uppercase;
  white-space: nowrap;

  ${({ blank }) =>
    blank &&
    css`
      background-color: #ecedee;
      height: 8px;
    `}
`;

type ImproveComparatorType = "better" | "worse" | "same" | "baseline";

const StatColumnValueStyled = styled.div<{ blank?: true }>`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.9px;
  color: #393839;
  margin-top: 5px;

  display: inline-flex;
  align-items: center;

  ${({ blank }) =>
    blank &&
    css`
      background-color: #ecedee;
      height: 14px;
    `}

  svg {
    margin-right: 5px;
  }
`;

const VariationWinnerStyled = styled(VariationWinnerIcon)`
  position: absolute;
  z-index: 1;
  top: -8px;
  left: -7px;
`;

// const BlankArrow = styled.div`
//   width: 14px;
//   height: 14px;
//   margin-right: 5px;
// `;

const StatColumnValue: React.SFC<
  React.HTMLAttributes<HTMLDivElement> & {
    improvementRate?: ImproveComparatorType;
    blank?: true;
  }
> = ({ improvementRate, blank, children, ...props }) => (
  <StatColumnValueStyled blank={blank} {...props}>
    {improvementRate === "better" && <ArrowUpIcon />}
    {improvementRate === "worse" && <ArrowDownIcon />}
    {children}
  </StatColumnValueStyled>
);

const conversionImproveComparator = (
  improvementRate: number | null
): ImproveComparatorType => {
  if (improvementRate === null) {
    return "baseline";
  }

  if (improvementRate > 0) {
    return "better";
  } else if (improvementRate < 0) {
    return "worse";
  } else {
    return "same";
  }
};

export const Variation: React.SFC<{ variation: VariationType }> = ({
  variation
}) => {
  const conversion = variation.conversion;

  return (
    <VariationStyled>
      {conversion.isWinner && <VariationWinnerStyled />}
      <Description>
        <HighlightHiddenText
          caption={variation.name || "Control"}
          style={{
            marginRight: "20px",
            fontWeight: 500,
            fontSize: "14px"
          }}
        >
          <DescriptionName>{variation.name}</DescriptionName>
        </HighlightHiddenText>
        <DescriptionUrl>{variation.url}</DescriptionUrl>
      </Description>
      <Stat>
        <StatColumn>
          <StatColumnName>Improvement</StatColumnName>
          <StatColumnValue>
            {conversion.improvementRate !== null
              ? `${
                  conversion.improvementRate > 0 ? "+" : ""
                }${conversion.improvementRate.toFixed(2)}%`
              : variation.control
              ? "Baseline"
              : "-"}
          </StatColumnValue>
        </StatColumn>

        <StatColumn>
          <StatColumnName>Conversion Rate</StatColumnName>
          <StatColumnValue
            improvementRate={conversionImproveComparator(
              conversion.improvementRate
            )}
          >
            {conversion.conversionRate.toFixed(2)}%
          </StatColumnValue>
        </StatColumn>

        <StatColumn>
          <StatColumnName>Conversions</StatColumnName>
          <StatColumnValue>{conversion.conversionsCount}</StatColumnValue>
        </StatColumn>

        <StatColumn>
          <StatColumnName>Visitors</StatColumnName>
          <StatColumnValue>{conversion.visitsCount}</StatColumnValue>
        </StatColumn>
      </Stat>
    </VariationStyled>
  );
};

export const BlankVariation = () => (
  <VariationStyled>
    <Description blank={true}>
      <DescriptionName blank={true} />
      <DescriptionUrl blank={true} />
    </Description>
    <Stat>
      <StatColumn>
        <StatColumnName blank={true} style={{ width: "81px" }} />
        <StatColumnValue blank={true} style={{ width: "56px" }} />
      </StatColumn>

      <StatColumn>
        <StatColumnName blank={true} style={{ width: "95px" }} />
        <StatColumnValue blank={true} style={{ width: "56px" }} />
      </StatColumn>

      <StatColumn>
        <StatColumnName blank={true} style={{ width: "51px" }} />
        <StatColumnValue blank={true} style={{ width: "35px" }} />
      </StatColumn>
    </Stat>
  </VariationStyled>
);
