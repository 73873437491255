import {
  OnErrorListener,
  PlayingState
} from "session-player/playback/PlaybackManager";
import {
  OnFrameRenderedListener,
  PlayerController
} from "session-player/PlayerController";

// Is needed when iframe is not initialized, but components alredy want to set event listeners
export class DelayedPlayerController {
  private playerController?: PlayerController;
  private onFrameRenderedListeners: OnFrameRenderedListener[] = [];
  private onErrorListeners: OnErrorListener[] = [];

  public playerSet() {
    return this.playerController !== undefined;
  }

  public resetPlayer() {
    if (this.playerController) {
      this.playerController.stop();
    }
    this.playerController = undefined;
  }

  public setPlayer(player: PlayerController): void {
    if (this.playerController) {
      this.playerController.stop();
    }

    this.playerController = player;
    player.onNewFrame(async (...args) => {
      await Promise.all(
        this.onFrameRenderedListeners.map(async listener => {
          return listener(...args);
        })
      );
    });
    player.onError(async (...args) => {
      this.onErrorListeners.forEach(listener => {
        listener(...args);
      });
    });
  }

  public onFrameRender(listener: OnFrameRenderedListener): void {
    this.onFrameRenderedListeners.push(listener);
  }

  public onError(listener: OnErrorListener): void {
    this.onErrorListeners.push(listener);
  }

  public state(): PlayingState | undefined {
    return this.playerController && this.playerController.state();
  }

  public play(): void {
    if (this.playerController) {
      this.playerController.play();
    }
  }

  public pause(): void {
    if (this.playerController) {
      this.playerController.pause();
    }
  }

  public stop(): void {
    if (this.playerController) {
      this.playerController.stop();
    }
  }

  public seek(playTime: number): void {
    if (this.playerController) {
      this.playerController.seek(playTime);
    }
  }

  public seekPercent(percent: number): void {
    if (this.playerController) {
      this.playerController.seekPercent(percent);
    }
  }
}
