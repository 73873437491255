import React from "react";
import { GetCurrentProjectId } from "testly-web/components/CurrentProjectId/GetCurrentProjectId";
import { LoadingBar } from "testly-web/components/LoadingBar";
import { GetProjectsForDropdownComponent } from "testly-web/queries";
import { ProjectsDropdown } from "./ProjectsDropdown";

export const ProjectsDropdownContainer = () => (
  <GetProjectsForDropdownComponent>
    {({ loading, error, data }) => {
      if (error) {
        console.error(`Failed to load projects for dropdown ${error}`);
      }

      return (
        <>
          <LoadingBar show={loading} />
          <GetCurrentProjectId>
            {currentProjectId => (
              <ProjectsDropdown
                currentProjectId={currentProjectId}
                availableProjects={
                  (data &&
                    data.currentUser &&
                    data.currentUser.projects.nodes) ||
                  []
                }
              />
            )}
          </GetCurrentProjectId>
        </>
      );
    }}
  </GetProjectsForDropdownComponent>
);
