import {
  FieldArray,
  Form,
  Formik,
  FormikActions,
  FormikProps,
  getIn
} from "formik";
import { times } from "lodash";
import React from "react";
import { Prompt } from "react-router";
import styled from "styled-components/macro";
import { TrashIcon } from "testly-web/icons/TrashIcon";
import { Button } from "../Button";
import { TextField } from "../Form/Field";
import { Body, Panel } from "../Panel";
import { Footer, Hr } from "../Panel/Panel";
import { BackButton, NextButton } from "./formComponents";
import { Variation, variationFormSchema, VariationFormValues } from "./schemas";

const newVariation = (i: number): Variation<false> => ({
  name: `Variation ${i}`,
  url: "",
  control: false
});

const VariationFieldsStyled = styled.div<{ control: boolean }>`
  border-left: 4px solid ${({ control }) => (control ? "#7fd6b4" : "#d2adf3")};
  padding-left: 16px;
  position: relative;
`;

const VariationName = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
`;

const TrashIconStyled = styled(TrashIcon)`
  position: absolute;
  cursor: pointer;
  top: -5px;
  right: 0;
`;

const VariationFields: React.SFC<{
  prefix: string;
  formProps: FormikProps<VariationFormValues>;
  showDeleteButton: boolean;
  includeNameInput: boolean;
  control: boolean;
  onRemove?(): void;
}> = ({
  prefix,
  includeNameInput,
  formProps,
  showDeleteButton,
  onRemove,
  control
}) => {
  return (
    <VariationFieldsStyled control={control}>
      <VariationName>
        {control ? "Control URL" : getIn(formProps.values, `${prefix}.name`)}
      </VariationName>
      <TextField
        label={
          control
            ? "This is URL of your control page."
            : "Enter the URL of the page."
        }
        name={`${prefix}.url`}
        {...formProps}
      />

      {includeNameInput && (
        <TextField
          label="The variation name."
          name={`${prefix}.name`}
          {...formProps}
        />
      )}

      {showDeleteButton && (
        <TrashIconStyled
          onClick={() => {
            if (onRemove) {
              onRemove();
            }
          }}
          role="button"
        />
      )}
    </VariationFieldsStyled>
  );
};

const VariationButton = styled(Button)`
  padding-left: 16px;
  &:before {
    display: inline-block;
    font-size: 21px;
    content: "+";
    color: #d2adf3;
    font-weight: 400;
    margin-right: 12px;
  }
`;

const BodyStyled = styled(Body)`
  padding-bottom: 20px;
`;

export const VariationsForm: React.SFC<{
  initialValues: VariationFormValues;
  onSubmit(
    values: VariationFormValues,
    actions: FormikActions<VariationFormValues>
  ): void;
  onBackButtonClick(): void;
}> = ({ initialValues, onSubmit, onBackButtonClick }) => (
  <Panel>
    <Formik<VariationFormValues>
      initialValues={initialValues}
      validationSchema={variationFormSchema}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {props => {
        const {
          isSubmitting,
          values: { notControlVariations }
        } = props;

        return (
          <>
            <Prompt
              when={props.dirty}
              message="You have unsaved changes, are you sure you want to leave?"
            />
            <Form>
              <BodyStyled>
                <VariationFields
                  prefix="controlVariation"
                  formProps={props}
                  showDeleteButton={false}
                  includeNameInput={false}
                  control={true}
                />

                <Hr />

                <FieldArray
                  validateOnChange={false}
                  name="notControlVariations"
                >
                  {({ remove, push }) => (
                    <>
                      {times(notControlVariations.length, i => (
                        <React.Fragment key={i}>
                          <VariationFields
                            prefix={`notControlVariations.${i}`}
                            formProps={props}
                            showDeleteButton={notControlVariations.length !== 1}
                            onRemove={() => {
                              remove(i);
                            }}
                            includeNameInput={true}
                            control={false}
                          />

                          <Hr />
                        </React.Fragment>
                      ))}

                      <VariationButton
                        onClick={() => {
                          push(newVariation(notControlVariations.length + 1));
                        }}
                        action="secondary"
                        size="small"
                        type="button"
                      >
                        Add Variation
                      </VariationButton>
                    </>
                  )}
                </FieldArray>
              </BodyStyled>
              <Footer>
                <BackButton
                  onClick={onBackButtonClick}
                  type="button"
                  disabled={isSubmitting}
                />
                <NextButton disabled={isSubmitting} loading={isSubmitting} />
              </Footer>
            </Form>
          </>
        );
      }}
    </Formik>
  </Panel>
);
