import { FormikActions } from "formik";
import { fromPairs } from "lodash";
import React from "react";
import { MutationFn } from "react-apollo";
import { RouteComponentProps } from "react-router-dom";
import { toast } from "react-toastify";
import { SetCurrentProjectId } from "testly-web/components/CurrentProjectId/SetCurrentProjectId";
import { loadingRender } from "testly-web/components/loadingRender";
import { SetNavbar } from "testly-web/components/Navbar/SetNavbar";
import { onMutationError } from "testly-web/components/onFail";
import { DeleteProjectMutation } from "testly-web/mutations/DeleteProject/DeleteProjectMutation";
import { UpdateProjectMutation } from "testly-web/mutations/UpdateProject";
import { paths } from "testly-web/paths";
import {
  DeleteProjectMutation as GeneratedDeleteProjectMutation,
  DeleteProjectVariables,
  GetDataForProjectEditPageComponent,
  UpdateProjectMutation as GeneratedUpdateProjectMutation,
  UpdateProjectVariables
} from "testly-web/queries";
import { FormValues, ProjectEditPage } from "./ProjectEditPage";

const onSubmit = (
  updateProject: MutationFn<
    GeneratedUpdateProjectMutation,
    UpdateProjectVariables
  >,
  projectId: string
) => {
  return async (values: FormValues, actions: FormikActions<FormValues>) => {
    const response = await updateProject({
      variables: { params: values, projectId }
    });

    if (response && response.data && response.data.updateProject) {
      const { successful, messages, result } = response.data.updateProject;

      if (successful && result) {
        toast.success("The project is updated");
      } else {
        actions.setErrors(fromPairs(messages.map(m => [m.field, m.message])));
      }

      actions.setSubmitting(false);
    }
  };
};

const onDelete = (
  deleteProject: MutationFn<
    GeneratedDeleteProjectMutation,
    DeleteProjectVariables
  >,
  projectId: string,
  replace: (location: string) => void
) => {
  return async () => {
    await deleteProject({ variables: { projectId } });

    toast.info(
      "The project was deleted. Please, contact support if you made it accidentally"
    );

    replace(paths.projectRedirectPath());
  };
};

export const ProjectEditPageContainer: React.SFC<
  RouteComponentProps<{ projectId: string }>
> = ({
  history: { replace },
  match: {
    params: { projectId }
  }
}) => (
  <>
    <SetCurrentProjectId id={projectId} />
    <SetNavbar
      header="Edit Project"
      backPath={paths.projectPath({ projectId })}
    />
    <GetDataForProjectEditPageComponent
      variables={{ projectId }}
      fetchPolicy="network-only"
    >
      {loadingRender("currentUser.project", ({ data }) => (
        <UpdateProjectMutation onError={onMutationError}>
          {updateProject => (
            <DeleteProjectMutation onError={onMutationError}>
              {deleteProject => (
                <ProjectEditPage
                  project={data!.currentUser!.project!}
                  onSubmit={onSubmit(updateProject, projectId)}
                  onDelete={onDelete(deleteProject, projectId, replace)}
                />
              )}
            </DeleteProjectMutation>
          )}
        </UpdateProjectMutation>
      ))}
    </GetDataForProjectEditPageComponent>
  </>
);
