import { create } from "heatmap.js/build/heatmap.js";
import React from "react";
import styled from "styled-components/macro";
import { HeatmapElement } from "../ClickHeatmap";
import { heatmapRadius } from "../config";
import { calcRelativePoints, reducePointsToRegions, Region } from "../Region";

interface HeatmapProps {
  elements: HeatmapElement[];
}
const HeatmapStyled = styled.div`
  position: absolute;
  transform: translateZ(0);
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
`;
const HeatmapWrapper = styled.div`
  position: absolute !important;
  transform: translateZ(0);
  box-sizing: border-box;
  canvas {
    transform: translateZ(0px);
  }
`;
export class Heatmap extends React.Component<HeatmapProps> {
  private regions: Region[];
  private heatmapRefs: Array<React.RefObject<HTMLDivElement>> = [];

  public constructor(props: HeatmapProps) {
    super(props);

    const points = this.props.elements.flatMap(({ calculatedClickPoints }) => {
      return calculatedClickPoints;
    });
    this.regions = reducePointsToRegions(points);
    this.regions.forEach((region, i) => {
      this.heatmapRefs[i] = React.createRef<HTMLDivElement>();
    });
  }
  public componentDidMount() {
    this.regions.forEach((region, i) => {
      const heatmap = create({
        container: this.heatmapRefs[i].current!,
        maxOpacity: 0.6,
        minOpacity: 0,
        blur: 0.75,
        radius: heatmapRadius,
        valueField: "weight"
      });

      heatmap.setData({
        max: 100,
        data: calcRelativePoints(region)
      });
    });
  }

  public render() {
    return (
      <HeatmapStyled
        children={this.regions.map((region, i) => (
          <HeatmapWrapper
            key={i}
            ref={this.heatmapRefs[i]}
            style={{
              width: region.x2 - region.x,
              height: region.y2 - region.y,
              top: region.y,
              left: region.x
            }}
          />
        ))}
      />
    );
  }
}
