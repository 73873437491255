import { NetworkStatus } from "apollo-client";
import { capitalize, upperFirst } from "lodash";
import moment from "moment";
import React from "react";
import styled, { css } from "styled-components/macro";
import { Caption } from "testly-web/components/Caption";
import { CalendarIcon } from "testly-web/icons/CalendarIcon";
import { DeviceIcon } from "testly-web/icons/DeviceIcon";
import { GoalIcon } from "testly-web/icons/GoalIcon";
import { IpIcon } from "testly-web/icons/IpIcon";
import { MapIcon } from "testly-web/icons/MapIcon";
import { RefreshIcon } from "testly-web/icons/RefreshIcon";
import { SourceIcon } from "testly-web/icons/SourceIcon";
import { SplitTestIcon } from "testly-web/icons/SplitTestIcon";
import { TimeIcon } from "testly-web/icons/TimeIcon";
import { GetSessionRecordingForRecordingPageSessionRecording } from "testly-web/queries";
import { DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT } from "testly-web/settings";
import { toMMSS } from "testly-web/utils";

const SessionDetailsStyled = styled.div`
  font-family: Roboto;
  font-size: 14px;
  color: #1e1d1e;
  position: relative;
  padding-right: 10px;

  background-color: #ffffff;

  margin-bottom: 40px;
`;
const Map = styled.div``;
const Details = styled.ul`
  padding: 22px;
  margin: 0;
  list-style: none;
`;
const DetailsItem = styled.li<{ canContainLongText?: true }>`
  margin-bottom: 15px;

  display: flex;
  align-items: center;

  ${({ canContainLongText }) =>
    canContainLongText &&
    css`
      word-break: break-word;
    `};

  svg {
    flex-shrink: 0;
    align-self: flex-start;
    margin-right: 16px;
  }

  &:last-child {
    margin-bottom: 0px;
  }
`;

const CaptionStyled = styled(Caption)`
  display: inline-block;
  margin-bottom: 18px;
`;

const RefreshIconStyled = styled(RefreshIcon)`
  top: auto;
  bottom: 12px;
`;

const SplitTestDetail: React.SFC<{
  sessionRecording: GetSessionRecordingForRecordingPageSessionRecording;
}> = ({ sessionRecording }) => (
  <>
    {sessionRecording.splitTestEdges.map(
      ({ node: splitTest, convertedGoals, visitedVariation }) => (
        <SessionDetailsStyled key={splitTest.id}>
          <Details>
            <DetailsItem>
              <SplitTestIcon mainColor="blackGray" width="16" />
              {upperFirst(splitTest.name)}, {visitedVariation.name}
            </DetailsItem>
            {convertedGoals.length !== 0 && (
              <DetailsItem canContainLongText={true}>
                <GoalIcon mainColor="gray" size="small" />
                {upperFirst(convertedGoals.map(({ name }) => name).join(", "))}
              </DetailsItem>
            )}
          </Details>
        </SessionDetailsStyled>
      )
    )}
  </>
);
/* tslint:disable:react-iframe-missing-sandbox */
export const SessionDetails: React.SFC<{
  sessionRecording: GetSessionRecordingForRecordingPageSessionRecording;
  className?: string;
  networkStatus: NetworkStatus;
  refetch(): void;
}> = React.memo(({ sessionRecording, networkStatus, refetch, className }) => {
  const {
    location: { city, country, ip },
    createdAt,
    device: { screenWidth, screenHeight, browser, os, deviceType },
    convertedProjectGoals,
    referrerSource,
    isVisitorOnline,
    duration
  } = sessionRecording;
  const address = [country, city].filter(v => v).join(", ");
  const addressPresent = address !== "";
  const resolution = [screenWidth, screenHeight].filter(v => v).join("x");
  const browserString = [browser.name, browser.version]
    .filter(v => v)
    .join(", ");
  const osString = [os.name, os.version].filter(v => v).join(", ");
  const goalsString = convertedProjectGoals.map(({ name }) => name).join(", ");

  return (
    <>
      <SessionDetailsStyled className={className}>
        {addressPresent && (
          <Map>
            <iframe
              title="map"
              width="100%"
              height="200"
              frameBorder="0"
              style={{ border: 0 }}
              src={`https://maps.google.com/maps?q=${encodeURIComponent(
                address
              )}&t=&ie=UTF8&iwloc=&output=embed&zoom=15`}
              allowFullScreen={true}
            />
          </Map>
        )}
        <Details>
          {isVisitorOnline && (
            <CaptionStyled size="medium">online</CaptionStyled>
          )}
          <RefreshIconStyled
            isRefreshing={networkStatus === NetworkStatus.refetch}
            onClick={e => {
              refetch();
            }}
          />
          <DetailsItem>
            <DeviceIcon deviceType={deviceType} width="16" />
            {[
              capitalize(deviceType),
              resolution !== "" ? resolution : undefined,
              browserString,
              osString
            ]
              .filter(v => v)
              .join(", ")}
          </DetailsItem>
          {goalsString !== "" && (
            <DetailsItem canContainLongText={true}>
              <GoalIcon mainColor="gray" size="small" />
              {upperFirst(goalsString)}
            </DetailsItem>
          )}
          {addressPresent && (
            <DetailsItem>
              <MapIcon />
              {address}
            </DetailsItem>
          )}
          <DetailsItem>
            <TimeIcon />
            {toMMSS(duration / 1000)}{" "}
          </DetailsItem>
          <DetailsItem>
            <SourceIcon width="16" />
            {capitalize(referrerSource)}
          </DetailsItem>
          <DetailsItem>
            <IpIcon />
            {ip}
          </DetailsItem>
          <DetailsItem>
            <CalendarIcon />
            {moment(createdAt).format(
              `${DEFAULT_DATE_FORMAT}, ${DEFAULT_TIME_FORMAT}`
            )}
          </DetailsItem>
        </Details>
      </SessionDetailsStyled>
      <SplitTestDetail sessionRecording={sessionRecording} />
    </>
  );
});
