import React from "react";
import styled from "styled-components/macro";

import { UserContainer } from "testly-web/components/Layout/TopBar/UserContainer";
import { NavbarContainer } from "testly-web/components/Navbar/NavbarContainer";
import { ProjectsDropdownContainer } from "testly-web/components/TopBarProjectsDropdown";
import { ActiveRow } from "testly-web/components/TopBarProjectsDropdown/Row";
import { topBarHeight } from "testly-web/style-vars";

const StyledTopBar = styled.div`
  display: flex;
  height: ${topBarHeight}px;
  justify-content: flex-end;
  align-items: center;
  background-color: #fff;
  width: 100%;

  position: relative;

  &:before {
    content: "";
    position: absolute;
    display: inline-block;
    background: linear-gradient(to right, #e6e7ea, #fff);
    height: 100%;
    width: 20px;
    left: 0;
  }
`;
const Title = styled.div`
  height: ${topBarHeight}px;
  white-space: nowrap;

  font-family: Roboto;
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 1px;
  color: #393839;

  margin-right: auto;
  margin-left: 38px;
`;

const TopBarStyledUser = styled(UserContainer)`
  margin-right: 47px;
`;

const ProjectsContent = styled.div`
  height: ${topBarHeight}px;
  z-index: 1000;
  margin-right: 25px;

  ${ActiveRow} {
    height: ${topBarHeight}px;
  }
`;

export const TopBar: React.SFC = () => (
  <StyledTopBar>
    <Title>
      <NavbarContainer />
    </Title>
    <ProjectsContent>
      <ProjectsDropdownContainer />
    </ProjectsContent>
    <TopBarStyledUser />
  </StyledTopBar>
);
