import React from "react";
import styled from "styled-components/macro";
import { DotLoadingIndicator } from "testly-web/components/DotLoadingIndicator/DotLoadingIndicator";

const LoadingCoverStyled = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;

  background: rgba(248, 248, 248, 0.99);
`;

const LoadingCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 230px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
`;

const LoadingContent = styled.div``;

const LoadingLabel = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #393839;
  text-align: center;
  margin-top: 40px;
`;

export const LoadingCover = () => (
  <LoadingCoverStyled>
    <LoadingCard>
      <LoadingContent>
        <DotLoadingIndicator size={40} />
        <LoadingLabel>Loading...</LoadingLabel>
      </LoadingContent>
    </LoadingCard>
  </LoadingCoverStyled>
);
