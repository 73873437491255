import React from "react";

export const IpIcon = () => (
  // tslint:disable:max-line-length
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="16"
    viewBox="0 0 14 16"
  >
    <path
      fill="#A7A9AE"
      d="M6.79.031C2.74.031.03 2.736.03 6.79c0 3.935 6.113 8.821 6.373 9.03.113.092.248.212.385.212.135 0 .275-.122.385-.212.26-.207 6.373-5.02 6.373-9.03 0-4.053-2.71-6.758-6.758-6.758zM4.901 4.325h.559c.065 0 .115.07.115.135v4.1c0 .066-.05.116-.115.116h-.559a.114.114 0 0 1-.115-.115V4.46c0-.065.05-.135.115-.135zm1.964 0h1.791c.445 0 .785.13 1.02.366.236.233.366.568.366.982 0 .414-.13.726-.366.962-.23.234-.565.366-1 .366H7.52v1.56c0 .065-.05.115-.116.115h-.539a.114.114 0 0 1-.115-.115V4.46c0-.065.05-.135.115-.135zm.655.712V6.29h.963c.29 0 .481-.048.597-.154.118-.109.173-.25.173-.462 0-.212-.055-.376-.173-.482-.12-.108-.318-.154-.597-.154H7.52z"
    />
  </svg>
);
