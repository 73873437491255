import { lighten } from "polished";
import React from "react";
import styled from "styled-components/macro";

const color = "#a7a9ae";

const CollapseStyled = styled.svg`
  fill: ${color};

  transition: fill 0.3s;

  &:hover {
    fill: ${lighten(0.2, color)};
  }
`;

export const Collapse = ({ ...props }) => (
  // tslint:disable:max-line-length
  <CollapseStyled
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <path d="M12.625 12a.625.625 0 0 0-.625.625v5.577c0 .252.15.48.385.577a.619.619 0 0 0 .673-.144l1.85-1.851 2.741 2.74a.444.444 0 0 0 .625 0l1.25-1.25a.447.447 0 0 0 0-.625l-2.74-2.74 1.85-1.851a.625.625 0 0 0 .193-.433.626.626 0 0 0-.625-.625h-5.577zM1.451 12c-.253 0-.481.15-.577.385a.619.619 0 0 0 .144.673l1.85 1.85-2.74 2.741a.444.444 0 0 0 0 .625l1.25 1.25a.444.444 0 0 0 .625 0l2.74-2.74 1.852 1.85c.12.118.27.193.432.193a.626.626 0 0 0 .625-.625v-5.577A.625.625 0 0 0 7.027 12H1.451zM17.96 0a.44.44 0 0 0-.312.12l-2.74 2.74-1.851-1.85a.625.625 0 0 0-.433-.193.626.626 0 0 0-.625.625V7.02c0 .346.282.625.625.625h5.577c.252 0 .48-.15.577-.384a.619.619 0 0 0-.144-.673l-1.851-1.851 2.716-2.74a.444.444 0 0 0 0-.626L18.274.144A.466.466 0 0 0 17.962 0zM1.68 0a.445.445 0 0 0-.313.12L.141 1.37c-.171.171-.195.454-.024.625l2.74 2.74-1.85 1.852a.625.625 0 0 0-.193.432.626.626 0 0 0 .625.625h5.577c.346 0 .625-.28.625-.625V1.442c0-.252-.15-.48-.385-.577a.619.619 0 0 0-.673.145l-1.85 1.85L1.992.12A.44.44 0 0 0 1.68 0z" />
  </CollapseStyled>
);
