// https://stackoverflow.com/a/52913382/3872807
// Is used for switch-case default case
export class UnreachableCaseError extends Error {
  constructor(val: never) {
    super(`Unreachable case: ${val}`);
  }
}

type Not<T> = [T] extends [never] ? unknown : never;
type Extractable<T, U> = Not<
  U extends any ? Not<T extends U ? unknown : never> : never
>;
export function toEnumValue<
  E extends Record<keyof E, string | number>,
  K extends string | number
>(e: E, k: K & Extractable<E[keyof E], K>): Extract<E[keyof E], K> {
  // runtime guard, shouldn't need it at compiler time
  if (Object.values(e).indexOf(k) < 0) {
    throw new Error("Expected one of " + Object.values(e).join(", "));
  }
  return k as any; // assertion
}

export const toMMSS = (time: number) => {
  if (time === 0) {
    return `0:00`;
  } else {
    return `${Math.floor(time / 60)}:${`0${Math.floor(time % 60)}`.slice(-2)}`;
  }
};
