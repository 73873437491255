import React from "react";
import { GoalIcon } from "testly-web/icons/GoalIcon";
import { SplitTestIcon } from "testly-web/icons/SplitTestIcon";
import { paths } from "testly-web/paths";
import { Feature, FeatureImageWrapper } from "./Feature";
import { Body, Section, Title } from "./Section";

export const ProvingContent: React.SFC<{
  currentProjectId: string | null;
  onItemClick(): void;
}> = ({ currentProjectId, onItemClick }) => (
  <>
    <Section>
      <Title>Proving</Title>
      <Body>
        <Feature
          to={paths.splitTestIndexPath({
            projectId: currentProjectId || ""
          })}
          onClick={onItemClick}
        >
          <FeatureImageWrapper>
            <SplitTestIcon mainColor="green" />
          </FeatureImageWrapper>
          Split Tests
        </Feature>
      </Body>
    </Section>
    <Section>
      <Title>Tracking</Title>
      <Body>
        <Feature
          to={paths.goalListPath({
            projectId: currentProjectId || ""
          })}
          onClick={onItemClick}
        >
          <FeatureImageWrapper>
            <GoalIcon />
          </FeatureImageWrapper>
          Goals
        </Feature>
      </Body>
    </Section>
  </>
);
