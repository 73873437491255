import { History } from "history";
import { buildQuery, QueryState } from "./query";
import { SessionRecordingListPageProps } from "./SessionRecordingListPage";

export const pageProps = ({
  history,
  totalRecords,
  perPage,
  queryState
}: {
  history: History;
  totalRecords: number;
  perPage: number;
  queryState: QueryState;
}): Pick<
  SessionRecordingListPageProps,
  "onFilterChange" | "onPageChange" | "pageCount"
> => {
  const handlePageFilterChange = (state: QueryState) => {
    history.replace({
      pathname: history.location.pathname,
      search: buildQuery(state)
    });
  };

  return {
    onFilterChange: filter => {
      handlePageFilterChange({
        ...queryState,
        page: 1,
        filter
      });
    },
    onPageChange: async ({ selected }) => {
      window.scrollTo({
        top: 0,
        left: 0
      });

      handlePageFilterChange({
        ...queryState,
        page: selected
      });
    },
    pageCount: Math.ceil(totalRecords / perPage)
  };
};
