import React from "react";
import { ProjectState } from "testly-web/queries";
import { UnreachableCaseError } from "testly-web/utils";

export const ProjectStateIcon: React.SFC<
  { projectState: ProjectState } & React.SVGProps<SVGSVGElement>
> = ({ projectState, ...props }) => {
  switch (projectState) {
    case ProjectState.Active:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="65"
          height="65"
          viewBox="0 0 65 65"
          {...props}
        >
          <path
            fill="#00AC69"
            // tslint:disable-next-line:max-line-length
            d="M32.139 0C14.34 0 0 14.502 0 32.5 0 50.498 14.34 65 32.139 65c17.798 0 32.139-14.502 32.139-32.5 0-3.496-.464-6.982-1.7-10.234l-4.017 3.984a25.57 25.57 0 0 1 .772 6.25c0 15.254-12.11 27.5-27.194 27.5C17.054 60 4.944 47.754 4.944 32.5S17.054 5 32.14 5c7.417 0 14.08 2.988 18.773 7.734L54.39 9.22C48.7 3.467 40.792 0 32.139 0zm27.89 10.703l-27.89 28.203-10.584-10.703L18 31.797l12.361 12.5 1.777 1.719 1.777-1.72 29.666-30-3.553-3.593z"
          />
        </svg>
      );
    case ProjectState.CodeMayNotBeInstalled:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="66"
          height="64"
          viewBox="0 0 66 64"
          {...props}
        >
          <path
            fill="#FF5858"
            // tslint:disable-next-line:max-line-length
            d="M23 .9v5.22c-5.503 2.04-10.435 6.015-13.677 11.63-7.542 13.063-2.992 29.674 10.219 37.301 13.21 7.627 29.87 3.262 37.413-9.801 7.542-13.063 2.991-29.674-10.219-37.301A25.153 25.153 0 0 0 43 6.177V.867c2.157.69 4.23 1.658 6.208 2.8 15.587 8.999 20.976 28.67 12.077 44.083-8.9 15.414-28.629 20.582-44.216 11.583C1.483 50.334-3.906 30.663 4.993 15.25 9.115 8.11 15.626 3.128 23 .9zm6 .1h7l-1 32h-5L29 1zm3.703 44.867a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"
          />
        </svg>
      );
    case ProjectState.WaitingForFirstVisit:
      return (
        <svg
          width="48"
          height="63"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            // tslint:disable-next-line:max-line-length
            d="M0 63v-5.25h5.333v-10.5c0-6.675 3.646-12.53 9.084-15.75-5.438-3.22-9.084-9.075-9.084-15.75V5.25H0V0h48v5.25h-5.333v10.5c0 6.675-3.646 12.53-9.084 15.75 5.438 3.22 9.084 9.075 9.084 15.75v10.5H48V63H0zm10.667-5.25h26.666v-10.5c0-7.28-5.937-13.125-13.333-13.125S10.667 39.97 10.667 47.25v10.5zM24 28.875c7.396 0 13.333-5.845 13.333-13.125V5.25H10.667v10.5c0 7.28 5.937 13.125 13.333 13.125zM14 16.062h20c-1.357 6.132-4.791 9.198-10.301 9.198S14.956 22.194 14 16.062zM14 55h20c-1.357-6.131-4.791-9.197-10.301-9.197S14.956 48.869 14 55z"
            fill="#F5A623"
          />
        </svg>
      );
    default:
      throw new UnreachableCaseError(projectState);
  }
};
