import { NetworkStatus } from "apollo-client";
import { flatMap } from "lodash";
import React from "react";
import { HeadCell, TableRefreshIcon } from "../Table";
import {
  ActionCell,
  BodyCell,
  CountryCell,
  CreatedAtCell,
  DeviceCell,
  DurationCell,
  goalCell,
  ReferrerSourceCell,
  ResolutionCell,
  StartingPageCell,
  VariationCell
} from "./bodyCells";

type HeaderCell = React.SFC<{
  networkStatus: NetworkStatus;
  refetch(): void;
}>;

export interface Column {
  HeaderCell: HeaderCell;
  BodyCell: BodyCell;
}

interface ColumnsConfig {
  variation: Column;
  startingPage: Column;
  country: Column;
  duration: Column;
  device: Column;
  referrerSource: Column;
  resolution: Column;
  projectGoals: Column;
  splitTestGoals: Column;
  createdAt: Column;
  action: Column;
}

export type ColumnName = keyof ColumnsConfig;

export const columnsConfig: ColumnsConfig = {
  variation: {
    HeaderCell: () => <HeadCell>Variation</HeadCell>,
    BodyCell: VariationCell
  },
  startingPage: {
    HeaderCell: () => <HeadCell>Starting Page</HeadCell>,
    BodyCell: StartingPageCell
  },
  country: {
    HeaderCell: () => <HeadCell>Country</HeadCell>,
    BodyCell: CountryCell
  },
  duration: {
    HeaderCell: () => <HeadCell>Duration</HeadCell>,
    BodyCell: DurationCell
  },
  device: {
    HeaderCell: () => <HeadCell>Device</HeadCell>,
    BodyCell: DeviceCell
  },
  referrerSource: {
    HeaderCell: () => <HeadCell>Source</HeadCell>,
    BodyCell: ReferrerSourceCell
  },
  resolution: {
    HeaderCell: () => <HeadCell>Resolution</HeadCell>,
    BodyCell: ResolutionCell
  },
  projectGoals: {
    HeaderCell: () => <HeadCell>Goals</HeadCell>,
    BodyCell: goalCell(
      sessionRecording => sessionRecording.convertedProjectGoals
    )
  },
  splitTestGoals: {
    HeaderCell: () => <HeadCell>Goals</HeadCell>,
    BodyCell: goalCell(sessionRecording =>
      flatMap(sessionRecording.splitTestEdges.map(edge => edge.convertedGoals))
    )
  },
  createdAt: {
    HeaderCell: () => <HeadCell>Recorded on</HeadCell>,
    BodyCell: CreatedAtCell
  },
  action: {
    HeaderCell: ({ networkStatus, refetch }) => (
      <HeadCell>
        <TableRefreshIcon
          isRefreshing={networkStatus === NetworkStatus.refetch}
          onClick={e => {
            refetch();
          }}
        />
      </HeadCell>
    ),
    BodyCell: ActionCell
  }
};
