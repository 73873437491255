import { History } from "history";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { Button } from "testly-web/components/Button";
import { DisabledType } from "../Card";

interface LinkType {
  name: string;
  href: History.LocationDescriptor;
  disabled?: boolean;
}

export type FooterProps = {
  primaryLink: LinkType;
  secondaryLink?: LinkType;
} & DisabledType;

const StyledFooter = styled.div`
  display: flex;
  margin-top: auto;
  padding-top: 30px;
  justify-content: space-between;
`;

const StyledButton = styled(Button)`
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
`;

const StyledLink = styled(Link)`
  width: 100%;

  margin-left: 16px;
  &:first-child {
    margin-left: 0px;
  }
`;

export const Footer: React.SFC<FooterProps> = ({
  primaryLink,
  secondaryLink
}) => (
  <StyledFooter>
    <StyledLink to={primaryLink.disabled ? "#" : primaryLink.href}>
      <StyledButton
        action="primary"
        size="medium"
        disabled={primaryLink.disabled}
      >
        {primaryLink.name}
      </StyledButton>
    </StyledLink>
    {secondaryLink && (
      <StyledLink to={secondaryLink.disabled ? "#" : secondaryLink.href}>
        <StyledButton
          action="secondary"
          size="medium"
          disabled={secondaryLink.disabled}
        >
          {secondaryLink.name}
        </StyledButton>
      </StyledLink>
    )}
  </StyledFooter>
);
