import React from "react";
import styled, { css } from "styled-components/macro";
import { menuItemWidth } from "testly-web/style-vars";
import { LearningContent } from "./LearningContent";
import { ProvingContent } from "./ProvingContent";

export type OpenedMenuType = "learning" | "proving" | "none";

type SlideMenuProps = {
  openedMenuType: OpenedMenuType;
  wrapperRef: React.RefObject<HTMLDivElement>;
  currentProjectId: string | null;
  close(): void;
} & SlideMenuStyleProps;

interface SlideMenuStyleProps {
  opened: boolean;
  closeAnimationIsRunning: boolean;
}

const SlideMenuStyled = styled.div<SlideMenuStyleProps>`
  visibility: hidden;
  position: absolute;
  box-sizing: border-box;
  z-index: -1;
  width: 280px;
  height: 100%;
  background-color: #1d1c1d;
  box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.25);
  margin-left: ${menuItemWidth}px;

  padding: 25px 0;

  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-timing-function: ease-out;

  will-change: transform;

  ${({ opened }) =>
    opened &&
    css`
      animation-name: slideInLeftMenu;
      visibility: visible;
    `};

  ${({ closeAnimationIsRunning }) =>
    closeAnimationIsRunning &&
    css`
      animation-name: slideOutLeftMenu;
    `};

  @keyframes slideOutLeftMenu {
    from {
      visibility: visible;

      transform: translate3d(0, 0, 0);
    }

    to {
      visibility: hidden;

      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes slideInLeftMenu {
    from {
      transform: translate3d(-100%, 0, 0);
    }

    to {
      transform: translate3d(0, 0, 0);
    }
  }
`;

export const SlideMenu: React.SFC<SlideMenuProps> = ({
  openedMenuType,
  opened,
  closeAnimationIsRunning,
  wrapperRef,
  currentProjectId,
  close
}) => {
  return (
    <SlideMenuStyled
      ref={wrapperRef as React.RefObject<HTMLDivElement> & string}
      opened={opened}
      closeAnimationIsRunning={closeAnimationIsRunning}
    >
      {openedMenuType === "proving" && (
        <ProvingContent
          currentProjectId={currentProjectId}
          onItemClick={close}
        />
      )}
      {openedMenuType === "learning" && (
        <LearningContent
          currentProjectId={currentProjectId}
          onItemClick={close}
        />
      )}
    </SlideMenuStyled>
  );
};
