import { NetworkStatus } from "apollo-boost";
import React from "react";
import styled from "styled-components/macro";
import { RefreshIcon } from "testly-web/icons/RefreshIcon";
import {
  GetProjectForProjectPageProject,
  GetProjectForProjectPageBillingProducts,
  GetProjectForProjectPageCurrentBilling
} from "testly-web/queries";
import { spaceBetweenColumns } from "../styled-vars";
import * as Table from "../Table";
import { StyledCell } from "../Table/Cell";
import { BillingWithLinks } from "./BillingWithLinks";
import { SiteStatus } from "./SiteStatus";

export const ProjectStatusColumnStyled = styled.div`
  margin-right: ${spaceBetweenColumns}px;
  background-color: #fff;
  height: 100%;
  position: relative;

  ${StyledCell} {
    flex-grow: 1;
    width: 100%;
  }
`;

interface ProjectStatusProps {
  className?: string;
  project: GetProjectForProjectPageProject;
  networkStatus: NetworkStatus;
  billingProducts: GetProjectForProjectPageBillingProducts[];
  currentBilling: GetProjectForProjectPageCurrentBilling;
  refetch(): void;
}

const RefreshIconStyled = styled(RefreshIcon)`
  z-index: 1;
`;

export const ProjectStatusColumn: React.SFC<ProjectStatusProps> = ({
  className,
  project,
  networkStatus,
  refetch,
  currentBilling
}) => (
  <ProjectStatusColumnStyled className={className}>
    <RefreshIconStyled
      isRefreshing={networkStatus === NetworkStatus.refetch}
      onClick={e => {
        refetch();
      }}
    />
    <Table.Cell order={1}>
      <SiteStatus state={project.state} domain={project.domain} />
    </Table.Cell>
    <Table.Cell order={2}>
      <BillingWithLinks
        currentPlan={
          currentBilling.subscription
            ? currentBilling.subscription.product.name
            : "Free"
        }
        currentVisitorsAmount={currentBilling.thisMonthRecordingsCount}
        visitorsLimit={currentBilling.currentUsage.visitorsPerMonth}
        project={project}
      />
    </Table.Cell>
  </ProjectStatusColumnStyled>
);
