import React from "react";

export const SessionRecordingIcon = () => (
  // tslint:disable:max-line-length
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="37"
    height="23"
    viewBox="0 0 37 23"
  >
    <path
      fillRule="nonzero"
      d="M5.525 0C3.88 0 2.608 1.15 2.608 2.632v14.236c0 .474.151.917.382 1.296H1.26c-.448 0-.74.263-.74.668 0 1.823 1.647 3.24 3.59 3.24h28.726c2.02 0 3.59-1.485 3.59-3.24 0-.336-.29-.668-.74-.668H33.96c.23-.38.382-.822.382-1.296V2.633C34.34 1.147 33.067 0 31.423 0H5.525zm.763 2.632H30.66c.45 0 .763.264.763.669v12.96c0 .405-.314.688-.763.688H6.288c-.448 0-.763-.283-.763-.688V3.3c0-.405.315-.669.763-.669zM19.21 15a.24.24 0 0 0 .099-.021l1.045-.477a.239.239 0 0 0 .117-.315l-1.45-3.17 2.418-.208a.239.239 0 0 0 .142-.412l-5.699-5.333a.238.238 0 0 0-.4.174v7.857a.238.238 0 1 0 .4.174l1.724-1.625 1.386 3.213a.241.241 0 0 0 .218.143z"
    />
  </svg>
);
