import fetch from "isomorphic-fetch";

export const logoutUser = async () => {
  return fetch(process.env.REACT_APP_LOGOUT_URL as string, {
    method: "DELETE",
    headers: {
      Accept: "application/json"
    },
    mode: "cors",
    credentials: "include"
  });
};
