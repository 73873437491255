import React from "react";
import styled from "styled-components/macro";
import { DropdownController } from "testly-web/components/Dropdown/DropdownController";
import { SelectBox } from "testly-web/components/SelectBox";
import { CirclePlusIcon } from "testly-web/icons/CirclePlusIcon";
import { ListFilter } from "./ListFilter";
import { ListOption } from "./ListOption";
import { OptionType } from "./types";

// NOTE: render of big amount of data is slow
// Consider https://github.com/bvaughn/react-virtualized

const SelectBoxStyled = styled(SelectBox)`
  left: -20px;
  top: 0px;
  z-index: 2;
`;

const AddCountryLink = styled.div`
  display: flex;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;

  svg {
    margin-right: 15px;
  }
`;

class TypedDropdownController extends DropdownController<
  HTMLDivElement,
  HTMLDivElement
> {}

export class ListInFilter extends React.Component<{
  namePlural: string;
  nameSingular: string;
  options: OptionType[];
  iconRender?(option: OptionType): React.ReactNode;
  onItemClick(option: OptionType): void;
}> {
  public render() {
    const {
      namePlural,
      nameSingular,
      options,
      iconRender,
      onItemClick
    } = this.props;

    const selectedOptions = options.filter(({ selected }) => selected);

    return (
      <>
        <ListFilter
          name={namePlural}
          options={selectedOptions}
          optionRender={option => (
            <ListOption
              key={option.value}
              option={option}
              onDeleteClick={onItemClick}
              iconRender={iconRender}
            />
          )}
          boxRender={() => (
            <TypedDropdownController>
              {({ wrapperRef, togglerRef, onControlClick, opened }) => (
                <>
                  <AddCountryLink
                    ref={togglerRef as React.RefObject<HTMLDivElement> & string}
                    onClick={onControlClick}
                    role="button"
                  >
                    <CirclePlusIcon />
                    Add {nameSingular}
                  </AddCountryLink>
                  {opened && (
                    <SelectBoxStyled
                      availableValues={options}
                      onSelect={onItemClick}
                      onCloseClick={onControlClick}
                      innerRef={wrapperRef}
                      pluralSearchEntityName={namePlural}
                    />
                  )}
                </>
              )}
            </TypedDropdownController>
          )}
        />
      </>
    );
  }
}
