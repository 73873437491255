import React from "react";
import styled from "styled-components/macro";
import { getCodeToInstall } from "testly-web/services/codeToInstall";
import { Button } from "../Button";
import { CheckCodeModal } from "../CheckCodeModal/CheckCodeModal";
import { Body, Footer, Panel } from "../Panel";
import { BackButton, NextButton } from "./formComponents";
import { InfoIcon } from "./InfoIcon";

const Notice = styled.div`
  display: flex;

  svg {
    flex-shrink: 0;
    margin-right: 20px;
  }
`;
const NoticeBody = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.57;
  color: #393839;
`;
const Code = styled.div`
  border-radius: 8px;
  background-color: #f0f1f1;

  font-family: NotoMono;
  font-size: 12px;
  line-height: 1.67;
  color: #393839;

  padding: 24px;

  margin-top: 16px;
`;

const Pages = styled.div`
  margin-top: 45px;
`;

const PageContainer = styled.div`
  display: flex;
  position: relative;

  margin-bottom: 22px;

  &:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 1px;
    background-color: #ecedee;
    bottom: -12px;
  }

  &:last-child {
    margin-bottom: 0px;
  }

  &:last-child:after {
    display: none;
  }
`;
const PageInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 0;
`;

const PageName = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #393839;

  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const PageURL = styled.div`
  font-size: 12px;
  color: #a7a9ae;

  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const PageCheckSetupBtn = styled(Button)`
  margin-left: auto;
  flex-shrink: 0;
`;

export const InstallationCheckStep: React.SFC<{
  projectId: string;
  pagesToCheck: Array<{
    name: string;
    url: string;
  }>;
  onBackButtonClick: () => void;
  onNextButtonClick: () => void;
}> = ({ projectId, pagesToCheck, onBackButtonClick, onNextButtonClick }) => {
  const code = getCodeToInstall({ projectId });

  return (
    <Panel>
      <Body>
        <Notice>
          <InfoIcon />
          <NoticeBody>
            Make sure that following code is installed between the{" "}
            {"<HEAD></HEAD>"} tag on the all variation/goal pages.
          </NoticeBody>
        </Notice>

        <Code>{code}</Code>

        <Pages>
          {pagesToCheck.length === 0 &&
            "Please, set the variation pages first."}
          {pagesToCheck.map(({ name, url }) => (
            <PageContainer key={url}>
              <PageInfo>
                <PageName>{name}</PageName>
                <PageURL>{url}</PageURL>
              </PageInfo>
              <CheckCodeModal url={url} projectId={projectId}>
                {open => (
                  <PageCheckSetupBtn
                    action="secondary"
                    size="small"
                    onClick={() => {
                      open();
                    }}
                  >
                    Check Code Setup
                  </PageCheckSetupBtn>
                )}
              </CheckCodeModal>
            </PageContainer>
          ))}
        </Pages>
      </Body>
      <Footer>
        <BackButton type="button" onClick={onBackButtonClick} />
        <NextButton type="button" onClick={onNextButtonClick} />
      </Footer>
    </Panel>
  );
};
