import React from "react";
import { ProvingIcon } from "testly-web/icons/ProvingIcon";
import { SplitTestIcon } from "testly-web/icons/SplitTestIcon";
import { paths } from "testly-web/paths";
import {
  GetProjectForProjectPageProject,
  GetProjectForProjectPageCurrentUsage
} from "testly-web/queries";
import { Card } from "../Card";
import * as Table from "../Table";

export const ProvingColumn: React.SFC<{
  project: GetProjectForProjectPageProject;
  currentUsage: GetProjectForProjectPageCurrentUsage;
}> = ({ project }) => (
  <>
    <Table.Cell order={1}>
      <Table.Header
        image={ProvingIcon}
        caption="Proving"
        description="Allows you to prove that the ideas you come up with during the learning phase will improve your results"
      />
    </Table.Cell>
    <Table.Cell order={2}>
      <Card
        titleCaption="Split Tests"
        titleImg={() => <SplitTestIcon mainColor="green" />}
        disabled={false}
        secondaryLink={{
          name: "View Tests",
          href: paths.splitTestIndexPath({ projectId: project.id }),
          disabled: project.splitTestsConnection.totalRecords === 0
        }}
        primaryLink={{
          name: "Create Test",
          href: {
            pathname: paths.splitTestNewPath({ projectId: project.id }),
            state: {
              previousPath: `${window.location.pathname}${
                window.location.search
              }`
            }
          }
        }}
        statistics={{
          Total: project.splitTestsConnection.totalRecords.toLocaleString(),
          Active: project.splitTestsConnection.activeTests.toLocaleString(),
          Done: project.splitTestsConnection.finishedTests.toLocaleString()
        }}
      />
    </Table.Cell>
    <Table.Cell order={3} showHr={false} />
    <Table.Cell order={4} showHr={false} />
  </>
);
