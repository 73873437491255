// tslint:disable

import gql from "graphql-tag";
import * as React from "react";
import * as ReactApollo from "react-apollo";

export type Maybe<T> = T | null;

export interface CursorPagination {
  after?: Maybe<string>;

  before?: Maybe<string>;

  limit?: number;
}

export interface HeatmapPageFilter {
  urlCont: string;
}

export interface HeatmapPageOrder {
  direction: OrderDirection;

  field: HeatmapPageOrderField;
}

export interface Pagination {
  page?: number;

  perPage?: number;
}

export interface SessionRecordingFilter {
  convertedProjectGoalIdsIn?: Maybe<string[]>;

  convertedSplitTestGoalIdsIn?: Maybe<string[]>;

  createdAtGteq?: Maybe<string>;

  createdAtLteq?: Maybe<string>;

  deviceTypeIn?: Maybe<DeviceType[]>;

  durationGteq?: Maybe<number>;

  durationLteq?: Maybe<number>;

  locationCountryIsoCodeIn?: Maybe<string[]>;

  referrerSourceIn?: Maybe<ReferrerSource[]>;

  splitTestIdEq?: Maybe<string>;
}

export interface SplitTestFilter {
  createdAtGteq?: Maybe<string>;

  createdAtLteq?: Maybe<string>;

  nameCont?: Maybe<string>;

  pageCategoryIdIn?: Maybe<string[]>;

  pageTypeIdIn?: Maybe<string[]>;

  statusIn?: Maybe<SplitTestStatus[]>;
}

export interface TestIdeaFilter {
  categoryIdEq?: Maybe<string>;

  descriptionCont?: Maybe<string>;

  impactRateEq?: Maybe<number>;

  likeUserIdEq?: Maybe<string>;

  titleCont?: Maybe<string>;
}

export interface FeedbackPollParams {
  isActive: boolean;

  isPageMatcherEnabled: boolean;

  isPollOpenedOnStart: boolean;

  name: string;

  pageMatchers: PageMatcherParams[];

  questions: FeedbackPollQuestionParams[];

  showPollOption: FeedbackPollShowOption;

  thankYouMessage: string;
}

export interface PageMatcherParams {
  matchType: PageMatcherMatchType;

  url: string;
}

export interface FeedbackPollQuestionParams {
  id?: Maybe<string>;

  index: number;

  title: string;

  type: string;
}

export interface GoalParams {
  id?: Maybe<string>;

  name: string;

  path?: Maybe<PathGoalStepParams[]>;

  type: GoalType;

  value: string;
}

export interface PathGoalStepParams {
  index: number;

  matchType: PageMatcherMatchType;

  url: string;
}

export interface ProjectParams {
  domain?: Maybe<string>;

  isRecordingEnabled?: boolean;
}

export interface SplitTestParams {
  description?: Maybe<string>;

  finishCondition?: Maybe<FinishConditionParams>;

  goals?: Maybe<GoalParams[]>;

  name?: Maybe<string>;

  pageCategoryId?: Maybe<string>;

  pageTypeId?: Maybe<string>;

  testIdeaId?: Maybe<string>;

  trafficDeviceTypes?: Maybe<DeviceType[]>;

  trafficPercent?: Maybe<number>;

  trafficReferrerSources?: Maybe<ReferrerSource[]>;

  variations?: Maybe<VariationParams[]>;
}

export interface FinishConditionParams {
  /** Required for: days_passed, goal_conversions, visits */
  count: number;
  /** Required for: goal_conversions */
  goalId?: Maybe<string>;

  type: SplitTestFinishConditionType;
}

export interface VariationParams {
  control: boolean;

  id?: Maybe<string>;

  name: string;

  url: string;
}

export interface UpdatePasswordParams {
  currentPassword: string;

  newPassword: string;
}

export interface UserParams {
  avatar?: Maybe<Upload>;

  companyName?: Maybe<string>;

  fullName?: Maybe<string>;

  phone?: Maybe<string>;
}
/** Possible durations for a product. */
export enum Duration {
  OneMonth = "ONE_MONTH",
  OneYear = "ONE_YEAR"
}

export enum UserAvatarType {
  Original = "ORIGINAL",
  Thumb = "THUMB"
}

export enum PageMatcherMatchType {
  BeginsWith = "BEGINS_WITH",
  Contains = "CONTAINS",
  EndsWith = "ENDS_WITH",
  MatchesExactly = "MATCHES_EXACTLY"
}

export enum GoalType {
  Path = "PATH"
}

export enum DeviceType {
  Desktop = "DESKTOP",
  Mobile = "MOBILE",
  Tablet = "TABLET"
}

export enum SessionRecordingEventType {
  CssRuleDeleted = "CSS_RULE_DELETED",
  CssRuleInserted = "CSS_RULE_INSERTED",
  DomMutated = "DOM_MUTATED",
  MouseClicked = "MOUSE_CLICKED",
  MouseMoved = "MOUSE_MOVED",
  PageVisited = "PAGE_VISITED",
  Scrolled = "SCROLLED",
  WindowResized = "WINDOW_RESIZED"
}

export enum ReferrerSource {
  Direct = "DIRECT",
  Email = "EMAIL",
  Paid = "PAID",
  Search = "SEARCH",
  Social = "SOCIAL",
  Unknown = "UNKNOWN"
}

export enum SplitTestStatus {
  Active = "ACTIVE",
  Draft = "DRAFT",
  Finished = "FINISHED",
  Paused = "PAUSED"
}

export enum FeedbackPollShowOption {
  Always = "ALWAYS",
  HideAfterSubmit = "HIDE_AFTER_SUBMIT"
}

export enum OrderDirection {
  Asc = "ASC",
  Desc = "DESC"
}

export enum HeatmapPageOrderField {
  CreatedAt = "CREATED_AT",
  TotalViewsCount = "TOTAL_VIEWS_COUNT"
}

export enum ProjectState {
  Active = "ACTIVE",
  CodeMayNotBeInstalled = "CODE_MAY_NOT_BE_INSTALLED",
  WaitingForFirstVisit = "WAITING_FOR_FIRST_VISIT"
}

export enum SplitTestFinishConditionType {
  DaysPassed = "DAYS_PASSED",
  GoalConversions = "GOAL_CONVERSIONS",
  Visits = "VISITS"
}

/** The `DateTime` scalar type represents a date and time in the UTC timezone. The DateTime appears in a JSON response as an ISO8601 formatted string, including UTC timezone ("Z"). The parsed date and time string will be converted to UTC if there is an offset. */
export type DateTime = string;

export type Ulid = string;

/** The `Decimal` scalar type represents signed double-precision fractional values parsed by the `Decimal` library. The Decimal appears in a JSON response as a string to preserve precision. */
export type Decimal = string;

/** The `Json` scalar type represents arbitrary json string data, represented as UTF-8 character sequences. The Json type is most often used to represent a free-form human-readable json string. */
export type Json = string;

/** The `Date` scalar type represents a date. The Date appears in a JSON response as an ISO8601 formatted string, without a time component. */
export type Date = string;

/** Represents an uploaded file. */
export type Upload = any;

// ====================================================
// Documents
// ====================================================

export type GetDataForAccountSettingsVariables = {};

export type GetDataForAccountSettingsQuery = {
  __typename?: "Query";

  currentUser: Maybe<GetDataForAccountSettingsCurrentUser>;

  accountSettingsTab: GetDataForAccountSettingsAccountSettingsTab;
};

export type GetDataForAccountSettingsCurrentUser = {
  __typename?: "User";

  id: string;

  fullName: string;

  companyName: Maybe<string>;

  email: string;

  phone: Maybe<string>;

  avatarUrl: Maybe<string>;
};

export type GetDataForAccountSettingsAccountSettingsTab = {
  __typename: "AccountSettingsTab";

  active: boolean;
};

export type UpdatePasswordAtAccountSettingsVariables = {
  passwordParams: UpdatePasswordParams;
  userId: string;
};

export type UpdatePasswordAtAccountSettingsMutation = {
  __typename?: "Mutation";

  updatePassword: UpdatePasswordAtAccountSettingsUpdatePassword;
};

export type UpdatePasswordAtAccountSettingsUpdatePassword = {
  __typename?: "UserPayload";

  successful: boolean;

  messages: UpdatePasswordAtAccountSettingsMessages[];
};

export type UpdatePasswordAtAccountSettingsMessages = {
  __typename?: "ValidationMessage";

  field: Maybe<string>;

  message: Maybe<string>;

  template: Maybe<string>;
};

export type UpdateProfileAtAccountSettingsVariables = {
  userParams: UserParams;
  userId: string;
};

export type UpdateProfileAtAccountSettingsMutation = {
  __typename?: "Mutation";

  updateUser: UpdateProfileAtAccountSettingsUpdateUser;
};

export type UpdateProfileAtAccountSettingsUpdateUser = {
  __typename?: "UserPayload";

  successful: boolean;

  result: Maybe<UpdateProfileAtAccountSettingsResult>;

  messages: UpdateProfileAtAccountSettingsMessages[];
};

export type UpdateProfileAtAccountSettingsResult = {
  __typename?: "User";

  id: string;

  fullName: string;

  companyName: Maybe<string>;

  email: string;

  phone: Maybe<string>;

  avatarUrl: Maybe<string>;
};

export type UpdateProfileAtAccountSettingsMessages = {
  __typename?: "ValidationMessage";

  field: Maybe<string>;

  message: Maybe<string>;

  template: Maybe<string>;
};

export type GetDataForCurrentProjectIdVariables = {};

export type GetDataForCurrentProjectIdQuery = {
  __typename?: "Query";

  currentProjectId: Maybe<string>;
};

export type GetCurrentUserForOnlyAuthedVariables = {};

export type GetCurrentUserForOnlyAuthedQuery = {
  __typename?: "Query";

  currentUser: Maybe<GetCurrentUserForOnlyAuthedCurrentUser>;
};

export type GetCurrentUserForOnlyAuthedCurrentUser = {
  __typename?: "User";

  id: string;
};

export type GetCurrentUserIdVariables = {};

export type GetCurrentUserIdQuery = {
  __typename?: "Query";

  currentUserId: string;
};

export type DeleteFeedbackPollVariables = {
  feedbackPollId: string;
};

export type DeleteFeedbackPollMutation = {
  __typename?: "Mutation";

  deleteFeedbackPoll: Maybe<DeleteFeedbackPollDeleteFeedbackPoll>;
};

export type DeleteFeedbackPollDeleteFeedbackPoll = {
  __typename?: "FeedbackPoll";

  id: string;
};

export type DeleteFeedbackPollResponseVariables = {
  feedbackPollResponseId: string;
};

export type DeleteFeedbackPollResponseMutation = {
  __typename?: "Mutation";

  deleteFeedbackPollResponse: Maybe<
    DeleteFeedbackPollResponseDeleteFeedbackPollResponse
  >;
};

export type DeleteFeedbackPollResponseDeleteFeedbackPollResponse = {
  __typename?: "FeedbackPollResponse";

  id: string;
};

export type GetCurrentUserForTopBarVariables = {};

export type GetCurrentUserForTopBarQuery = {
  __typename?: "Query";

  currentUser: Maybe<GetCurrentUserForTopBarCurrentUser>;
};

export type GetCurrentUserForTopBarCurrentUser = {
  __typename?: "User";

  id: string;

  fullName: string;

  avatarUrl: Maybe<string>;
};

export type GetDataForNavbarVariables = {};

export type GetDataForNavbarQuery = {
  __typename?: "Query";

  navbar: GetDataForNavbarNavbar;
};

export type GetDataForNavbarNavbar = {
  __typename?: "Navbar";

  header: string;

  backPath: Maybe<string>;

  caption: Maybe<string>;
};

export type CreateNewProjectVariables = {
  projectParams: ProjectParams;
};

export type CreateNewProjectMutation = {
  __typename?: "Mutation";

  createProject: Maybe<CreateNewProjectCreateProject>;
};

export type CreateNewProjectCreateProject = {
  __typename?: "ProjectPayload";

  result: Maybe<CreateNewProjectResult>;

  messages: CreateNewProjectMessages[];

  successful: boolean;
};

export type CreateNewProjectResult = {
  __typename?: "Project";

  id: string;

  domain: string;
};

export type CreateNewProjectMessages = {
  __typename?: "ValidationMessage";

  message: Maybe<string>;

  field: Maybe<string>;
};

export type GetProjectsForDropdownVariables = {};

export type GetProjectsForDropdownQuery = {
  __typename?: "Query";

  currentUser: Maybe<GetProjectsForDropdownCurrentUser>;
};

export type GetProjectsForDropdownCurrentUser = {
  __typename?: "User";

  id: string;

  projects: GetProjectsForDropdownProjects;
};

export type GetProjectsForDropdownProjects = {
  __typename?: "ProjectsConnection";

  nodes: GetProjectsForDropdownNodes[];
};

export type GetProjectsForDropdownNodes = {
  __typename?: "Project";

  id: string;

  domain: string;
};

export type DeleteGoalVariables = {
  goalId: string;
};

export type DeleteGoalMutation = {
  __typename?: "Mutation";

  deleteGoal: Maybe<DeleteGoalDeleteGoal>;
};

export type DeleteGoalDeleteGoal = DeleteGoalPathGoalInlineFragment;

export type DeleteGoalPathGoalInlineFragment = {
  __typename?: "PathGoal";

  id: string;
};

export type DeleteProjectVariables = {
  projectId: string;
};

export type DeleteProjectMutation = {
  __typename?: "Mutation";

  deleteProject: Maybe<DeleteProjectDeleteProject>;
};

export type DeleteProjectDeleteProject = {
  __typename?: "Project";

  id: string;
};

export type UpdateProjectVariables = {
  projectId: string;
  params: ProjectParams;
};

export type UpdateProjectMutation = {
  __typename?: "Mutation";

  updateProject: Maybe<UpdateProjectUpdateProject>;
};

export type UpdateProjectUpdateProject = {
  __typename?: "ProjectPayload";

  result: Maybe<UpdateProjectResult>;

  messages: UpdateProjectMessages[];

  successful: boolean;
};

export type UpdateProjectResult = {
  __typename?: "Project";

  id: string;

  domain: string;

  isRecordingEnabled: boolean;
};

export type UpdateProjectMessages = {
  __typename?: "ValidationMessage";

  message: Maybe<string>;

  field: Maybe<string>;
};

export type GetDataForFeedbackPollEditPageVariables = {
  projectId: string;
  feedbackPollId: string;
};

export type GetDataForFeedbackPollEditPageQuery = {
  __typename?: "Query";

  currentUser: Maybe<GetDataForFeedbackPollEditPageCurrentUser>;
};

export type GetDataForFeedbackPollEditPageCurrentUser = {
  __typename?: "User";

  id: string;

  project: Maybe<GetDataForFeedbackPollEditPageProject>;
};

export type GetDataForFeedbackPollEditPageProject = {
  __typename?: "Project";

  id: string;

  feedbackPoll: GetDataForFeedbackPollEditPageFeedbackPoll;
};

export type GetDataForFeedbackPollEditPageFeedbackPoll = FeedbackForFormFragment;

export type UpdateFeedbackPollAtFeedbackPollEditPageVariables = {
  feedbackPollId: string;
  feedbackPollParams: FeedbackPollParams;
};

export type UpdateFeedbackPollAtFeedbackPollEditPageMutation = {
  __typename?: "Mutation";

  updateFeedbackPoll: Maybe<
    UpdateFeedbackPollAtFeedbackPollEditPageUpdateFeedbackPoll
  >;
};

export type UpdateFeedbackPollAtFeedbackPollEditPageUpdateFeedbackPoll = {
  __typename?: "FeedbackPollPayload";

  result: Maybe<UpdateFeedbackPollAtFeedbackPollEditPageResult>;

  messages: UpdateFeedbackPollAtFeedbackPollEditPageMessages[];

  successful: boolean;
};

export type UpdateFeedbackPollAtFeedbackPollEditPageResult = FeedbackForFormFragment;

export type UpdateFeedbackPollAtFeedbackPollEditPageMessages = {
  __typename?: "ValidationMessage";

  message: Maybe<string>;

  field: Maybe<string>;
};

export type GetDataForFeedbackPollIndexPageVariables = {
  projectId: string;
};

export type GetDataForFeedbackPollIndexPageQuery = {
  __typename?: "Query";

  currentUser: Maybe<GetDataForFeedbackPollIndexPageCurrentUser>;
};

export type GetDataForFeedbackPollIndexPageCurrentUser = {
  __typename?: "User";

  id: string;

  project: Maybe<GetDataForFeedbackPollIndexPageProject>;
};

export type GetDataForFeedbackPollIndexPageProject = {
  __typename?: "Project";

  id: string;

  feedbackPolls: GetDataForFeedbackPollIndexPageFeedbackPolls;
};

export type GetDataForFeedbackPollIndexPageFeedbackPolls = {
  __typename?: "ProjectFeedbackPollsConnection";

  nodes: GetDataForFeedbackPollIndexPageNodes[];
};

export type GetDataForFeedbackPollIndexPageNodes = {
  __typename?: "FeedbackPoll";

  id: string;

  name: string;

  isActive: boolean;

  responsesCount: number;

  createdAt: string;
};

export type CreateFeedbackPollAtFeedbackPollNewPageVariables = {
  projectId: string;
  feedbackPollParams: FeedbackPollParams;
};

export type CreateFeedbackPollAtFeedbackPollNewPageMutation = {
  __typename?: "Mutation";

  createFeedbackPoll: Maybe<
    CreateFeedbackPollAtFeedbackPollNewPageCreateFeedbackPoll
  >;
};

export type CreateFeedbackPollAtFeedbackPollNewPageCreateFeedbackPoll = {
  __typename?: "FeedbackPollPayload";

  result: Maybe<CreateFeedbackPollAtFeedbackPollNewPageResult>;

  messages: CreateFeedbackPollAtFeedbackPollNewPageMessages[];

  successful: boolean;
};

export type CreateFeedbackPollAtFeedbackPollNewPageResult = FeedbackForFormFragment;

export type CreateFeedbackPollAtFeedbackPollNewPageMessages = {
  __typename?: "ValidationMessage";

  message: Maybe<string>;

  field: Maybe<string>;
};

export type GetDataForFeedbackPollResponseIndexPageVariables = {
  projectId: string;
  feedbackPollId: string;
  pagination: CursorPagination;
};

export type GetDataForFeedbackPollResponseIndexPageQuery = {
  __typename?: "Query";

  currentUser: Maybe<GetDataForFeedbackPollResponseIndexPageCurrentUser>;
};

export type GetDataForFeedbackPollResponseIndexPageCurrentUser = {
  __typename?: "User";

  id: string;

  project: Maybe<GetDataForFeedbackPollResponseIndexPageProject>;
};

export type GetDataForFeedbackPollResponseIndexPageProject = {
  __typename?: "Project";

  id: string;

  feedbackPoll: GetDataForFeedbackPollResponseIndexPageFeedbackPoll;
};

export type GetDataForFeedbackPollResponseIndexPageFeedbackPoll = {
  __typename?: "FeedbackPoll";

  id: string;

  name: string;

  questions: GetDataForFeedbackPollResponseIndexPageQuestions[];

  responses: GetDataForFeedbackPollResponseIndexPageResponses;
};

export type GetDataForFeedbackPollResponseIndexPageQuestions =
  | GetDataForFeedbackPollResponseIndexPageFeedbackPollShortTextQuestionInlineFragment
  | GetDataForFeedbackPollResponseIndexPageFeedbackPollLongTextQuestionInlineFragment;

export type GetDataForFeedbackPollResponseIndexPageFeedbackPollShortTextQuestionInlineFragment = {
  __typename?: "FeedbackPollShortTextQuestion";

  id: string;

  title: string;
};

export type GetDataForFeedbackPollResponseIndexPageFeedbackPollLongTextQuestionInlineFragment = {
  __typename?: "FeedbackPollLongTextQuestion";

  id: string;

  title: string;
};

export type GetDataForFeedbackPollResponseIndexPageResponses = {
  __typename?: "FeedbackPollResponsesConnection";

  nodes: GetDataForFeedbackPollResponseIndexPageNodes[];

  pageInfo: GetDataForFeedbackPollResponseIndexPagePageInfo;
};

export type GetDataForFeedbackPollResponseIndexPageNodes = {
  __typename?: "FeedbackPollResponse";

  id: string;

  pageUrl: string;

  answers: GetDataForFeedbackPollResponseIndexPageAnswers[];

  createdAt: string;

  sessionRecording: Maybe<
    GetDataForFeedbackPollResponseIndexPageSessionRecording
  >;
};

export type GetDataForFeedbackPollResponseIndexPageAnswers = {
  __typename?: "FeedbackPollResponseAnswer";

  id: string;

  content: string;

  question: GetDataForFeedbackPollResponseIndexPageQuestion;
};

export type GetDataForFeedbackPollResponseIndexPageQuestion =
  | GetDataForFeedbackPollResponseIndexPage_FeedbackPollShortTextQuestionInlineFragment
  | GetDataForFeedbackPollResponseIndexPage_FeedbackPollLongTextQuestionInlineFragment;

export type GetDataForFeedbackPollResponseIndexPage_FeedbackPollShortTextQuestionInlineFragment = {
  __typename?: "FeedbackPollShortTextQuestion";

  id: string;
};

export type GetDataForFeedbackPollResponseIndexPage_FeedbackPollLongTextQuestionInlineFragment = {
  __typename?: "FeedbackPollLongTextQuestion";

  id: string;
};

export type GetDataForFeedbackPollResponseIndexPageSessionRecording = {
  __typename?: "SessionRecording";

  id: string;
};

export type GetDataForFeedbackPollResponseIndexPagePageInfo = {
  __typename?: "CursorPageInfo";

  before: Maybe<string>;

  after: Maybe<string>;

  hasNextPage: boolean;

  hasPreviousPage: boolean;
};

export type GetDataForGoalEditPageVariables = {
  projectId: string;
  goalId: string;
};

export type GetDataForGoalEditPageQuery = {
  __typename?: "Query";

  currentUser: Maybe<GetDataForGoalEditPageCurrentUser>;
};

export type GetDataForGoalEditPageCurrentUser = {
  __typename?: "User";

  id: string;

  project: Maybe<GetDataForGoalEditPageProject>;
};

export type GetDataForGoalEditPageProject = {
  __typename?: "Project";

  id: string;

  goal: Maybe<GetDataForGoalEditPageGoal>;
};

export type GetDataForGoalEditPageGoal = GoalForEditGoalPageFragment;

export type UpdateGoalVariables = {
  params: GoalParams;
  id: string;
};

export type UpdateGoalMutation = {
  __typename?: "Mutation";

  updateGoal: Maybe<UpdateGoalUpdateGoal>;
};

export type UpdateGoalUpdateGoal = {
  __typename?: "GoalPayload";

  result: Maybe<UpdateGoalResult>;

  messages: UpdateGoalMessages[];

  successful: boolean;
};

export type UpdateGoalResult = GoalForEditGoalPageFragment;

export type UpdateGoalMessages = {
  __typename?: "ValidationMessage";

  message: Maybe<string>;

  field: Maybe<string>;
};

export type GetDataForGoalListPageVariables = {
  projectId: string;
};

export type GetDataForGoalListPageQuery = {
  __typename?: "Query";

  currentUser: Maybe<GetDataForGoalListPageCurrentUser>;
};

export type GetDataForGoalListPageCurrentUser = {
  __typename?: "User";

  id: string;

  project: Maybe<GetDataForGoalListPageProject>;
};

export type GetDataForGoalListPageProject = {
  __typename?: "Project";

  id: string;

  goals: GetDataForGoalListPageGoals;
};

export type GetDataForGoalListPageGoals = {
  __typename?: "GoalsConnection";

  nodes: GetDataForGoalListPageNodes[];
};

export type GetDataForGoalListPageNodes = GetDataForGoalListPagePathGoalInlineFragment;

export type GetDataForGoalListPagePathGoalInlineFragment = {
  __typename?: "PathGoal";

  id: string;

  name: string;

  createdAt: string;

  conversionsCount: number;

  path: GetDataForGoalListPagePath[];
};

export type GetDataForGoalListPagePath = {
  __typename?: "PathGoalStep";

  url: string;

  matchType: PageMatcherMatchType;

  index: number;
};

export type CreateGoalVariables = {
  params: GoalParams;
  projectId: string;
};

export type CreateGoalMutation = {
  __typename?: "Mutation";

  createGoal: Maybe<CreateGoalCreateGoal>;
};

export type CreateGoalCreateGoal = {
  __typename?: "GoalPayload";

  result: Maybe<CreateGoalResult>;

  messages: CreateGoalMessages[];

  successful: boolean;
};

export type CreateGoalResult = CreateGoalGoalEntityInlineFragment;

export type CreateGoalGoalEntityInlineFragment = {
  __typename?: "GoalEntity";

  id: string;
};

export type CreateGoalMessages = {
  __typename?: "ValidationMessage";

  message: Maybe<string>;

  field: Maybe<string>;
};

export type GetDataForHeatmapIndexPageVariables = {
  projectId: string;
  perPage: number;
  page: number;
  filter: HeatmapPageFilter;
  order: HeatmapPageOrder;
};

export type GetDataForHeatmapIndexPageQuery = {
  __typename?: "Query";

  currentUser: Maybe<GetDataForHeatmapIndexPageCurrentUser>;
};

export type GetDataForHeatmapIndexPageCurrentUser = {
  __typename?: "User";

  id: string;

  project: Maybe<GetDataForHeatmapIndexPageProject>;
};

export type GetDataForHeatmapIndexPageProject = {
  __typename?: "Project";

  id: string;

  heatmapPagesConnection: GetDataForHeatmapIndexPageHeatmapPagesConnection;
};

export type GetDataForHeatmapIndexPageHeatmapPagesConnection = {
  __typename?: "HeatmapPagesConnection";

  heatmapPages: GetDataForHeatmapIndexPageHeatmapPages[];

  totalRecords: number;
};

export type GetDataForHeatmapIndexPageHeatmapPages = {
  __typename?: "HeatmapPage";

  id: string;

  url: string;

  viewsCount: GetDataForHeatmapIndexPageViewsCount;

  createdAt: string;
};

export type GetDataForHeatmapIndexPageViewsCount = {
  __typename?: "HeatmapViewsCount";

  total: number;

  desktop: number;

  mobile: number;

  tablet: number;
};

export type GetDataForHeatmapShowPageVariables = {
  projectId: string;
  heatmapPageId: string;
  deviceType: DeviceType;
};

export type GetDataForHeatmapShowPageQuery = {
  __typename?: "Query";

  currentUser: Maybe<GetDataForHeatmapShowPageCurrentUser>;
};

export type GetDataForHeatmapShowPageCurrentUser = {
  __typename?: "User";

  id: string;

  project: Maybe<GetDataForHeatmapShowPageProject>;
};

export type GetDataForHeatmapShowPageProject = {
  __typename?: "Project";

  id: string;

  heatmapPage: GetDataForHeatmapShowPageHeatmapPage;
};

export type GetDataForHeatmapShowPageHeatmapPage = {
  __typename?: "HeatmapPage";

  id: string;

  url: string;

  viewsCount: GetDataForHeatmapShowPageViewsCount;

  snapshot: GetDataForHeatmapShowPageSnapshot;
};

export type GetDataForHeatmapShowPageViewsCount = {
  __typename?: "HeatmapViewsCount";

  desktop: number;

  mobile: number;

  tablet: number;
};

export type GetDataForHeatmapShowPageSnapshot = {
  __typename?: "HeatmapSnapshot";

  deviceType: DeviceType;

  domNodes: string;

  docType: string;

  elements: GetDataForHeatmapShowPageElements[];
};

export type GetDataForHeatmapShowPageElements = {
  __typename?: "HeatmapElement";

  selector: string;

  clickPoints: GetDataForHeatmapShowPageClickPoints[];
};

export type GetDataForHeatmapShowPageClickPoints = {
  __typename?: "HeatmapClickPoint";

  percentX: number;

  percentY: number;

  count: number;
};

export type GetDataForProjectEditPageVariables = {
  projectId: string;
};

export type GetDataForProjectEditPageQuery = {
  __typename?: "Query";

  currentUser: Maybe<GetDataForProjectEditPageCurrentUser>;
};

export type GetDataForProjectEditPageCurrentUser = {
  __typename?: "User";

  id: string;

  project: Maybe<GetDataForProjectEditPageProject>;
};

export type GetDataForProjectEditPageProject = {
  __typename?: "Project";

  id: string;

  domain: string;

  isRecordingEnabled: boolean;
};

export type ChangeProjectSessionRecordingsStatusVariables = {
  projectId: string;
  active: boolean;
};

export type ChangeProjectSessionRecordingsStatusMutation = {
  __typename?: "Mutation";

  updateProject: Maybe<ChangeProjectSessionRecordingsStatusUpdateProject>;
};

export type ChangeProjectSessionRecordingsStatusUpdateProject = {
  __typename?: "ProjectPayload";

  result: Maybe<ChangeProjectSessionRecordingsStatusResult>;

  messages: ChangeProjectSessionRecordingsStatusMessages[];

  successful: boolean;
};

export type ChangeProjectSessionRecordingsStatusResult = {
  __typename?: "Project";

  id: string;

  isRecordingEnabled: boolean;
};

export type ChangeProjectSessionRecordingsStatusMessages = {
  __typename?: "ValidationMessage";

  message: Maybe<string>;

  field: Maybe<string>;
};

export type GetProjectForProjectPageVariables = {
  projectId: string;
};

export type GetProjectForProjectPageQuery = {
  __typename?: "Query";

  billingProducts: GetProjectForProjectPageBillingProducts[];

  currentUser: Maybe<GetProjectForProjectPageCurrentUser>;
};

export type GetProjectForProjectPageBillingProducts = {
  __typename?: "BillingProduct";

  name: string;

  duration: Duration;

  id: string;

  usage: GetProjectForProjectPageUsage;
};

export type GetProjectForProjectPageUsage = {
  __typename?: "BillingUsage";

  feedbackPollEnabled: boolean;
};

export type GetProjectForProjectPageCurrentUser = {
  __typename?: "User";

  id: string;

  currentBilling: GetProjectForProjectPageCurrentBilling;

  project: Maybe<GetProjectForProjectPageProject>;
};

export type GetProjectForProjectPageCurrentBilling = {
  __typename?: "BillingInfo";

  thisMonthRecordingsCount: number;

  currentUsage: GetProjectForProjectPageCurrentUsage;

  subscription: Maybe<GetProjectForProjectPage_Subscription>;
};

export type GetProjectForProjectPageCurrentUsage = {
  __typename?: "BillingUsage";

  feedbackPollEnabled: boolean;

  fullIdeaLab: boolean;

  heatmapEnabled: boolean;

  sessionRecordingsEnabled: boolean;

  sessionRecordingsStoreDays: number;

  visitorsPerMonth: number;
};

export type GetProjectForProjectPage_Subscription = {
  __typename?: "BillingSubscription";

  nextBillingDate: Maybe<string>;

  product: GetProjectForProjectPageProduct;
};

export type GetProjectForProjectPageProduct = {
  __typename?: "BillingProduct";

  id: string;

  duration: Duration;

  name: string;
};

export type GetProjectForProjectPageProject = {
  __typename?: "Project";

  id: string;

  domain: string;

  state: ProjectState;

  isRecordingEnabled: boolean;

  sessionRecordings: GetProjectForProjectPageSessionRecordings;

  goals: GetProjectForProjectPageGoals;

  splitTestsConnection: GetProjectForProjectPageSplitTestsConnection;

  feedbackPolls: GetProjectForProjectPageFeedbackPolls;

  heatmapPagesConnection: GetProjectForProjectPageHeatmapPagesConnection;
};

export type GetProjectForProjectPageSessionRecordings = {
  __typename?: "SessionRecordingConnection";

  totalRecords: number;

  avgSessionTime: number;

  avgSessionClicks: number;
};

export type GetProjectForProjectPageGoals = {
  __typename?: "GoalsConnection";

  totalRecords: number;
};

export type GetProjectForProjectPageSplitTestsConnection = {
  __typename?: "SplitTestsConnection";

  activeTests: number;

  finishedTests: number;

  totalRecords: number;
};

export type GetProjectForProjectPageFeedbackPolls = {
  __typename?: "ProjectFeedbackPollsConnection";

  totalRecords: number;

  activePollsCount: number;

  responsesCount: number;
};

export type GetProjectForProjectPageHeatmapPagesConnection = {
  __typename?: "HeatmapPagesConnection";

  totalRecords: number;
};

export type GetRelevantProjectForRedirectPageVariables = {};

export type GetRelevantProjectForRedirectPageQuery = {
  __typename?: "Query";

  currentUser: Maybe<GetRelevantProjectForRedirectPageCurrentUser>;
};

export type GetRelevantProjectForRedirectPageCurrentUser = {
  __typename?: "User";

  id: string;

  relevantProject: Maybe<GetRelevantProjectForRedirectPageRelevantProject>;
};

export type GetRelevantProjectForRedirectPageRelevantProject = {
  __typename?: "Project";

  id: string;
};

export type GetProjectForProjectSetupPageVariables = {
  projectId: string;
};

export type GetProjectForProjectSetupPageQuery = {
  __typename?: "Query";

  currentUser: Maybe<GetProjectForProjectSetupPageCurrentUser>;
};

export type GetProjectForProjectSetupPageCurrentUser = {
  __typename?: "User";

  id: string;

  project: Maybe<GetProjectForProjectSetupPageProject>;
};

export type GetProjectForProjectSetupPageProject = {
  __typename?: "Project";

  id: string;

  domain: string;
};

export type GetSessionRecordingsForListPageVariables = {
  projectId: string;
  perPage: number;
  page: number;
  filter: SessionRecordingFilter;
};

export type GetSessionRecordingsForListPageQuery = {
  __typename?: "Query";

  currentUser: Maybe<GetSessionRecordingsForListPageCurrentUser>;
};

export type GetSessionRecordingsForListPageCurrentUser = {
  __typename?: "User";

  id: string;

  project: Maybe<GetSessionRecordingsForListPageProject>;
};

export type GetSessionRecordingsForListPageProject = {
  __typename?: "Project";

  id: string;

  goals: GetSessionRecordingsForListPageGoals;

  sessionRecordings: GetSessionRecordingsForListPageSessionRecordings;
};

export type GetSessionRecordingsForListPageGoals = {
  __typename?: "GoalsConnection";

  nodes: GetSessionRecordingsForListPageNodes[];
};

export type GetSessionRecordingsForListPageNodes = GetSessionRecordingsForListPageGoalEntityInlineFragment;

export type GetSessionRecordingsForListPageGoalEntityInlineFragment = {
  __typename?: "GoalEntity";

  id: string;

  name: string;
};

export type GetSessionRecordingsForListPageSessionRecordings = {
  __typename?: "SessionRecordingConnection";

  totalRecords: number;

  nodes: GetSessionRecordingsForListPage_Nodes[];
};

export type GetSessionRecordingsForListPage_Nodes = SessionRecordingForListFragment;

export type GetSessionRecordingForRecordingPageVariables = {
  projectId: string;
  sessionRecordingId: string;
};

export type GetSessionRecordingForRecordingPageQuery = {
  __typename?: "Query";

  currentUser: Maybe<GetSessionRecordingForRecordingPageCurrentUser>;
};

export type GetSessionRecordingForRecordingPageCurrentUser = {
  __typename?: "User";

  id: string;

  project: Maybe<GetSessionRecordingForRecordingPageProject>;
};

export type GetSessionRecordingForRecordingPageProject = {
  __typename?: "Project";

  id: string;

  sessionRecording: Maybe<GetSessionRecordingForRecordingPageSessionRecording>;
};

export type GetSessionRecordingForRecordingPageSessionRecording = {
  __typename?: "SessionRecording";

  id: string;

  isVisitorOnline: boolean;

  referrer: Maybe<string>;

  referrerSource: ReferrerSource;

  duration: number;

  createdAt: string;

  projectId: string;

  startingPage: GetSessionRecordingForRecordingPageStartingPage;

  pages: GetSessionRecordingForRecordingPagePages[];

  device: GetSessionRecordingForRecordingPageDevice;

  location: GetSessionRecordingForRecordingPageLocation;

  convertedProjectGoals: GetSessionRecordingForRecordingPageConvertedProjectGoals[];

  splitTestEdges: GetSessionRecordingForRecordingPageSplitTestEdges[];

  feedbackPollResponses: GetSessionRecordingForRecordingPageFeedbackPollResponses[];

  events: GetSessionRecordingForRecordingPageEvents[];
};

export type GetSessionRecordingForRecordingPageStartingPage = {
  __typename?: "SessionRecordingPage";

  id: string;

  title: string;

  url: string;
};

export type GetSessionRecordingForRecordingPagePages = {
  __typename?: "SessionRecordingPage";

  id: string;

  title: string;

  url: string;

  duration: number;

  visitedAt: string;
};

export type GetSessionRecordingForRecordingPageDevice = {
  __typename?: "SessionRecordingDevice";

  id: string;

  screenWidth: number;

  screenHeight: number;

  userAgent: string;

  os: GetSessionRecordingForRecordingPageOs;

  browser: GetSessionRecordingForRecordingPageBrowser;

  deviceType: DeviceType;
};

export type GetSessionRecordingForRecordingPageOs = {
  __typename?: "DeviceOs";

  name: Maybe<string>;

  version: Maybe<string>;
};

export type GetSessionRecordingForRecordingPageBrowser = {
  __typename?: "DeviceBrowser";

  name: Maybe<string>;

  version: Maybe<string>;
};

export type GetSessionRecordingForRecordingPageLocation = {
  __typename?: "SessionRecordingLocation";

  id: string;

  city: Maybe<string>;

  country: Maybe<string>;

  ip: string;
};

export type GetSessionRecordingForRecordingPageConvertedProjectGoals = GetSessionRecordingForRecordingPageGoalEntityInlineFragment;

export type GetSessionRecordingForRecordingPageGoalEntityInlineFragment = {
  __typename?: "GoalEntity";

  id: string;

  name: string;
};

export type GetSessionRecordingForRecordingPageSplitTestEdges = {
  __typename?: "SplitTestEdge";

  node: GetSessionRecordingForRecordingPageNode;

  visitedVariation: GetSessionRecordingForRecordingPageVisitedVariation;

  convertedGoals: GetSessionRecordingForRecordingPageConvertedGoals[];
};

export type GetSessionRecordingForRecordingPageNode = {
  __typename?: "SplitTest";

  id: string;

  name: string;
};

export type GetSessionRecordingForRecordingPageVisitedVariation = {
  __typename?: "SplitTestVariation";

  id: string;

  name: string;

  url: string;
};

export type GetSessionRecordingForRecordingPageConvertedGoals = GetSessionRecordingForRecordingPage_GoalEntityInlineFragment;

export type GetSessionRecordingForRecordingPage_GoalEntityInlineFragment = {
  __typename?: "GoalEntity";

  id: string;

  name: string;
};

export type GetSessionRecordingForRecordingPageFeedbackPollResponses = {
  __typename?: "FeedbackPollResponse";

  id: string;

  firstInteractionAt: string;

  answers: GetSessionRecordingForRecordingPageAnswers[];

  feedbackPoll: GetSessionRecordingForRecordingPageFeedbackPoll;
};

export type GetSessionRecordingForRecordingPageAnswers = {
  __typename?: "FeedbackPollResponseAnswer";

  id: string;

  content: string;

  answeredAt: string;

  question: GetSessionRecordingForRecordingPageQuestion;
};

export type GetSessionRecordingForRecordingPageQuestion =
  | GetSessionRecordingForRecordingPageFeedbackPollShortTextQuestionInlineFragment
  | GetSessionRecordingForRecordingPageFeedbackPollLongTextQuestionInlineFragment;

export type GetSessionRecordingForRecordingPageFeedbackPollShortTextQuestionInlineFragment = {
  __typename?: "FeedbackPollShortTextQuestion";

  id: string;

  title: string;
};

export type GetSessionRecordingForRecordingPageFeedbackPollLongTextQuestionInlineFragment = {
  __typename?: "FeedbackPollLongTextQuestion";

  id: string;

  title: string;
};

export type GetSessionRecordingForRecordingPageFeedbackPoll = {
  __typename?: "FeedbackPoll";

  id: string;

  name: string;
};

export type GetSessionRecordingForRecordingPageEvents = {
  __typename?: "SessionRecordingEvent";

  data: string;

  happenedAt: string;

  type: SessionRecordingEventType;
};

export type GetDataForSplitTestEditPageVariables = {
  splitTestId: string;
  projectId: string;
};

export type GetDataForSplitTestEditPageQuery = {
  __typename?: "Query";

  pageCategories: GetDataForSplitTestEditPagePageCategories[];

  pageTypes: GetDataForSplitTestEditPagePageTypes[];

  testIdeas: GetDataForSplitTestEditPageTestIdeas[];

  currentUser: Maybe<GetDataForSplitTestEditPageCurrentUser>;
};

export type GetDataForSplitTestEditPagePageCategories = {
  __typename?: "PageCategory";

  id: string;

  name: string;
};

export type GetDataForSplitTestEditPagePageTypes = {
  __typename?: "PageType";

  id: string;

  name: string;
};

export type GetDataForSplitTestEditPageTestIdeas = {
  __typename?: "TestIdea";

  id: string;

  name: string;
};

export type GetDataForSplitTestEditPageCurrentUser = {
  __typename?: "User";

  id: string;

  project: Maybe<GetDataForSplitTestEditPageProject>;
};

export type GetDataForSplitTestEditPageProject = {
  __typename?: "Project";

  id: string;

  splitTest: Maybe<GetDataForSplitTestEditPageSplitTest>;
};

export type GetDataForSplitTestEditPageSplitTest = SplitTestForEditSplitTestPageFragment;

export type ActivateSplitTestAtSplitTestEditPageVariables = {
  splitTestId: string;
};

export type ActivateSplitTestAtSplitTestEditPageMutation = {
  __typename?: "Mutation";

  activateSplitTest: Maybe<
    ActivateSplitTestAtSplitTestEditPageActivateSplitTest
  >;
};

export type ActivateSplitTestAtSplitTestEditPageActivateSplitTest = SplitTestForEditSplitTestPageFragment;

export type FinishSplitTestAtSplitTestEditPageVariables = {
  splitTestId: string;
};

export type FinishSplitTestAtSplitTestEditPageMutation = {
  __typename?: "Mutation";

  finishSplitTest: Maybe<FinishSplitTestAtSplitTestEditPageFinishSplitTest>;
};

export type FinishSplitTestAtSplitTestEditPageFinishSplitTest = SplitTestForEditSplitTestPageFragment;

export type PauseSplitTestAtSplitTestEditPageVariables = {
  splitTestId: string;
};

export type PauseSplitTestAtSplitTestEditPageMutation = {
  __typename?: "Mutation";

  pauseSplitTest: Maybe<PauseSplitTestAtSplitTestEditPagePauseSplitTest>;
};

export type PauseSplitTestAtSplitTestEditPagePauseSplitTest = SplitTestForEditSplitTestPageFragment;

export type UpdateSplitTestAtSplitTestEditPageVariables = {
  splitTestId: string;
  splitTestParams: SplitTestParams;
};

export type UpdateSplitTestAtSplitTestEditPageMutation = {
  __typename?: "Mutation";

  updateSplitTest: Maybe<UpdateSplitTestAtSplitTestEditPageUpdateSplitTest>;
};

export type UpdateSplitTestAtSplitTestEditPageUpdateSplitTest = {
  __typename?: "SplitTestPayload";

  result: Maybe<UpdateSplitTestAtSplitTestEditPageResult>;

  messages: UpdateSplitTestAtSplitTestEditPageMessages[];

  successful: boolean;
};

export type UpdateSplitTestAtSplitTestEditPageResult = SplitTestForEditSplitTestPageFragment;

export type UpdateSplitTestAtSplitTestEditPageMessages = {
  __typename?: "ValidationMessage";

  message: Maybe<string>;

  field: Maybe<string>;
};

export type GetDataForSplitTestIndexPageVariables = {
  projectId: string;
  conversionGoalId?: Maybe<string>;
  perPage: number;
  page: number;
  filter: SplitTestFilter;
};

export type GetDataForSplitTestIndexPageQuery = {
  __typename?: "Query";

  pageCategories: GetDataForSplitTestIndexPagePageCategories[];

  pageTypes: GetDataForSplitTestIndexPagePageTypes[];

  currentUser: Maybe<GetDataForSplitTestIndexPageCurrentUser>;
};

export type GetDataForSplitTestIndexPagePageCategories = {
  __typename?: "PageCategory";

  id: string;

  name: string;
};

export type GetDataForSplitTestIndexPagePageTypes = {
  __typename?: "PageType";

  id: string;

  name: string;
};

export type GetDataForSplitTestIndexPageCurrentUser = {
  __typename?: "User";

  id: string;

  project: Maybe<GetDataForSplitTestIndexPageProject>;
};

export type GetDataForSplitTestIndexPageProject = {
  __typename?: "Project";

  id: string;

  splitTestsConnection: GetDataForSplitTestIndexPageSplitTestsConnection;
};

export type GetDataForSplitTestIndexPageSplitTestsConnection = {
  __typename?: "SplitTestsConnection";

  splitTests: GetDataForSplitTestIndexPageSplitTests[];

  totalRecords: number;
};

export type GetDataForSplitTestIndexPageSplitTests = SplitTestForIndexSplitTestPageFragment;

export type GetSplitTestForSplitTestIndexPageVariables = {
  splitTestId: string;
  conversionGoalId: string;
  projectId: string;
};

export type GetSplitTestForSplitTestIndexPageQuery = {
  __typename?: "Query";

  currentUser: Maybe<GetSplitTestForSplitTestIndexPageCurrentUser>;
};

export type GetSplitTestForSplitTestIndexPageCurrentUser = {
  __typename?: "User";

  id: string;

  project: Maybe<GetSplitTestForSplitTestIndexPageProject>;
};

export type GetSplitTestForSplitTestIndexPageProject = {
  __typename?: "Project";

  id: string;

  splitTest: Maybe<GetSplitTestForSplitTestIndexPageSplitTest>;
};

export type GetSplitTestForSplitTestIndexPageSplitTest = SplitTestForIndexSplitTestPageFragment;

export type ActivateSplitTestAtSplitTestIndexPageVariables = {
  splitTestId: string;
  conversionGoalId?: Maybe<string>;
};

export type ActivateSplitTestAtSplitTestIndexPageMutation = {
  __typename?: "Mutation";

  activateSplitTest: Maybe<
    ActivateSplitTestAtSplitTestIndexPageActivateSplitTest
  >;
};

export type ActivateSplitTestAtSplitTestIndexPageActivateSplitTest = SplitTestForIndexSplitTestPageFragment;

export type FinishSplitTestAtSplitTestIndexPageVariables = {
  splitTestId: string;
  conversionGoalId?: Maybe<string>;
};

export type FinishSplitTestAtSplitTestIndexPageMutation = {
  __typename?: "Mutation";

  finishSplitTest: Maybe<FinishSplitTestAtSplitTestIndexPageFinishSplitTest>;
};

export type FinishSplitTestAtSplitTestIndexPageFinishSplitTest = SplitTestForIndexSplitTestPageFragment;

export type PauseSplitTestAtSplitTestIndexPageVariables = {
  splitTestId: string;
  conversionGoalId?: Maybe<string>;
};

export type PauseSplitTestAtSplitTestIndexPageMutation = {
  __typename?: "Mutation";

  pauseSplitTest: Maybe<PauseSplitTestAtSplitTestIndexPagePauseSplitTest>;
};

export type PauseSplitTestAtSplitTestIndexPagePauseSplitTest = SplitTestForIndexSplitTestPageFragment;

export type CreateSplitTestAtSplitTestCreatePageVariables = {
  projectId: string;
  splitTestParams: SplitTestParams;
};

export type CreateSplitTestAtSplitTestCreatePageMutation = {
  __typename?: "Mutation";

  createSplitTest: Maybe<CreateSplitTestAtSplitTestCreatePageCreateSplitTest>;
};

export type CreateSplitTestAtSplitTestCreatePageCreateSplitTest = {
  __typename?: "SplitTestPayload";

  result: Maybe<CreateSplitTestAtSplitTestCreatePageResult>;

  messages: CreateSplitTestAtSplitTestCreatePageMessages[];

  successful: boolean;
};

export type CreateSplitTestAtSplitTestCreatePageResult = {
  __typename?: "SplitTest";

  id: string;

  name: string;

  description: string;

  pageCategory: CreateSplitTestAtSplitTestCreatePagePageCategory;

  pageType: CreateSplitTestAtSplitTestCreatePagePageType;
};

export type CreateSplitTestAtSplitTestCreatePagePageCategory = {
  __typename?: "PageCategory";

  id: string;

  name: string;
};

export type CreateSplitTestAtSplitTestCreatePagePageType = {
  __typename?: "PageType";

  id: string;

  name: string;
};

export type CreateSplitTestAtSplitTestCreatePageMessages = {
  __typename?: "ValidationMessage";

  message: Maybe<string>;

  field: Maybe<string>;
};

export type GetDataForSplitTestNewPageVariables = {};

export type GetDataForSplitTestNewPageQuery = {
  __typename?: "Query";

  testIdeas: GetDataForSplitTestNewPageTestIdeas[];

  pageCategories: GetDataForSplitTestNewPagePageCategories[];

  pageTypes: GetDataForSplitTestNewPagePageTypes[];
};

export type GetDataForSplitTestNewPageTestIdeas = {
  __typename?: "TestIdea";

  id: string;

  name: string;
};

export type GetDataForSplitTestNewPagePageCategories = {
  __typename?: "PageCategory";

  id: string;

  name: string;
};

export type GetDataForSplitTestNewPagePageTypes = {
  __typename?: "PageType";

  id: string;

  name: string;
};

export type GetDataForSplitTestSessionRecordingIndexPageVariables = {
  projectId: string;
  splitTestId: string;
  perPage: number;
  page: number;
  filter: SessionRecordingFilter;
};

export type GetDataForSplitTestSessionRecordingIndexPageQuery = {
  __typename?: "Query";

  currentUser: Maybe<GetDataForSplitTestSessionRecordingIndexPageCurrentUser>;
};

export type GetDataForSplitTestSessionRecordingIndexPageCurrentUser = {
  __typename?: "User";

  id: string;

  project: Maybe<GetDataForSplitTestSessionRecordingIndexPageProject>;
};

export type GetDataForSplitTestSessionRecordingIndexPageProject = {
  __typename?: "Project";

  id: string;

  sessionRecordings: GetDataForSplitTestSessionRecordingIndexPageSessionRecordings;

  splitTest: Maybe<GetDataForSplitTestSessionRecordingIndexPageSplitTest>;
};

export type GetDataForSplitTestSessionRecordingIndexPageSessionRecordings = {
  __typename?: "SessionRecordingConnection";

  totalRecords: number;

  nodes: GetDataForSplitTestSessionRecordingIndexPageNodes[];
};

export type GetDataForSplitTestSessionRecordingIndexPageNodes = SessionRecordingForListFragment;

export type GetDataForSplitTestSessionRecordingIndexPageSplitTest = {
  __typename?: "SplitTest";

  id: string;

  goals: GetDataForSplitTestSessionRecordingIndexPageGoals[];
};

export type GetDataForSplitTestSessionRecordingIndexPageGoals = GetDataForSplitTestSessionRecordingIndexPageGoalEntityInlineFragment;

export type GetDataForSplitTestSessionRecordingIndexPageGoalEntityInlineFragment = {
  __typename?: "GoalEntity";

  id: string;

  name: string;
};

export type GetDataForSplitTestShowPageVariables = {
  projectId: string;
  splitTestId: string;
  goalId?: Maybe<string>;
};

export type GetDataForSplitTestShowPageQuery = {
  __typename?: "Query";

  currentUser: Maybe<GetDataForSplitTestShowPageCurrentUser>;
};

export type GetDataForSplitTestShowPageCurrentUser = {
  __typename?: "User";

  id: string;

  project: Maybe<GetDataForSplitTestShowPageProject>;
};

export type GetDataForSplitTestShowPageProject = {
  __typename?: "Project";

  id: string;

  splitTest: Maybe<GetDataForSplitTestShowPageSplitTest>;
};

export type GetDataForSplitTestShowPageSplitTest = {
  __typename?: "SplitTest";

  id: string;

  name: string;

  variations: GetDataForSplitTestShowPageVariations[];

  goals: GetDataForSplitTestShowPageGoals[];

  variationsConversions: GetDataForSplitTestShowPageVariationsConversions[];
};

export type GetDataForSplitTestShowPageVariations = {
  __typename?: "SplitTestVariation";

  id: string;

  name: string;

  url: string;

  control: boolean;
};

export type GetDataForSplitTestShowPageGoals = GetDataForSplitTestShowPagePathGoalInlineFragment;

export type GetDataForSplitTestShowPagePathGoalInlineFragment = {
  __typename?: "PathGoal";

  id: string;

  name: string;
};

export type GetDataForSplitTestShowPageVariationsConversions = {
  __typename?: "SplitTestVariationConversion";

  conversionsCount: number;

  conversionRate: number;

  goalId: string;

  improvementRate: Maybe<number>;

  ratesByDate: GetDataForSplitTestShowPageRatesByDate[];

  revenue: string;

  variationId: string;

  visitsCount: number;

  isWinner: boolean;
};

export type GetDataForSplitTestShowPageRatesByDate = {
  __typename?: "SplitTestVariationConversionRateByDate";

  conversionRate: number;

  date: string;
};

export type GetDataForTestIdeaIndexPageVariables = {
  pagination: Pagination;
  filter: TestIdeaFilter;
};

export type GetDataForTestIdeaIndexPageQuery = {
  __typename?: "Query";

  testIdeaCategories: GetDataForTestIdeaIndexPageTestIdeaCategories[];

  currentUser: Maybe<GetDataForTestIdeaIndexPageCurrentUser>;
};

export type GetDataForTestIdeaIndexPageTestIdeaCategories = {
  __typename?: "TestIdeaCategory";

  id: string;

  name: string;
};

export type GetDataForTestIdeaIndexPageCurrentUser = {
  __typename?: "User";

  id: string;

  testIdeas: GetDataForTestIdeaIndexPageTestIdeas;
};

export type GetDataForTestIdeaIndexPageTestIdeas = {
  __typename?: "UserTestIdeasConnection";

  totalRecords: number;

  edges: GetDataForTestIdeaIndexPageEdges[];
};

export type GetDataForTestIdeaIndexPageEdges = {
  __typename?: "UserTestIdeaEdge";

  isLiked: boolean;

  node: GetDataForTestIdeaIndexPageNode;
};

export type GetDataForTestIdeaIndexPageNode = {
  __typename?: "TestIdea";

  id: string;

  coverUrl: string;

  description: string;

  impactRate: number;

  title: string;

  category: GetDataForTestIdeaIndexPageCategory;
};

export type GetDataForTestIdeaIndexPageCategory = {
  __typename?: "TestIdeaCategory";

  id: string;

  color: string;

  name: string;

  iconUrl: Maybe<string>;
};

export type ToggleLikeAtTestIdeaIndexPageVariables = {
  testIdeaTestId: string;
};

export type ToggleLikeAtTestIdeaIndexPageMutation = {
  __typename?: "Mutation";

  toggleTestIdeaLike: ToggleLikeAtTestIdeaIndexPageToggleTestIdeaLike;
};

export type ToggleLikeAtTestIdeaIndexPageToggleTestIdeaLike = {
  __typename?: "LikeTestIdeaPayload";

  testIdeaEdge: ToggleLikeAtTestIdeaIndexPageTestIdeaEdge;
};

export type ToggleLikeAtTestIdeaIndexPageTestIdeaEdge = {
  __typename?: "UserTestIdeaEdge";

  isLiked: boolean;

  node: ToggleLikeAtTestIdeaIndexPageNode;
};

export type ToggleLikeAtTestIdeaIndexPageNode = {
  __typename?: "TestIdea";

  id: string;
};

export type FeedbackForFormFragment = {
  __typename?: "FeedbackPoll";

  id: string;

  name: string;

  isActive: boolean;

  thankYouMessage: string;

  isPageMatcherEnabled: boolean;

  showPollOption: FeedbackPollShowOption;

  isPollOpenedOnStart: boolean;

  questions: FeedbackForFormQuestions[];

  pageMatchers: FeedbackForFormPageMatchers[];
};

export type FeedbackForFormQuestions =
  | FeedbackForFormFeedbackPollShortTextQuestionInlineFragment
  | FeedbackForFormFeedbackPollLongTextQuestionInlineFragment;

export type FeedbackForFormFeedbackPollShortTextQuestionInlineFragment = {
  __typename: "FeedbackPollShortTextQuestion";

  id: string;

  title: string;

  index: number;
};

export type FeedbackForFormFeedbackPollLongTextQuestionInlineFragment = {
  __typename: "FeedbackPollLongTextQuestion";

  id: string;

  title: string;

  index: number;
};

export type FeedbackForFormPageMatchers = {
  __typename?: "PageMatcher";

  matchType: PageMatcherMatchType;

  url: string;
};

export type SessionRecordingForListFragment = {
  __typename?: "SessionRecording";

  id: string;

  isVisitorOnline: boolean;

  referrer: Maybe<string>;

  referrerSource: ReferrerSource;

  duration: number;

  createdAt: string;

  projectId: string;

  startingPage: SessionRecordingForListStartingPage;

  device: SessionRecordingForListDevice;

  convertedProjectGoals: SessionRecordingForListConvertedProjectGoals[];

  splitTestEdges: SessionRecordingForListSplitTestEdges[];

  location: SessionRecordingForListLocation;
};

export type SessionRecordingForListStartingPage = {
  __typename?: "SessionRecordingPage";

  id: string;

  title: string;

  url: string;
};

export type SessionRecordingForListDevice = {
  __typename?: "SessionRecordingDevice";

  id: string;

  screenWidth: number;

  screenHeight: number;

  userAgent: string;

  deviceType: DeviceType;
};

export type SessionRecordingForListConvertedProjectGoals = SessionRecordingForListGoalEntityInlineFragment;

export type SessionRecordingForListGoalEntityInlineFragment = {
  __typename?: "GoalEntity";

  id: string;

  name: string;
};

export type SessionRecordingForListSplitTestEdges = {
  __typename?: "SplitTestEdge";

  visitedVariation: SessionRecordingForListVisitedVariation;

  convertedGoals: SessionRecordingForListConvertedGoals[];
};

export type SessionRecordingForListVisitedVariation = {
  __typename?: "SplitTestVariation";

  id: string;

  name: string;

  url: string;
};

export type SessionRecordingForListConvertedGoals = SessionRecordingForList_GoalEntityInlineFragment;

export type SessionRecordingForList_GoalEntityInlineFragment = {
  __typename?: "GoalEntity";

  id: string;

  name: string;
};

export type SessionRecordingForListLocation = {
  __typename?: "SessionRecordingLocation";

  id: string;

  city: Maybe<string>;

  country: Maybe<string>;

  ip: string;

  countryIsoCode: Maybe<string>;
};

export type GoalForEditGoalPageFragment = {
  __typename?: "PathGoal";

  id: string;

  name: string;

  value: string;

  path: GoalForEditGoalPagePath[];
};

export type GoalForEditGoalPagePath = {
  __typename?: "PathGoalStep";

  url: string;

  matchType: PageMatcherMatchType;

  index: number;
};

export type SplitTestForEditSplitTestPageFragment = {
  __typename?: "SplitTest";

  id: string;

  name: string;

  description: string;

  trafficPercent: number;

  trafficDeviceTypes: DeviceType[];

  trafficReferrerSources: ReferrerSource[];

  testIdea: Maybe<SplitTestForEditSplitTestPageTestIdea>;

  pageCategory: SplitTestForEditSplitTestPagePageCategory;

  pageType: SplitTestForEditSplitTestPagePageType;

  status: SplitTestStatus;

  variations: SplitTestForEditSplitTestPageVariations[];

  goals: SplitTestForEditSplitTestPageGoals[];

  finishCondition: Maybe<SplitTestForEditSplitTestPageFinishCondition>;
};

export type SplitTestForEditSplitTestPageTestIdea = {
  __typename?: "TestIdea";

  id: string;

  name: string;
};

export type SplitTestForEditSplitTestPagePageCategory = {
  __typename?: "PageCategory";

  id: string;

  name: string;
};

export type SplitTestForEditSplitTestPagePageType = {
  __typename?: "PageType";

  id: string;

  name: string;
};

export type SplitTestForEditSplitTestPageVariations = {
  __typename?: "SplitTestVariation";

  id: string;

  name: string;

  url: string;

  control: boolean;
};

export type SplitTestForEditSplitTestPageGoals = SplitTestForEditSplitTestPagePathGoalInlineFragment;

export type SplitTestForEditSplitTestPagePathGoalInlineFragment = {
  __typename?: "PathGoal";

  id: string;

  name: string;

  value: string;

  path: SplitTestForEditSplitTestPagePath[];
};

export type SplitTestForEditSplitTestPagePath = {
  __typename?: "PathGoalStep";

  url: string;

  matchType: PageMatcherMatchType;

  index: number;
};

export type SplitTestForEditSplitTestPageFinishCondition =
  | SplitTestForEditSplitTestPageSplitTestGoalConversionsFinishConditionInlineFragment
  | SplitTestForEditSplitTestPageSplitTestDaysPassedFinishConditionInlineFragment
  | SplitTestForEditSplitTestPageSplitTestVisitsFinishConditionInlineFragment;

export type SplitTestForEditSplitTestPageSplitTestGoalConversionsFinishConditionInlineFragment = {
  __typename?: "SplitTestGoalConversionsFinishCondition";

  count: number;

  goal: SplitTestForEditSplitTestPageGoal;
};

export type SplitTestForEditSplitTestPageGoal = SplitTestForEditSplitTestPageGoalEntityInlineFragment;

export type SplitTestForEditSplitTestPageGoalEntityInlineFragment = {
  __typename?: "GoalEntity";

  id: string;

  name: string;
};

export type SplitTestForEditSplitTestPageSplitTestDaysPassedFinishConditionInlineFragment = {
  __typename?: "SplitTestDaysPassedFinishCondition";

  count: number;
};

export type SplitTestForEditSplitTestPageSplitTestVisitsFinishConditionInlineFragment = {
  __typename?: "SplitTestVisitsFinishCondition";

  count: number;
};

export type SplitTestForIndexSplitTestPageFragment = {
  __typename?: "SplitTest";

  id: string;

  name: string;

  status: SplitTestStatus;

  pageCategory: SplitTestForIndexSplitTestPagePageCategory;

  pageType: SplitTestForIndexSplitTestPagePageType;

  variations: SplitTestForIndexSplitTestPageVariations[];

  variationsConversions: SplitTestForIndexSplitTestPageVariationsConversions[];

  goals: SplitTestForIndexSplitTestPageGoals[];
};

export type SplitTestForIndexSplitTestPagePageCategory = {
  __typename?: "PageCategory";

  id: string;

  name: string;
};

export type SplitTestForIndexSplitTestPagePageType = {
  __typename?: "PageType";

  id: string;

  name: string;
};

export type SplitTestForIndexSplitTestPageVariations = {
  __typename?: "SplitTestVariation";

  id: string;

  name: string;

  url: string;

  control: boolean;
};

export type SplitTestForIndexSplitTestPageVariationsConversions = {
  __typename?: "SplitTestVariationConversion";

  variationId: string;

  goalId: string;

  conversionRate: number;

  visitsCount: number;

  improvementRate: Maybe<number>;

  isWinner: boolean;

  conversionsCount: number;
};

export type SplitTestForIndexSplitTestPageGoals = SplitTestForIndexSplitTestPageGoalEntityInlineFragment;

export type SplitTestForIndexSplitTestPageGoalEntityInlineFragment = {
  __typename?: "GoalEntity";

  id: string;

  name: string;
};

// ====================================================
// Fragments
// ====================================================

export const FeedbackForFormFragmentDoc = gql`
  fragment feedbackForForm on FeedbackPoll {
    id
    name
    isActive
    thankYouMessage
    isPageMatcherEnabled
    showPollOption
    isPollOpenedOnStart
    questions {
      ... on FeedbackPollShortTextQuestion {
        id
        title
        index
        __typename
      }
      ... on FeedbackPollLongTextQuestion {
        id
        title
        index
        __typename
      }
    }
    pageMatchers {
      matchType
      url
    }
  }
`;

export const SessionRecordingForListFragmentDoc = gql`
  fragment sessionRecordingForList on SessionRecording {
    id
    isVisitorOnline
    referrer
    referrerSource
    duration
    createdAt
    projectId
    startingPage {
      id
      title
      url
    }
    device {
      id
      screenWidth
      screenHeight
      userAgent
      deviceType: type
    }
    convertedProjectGoals {
      ... on GoalEntity {
        id
        name
      }
    }
    splitTestEdges {
      visitedVariation {
        id
        name
        url
      }
      convertedGoals {
        ... on GoalEntity {
          id
          name
        }
      }
    }
    location {
      id
      city
      country
      ip
      countryIsoCode
    }
  }
`;

export const GoalForEditGoalPageFragmentDoc = gql`
  fragment goalForEditGoalPage on PathGoal {
    id
    name
    value
    path {
      url
      matchType
      index
    }
  }
`;

export const SplitTestForEditSplitTestPageFragmentDoc = gql`
  fragment splitTestForEditSplitTestPage on SplitTest {
    id
    name
    description
    trafficPercent
    trafficDeviceTypes
    trafficReferrerSources
    testIdea {
      id
      name: title
    }
    pageCategory {
      id
      name
    }
    pageType {
      id
      name
    }
    status
    variations {
      id
      name
      url
      control
    }
    goals {
      ... on PathGoal {
        id
        name
        value
        path {
          url
          matchType
          index
        }
      }
    }
    finishCondition {
      ... on SplitTestGoalConversionsFinishCondition {
        count
        goal {
          ... on GoalEntity {
            id
            name
          }
        }
      }
      ... on SplitTestDaysPassedFinishCondition {
        count
      }
      ... on SplitTestVisitsFinishCondition {
        count
      }
      __typename
    }
  }
`;

export const SplitTestForIndexSplitTestPageFragmentDoc = gql`
  fragment splitTestForIndexSplitTestPage on SplitTest {
    id
    name
    status
    pageCategory {
      id
      name
    }
    pageType {
      id
      name
    }
    variations {
      id
      name
      url
      control
    }
    variationsConversions(goalId: $conversionGoalId) {
      variationId
      goalId
      conversionRate
      visitsCount
      improvementRate
      isWinner
      conversionsCount
    }
    goals {
      ... on GoalEntity {
        id
        name
      }
    }
  }
`;

// ====================================================
// Components
// ====================================================

export const GetDataForAccountSettingsDocument = gql`
  query getDataForAccountSettings {
    currentUser {
      id
      fullName
      companyName
      email
      phone
      avatarUrl
    }
    accountSettingsTab @client {
      active
      __typename
    }
  }
`;
export class GetDataForAccountSettingsComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      GetDataForAccountSettingsQuery,
      GetDataForAccountSettingsVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        GetDataForAccountSettingsQuery,
        GetDataForAccountSettingsVariables
      >
        query={GetDataForAccountSettingsDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type GetDataForAccountSettingsProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<
    GetDataForAccountSettingsQuery,
    GetDataForAccountSettingsVariables
  >
> &
  TChildProps;
export function GetDataForAccountSettingsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetDataForAccountSettingsQuery,
        GetDataForAccountSettingsVariables,
        GetDataForAccountSettingsProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetDataForAccountSettingsQuery,
    GetDataForAccountSettingsVariables,
    GetDataForAccountSettingsProps<TChildProps>
  >(GetDataForAccountSettingsDocument, operationOptions);
}
export const UpdatePasswordAtAccountSettingsDocument = gql`
  mutation updatePasswordAtAccountSettings(
    $passwordParams: UpdatePasswordParams!
    $userId: ULID!
  ) {
    updatePassword(userId: $userId, passwordParams: $passwordParams) {
      successful
      messages {
        field
        message
        template
      }
    }
  }
`;
export class UpdatePasswordAtAccountSettingsComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      UpdatePasswordAtAccountSettingsMutation,
      UpdatePasswordAtAccountSettingsVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        UpdatePasswordAtAccountSettingsMutation,
        UpdatePasswordAtAccountSettingsVariables
      >
        mutation={UpdatePasswordAtAccountSettingsDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type UpdatePasswordAtAccountSettingsProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    UpdatePasswordAtAccountSettingsMutation,
    UpdatePasswordAtAccountSettingsVariables
  >
> &
  TChildProps;
export type UpdatePasswordAtAccountSettingsMutationFn = ReactApollo.MutationFn<
  UpdatePasswordAtAccountSettingsMutation,
  UpdatePasswordAtAccountSettingsVariables
>;
export function UpdatePasswordAtAccountSettingsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        UpdatePasswordAtAccountSettingsMutation,
        UpdatePasswordAtAccountSettingsVariables,
        UpdatePasswordAtAccountSettingsProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    UpdatePasswordAtAccountSettingsMutation,
    UpdatePasswordAtAccountSettingsVariables,
    UpdatePasswordAtAccountSettingsProps<TChildProps>
  >(UpdatePasswordAtAccountSettingsDocument, operationOptions);
}
export const UpdateProfileAtAccountSettingsDocument = gql`
  mutation updateProfileAtAccountSettings(
    $userParams: UserParams!
    $userId: ULID!
  ) {
    updateUser(userId: $userId, userParams: $userParams) {
      successful
      result {
        id
        fullName
        companyName
        email
        phone
        avatarUrl
      }
      messages {
        field
        message
        template
      }
    }
  }
`;
export class UpdateProfileAtAccountSettingsComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      UpdateProfileAtAccountSettingsMutation,
      UpdateProfileAtAccountSettingsVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        UpdateProfileAtAccountSettingsMutation,
        UpdateProfileAtAccountSettingsVariables
      >
        mutation={UpdateProfileAtAccountSettingsDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type UpdateProfileAtAccountSettingsProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    UpdateProfileAtAccountSettingsMutation,
    UpdateProfileAtAccountSettingsVariables
  >
> &
  TChildProps;
export type UpdateProfileAtAccountSettingsMutationFn = ReactApollo.MutationFn<
  UpdateProfileAtAccountSettingsMutation,
  UpdateProfileAtAccountSettingsVariables
>;
export function UpdateProfileAtAccountSettingsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        UpdateProfileAtAccountSettingsMutation,
        UpdateProfileAtAccountSettingsVariables,
        UpdateProfileAtAccountSettingsProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    UpdateProfileAtAccountSettingsMutation,
    UpdateProfileAtAccountSettingsVariables,
    UpdateProfileAtAccountSettingsProps<TChildProps>
  >(UpdateProfileAtAccountSettingsDocument, operationOptions);
}
export const GetDataForCurrentProjectIdDocument = gql`
  query getDataForCurrentProjectId {
    currentProjectId @client
  }
`;
export class GetDataForCurrentProjectIdComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      GetDataForCurrentProjectIdQuery,
      GetDataForCurrentProjectIdVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        GetDataForCurrentProjectIdQuery,
        GetDataForCurrentProjectIdVariables
      >
        query={GetDataForCurrentProjectIdDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type GetDataForCurrentProjectIdProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<
    GetDataForCurrentProjectIdQuery,
    GetDataForCurrentProjectIdVariables
  >
> &
  TChildProps;
export function GetDataForCurrentProjectIdHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetDataForCurrentProjectIdQuery,
        GetDataForCurrentProjectIdVariables,
        GetDataForCurrentProjectIdProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetDataForCurrentProjectIdQuery,
    GetDataForCurrentProjectIdVariables,
    GetDataForCurrentProjectIdProps<TChildProps>
  >(GetDataForCurrentProjectIdDocument, operationOptions);
}
export const GetCurrentUserForOnlyAuthedDocument = gql`
  query getCurrentUserForOnlyAuthed {
    currentUser {
      id
    }
  }
`;
export class GetCurrentUserForOnlyAuthedComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      GetCurrentUserForOnlyAuthedQuery,
      GetCurrentUserForOnlyAuthedVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        GetCurrentUserForOnlyAuthedQuery,
        GetCurrentUserForOnlyAuthedVariables
      >
        query={GetCurrentUserForOnlyAuthedDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type GetCurrentUserForOnlyAuthedProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<
    GetCurrentUserForOnlyAuthedQuery,
    GetCurrentUserForOnlyAuthedVariables
  >
> &
  TChildProps;
export function GetCurrentUserForOnlyAuthedHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetCurrentUserForOnlyAuthedQuery,
        GetCurrentUserForOnlyAuthedVariables,
        GetCurrentUserForOnlyAuthedProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetCurrentUserForOnlyAuthedQuery,
    GetCurrentUserForOnlyAuthedVariables,
    GetCurrentUserForOnlyAuthedProps<TChildProps>
  >(GetCurrentUserForOnlyAuthedDocument, operationOptions);
}
export const GetCurrentUserIdDocument = gql`
  query getCurrentUserId {
    currentUserId @client
  }
`;
export class GetCurrentUserIdComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<GetCurrentUserIdQuery, GetCurrentUserIdVariables>
  >
> {
  render() {
    return (
      <ReactApollo.Query<GetCurrentUserIdQuery, GetCurrentUserIdVariables>
        query={GetCurrentUserIdDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type GetCurrentUserIdProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<GetCurrentUserIdQuery, GetCurrentUserIdVariables>
> &
  TChildProps;
export function GetCurrentUserIdHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetCurrentUserIdQuery,
        GetCurrentUserIdVariables,
        GetCurrentUserIdProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetCurrentUserIdQuery,
    GetCurrentUserIdVariables,
    GetCurrentUserIdProps<TChildProps>
  >(GetCurrentUserIdDocument, operationOptions);
}
export const DeleteFeedbackPollDocument = gql`
  mutation deleteFeedbackPoll($feedbackPollId: ULID!) {
    deleteFeedbackPoll(id: $feedbackPollId) {
      id
    }
  }
`;
export class DeleteFeedbackPollComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      DeleteFeedbackPollMutation,
      DeleteFeedbackPollVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        DeleteFeedbackPollMutation,
        DeleteFeedbackPollVariables
      >
        mutation={DeleteFeedbackPollDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DeleteFeedbackPollProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    DeleteFeedbackPollMutation,
    DeleteFeedbackPollVariables
  >
> &
  TChildProps;
export type DeleteFeedbackPollMutationFn = ReactApollo.MutationFn<
  DeleteFeedbackPollMutation,
  DeleteFeedbackPollVariables
>;
export function DeleteFeedbackPollHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DeleteFeedbackPollMutation,
        DeleteFeedbackPollVariables,
        DeleteFeedbackPollProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DeleteFeedbackPollMutation,
    DeleteFeedbackPollVariables,
    DeleteFeedbackPollProps<TChildProps>
  >(DeleteFeedbackPollDocument, operationOptions);
}
export const DeleteFeedbackPollResponseDocument = gql`
  mutation deleteFeedbackPollResponse($feedbackPollResponseId: ULID!) {
    deleteFeedbackPollResponse(id: $feedbackPollResponseId) {
      id
    }
  }
`;
export class DeleteFeedbackPollResponseComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      DeleteFeedbackPollResponseMutation,
      DeleteFeedbackPollResponseVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        DeleteFeedbackPollResponseMutation,
        DeleteFeedbackPollResponseVariables
      >
        mutation={DeleteFeedbackPollResponseDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DeleteFeedbackPollResponseProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    DeleteFeedbackPollResponseMutation,
    DeleteFeedbackPollResponseVariables
  >
> &
  TChildProps;
export type DeleteFeedbackPollResponseMutationFn = ReactApollo.MutationFn<
  DeleteFeedbackPollResponseMutation,
  DeleteFeedbackPollResponseVariables
>;
export function DeleteFeedbackPollResponseHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DeleteFeedbackPollResponseMutation,
        DeleteFeedbackPollResponseVariables,
        DeleteFeedbackPollResponseProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DeleteFeedbackPollResponseMutation,
    DeleteFeedbackPollResponseVariables,
    DeleteFeedbackPollResponseProps<TChildProps>
  >(DeleteFeedbackPollResponseDocument, operationOptions);
}
export const GetCurrentUserForTopBarDocument = gql`
  query getCurrentUserForTopBar {
    currentUser {
      id
      fullName
      avatarUrl
    }
  }
`;
export class GetCurrentUserForTopBarComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      GetCurrentUserForTopBarQuery,
      GetCurrentUserForTopBarVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        GetCurrentUserForTopBarQuery,
        GetCurrentUserForTopBarVariables
      >
        query={GetCurrentUserForTopBarDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type GetCurrentUserForTopBarProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<
    GetCurrentUserForTopBarQuery,
    GetCurrentUserForTopBarVariables
  >
> &
  TChildProps;
export function GetCurrentUserForTopBarHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetCurrentUserForTopBarQuery,
        GetCurrentUserForTopBarVariables,
        GetCurrentUserForTopBarProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetCurrentUserForTopBarQuery,
    GetCurrentUserForTopBarVariables,
    GetCurrentUserForTopBarProps<TChildProps>
  >(GetCurrentUserForTopBarDocument, operationOptions);
}
export const GetDataForNavbarDocument = gql`
  query getDataForNavbar {
    navbar @client {
      header
      backPath
      caption
    }
  }
`;
export class GetDataForNavbarComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<GetDataForNavbarQuery, GetDataForNavbarVariables>
  >
> {
  render() {
    return (
      <ReactApollo.Query<GetDataForNavbarQuery, GetDataForNavbarVariables>
        query={GetDataForNavbarDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type GetDataForNavbarProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<GetDataForNavbarQuery, GetDataForNavbarVariables>
> &
  TChildProps;
export function GetDataForNavbarHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetDataForNavbarQuery,
        GetDataForNavbarVariables,
        GetDataForNavbarProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetDataForNavbarQuery,
    GetDataForNavbarVariables,
    GetDataForNavbarProps<TChildProps>
  >(GetDataForNavbarDocument, operationOptions);
}
export const CreateNewProjectDocument = gql`
  mutation createNewProject($projectParams: ProjectParams!) {
    createProject(projectParams: $projectParams) {
      result {
        id
        domain
      }
      messages {
        message
        field
      }
      successful
    }
  }
`;
export class CreateNewProjectComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      CreateNewProjectMutation,
      CreateNewProjectVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<CreateNewProjectMutation, CreateNewProjectVariables>
        mutation={CreateNewProjectDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateNewProjectProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<CreateNewProjectMutation, CreateNewProjectVariables>
> &
  TChildProps;
export type CreateNewProjectMutationFn = ReactApollo.MutationFn<
  CreateNewProjectMutation,
  CreateNewProjectVariables
>;
export function CreateNewProjectHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateNewProjectMutation,
        CreateNewProjectVariables,
        CreateNewProjectProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateNewProjectMutation,
    CreateNewProjectVariables,
    CreateNewProjectProps<TChildProps>
  >(CreateNewProjectDocument, operationOptions);
}
export const GetProjectsForDropdownDocument = gql`
  query getProjectsForDropdown {
    currentUser {
      id
      projects {
        nodes {
          id
          domain
        }
      }
    }
  }
`;
export class GetProjectsForDropdownComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      GetProjectsForDropdownQuery,
      GetProjectsForDropdownVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        GetProjectsForDropdownQuery,
        GetProjectsForDropdownVariables
      >
        query={GetProjectsForDropdownDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type GetProjectsForDropdownProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<
    GetProjectsForDropdownQuery,
    GetProjectsForDropdownVariables
  >
> &
  TChildProps;
export function GetProjectsForDropdownHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetProjectsForDropdownQuery,
        GetProjectsForDropdownVariables,
        GetProjectsForDropdownProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetProjectsForDropdownQuery,
    GetProjectsForDropdownVariables,
    GetProjectsForDropdownProps<TChildProps>
  >(GetProjectsForDropdownDocument, operationOptions);
}
export const DeleteGoalDocument = gql`
  mutation deleteGoal($goalId: ULID!) {
    deleteGoal(id: $goalId) {
      ... on PathGoal {
        id
      }
    }
  }
`;
export class DeleteGoalComponent extends React.Component<
  Partial<ReactApollo.MutationProps<DeleteGoalMutation, DeleteGoalVariables>>
> {
  render() {
    return (
      <ReactApollo.Mutation<DeleteGoalMutation, DeleteGoalVariables>
        mutation={DeleteGoalDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DeleteGoalProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<DeleteGoalMutation, DeleteGoalVariables>
> &
  TChildProps;
export type DeleteGoalMutationFn = ReactApollo.MutationFn<
  DeleteGoalMutation,
  DeleteGoalVariables
>;
export function DeleteGoalHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DeleteGoalMutation,
        DeleteGoalVariables,
        DeleteGoalProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DeleteGoalMutation,
    DeleteGoalVariables,
    DeleteGoalProps<TChildProps>
  >(DeleteGoalDocument, operationOptions);
}
export const DeleteProjectDocument = gql`
  mutation deleteProject($projectId: ULID!) {
    deleteProject(id: $projectId) {
      id
    }
  }
`;
export class DeleteProjectComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<DeleteProjectMutation, DeleteProjectVariables>
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<DeleteProjectMutation, DeleteProjectVariables>
        mutation={DeleteProjectDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DeleteProjectProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<DeleteProjectMutation, DeleteProjectVariables>
> &
  TChildProps;
export type DeleteProjectMutationFn = ReactApollo.MutationFn<
  DeleteProjectMutation,
  DeleteProjectVariables
>;
export function DeleteProjectHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DeleteProjectMutation,
        DeleteProjectVariables,
        DeleteProjectProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DeleteProjectMutation,
    DeleteProjectVariables,
    DeleteProjectProps<TChildProps>
  >(DeleteProjectDocument, operationOptions);
}
export const UpdateProjectDocument = gql`
  mutation updateProject($projectId: ULID!, $params: ProjectParams!) {
    updateProject(id: $projectId, projectParams: $params) {
      result {
        id
        domain
        isRecordingEnabled
      }
      messages {
        message
        field
      }
      successful
    }
  }
`;
export class UpdateProjectComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<UpdateProjectMutation, UpdateProjectVariables>
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<UpdateProjectMutation, UpdateProjectVariables>
        mutation={UpdateProjectDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type UpdateProjectProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<UpdateProjectMutation, UpdateProjectVariables>
> &
  TChildProps;
export type UpdateProjectMutationFn = ReactApollo.MutationFn<
  UpdateProjectMutation,
  UpdateProjectVariables
>;
export function UpdateProjectHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        UpdateProjectMutation,
        UpdateProjectVariables,
        UpdateProjectProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    UpdateProjectMutation,
    UpdateProjectVariables,
    UpdateProjectProps<TChildProps>
  >(UpdateProjectDocument, operationOptions);
}
export const GetDataForFeedbackPollEditPageDocument = gql`
  query getDataForFeedbackPollEditPage(
    $projectId: ULID!
    $feedbackPollId: ULID!
  ) {
    currentUser {
      id
      project(id: $projectId) {
        id
        feedbackPoll(id: $feedbackPollId) {
          ...feedbackForForm
        }
      }
    }
  }

  ${FeedbackForFormFragmentDoc}
`;
export class GetDataForFeedbackPollEditPageComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      GetDataForFeedbackPollEditPageQuery,
      GetDataForFeedbackPollEditPageVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        GetDataForFeedbackPollEditPageQuery,
        GetDataForFeedbackPollEditPageVariables
      >
        query={GetDataForFeedbackPollEditPageDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type GetDataForFeedbackPollEditPageProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<
    GetDataForFeedbackPollEditPageQuery,
    GetDataForFeedbackPollEditPageVariables
  >
> &
  TChildProps;
export function GetDataForFeedbackPollEditPageHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetDataForFeedbackPollEditPageQuery,
        GetDataForFeedbackPollEditPageVariables,
        GetDataForFeedbackPollEditPageProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetDataForFeedbackPollEditPageQuery,
    GetDataForFeedbackPollEditPageVariables,
    GetDataForFeedbackPollEditPageProps<TChildProps>
  >(GetDataForFeedbackPollEditPageDocument, operationOptions);
}
export const UpdateFeedbackPollAtFeedbackPollEditPageDocument = gql`
  mutation updateFeedbackPollAtFeedbackPollEditPage(
    $feedbackPollId: ULID!
    $feedbackPollParams: FeedbackPollParams!
  ) {
    updateFeedbackPoll(
      feedbackPollId: $feedbackPollId
      feedbackPollParams: $feedbackPollParams
    ) {
      result {
        ...feedbackForForm
      }
      messages {
        message
        field
      }
      successful
    }
  }

  ${FeedbackForFormFragmentDoc}
`;
export class UpdateFeedbackPollAtFeedbackPollEditPageComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      UpdateFeedbackPollAtFeedbackPollEditPageMutation,
      UpdateFeedbackPollAtFeedbackPollEditPageVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        UpdateFeedbackPollAtFeedbackPollEditPageMutation,
        UpdateFeedbackPollAtFeedbackPollEditPageVariables
      >
        mutation={UpdateFeedbackPollAtFeedbackPollEditPageDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type UpdateFeedbackPollAtFeedbackPollEditPageProps<
  TChildProps = any
> = Partial<
  ReactApollo.MutateProps<
    UpdateFeedbackPollAtFeedbackPollEditPageMutation,
    UpdateFeedbackPollAtFeedbackPollEditPageVariables
  >
> &
  TChildProps;
export type UpdateFeedbackPollAtFeedbackPollEditPageMutationFn = ReactApollo.MutationFn<
  UpdateFeedbackPollAtFeedbackPollEditPageMutation,
  UpdateFeedbackPollAtFeedbackPollEditPageVariables
>;
export function UpdateFeedbackPollAtFeedbackPollEditPageHOC<
  TProps,
  TChildProps = any
>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        UpdateFeedbackPollAtFeedbackPollEditPageMutation,
        UpdateFeedbackPollAtFeedbackPollEditPageVariables,
        UpdateFeedbackPollAtFeedbackPollEditPageProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    UpdateFeedbackPollAtFeedbackPollEditPageMutation,
    UpdateFeedbackPollAtFeedbackPollEditPageVariables,
    UpdateFeedbackPollAtFeedbackPollEditPageProps<TChildProps>
  >(UpdateFeedbackPollAtFeedbackPollEditPageDocument, operationOptions);
}
export const GetDataForFeedbackPollIndexPageDocument = gql`
  query getDataForFeedbackPollIndexPage($projectId: ULID!) {
    currentUser {
      id
      project(id: $projectId) {
        id
        feedbackPolls {
          nodes {
            id
            name
            isActive
            responsesCount
            createdAt
          }
        }
      }
    }
  }
`;
export class GetDataForFeedbackPollIndexPageComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      GetDataForFeedbackPollIndexPageQuery,
      GetDataForFeedbackPollIndexPageVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        GetDataForFeedbackPollIndexPageQuery,
        GetDataForFeedbackPollIndexPageVariables
      >
        query={GetDataForFeedbackPollIndexPageDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type GetDataForFeedbackPollIndexPageProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<
    GetDataForFeedbackPollIndexPageQuery,
    GetDataForFeedbackPollIndexPageVariables
  >
> &
  TChildProps;
export function GetDataForFeedbackPollIndexPageHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetDataForFeedbackPollIndexPageQuery,
        GetDataForFeedbackPollIndexPageVariables,
        GetDataForFeedbackPollIndexPageProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetDataForFeedbackPollIndexPageQuery,
    GetDataForFeedbackPollIndexPageVariables,
    GetDataForFeedbackPollIndexPageProps<TChildProps>
  >(GetDataForFeedbackPollIndexPageDocument, operationOptions);
}
export const CreateFeedbackPollAtFeedbackPollNewPageDocument = gql`
  mutation createFeedbackPollAtFeedbackPollNewPage(
    $projectId: ULID!
    $feedbackPollParams: FeedbackPollParams!
  ) {
    createFeedbackPoll(
      projectId: $projectId
      feedbackPollParams: $feedbackPollParams
    ) {
      result {
        ...feedbackForForm
      }
      messages {
        message
        field
      }
      successful
    }
  }

  ${FeedbackForFormFragmentDoc}
`;
export class CreateFeedbackPollAtFeedbackPollNewPageComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      CreateFeedbackPollAtFeedbackPollNewPageMutation,
      CreateFeedbackPollAtFeedbackPollNewPageVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        CreateFeedbackPollAtFeedbackPollNewPageMutation,
        CreateFeedbackPollAtFeedbackPollNewPageVariables
      >
        mutation={CreateFeedbackPollAtFeedbackPollNewPageDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateFeedbackPollAtFeedbackPollNewPageProps<
  TChildProps = any
> = Partial<
  ReactApollo.MutateProps<
    CreateFeedbackPollAtFeedbackPollNewPageMutation,
    CreateFeedbackPollAtFeedbackPollNewPageVariables
  >
> &
  TChildProps;
export type CreateFeedbackPollAtFeedbackPollNewPageMutationFn = ReactApollo.MutationFn<
  CreateFeedbackPollAtFeedbackPollNewPageMutation,
  CreateFeedbackPollAtFeedbackPollNewPageVariables
>;
export function CreateFeedbackPollAtFeedbackPollNewPageHOC<
  TProps,
  TChildProps = any
>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateFeedbackPollAtFeedbackPollNewPageMutation,
        CreateFeedbackPollAtFeedbackPollNewPageVariables,
        CreateFeedbackPollAtFeedbackPollNewPageProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateFeedbackPollAtFeedbackPollNewPageMutation,
    CreateFeedbackPollAtFeedbackPollNewPageVariables,
    CreateFeedbackPollAtFeedbackPollNewPageProps<TChildProps>
  >(CreateFeedbackPollAtFeedbackPollNewPageDocument, operationOptions);
}
export const GetDataForFeedbackPollResponseIndexPageDocument = gql`
  query getDataForFeedbackPollResponseIndexPage(
    $projectId: ULID!
    $feedbackPollId: ULID!
    $pagination: CursorPagination!
  ) {
    currentUser {
      id
      project(id: $projectId) {
        id
        feedbackPoll(id: $feedbackPollId) {
          id
          name
          questions {
            ... on FeedbackPollShortTextQuestion {
              id
              title
            }
            ... on FeedbackPollLongTextQuestion {
              id
              title
            }
          }
          responses(pagination: $pagination) {
            nodes {
              id
              pageUrl
              answers {
                id
                content
                question {
                  ... on FeedbackPollShortTextQuestion {
                    id
                  }
                  ... on FeedbackPollLongTextQuestion {
                    id
                  }
                }
              }
              createdAt
              sessionRecording {
                id
              }
            }
            pageInfo {
              before
              after
              hasNextPage
              hasPreviousPage
            }
          }
        }
      }
    }
  }
`;
export class GetDataForFeedbackPollResponseIndexPageComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      GetDataForFeedbackPollResponseIndexPageQuery,
      GetDataForFeedbackPollResponseIndexPageVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        GetDataForFeedbackPollResponseIndexPageQuery,
        GetDataForFeedbackPollResponseIndexPageVariables
      >
        query={GetDataForFeedbackPollResponseIndexPageDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type GetDataForFeedbackPollResponseIndexPageProps<
  TChildProps = any
> = Partial<
  ReactApollo.DataProps<
    GetDataForFeedbackPollResponseIndexPageQuery,
    GetDataForFeedbackPollResponseIndexPageVariables
  >
> &
  TChildProps;
export function GetDataForFeedbackPollResponseIndexPageHOC<
  TProps,
  TChildProps = any
>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetDataForFeedbackPollResponseIndexPageQuery,
        GetDataForFeedbackPollResponseIndexPageVariables,
        GetDataForFeedbackPollResponseIndexPageProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetDataForFeedbackPollResponseIndexPageQuery,
    GetDataForFeedbackPollResponseIndexPageVariables,
    GetDataForFeedbackPollResponseIndexPageProps<TChildProps>
  >(GetDataForFeedbackPollResponseIndexPageDocument, operationOptions);
}
export const GetDataForGoalEditPageDocument = gql`
  query getDataForGoalEditPage($projectId: ULID!, $goalId: ULID!) {
    currentUser {
      id
      project(id: $projectId) {
        id
        goal(id: $goalId) {
          ...goalForEditGoalPage
        }
      }
    }
  }

  ${GoalForEditGoalPageFragmentDoc}
`;
export class GetDataForGoalEditPageComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      GetDataForGoalEditPageQuery,
      GetDataForGoalEditPageVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        GetDataForGoalEditPageQuery,
        GetDataForGoalEditPageVariables
      >
        query={GetDataForGoalEditPageDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type GetDataForGoalEditPageProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<
    GetDataForGoalEditPageQuery,
    GetDataForGoalEditPageVariables
  >
> &
  TChildProps;
export function GetDataForGoalEditPageHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetDataForGoalEditPageQuery,
        GetDataForGoalEditPageVariables,
        GetDataForGoalEditPageProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetDataForGoalEditPageQuery,
    GetDataForGoalEditPageVariables,
    GetDataForGoalEditPageProps<TChildProps>
  >(GetDataForGoalEditPageDocument, operationOptions);
}
export const UpdateGoalDocument = gql`
  mutation updateGoal($params: GoalParams!, $id: ULID!) {
    updateGoal(goalParams: $params, id: $id) {
      result {
        ...goalForEditGoalPage
      }
      messages {
        message
        field
      }
      successful
    }
  }

  ${GoalForEditGoalPageFragmentDoc}
`;
export class UpdateGoalComponent extends React.Component<
  Partial<ReactApollo.MutationProps<UpdateGoalMutation, UpdateGoalVariables>>
> {
  render() {
    return (
      <ReactApollo.Mutation<UpdateGoalMutation, UpdateGoalVariables>
        mutation={UpdateGoalDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type UpdateGoalProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<UpdateGoalMutation, UpdateGoalVariables>
> &
  TChildProps;
export type UpdateGoalMutationFn = ReactApollo.MutationFn<
  UpdateGoalMutation,
  UpdateGoalVariables
>;
export function UpdateGoalHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        UpdateGoalMutation,
        UpdateGoalVariables,
        UpdateGoalProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    UpdateGoalMutation,
    UpdateGoalVariables,
    UpdateGoalProps<TChildProps>
  >(UpdateGoalDocument, operationOptions);
}
export const GetDataForGoalListPageDocument = gql`
  query getDataForGoalListPage($projectId: ULID!) {
    currentUser {
      id
      project(id: $projectId) {
        id
        goals {
          nodes {
            ... on PathGoal {
              id
              name
              createdAt
              conversionsCount
              path {
                url
                matchType
                index
              }
            }
          }
        }
      }
    }
  }
`;
export class GetDataForGoalListPageComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      GetDataForGoalListPageQuery,
      GetDataForGoalListPageVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        GetDataForGoalListPageQuery,
        GetDataForGoalListPageVariables
      >
        query={GetDataForGoalListPageDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type GetDataForGoalListPageProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<
    GetDataForGoalListPageQuery,
    GetDataForGoalListPageVariables
  >
> &
  TChildProps;
export function GetDataForGoalListPageHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetDataForGoalListPageQuery,
        GetDataForGoalListPageVariables,
        GetDataForGoalListPageProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetDataForGoalListPageQuery,
    GetDataForGoalListPageVariables,
    GetDataForGoalListPageProps<TChildProps>
  >(GetDataForGoalListPageDocument, operationOptions);
}
export const CreateGoalDocument = gql`
  mutation createGoal($params: GoalParams!, $projectId: ULID!) {
    createGoal(projectId: $projectId, goalParams: $params) {
      result {
        ... on GoalEntity {
          id
        }
      }
      messages {
        message
        field
      }
      successful
    }
  }
`;
export class CreateGoalComponent extends React.Component<
  Partial<ReactApollo.MutationProps<CreateGoalMutation, CreateGoalVariables>>
> {
  render() {
    return (
      <ReactApollo.Mutation<CreateGoalMutation, CreateGoalVariables>
        mutation={CreateGoalDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateGoalProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<CreateGoalMutation, CreateGoalVariables>
> &
  TChildProps;
export type CreateGoalMutationFn = ReactApollo.MutationFn<
  CreateGoalMutation,
  CreateGoalVariables
>;
export function CreateGoalHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateGoalMutation,
        CreateGoalVariables,
        CreateGoalProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateGoalMutation,
    CreateGoalVariables,
    CreateGoalProps<TChildProps>
  >(CreateGoalDocument, operationOptions);
}
export const GetDataForHeatmapIndexPageDocument = gql`
  query getDataForHeatmapIndexPage(
    $projectId: ULID!
    $perPage: Int!
    $page: Int!
    $filter: HeatmapPageFilter!
    $order: HeatmapPageOrder!
  ) {
    currentUser {
      id
      project(id: $projectId) {
        id
        heatmapPagesConnection(
          pagination: { perPage: $perPage, page: $page }
          filter: $filter
          order: $order
        ) {
          heatmapPages {
            id
            url
            viewsCount {
              total
              desktop
              mobile
              tablet
            }
            createdAt
          }
          totalRecords
        }
      }
    }
  }
`;
export class GetDataForHeatmapIndexPageComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      GetDataForHeatmapIndexPageQuery,
      GetDataForHeatmapIndexPageVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        GetDataForHeatmapIndexPageQuery,
        GetDataForHeatmapIndexPageVariables
      >
        query={GetDataForHeatmapIndexPageDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type GetDataForHeatmapIndexPageProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<
    GetDataForHeatmapIndexPageQuery,
    GetDataForHeatmapIndexPageVariables
  >
> &
  TChildProps;
export function GetDataForHeatmapIndexPageHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetDataForHeatmapIndexPageQuery,
        GetDataForHeatmapIndexPageVariables,
        GetDataForHeatmapIndexPageProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetDataForHeatmapIndexPageQuery,
    GetDataForHeatmapIndexPageVariables,
    GetDataForHeatmapIndexPageProps<TChildProps>
  >(GetDataForHeatmapIndexPageDocument, operationOptions);
}
export const GetDataForHeatmapShowPageDocument = gql`
  query getDataForHeatmapShowPage(
    $projectId: ULID!
    $heatmapPageId: ULID!
    $deviceType: DeviceType!
  ) {
    currentUser {
      id
      project(id: $projectId) {
        id
        heatmapPage(id: $heatmapPageId) {
          id
          url
          viewsCount {
            desktop
            mobile
            tablet
          }
          snapshot(deviceType: $deviceType) {
            deviceType
            domNodes
            docType
            elements {
              selector
              clickPoints {
                percentX
                percentY
                count
              }
            }
          }
        }
      }
    }
  }
`;
export class GetDataForHeatmapShowPageComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      GetDataForHeatmapShowPageQuery,
      GetDataForHeatmapShowPageVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        GetDataForHeatmapShowPageQuery,
        GetDataForHeatmapShowPageVariables
      >
        query={GetDataForHeatmapShowPageDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type GetDataForHeatmapShowPageProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<
    GetDataForHeatmapShowPageQuery,
    GetDataForHeatmapShowPageVariables
  >
> &
  TChildProps;
export function GetDataForHeatmapShowPageHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetDataForHeatmapShowPageQuery,
        GetDataForHeatmapShowPageVariables,
        GetDataForHeatmapShowPageProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetDataForHeatmapShowPageQuery,
    GetDataForHeatmapShowPageVariables,
    GetDataForHeatmapShowPageProps<TChildProps>
  >(GetDataForHeatmapShowPageDocument, operationOptions);
}
export const GetDataForProjectEditPageDocument = gql`
  query getDataForProjectEditPage($projectId: ULID!) {
    currentUser {
      id
      project(id: $projectId) {
        id
        domain
        isRecordingEnabled
      }
    }
  }
`;
export class GetDataForProjectEditPageComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      GetDataForProjectEditPageQuery,
      GetDataForProjectEditPageVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        GetDataForProjectEditPageQuery,
        GetDataForProjectEditPageVariables
      >
        query={GetDataForProjectEditPageDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type GetDataForProjectEditPageProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<
    GetDataForProjectEditPageQuery,
    GetDataForProjectEditPageVariables
  >
> &
  TChildProps;
export function GetDataForProjectEditPageHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetDataForProjectEditPageQuery,
        GetDataForProjectEditPageVariables,
        GetDataForProjectEditPageProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetDataForProjectEditPageQuery,
    GetDataForProjectEditPageVariables,
    GetDataForProjectEditPageProps<TChildProps>
  >(GetDataForProjectEditPageDocument, operationOptions);
}
export const ChangeProjectSessionRecordingsStatusDocument = gql`
  mutation changeProjectSessionRecordingsStatus(
    $projectId: ULID!
    $active: Boolean!
  ) {
    updateProject(
      id: $projectId
      projectParams: { isRecordingEnabled: $active }
    ) {
      result {
        id
        isRecordingEnabled
      }
      messages {
        message
        field
      }
      successful
    }
  }
`;
export class ChangeProjectSessionRecordingsStatusComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      ChangeProjectSessionRecordingsStatusMutation,
      ChangeProjectSessionRecordingsStatusVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        ChangeProjectSessionRecordingsStatusMutation,
        ChangeProjectSessionRecordingsStatusVariables
      >
        mutation={ChangeProjectSessionRecordingsStatusDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type ChangeProjectSessionRecordingsStatusProps<
  TChildProps = any
> = Partial<
  ReactApollo.MutateProps<
    ChangeProjectSessionRecordingsStatusMutation,
    ChangeProjectSessionRecordingsStatusVariables
  >
> &
  TChildProps;
export type ChangeProjectSessionRecordingsStatusMutationFn = ReactApollo.MutationFn<
  ChangeProjectSessionRecordingsStatusMutation,
  ChangeProjectSessionRecordingsStatusVariables
>;
export function ChangeProjectSessionRecordingsStatusHOC<
  TProps,
  TChildProps = any
>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        ChangeProjectSessionRecordingsStatusMutation,
        ChangeProjectSessionRecordingsStatusVariables,
        ChangeProjectSessionRecordingsStatusProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    ChangeProjectSessionRecordingsStatusMutation,
    ChangeProjectSessionRecordingsStatusVariables,
    ChangeProjectSessionRecordingsStatusProps<TChildProps>
  >(ChangeProjectSessionRecordingsStatusDocument, operationOptions);
}
export const GetProjectForProjectPageDocument = gql`
  query getProjectForProjectPage($projectId: ULID!) {
    billingProducts {
      name
      duration
      id
      usage {
        feedbackPollEnabled
      }
    }
    currentUser {
      id
      currentBilling {
        thisMonthRecordingsCount
        currentUsage {
          feedbackPollEnabled
          fullIdeaLab
          heatmapEnabled
          sessionRecordingsEnabled
          sessionRecordingsStoreDays
          visitorsPerMonth
        }
        subscription {
          nextBillingDate
          product {
            id
            duration
            name
          }
        }
      }
      project(id: $projectId) {
        id
        domain
        state
        isRecordingEnabled
        sessionRecordings {
          totalRecords
          avgSessionTime
          avgSessionClicks
        }
        goals {
          totalRecords
        }
        splitTestsConnection {
          activeTests
          finishedTests
          totalRecords
        }
        feedbackPolls {
          totalRecords
          activePollsCount
          responsesCount
        }
        heatmapPagesConnection {
          totalRecords
        }
      }
    }
  }
`;
export class GetProjectForProjectPageComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      GetProjectForProjectPageQuery,
      GetProjectForProjectPageVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        GetProjectForProjectPageQuery,
        GetProjectForProjectPageVariables
      >
        query={GetProjectForProjectPageDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type GetProjectForProjectPageProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<
    GetProjectForProjectPageQuery,
    GetProjectForProjectPageVariables
  >
> &
  TChildProps;
export function GetProjectForProjectPageHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetProjectForProjectPageQuery,
        GetProjectForProjectPageVariables,
        GetProjectForProjectPageProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetProjectForProjectPageQuery,
    GetProjectForProjectPageVariables,
    GetProjectForProjectPageProps<TChildProps>
  >(GetProjectForProjectPageDocument, operationOptions);
}
export const GetRelevantProjectForRedirectPageDocument = gql`
  query getRelevantProjectForRedirectPage {
    currentUser {
      id
      relevantProject {
        id
      }
    }
  }
`;
export class GetRelevantProjectForRedirectPageComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      GetRelevantProjectForRedirectPageQuery,
      GetRelevantProjectForRedirectPageVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        GetRelevantProjectForRedirectPageQuery,
        GetRelevantProjectForRedirectPageVariables
      >
        query={GetRelevantProjectForRedirectPageDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type GetRelevantProjectForRedirectPageProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<
    GetRelevantProjectForRedirectPageQuery,
    GetRelevantProjectForRedirectPageVariables
  >
> &
  TChildProps;
export function GetRelevantProjectForRedirectPageHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetRelevantProjectForRedirectPageQuery,
        GetRelevantProjectForRedirectPageVariables,
        GetRelevantProjectForRedirectPageProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetRelevantProjectForRedirectPageQuery,
    GetRelevantProjectForRedirectPageVariables,
    GetRelevantProjectForRedirectPageProps<TChildProps>
  >(GetRelevantProjectForRedirectPageDocument, operationOptions);
}
export const GetProjectForProjectSetupPageDocument = gql`
  query getProjectForProjectSetupPage($projectId: ULID!) {
    currentUser {
      id
      project(id: $projectId) {
        id
        domain
      }
    }
  }
`;
export class GetProjectForProjectSetupPageComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      GetProjectForProjectSetupPageQuery,
      GetProjectForProjectSetupPageVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        GetProjectForProjectSetupPageQuery,
        GetProjectForProjectSetupPageVariables
      >
        query={GetProjectForProjectSetupPageDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type GetProjectForProjectSetupPageProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<
    GetProjectForProjectSetupPageQuery,
    GetProjectForProjectSetupPageVariables
  >
> &
  TChildProps;
export function GetProjectForProjectSetupPageHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetProjectForProjectSetupPageQuery,
        GetProjectForProjectSetupPageVariables,
        GetProjectForProjectSetupPageProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetProjectForProjectSetupPageQuery,
    GetProjectForProjectSetupPageVariables,
    GetProjectForProjectSetupPageProps<TChildProps>
  >(GetProjectForProjectSetupPageDocument, operationOptions);
}
export const GetSessionRecordingsForListPageDocument = gql`
  query getSessionRecordingsForListPage(
    $projectId: ULID!
    $perPage: Int!
    $page: Int!
    $filter: SessionRecordingFilter!
  ) {
    currentUser {
      id
      project(id: $projectId) {
        id
        goals {
          nodes {
            ... on GoalEntity {
              id
              name
            }
          }
        }
        sessionRecordings(perPage: $perPage, page: $page, filter: $filter) {
          totalRecords
          nodes {
            ...sessionRecordingForList
          }
        }
      }
    }
  }

  ${SessionRecordingForListFragmentDoc}
`;
export class GetSessionRecordingsForListPageComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      GetSessionRecordingsForListPageQuery,
      GetSessionRecordingsForListPageVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        GetSessionRecordingsForListPageQuery,
        GetSessionRecordingsForListPageVariables
      >
        query={GetSessionRecordingsForListPageDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type GetSessionRecordingsForListPageProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<
    GetSessionRecordingsForListPageQuery,
    GetSessionRecordingsForListPageVariables
  >
> &
  TChildProps;
export function GetSessionRecordingsForListPageHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetSessionRecordingsForListPageQuery,
        GetSessionRecordingsForListPageVariables,
        GetSessionRecordingsForListPageProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetSessionRecordingsForListPageQuery,
    GetSessionRecordingsForListPageVariables,
    GetSessionRecordingsForListPageProps<TChildProps>
  >(GetSessionRecordingsForListPageDocument, operationOptions);
}
export const GetSessionRecordingForRecordingPageDocument = gql`
  query getSessionRecordingForRecordingPage(
    $projectId: ULID!
    $sessionRecordingId: ULID!
  ) {
    currentUser {
      id
      project(id: $projectId) {
        id
        sessionRecording(id: $sessionRecordingId) {
          id
          isVisitorOnline
          referrer
          referrerSource
          duration
          createdAt
          projectId
          startingPage {
            id
            title
            url
          }
          pages {
            id
            title
            url
            duration
            visitedAt
          }
          device {
            id
            screenWidth
            screenHeight
            userAgent
            os {
              name
              version
            }
            browser {
              name
              version
            }
            deviceType: type
          }
          location {
            id
            city
            country
            ip
          }
          convertedProjectGoals {
            ... on GoalEntity {
              id
              name
            }
          }
          splitTestEdges {
            node {
              id
              name
            }
            visitedVariation {
              id
              name
              url
            }
            convertedGoals {
              ... on GoalEntity {
                id
                name
              }
            }
          }
          feedbackPollResponses {
            id
            firstInteractionAt
            answers {
              id
              content
              answeredAt
              question {
                ... on FeedbackPollShortTextQuestion {
                  id
                  title
                }
                ... on FeedbackPollLongTextQuestion {
                  id
                  title
                }
              }
            }
            feedbackPoll {
              id
              name
            }
          }
          events {
            data
            happenedAt
            type
          }
        }
      }
    }
  }
`;
export class GetSessionRecordingForRecordingPageComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      GetSessionRecordingForRecordingPageQuery,
      GetSessionRecordingForRecordingPageVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        GetSessionRecordingForRecordingPageQuery,
        GetSessionRecordingForRecordingPageVariables
      >
        query={GetSessionRecordingForRecordingPageDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type GetSessionRecordingForRecordingPageProps<
  TChildProps = any
> = Partial<
  ReactApollo.DataProps<
    GetSessionRecordingForRecordingPageQuery,
    GetSessionRecordingForRecordingPageVariables
  >
> &
  TChildProps;
export function GetSessionRecordingForRecordingPageHOC<
  TProps,
  TChildProps = any
>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetSessionRecordingForRecordingPageQuery,
        GetSessionRecordingForRecordingPageVariables,
        GetSessionRecordingForRecordingPageProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetSessionRecordingForRecordingPageQuery,
    GetSessionRecordingForRecordingPageVariables,
    GetSessionRecordingForRecordingPageProps<TChildProps>
  >(GetSessionRecordingForRecordingPageDocument, operationOptions);
}
export const GetDataForSplitTestEditPageDocument = gql`
  query getDataForSplitTestEditPage($splitTestId: ULID!, $projectId: ULID!) {
    pageCategories {
      id
      name
    }
    pageTypes {
      id
      name
    }
    testIdeas {
      id
      name: title
    }
    currentUser {
      id
      project(id: $projectId) {
        id
        splitTest(id: $splitTestId) {
          ...splitTestForEditSplitTestPage
        }
      }
    }
  }

  ${SplitTestForEditSplitTestPageFragmentDoc}
`;
export class GetDataForSplitTestEditPageComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      GetDataForSplitTestEditPageQuery,
      GetDataForSplitTestEditPageVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        GetDataForSplitTestEditPageQuery,
        GetDataForSplitTestEditPageVariables
      >
        query={GetDataForSplitTestEditPageDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type GetDataForSplitTestEditPageProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<
    GetDataForSplitTestEditPageQuery,
    GetDataForSplitTestEditPageVariables
  >
> &
  TChildProps;
export function GetDataForSplitTestEditPageHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetDataForSplitTestEditPageQuery,
        GetDataForSplitTestEditPageVariables,
        GetDataForSplitTestEditPageProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetDataForSplitTestEditPageQuery,
    GetDataForSplitTestEditPageVariables,
    GetDataForSplitTestEditPageProps<TChildProps>
  >(GetDataForSplitTestEditPageDocument, operationOptions);
}
export const ActivateSplitTestAtSplitTestEditPageDocument = gql`
  mutation activateSplitTestAtSplitTestEditPage($splitTestId: ULID!) {
    activateSplitTest(splitTestId: $splitTestId) {
      ...splitTestForEditSplitTestPage
    }
  }

  ${SplitTestForEditSplitTestPageFragmentDoc}
`;
export class ActivateSplitTestAtSplitTestEditPageComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      ActivateSplitTestAtSplitTestEditPageMutation,
      ActivateSplitTestAtSplitTestEditPageVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        ActivateSplitTestAtSplitTestEditPageMutation,
        ActivateSplitTestAtSplitTestEditPageVariables
      >
        mutation={ActivateSplitTestAtSplitTestEditPageDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type ActivateSplitTestAtSplitTestEditPageProps<
  TChildProps = any
> = Partial<
  ReactApollo.MutateProps<
    ActivateSplitTestAtSplitTestEditPageMutation,
    ActivateSplitTestAtSplitTestEditPageVariables
  >
> &
  TChildProps;
export type ActivateSplitTestAtSplitTestEditPageMutationFn = ReactApollo.MutationFn<
  ActivateSplitTestAtSplitTestEditPageMutation,
  ActivateSplitTestAtSplitTestEditPageVariables
>;
export function ActivateSplitTestAtSplitTestEditPageHOC<
  TProps,
  TChildProps = any
>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        ActivateSplitTestAtSplitTestEditPageMutation,
        ActivateSplitTestAtSplitTestEditPageVariables,
        ActivateSplitTestAtSplitTestEditPageProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    ActivateSplitTestAtSplitTestEditPageMutation,
    ActivateSplitTestAtSplitTestEditPageVariables,
    ActivateSplitTestAtSplitTestEditPageProps<TChildProps>
  >(ActivateSplitTestAtSplitTestEditPageDocument, operationOptions);
}
export const FinishSplitTestAtSplitTestEditPageDocument = gql`
  mutation finishSplitTestAtSplitTestEditPage($splitTestId: ULID!) {
    finishSplitTest(splitTestId: $splitTestId) {
      ...splitTestForEditSplitTestPage
    }
  }

  ${SplitTestForEditSplitTestPageFragmentDoc}
`;
export class FinishSplitTestAtSplitTestEditPageComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      FinishSplitTestAtSplitTestEditPageMutation,
      FinishSplitTestAtSplitTestEditPageVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        FinishSplitTestAtSplitTestEditPageMutation,
        FinishSplitTestAtSplitTestEditPageVariables
      >
        mutation={FinishSplitTestAtSplitTestEditPageDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type FinishSplitTestAtSplitTestEditPageProps<
  TChildProps = any
> = Partial<
  ReactApollo.MutateProps<
    FinishSplitTestAtSplitTestEditPageMutation,
    FinishSplitTestAtSplitTestEditPageVariables
  >
> &
  TChildProps;
export type FinishSplitTestAtSplitTestEditPageMutationFn = ReactApollo.MutationFn<
  FinishSplitTestAtSplitTestEditPageMutation,
  FinishSplitTestAtSplitTestEditPageVariables
>;
export function FinishSplitTestAtSplitTestEditPageHOC<
  TProps,
  TChildProps = any
>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        FinishSplitTestAtSplitTestEditPageMutation,
        FinishSplitTestAtSplitTestEditPageVariables,
        FinishSplitTestAtSplitTestEditPageProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    FinishSplitTestAtSplitTestEditPageMutation,
    FinishSplitTestAtSplitTestEditPageVariables,
    FinishSplitTestAtSplitTestEditPageProps<TChildProps>
  >(FinishSplitTestAtSplitTestEditPageDocument, operationOptions);
}
export const PauseSplitTestAtSplitTestEditPageDocument = gql`
  mutation pauseSplitTestAtSplitTestEditPage($splitTestId: ULID!) {
    pauseSplitTest(splitTestId: $splitTestId) {
      ...splitTestForEditSplitTestPage
    }
  }

  ${SplitTestForEditSplitTestPageFragmentDoc}
`;
export class PauseSplitTestAtSplitTestEditPageComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      PauseSplitTestAtSplitTestEditPageMutation,
      PauseSplitTestAtSplitTestEditPageVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        PauseSplitTestAtSplitTestEditPageMutation,
        PauseSplitTestAtSplitTestEditPageVariables
      >
        mutation={PauseSplitTestAtSplitTestEditPageDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type PauseSplitTestAtSplitTestEditPageProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    PauseSplitTestAtSplitTestEditPageMutation,
    PauseSplitTestAtSplitTestEditPageVariables
  >
> &
  TChildProps;
export type PauseSplitTestAtSplitTestEditPageMutationFn = ReactApollo.MutationFn<
  PauseSplitTestAtSplitTestEditPageMutation,
  PauseSplitTestAtSplitTestEditPageVariables
>;
export function PauseSplitTestAtSplitTestEditPageHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        PauseSplitTestAtSplitTestEditPageMutation,
        PauseSplitTestAtSplitTestEditPageVariables,
        PauseSplitTestAtSplitTestEditPageProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    PauseSplitTestAtSplitTestEditPageMutation,
    PauseSplitTestAtSplitTestEditPageVariables,
    PauseSplitTestAtSplitTestEditPageProps<TChildProps>
  >(PauseSplitTestAtSplitTestEditPageDocument, operationOptions);
}
export const UpdateSplitTestAtSplitTestEditPageDocument = gql`
  mutation updateSplitTestAtSplitTestEditPage(
    $splitTestId: ULID!
    $splitTestParams: SplitTestParams!
  ) {
    updateSplitTest(
      splitTestId: $splitTestId
      splitTestParams: $splitTestParams
    ) {
      result {
        ...splitTestForEditSplitTestPage
      }
      messages {
        message
        field
      }
      successful
    }
  }

  ${SplitTestForEditSplitTestPageFragmentDoc}
`;
export class UpdateSplitTestAtSplitTestEditPageComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      UpdateSplitTestAtSplitTestEditPageMutation,
      UpdateSplitTestAtSplitTestEditPageVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        UpdateSplitTestAtSplitTestEditPageMutation,
        UpdateSplitTestAtSplitTestEditPageVariables
      >
        mutation={UpdateSplitTestAtSplitTestEditPageDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type UpdateSplitTestAtSplitTestEditPageProps<
  TChildProps = any
> = Partial<
  ReactApollo.MutateProps<
    UpdateSplitTestAtSplitTestEditPageMutation,
    UpdateSplitTestAtSplitTestEditPageVariables
  >
> &
  TChildProps;
export type UpdateSplitTestAtSplitTestEditPageMutationFn = ReactApollo.MutationFn<
  UpdateSplitTestAtSplitTestEditPageMutation,
  UpdateSplitTestAtSplitTestEditPageVariables
>;
export function UpdateSplitTestAtSplitTestEditPageHOC<
  TProps,
  TChildProps = any
>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        UpdateSplitTestAtSplitTestEditPageMutation,
        UpdateSplitTestAtSplitTestEditPageVariables,
        UpdateSplitTestAtSplitTestEditPageProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    UpdateSplitTestAtSplitTestEditPageMutation,
    UpdateSplitTestAtSplitTestEditPageVariables,
    UpdateSplitTestAtSplitTestEditPageProps<TChildProps>
  >(UpdateSplitTestAtSplitTestEditPageDocument, operationOptions);
}
export const GetDataForSplitTestIndexPageDocument = gql`
  query getDataForSplitTestIndexPage(
    $projectId: ULID!
    $conversionGoalId: ULID = null
    $perPage: Int!
    $page: Int!
    $filter: SplitTestFilter!
  ) {
    pageCategories {
      id
      name
    }
    pageTypes {
      id
      name
    }
    currentUser {
      id
      project(id: $projectId) {
        id
        splitTestsConnection(perPage: $perPage, page: $page, filter: $filter) {
          splitTests {
            ...splitTestForIndexSplitTestPage
          }
          totalRecords
        }
      }
    }
  }

  ${SplitTestForIndexSplitTestPageFragmentDoc}
`;
export class GetDataForSplitTestIndexPageComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      GetDataForSplitTestIndexPageQuery,
      GetDataForSplitTestIndexPageVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        GetDataForSplitTestIndexPageQuery,
        GetDataForSplitTestIndexPageVariables
      >
        query={GetDataForSplitTestIndexPageDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type GetDataForSplitTestIndexPageProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<
    GetDataForSplitTestIndexPageQuery,
    GetDataForSplitTestIndexPageVariables
  >
> &
  TChildProps;
export function GetDataForSplitTestIndexPageHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetDataForSplitTestIndexPageQuery,
        GetDataForSplitTestIndexPageVariables,
        GetDataForSplitTestIndexPageProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetDataForSplitTestIndexPageQuery,
    GetDataForSplitTestIndexPageVariables,
    GetDataForSplitTestIndexPageProps<TChildProps>
  >(GetDataForSplitTestIndexPageDocument, operationOptions);
}
export const GetSplitTestForSplitTestIndexPageDocument = gql`
  query getSplitTestForSplitTestIndexPage(
    $splitTestId: ULID!
    $conversionGoalId: ULID!
    $projectId: ULID!
  ) {
    currentUser {
      id
      project(id: $projectId) {
        id
        splitTest(id: $splitTestId) {
          ...splitTestForIndexSplitTestPage
        }
      }
    }
  }

  ${SplitTestForIndexSplitTestPageFragmentDoc}
`;
export class GetSplitTestForSplitTestIndexPageComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      GetSplitTestForSplitTestIndexPageQuery,
      GetSplitTestForSplitTestIndexPageVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        GetSplitTestForSplitTestIndexPageQuery,
        GetSplitTestForSplitTestIndexPageVariables
      >
        query={GetSplitTestForSplitTestIndexPageDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type GetSplitTestForSplitTestIndexPageProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<
    GetSplitTestForSplitTestIndexPageQuery,
    GetSplitTestForSplitTestIndexPageVariables
  >
> &
  TChildProps;
export function GetSplitTestForSplitTestIndexPageHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetSplitTestForSplitTestIndexPageQuery,
        GetSplitTestForSplitTestIndexPageVariables,
        GetSplitTestForSplitTestIndexPageProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetSplitTestForSplitTestIndexPageQuery,
    GetSplitTestForSplitTestIndexPageVariables,
    GetSplitTestForSplitTestIndexPageProps<TChildProps>
  >(GetSplitTestForSplitTestIndexPageDocument, operationOptions);
}
export const ActivateSplitTestAtSplitTestIndexPageDocument = gql`
  mutation activateSplitTestAtSplitTestIndexPage(
    $splitTestId: ULID!
    $conversionGoalId: ULID
  ) {
    activateSplitTest(splitTestId: $splitTestId) {
      ...splitTestForIndexSplitTestPage
    }
  }

  ${SplitTestForIndexSplitTestPageFragmentDoc}
`;
export class ActivateSplitTestAtSplitTestIndexPageComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      ActivateSplitTestAtSplitTestIndexPageMutation,
      ActivateSplitTestAtSplitTestIndexPageVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        ActivateSplitTestAtSplitTestIndexPageMutation,
        ActivateSplitTestAtSplitTestIndexPageVariables
      >
        mutation={ActivateSplitTestAtSplitTestIndexPageDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type ActivateSplitTestAtSplitTestIndexPageProps<
  TChildProps = any
> = Partial<
  ReactApollo.MutateProps<
    ActivateSplitTestAtSplitTestIndexPageMutation,
    ActivateSplitTestAtSplitTestIndexPageVariables
  >
> &
  TChildProps;
export type ActivateSplitTestAtSplitTestIndexPageMutationFn = ReactApollo.MutationFn<
  ActivateSplitTestAtSplitTestIndexPageMutation,
  ActivateSplitTestAtSplitTestIndexPageVariables
>;
export function ActivateSplitTestAtSplitTestIndexPageHOC<
  TProps,
  TChildProps = any
>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        ActivateSplitTestAtSplitTestIndexPageMutation,
        ActivateSplitTestAtSplitTestIndexPageVariables,
        ActivateSplitTestAtSplitTestIndexPageProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    ActivateSplitTestAtSplitTestIndexPageMutation,
    ActivateSplitTestAtSplitTestIndexPageVariables,
    ActivateSplitTestAtSplitTestIndexPageProps<TChildProps>
  >(ActivateSplitTestAtSplitTestIndexPageDocument, operationOptions);
}
export const FinishSplitTestAtSplitTestIndexPageDocument = gql`
  mutation finishSplitTestAtSplitTestIndexPage(
    $splitTestId: ULID!
    $conversionGoalId: ULID
  ) {
    finishSplitTest(splitTestId: $splitTestId) {
      ...splitTestForIndexSplitTestPage
    }
  }

  ${SplitTestForIndexSplitTestPageFragmentDoc}
`;
export class FinishSplitTestAtSplitTestIndexPageComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      FinishSplitTestAtSplitTestIndexPageMutation,
      FinishSplitTestAtSplitTestIndexPageVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        FinishSplitTestAtSplitTestIndexPageMutation,
        FinishSplitTestAtSplitTestIndexPageVariables
      >
        mutation={FinishSplitTestAtSplitTestIndexPageDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type FinishSplitTestAtSplitTestIndexPageProps<
  TChildProps = any
> = Partial<
  ReactApollo.MutateProps<
    FinishSplitTestAtSplitTestIndexPageMutation,
    FinishSplitTestAtSplitTestIndexPageVariables
  >
> &
  TChildProps;
export type FinishSplitTestAtSplitTestIndexPageMutationFn = ReactApollo.MutationFn<
  FinishSplitTestAtSplitTestIndexPageMutation,
  FinishSplitTestAtSplitTestIndexPageVariables
>;
export function FinishSplitTestAtSplitTestIndexPageHOC<
  TProps,
  TChildProps = any
>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        FinishSplitTestAtSplitTestIndexPageMutation,
        FinishSplitTestAtSplitTestIndexPageVariables,
        FinishSplitTestAtSplitTestIndexPageProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    FinishSplitTestAtSplitTestIndexPageMutation,
    FinishSplitTestAtSplitTestIndexPageVariables,
    FinishSplitTestAtSplitTestIndexPageProps<TChildProps>
  >(FinishSplitTestAtSplitTestIndexPageDocument, operationOptions);
}
export const PauseSplitTestAtSplitTestIndexPageDocument = gql`
  mutation pauseSplitTestAtSplitTestIndexPage(
    $splitTestId: ULID!
    $conversionGoalId: ULID
  ) {
    pauseSplitTest(splitTestId: $splitTestId) {
      ...splitTestForIndexSplitTestPage
    }
  }

  ${SplitTestForIndexSplitTestPageFragmentDoc}
`;
export class PauseSplitTestAtSplitTestIndexPageComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      PauseSplitTestAtSplitTestIndexPageMutation,
      PauseSplitTestAtSplitTestIndexPageVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        PauseSplitTestAtSplitTestIndexPageMutation,
        PauseSplitTestAtSplitTestIndexPageVariables
      >
        mutation={PauseSplitTestAtSplitTestIndexPageDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type PauseSplitTestAtSplitTestIndexPageProps<
  TChildProps = any
> = Partial<
  ReactApollo.MutateProps<
    PauseSplitTestAtSplitTestIndexPageMutation,
    PauseSplitTestAtSplitTestIndexPageVariables
  >
> &
  TChildProps;
export type PauseSplitTestAtSplitTestIndexPageMutationFn = ReactApollo.MutationFn<
  PauseSplitTestAtSplitTestIndexPageMutation,
  PauseSplitTestAtSplitTestIndexPageVariables
>;
export function PauseSplitTestAtSplitTestIndexPageHOC<
  TProps,
  TChildProps = any
>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        PauseSplitTestAtSplitTestIndexPageMutation,
        PauseSplitTestAtSplitTestIndexPageVariables,
        PauseSplitTestAtSplitTestIndexPageProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    PauseSplitTestAtSplitTestIndexPageMutation,
    PauseSplitTestAtSplitTestIndexPageVariables,
    PauseSplitTestAtSplitTestIndexPageProps<TChildProps>
  >(PauseSplitTestAtSplitTestIndexPageDocument, operationOptions);
}
export const CreateSplitTestAtSplitTestCreatePageDocument = gql`
  mutation createSplitTestAtSplitTestCreatePage(
    $projectId: ULID!
    $splitTestParams: SplitTestParams!
  ) {
    createSplitTest(projectId: $projectId, splitTestParams: $splitTestParams) {
      result {
        id
        name
        description
        pageCategory {
          id
          name
        }
        pageType {
          id
          name
        }
      }
      messages {
        message
        field
      }
      successful
    }
  }
`;
export class CreateSplitTestAtSplitTestCreatePageComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      CreateSplitTestAtSplitTestCreatePageMutation,
      CreateSplitTestAtSplitTestCreatePageVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        CreateSplitTestAtSplitTestCreatePageMutation,
        CreateSplitTestAtSplitTestCreatePageVariables
      >
        mutation={CreateSplitTestAtSplitTestCreatePageDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateSplitTestAtSplitTestCreatePageProps<
  TChildProps = any
> = Partial<
  ReactApollo.MutateProps<
    CreateSplitTestAtSplitTestCreatePageMutation,
    CreateSplitTestAtSplitTestCreatePageVariables
  >
> &
  TChildProps;
export type CreateSplitTestAtSplitTestCreatePageMutationFn = ReactApollo.MutationFn<
  CreateSplitTestAtSplitTestCreatePageMutation,
  CreateSplitTestAtSplitTestCreatePageVariables
>;
export function CreateSplitTestAtSplitTestCreatePageHOC<
  TProps,
  TChildProps = any
>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateSplitTestAtSplitTestCreatePageMutation,
        CreateSplitTestAtSplitTestCreatePageVariables,
        CreateSplitTestAtSplitTestCreatePageProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateSplitTestAtSplitTestCreatePageMutation,
    CreateSplitTestAtSplitTestCreatePageVariables,
    CreateSplitTestAtSplitTestCreatePageProps<TChildProps>
  >(CreateSplitTestAtSplitTestCreatePageDocument, operationOptions);
}
export const GetDataForSplitTestNewPageDocument = gql`
  query getDataForSplitTestNewPage {
    testIdeas {
      id
      name: title
    }
    pageCategories {
      id
      name
    }
    pageTypes {
      id
      name
    }
  }
`;
export class GetDataForSplitTestNewPageComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      GetDataForSplitTestNewPageQuery,
      GetDataForSplitTestNewPageVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        GetDataForSplitTestNewPageQuery,
        GetDataForSplitTestNewPageVariables
      >
        query={GetDataForSplitTestNewPageDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type GetDataForSplitTestNewPageProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<
    GetDataForSplitTestNewPageQuery,
    GetDataForSplitTestNewPageVariables
  >
> &
  TChildProps;
export function GetDataForSplitTestNewPageHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetDataForSplitTestNewPageQuery,
        GetDataForSplitTestNewPageVariables,
        GetDataForSplitTestNewPageProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetDataForSplitTestNewPageQuery,
    GetDataForSplitTestNewPageVariables,
    GetDataForSplitTestNewPageProps<TChildProps>
  >(GetDataForSplitTestNewPageDocument, operationOptions);
}
export const GetDataForSplitTestSessionRecordingIndexPageDocument = gql`
  query getDataForSplitTestSessionRecordingIndexPage(
    $projectId: ULID!
    $splitTestId: ULID!
    $perPage: Int!
    $page: Int!
    $filter: SessionRecordingFilter!
  ) {
    currentUser {
      id
      project(id: $projectId) {
        id
        sessionRecordings(perPage: $perPage, page: $page, filter: $filter) {
          totalRecords
          nodes {
            ...sessionRecordingForList
          }
        }
        splitTest(id: $splitTestId) {
          id
          goals {
            ... on GoalEntity {
              id
              name
            }
          }
        }
      }
    }
  }

  ${SessionRecordingForListFragmentDoc}
`;
export class GetDataForSplitTestSessionRecordingIndexPageComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      GetDataForSplitTestSessionRecordingIndexPageQuery,
      GetDataForSplitTestSessionRecordingIndexPageVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        GetDataForSplitTestSessionRecordingIndexPageQuery,
        GetDataForSplitTestSessionRecordingIndexPageVariables
      >
        query={GetDataForSplitTestSessionRecordingIndexPageDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type GetDataForSplitTestSessionRecordingIndexPageProps<
  TChildProps = any
> = Partial<
  ReactApollo.DataProps<
    GetDataForSplitTestSessionRecordingIndexPageQuery,
    GetDataForSplitTestSessionRecordingIndexPageVariables
  >
> &
  TChildProps;
export function GetDataForSplitTestSessionRecordingIndexPageHOC<
  TProps,
  TChildProps = any
>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetDataForSplitTestSessionRecordingIndexPageQuery,
        GetDataForSplitTestSessionRecordingIndexPageVariables,
        GetDataForSplitTestSessionRecordingIndexPageProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetDataForSplitTestSessionRecordingIndexPageQuery,
    GetDataForSplitTestSessionRecordingIndexPageVariables,
    GetDataForSplitTestSessionRecordingIndexPageProps<TChildProps>
  >(GetDataForSplitTestSessionRecordingIndexPageDocument, operationOptions);
}
export const GetDataForSplitTestShowPageDocument = gql`
  query getDataForSplitTestShowPage(
    $projectId: ULID!
    $splitTestId: ULID!
    $goalId: ULID = null
  ) {
    currentUser {
      id
      project(id: $projectId) {
        id
        splitTest(id: $splitTestId) {
          id
          name
          variations {
            id
            name
            url
            control
          }
          goals {
            ... on PathGoal {
              id
              name
            }
          }
          variationsConversions(goalId: $goalId) {
            conversionsCount
            conversionRate
            goalId
            improvementRate
            ratesByDate {
              conversionRate
              date
            }
            revenue
            variationId
            visitsCount
            isWinner
          }
        }
      }
    }
  }
`;
export class GetDataForSplitTestShowPageComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      GetDataForSplitTestShowPageQuery,
      GetDataForSplitTestShowPageVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        GetDataForSplitTestShowPageQuery,
        GetDataForSplitTestShowPageVariables
      >
        query={GetDataForSplitTestShowPageDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type GetDataForSplitTestShowPageProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<
    GetDataForSplitTestShowPageQuery,
    GetDataForSplitTestShowPageVariables
  >
> &
  TChildProps;
export function GetDataForSplitTestShowPageHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetDataForSplitTestShowPageQuery,
        GetDataForSplitTestShowPageVariables,
        GetDataForSplitTestShowPageProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetDataForSplitTestShowPageQuery,
    GetDataForSplitTestShowPageVariables,
    GetDataForSplitTestShowPageProps<TChildProps>
  >(GetDataForSplitTestShowPageDocument, operationOptions);
}
export const GetDataForTestIdeaIndexPageDocument = gql`
  query getDataForTestIdeaIndexPage(
    $pagination: Pagination!
    $filter: TestIdeaFilter!
  ) {
    testIdeaCategories {
      id
      name
    }
    currentUser {
      id
      testIdeas(filter: $filter, pagination: $pagination) {
        totalRecords
        edges {
          isLiked
          node {
            id
            coverUrl
            description
            impactRate
            title
            category {
              id
              color
              name
              iconUrl
            }
          }
        }
      }
    }
  }
`;
export class GetDataForTestIdeaIndexPageComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      GetDataForTestIdeaIndexPageQuery,
      GetDataForTestIdeaIndexPageVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        GetDataForTestIdeaIndexPageQuery,
        GetDataForTestIdeaIndexPageVariables
      >
        query={GetDataForTestIdeaIndexPageDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type GetDataForTestIdeaIndexPageProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<
    GetDataForTestIdeaIndexPageQuery,
    GetDataForTestIdeaIndexPageVariables
  >
> &
  TChildProps;
export function GetDataForTestIdeaIndexPageHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetDataForTestIdeaIndexPageQuery,
        GetDataForTestIdeaIndexPageVariables,
        GetDataForTestIdeaIndexPageProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetDataForTestIdeaIndexPageQuery,
    GetDataForTestIdeaIndexPageVariables,
    GetDataForTestIdeaIndexPageProps<TChildProps>
  >(GetDataForTestIdeaIndexPageDocument, operationOptions);
}
export const ToggleLikeAtTestIdeaIndexPageDocument = gql`
  mutation toggleLikeAtTestIdeaIndexPage($testIdeaTestId: ULID!) {
    toggleTestIdeaLike(testIdeaTestId: $testIdeaTestId) {
      testIdeaEdge {
        isLiked
        node {
          id
        }
      }
    }
  }
`;
export class ToggleLikeAtTestIdeaIndexPageComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      ToggleLikeAtTestIdeaIndexPageMutation,
      ToggleLikeAtTestIdeaIndexPageVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        ToggleLikeAtTestIdeaIndexPageMutation,
        ToggleLikeAtTestIdeaIndexPageVariables
      >
        mutation={ToggleLikeAtTestIdeaIndexPageDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type ToggleLikeAtTestIdeaIndexPageProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    ToggleLikeAtTestIdeaIndexPageMutation,
    ToggleLikeAtTestIdeaIndexPageVariables
  >
> &
  TChildProps;
export type ToggleLikeAtTestIdeaIndexPageMutationFn = ReactApollo.MutationFn<
  ToggleLikeAtTestIdeaIndexPageMutation,
  ToggleLikeAtTestIdeaIndexPageVariables
>;
export function ToggleLikeAtTestIdeaIndexPageHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        ToggleLikeAtTestIdeaIndexPageMutation,
        ToggleLikeAtTestIdeaIndexPageVariables,
        ToggleLikeAtTestIdeaIndexPageProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    ToggleLikeAtTestIdeaIndexPageMutation,
    ToggleLikeAtTestIdeaIndexPageVariables,
    ToggleLikeAtTestIdeaIndexPageProps<TChildProps>
  >(ToggleLikeAtTestIdeaIndexPageDocument, operationOptions);
}
