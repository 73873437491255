import React, { useState } from "react";
import Textarea from "react-textarea-autosize";
import styled, { css } from "styled-components/macro";
import { DragIcon } from "testly-web/icons/DragIcon";
import { RemoveIcon } from "testly-web/icons/RemoveIcon";
import { errorBorderColor } from "../../Form/Input";
import { OutsideEventHandler } from "../../OnOutsideEvent";
import { QuestionType } from "../types";
import { SelectedType, TypesDropdown } from "./TypesDropdown";

const borderRadius = 4;

const onError = ({ isError }: { isError: boolean }) =>
  isError &&
  css`
    border-color: ${errorBorderColor};
  `;

// eslint-disable-next-line no-unexpected-multiline
const QuestionTextareaInputStyled = styled.div<{
  active: boolean;
  isError: boolean;
}>`
  border-radius: ${borderRadius}px;
  border: solid 1px #dadddf;

  transition: box-shadow 0.5s, border-color 0.3s;

  background-color: #fff;
  ${({ active }) =>
    active &&
    css`
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
      border-color: #a7a9ae;
    `}
  
  ${onError}

  ${({ isError }) =>
    isError &&
    css`
      border-width: 2px;
    `}
`;

const Header = styled.div<{ isError: boolean }>`
  display: flex;
  border-bottom: solid 1px #dbdee0;
  height: 36px;
  ${onError}
`;

// eslint-disable-next-line no-unexpected-multiline
const OrderNumber = styled.div<{
  active: boolean;
}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #a7a9ae;

  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  color: #ffffff;

  margin-left: 8px;

  transition: background-color 0.5s;

  ${({ active }) =>
    active &&
    css`
      background-color: #00ac69;
    `}
`;

const DragAndOrderSection = styled.div<{ isError: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  padding-right: 8px;
  border-right: solid 1px #dbdee0;

  ${onError}
`;

const OptionsSelectSection = styled.div<{ isError: boolean }>`
  border-right: solid 1px #dbdee0;

  ${onError}

  ${SelectedType} {
    ${onError}
  }
`;

const DeleteSection = styled.div<{ isError: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 37px;
  margin-left: auto;

  border-left: solid 1px #dbdee0;

  cursor: pointer;

  transition: background-color 0.5s;

  ${onError}

  svg path {
    transition: fill 0.5s;
  }

  &:hover {
    background-color: #f5f5f5;

    svg path {
      fill: red;
    }
  }
`;
const Body = styled.div``;

const ErrorSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${errorBorderColor};
  font-weight: 400;
  margin-left: 8px;
`;

const textareaLineHeight = 19;
const textareaPaddingVertically = 12;
const FullTextarea = styled(Textarea)`
  display: block;
  box-sizing: border-box;
  padding: ${textareaPaddingVertically}px 20px;
  border: none;
  width: 100%;
  height: 100%;
  resize: none;
  line-height: ${textareaLineHeight}px;
  border-radius: ${borderRadius}px;
  outline: none;
  font-size: 14px;
`;

const DragIconStyled = styled(DragIcon)`
  margin-left: 5px;
  cursor: grab;
`;

export const TextQuestionInput = React.forwardRef<
  HTMLDivElement,
  {
    orderNumber: number;
    selectedQuestionType: QuestionType;
    onQuestionTypeSelect: (selectedQuestionType: QuestionType) => void;
    ableToChangeType: boolean;
    value: string;
    onValueChange: (value: string) => void;
    onDeleteClick: () => void;
    deletable: boolean;
    className?: string;
    error?: string;
  } & React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >
>(
  (
    {
      orderNumber,
      selectedQuestionType,
      onQuestionTypeSelect,
      value,
      onValueChange,
      onDeleteClick,
      deletable,
      error,
      ableToChangeType,
      ...props
    },
    ref
  ) => {
    const [isFocused, setFocused] = useState(false);
    const isError = error !== undefined;

    return (
      <OutsideEventHandler
        eventName="mousedown"
        onEvent={() => setFocused(false)}
      >
        <QuestionTextareaInputStyled
          active={isFocused}
          isError={isError}
          {...props}
          onMouseDown={e => {
            if (props.onMouseDown) {
              props.onMouseDown(e);
            }
            setFocused(true);
          }}
          ref={ref}
        >
          <Header isError={isError}>
            <DragAndOrderSection isError={isError}>
              <DragIconStyled />
              <OrderNumber active={isFocused}>{orderNumber}</OrderNumber>
            </DragAndOrderSection>
            <OptionsSelectSection isError={isError}>
              <TypesDropdown
                selectedQuestionType={selectedQuestionType}
                onQuestionTypeSelect={onQuestionTypeSelect}
                ableToChangeType={ableToChangeType}
              />
            </OptionsSelectSection>
            {error && <ErrorSection>{error}</ErrorSection>}
            {deletable && (
              <DeleteSection onClick={onDeleteClick} isError={isError}>
                <RemoveIcon />
              </DeleteSection>
            )}
          </Header>
          <Body>
            <FullTextarea
              placeholder="Would you like a response to your question or comment?"
              value={value}
              onChange={e => {
                onValueChange(e.currentTarget.value);
              }}
              minRows={3}
              maxRows={5}
            />
          </Body>
        </QuestionTextareaInputStyled>
      </OutsideEventHandler>
    );
  }
);
