import { FrameProducer } from "session-player/playback/FrameProducer";
import {
  BrokerEventListener,
  FrameRenderedEvent,
  OnErrorListener,
  PlaybackEventType,
  PlaybackManager,
  PlayingState
} from "session-player/playback/PlaybackManager";

import { DomMutator } from "session-player/dom/DomMutator";
import { Scroller } from "session-player/dom/Scroller";
import { TimelineManager } from "./playback/TimelineManager";
import { StylesheetMutator } from "./stylesheet/StylesheetMutator";
import { UserPlayerSettings } from "./UserPlayerSettings";

export type OnFrameRenderedListener = (
  event: FrameRenderedEvent
) => Promise<void>;

// It:
// 1) renders frames to iframes
// 2) notify listeners about new frames
// 3) receive stop/pause/seek commands
export class PlayerController {
  private timelineManager: TimelineManager;
  private playbackManager: PlaybackManager;
  private domMutator: DomMutator;
  private scroller: Scroller;
  private stylesheetMutator: StylesheetMutator;
  private userPlayerSettings: UserPlayerSettings;

  constructor({
    timelineManager,
    contentWindow,
    userPlayerSettings
  }: {
    timelineManager: TimelineManager;
    contentWindow: Window;
    userPlayerSettings: UserPlayerSettings;
  }) {
    this.timelineManager = timelineManager;
    this.userPlayerSettings = userPlayerSettings;

    this.playbackManager = new PlaybackManager(
      timelineManager,
      new FrameProducer(timelineManager),
      this.userPlayerSettings
    );
    this.domMutator = new DomMutator(contentWindow.document);
    this.scroller = new Scroller(contentWindow);
    this.stylesheetMutator = new StylesheetMutator(contentWindow);

    this.initialize();
  }

  public initialize = () => {
    this.onNewFrame(async event => {
      const frame = event.frame;

      if (frame.likeThePreviousFrame) {
        return;
      }

      if (frame.isNewSnapshot || !frame.isConsecutiveFrame) {
        this.domMutator.initDomSnapshot(
          frame.lastDomSnapshot,
          frame.lastDocType
        );

        this.domMutator.mutateDom(frame.snapshotMutations);
        this.stylesheetMutator.applyToDom(frame.cssRulesMutations);
      } else {
        this.domMutator.mutateDom(frame.snapshotMutationsInFrameTime);
        this.stylesheetMutator.applyToDom(frame.cssRulesMutationsInFrameTime);
      }

      this.scroller.scroll(frame.lastScrollsPositions);
    });
  };

  public state(): PlayingState {
    return this.playbackManager.stateManager.currentState;
  }

  public appendListener(listener: BrokerEventListener): void {
    this.playbackManager.appendListener(listener);
  }

  public onError(listener: OnErrorListener): void {
    this.playbackManager.appendOnErrorListener(listener);
  }

  public onNewFrame(listener: OnFrameRenderedListener): void {
    this.playbackManager.appendListener(async e => {
      if (e.eventType === PlaybackEventType.FrameRendered) {
        await listener(e);
      }
    });
  }

  public play(): void {
    this.playbackManager.play();
  }

  public pause(): void {
    this.playbackManager.pause();
  }

  public seek(playTime: number): void {
    this.playbackManager.seek(playTime);
  }

  public seekPercent(percent: number): void {
    this.playbackManager.seek(
      Math.floor((percent / 100) * this.timelineManager.duration())
    );
  }

  public stop(): void {
    this.playbackManager.stop();
  }
}
