import React from "react";
import styled from "styled-components";

import { LogoutIcon } from "testly-web/icons/LogoutIcon";
import { logoutUser } from "testly-web/services/accounts";
import { scrollableContentVerticalGap, sidebarNavHeight } from "./constants";

const LOGIN_URL = process.env.REACT_APP_LOGIN_URL as string;

const StyledAccountSettingsNav = styled.nav`
  display: flex;
  justify-content: space-between;

  height: ${sidebarNavHeight}px;
  padding: 0 40px;
  margin-bottom: ${scrollableContentVerticalGap}px;

  background-color: #fff;
  border-bottom: 4px solid #e2e4e6;

  box-sizing: border-box;
`;

const AccountSettingsNavTabs = styled.nav`
  display: flex;

  height: 100%;

  & > div {
    display: flex;
    align-items: center;

    height: 100%;

    font-size: 18px;
    font-weight: 500;
    color: #393839;

    cursor: pointer;
  }

  div + div {
    margin-left: 40px;
  }

  div.active {
    border-bottom: 4px solid #00ac69;
    color: #00ac69;
  }
`;

const LogoutLink = styled.a`
  display: flex;
  align-items: center;

  opacity: 0.75;

  color: #747474;
  font-size: 14px;

  transition: 0.2s opacity;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  & > span {
    margin-right: 10px;
  }
`;

interface AccountSettingsNavProps {
  activeSettingsTab: string;
  switchTab(tab: string): void;
}
// <div
//   className={activeSettingsTab === "billing" ? "active" : ""}
//   onClick={() => switchTab("billing")}
// >
//   Billing
// </div>

export const AccountSettingsNav: React.SFC<AccountSettingsNavProps> = ({
  activeSettingsTab,
  switchTab
}) => (
  <StyledAccountSettingsNav>
    <AccountSettingsNavTabs>
      <div
        className={activeSettingsTab === "profile" ? "active" : ""}
        onClick={() => switchTab("profile")}
      >
        Profile
      </div>
      <div
        className={activeSettingsTab === "password" ? "active" : ""}
        onClick={() => switchTab("password")}
      >
        Password
      </div>
    </AccountSettingsNavTabs>
    <LogoutLink
      onClick={async () => {
        await logoutUser();
        window.location.replace(LOGIN_URL);
      }}
    >
      <span>Logout</span>
      <LogoutIcon />
    </LogoutLink>
  </StyledAccountSettingsNav>
);
