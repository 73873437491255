import React from "react";

export const IdeaLabIcon = () => (
  // tslint:disable:max-line-length
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="25"
    viewBox="0 0 26 25"
  >
    <path
      fillRule="nonzero"
      d="M4.469 0L3.163 1.322 5.107 3.29l1.306-1.322L4.469 0zM21.53 0l-1.944 1.968 1.306 1.322 1.944-1.968L21.531 0zM13 .588a8.878 8.878 0 0 0-.929.058h-.029c-3.765.437-6.76 3.518-7.283 7.315-.417 3.055.827 5.824 2.902 7.609a4.943 4.943 0 0 1 1.625 2.85v5.64h2.118c.323.562.91.94 1.596.94s1.273-.378 1.596-.94h2.118V20.3h.087v-1.117c0-1.377.708-2.768 1.857-3.848 1.538-1.557 2.7-3.771 2.7-6.316C21.357 4.374 17.58.543 13 .588zm0 1.88c3.587-.052 6.5 2.923 6.5 6.55 0 1.97-.9 3.702-2.147 4.966l.029.029a7.342 7.342 0 0 0-2.264 4.407h-4.062c-.203-1.613-.889-3.17-2.177-4.29-1.639-1.413-2.618-3.536-2.292-5.934.406-2.963 2.779-5.343 5.688-5.67.243-.029.486-.055.725-.058zM0 9.018v1.88h2.786v-1.88H0zm23.214 0v1.88H26v-1.88h-2.786zm-18.107 7.61l-1.944 1.968 1.306 1.322 1.944-1.969-1.306-1.322zm15.786 0l-1.306 1.321 1.944 1.969 1.306-1.322-1.944-1.969zm-9.75 3.672h3.714v1.88h-3.714V20.3z"
    />
  </svg>
);
