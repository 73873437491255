import React from "react";

export const CloseIcon: React.SFC<React.SVGProps<SVGSVGElement>> = props => (
  // tslint:disable:max-line-length
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    {...props}
  >
    <path
      fill="#A7A9AE"
      d="M.974 0L0 .974 5.026 6 0 11.026.974 12 6 6.974 11.026 12l.974-.974L6.974 6 12 .974 11.026 0 6 5.026z"
    />
  </svg>
);
