import React from "react";
import { Frame } from "session-player/playback/types";
import { MousePosition } from "session-player/siteTypes";
import shallowEqual from "shallowequal";
import styled, { css, keyframes } from "styled-components/macro";
import { LayerProps } from "./types";

interface ClickLayerState {
  previousFrame?: Frame;
  clicks: {
    position: MousePosition;
    animated: boolean;
  }[];
}

const clickKeyframes = keyframes`
  0% {
    transform: translate(0px, 0px);
    width: 0px;
    height: 0px;
  }

  100% {
    transform: translate(-10px, -10px);
    width: 17px;
    height: 17px;
  }
`;

// eslint-disable-next-line no-unexpected-multiline
const Click = React.memo(styled.div<{
  mousePosition: MousePosition;
  animated: boolean;
}>`
  width: 0px;
  height: 0px;
  z-index: 10000;
  position: absolute;
  background-color: #e0106d;
  box-shadow: 0 0 15px 0 #bd10e0;
  border-radius: 50%;
  margin-left: 2px;
  margin-top: 2px;

  ${({ animated }) =>
    animated
      ? css`
          animation: ${clickKeyframes} 0.6s normal;
        `
      : css`
          transform: translate(-10px, -10px);
          width: 17px;
          height: 17px;
        `}
  ${({ mousePosition }) => css`
    top: ${mousePosition.y}px;
    left: ${mousePosition.x}px;
  `};
`);

export class ClickLayer extends React.Component<LayerProps, ClickLayerState> {
  public static getDerivedStateFromProps(
    { frame }: LayerProps,
    state: ClickLayerState
  ): ClickLayerState {
    const lastMouseClickPosition = frame.mouseClickInFrameTime;

    if (
      state.clicks[state.clicks.length - 1] &&
      lastMouseClickPosition === state.clicks[state.clicks.length - 1].position
    ) {
      return {
        clicks: state.clicks.map((click, i) => {
          if (state.clicks.length - 1 === i) {
            return { ...click, animated: false };
          } else {
            return click;
          }
        }),
        previousFrame: frame
      };
    } else {
      return {
        clicks: (frame.mouseClickInFrameTime === undefined
          ? state.clicks
          : [
              ...state.clicks,
              { position: frame.mouseClickInFrameTime, animated: true }
            ]
        ).filter(click => click.animated),
        previousFrame: frame
      };
    }
  }
  constructor(props: LayerProps) {
    super(props);

    this.state = {
      clicks: []
    };
  }

  public shouldComponentUpdate(
    nextProps: LayerProps,
    nextState: ClickLayerState
  ) {
    return !shallowEqual(nextState.clicks, this.state.clicks);
  }

  public render() {
    return (
      <div className="testly-player-click-layer">
        {this.state.clicks.map((click, i) => (
          <Click
            mousePosition={click.position}
            animated={click.animated}
            key={i}
          />
        ))}
      </div>
    );
  }
}
