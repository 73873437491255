import React, { useState } from "react";
import { ValueType } from "react-select/lib/types";
import styled from "styled-components/macro";
import { Button } from "testly-web/components/Button";
import { Input } from "testly-web/components/Form";
import {
  Option,
  Select as SelectComponent
} from "testly-web/components/Form/Select";
import { GetDataForTestIdeaIndexPageTestIdeaCategories } from "testly-web/queries";
import { Filter } from "./queryState";

const SearchStyled = styled.form`
  display: grid;
  grid-template-columns: 260px 220px 140px 130px;
  grid-column-gap: 30px;
  align-items: center;

  height: 100px;
  padding: 0 40px;

  background-color: #f2f3f4;
`;
const defaultImpactOption = {
  label: "Any Impact",
  value: "any"
};

const impactOptions = [
  defaultImpactOption,
  {
    label: "1",
    value: "1"
  },
  {
    label: "2",
    value: "2"
  },
  {
    label: "3",
    value: "3"
  },
  {
    label: "4",
    value: "4"
  },
  {
    label: "5",
    value: "5"
  }
];

const defaultCategoryOption = {
  label: "Any Category",
  value: "any"
};

export const Search: React.SFC<{
  categories: GetDataForTestIdeaIndexPageTestIdeaCategories[];
  filter: Filter;
  onFilterChange: (filter: Filter) => void;
}> = ({ categories, filter: propsFilter, onFilterChange }) => {
  const [filter, setFilter] = useState<Filter>(propsFilter);

  const categoryOptions = [defaultCategoryOption].concat(
    categories.map(category => ({
      label: category.name,
      value: category.id
    }))
  );

  return (
    <SearchStyled
      onSubmit={e => {
        e.preventDefault();
        onFilterChange(filter);
      }}
    >
      <Input
        placeholder="Search"
        onChange={e => setFilter({ ...filter, titleCont: e.target.value })}
        value={filter.titleCont || ""}
      />
      <SelectComponent
        placeholder="All Categories"
        options={categoryOptions}
        value={
          categoryOptions.find(({ value }) => value === filter.categoryIdEq) ||
          defaultCategoryOption
        }
        onChange={(option: ValueType<Option>) => {
          const { value } = option as Option;

          setFilter({
            ...filter,
            categoryIdEq: value === "any" ? null : value
          });
        }}
      />
      <SelectComponent
        options={impactOptions}
        placeholder="Impact Level"
        value={
          impactOptions.find(({ value }) => value === filter.impactRateEq) ||
          defaultImpactOption
        }
        onChange={(option: ValueType<Option>) => {
          const { value } = option as Option;

          setFilter({
            ...filter,
            impactRateEq: value === "any" ? null : value
          });
        }}
      />
      <Button
        size="big"
        action="secondary"
        onClick={() => onFilterChange(filter)}
      >
        Search
      </Button>
    </SearchStyled>
  );
};
