import React from "react";
import { GetCurrentUserForTopBarComponent } from "testly-web/queries";
import { User } from "./User";

export const UserContainer: React.SFC<{ className?: string }> = ({
  className
}) => (
  <GetCurrentUserForTopBarComponent>
    {({ loading, data }) => (
      <User
        loading={loading}
        currentUser={data && data.currentUser!}
        className={className}
      />
    )}
  </GetCurrentUserForTopBarComponent>
);
