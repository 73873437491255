import { SplitTestType } from "testly-web/pages/SplitTestIndexPage/SplitTestCard/SplitTestCard";
import { SplitTestForIndexSplitTestPageFragment } from "testly-web/queries";

export const mapSplitTest = (
  splitTest: SplitTestForIndexSplitTestPageFragment
): SplitTestType => {
  const selectedGoal =
    splitTest.variationsConversions.length === 0
      ? null
      : splitTest.goals.find(
          ({ id }) => id === splitTest.variationsConversions[0].goalId
        )!;
  const selectedGoalId = selectedGoal ? selectedGoal.id : null;

  const variations = splitTest.variations.map(variation => {
    const conversion = splitTest.variationsConversions.find(
      ({ goalId, variationId }) =>
        goalId === selectedGoalId && variationId === variation.id
    ) || {
      conversionRate: 0,
      visitsCount: 0,
      isWinner: false,
      improvementRate: 0,
      conversionsCount: 0
    };

    return {
      ...variation,
      conversion: {
        ...conversion,
        improvementRate: variation.control ? null : conversion.improvementRate
      }
    };
  });

  return {
    ...splitTest,
    selectedGoal,
    variations
  };
};
