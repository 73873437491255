import React from "react";
import { ButtonStyledProps } from "testly-web/components/Button/Button";
import { OkIcon } from "testly-web/icons/OkIcon";
import { SplitTestStatus } from "testly-web/queries";
import {
  Action,
  PauseStyled,
  PlayStyled,
  StyledButton,
  StyledDropdown
} from "./common";

const buttonActionMap: Record<SplitTestStatus, ButtonStyledProps["action"]> = {
  [SplitTestStatus.Active]: "primary",
  [SplitTestStatus.Finished]: "secondary",
  [SplitTestStatus.Draft]: "secondary",
  [SplitTestStatus.Paused]: "thirdly"
};

export interface SplitTestActions {
  activate: () => void;
  pause: () => void;
  complete: () => void;
  finishSetup: () => void;
}

export const ActionButton: React.SFC<
  {
    status: SplitTestStatus;
    isUpdating: boolean;
  } & SplitTestActions
> = ({ status, isUpdating, activate, pause, complete, finishSetup }) => {
  return (
    <StyledDropdown
      animation="popup"
      dropdownContent={() => (
        <>
          <Action active={status === SplitTestStatus.Active} onClick={activate}>
            Active
          </Action>
          <Action active={status === SplitTestStatus.Paused} onClick={pause}>
            Pause
          </Action>
          <Action active={false} onClick={complete}>
            Finish Test
          </Action>
        </>
      )}
      dropdownEnabled={
        status !== SplitTestStatus.Finished && status !== SplitTestStatus.Draft
      }
    >
      <StyledButton
        action={buttonActionMap[status]}
        size="small"
        dropdown={[SplitTestStatus.Active, SplitTestStatus.Paused].includes(
          status
        )}
        loading={isUpdating}
        onClick={() => status === SplitTestStatus.Draft && finishSetup()}
      >
        {status === SplitTestStatus.Draft && "Finish Setup"}
        {status === SplitTestStatus.Active && (
          <>
            <PlayStyled />
            Active
          </>
        )}

        {status === SplitTestStatus.Paused && (
          <>
            <PauseStyled />
            Paused
          </>
        )}

        {status === SplitTestStatus.Finished && (
          <>
            <OkIcon />
            Finished
          </>
        )}
      </StyledButton>
    </StyledDropdown>
  );
};
