import React from "react";

export const VariationWinnerIcon: React.SFC<
  React.SVGProps<SVGSVGElement>
> = props => (
  // tslint:disable:max-line-length
  <svg
    width="57"
    height="57"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs>
      <path id="b" d="M0 0h40L0 40z" />
      <filter
        x="-31.2%"
        y="-31.2%"
        width="172.5%"
        height="172.5%"
        filterUnits="objectBoundingBox"
        id="a"
      >
        >
        <feOffset dx="2" dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation="4.5"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.304913949 0"
          in="shadowBlurOuter1"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(7 7)">
        <use fill="#000" filter="url(#a)" xlinkHref="#b" />
        <use fill="#00AC69" xlinkHref="#b" />
      </g>
      <path
        d="M25.5415807 18.5026403l-2.9398129 2.8691709.6928397 4.0483562c.0380379.2064934-.0489067.4184211-.220079.5406867-.0950951.0706428-.2092102.1059639-.3260421.1059639-.0869446 0-.176606-.0190193-.2581162-.0624916L18.855 24.0915459l-3.6353695 1.9127805c-.18747475.0978127-.41298671.0815109-.58415901-.0434723-.1711723-.1222656-.25811687-.3341933-.22007827-.5406867l.69283903-4.0483562-2.93981296-2.8691709c-.152153-.1467193-.20649335-.3667975-.14128492-.5678565.06520842-.1983428.23909756-.3450615.44559092-.3749488l4.06737553-.59230957 1.81768478-3.68427619c.1874747-.37766565.8069548-.37766565.9944289 0l1.8176847 3.68427619 4.0673755.59230957c.2092109.0298873.3831001.176606.445591.3749488.0652084.201059.010868.4211372-.141285.5678565z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </g>
  </svg>
);
