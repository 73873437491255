import { omit } from "lodash";
import {
  FeedbackForFormFragment,
  FeedbackPollParams,
  FeedbackPollShowOption,
  PageMatcherMatchType
} from "testly-web/queries";
import * as Yup from "yup";
import { QuestionType } from "./types";

export interface QuestionValues {
  type: QuestionType;
  id: string;
  title: string;
  index: number;
  isNew: boolean;
}

export interface FeedbackFormValues {
  name: string;
  questions: QuestionValues[];
  thankYouMessage: string;
  isActive: boolean;
  isPageMatcherEnabled: boolean;
  pageMatchers: Array<{
    url: string;
    matchType: PageMatcherMatchType;
  }>;
  isPollOpenedOnStart: boolean;
  showPollOption: FeedbackPollShowOption;
}

export const feedbackFormSchema = Yup.object().shape({
  name: Yup.string().required("A name is required."),
  thankYouMessage: Yup.string().required("A thank you message is required."),
  questions: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().required("A question is required.")
    })
  ),
  pageMatcher: Yup.object().when("isPageMatcherEnabled", {
    is: true,
    then: Yup.array().of(
      Yup.object().shape({
        url: Yup.string().required("An url is required."),
        matchType: Yup.string()
      })
    )
  })
});

const typenameToQuestionType = {
  FeedbackPollShortTextQuestion: QuestionType.ShortTextQuestion,
  FeedbackPollLongTextQuestion: QuestionType.LongTextQuestion
};

export const mapQueryDataToFormValues = (
  data: FeedbackForFormFragment
): FeedbackFormValues => {
  return {
    ...omit(data, ["__typename", "id"]),
    questions: data.questions.map(q => ({
      ...omit(q, "__typename"),
      type: typenameToQuestionType[q.__typename],
      isNew: false
    })),
    pageMatchers: data.pageMatchers.map(matcher => omit(matcher, "__typename"))
  };
};

export const mapFormValuesToMutationParams = (
  values: FeedbackFormValues
): FeedbackPollParams => {
  return {
    ...values,
    questions: values.questions.map((q, i) => ({
      ...omit(q, "isNew"),
      index: i,
      id: q.isNew ? null : q.id
    })),
    ...(values.isPageMatcherEnabled
      ? { pageMatchers: values.pageMatchers }
      : { pageMatchers: [] })
  };
};

export const initPageMatcher = () => ({
  url: "",
  matchType: PageMatcherMatchType.Contains
});
