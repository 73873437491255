import React from "react";
import styled, { css } from "styled-components/macro";

type ValueSign = "positive" | "negative" | "none";

// eslint-disable-next-line no-unexpected-multiline
const StyledConversionImprovement = styled.span<{
  valueType: ValueSign;
}>`
  padding: 4px 8px;

  border-radius: 4px;

  color: #fff;
  font-weight: 500;

  ${({ valueType }) =>
    valueType === "negative" &&
    css`
      background-color: #ff5857;
    `};

  ${({ valueType }) =>
    valueType === "positive" &&
    css`
      background-color: #00ac69;
    `};

  ${({ valueType }) =>
    valueType === "none" &&
    css`
      color: #393839;
    `};
`;

interface ConversionImprovementProps {
  value: number;
}

export const ConversionImprovement: React.SFC<ConversionImprovementProps> = ({
  value
}) => {
  const valueType: ValueSign = (() => {
    if (value > 0) {
      return "positive";
    } else if (value < 0) {
      return "negative";
    } else {
      return "none";
    }
  })();

  return (
    <StyledConversionImprovement valueType={valueType}>
      {valueType === "positive" && "+"}
      {value.toFixed(2)}%
    </StyledConversionImprovement>
  );
};
