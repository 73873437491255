import { darken } from "polished";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { Button } from "testly-web/components/Button";
import { CopyIcon } from "testly-web/icons/CopyIcon";
import { WaitingIcon } from "testly-web/icons/WaitingIcon";
import { paths } from "testly-web/paths";
import { Body, Footer, Header, Step, StepNumber } from "./Step";

export interface CodeStepBaseProps {
  project: {
    id: string;
    domain: string;
  };
}

export interface CodeStepCodeProps {
  codeToInstall: string;
  codeContainerRef: React.RefObject<HTMLElement>;
  onCopyClick(): void;
  onCodeClick(): void;
}

const Domain = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;

  max-width: 300px;

  svg {
    flex-shrink: 0;
    margin-right: 11px;
  }
`;

const DomainName = styled.div`
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
`;

const Description = styled.div`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.83;
  color: #393839;
`;
const Code = styled.div`
  position: relative;
  border-radius: 8px;
  background-color: #f8f8f8;
  padding: 55px 40px;
  padding-bottom: 20px;
  margin-top: 12px;

  font-family: NotoMono;
  font-size: 12px;
  color: #393839;
  line-height: 1.67;
  white-space: pre;
`;

const copyToClipboardBgColor = "#f0f1f1";

const CopyToClipboard = styled.button`
  position: absolute;
  top: 12px;
  right: 11px;
  display: flex;
  align-items: center;
  padding: 8px 14px;
  border-radius: 4px;
  border: none;
  background-color: ${copyToClipboardBgColor};
  outline: none;
  cursor: pointer;

  transition: all 0.5s ease;

  font-family: Roboto;
  font-size: 10px;
  color: rgba(57, 56, 57, 0.75);

  &:active {
    transform: translate(8px, 8px);
  }

  &:hover {
    background-color: ${darken(0.03, copyToClipboardBgColor)};
  }

  svg {
    margin-right: 8px;
  }
`;
const LastChecked = styled.div`
  font-family: Roboto;
  font-size: 12px;
  color: #a7a9ae;
  margin-left: 3px;
`;

export const CodeStep: React.SFC<CodeStepBaseProps & CodeStepCodeProps> = ({
  project: { id, domain },
  onCopyClick,
  codeToInstall,
  codeContainerRef,
  onCodeClick
}) => (
  <Step>
    <Header>
      <StepNumber>2</StepNumber>
      Do this next{" "}
      <Domain>
        <WaitingIcon />
        <DomainName aria-label={domain}>{domain}</DomainName>
      </Domain>
    </Header>
    <Body>
      <Description>
        Paste this code between the {"<head>"} tag at the top to start learning
        about your visitors.
      </Description>
      <Code onClick={onCodeClick}>
        <CopyToClipboard onClick={onCopyClick}>
          <CopyIcon />
          Copy to clipboard
        </CopyToClipboard>
        <span ref={codeContainerRef}>{codeToInstall}</span>
      </Code>
    </Body>
    <Footer>
      <LastChecked />
      <Link to={paths.projectPath({ projectId: id })}>
        <Button action="primary" size="small">
          Go To Dashboard
        </Button>
      </Link>
    </Footer>
  </Step>
);
