import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { SetCurrentProjectId } from "testly-web/components/CurrentProjectId/SetCurrentProjectId";
import { GetCurrentUserId } from "testly-web/components/CurrentUser/GetCurrentUserId";
import { loadingRender } from "testly-web/components/loadingRender";
import { SetNavbar } from "testly-web/components/Navbar/SetNavbar";
import { paths } from "testly-web/paths";
import {
  GetDataForTestIdeaIndexPageComponent,
  ToggleLikeAtTestIdeaIndexPageComponent
} from "testly-web/queries";
import { HeaderTab } from "./Header";
import { useQueryState } from "./queryState";
import { TestIdeaIndexPage } from "./TestIdeaIndexPage";

const perPage = 12;

export const TestIdeaIndexPageContainer: React.SFC<
  RouteComponentProps<{
    projectId: string;
    tab: HeaderTab;
  }>
> = ({
  history,
  match: {
    params: { projectId, tab }
  },
  location: { search }
}) => {
  const [state, setState] = useQueryState(history, search);
  const { filter, page } = state;

  return (
    <>
      <SetCurrentProjectId id={projectId} />
      <SetNavbar
        header="Idea Lab"
        backPath={paths.projectPath({ projectId })}
      />
      <GetCurrentUserId>
        {currentUserId => (
          <ToggleLikeAtTestIdeaIndexPageComponent>
            {toggleLike => (
              <GetDataForTestIdeaIndexPageComponent
                fetchPolicy="network-only"
                notifyOnNetworkStatusChange={true}
                variables={{
                  pagination: { page, perPage },
                  filter: {
                    ...filter,
                    impactRateEq: filter.impactRateEq
                      ? parseInt(filter.impactRateEq, 10)
                      : null,
                    likeUserIdEq: tab === "favorites" ? currentUserId : null
                  }
                }}
              >
                {loadingRender("currentUser.testIdeas.edges", ({ data }) => (
                  <TestIdeaIndexPage
                    toggleLike={toggleLike}
                    categories={data!.testIdeaCategories}
                    ideaEdges={data!.currentUser!.testIdeas.edges}
                    filter={filter}
                    onFilterChange={newFilter =>
                      setState({ ...state, page: 1, filter: newFilter })
                    }
                    pageCount={Math.ceil(
                      data!.currentUser!.testIdeas.totalRecords / perPage
                    )}
                    onPageChange={newPage => {
                      window.scrollTo({
                        top: 0,
                        left: 0
                      });

                      setState({ ...state, page: newPage });
                    }}
                    page={page}
                    headerTab={tab}
                    projectId={projectId}
                  />
                ))}
              </GetDataForTestIdeaIndexPageComponent>
            )}
          </ToggleLikeAtTestIdeaIndexPageComponent>
        )}
      </GetCurrentUserId>
    </>
  );
};
