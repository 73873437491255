import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components/macro";

import { Button } from "../../components/Button";

import { Link } from "react-router-dom";
import { paths } from "testly-web/paths";
import { GetDataForTestIdeaIndexPageEdges } from "testly-web/queries";
import {
  EmptyHeartIcon,
  FilledHeartIcon,
  ImpactIcon,
  StartTestIcon
} from "../../icons/IdeaLabIcons";

const CardContainer = styled.div`
  height: 430px;

  position: relative;
`;

const CardDescriptionWrapper = styled.div`
  position: relative;
`;

const CardDescription = styled.p`
  margin: 0 0 15px;

  position: relative;

  font-size: 12px;
  color: #393839;

  overflow: hidden;
`;

const ReadMoreLink = styled.a`
  position: absolute;
  right: 0;
  bottom: -1.5em;

  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;

const Card = styled.div<{ expanded?: boolean }>`
  padding: 20px;

  position: absolute;

  background-color: #fff;
  box-sizing: border-box;

  font-size: 12px;
  line-height: 18px;
  color: steelblue;

  transition: all 0.2s;

  width: 100%;

  ${({ expanded }) => css`
    height: ${expanded ? "auto" : "430px"};
    box-shadow: ${expanded ? "0 20px 50px rgba(0,0,0,.3)" : null};
    z-index: ${expanded ? 2 : "auto"};

    ${CardDescription} {
      height: ${expanded ? "auto" : "90px"};
    }

    ${ReadMoreLink} {
      top: ${expanded ? "-1.5em" : "auto"};
      bottom: ${expanded ? "auto" : "-1.5em"};
    }
  `};
`;

const CardPicWrapper = styled("div")<{ bg: string }>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 160px;
  margin-bottom: 10px;

  /* background: ${p => p.bg}; */

  img {
    max-height: 100%;
    max-width: 100%;
    /* max-width: 50%; */
    /* height: auto; */
    /* width: auto; */
    /* max-height: 80%; */
    /* width: 100%; */
  }
`;

const ControlsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Tag = styled("div")<{ color?: string }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  height: 18px;
  padding: 0 10px;

  border-radius: 12px;
  border: solid 1px ${p => p.color || "#a7a9ae"};

  color: ${p => p.color || "#a7a9ae"};
  font-size: 9px;
  font-weight: 600;
  letter-spacing: 0.7px;
  text-transform: uppercase;

  & > svg {
    margin-right: 5px;

    * {
      fill: ${p => p.color};
    }
  }

  & + & {
    margin-left: 10px;
  }
`;

const CardTitle = styled.h4`
  margin: 15px 0;

  font-size: 16px;
  font-weight: 500;
  color: #393839;
`;

const StyledButton = styled(Button)`
  width: 120px;

  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.4px;

  svg {
    margin-right: 10px;
  }
`;

export const TestIdea: React.SFC<{
  ideaEdge: GetDataForTestIdeaIndexPageEdges;
  onLikeClick: () => void;
  projectId: string;
}> = ({ ideaEdge, onLikeClick, projectId }) => {
  const [expanded, setExpanded] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const { node } = ideaEdge;

  const handleClickOutside = (event: Event) => {
    if (!ref.current) {
      return;
    }

    if (!ref.current.contains(event.target as HTMLDivElement)) {
      setExpanded(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <CardContainer>
      <Card expanded={expanded} ref={ref}>
        <CardPicWrapper bg={node.category.color}>
          <img src={node.coverUrl} alt="" />
        </CardPicWrapper>
        <ControlsContainer>
          <Tag color={node.category.color}>
            <img src={node.category.iconUrl || ""} alt="" />
            <span>{node.category.name}</span>
          </Tag>
          <Tag>
            <ImpactIcon />
            <span>{`${node.impactRate}/5 Impact`}</span>
          </Tag>
        </ControlsContainer>
        <CardTitle>{node.title}</CardTitle>
        <CardDescriptionWrapper>
          <CardDescription>{node.description}</CardDescription>
          {node.description.length > 350 && (
            <ReadMoreLink
              href="#"
              onClick={event => {
                event.preventDefault();
                setExpanded(!expanded);
              }}
            >
              {expanded ? "Close" : "Read more"}
            </ReadMoreLink>
          )}
        </CardDescriptionWrapper>
        <ControlsContainer>
          <Link
            to={{
              pathname: paths.splitTestNewPath({ projectId }),
              search: `?ideaId=${ideaEdge.node.id}`,
              state: {
                previousPath: `${window.location.pathname}${
                  window.location.search
                }`
              }
            }}
          >
            <StyledButton action="secondary" size="big">
              <StartTestIcon />
              Start Test
            </StyledButton>
          </Link>

          <div
            style={{ marginLeft: "auto", cursor: "pointer" }}
            onClick={onLikeClick}
          >
            {ideaEdge.isLiked ? <FilledHeartIcon /> : <EmptyHeartIcon />}
          </div>
        </ControlsContainer>
      </Card>
    </CardContainer>
  );
};
