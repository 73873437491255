import { darken } from "polished";
import React from "react";
import styled, { css } from "styled-components/macro";
import { DropdownContent } from "testly-web/components/Dropdown";
import { DropdownArrow } from "testly-web/components/Dropdown/DropdownArrow";
import { DropdownContainer } from "testly-web/components/Dropdown/DropdownContainer";
import { Event as EventIcon } from "testly-web/icons/ActionIcon";
import { toMMSS } from "testly-web/utils";
import { Time } from "../styles";
import { ViewableActionType } from "../viewableActions";

export interface ViewableAction {
  actionType: ViewableActionType;
  title: string;
  body: string;
  time: number;
  dropdownContent?: React.ReactNode;
}

const actionBgColor = "#fff";

const ActionStyled = styled.div`
  position: relative;
  border-top: solid 1px #ecedee;

  cursor: pointer;

  background-color: ${actionBgColor};

  transition: background-color 0.3s;

  &:hover {
    background-color: ${darken(0.04, actionBgColor)};
  }
`;

const Wrapper = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  position: relative;
  padding-right: 40px;
  background-color: #fff;

  ${({ active }) =>
    active &&
    css`
      z-index: 1001;
      &:before {
        content: " ";
        position: absolute;
        background-color: #00ac69;
        width: 5px;
        height: calc(100% + 2px);
        top: -1px;
        left: 0px;
      }
    `};
`;

const Content = styled.div`
  position: relative;
  flex: 1;
  margin-left: 10px;
`;

const Title = styled.div`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: #1e1d1e;
`;

const Body = styled.div`
  font-family: Roboto;
  font-size: 12px;
  line-height: 1.67;
  color: #a7a9ae;
  word-break: break-word;
`;

const ActionTime = styled(Time)`
  position: absolute;
  top: 0;
  right: 0;
`;

const DropdownArrowStyled = styled(DropdownArrow)`
  position: absolute;
  right: -24px;
  top: 50%;
  transform: translateY(-50%);
`;

const DropdownContainerStyled = styled(DropdownContainer)`
  ${DropdownContent} {
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.21);
    border: none;
    border-radius: 0;
    margin-top: 1px;
    right: 0;
  }
`;

export const Action: React.SFC<{
  action: ViewableAction;
  active: boolean;
  innerRef: React.RefObject<HTMLDivElement>;
  onClick(): void;
}> = React.memo(({ action, active, onClick, innerRef }) => (
  <DropdownContainerStyled
    dropdownContent={() => action.dropdownContent || null}
    animation="slide"
  >
    <ActionStyled
      onClick={onClick}
      ref={
        // tslint:disable-next-line:no-any
        innerRef as React.RefObject<any>
      }
    >
      <Wrapper active={active}>
        <EventIcon tail={false} active={false} actionType={action.actionType} />

        <Content>
          <Title>{action.title}</Title>
          <Body>{action.body}</Body>

          <ActionTime>{toMMSS(action.time / 1000)}</ActionTime>

          {action.dropdownContent ? <DropdownArrowStyled /> : null}
        </Content>
      </Wrapper>
    </ActionStyled>
  </DropdownContainerStyled>
));
