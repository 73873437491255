export enum CssRuleMutationType {
  CssRuleDeleteMutation = "cssRuleDeleteMutation",
  CssRuleInsertMutation = "cssRuleInsertedMutation"
}

interface CssRuleDeleteMutation {
  index: number;
  nodeId: number;
  type: CssRuleMutationType.CssRuleDeleteMutation;
}

interface CssRuleInsertMutation {
  rule: string;
  index: number;
  nodeId: number;
  type: CssRuleMutationType.CssRuleInsertMutation;
}

export type CssRuleMutation = CssRuleInsertMutation | CssRuleDeleteMutation;
