import React from "react";

export const LocationIcon = () => (
  // tslint:disable:max-line-length
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path
      fill="#B9BABD"
      d="M8 0C5.393 0 3.079 1.262 1.617 3.2H1.6v.022A7.945 7.945 0 0 0 0 8c0 4.409 3.591 8 8 8s8-3.591 8-8-3.591-8-8-8zm2.4 2.067c2.348.947 4 3.24 4 5.933a6.37 6.37 0 0 1-1.681 4.32A1.594 1.594 0 0 0 11.2 11.2h-.8V8.8a.8.8 0 0 0-.8-.8h-4V6.4h.8a.8.8 0 0 0 .8-.8V4h1.6a1.6 1.6 0 0 0 1.6-1.6v-.333zM1.766 6.566L3.2 8l2.4 2.4v.8a1.6 1.6 0 0 0 1.6 1.6v1.545a6.383 6.383 0 0 1-5.434-7.78z"
    />
  </svg>
);
