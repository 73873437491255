import { FieldArray, Form, FormikProps } from "formik";
import React from "react";
import styled from "styled-components/macro";
import { Button } from "testly-web/components/Button";
import { GoalFields } from "testly-web/components/goal/GoalFields";
import {
  initialGoalValues,
  PathGoalForm
} from "testly-web/components/goal/pathGoal";
import { Body, Panel } from "testly-web/components/Panel";
import { TrashIcon } from "testly-web/icons/TrashIcon";
import { Footer, Hr } from "../Panel/Panel";
import { BackButton, NextButton } from "./formComponents";
import { GoalsFormValues } from "./schemas";

const GoalFieldsStyled = styled.div`
  position: relative;
  border-left: 4px solid #a7e1fb;
  padding-left: 20px;
`;

const TrashIconStyled = styled(TrashIcon)`
  position: absolute;
  cursor: pointer;
  top: -5px;
  right: 0;
`;

const GoalName = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
  padding-right: 30px;
`;

const GoalButton = styled(Button)`
  padding-left: 16px;
  &:before {
    display: inline-block;
    font-size: 21px;
    content: "+";
    color: #a7e1fb;
    font-weight: 400;
    margin-right: 12px;
  }
`;

const BodyStyled = styled(Body)`
  padding-bottom: 20px;
`;

const goalFieldsRender = (
  formProps: FormikProps<GoalsFormValues>,
  remove: (i: number) => void
) => {
  const { values } = formProps;

  return (goal: PathGoalForm, i: number) => (
    <>
      <GoalFieldsStyled>
        <GoalName>{goal.name}</GoalName>

        <GoalFields key={i} formProps={formProps} fieldPrefix={`goals.${i}.`} />

        {values.goals.length !== 1 && (
          <TrashIconStyled
            onClick={() => {
              remove(i);
            }}
            role="button"
          />
        )}
      </GoalFieldsStyled>
      <Hr />
    </>
  );
};

export const GoalsForm: React.SFC<{
  formProps: FormikProps<GoalsFormValues>;
  onBackButtonClick(): void;
}> = ({ formProps, onBackButtonClick }) => {
  const { values, isSubmitting } = formProps;

  return (
    <Panel>
      <Form>
        <BodyStyled>
          <FieldArray validateOnChange={false} name="goals">
            {({ push, remove }) => (
              <>
                {values.goals.map(goalFieldsRender(formProps, remove))}

                <GoalButton
                  onClick={() => {
                    push({
                      ...initialGoalValues(),
                      name: `Goal ${values.goals.length + 1}`
                    });
                  }}
                  action="secondary"
                  size="small"
                  type="button"
                >
                  Add Goal
                </GoalButton>
              </>
            )}
          </FieldArray>
        </BodyStyled>
        <Footer>
          <BackButton
            type="button"
            disabled={isSubmitting}
            onClick={onBackButtonClick}
          />
          <NextButton
            type="submit"
            disabled={isSubmitting}
            loading={isSubmitting}
          />
        </Footer>
      </Form>
    </Panel>
  );
};
