import React from "react";
import { GoalIcon } from "testly-web/icons/GoalIcon";
import { TrackingIcon } from "testly-web/icons/TrackingIcon";
import { paths } from "testly-web/paths";
import {
  GetProjectForProjectPageProject,
  GetProjectForProjectPageCurrentUsage
} from "testly-web/queries";
import { Card } from "../Card";
import * as Table from "../Table";

export const TrackingColumn: React.SFC<{
  project: GetProjectForProjectPageProject;
  currentUsage: GetProjectForProjectPageCurrentUsage;
}> = ({ project }) => (
  <>
    <Table.Cell order={1}>
      <Table.Header
        image={TrackingIcon}
        caption="Tracking"
        description="Allows you to prove that the ideas you come up with during the learning phase will improve your results"
      />
    </Table.Cell>
    <Table.Cell order={2}>
      <Card
        titleCaption="Goals"
        titleImg={GoalIcon}
        disabled={false}
        secondaryLink={{
          name: "View Goals",
          href: paths.goalListPath({ projectId: project.id })
        }}
        primaryLink={{
          name: "Create Goal",
          href: `${paths.goalNewPath({
            projectId: project.id
          })}?cameFrom=dashboard`
        }}
        statistics={{
          "Goals created": project.goals.totalRecords.toLocaleString()
        }}
      />
    </Table.Cell>
    <Table.Cell order={3} showHr={false} />
    <Table.Cell order={4} showHr={false} />
  </>
);
