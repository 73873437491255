import React from "react";
import styled from "styled-components/macro";
import {
  GetProjectForProjectPageProject,
  GetProjectForProjectPageCurrentUsage
} from "testly-web/queries";
import { LearningColumn } from "./FeaturesTable/LearningColumn";
import { ProvingColumn } from "./FeaturesTable/ProvingColumn";
import { TrackingColumn } from "./FeaturesTable/TrackingColumn";
import { spaceBetweenColumns } from "./styled-vars";
import { StyledCell } from "./Table/Cell";

export const FeaturesTableStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: auto;

  ${StyledCell} {
    margin-right: ${spaceBetweenColumns}px;
    width: calc(33.3333% - ${spaceBetweenColumns}px);
    flex-grow: 1;
    overflow: hidden;
    list-style: none;
  }
`;

export const FeaturesTable: React.SFC<{
  project: GetProjectForProjectPageProject;
  updateProjectRecordingsStatus(isRecordingEnabled: boolean): Promise<void>;
  currentUsage: GetProjectForProjectPageCurrentUsage;
}> = ({ project, currentUsage, updateProjectRecordingsStatus }) => (
  <FeaturesTableStyled>
    <LearningColumn
      project={project}
      updateProjectRecordingsStatus={updateProjectRecordingsStatus}
      currentUsage={currentUsage}
    />
    <ProvingColumn project={project} currentUsage={currentUsage} />
    <TrackingColumn project={project} currentUsage={currentUsage} />
  </FeaturesTableStyled>
);
