import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import { paths } from "testly-web/paths";

const StyledHeader = styled.header`
  display: flex;
  height: 100px;
  padding: 0 40px;

  background-color: #f2f3f4;
  border-bottom: 4px solid #e2e4e6;
`;

// eslint-disable-next-line no-unexpected-multiline
const PageHeaderLink = styled(Link)<{
  active?: boolean;
}>`
  display: flex;
  align-items: center;

  height: 100%;

  position: relative;

  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  color: #393839;

  cursor: pointer;

  & + & {
    margin-left: 45px;
  }

  &:after {
    content: "";

    width: calc(100% + 20px);
    height: 100%;

    position: absolute;
    left: -10px;

    background-color: #000;
    opacity: 0;

    transition: opacity 0.2s;
  }

  &:hover::after {
    opacity: 0.02;
  }

  svg {
    margin-right: 10px;
  }

  ${({ active }) =>
    active &&
    css`
      color: #00ac69;
      border-bottom: 4px solid #00ac69;
    `}
`;

export type HeaderTab = "search" | "favorites";

// const RightSide = styled.div`
//   margin-left: auto;
// `;

export const Header: React.SFC<{
  tab: HeaderTab;
  projectId: string;
}> = ({ projectId, tab }) => {
  return (
    <StyledHeader>
      <PageHeaderLink
        to={paths.testIdeaIndexPath({ projectId, tab: "search" })}
        active={tab === "search"}
      >
        Search Test Ideas
      </PageHeaderLink>
      <PageHeaderLink
        to={paths.testIdeaIndexPath({ projectId, tab: "favorites" })}
        active={tab === "favorites"}
      >
        My Favorites
      </PageHeaderLink>
      {/* <RightSide>
        <PageHeaderLink to="#">
          <AddCutsomIdeaIcon />
          Add Custom Idea
        </PageHeaderLink>
      </RightSide> */}
    </StyledHeader>
  );
};
