import Honeybadger from "honeybadger-js";
import { get } from "lodash";
import React from "react";
import { QueryResult } from "react-apollo";
import { LoadingBar } from "./LoadingBar";
import { onQueryError } from "./onFail";
import { RedirectOnNotFound } from "./RedirectOnNotFound";

export const loadingRender = <Q, V>(
  checkPath: string,
  render: (result: QueryResult<Q, V>, isLoaded: boolean) => React.ReactNode,
  renderOnLoading?: boolean,
  showLoadingBar?: boolean
) => {
  return (queryResultik: QueryResult<Q, V>): React.ReactFragment => {
    const { loading, error, data } = queryResultik;

    if (error) {
      Honeybadger.notify(`Failed to load query ${JSON.stringify(error)}`);
      console.error(error);
      onQueryError();
      return <RedirectOnNotFound />;
    }

    const getData = get(data, checkPath);
    const isLoaded = getData !== null && getData !== undefined;

    if (loading === false && !get(data, checkPath)) {
      onQueryError();
      return <RedirectOnNotFound />;
    }

    return (
      <>
        <LoadingBar show={showLoadingBar !== false && (loading || false)} />
        {get(data, checkPath) || (renderOnLoading && loading)
          ? render(queryResultik, isLoaded)
          : !loading && <RedirectOnNotFound />}
      </>
    );
  };
};
