import React from "react";
import styled from "styled-components/macro";

interface Order {
  order: number;
}

export const StyledCell = styled.div<Order>`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: #fff;
  position: relative;
  ${({ order }) => `order: ${order};`};
`;

export const CellBody = styled.div`
  padding: 20px 25px;
  position: relative;
  height: calc(100% - 50px);
`;

// height: calc(100% - 50px); is needed because
// it seems chrome has a bug - it doesn't count pading/marging
// of flex child when calculating width/height of parent.

const Hr = styled.hr`
  border: none;
  border-top: solid 1px #ecedee;

  width: 100%;
  margin: 0;
  margin-top: auto;
`;

type CellProps = { className?: string; showHr?: boolean } & Order;

export const Cell: React.SFC<CellProps> = ({
  children,
  className,
  order,
  showHr
}) => (
  <>
    <StyledCell className={className} order={order}>
      <CellBody>{children}</CellBody>

      {showHr && <Hr />}
    </StyledCell>
  </>
);

Cell.defaultProps = {
  showHr: true
};
