import { IdentifiableVNode } from "./types";

// To avoid https://github.com/snabbdom/snabbdom#common-errors
export const resetElm = (node: IdentifiableVNode): IdentifiableVNode => {
  return {
    ...node,
    elm: undefined,
    children: node.children ? node.children.map(resetElm) : undefined
  };
};
