import { flatMap, groupBy, map } from "lodash";

export const graphColors = [
  "#000000",
  "#57c8f2",
  "#ff6c60",
  "#99c262",
  "#c262b1",
  "#00BCD4",
  "#009688",
  "#4CAF50",
  "#8BC34A",
  "#CDDC39",
  "#FFEB3B",
  "#FFC107",
  "#FF9800",
  "#FF5722",
  "#607D8B",
  "#F44336",
  "#E91E63",
  "#9C27B0",
  "#673AB7",
  "#3F51B5",
  "#2196F3",
  "#03A9F4"
];

export interface VariationConversion {
  conversionRate: number;
  conversionsCount: number;
  visitsCount: number;
  revenue: string;
  improvementRate: number | null;
  isWinner: boolean;
  goalId: string;
  variationId: string;
  ratesByDate: VariationConversionRatesByDate[];
}

interface VariationConversionRatesByDate {
  date: string;
  conversionRate: number;
}

export interface Variation {
  id: string;
  name: string;
  url: string;
  control: boolean;
}

export interface PathGoal {
  id: string;
  name: string;
}

export interface SplitTest {
  id: string;
  name: string;

  variations: Variation[];
  goals: PathGoal[];
  variationsConversions: VariationConversion[];
}

interface Point {
  [key: string]: any;
  date?: string;
  index?: number;
}

export const convertData = (arr: MappedVariation[]) => {
  const flatmapped = flatMap(arr, val => val.conversion.ratesByDate);
  const grouped = groupBy(flatmapped, "date");
  return map(grouped, group => {
    const point: Point = {};

    group.forEach((val, index) => {
      point.date = val.date;
      point[`${arr[index].name}`] = val.conversionRate.toFixed(2);

      return;
    });

    return point;
  });
};

export interface MappedVariation {
  id: string;
  name: string;
  url: string;
  control: boolean;
  conversion: VariationConversion;
}

export interface MappedSplitTest {
  id: string;
  name: string;

  variations: MappedVariation[];
  goals: PathGoal[];
  variationsConversions: VariationConversion[];

  selectedGoal: PathGoal | null;
}

export const mapSplitTestData = (splitTest: SplitTest): MappedSplitTest => {
  // TODO: make the same mapSpitTest for Show, Index and Edit pages
  const selectedGoal =
    splitTest.variationsConversions.length === 0
      ? null
      : splitTest.goals.find(
          ({ id }) => id === splitTest.variationsConversions[0].goalId
        )!;
  const selectedGoalId = selectedGoal ? selectedGoal.id : null;

  const variations = splitTest.variations.map(variation => {
    const conversion: VariationConversion = splitTest.variationsConversions.find(
      ({ goalId, variationId }) =>
        goalId === selectedGoalId && variationId === variation.id
    ) || {
      conversionRate: 0,
      conversionsCount: 0,
      visitsCount: 0,
      revenue: "0",
      improvementRate: null,
      isWinner: false,
      goalId: "string",
      variationId: "",
      ratesByDate: []
    };

    return {
      ...variation,
      conversion: {
        ...conversion,
        improvementRate: variation.control ? null : conversion.improvementRate
      }
    };
  });

  return {
    ...splitTest,
    selectedGoal,
    variations
  };
};
