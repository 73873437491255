import { debounce } from "lodash";
import React from "react";
import styled from "styled-components/macro";
import { FilterStyled } from "testly-web/components/filters/style";
import { InputWithImage } from "testly-web/components/Form/Input";
import { Search2Icon } from "testly-web/icons/Search2Icon";
import { FilterState } from "./HeatmapIndexPageContainer";

interface FilterProps {
  onFilterChange: (filter: FilterState) => void;
  filter: FilterState;
}

interface FilterComponentState {
  filter: FilterState;
}

const InputWrapper = styled.div`
  position: relative;
  width: 260px;
  margin-right: 30px;
`;
const InputWithImageStyled = styled(InputWithImage)`
  width: 260px;
  margin-right: 30px;
`;

export class Filter extends React.Component<FilterProps, FilterComponentState> {
  private debouncedOnFilterChange: (filter: FilterState) => void;
  private searchRef: React.RefObject<HTMLInputElement>;

  constructor(props: FilterProps) {
    super(props);

    this.state = {
      filter: props.filter
    };

    this.debouncedOnFilterChange = debounce(props.onFilterChange, 300);
    this.searchRef = React.createRef<HTMLInputElement>();
  }

  public componentWillReceiveProps(props: FilterProps) {
    this.debouncedOnFilterChange = debounce(props.onFilterChange, 300);
  }

  public render() {
    const { filter } = this.state;

    return (
      <FilterStyled>
        <InputWrapper>
          <InputWithImageStyled
            onChange={this.handleNameInput}
            value={filter.urlCont || ""}
            placeholder="Min. 3 chars"
            imagePosition="right"
            imageRender={() => <Search2Icon />}
            ref={this.searchRef}
          />
        </InputWrapper>
      </FilterStyled>
    );
  }

  public componentDidMount() {
    if (this.searchRef.current) {
      this.searchRef.current.focus();
    }
  }

  private handleNameInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFilterState = {
      ...this.props.filter,
      urlCont: e.target.value
    };

    this.setState({ filter: newFilterState });

    if (
      newFilterState.urlCont.length === 0 ||
      newFilterState.urlCont.length >= 3
    ) {
      this.debouncedOnFilterChange(newFilterState);
    }
  };
}
