import React from "react";
import styled from "styled-components/macro";
import { TextareaInputIcon } from "testly-web/icons/TextareaInputIcon";
import { TextInputIcon } from "testly-web/icons/TextInputIcon";
import { DropdownCaptionContainer, DropdownContent } from "../../Dropdown";
import { DropdownArrow } from "../../Dropdown/DropdownArrow";
import { DropdownContainer } from "../../Dropdown/DropdownContainer";
import { QuestionType } from "../types";

const StyledDropdown = styled(DropdownContainer)`
  height: 100%;
  ${DropdownCaptionContainer} {
    height: 100%;
    z-index: 1001;
  }

  ${DropdownContent} {
    border-radius: 0;
  }
`;

export const SelectedType = styled.div`
  display: flex;
  width: 168px;
  height: 100%;
  font-size: 12px;
  font-weight: 500;
  color: #393839;

  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 8px;

  background-color: #fff;

  border-bottom: solid 1px #dbdee0;
`;

const DropdownArrowStyled = styled(DropdownArrow)`
  margin-left: auto;
  margin-right: 8px;
`;

const QuestionDropdown = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
`;

const QuestionDropdownOption = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #393839;
  height: 32px;

  padding: 0px 16px;

  cursor: pointer;

  transition: background-color 0.25s;

  &:hover {
    background-color: #efefef;
  }

  svg {
    margin-right: 10px;
  }
`;

const questionSelectTypes: Record<
  QuestionType,
  { label: string; Icon: React.SFC }
> = {
  [QuestionType.ShortTextQuestion]: {
    label: "Short Answer",
    Icon: TextInputIcon
  },
  [QuestionType.LongTextQuestion]: {
    label: "Long Answer",
    Icon: TextareaInputIcon
  }
};

export const TypesDropdown: React.SFC<{
  selectedQuestionType: QuestionType;
  onQuestionTypeSelect: (type: QuestionType) => void;
  ableToChangeType: boolean;
}> = ({ selectedQuestionType, onQuestionTypeSelect, ableToChangeType }) => (
  <StyledDropdown
    dropdownContent={() => (
      <QuestionDropdown>
        {Object.entries(questionSelectTypes).map(([type, { label, Icon }]) => (
          <QuestionDropdownOption
            key={type}
            onClick={() => onQuestionTypeSelect(type as QuestionType)}
          >
            <Icon />
            {label}
          </QuestionDropdownOption>
        ))}
      </QuestionDropdown>
    )}
    animation="slide"
    dropdownEnabled={ableToChangeType}
  >
    <SelectedType>
      {questionSelectTypes[selectedQuestionType].label}
      {ableToChangeType && <DropdownArrowStyled />}
    </SelectedType>
  </StyledDropdown>
);
