import React from "react";
import { GetDataForCurrentProjectIdComponent } from "testly-web/queries";

export const GetCurrentProjectId: React.SFC<{
  children(currentProjectId: string | null): React.ReactNode;
}> = ({ children }) => (
  <GetDataForCurrentProjectIdComponent>
    {({ data }) => data && children(data.currentProjectId)}
  </GetDataForCurrentProjectIdComponent>
);
