import React from "react";

export const GoalIcon: React.SFC<
  React.SVGProps<SVGSVGElement> & { mainColor?: "gray"; size?: "small" }
> = ({ mainColor, size, ...props }) => (
  // tslint:disable:max-line-length
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    {...(size === "small" ? { width: "16", height: "16" } : {})}
    {...props}
  >
    <path
      fillRule="nonzero"
      fill={mainColor === "gray" ? "#A7A9AE" : "#00ac69"}
      d="M14 0C6.28 0 0 6.28 0 14s6.28 14 14 14 14-6.28 14-14c0-2.798-.828-5.406-2.248-7.596l-.543.544-.357.356h-1.574l-1.663 1.664A9.079 9.079 0 0 1 23.13 14 9.13 9.13 0 1 1 14 4.87c1.86 0 3.59.56 5.032 1.515l1.664-1.663V3.148l.356-.357.541-.54A13.902 13.902 0 0 0 14 0zm10.633.933l-3.041 3.042v1.573l-8.338 8.338a.608.608 0 1 0 .86.86l8.338-8.338h1.573l3.042-3.041h-2.434V.933zM14 8.4A5.607 5.607 0 0 0 8.4 14c0 3.087 2.513 5.6 5.6 5.6 3.087 0 5.6-2.513 5.6-5.6 0-.863-.202-1.677-.552-2.407L15.32 15.32a1.853 1.853 0 0 1-1.32.547c-.498 0-.968-.194-1.32-.547a1.853 1.853 0 0 1-.547-1.32c0-.498.194-.968.547-1.32l3.729-3.728A5.534 5.534 0 0 0 14 8.4z"
    />
  </svg>
);
