import React from "react";

export const SettingsIcon = () => (
  // tslint:disable:max-line-length
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
  >
    <path
      fillRule="nonzero"
      d="M10.601 0l-.168.875-.64 3.197A10.704 10.704 0 0 0 7 5.722L3.87 4.643l-.841-.269-.438.774L.437 8.851 0 9.625l.64.572 2.422 2.12c-.088.551-.201 1.102-.201 1.683 0 .58.113 1.132.201 1.683L.64 17.803 0 18.375l.438.774 2.153 3.702.438.774.841-.27L7 22.28a10.704 10.704 0 0 0 2.793 1.649l.64 3.197.168.875h6.058l.168-.875.64-3.197a10.704 10.704 0 0 0 2.793-1.65l3.13 1.078.84.269.438-.774 2.154-3.702.438-.774-.64-.572-2.423-2.12c.088-.551.202-1.102.202-1.683 0-.58-.114-1.132-.202-1.683l2.423-2.12.64-.572-.438-.774-2.154-3.702-.437-.774-.842.27-3.13 1.076a10.704 10.704 0 0 0-2.793-1.649l-.64-3.197L16.66 0H10.6zm1.75 2.154h2.558l.538 2.793.135.64.605.201a8.633 8.633 0 0 1 3.265 1.885l.471.438.606-.202 2.726-.943 1.279 2.188-2.154 1.918-.505.404.168.64A8.51 8.51 0 0 1 22.245 14c0 .644-.063 1.27-.202 1.885l-.134.639.47.404 2.155 1.918-1.28 2.188-2.725-.943-.606-.202-.471.438a8.633 8.633 0 0 1-3.265 1.885l-.605.201-.135.64-.538 2.793H12.35l-.539-2.793-.134-.64-.606-.201a8.633 8.633 0 0 1-3.264-1.885l-.471-.438-.606.202-2.726.943-1.279-2.188 2.154-1.918.505-.404-.169-.64A8.509 8.509 0 0 1 5.014 14c0-.644.064-1.27.202-1.885l.169-.639-.505-.404-2.154-1.918 1.279-2.188 2.726.943.606.202.47-.438a8.633 8.633 0 0 1 3.265-1.885l.606-.201.134-.64.539-2.793zm1.279 6.461c-2.962 0-5.385 2.423-5.385 5.385 0 2.962 2.423 5.385 5.385 5.385 2.961 0 5.384-2.423 5.384-5.385 0-2.962-2.423-5.385-5.384-5.385zm0 2.154A3.216 3.216 0 0 1 16.86 14a3.216 3.216 0 0 1-3.23 3.23A3.216 3.216 0 0 1 10.399 14a3.216 3.216 0 0 1 3.23-3.23z"
    />
  </svg>
);
