import React from "react";
import { MutationFn } from "react-apollo";
import styled from "styled-components/macro";
import { Paginator } from "testly-web/components/Paginator";
import {
  GetDataForTestIdeaIndexPageEdges,
  GetDataForTestIdeaIndexPageTestIdeaCategories,
  ToggleLikeAtTestIdeaIndexPageMutation,
  ToggleLikeAtTestIdeaIndexPageVariables
} from "testly-web/queries";
import { Header, HeaderTab } from "./Header";
import { Filter } from "./queryState";
import { Search } from "./Search";
import { TestIdea } from "./TestIdea";

const IdeasContainer = styled.div`
  padding: 20px 40px;

  background-color: #f8f8f8;
`;

const IdeaCards = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* grid-template-rows: repeat(3, 1fr); */
  gap: 20px;

  @media screen and (max-width: 1400px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const TestIdeaIndexPage: React.SFC<{
  categories: GetDataForTestIdeaIndexPageTestIdeaCategories[];
  ideaEdges: GetDataForTestIdeaIndexPageEdges[];
  onFilterChange: (filter: Filter) => void;
  filter: Filter;

  headerTab: HeaderTab;
  projectId: string;

  pageCount: number;
  onPageChange: (page: number) => void;
  page: number;

  toggleLike: MutationFn<
    ToggleLikeAtTestIdeaIndexPageMutation,
    ToggleLikeAtTestIdeaIndexPageVariables
  >;
}> = ({
  ideaEdges,
  categories,
  filter,
  onFilterChange,
  pageCount,
  onPageChange,
  page,
  toggleLike,
  headerTab,
  projectId
}) => {
  return (
    <>
      <Header tab={headerTab} projectId={projectId} />
      <Search
        categories={categories}
        filter={filter}
        onFilterChange={onFilterChange}
      />
      <IdeasContainer>
        <IdeaCards>
          {ideaEdges.map(edge => (
            <TestIdea
              projectId={projectId}
              ideaEdge={edge}
              key={edge.node.id}
              onLikeClick={() => {
                toggleLike({
                  variables: { testIdeaTestId: edge.node.id },
                  optimisticResponse: {
                    __typename: "Mutation",
                    toggleTestIdeaLike: {
                      __typename: "LikeTestIdeaPayload",
                      testIdeaEdge: {
                        __typename: "UserTestIdeaEdge",
                        isLiked: !edge.isLiked,
                        node: {
                          __typename: "TestIdea",
                          id: edge.node.id
                        }
                      }
                    }
                  }
                });
              }}
            />
          ))}
        </IdeaCards>
        {pageCount > 0 && (
          <Paginator
            pageCount={pageCount}
            page={page}
            onPageChange={({ selected }) => onPageChange(selected)}
          />
        )}
      </IdeasContainer>
    </>
  );
};
