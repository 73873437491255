import React from "react";
import styled from "styled-components/macro";
import { ProjectStateIcon } from "testly-web/icons/ProjectStateIcon";
import { ProjectState } from "testly-web/queries";
import { UnreachableCaseError } from "testly-web/utils";

const statusCaptions: Record<ProjectState, string> = {
  ACTIVE: "Code is installed",
  CODE_MAY_NOT_BE_INSTALLED: "Warning. Code may not be installed.",
  WAITING_FOR_FIRST_VISIT: "Pending. Waiting on 1st website visitor."
};

const StyledSiteStatus = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
`;
const Domain = styled.div`
  min-width: 0;
  font-size: 18px;
  font-weight: 500;
  color: #393839;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  padding-right: 16px; /* to add more space for project refresher */
`;
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 13px;
  min-width: 0;
`;
const StatusCaption = styled.div<{ projectState: ProjectState }>`
  font-size: 16px;
  margin-top: 8px;
  color: ${({ projectState }) => {
    switch (projectState) {
      case ProjectState.Active:
        return "#00ac69";
      case ProjectState.CodeMayNotBeInstalled:
        return "#ff5858";
      case ProjectState.WaitingForFirstVisit:
        return "#f5a623";
      default:
        throw new UnreachableCaseError(projectState);
    }
  }};
`;
const ProjectStateIconStyled = styled(ProjectStateIcon)`
  flex-shrink: 0;
`;
// const Refresh = styled.div<{ isRefreshing: boolean }>`
//   @keyframes spin {
//     from {
//       transform: rotate(360deg);
//     }
//     to {
//       transform: rotate(0deg);
//     }
//   }

//   position: absolute;
//   border: none;
//   background-image: url(${refresh});
//   width: 18px;
//   height: 24px;
//   top: 15px;
//   right: 15px;
//   outline: none;
//   cursor: pointer;

//   ${({ isRefreshing }) =>
//     isRefreshing &&
//     css`
//       animation: spin 1s infinite linear;
//     `};
// `;

export const SiteStatus: React.SFC<{
  state: ProjectState;
  domain: string;
}> = ({ state, domain }) => (
  <StyledSiteStatus>
    <ProjectStateIconStyled projectState={state} />
    <TextWrapper>
      <Domain>{domain}</Domain>
      <StatusCaption projectState={state}>
        {statusCaptions[state]}
      </StatusCaption>
    </TextWrapper>
  </StyledSiteStatus>
);
