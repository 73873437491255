import React, { useRef, useState } from "react";
import { MutationFn } from "react-apollo";
import styled from "styled-components/macro";
import noPhotoIcon from "testly-web/icons/no-photo-icon.svg";
import {
  UpdateProfileAtAccountSettingsMutation,
  UpdateProfileAtAccountSettingsVariables
} from "testly-web/queries";
import { Button } from "../Button";
import { LoadingIndicator } from "../LoadingIndicator";

const UserPhoto = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-right: 40px;
  position: relative;
`;

const EditAvatarLink = styled(Button)`
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  color: #a7a9ae;
`;

const Avatar = styled.img`
  border-radius: 50%;
`;

const LoadingIndicatorStyled = styled(LoadingIndicator)`
  position: absolute;
  left: -8px;
  top: -8px;
`;

export const AvatarUpload: React.SFC<{
  updateProfile: MutationFn<
    UpdateProfileAtAccountSettingsMutation,
    UpdateProfileAtAccountSettingsVariables
  >;
  userId: string;
  avatarUrl: string | null;
}> = ({ updateProfile, userId, avatarUrl }) => {
  const inputEl = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const onButtonClick = () => {
    // `current` points to the mounted text input element
    inputEl.current!.click();
  };

  return (
    <UserPhoto>
      <input
        type="file"
        accept="image/*"
        ref={inputEl}
        style={{ display: "none" }}
        onChange={async () => {
          setLoading(true);

          await updateProfile({
            variables: {
              userId,
              userParams: {
                avatar: inputEl.current!.files!.item(0)!
              }
            }
          });

          setLoading(false);
        }}
      />
      <Avatar src={avatarUrl || noPhotoIcon} width={125} height={125} />

      <LoadingIndicatorStyled isLoading={loading} />

      <EditAvatarLink
        type="button"
        action="link"
        size="small"
        children="Edit Avatar"
        onClick={onButtonClick}
      />
    </UserPhoto>
  );
};
