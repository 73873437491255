import React from "react";

export const PauseIcon: React.SFC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="20"
    viewBox="0 0 17 20"
    {...props}
  >
    <g fill="#FFF">
      <rect width="5" height="20" rx="2" />
      <rect width="5" height="20" x="12" rx="2" />
    </g>
  </svg>
);
