import {
  MousePosition,
  PageVisit,
  ScrollPosition,
  WindowSize
} from "./siteTypes";
import { CssRuleMutation } from "./stylesheet/types";
import { DomMutation } from "./virtualDom/mutationTypes";

export enum SessionEventType {
  PageVisited = "pageVisited",
  DomMutated = "domMutated",
  ScrollPositionChanged = "scrolled",
  MouseClicked = "mouseClicked",
  MouseMoved = "mouseMoved",
  WindowSizeChanged = "windowSizeChanged",
  CssRuleMutated = "cssRuleMutated"
}

interface Timeable {
  time: number; // can be negative, if timeline is applied
  happenedAt: number;
  onTimeline: boolean; // show if belongs to current Timeline(time > 0), see TimelineManager
}

export interface PageVisited extends Timeable {
  eventType: SessionEventType.PageVisited;
  data: PageVisit;
}

export interface DomMutated extends Timeable {
  eventType: SessionEventType.DomMutated;
  data: DomMutation;
}

export interface ScrollPositionChanged extends Timeable {
  eventType: SessionEventType.ScrollPositionChanged;
  data: ScrollPosition;
}

export interface MouseClicked extends Timeable {
  eventType: SessionEventType.MouseClicked;
  data: MousePosition & { selector: string };
}

export interface MouseMoved extends Timeable {
  eventType: SessionEventType.MouseMoved;
  data: MousePosition;
}

export interface WindowSizeChanged extends Timeable {
  eventType: SessionEventType.WindowSizeChanged;
  data: WindowSize;
}

export interface CssRuleMutated extends Timeable {
  eventType: SessionEventType.CssRuleMutated;
  data: CssRuleMutation;
}

export type SessionEvent =
  | PageVisited
  | DomMutated
  | ScrollPositionChanged
  | MouseClicked
  | MouseMoved
  | WindowSizeChanged
  | CssRuleMutated;
