import React from "react";

export const BackIcon = () => (
  // tslint:disable:max-line-length
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="24"
    viewBox="0 0 35 24"
  >
    <path
      fill="#A7A9AE"
      d="M12.813.094a.99.99 0 0 0-.563.312L1.344 11.281.656 12l.688.719L12.25 23.594c.242.297.629.433 1.004.347.371-.086.664-.378.75-.75a1.004 1.004 0 0 0-.348-1.003L4.47 13H34c.36.004.695-.184.879-.496a1.01 1.01 0 0 0 0-1.008c-.184-.312-.52-.5-.879-.496H4.469l9.187-9.188c.324-.3.41-.777.215-1.171a.996.996 0 0 0-1.059-.547z"
    />
  </svg>
);
