import React from "react";

export const PlusIcon = () => (
  // tslint:disable:max-line-length

  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Dashboard" fill="none" fillRule="evenodd">
      <g
        id="Testly-Dashboard-Learning"
        transform="translate(-901 -217)"
        fill="#00AC69"
        fillRule="nonzero"
      >
        <g id="Header">
          <g id="Add-website" transform="translate(877)">
            <path
              d="M44,217 C32.9646043,217 24,225.964604 24,237 C24,248.035396 32.9646043,257 44,257 C55.0353957,257 64,248.035396 64,237 C64,225.964604 55.0353957,217 44,217 Z M44,218.73913 C54.0954991,218.73913 62.2608696,226.904501 62.2608696,237 C62.2608696,247.095499 54.0954991,255.26087 44,255.26087 C33.9045009,255.26087 25.7391304,247.095499 25.7391304,237 C25.7391304,226.904501 33.9045009,218.73913 44,218.73913 Z M43.986413,230.03159 C43.5068337,230.039086 43.1238672,230.433451 43.1304348,230.913043 L43.1304348,236.130435 L37.9130435,236.130435 C37.599447,236.126 37.3077553,236.290761 37.1496629,236.561629 C36.9915704,236.832496 36.9915704,237.167504 37.1496629,237.438371 C37.3077553,237.709239 37.599447,237.874 37.9130435,237.869565 L43.1304348,237.869565 L43.1304348,243.086957 C43.1259997,243.400553 43.2907611,243.692245 43.5616286,243.850337 C43.832496,244.00843 44.167504,244.00843 44.4383714,243.850337 C44.7092389,243.692245 44.8740003,243.400553 44.8695652,243.086957 L44.8695652,237.869565 L50.0869565,237.869565 C50.400553,237.874 50.6922447,237.709239 50.8503371,237.438371 C51.0084296,237.167504 51.0084296,236.832496 50.8503371,236.561629 C50.6922447,236.290761 50.400553,236.126 50.0869565,236.130435 L44.8695652,236.130435 L44.8695652,230.913043 C44.8727839,230.678 44.7807159,230.451664 44.6143394,230.285607 C44.4479629,230.119551 44.2214502,230.027918 43.986413,230.03159 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
