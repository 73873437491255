import React from "react";
import styled from "styled-components/macro";
import { CodeStepContainer, CodeStepContainerProps } from "./CodeStepContainer";
import { VideoStep } from "./VideoStep";

type ProjectSetupPageProps = CodeStepContainerProps;

const ProjectSetupPageStyled = styled.div`
  display: flex;
  padding: 32px 40px;
`;

export const ProjectSetupPage: React.SFC<ProjectSetupPageProps> = props => (
  <ProjectSetupPageStyled>
    <VideoStep />
    <CodeStepContainer {...props} />
  </ProjectSetupPageStyled>
);
