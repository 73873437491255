function hypotenuse(x1: number, y1: number, x2: number, y2: number) {
  const xSquare = Math.pow(x1 - x2, 2);
  const ySquare = Math.pow(y1 - y2, 2);
  return Math.sqrt(xSquare + ySquare);
}

const influence = (distanceFromCenter: number, dimension: number) => {
  // const percent = distanceFromCenter / (dimension / 2);

  // if (percent > 1) {
  //   return 0.9;
  // } else if (percent >= 0.85) {
  //   return 0.9;
  // } else if (percent >= 0.55) {
  //   return 0.9;
  // } else if (percent >= 0.25) {
  //   return 1;
  // } else {
  //   return 1;
  // }
  // if (percent <= 0.1) {
  //   return 3;
  // } else {
  //   return 1;
  // }

  return 1;
};

/*
 * Generates a kernel used for the heatmap generation
 */
export function interKernel(dimension: number) {
  if (
    !(dimension % 2) ||
    Math.floor(dimension) !== dimension ||
    dimension < 3
  ) {
    throw new Error(
      "The dimension must be an odd integer greater than or equal to 3"
    );
  }
  const kernel: number[][] = [];

  const centre = (dimension - 1) / 2;

  for (let i = 0; i < dimension; i++) {
    kernel[i] = [];

    for (let j = 0; j < dimension; j++) {
      const distance = hypotenuse(i, j, centre, centre);

      kernel[i][j] = influence(distance, dimension);
    }
  }

  return kernel;
}
