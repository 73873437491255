import React from "react";
import styled from "styled-components";

const exampleHistory = [
  {
    type: "Free trial",
    receipt: "BRYX-201805211019-311875",
    value: 0,
    date: "May 21, 2018"
  },
  {
    type: "Free trial",
    receipt: "BRYX-201805211019-123122",
    value: 15,
    date: "Nov 28, 2018"
  },
  {
    type: "Free trial",
    receipt: "BRYX-201805211019-123131",
    value: 15,
    date: "Nov 28, 2018"
  },
  {
    type: "Free trial",
    receipt: "BRYX-201805211019-124631",
    value: 15,
    date: "Nov 28, 2018"
  }
];

const StyledBillingHistoryItem = styled.tr`
  height: 50px;

  & > td {
    border-bottom: 1px solid #ecedee;

    font-size: 14px;
    color: #393839;

    &:nth-child(1) {
      width: 110px;
    }

    &:nth-child(2) {
      width: auto;
    }

    &:nth-child(3) {
      width: 60px;
    }

    &:nth-child(4) {
      width: 100px;
    }
  }
`;

const StyledTable = styled.table`
  width: 100%;
`;

const StyledTHead = styled.thead`
  th {
    color: #a7a9ae;
    font-size: 10px;
    font-weight: 500;
    text-transform: uppercase;
    text-align: left;
  }
`;

const BillingHistoryItem: React.SFC<{
  type: string;
  receipt: string;
  value: number;
  date: string;
}> = ({ type, receipt, value, date }) => (
  <StyledBillingHistoryItem>
    <td>{type}</td>
    <td>{receipt}</td>
    <td>${value.toFixed(2)}</td>
    <td>{date}</td>
  </StyledBillingHistoryItem>
);

export const BillingHistory: React.SFC = () => (
  <>
    <StyledTable>
      <StyledTHead>
        <tr>
          <th>Record type</th>
          <th>Receipt</th>
          <th>Value</th>
          <th>Date</th>
        </tr>
      </StyledTHead>
      <tbody>
        {exampleHistory.map(item => (
          <BillingHistoryItem
            type={item.type}
            receipt={item.receipt}
            value={item.value}
            date={item.date}
            key={item.receipt}
          />
        ))}
      </tbody>
    </StyledTable>
  </>
);
